import React, { useEffect } from 'react'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import {Button, Card, Col, Row, Select, Spin, Tag} from "antd"
import { RouteComponentProps, useParams } from 'react-router-dom'
import getContainersHistories from './actions/getContainersHistories'
import getContainersChanges from './actions/getContainersChanges'
import HistoryTable from './components/HistoryTable/HistoryTable'
import ChangesTable from './components/ChangesTable/ChangesTable'
import getVpsContainer from './actions/getVpsContainer'
import getHypervisorByHost from './actions/getVpsByHost'
import GraphComponent from './components/GraphComponent/GraphComponent'
import VpsDetailComponent from "./VpsDetailComponent";

interface ParamTypes {
    vps_id: string
}


const VpsDetailPage = (props: RouteComponentProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { vps_id } = useParams<ParamTypes>()

    const [activeTabKey, setActiveTabKey] = useState<string>('graph');
    const { vpsServers, hypervisor, vpsServer, isLoading } = useSelector((state: AppState) => state.vpsadmin)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg) => { if (appSetting && appSetting.debug) {console.log('VpsDetailPage: ' + msg)}}

    useEffect(() => {
        // load hypervisors from API
        // trigger ONCE
        dispatch(getVpsContainer(vps_id))
    }, [])

    useEffect(() => {
        if (vpsServer) {
            dispatch(getContainersHistories(vpsServer.id))
            dispatch(getContainersChanges(vpsServer.id))
            if (!hypervisor || vpsServer.host != hypervisor.host) {
                dispatch(getHypervisorByHost(vpsServer.host))
                logger('getHypervisorByHost: '+vpsServer.host)
            }
        }
    }, [vpsServer])

    useEffect(() => {
        if (hypervisor) {
            logger('loaded hypervisor: '+hypervisor.host)
        }
    }, [hypervisor])

    const contentListNoTitle: Record<string, React.ReactNode> = {
        graph: <GraphComponent />,
        history: <Col span={24}><HistoryTable type='vps' /></Col>,
        changes: <Col span={24} className={'pad4'}><ChangesTable type='vps' /></Col>,
    };

    if (!vpsServer) {
        logger('vpsServer: '+vpsServer)
        return <Spin />
    }

    return (
        <Card key={1}>
            <VpsDetailComponent />
            <Row style={{marginBottom: '15px', padding: 15, borderBottom: '1px solid #dedede'}}>
                <Col span={3} style={{textAlign: 'center'}}>
                    <Button type="primary"
                            ghost={activeTabKey != 'graph'}
                            onClick={() => setActiveTabKey('graph')}>
                        {t('vpsadmin.graph.title')}
                    </Button>
                </Col>
                <Col span={3} style={{textAlign: 'center'}}>
                    <Button type="primary"
                            ghost={activeTabKey != 'history'}
                            onClick={() => setActiveTabKey('history')}>
                        {t('vpsadmin.history')}
                    </Button>
                </Col>
                <Col span={3} style={{textAlign: 'center'}}>
                    <Button type="primary"
                            ghost={activeTabKey != 'changes'}
                            onClick={() => setActiveTabKey('changes')}>
                        {t('vpsadmin.changes.title')}
                    </Button>
                </Col>
                <Col span={15}>&nbsp;</Col>
            </Row>
            <Row>
                <Col span={24}>
                    {contentListNoTitle[activeTabKey]}
                </Col>
            </Row>
        </Card>
    )
}

export default VpsDetailPage

