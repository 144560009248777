import React, {useEffect, useState} from 'react'
import {Button, Col, Input, InputNumber, message, Row, Switch, Tag} from 'antd'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import {SettingsDetails, SettingsParams} from "../settings/models";
import isNumeric from "antd/es/_util/isNumeric";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import LoggedUser, {ROLE_CUSTOMER_OWNER, ROLE_USER_MANAGER, ROLE_ZCOM_ADMIN} from "../../common/LoggedUser";
import updateUserSetting from "./actions/updateUserSetting";
import updateCustomerSetting from "./actions/updateCustomerSetting";
import updateAppSetting from "./actions/updateAppSetting";
import updateSelfSetting from "../login/actions/updateSelfSetting";
import getUserSettings from "./actions/getUserSettings";


interface Props {
    item: SettingsDetails
    loggedUser: LoggedUser
}

export const renderScope = (scope: string) => {
    if (!scope) {
        return ''
    }
    if (scope.includes('app')) {
        return (<Tag color="red">app</Tag>)
    }
    if (scope.includes('customer')) {
        return (<Tag color="orange">customer</Tag>)
    }
    if (scope.includes('user')) {
        return (<Tag color="green">user</Tag>)
    }
}

const PanelSettings = ({item, loggedUser}: Props) => {

    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {user, user_settings, user_settings_scope} = useSelector((state: AppState) => state.user)
    const {self} = useSelector((state: AppState) => state.auth)

    const [settingItem, setSettingItem] = useState<SettingsDetails>()
    const [valueBool, setValueBool] = useState<boolean>(false)
    const [valueNumber, setValueNumber] = useState<number | undefined>()
    const [valueString, setValueString] = useState<string>('')
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)


    useEffect(() => {
        guessType()
        console.log('guessType: '+item.name + ' .. '+ item.ctrl_type + ' .. '   + user_settings_scope)
        setSettingItem(item)
    }, [item]);

    const isMyself = () => {
        if (self && user) {
            return self.id === user.id
        }
        return false
    }

    const guessType = () => {

        if (checkExceptions()) {
            // found
            return;
        }

        if (item.value === '0') {
            item.ctrl_type = 'bool'
            setValueBool(false)
            return
        }
        if (item.value === '1') {
            item.ctrl_type = 'bool'
            setValueBool(true)
            return
        }

        if ( isNumeric(item.value) && parseInt(item.value) > 0) {
            item.ctrl_type = 'number'
            item.ctrl_min = 0
            item.ctrl_max = 10000
            setValueNumber(parseInt(item.value))
            return
        }
        setValueString(item.value)
        item.ctrl_type = 'string'
    }

    const checkExceptions = (): boolean => {
        if (item.name === 'price_rounding') {
            item.ctrl_type = 'number'
            item.ctrl_min = 0
            item.ctrl_max = 5
            setValueNumber(parseInt(item.value))
            return true
        }
        return false
    }

    const saveAppSettings = (record: SettingsDetails, value: string) => {
        const params: SettingsParams = {name: record.name, value: value}
        dispatch(
            updateAppSetting(params, (suc: boolean) => {
                if (suc) {
                    dispatch(getUserSettings(user!.id))
                    message.success(t('settingsPage.edit_done'))
                } else {
                    message.error(t('settingsPage.error.update'))
                }
            }),
        )
    }

    const saveCustomerSettings = (record: SettingsDetails, value: string) => {
        const params: SettingsParams = {id: user!.customer_id, name: record.name, value: value}
        dispatch(
            updateCustomerSetting(params, (suc: boolean) => {
                if (suc) {
                    dispatch(getUserSettings(user!.id))
                    message.success(t('settingsPage.edit_done'))
                } else {
                    message.error(t('settingsPage.error.update'))
                }
            }),
        )
    }

    const saveUserSettings = (record: SettingsDetails, value: string) => {
        let objId = isMyself() ? self!.id : user!.id
        if (isMyself()) {
            const params: SettingsParams = {id: objId, name: record.name, value: value}
            dispatch(updateSelfSetting(params, (suc: boolean) => {
                if (suc) {
                    dispatch(getUserSettings(user!.id))
                    message.success(t('settingsPage.edit_done'))
                } else {
                    message.error(t('settingsPage.error.update'))
                }
            }))

        }
        else {
            const params: SettingsParams = {id: objId, name: record.name, value: value}
            dispatch(
                updateUserSetting(params, (suc: boolean) => {
                    if (suc) {
                        dispatch(getUserSettings(user!.id))
                        message.success(t('settingsPage.edit_done'))
                    } else {
                        message.error(t('settingsPage.error.update'))
                    }
                }),
            )
        }
    }

    const saveSettings = () => {
        let value: string = 'error'
        if (item.ctrl_type === 'bool') {
            value = valueBool ? '1' : '0'
        }
        if (item.ctrl_type === 'number') {
            value = `${valueNumber}`
        }
        if (item.ctrl_type === 'string') {
            value = valueString
        }
        if (value === 'error') {
            console.error('ERROR: Setting missing ctrl_type: '+item.name)
            message.error(t('general.error'))
            return
        }

        if (user_settings_scope === 'user') {
            if (!loggedUser!.hasRole(ROLE_USER_MANAGER) && !isMyself()) {
                message.error(t('errors.permission_denied') + ': '+ ROLE_USER_MANAGER)
                return
            }
            saveUserSettings(item, value)
            return
        }

        if (user_settings_scope === 'customer') {
            if (!loggedUser!.hasRole(ROLE_CUSTOMER_OWNER) && !loggedUser!.hasRole(ROLE_ZCOM_ADMIN)) {
                message.error(t('errors.permission_denied') + ': '+ ROLE_CUSTOMER_OWNER)
                return
            }
            saveCustomerSettings(item, value)
            return
        }

        if (user_settings_scope === 'app') {
            if (!loggedUser!.hasRole(ROLE_ZCOM_ADMIN)) {
                message.error(t('errors.permission_denied') + ': '+ ROLE_ZCOM_ADMIN)
                return
            }
            saveAppSettings(item, value)
        }
    }

    const renderBool = () => {
        return (
            <Row className='settingItem'>
                <Col span={10} className='labelCol'><span className='label'> {settingItem!.name}</span> </Col>
                <Col span={10} className='valueCol'>

                        <Switch
                            checkedChildren={<CheckOutlined/>}
                            unCheckedChildren={<CloseOutlined/>}
                            checked={valueBool}
                            disabled={(item.frozen === 1 && item.scope != 'app')}
                            onChange={() => {setValueBool(!valueBool);setUpdateStatus(true)}}
                        />

                </Col>
                <Col span={4} className='btnCol'>
                    <Button size='small' type='primary'
                            onClick={() => saveSettings()}
                            disabled={!updateStatus}>{t('general.save')}</Button>
                </Col>
                <Col span={20}>
                    <span className='textGray'>{t(`settingHelp.${item.name}_desc`)}</span>
                </Col>
                <Col span={4} className='scopeCol'>{renderScope(user_settings_scope)}</Col>
            </Row>
        )
    }

    const renderNumber = () => {
        return (
            <Row className='settingItem'>
                <Col span={10} className='labelCol'><span className='label'> {settingItem!.name}</span> </Col>
                <Col span={10} className='valueCol'>
                    {
                        item.frozen === 1 && (
                            <span className="value">{item.value}</span>
                        )
                    }
                    {
                        item.frozen === 0 && (
                            <InputNumber
                                min={item.ctrl_min}
                                max={item.ctrl_max}
                                step={1}
                                value={valueNumber}
                                onChange={(v) => {v!=null && setValueNumber(v); setUpdateStatus(true)}}
                            />
                        )
                    }
                </Col>
                <Col span={4} className='btnCol'>
                    <Button size='small' type='primary'
                            onClick={() => saveSettings()}
                            disabled={!updateStatus}>{t('general.save')}</Button>
                </Col>
                <Col span={20}>
                    <span className='textGray'>{t(`settingHelp.${item.name}_desc`)}</span>
                </Col>
                <Col span={4} className='scopeCol'>{renderScope(user_settings_scope)}</Col>
            </Row>
        )
    }

    const renderString = () => {
        return (
            <Row className='settingItem'>
                <Col span={10} className='labelCol'><span className='label'> {settingItem!.name}</span> </Col>
                <Col span={10} className='valueCol'>
                    {
                        item.frozen === 1 && (
                            <span className="value">{item.value}</span>
                        )
                    }
                    {
                        item.frozen === 0 && (
                            <Input type='text' style={{width: '240px'}}
                                   onChange={() => {setUpdateStatus(true)}}
                                   value={valueString}/>
                        )
                    }
                </Col>
                <Col span={4} className='btnCol'>
                    <Button size='small' type='primary'
                            onClick={() => saveSettings()}
                            disabled={!updateStatus}>{t('general.save')}</Button>
                </Col>
                <Col span={20}>
                    <span className='textGray'>{t(`settingHelp.${item.name}_desc`)}</span>
                </Col>
                <Col span={4} className='scopeCol'>{renderScope(user_settings_scope)}</Col>
            </Row>
        )
    }

    if (!settingItem || settingItem && !settingItem.ctrl_type) {
        return (
            <div style={{width: '99%', textAlign: 'center'}}><span>Loading..{item.name} .. {item.ctrl_type}</span></div>
        )
    }

    switch(settingItem.ctrl_type) {
        case 'bool':
            return renderBool()

        case 'number':
            return renderNumber()

        case 'string':
            return renderString()

    }

    return (
        <>
            <Row>
                <Col span={24} style={{textAlign: 'center'}}>
                    Error of Setting: {settingItem.name}
                </Col>
            </Row>
        </>
    )
}

export default PanelSettings
