import {Tag} from "antd";
import React from "react";
import {DnsZone, DomainModel} from "./models";


export const hasDnsService = (item: DomainModel):boolean => {
    if (!item || !item.services) {
        return false
    }
    return item.services.filter(s => s.type_id === 1).length > 0
}

export const getDnsServiceId = (item: DomainModel):number | undefined => {
    if (!item || !item.services) {
        return undefined
    }
    const service = item.services.find(s => s.type_id === 1)
    return service ? service.service_id : undefined
}

export const getDriverName = (driverId: number) => {
    if (driverId === 1) {
        return "PowerDns"
    }if (driverId === 2) {
        return "Amazon"
    }
    return "unknown"
}

export const renderDriver = (driver_id: number) => {
    if (!driver_id) {
        return ''
    }
    if (driver_id === 1) {
        return (<Tag color="geekblue">{getDriverName(driver_id)}</Tag>)
    }
    else {
        return (<Tag color="orange">{getDriverName(driver_id)}</Tag>)
    }
}

export const renderZoneState = (zone: DnsZone, t) => {
    if (!zone) {
        return ''
    }
    if (zone.state === 'NOT_AUTORITATIVE') {
        return (<span className="text-warning">{t('dnsPage.NOT_AUTORITATIVE')}</span>)
    }
    if (zone.state === 'IS_AUTORITATIVE') {
        return (<span className="text-success">{t('dnsPage.IS_AUTORITATIVE')}</span>)
    }
    return (<span className="text-alert">{t('dnsPage.NOT_FOUND')}</span>)
}
