import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Button, Col, Divider, Input, message, Row, Spin} from 'antd'
import {Store} from 'antd/lib/form/interface'
import {AppState} from 'common/models'
import profileUser from "./actions/profileUser";
import Form, {useForm} from "antd/lib/form/Form";
import {formItemLayout, tailLayout} from "../../helpers/layoutHelpers";
import Item from "antd/lib/form/FormItem";
import {euPhoneNumberRegex} from "../../helpers/stringHelpers";
import {_parsePhone, _renderPhone} from "../../common/fce";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../components";
import getSelf from "../login/actions/getSelf";
import {ROLE_ACCESS_MANAGER, ROLE_USER_BASE, ROLE_USER_MANAGER, ROLE_USER_ZCOM} from "../../common/LoggedUser";


interface Props {
    forceRender: number     // counter to force render when opened
    setModalVisible: (param: boolean) => void
}

const ProfileForm = (props: Props) => {
    const {t} = useTranslation()
    const [form] = useForm()
    const dispatch = useDispatch()
    const {setModalVisible, forceRender} = props

    const {user, isSaving} = useSelector((state: AppState) => state.user)
    const {self} = useSelector((state: AppState) => state.auth)
    const {fontSize} = useSelector((state: AppState) => state.font)

    const [updateStatus, setUpdateStatus] = useState<boolean>()

    const [isItMe, setIsItMe] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isUserManager, setUserManager] = useState(false)
    const [canEdit, setCanEdit] = useState<boolean>(false)


    let loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator/>
            </div>
        )
    }

    useEffect(() => {
        if (!user) {
            // user is loaded from UserDetailPage
            props.setModalVisible(false)
            return
        }
        setIsItMe(self!.id === user.id)
        const editor = loggedUser!.hasRole(ROLE_USER_BASE) && self!.id === user.id
        setEditor(editor)
        const um = loggedUser!.hasRole(ROLE_USER_ZCOM) || loggedUser!.hasRole(ROLE_USER_MANAGER) || loggedUser!.hasRole(ROLE_ACCESS_MANAGER)
        setUserManager(um)
        setCanEdit(um || editor)
    },[])

    useEffect(() => {
        // when dialog is opened
        form.resetFields()
        setUpdateStatus(false)
    },[forceRender])

    const onFormSubmit = (values: Store): void => {
        if (user && self && user.id) {
            let params = {
                email: values.email,
                name: values.name,
                surname: values.surname,
                phone: _parsePhone(values.phone),
                note: values.note
            }
            if (user.id === self.id) {
                // edit profile
                dispatch(profileUser(params, suc => {
                    if (suc) {
                        message.success(t('general.success'))
                        // reload self
                        dispatch(getSelf('roles,resourceGroups,groups,userAuthentications,authentications,userVerifications,auth_2fa_ips,auth_2fa_authentications', suc => {
                            setModalVisible(false)
                        }))
                    }
                }))
            }
        }
    }

    if (!user) {
        return <Spin/>
    }

    return (
        <Row>
            <Col flex={1}>
                <h2>{user.username}</h2>
            </Col>
            <Col>
                <Divider type='vertical' style={{height: '100%'}}/>
            </Col>
            <Col flex={4}>
                <Form
                    className='UserUpdateForm'
                    onFinish={onFormSubmit}
                    initialValues={{...user, phone: user ? _renderPhone(user.phone) : ''}}
                    onChange={() => setUpdateStatus(true)}
                    {...formItemLayout}
                    form={form}>
                    <Item name='id' style={{display: 'none'}}>
                        <Input type='hidden'/>
                    </Item>

                    <Item name='name' label={t('createUserPage.name')}>
                        <Input size={fontSize} />
                    </Item>

                    <Item name='surname'
                          rules={[{required: true, message: t('loginPage.err_password')}]}
                          label={t('createUserPage.surname')}
                          hasFeedback>
                        <Input size={fontSize} />
                    </Item>

                    <Item name='email'
                          label={t('createUserPage.email')}
                          rules={[{
                              required: false,
                              type: "email",
                              message: t('errors.error_email')
                          }]}
                          hasFeedback>
                        <Input size={fontSize} type='email'/>
                    </Item>

                    <Item name='phone'
                          label={t('createUserPage.phone')}
                          rules={[{
                              pattern: new RegExp(euPhoneNumberRegex),
                              message: t('errors.error_phone')
                          }]}
                          hasFeedback>
                        <Input size={fontSize}/>
                    </Item>

                    <Item name='note' label={t('createUserPage.note')}>
                        <Input size={fontSize}/>
                    </Item>

                    <Item {...tailLayout}>
                        <Button type='primary' htmlType='submit'
                                className='login-form-button'
                                loading={isSaving}
                                disabled={!canEdit || !updateStatus}
                                size={fontSize}>
                            {t('updateUserPage.update')}
                        </Button>
                    </Item>
                </Form>
            </Col>
        </Row>
    )


}
export default ProfileForm
