import React from 'react'
import {Col, Pagination, Row} from "antd";
import {CSVLink} from 'react-csv'
import {Button, message} from 'antd'
import {PAGING} from "../../common/enums";

interface Props {
    filename: string
    data: any,
    total: number
    current: number
    pageSize: number
    fetchRecords: (page: number, pageSize: number) => void
}


const Pager = (props: Props) => {

    const onChange = (page: number, pageSize: number) => {
        console.log('pager1: '+page)
        if ( props.pageSize != pageSize) {
            page = 1
        }
        console.log('pager2: '+page)
        props.fetchRecords(page, pageSize);
    }

    return (
        <Row>
            <Col span={4}>
                <div style={{padding: '5px', fontWeight: 'bold'}} >Total: {props.total}</div>
            </Col>
            <Col span={16} style={{textAlign: 'center'}}>
                <Pagination showQuickJumper
                            showSizeChanger
                            total={props.total}
                            current={props.current}
                            pageSize={props.pageSize}
                            pageSizeOptions={PAGING}
                            onChange={onChange}
                            />
            </Col>
            <Col span={4}>
                <div style={{float: 'right'}}>
                    <Button type="primary" disabled={props.data.length === 0}>
                        {props.data.length && (
                            <CSVLink
                                filename={props.filename}
                                data={props.data}
                                className="btn btn-primary"
                                onClick={()=>{
                                    message.success("The file is downloading").then()
                                }}
                            >
                                Export CSV
                            </CSVLink>
                        )}
                    </Button>
                </div>
            </Col>
        </Row>
    )
}

export default Pager
