import {Tag} from "antd";
import React from "react";


export const renderYes = (name: string, title: string='') => {
    return (<Tag title={title} color='success'>{name}</Tag>)
}

export const renderNo = (name: string, title: string='') => {
    return (<Tag title={title} color='error'>{name}</Tag>)
}

