import React, { useEffect } from 'react'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {Card, Col, Row, Spin, Tag} from "antd"
import { VpsHelper } from 'pages/vpsadmin/models'
import { Link } from 'react-router-dom'
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../components";


const RenderBox = (props) => {
    return (
        <div className={'center'} style={{ width: 180, float: 'left', margin: '10px', padding: '10px', backgroundColor: '#ededed', border: '1px solid #dcdcdc', borderRadius: '5px' }}>
            <div style={{padding: '20px', color: '#666666'}}>
                <span className='title2'>{props.title}</span>
            </div>
            <div>
                <span className='title3' style={{padding: '1px'}}>{props.line}</span>
            </div>
        </div>
    )
}

const VpsDetailComponent = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { vpsServer, vpsServers, hypervisor, isLoadingHypervisor } = useSelector((state: AppState) => state.vpsadmin)

    // const vpsServer = props.vpsServer

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    if (!vpsServer || !hypervisor) {
        return (<Spin />)
    }

    return (
        <Card
            key={vpsServer.id}
            className={'mb8'}
            style={{border: 0}}
            loading={isLoadingHypervisor}
            title={
                <>
                    <Row style={{marginBottom: '10px'}}>
                        <Col span={2} className='right' style={{marginTop: 0}}>
                            <span className='textGray'>{t('vpsadmin.hypervisor')}:</span>
                        </Col>
                        <Col span={8} className='left'>&nbsp;
                            <Link style={{fontWeight: 'bold'}} to={`/vpsadmin/hypervisor/${hypervisor.id}`}>{hypervisor.host}</Link>
                        </Col>

                        <Col span={3} className='center'>
                            <span className='textGray'>{t('vpsadmin.hypervisors.updated')}:</span>
                        </Col>
                        <Col span={7} className='left'>&nbsp;
                            {appSetting.renderFullDateTime(vpsServer.last_update)}
                        </Col>
                        <Col span={4} className='right'>
                            <span className='textGray'>{t('vpsadmin.proxmox')}:</span> {vpsServer.version}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={2} className='right' style={{marginTop: '4px'}}>
                            <span className='textGray'>{t('vpsadmin.vps.title')}:</span>
                        </Col>
                        <Col span={11} className='left'>&nbsp;
                            <Link className='title3' to={`/vpsadmin/vps/${vpsServer.id}`}>{vpsServer.name}</Link>
                        </Col>
                        <Col span={11}>&nbsp;
                            <Tag color={VpsHelper.setStatusColor(vpsServer.state)} >{VpsHelper.convertedState(vpsServer.state)}</Tag>
                        </Col>
                    </Row>
                </>
            }>

            <Row style={{marginTop: '25px', marginBottom: '25px'}}>
                <Col span={24} className='right'>
                    <RenderBox title={t('vpsadmin.cores')} line={vpsServer.vps_id ? `${vpsServer.cpu_occupied}` : `${VpsHelper.usedCpus(vpsServers, vpsServer)} / ${vpsServer.cpu_count}`} />
                    <RenderBox title={t('vpsadmin.vps.ram')} line={vpsServer.vps_id ? `${VpsHelper.transformToGB(vpsServer.ram_total)} GB` : `${VpsHelper.transformToGB(VpsHelper.usedRam(vpsServers, vpsServer))} / ${VpsHelper.transformToGB(vpsServer.ram_total)} GB`} />
                    {
                        vpsServer.storages.map(el => {
                            let storage = vpsServer.containerStorages.find(s => s.storage_id === el.id)
                            if (storage) {
                                let size = vpsServer.vps_id ? `${VpsHelper.transformToGB(storage.disk_size)}` : `${VpsHelper.transformToGB(el.total_size - el.free_space)} / ${VpsHelper.transformToGB(el.total_size)}`
                                return (
                                    <RenderBox title={el.type} line={`${size} GB`} />
                                )
                            }
                        })
                    }
                </Col>
            </Row>
        </Card>
    )
}

export default VpsDetailComponent

