import React, { useEffect } from 'react'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useLoggedUser } from "../../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../../components"
import { AutoComplete, Card, Col, message, Modal, Row, Select, Space, Table } from "antd"
import { PAGING } from "../../../common/enums"
import TotalNum from "../../../components/TotalNum/TotalNum"
import { EditTwoTone, OneToOneOutlined, PlusCircleOutlined } from "@ant-design/icons"
import getCountries from './actions/getCountries'
import { Country } from './models'
import Button from "antd-button-color"
import CountriesForm from './components/CountriesForm/CountriesForm'
import {stopPropagation} from "../../../common/fce";
import {sort_iso} from "../../../common/sorting";
import Highlighter from "react-highlight-words";
import usePageSize from "../../../common/usePageSize";

const { Option } = Select

const CountriesPage = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { countries, isLoading } = useSelector((state: AppState) => state.countriesPage)
    const { currencies } = useSelector((state: AppState) => state.currencies)

    const [dataSource, setDataSource] = useState<Country[]>([])
    const [searchIso, setSearchIso] = useState('')
    const [searchCode, setSearchCode] = useState('')
    const [searchNameCZ, setSearchNameCZ] = useState('')
    const [searchNameEN, setSearchNameEN] = useState('')
    const [searchCurrency, setSearchCurrency] = useState('')
    const [updateId, setUpdateId] = useState<number>(0)
    const [isModalVisible, setModalVisible] = useState(false)
    const [pageSize, setPageSize] = useState<string>()

    // permissions
    const [isEditor, setEditor] = useState(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }

    const appSetting = loggedUser.getAppSettings()
    usePageSize(appSetting, loggedUser.user.id, pageSize)
    const SEARCH_MIN = appSetting.min_search_length

    // logger
    const logger = (msg) => {
        if (appSetting && appSetting.debug) {
            console.log('CountriesPage: ' + msg)
        }
    }

    useEffect(() => {
        // load countries from API
        // trigger ONCE
        if (loggedUser) {
            setEditor(loggedUser.hasAccess('page_billing_country_editor'))
        }
        dispatch(getCountries())
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }
    }, [countries, searchIso, searchCode, searchNameCZ, searchNameEN, searchCurrency, isModalVisible, currencies])

    const refreshGrid = () => {

        let patternIso = ''
        let patternCode = ''
        let patternNameCZ = ''
        let patternNameEN = ''
        let patternCurrency = ''

        if (searchIso) {
            patternIso = searchIso
        }
        if (searchCode) {
            patternCode = searchCode
        }
        if (searchNameCZ) {
            patternNameCZ = searchNameCZ
        }
        if (searchNameEN) {
            patternNameEN = searchNameEN
        }
        if (searchCurrency) {
            patternCurrency = searchCurrency
        }


        const newData = filtered(patternIso, patternCode, patternNameCZ, patternNameEN, patternCurrency).sort(sort_iso)
        logger(`refreshGrid name: ${patternIso}, countries: ${countries?.length}, dataSource: ${newData.length}`)
        setDataSource(newData)
    }


    const filtered = (iso: string, code: string, nameCZ: string, nameEN: string, currency: string) => {

        if (!countries) {
            return []
        }

        const qIso = (h) => h.iso.toLowerCase().includes(iso.toLowerCase())
        const qCode = (h) => h.code.toLowerCase().includes(code.toLowerCase())
        const qNameCZ = (h) => h.name_cz.toLowerCase().includes(nameCZ.toLowerCase())
        const qNameEN = (h) => h.name_en.toLowerCase().includes(nameEN.toLowerCase())
        const qCurrency = (h) => h.currency.toLowerCase().includes(currency.toLowerCase())
        let result;
        result = countries.filter((t) => qIso(t))
        result = result.filter((t) => qCode(t))
        result = result.filter((t) => qNameCZ(t))
        result = result.filter((t) => qNameEN(t))
        result = result.filter((t) => qCurrency(t))
        return result
    }

    const FilterIsoInput = (
        <>
            <AutoComplete
                style={{ width: '100%' }}
                value={searchIso}
                allowClear={true}
                onClick={stopPropagation}
                placeholder={t('countries.columns.iso')}
                onChange={(currValue) => {
                    setSearchIso(currValue)
                }}
            />
        </>
    )

    const FilterCodeInput = (
        <>
            <AutoComplete
                style={{ width: '100%' }}
                value={searchCode}
                allowClear={true}
                onClick={stopPropagation}
                placeholder={t('countries.columns.code')}
                onChange={(currValue) => {
                    setSearchCode(currValue)
                }}
            />
        </>
    )

    const FilterNameCZInput = (
        <>
            <AutoComplete
                style={{ width: '100%' }}
                value={searchNameCZ}
                allowClear={true}
                onClick={stopPropagation}
                placeholder={t('countries.columns.name_cz')}
                onChange={(currValue) => {
                    setSearchNameCZ(currValue)
                }}
            />
        </>
    )
    const FilterNameENInput = (
        <>
            <AutoComplete
                style={{ width: '100%' }}
                value={searchNameEN}
                allowClear={true}
                onClick={stopPropagation}
                placeholder={t('countries.columns.name_en')}
                onChange={(currValue) => {
                    setSearchNameEN(currValue)
                }}
            />
        </>
    )

    const FilterCurrencyInput = (
        <>
            <Select
                style={{ width: 200 }}
                placeholder={t('countries.columns.currency')}
                allowClear={true}
                onChange={(currValue) => {
                    setSearchCurrency(currValue)
                }} >
                {
                    <>
                        {currencies!.map((value, index) => {
                            console.log(value)
                            if (value) {
                                return <Option value={value} key={index}>{value}</Option>
                            }

                        })}
                    </>
                }
            </Select>
        </>
    )


    const columns = [

        {
            title: FilterIsoInput,
            dataIndex: 'iso',
            key: 'iso',
            width: '10%',

        },
        {
            title: FilterCodeInput,
            dataIndex: 'code',
            key: 'code',
            className: 'left',
            width: '10%'
        },
        {
            title: FilterNameCZInput,
            dataIndex: 'name_cz',
            key: 'name_cz',
            className: 'left',
            width: '35%',
            render: (text: string) => (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        color: '#2d9259',
                        padding: 0,
                    }}
                    searchWords={[searchNameCZ]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ),
        },
        {
            title: FilterNameENInput,
            dataIndex: 'name_en',
            key: 'name_en',
            className: 'left align',
            width: '35%',
            render: (text: string) => (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        color: '#2d9259',
                        padding: 0,
                    }}
                    searchWords={[searchNameEN]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ),
        },
        {
            title: FilterCurrencyInput,
            dataIndex: 'currency',
            key: 'currency',
            className: 'left align',
            width: '10%'
        },
        {
            title: 'Action',
            key: 'action',
            width: '100px',
            dataIndex: 'action',
            render: (_, record: Country) => (
                <Space size='small'>
                    <Button type='text'
                        size='small'
                            disabled={!isEditor}
                        onClick={() => {
                            record.id && setUpdateId(record.id)
                            setModalVisible(true)
                        }}
                        icon={<EditTwoTone twoToneColor='green' />}
                    />

                </Space>
            ),
        },

    ]



    return (
        <>
            <Card className='CountryPage'
                title={
                    <Row>
                        <Col span={6}>
                            <OneToOneOutlined />{` ${t('countries.title')}`}
                        </Col>
                    </Row>}


            >
                {
                    appSetting &&
                    <Table<Country>
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columns}
                        loading={isLoading}
                        dataSource={dataSource}
                        rowKey='id'
                        pagination={{
                            defaultPageSize: appSetting?.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => { setPageSize(`${ev.pageSize}`) }}
                        footer={() => TotalNum(Number(dataSource?.length), 'Countries', dataSource)}
                    />
                }
            </Card>
            <Modal
                destroyOnClose
                style={{ top: 20 }}
                width={800}
                title={
                    <>
                        {updateId > 0 ? t('countries.update-title') : t('countries.create-title')}
                    </>
                }
                visible={isModalVisible}
                onCancel={() => {
                    setUpdateId(0)
                    setModalVisible(false)
                }}
                footer={null}
                maskClosable={false}
                confirmLoading={true}>
                {<CountriesForm updateId={updateId} setModalVisible={setModalVisible} />}
            </Modal>
        </>
    )
}

export default CountriesPage