import {
    RemoveIpRequestAction,
    RemoveIpResponseAction,
    RemoveIpErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {AddIpParams, IpAddressModel} from '../models'

export default (
    params: AddIpParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | RemoveIpRequestAction
                | RemoveIpResponseAction
                | RemoveIpErrorAction,
        ) => void,
    ) => {
        const request = new RemoveIpRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<IpAddressModel>(`/ip/ip/${params.id}/remove-ip`, params)
            .then((response) => {
                dispatch(new RemoveIpResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new RemoveIpErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
