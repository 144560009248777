import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Input, Row, Select} from "antd";
import {renderScope} from "./SettingItem";
import {SettingsDetails} from "../settings/models";
import LoggedUser from "../../common/LoggedUser";
import {sort_label} from "../../common/sorting";
import {SUPPORTED_LANGUAGES} from "../../i18n";
import moment from "moment";



const PanelLanguage = () => {
    const { i18n } = useTranslation()
    const {t} = useTranslation()

    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [valueString, setValueString] = useState<string>(i18n.language)
    const [langOptions, setLangOptions] = useState<{label: string, value: string, key: string}[]>([])

    useEffect(() => {
        setLangOptions(SUPPORTED_LANGUAGES.map(c => ({value: c, key: c, label: t(`languageSwitcher.${c}`)})).sort(sort_label))
    }, []);

    const changeLanguage = (language: string) => {
        setValueString(language)
    }

    const saveSettings = () => {
        i18n.changeLanguage(valueString, () => {
            moment.locale(i18n.language)
        })
    }

    return (
        <Card title={t(`usersPage.menu_lang_settings`)} className='fblock' style={{marginTop: 0}}>
            <Row className='settingItem'>
                <Col span={10} className='labelCol'><span className='label'> {t(`settingHelp.language`)} </span> </Col>
                <Col span={10} className='valueCol'>
                    <Select style={{width: '120px'}}
                            options={langOptions}
                            value={valueString}
                            onChange={(value) => {changeLanguage(value); setUpdateStatus(true)}}
                    >
                    </Select>

                </Col>
                <Col span={4} className='btnCol'>
                    <Button size='small' type='primary'
                            onClick={() => saveSettings()}
                            disabled={valueString === i18n.language}>{t('general.save')}</Button>
                </Col>
                <Col span={20}>
                    <span className='textGray'>{t(`settingHelp.language_desc`)}</span>
                </Col>
                <Col span={4} className='scopeCol'>{renderScope('user')}</Col>
            </Row>

        </Card>
    )
}

export default PanelLanguage