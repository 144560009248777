import {
    ResetAllRequestAction,
    ResetAllResponseAction,
    ResetAllErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | ResetAllRequestAction
                | ResetAllResponseAction
                | ResetAllErrorAction,
        ) => void,
    ) => {
        const request = new ResetAllRequestAction()
        dispatch(request)

        protectedApiClient
            .delete(`/user/${id}/reset`)
            .then((response) => {
                dispatch(new ResetAllResponseAction(request))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new ResetAllErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
