import React, {useState, useEffect, useRef} from 'react'
import {Card, Button, Modal, message, Table, Tooltip, Space} from 'antd'
import {PicCenterOutlined, PlusCircleOutlined} from '@ant-design/icons/lib/icons'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {RackModel} from './models'
import RackForm from 'pages/datacenter/rack/RackForm'
import updateRack from './actions/updateRack'
import deleteRack from './actions/deleteRack'
import createRack from './actions/createRack'
import getRacks from './actions/getRacks'
import {
    DeleteTwoTone,
    EditTwoTone,
    ExclamationCircleOutlined,
    InfoCircleTwoTone
} from "@ant-design/icons";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import TotalNum from "../../../components/TotalNum/TotalNum";
import {sort_name} from "../../../common/sorting";
import {AppState} from "../../../common/models";
import getDatacenters from "../datacenter/actions/getDatacenters";
import HistoryModal from "../../../components/History/HistoryModal";

const { confirm } = Modal

const RackPage = () => {
    const CONTROL_NAME = 'page_datacenter_rack'
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {racks, isLoading} = useSelector((state: AppState) => state.rack)
    const {datacenter_racks} = useSelector((state: AppState) => state.sidenav)

    const [dataToUpdate, setDataToUpdate] = useState<RackModel>()
    const [isModalVisible, setModalVisible] = useState<boolean>(false)

    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [dataSource, setDataSource] = useState<RackModel[]>([])

    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [isEditor, setEditor] = useState(false)

    // get settings and logged user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    let triggerOneTimer = 0

    useEffect(() => {
        // trigger ONCE
        triggerOneTimer = Date.now().valueOf()
        setEditor(loggedUser.hasAccess('page_datacenter_rack_edit_button'))
        dispatch(getRacks())
        dispatch(getDatacenters())
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }
    }, [racks, isModalVisible])

    useEffect(() => {
        // trigger from Side menu clicked
        if (!isLoading) {
            const when = triggerOneTimer + 1000
            if (when < Date.now().valueOf()) {
                // when triggerOneTimer was not called (after 1sec. of SideMenu click)
                dispatch(getRacks())
                dispatch(getDatacenters())
            }
        }
    }, [datacenter_racks])

    const refreshGrid = () => {
        setDataSource(racks.sort(sort_name))
    }

    const hideModal = () => setModalVisible(false)
    const showModal = () => setModalVisible(true)

    const onFinish = (values: RackModel) => {
        dataToUpdate ?
            dispatch(updateRack(
                {...values, id: dataToUpdate?.id},
                isOk => {
                    isOk && message.success(t('RackPage.updated'))
                    hideModal()
                }
            )) :
            dispatch(createRack(
                {...values},
                isOk => {
                    isOk && message.success(t('RackPage.created'))
                    hideModal()
                }
            ))
    }

    const showConfirmDelete = (obj: RackModel, title: string) => {
        if (!obj) {
            return
        }
        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}}/>,
                title: title,
                content: <p>{obj.name}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: {loading: confirmDelete},
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteRack(obj.id, suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const columns = [
        {
            title: t('RackPage.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('RackPage.datacenter_id'),
            dataIndex: 'datacenter.name',
            key: 'datacenter.name',
            render: (_, record: RackModel) => record.datacenter?.name
        },
        {
            title: t('RackPage.u_count'),
            dataIndex: 'u_count',
            key: 'u_count', },
        {
            title: t('RackPage.action'),
            key: 'action',
            width: '100px',
            render: (_, record: RackModel) => (
                <Space size='small'>
                    {
                        isEditor && (
                            <Button type='text'
                                    size='small'
                                    onClick={() => {
                                        setDataToUpdate(record)
                                        setModalVisible(true)
                                    }}
                                    className='actionButton'
                                    icon={<EditTwoTone twoToneColor='green' />}
                            />
                        )
                    }

                    {
                        isEditor && (
                            <Button type='text' danger size='small'
                                    className='actionButton'
                                    onClick={() => showConfirmDelete(record, t('RackPage.confirm_delete'))}
                                    icon={<DeleteTwoTone twoToneColor='red'/>} />
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(record.id)
                                setHistoryTitle(record.name)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                </Space>
            ),
        },
    ]

    return (
        <>
            <Card className='RackPage'
                title={<>
                    <PicCenterOutlined/> &nbsp; {t('RackPage.title')}</>}
                extra={
                    <Button type='primary'
                        onClick={() => {
                            setDataToUpdate(undefined)
                            setModalVisible(true)
                        }}
                    >
                        <PlusCircleOutlined/> {t('RackPage.create')}
                    </Button>}
                >

                {
                    appSetting &&
                    <Table<RackModel>
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columns}
                        loading={isLoading}
                        dataSource={dataSource}
                        className='inventoryPage'
                        rowKey='id'
                        pagination={false}
                        footer={() => TotalNum(Number(dataSource?.length), 'Racks', dataSource)}
                    />
                }
            </Card>

            <Modal
                destroyOnClose
                style={{top: 20}}
                width={600}
                title={<>
                    <PicCenterOutlined/> &nbsp; {dataToUpdate ? t('RackPage.update-title') : t('RackPage.create-title')}</>}
                visible={isModalVisible}
                onCancel={hideModal}
                footer={null}
                confirmLoading={true}
            >
                <RackForm dataToUpdate={dataToUpdate} onFinish={onFinish}/>
            </Modal>

            <HistoryModal service='datacenter' model='Rack'
                          modelId={historyModelId}
                          title={historyTitle}
                          isModalVisible={isHistoryModalVisible}
                          setModalVisible={() => setHistoryModalVisible(false)}
                          modalRender={(modal) => (
                              <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                  <div ref={draggleRef}>{modal}</div>
                              </Draggable>
                          )}
            />
        </>
    )
}

export default RackPage