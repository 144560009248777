import {ReloadTokenErrorAction, ReloadTokenRequestAction, ReloadTokenResponseAction} from './index'


export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | ReloadTokenRequestAction
                | ReloadTokenResponseAction
                | ReloadTokenErrorAction,
        ) => void,
    ) => {
        const request = new ReloadTokenRequestAction()
        dispatch(request)

        const token = localStorage.getItem('currentUser')
        if (token && token.length > 8) {
            dispatch(new ReloadTokenResponseAction(request, token))
            cb && cb(true)
        }
        else {
            dispatch(new ReloadTokenErrorAction(request, 'token not found'))
            cb && cb(false)
        }
    }
}