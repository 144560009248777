import React, {ReactNode} from 'react'
import {Col, Modal, Row} from "antd";
import {CSVLink} from 'react-csv'
import {Button, message} from 'antd'
import {InfoCircleOutlined} from "@ant-design/icons";
import Draggable from "react-draggable";
import HistoryLog from "./HistoryLog";
import {useTranslation} from "react-i18next";

interface Props {
    title?: string
    modalRender: (node:ReactNode) =>  ReactNode | undefined
    isModalVisible: boolean
    setModalVisible?: (visible: boolean) => void
    service?: string
    model?: string
    modelId?: number
    forceUpdateId?: number   // force update after saving item (just increment it to update)
}

const HistoryModal = (props: Props) => {
    const {t} = useTranslation()

    return (
        <Modal
            title={ (<div style={{width: '100%', cursor: 'move'}}><InfoCircleOutlined />&nbsp;{t('general.history')}: {props.title}</div>)}
            destroyOnClose
            style={{top: 50}}
            bodyStyle={{height: '60%', minHeight: 450, padding: 2}}
            width='60%'
            className='historyLogModal'
            visible={props.isModalVisible}
            onCancel={() => props.setModalVisible && props.setModalVisible(false)}
            maskClosable={false}
            modalRender={props.modalRender}
            footer={null}>

            <HistoryLog  service={props.service}
                         model={props.model}
                         modelId={props.modelId}
                         forceUpdateId={props.forceUpdateId}
                         isModal={true}
                         showTitle={false} />
        </Modal>
    )
}

export default HistoryModal