import {
    GetCustomerPricelistResponseAction,
    GetCustomerPricelistErrorAction,
    GetCustomerPricelistRequestAction,
} from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {PricelistDetails} from "../../pricelist/models";


export default (customer_id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetCustomerPricelistRequestAction
                | GetCustomerPricelistResponseAction
                | GetCustomerPricelistErrorAction,
        ) => void,
    ) => {
        const request = new GetCustomerPricelistRequestAction(customer_id)
        dispatch(request)

        protectedApiClient
            .get<PricelistDetails>(`/customer/${customer_id}/pricelist`)
            .then((response) => {
                dispatch(new GetCustomerPricelistResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetCustomerPricelistErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
