import {
    UserGroupRemoveUserFromGroupRequestAction,
    UserGroupRemoveUserFromGroupErrorAction,
    UserGroupRemoveUserFromGroupResponseAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { UserGroupModel} from '../models'
import {AddDeleteUserToGroupParams} from "../../users/models";


export default (
    params: AddDeleteUserToGroupParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | UserGroupRemoveUserFromGroupRequestAction
                | UserGroupRemoveUserFromGroupErrorAction
                | UserGroupRemoveUserFromGroupResponseAction,
        ) => void,
    ) => {
        const request = new UserGroupRemoveUserFromGroupRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<UserGroupModel>(`/user-group/${params.id}/remove-user`, params)
            .then((response) => {
                dispatch(new UserGroupRemoveUserFromGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UserGroupRemoveUserFromGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
