import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {DeleteResGroupErrorAction, DeleteResGroupRequestAction, DeleteResGroupResponseAction} from "./index";


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | DeleteResGroupRequestAction
                | DeleteResGroupResponseAction
                | DeleteResGroupErrorAction,
        ) => void,
    ) => {
        const request = new DeleteResGroupRequestAction(id)
        dispatch(request)

        protectedApiClient
            .delete(`/resource-group/${id}`)
            .then((response) => {
                dispatch(new DeleteResGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteResGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
