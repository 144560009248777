import {ActionType, DbServiceActions} from './actions'
import {AsyncActionMode } from 'common/models'
import {DbServiceModel, DatabaseModel, DbUserModel, DatabasesPageState} from './models'


const RDB_SERVICE_INITIAL_STATE: DatabasesPageState = {
    isSavingService: false,
    isSavingDatabase: false,
    isSavingUser: false,
    isLoadingService: false,
    isLoadingDatabase: false,
    isLoadingUser: false,
    service: undefined,
    services: [],
    database: undefined,
    databases: [],
    user: undefined,
    users: [],
    drivers: [],
    daemons: [],
    error: ''
}

export default (state = RDB_SERVICE_INITIAL_STATE, action: DbServiceActions): DatabasesPageState => {
    switch (action.type) {
        case ActionType.GET_DB_SERVICE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return {...state, isLoadingService: true}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    service: action.data,
                    isLoadingService: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingService: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_DB_SERVICES:
            if (action.mode === AsyncActionMode.REQUEST) {
                return {...state, isLoadingService: true}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    services: action.data,
                    isLoadingService: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingService: false,
                    error: action.error,
                }
            }
            break

        case ActionType.CREATE_DB_SERVICE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return {...state, isSavingService: true}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    service: action.data,
                    isSavingService: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSavingService: false,
                    error: action.error,
                }
            }
            break

        case ActionType.UPDATE_DB_SERVICE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return {...state, isSavingService: true}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    service: action.data,
                    isSavingService: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSavingService: false,
                    error: action.error,
                }
            }
            break

        case ActionType.DELETE_DB_SERVICE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return {...state, isSavingService: true}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    service: undefined,
                    isSavingService: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSavingService: false,
                    error: action.error,
                }
            }
            break



    }

    return state
}