import {
    GetZoneRequestAction,
    GetZoneResponseAction,
    GetZoneErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DnsZoneModel, GetZoneParams } from '../models'


export default (service_id: number, zone: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetZoneRequestAction
                | GetZoneResponseAction
                | GetZoneErrorAction
        ) => void
    ) => {
        const request = new GetZoneRequestAction(service_id)
        dispatch(request)
        protectedApiClient
            .get<DnsZoneModel>(`/dns/service/${service_id}/record?zone=${zone}`)
            .then((response) => {
                dispatch(new GetZoneResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetZoneErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
