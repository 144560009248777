import React, {useEffect, useState} from 'react'
import {Button, Form, Input} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {Store} from 'antd/lib/form/interface'
import TextArea from "antd/lib/input/TextArea";


interface Props {
    comment?: string
    setModalVisible: (param: boolean) => void
    saveComment: (comment: string) => void
}

const {Item} = Form

const CommentForm = ({comment, setModalVisible, saveComment}: Props) => {

    const [form] = useForm()
    const {t} = useTranslation()
    const noteRef = React.useRef<any>(null)

    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(false)
        if (noteRef.current) {
            noteRef.current.focus();
        }
    }, []);

    const onFormSubmit = (values: Store) => {
        // save data in parent page
        setLoading(true)
        saveComment(values.comment)
    }

    return (
        <Form
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={{comment: comment}}
        >
            <Item name='comment'>
                <TextArea rows={5} ref={noteRef}
                          data-gramm="false"
                          data-gramm_editor="false"
                          data-enable-grammarly="false" />
            </Item>

            <Item {...tailLayout}>
                <Button type='primary' htmlType='submit'
                        loading={loading}
                        disabled={!updateStatus}>
                    { t('general.update') }
                </Button>
            </Item>

        </Form>
    )
}

export default CommentForm
