import React, {useContext, useEffect} from 'react'
import {
    Col,
    Form,
    Row,
    Space,
    Card,
    Modal,
    Table,
    Select,
    Input,
    Divider,
    message,
    InputNumber, Spin, Tooltip,
} from 'antd'
import { formItemLayout } from 'helpers/layoutHelpers'
import { Link, RouteComponentProps, useParams } from 'react-router-dom'
import { useForm } from 'antd/lib/form/Form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'common/models'
import './BillsPage.scss'
import getBill from './actions/getBill'
import moment from 'moment'
import {useState} from 'react'
import Button from 'antd-button-color'
import {CompanyDetails} from 'pages/company/models'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import {ExclamationCircleTwoTone, FileDoneOutlined} from "@ant-design/icons";
import {TaxDetails} from "../tax/models";
import BillAttachment from "../../../components/BillVat/BillAttachment/BillAttachments";
import TextArea from "antd/lib/input/TextArea";
import updateBill from "./actions/updateBill";
import updateBillVatSummary from "./actions/updateBillVatSummary";
import {FormInstance} from "antd/lib/form";
import AppSetting from "../../../common/AppSettings";
import BillCostAllocationCtrl from "./BillCostAllocationCtrl";
import createBillVatSummary from "./actions/createBillVatSummary";
import getCountries from "../../../components/SelectCountries/actions/getCountries";
import getTaxes from "../tax/actions/getTaxes";
import {useHistory} from "react-router";
import HistoryLog from "../../../components/History/HistoryLog";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {sort_rate} from "../../../common/sorting";
import DateInput from "../../../components/RangeFilter/DateInput";
import {BillVatSummaryModel} from "./models";
import AlertBox from "../../../components/popconfirm/AlertBox";
import {_parsePrice, decEn} from "../../../common/fce";
import getBillCostAllocationByBill from "./actions/getBillCostAllocationByBill";
import deleteBillVatSummary from "./actions/deleteBillVatSummary";
import tableCompanies from "../../login/actions/tableCompanies";

const { confirm } = Modal

interface ParamTypes {
    id: string
}

interface Item {
    key: number
    bill_id: number
    tax_id: number
    tax_rate: number
    total_without_vat: number
    total_vat: number
    total_with_vat: number
}

interface EditableRowProps {
    index: number;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    appSetting: AppSetting;
    handleSave: (record: Item, isDelete: boolean) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                       title,
                                                       editable,
                                                       children,
                                                       dataIndex,
                                                       record,
                                                       appSetting,
                                                       handleSave,
                                                       ...restProps
                                                   }) => {

    //const inputRef = useRef<InputRef>(null);
    let childNode = children;
    const form = useContext(EditableContext)!
    const inputRef: React.Ref<HTMLInputElement> = React.createRef()

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        // initialize cell
        if (editing && record) {
            if (dataIndex === 'total_without_vat') {
                if (inputRef.current) {
                    inputRef.current.value = record.total_without_vat ? appSetting.renderPrice(record.total_without_vat) : '0'
                    inputRef.current.focus()
                }
            }
            if (dataIndex === 'total_with_vat') {
                if (inputRef.current) {
                    inputRef.current.value = record.total_with_vat ? appSetting.renderPrice(record.total_with_vat) : '0'
                    inputRef.current.focus()
                }
            }
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: appSetting.renderPrice(record[dataIndex])});
    }

    const saveCell = (e) => {
        const name = e.target.id
        try {
            const values = form.getFieldsValue()
            toggleEdit()
            if (name === 'total_without_vat') {
                let value = values['total_without_vat']
                let total_without_vat = 0
                if (typeof value === 'string') {
                    total_without_vat = _parsePrice(value)
                }
                if (typeof value === 'number') {
                    total_without_vat = Math.round(value*100)
                }
                if (record.total_without_vat === 0) {
                    // insert
                    handleSave({ ...record, total_without_vat: total_without_vat, total_vat: 0, total_with_vat: 0 }, false)
                    return
                }

                // edit
                if (total_without_vat > 0) {
                    form.setFieldsValue( {"total_without_vat": appSetting.renderPrice(total_without_vat)})
                    if (record.total_without_vat != total_without_vat) {
                        // edit total_without_vat
                        handleSave({ ...record, total_without_vat: total_without_vat}, false)
                        return
                    }
                }
                else {
                    // delete
                    form.setFieldsValue( {"total_without_vat": 0})
                    form.setFieldsValue( {"total_vat": 0})
                    form.setFieldsValue( {"total_with_vat": 0})
                    if (record.total_without_vat != 0) {
                        // if changed
                        handleSave({ ...record, total_without_vat: 0, total_vat: 0, total_with_vat: 0}, true)
                        return
                    }
                }
            }

            if (name === 'total_with_vat') {
                let value = values['total_with_vat']
                let total_with_vat = 0
                if (typeof value === 'string') {
                    total_with_vat = _parsePrice(value)
                }
                if (typeof value === 'number') {
                    total_with_vat = Math.round(value*100)
                }
                if (record.total_with_vat === 0) {
                    // insert
                    handleSave({ ...record, total_without_vat: 0, total_vat: 0, total_with_vat: total_with_vat}, false)
                    return
                }

                // edit
                if (total_with_vat > 0) {
                    form.setFieldsValue( {"total_with_vat": appSetting.renderPrice(total_with_vat)})
                    if (record.total_with_vat != total_with_vat) {
                        // if changed
                        handleSave({ ...record, total_with_vat: total_with_vat}, false)
                        return
                    }
                }
                else {
                    form.setFieldsValue( {"total_without_vat": 0})
                    form.setFieldsValue( {"total_vat": 0})
                    form.setFieldsValue( {"total_with_vat": 0})
                    if (record.total_with_vat != 0) {
                        // if changed
                        handleSave({ ...record, total_without_vat: 0, total_vat: 0, total_with_vat: 0}, true)
                        return
                    }
                }
            }

        }
        catch (errInfo) {
            console.log('Save failed:', errInfo)
        }
    };

    if (editable) {
        childNode = editing ? (
            <Form.Item name={dataIndex}
                style={{ margin: 0 }}
                rules={[{required: true, message: `${title} is required.`}]}
            >
                <InputNumber ref={inputRef}
                             onPressEnter={(e) => saveCell(e)}
                             onBlur={(e) => saveCell(e)}
                             onFocus={(event) => event.target.select()}
                             decimalSeparator=','
                              />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 2 }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};


const UpdateBillPage = (props: RouteComponentProps) => {
    const CONTROL_NAME = 'page_billing_bill_detail'
    const {t} = useTranslation()
    const [form] = useForm()
    const {id} = useParams<ParamTypes>()
    const dispatch = useDispatch()
    const history = useHistory()

    const {taxes} = useSelector((state: AppState) => state.tax)
    const {companies } = useSelector((state: AppState) => state.auth.tables)
    const {countries} = useSelector((state: AppState) => state.countries)
    const {currencies} = useSelector((state: AppState) => state.currencies)
    const {bill, isLoading, attachments, isSaving} = useSelector((state: AppState) => state.bill)
    const {vatSummaries, isVatSummaryLoading} = useSelector((state: AppState) => state.bill)

    const [localTaxes, setLocalTaxes] = useState<TaxDetails[]>([])
    const [vatSource, setVatSource] = useState<BillVatSummaryModel[]>([])
    const [currentCompany, setCurrentCompany] = useState<CompanyDetails | undefined>(undefined)
    const [searchedCompany, setSearchedCompany] = useState<string>('')
    const [totalSummary, setTotalSummary] = useState<number>(0)
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [forceUpdateId, setForceUpdateId] = useState<number>(0)
    const [openModalWarning2, setOpenModalWarning2] = useState(false)
    const [openModalWarning1, setOpenModalWarning1] = useState(false)
    const [showWarningPopup, setShowWarningPopup] = useState(false)
    const [roundingMark, setRoundingMark] = useState(false)
    const [amountDue, setAmountDue] = useState(0)
    const [amountDiff, setAmountDiff] = useState(0)


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('UpdateBillPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('UpdateBillPage: ' + msg)} }

    useEffect(() => {
        // if it is not loaded
        // we load it
        if (!companies || companies.length === 0) {
            dispatch(tableCompanies())
            return
        }
        if (!taxes || taxes.length === 0) {
            dispatch(getTaxes())
            return
        }
        if (!countries) {
            dispatch(getCountries())
        }

        if (parseInt(id) > 0) {
            const bid = parseInt(id)
            dispatch(getBill(bid, (suc) => {
                if (!suc) {
                    history.replace('/billing/bill')
                    message.error(t('general.error'))
                }
            }))
        }
        else {
            // failover 404
            history.replace('/billing/bill')
        }
    }, [id, companies, taxes])

    useEffect(() => {
        if (bill && bill.id === parseInt(id)) {
            // loaded
            setLocalTaxes([])
            setAmountDue(bill.amount_due)
            let total_with_vat = bill!.total_with_vat
            if (total_with_vat === 0) {
                total_with_vat = bill!.total_without_vat + bill!.total_vat
            }
            setAmountDiff(Math.abs(total_with_vat - bill.amount_due))
            logger('useEffect bill id='+id)
            setHistoryModelId(bill.id)
            const company = companies.find(c => c.id === bill.company_id)
            logger('company id='+company!.id)
            setCurrentCompany(company)
            if (company) {
                const data = getLocalTaxes(company)
                logger('data length2='+data.length)
                setLocalTaxes(data)
            }
            loadHistory()
            setShowWarningPopup(showWarning())
        }
    }, [bill])

    useEffect(() => {
        // generate VAT ranges for grid
        if (bill && bill.id === Number(id)) {
            if (localTaxes && localTaxes.length) {
                logger('data localTaxes='+localTaxes.length)
                let totalWithoutVat = 0
                const data = getVatSource(bill.id, localTaxes)
                setVatSource(data.sort(sort_rate))
                vatSummaries.forEach((vat, idx) => {
                    totalWithoutVat += vat.total_without_vat
                })

                // update totalSummary
                setTotalSummary(totalWithoutVat)
            }
        }
    }, [vatSummaries.length, localTaxes, localTaxes.length])

    useEffect(() => {
        if (bill && bill.amount_due === 0) {
            let totalWithVat = 0
            vatSummaries.forEach((vat, idx) => {
                totalWithVat += vat.total_with_vat
            })
            setAmountDue(totalWithVat)
            form.setFieldsValue({'amount_due': appSetting.renderPrice(totalWithVat) })
            setAmountDiff(0)
            setRoundingMark(false)
        }
    }, [totalSummary])

    useEffect(() => {
        setShowWarningPopup(showWarning())
    }, [attachments.length])

    useEffect(() => {
        if (currentCompany) {
            const c = countries.find(c => c.iso === currentCompany.country)
            if (c) {
                form.setFieldsValue( {"currency": c.currency})
            }
            else {
                form.setFieldsValue( {"currency": undefined})
            }
        }
    }, [currentCompany])

    const getLocalTaxes = (company: CompanyDetails): TaxDetails[] => {
        const data = taxes.filter(t => t.country === company.country)
        logger('data length='+data.length + ' country: ' + company.country)
        let zero = data.find(t => t.rate === 0)
        if (!zero) {
            // add zero
            zero = {id: 84, name: 'zero', rate: 0, valid_from: '2020-01-01', valid_to: '2222-01-01', country: company.country}
            data.push(zero)
        }
        return data
    }

    const getVatSource = (billId: number, localTaxes: TaxDetails[]) => {
        return localTaxes.map(tx => {
            const bs = vatSummaries.find(b => b.tax_id === tx.id)
            if (bs) {
                // tax record
                return {...bs, tax_rate: localTaxes.find(t => t.id === bs.tax_id)?.rate}
            } else {
                // empty record
                return {
                    bill_id: billId,
                    tax_id: tx.id,
                    tax_rate: tx.rate,
                    total_without_vat: 0,
                    total_vat: 0,
                    total_with_vat: 0,
                    rounding_difference: 0
                }
            }
        })
    }

    const onChangeCompany = (value: number, option: any) => {
        const c = companies.find(c => c.id === value)
        setCurrentCompany(c)
    }

    const handleSaveVAT = (vat: BillVatSummaryModel, isDelete: boolean) => {
        // save VatSummary
        logger('handleSaveVAT: ', vat)
        if (vat.id) {
            logger('updateBillVatSummary: '+ JSON.stringify(vat))
            if (isDelete) {
                dispatch(deleteBillVatSummary(vat.id, suc => {
                    dispatch(getBill(bill!.id))
                    dispatch(getBillCostAllocationByBill(bill!.id))
                }))
            }
            else {
                dispatch(updateBillVatSummary(vat, suc => {
                    dispatch(getBill(bill!.id))
                    dispatch(getBillCostAllocationByBill(bill!.id))
                }))
            }
        }
        else {
            // insert
            logger('createBillVatSummary: '+ JSON.stringify(vat))
            dispatch(createBillVatSummary(vat, suc => {
                dispatch(getBill(bill!.id))
                dispatch(getBillCostAllocationByBill(bill!.id))
            }))
        }
    }

    const loadHistory = () => {
        bill && setForceUpdateId(forceUpdateId+1)	// update history
    }

    const showWarning = (): boolean => {
        if (!attachments || attachments.length === 0) {
            return true
        }
        return false
    }

    const handleSubmit = (values) => {
        if (!bill)
            return

        // onChange={(n) => setAmountDue(_parsePrice(`${n}`))}
        let totalWithoutVat = 0
        let totalVat = 0
        let totalWithVat = 0
        vatSummaries.forEach((vat, idx) => {
            totalWithoutVat += vat.total_without_vat
            totalVat += vat.total_vat
            totalWithVat += vat.total_with_vat
        })

        // do not save it
        if (totalWithoutVat === 0) {
            // check abs value
            let absTotal = 0
            vatSummaries.forEach((vat, idx) => {
                absTotal += Math.abs(vat.total_with_vat)
            })
            if (absTotal === 0) {
                message.error(t('billing.bill.error.price0'))
                return
            }
        }

        const params = {
            ...values,
            id: bill!.id,
            date_of_issue: appSetting.renderDateSQL(values.date_of_issue),
            date_of_maturity: appSetting.renderDateSQL(values.date_of_maturity),
            date_of_taxing: appSetting.renderDateSQL(values.date_of_taxing),
            amount_due: _parsePrice(`${values.amount_due}`)
        }
        saveBill(params)
    }

    const saveBill = (params) => {
        logger('saveBill: ' + JSON.stringify(params))
        const open_new = params.open_new
        params.hasOwnProperty('open_new') && delete params['open_new']
        dispatch(updateBill(params, (suc) => {
                if (suc) {
                    message.success(t('general.success'))
                    setOpenModalWarning1(false)
                    setOpenModalWarning2(false)
                    if (open_new === 1) {
                        history.push('/billing/bill/create')
                    }
                    else {
                        loadHistory()
                    }
                }
            }
        ))
    }

    const submitForm = (n: number) => {
        if (form) {
            form.setFieldsValue({open_new: n})
            form.submit()
        }
    }

    const initForm = () => {
        if (!bill)
            return {}

        return {
            ...bill,
            date_of_issue: moment(bill.date_of_issue),
            date_of_maturity: moment(bill.date_of_maturity),
            date_of_taxing: moment(bill.date_of_taxing),
            total_vat: appSetting.renderPrice(bill.total_vat),
            total_with_vat: appSetting.renderPrice(bill.total_with_vat),
            total_without_vat: appSetting.renderPrice(bill.total_without_vat),
            amount_due: bill.amount_due / 100,
            open_new: 0
        }
    }

    const getCompanyOptions = () => {
        return companies.map(i => ({
            value: i.id,
            key: i.id,
            label: i.name
        }))
    }

    const handleFormatter = e => {
        let v = decEn(`${e.target.value}`)
        let amount = _parsePrice(v)
        form.setFieldsValue({'amount_due': amount/100})
        let total_with_vat = bill!.total_with_vat
        if (total_with_vat === 0) {
            total_with_vat = bill!.total_without_vat + bill!.total_vat
        }
        setAmountDiff(Math.abs(total_with_vat - amount))
        setRoundingMark(Math.abs(total_with_vat - amount) > 49)
        setAmountDue(amount)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }

    const defaultColumns = [
        {
            title: t('billing.bill.tax_rate'),
            dataIndex: 'tax_rate',
            key: 'tax_rate',
            width: 60,
            render: (p: number) => appSetting.renderPercent(p)
        },
        {
            title: t('billing.bill.total_without_vat'),
            dataIndex: 'total_without_vat',
            key: 'total_without_vat',
            className: 'right',
            width: 90,
            editable: true,
            render : (price: number) => appSetting?.renderPrice(price)
        },
        {
            title: t('billing.bill.total_vat'),
            dataIndex: 'total_vat',
            key: 'total_vat',
            className: 'right',
            width: 90,
            render: (p: number) => appSetting.renderPrice(p)
        },
        {
            title: t('billing.bill.total_with_vat'),
            dataIndex: 'total_with_vat',
            key: 'total_with_vat',
            className: 'right',
            width: 90,
            editable: true,
            render: (p: number) => appSetting.renderPrice(p)
        }
    ]

    const columns = defaultColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: BillVatSummaryModel) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                appSetting: appSetting,
                handleSave: handleSaveVAT
            }),
        }
    })

    if (!bill) {
        // loading Bill
        return (
            <Card className='UpdateBillPage'
                title={
                    <Row>
                        <Col span={3}>
                            <FileDoneOutlined /> &nbsp; {t('billing.bill.edit-title')}
                        </Col>
                        <Col span={21}>&nbsp;</Col>
                    </Row>
                }
            >
                <Row style={{marginTop: '35px'}}>
                    <Col span={24} className='center'>
                        <Spin />
                    </Col>
                </Row>
            </Card>)
    }

    return (
        <>
            <Card className='UpdateBillPage'
                title={
                    <Row>
                        <Col span={4}>
                            <FileDoneOutlined /> &nbsp; {t('billing.bill.edit-title')}
                        </Col>
                        <Col span={20}>&nbsp;</Col>
                    </Row>
                }
            >

                <Row style={{padding: '24px'}} >
                    <Col span={24}>

                            <Row gutter={[8, 8]}>
                                <Col span={11}>
                                    <Form
                                        form={form}
                                        {...formItemLayout}
                                        className='Bill_Form'
                                        validateMessages={{required: ''}}
                                        preserve={false}
                                        initialValues={initForm()}
                                        onFinish={handleSubmit}
                                    >
                                        <Form.Item name='company_id' rules={[{required: true}]}
                                                   label={t('billing.bill.company_id')}>
                                            <Select
                                                showSearch
                                                onSearch={(value: string) => setSearchedCompany(value)}
                                                filterOption={true}
                                                className='Bill_Form_CompanySelect'
                                                optionFilterProp='label'
                                                options={getCompanyOptions()}
                                                placeholder={t('billing.bill.select-company')}
                                                onChange={onChangeCompany}
                                                onSelect={() => {
                                                }}>

                                            </Select>
                                        </Form.Item>

                                        <Form.Item name='number' rules={[{required: true}]}
                                                   label={t('billing.bill.number')}>
                                            <Input type='text' className='Bill_Form_NumberInput'/>
                                        </Form.Item>

                                        <Form.Item name='currency' rules={[{required: true}]}
                                                   label={t('billing.bill.currency')}>
                                            <Select
                                                className='Bill_Form_CurrencySelect'
                                                options={currencies.map(c => ({
                                                    value: c,
                                                    key: c,
                                                    label: c
                                                }))}
                                            >
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name='date_of_issue' rules={[{required: true}]}
                                                   label={t('billing.bill.date_of_issue')}>
                                            <DateInput htmlId='__date_of_issue'
                                                       initDateStr={appSetting.renderDateSQL(moment(bill.date_of_issue).toDate())}
                                                       disabled={false}
                                                       required={true}
                                                       format={appSetting.date_picker_format} // not Moment formats!!
                                                       onChange={(date) => {
                                                           date && form.setFieldsValue({date_of_maturity: appSetting.renderDateSQL(date)})
                                                       }}
                                            />
                                        </Form.Item>

                                        <Form.Item name='date_of_maturity' rules={[{required: true}]}
                                                   label={t('billing.bill.date_of_maturity')}>
                                            <DateInput htmlId='__date_of_maturity'
                                                       initDateStr={appSetting.renderDateSQL(moment(bill.date_of_maturity).toDate())}
                                                       disabled={false}
                                                       required={true}
                                                       format={appSetting.date_picker_format} // not Moment formats!!
                                                       onChange={(date) => {
                                                           date && form.setFieldsValue({date_of_maturity: appSetting.renderDateSQL(date)})
                                                       }}
                                            />
                                        </Form.Item>
                                        <Form.Item name='date_of_taxing' rules={[{required: true}]}
                                                   label={t('billing.bill.date_of_taxing')}>
                                            <DateInput htmlId='__date_of_taxing'
                                                       initDateStr={appSetting.renderDateSQL(moment(bill.date_of_taxing).toDate())}
                                                       disabled={false}
                                                       required={true}
                                                       format={appSetting.date_picker_format} // not Moment formats!!
                                                       onChange={(date) => {
                                                           date && form.setFieldsValue({date_of_taxing: appSetting.renderDateSQL(date)})
                                                       }}
                                            />
                                        </Form.Item>


                                        <Row gutter={[0,0]}>
                                            <Col span={1}>&nbsp;</Col>
                                            <Col span={21}>
                                                <Form.Item name='amount_due' label={t('billing.bill.amount_due')}>
                                                    <InputNumber precision={2}
                                                                 decimalSeparator=','
                                                                 className='inputPriceField'
                                                                 style={{width: '180px'}}
                                                                 onFocus={(event) => event.target.select()}
                                                                 onKeyPress={(e) => {if (e.key === 'Enter') {handleFormatter(e)}}}
                                                                 onBlur={handleFormatter}
                                                                 addonAfter={bill.currency}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                            </Col>
                                        </Row>

                                        <Divider/>

                                        <Form.Item label={t('billing.bill.attachments')} {...formItemLayout}>
                                            <BillAttachment/>
                                        </Form.Item>

                                        <Form.Item name='internal_note' label={t('billing.bill.note')}>
                                            <TextArea rows={3}
                                                      data-gramm="false"
                                                      data-gramm_editor="false"
                                                      data-enable-grammarly="false"
                                                      />
                                        </Form.Item>

                                        <Form.Item name='open_new' style={{margin: 0, padding: 0, minHeight: 0, height: 0}}>
                                            <Input type='hidden' />
                                        </Form.Item>

                                        <Row style={{marginTop: 0}}>
                                            <Col span={8} className='center'>
                                                <Link to='/billing/bill'>
                                                    <Button type='default'>{t('general.back')}</Button>
                                                </Link>
                                            </Col>
                                            <Col span={8}>
                                                <AlertBox title={t('billing.bill.confirm_header')}
                                                          text={t('billing.bill.confirm_text')}
                                                          openModal={openModalWarning1}
                                                          setOpenModal={setOpenModalWarning1}
                                                          onConfirm={() => submitForm(0)}
                                                          condition={showWarningPopup}>
                                                    <Button className='btnSave'
                                                            loading={isSaving}
                                                            onClick={() => {
                                                                if (!showWarning()) {
                                                                    submitForm(0)
                                                                }
                                                            }}
                                                            disabled={!loggedUser.hasAccess('page_billing_bill_edit_button')}
                                                    >{t('billing.bill.update')}</Button>
                                                </AlertBox>

                                            </Col>
                                            <Col span={8} className='center'>
                                                <AlertBox title={t('billing.bill.confirm_header')}
                                                          text={t('billing.bill.confirm_text')}
                                                          openModal={openModalWarning2}
                                                          setOpenModal={setOpenModalWarning2}
                                                          onConfirm={() => submitForm(1)}
                                                          condition={showWarningPopup}>
                                                    <Button type='primary'
                                                            disabled={!loggedUser.hasAccess('page_billing_bill_edit_button')}
                                                            onClick={() => {
                                                                if (!showWarning()) {
                                                                    submitForm(1)
                                                                }
                                                            }}>{t('billing.bill.update_and_create')}</Button>
                                                </AlertBox>

                                            </Col>
                                        </Row>

                                    </Form>
                                </Col>

                                <Col span={1}>&nbsp;</Col>


                                <Col span={12}>
                                    {
                                        bill && (
                                            <Table
                                                columns={columns}
                                                components={components}
                                                className='BillsTable'
                                                bordered={true}
                                                dataSource={vatSource}
                                                rowKey={(record) => record.tax_id}
                                                rowClassName={() => 'editable-row'}
                                                loading={isVatSummaryLoading}
                                                pagination={false}
                                                summary={pageData => {
                                                    let totalWithoutVat = 0
                                                    let totalVat = 0
                                                    let totalWithVat = 0
                                                    pageData.forEach(({ total_without_vat, total_vat, total_with_vat }) => {
                                                        totalWithoutVat += total_without_vat
                                                        totalVat += total_vat
                                                        totalWithVat += total_with_vat
                                                    })
                                                    return (
                                                        <Table.Summary.Row style={{backgroundColor:'#dcdcdc', fontWeight: 'bold'}}>
                                                            <Table.Summary.Cell index={0} className='sumTD'>{t('billing.bill.total')}</Table.Summary.Cell>
                                                            <Table.Summary.Cell align='right' index={1} className='sumTD'>
                                                                <span>{appSetting.renderPrice(totalWithoutVat)}</span>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell align='right' index={2} className='sumTD'>
                                                                <span>{appSetting.renderPrice(totalVat)}</span>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell align='right' index={3} className='sumTD'>
                                                                <span>{appSetting.renderPrice(totalWithVat)}</span>
                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                    )}
                                                }
                                            />
                                        )
                                    }

                                    <Divider/>

                                    {
                                        bill && (
                                            <>
                                                <BillCostAllocationCtrl
                                                    title={t('billing.bill-cost-allocation.page-title')}
                                                    billId={bill.id}
                                                />
                                            </>
                                        )
                                    }
                                </Col>
                            </Row>
                    </Col>
                </Row>

                <Row style={{padding: '24px'}} >
                    <Col span={24}>
                        {bill && (
                            <HistoryLog service='billing'
                            model='Bill'
                            modelId={historyModelId}
                            forceUpdateId={forceUpdateId}
                            isModal={false}
                            showTitle={true} />
                        )}
                    </Col>
                </Row>
            </Card>

        </>
    )
}

export default UpdateBillPage
