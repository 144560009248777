import {AsyncActionMode} from 'common/models'
import {
	CreateCustomerServiceParams,
	CustomerServiceDetails,
	CustomerServiceError,
	UpdateCustomerServiceParams,
	CreateOneTimeProductParams,
} from '../models'
import {PricelistDetails, PricelistError} from "../../pricelist/models";


export enum ActionType {
	CREATE_CUSTOMERSERVICE = 'CREATE_CUSTOMERSERVICE',
	CLEAR_CUSTOMERSERVICES = 'CLEAR_CUSTOMERSERVICES',
	GET_CUSTOMERSERVICES = 'GET_CUSTOMERSERVICES',
	GET_CUSTOMERSERVICES_ALL = 'GET_CUSTOMERSERVICES_ALL',
	GET_CUSTOMERSERVICE = 'GET_CUSTOMERSERVICE',
	GET_CUSTOMER_PRICELIST = 'GET_CUSTOMER_PRICELIST',
	UPDATE_CUSTOMERSERVICE = 'UPDATE_CUSTOMERSERVICE',
	DELETE_CUSTOMERSERVICE = 'DELETE_CUSTOMERSERVICE',
	CREATE_ONE_TIME_PRODUCT = 'CREATE_ONE_TIME_PRODUCT',
	SEND_CUSTOMER_SERVICE = "SEND_CUSTOMER_SERVICE",
	SET_ORDER = "SET_ORDER"
}

export type CustomerServiceActions =
	| CreateCustomerServiceRequestAction
	| CreateCustomerServiceResponseAction
	| CreateCustomerServiceErrorAction
	// | GetCsCustomerRequestAction
	// | GetCsCustomerResponseAction
	// | GetCsCustomerErrorAction
	| GetCustomerServicesRequestAction
	| ClearCustomerServicesResponseAction
	| GetCustomerServicesResponseAction
	| GetCustomerServicesErrorAction
	| GetCustomerServicesAllRequestAction
	| GetCustomerServicesAllResponseAction
	| GetCustomerServicesAllErrorAction
	| GetCustomerServiceRequestAction
	| GetCustomerServiceResponseAction
	| GetCustomerServiceErrorAction
	| GetCustomerPricelistRequestAction
	| GetCustomerPricelistResponseAction
	| GetCustomerPricelistErrorAction

	| UpdateCustomerServiceRequestAction
	| UpdateCustomerServiceResponseAction
	| UpdateCustomerServiceErrorAction
	| DeleteCustomerServiceRequestAction
	| DeleteCustomerServiceResponseAction
	| DeleteCustomerServiceErrorAction
	| CreateOneTimeProductRequestAction
	| CreateOneTimeProductResponseAction
	| CreateOneTimeProductErrorAction
	| SendCustomerServiceRequestAction
	| SendCustomerServiceResponseAction
	| SetOrderRequestAction
	| SetOrderResponseAction
	| SetOrderErrorAction

export class CreateOneTimeProductRequestAction {
	readonly type = ActionType.CREATE_ONE_TIME_PRODUCT
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateOneTimeProductParams) {}
}
export class CreateOneTimeProductResponseAction {
	readonly type = ActionType.CREATE_ONE_TIME_PRODUCT
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateOneTimeProductRequestAction, public data: CustomerServiceDetails) {}
}
export class CreateOneTimeProductErrorAction {
	readonly type = ActionType.CREATE_ONE_TIME_PRODUCT
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateOneTimeProductRequestAction, public error: string) {}
}

export class CreateCustomerServiceRequestAction {
	readonly type = ActionType.CREATE_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateCustomerServiceParams) {}
}
export class CreateCustomerServiceResponseAction {
	readonly type = ActionType.CREATE_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateCustomerServiceRequestAction, public data: CustomerServiceDetails) {}
}
export class CreateCustomerServiceErrorAction {
	readonly type = ActionType.CREATE_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateCustomerServiceRequestAction, public error: string) {}
}


// export class GetCsCustomerRequestAction {
// 	readonly type = ActionType.GET_CS_CUSTOMER
// 	readonly mode = AsyncActionMode.REQUEST
// 	constructor() {
// 		''
// 	}
// }
// export class GetCsCustomerResponseAction {
// 	readonly type = ActionType.GET_CS_CUSTOMER
// 	readonly mode = AsyncActionMode.RESPONSE
// 	constructor(
// 		public request: GetCsCustomerRequestAction,
// 		public data: CustomerDetails,
// 	) {}
// }
// export class GetCsCustomerErrorAction {
// 	readonly type = ActionType.GET_CS_CUSTOMER
// 	readonly mode = AsyncActionMode.ERROR
// 	constructor(public request: GetCsCustomerRequestAction, public error: string) {}
// }

export class GetCustomerServiceRequestAction {
	readonly type = ActionType.GET_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetCustomerServiceResponseAction {
	readonly type = ActionType.GET_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCustomerServiceRequestAction, public data: CustomerServiceDetails) {}
}
export class GetCustomerServiceErrorAction {
	readonly type = ActionType.GET_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetCustomerServiceRequestAction, public error: string) {}
}

export class GetCustomerServicesRequestAction {
	readonly type = ActionType.GET_CUSTOMERSERVICES
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}

export class ClearCustomerServicesResponseAction {
	readonly type = ActionType.CLEAR_CUSTOMERSERVICES
	readonly mode = AsyncActionMode.RESPONSE
	constructor() {}
}

export class GetCustomerServicesResponseAction {
	readonly type = ActionType.GET_CUSTOMERSERVICES
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCustomerServicesRequestAction, public data: CustomerServiceDetails[]) {}
}
export class GetCustomerServicesErrorAction {
	readonly type = ActionType.GET_CUSTOMERSERVICES
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetCustomerServicesRequestAction, public error: string) {}
}

export class GetCustomerServicesAllRequestAction {
	readonly type = ActionType.GET_CUSTOMERSERVICES_ALL
	readonly mode = AsyncActionMode.REQUEST
	constructor() {}
}
export class GetCustomerServicesAllResponseAction {
	readonly type = ActionType.GET_CUSTOMERSERVICES_ALL
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCustomerServicesAllRequestAction, public data: CustomerServiceDetails[]) {}
}
export class GetCustomerServicesAllErrorAction {
	readonly type = ActionType.GET_CUSTOMERSERVICES_ALL
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetCustomerServicesAllRequestAction, public error: string) {}
}

export class GetCustomerPricelistRequestAction {
	readonly type = ActionType.GET_CUSTOMER_PRICELIST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetCustomerPricelistResponseAction {
	readonly type = ActionType.GET_CUSTOMER_PRICELIST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCustomerPricelistRequestAction, public data: PricelistDetails) { }
}
export class GetCustomerPricelistErrorAction {
	readonly type = ActionType.GET_CUSTOMER_PRICELIST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetCustomerPricelistRequestAction, public error: string) { }
}

export class UpdateCustomerServiceRequestAction {
	readonly type = ActionType.UPDATE_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: UpdateCustomerServiceParams) {}
}
export class UpdateCustomerServiceResponseAction {
	readonly type = ActionType.UPDATE_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateCustomerServiceRequestAction, public data: CustomerServiceDetails) {}
}
export class UpdateCustomerServiceErrorAction {
	readonly type = ActionType.UPDATE_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateCustomerServiceRequestAction, public error: string) {}
}

export class DeleteCustomerServiceRequestAction {
	readonly type = ActionType.DELETE_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteCustomerServiceResponseAction {
	readonly type = ActionType.DELETE_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteCustomerServiceRequestAction, public data: CustomerServiceDetails) {}
}
export class DeleteCustomerServiceErrorAction {
	readonly type = ActionType.DELETE_CUSTOMERSERVICE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteCustomerServiceRequestAction, public error: string) {}
}

export class SendCustomerServiceRequestAction {
	readonly type = ActionType.SEND_CUSTOMER_SERVICE
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: string) {
	}
}

export class SendCustomerServiceResponseAction {
	readonly type = ActionType.SEND_CUSTOMER_SERVICE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public id: string) {
	}
}

export class SetOrderRequestAction {
	readonly type = ActionType.SET_ORDER
	readonly mode = AsyncActionMode.REQUEST
	constructor(customerServiceId: number) {
		''
	}
}
export class SetOrderResponseAction {
	readonly type = ActionType.SET_ORDER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(
		public request: SetOrderRequestAction,
		public data: CustomerServiceDetails) {}
}
export class SetOrderErrorAction {
	readonly type = ActionType.SET_ORDER
	readonly mode = AsyncActionMode.ERROR
	constructor(
		public request: SetOrderRequestAction,
		public error: string) {}
}