import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    GetHistoryLogsRequestAction,
    GetHistoryLogsResponseAction,
    GetHistoryLogsErrorAction,
} from '.'
import {LogRecord} from "../../../pages/logviewer/models";


export default (service: string, model: string, model_id: number | string, qs?: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetHistoryLogsRequestAction
                | GetHistoryLogsResponseAction
                | GetHistoryLogsErrorAction,
        ) => void,
    ) => {
        const request = new GetHistoryLogsRequestAction()
        dispatch(request)
        let url = '/history/model?service='+service+'&model='+model+'&model_id='+model_id
        if (qs) {
            url = url + '&' + qs
        }

        protectedApiClient
            .get<LogRecord[]>(url)
            .then((response) => {
                dispatch(
                    new GetHistoryLogsResponseAction(request, response.data),
                )
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetHistoryLogsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
