import React, {useState} from 'react'
import {Popconfirm, message, Row, Col, Popover} from 'antd'
import {useTranslation} from 'react-i18next'
import Button from "antd-button-color";
import {ExclamationCircleOutlined} from "@ant-design/icons";


interface Props {
    title: string
    text: string
    onConfirm?: () => void
    openModal: boolean
    setOpenModal: (b: boolean) => void
    condition?: boolean     // when popup should be used (suppress popup == do not show it)
    okText?: string
    children?: any
    placement?:
        | 'top'
        | 'left'
        | 'right'
        | 'bottom'
        | 'topLeft'
        | 'topRight'
        | 'bottomLeft'
        | 'bottomRight'
        | 'leftTop'
        | 'leftBottom'
        | 'rightTop'
        | 'rightBottom'
        | undefined
}

const AlertBox = (props: Props) => {
    // <AlertBox title='AAA' text='aaa'openModal={false} setOpenModal={} onConfirm={} okText='Yes'>{children}</AlertBox>
    const {title, text, onConfirm, openModal, setOpenModal, condition, okText, children, placement, ...restProps} = props
    const {t} = useTranslation()

    return (
    <Popover
        content={(
            <>
                <b>{text}</b>
                <br/>
                <Row style={{marginTop: '25px'}}>
                    <Col span={12} className='center'>
                        <Button type='default' onClick={() => {setOpenModal(false)}}>{t('general.cancel')}</Button>
                    </Col>
                    <Col span={12} className='center'>
                        <Button className='btnSave'
                                onClick={() => {
                                    onConfirm && onConfirm()
                                }}
                        >{ okText ? okText : t('billing.bill.update')}</Button>
                    </Col>
                </Row>
            </>
        )}
        overlayClassName='alertBox'
        overlayInnerStyle={{}}
        title={(<b><ExclamationCircleOutlined style={{color: 'white'}} />&nbsp;&nbsp;{title}</b>)}
        trigger={condition ? 'click' : 'none'}
        visible={openModal}
        onVisibleChange={(b) => setOpenModal(b)}
    >
        {children}
    </Popover>
    )
}

export default AlertBox
