import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    UpdateDataOfPaymentErrorAction,
    UpdateDataOfPaymentRequestAction,
    UpdateDataOfPaymentResponseAction
} from "./index";
import {InvoiceDetails, InvoicePaidPayload} from "../models";


export default (
    params: InvoicePaidPayload,
    cb?: (isSuccess: boolean) => void,
    onProgress?: (ProgressEvent: any) => void,
) => {
    return (
        dispatch: (
            arg:
                | UpdateDataOfPaymentRequestAction
                | UpdateDataOfPaymentResponseAction
                | UpdateDataOfPaymentErrorAction
        ) => void,
    ) => {
        const request = new UpdateDataOfPaymentRequestAction(params)
        dispatch(request)
        protectedApiClient
            .put<InvoiceDetails>(`/billing/invoice/${params.id}/paid`, params, { onUploadProgress: onProgress },
            )
            .then((response) => {
                dispatch(
                    new UpdateDataOfPaymentResponseAction(request, response.data),
                )
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateDataOfPaymentErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
