import {
    UpdateMailTemplateRequestAction,
    UpdateMailTemplateResponseAction,
    UpdateMailTemplateErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {MailTemplateDetails, UpdateMailTemplateParams} from '../models'

export default (params: UpdateMailTemplateParams, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | UpdateMailTemplateRequestAction
                | UpdateMailTemplateResponseAction
                | UpdateMailTemplateErrorAction,
        ) => void,
    ) => {
        const request = new UpdateMailTemplateRequestAction(params)
        dispatch(request)

        protectedApiClient
            .put<MailTemplateDetails>(`/mail-template/${params.id}`, params)
            .then((response) => {
                dispatch(new UpdateMailTemplateResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateMailTemplateErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
