import {
  SetDefaultVpsSettingAction,

} from '.'

export default (cb?: (isSuccess: boolean) => void) => {
  return (
      dispatch: (
          arg: SetDefaultVpsSettingAction
      ) => void,
  ) => {

      dispatch ( new SetDefaultVpsSettingAction())
      
  }
}

