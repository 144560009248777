import {AsyncActionMode, NameItem} from 'common/models'
import {
	AddRemoveIpResponse,
	CreateUpdateServerParams,
	ServerAddRemoveIpParams, ServerGrid,
	ServerModel,
} from '../models'
import {PhysicalServerModel} from "../../inventory/physicalserver/models";

export enum ActionType {
	CREATE_SERVER = 'CREATE_SERVER',
	GET_SERVERS = 'GET_SERVERS',
	GET_SERVER = 'GET_SERVER',
	GET_PHYSICAL_SERVERS2 = 'GET_PHYSICAL_SERVERS2',
	UPDATE_SERVER = 'UPDATE_SERVER',
	DELETE_SERVER = 'DELETE_SERVER',
	ADD_IP_TO_SERVER = 'ADD_IP_TO_SERVER',
	REMOVE_IP_FROM_SERVER = 'REMOVE_IP_FROM_SERVER',
	GET_SERVER_SERVICE_TYPE = 'GET_SERVER_SERVICE_TYPE',
	GET_SERVER_TYPE = 'GET_SERVER_TYPE',
	GET_SERVER_VPS_TYPE = 'GET_SERVER_VPS_TYPE',
	GET_ZIS_MANAGEMENT_TYPE = 'GET_ZIS_MANAGEMENT_TYPE',
	GET_CUSTOMER_MANAGEMENT_TYPE = 'GET_CUSTOMER_MANAGEMENT_TYPE',
	GET_SERVER_BACKUP_TYPE = 'GET_SERVER_BACKUP_TYPE',
	GET_SERVER_MONITORING_TYPE = 'GET_SERVER_MONITORING_TYPE',
}

export type ServerActions =
	| AddIpToServerRequestAction
	| AddIpToServerResponseAction
	| AddIpToServerErrorAction
	| RemoveIpFromServerRequestAction
	| RemoveIpFromServerResponseAction
	| RemoveIpFromServerErrorAction
	| CreateServerRequestAction
	| CreateServerResponseAction
	| CreateServerErrorAction
	| GetServersRequestAction
	| GetServersResponseAction
	| GetServersErrorAction
	| GetServerRequestAction
	| GetServerResponseAction
	| GetServerErrorAction
	| GetPhysicalServersRequestAction
	| GetPhysicalServersResponseAction
	| GetPhysicalServersErrorAction
	| UpdateServerRequestAction
	| UpdateServerResponseAction
	| UpdateServerErrorAction
	| DeleteServerRequestAction
	| DeleteServerResponseAction
	| DeleteServerErrorAction
	| GetServiceTypeRequestAction
	| GetServiceTypeResponseAction
	| GetServiceTypeErrorAction

	| GetServerTypeRequestAction
	| GetServerTypeResponseAction
	| GetServerTypeErrorAction
	| GetServerVpsTypeRequestAction
	| GetServerVpsTypeResponseAction
	| GetServerVpsTypeErrorAction
	| GetZisManagementTypeRequestAction
	| GetZisManagementTypeResponseAction
	| GetZisManagementTypeErrorAction
	| GetCustomerManagementTypeRequestAction
	| GetCustomerManagementTypeResponseAction
	| GetCustomerManagementTypeErrorAction
	| GetServerBackupTypeRequestAction
	| GetServerBackupTypeResponseAction
	| GetServerBackupTypeErrorAction
	| GetServerMonitoringTypeRequestAction
	| GetServerMonitoringTypeResponseAction
	| GetServerMonitoringTypeErrorAction


export class GetServiceTypeRequestAction {
	readonly type = ActionType.GET_SERVER_SERVICE_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetServiceTypeResponseAction {
	readonly type = ActionType.GET_SERVER_SERVICE_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServiceTypeRequestAction, public data: NameItem[]) {}
}
export class GetServiceTypeErrorAction {
	readonly type = ActionType.GET_SERVER_SERVICE_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServiceTypeRequestAction, public error: string) {}
}
export class GetServerMonitoringTypeRequestAction {
	readonly type = ActionType.GET_SERVER_MONITORING_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetServerMonitoringTypeResponseAction {
	readonly type = ActionType.GET_SERVER_MONITORING_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServerMonitoringTypeRequestAction, public data: NameItem[]) {}
}
export class GetServerMonitoringTypeErrorAction {
	readonly type = ActionType.GET_SERVER_MONITORING_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServerMonitoringTypeRequestAction, public error: string) {}
}
export class GetServerBackupTypeRequestAction {
	readonly type = ActionType.GET_SERVER_BACKUP_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetServerBackupTypeResponseAction {
	readonly type = ActionType.GET_SERVER_BACKUP_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServerBackupTypeRequestAction, public data: NameItem[]) {}
}
export class GetServerBackupTypeErrorAction {
	readonly type = ActionType.GET_SERVER_BACKUP_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServerBackupTypeRequestAction, public error: string) {}
}
export class GetCustomerManagementTypeRequestAction {
	readonly type = ActionType.GET_CUSTOMER_MANAGEMENT_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetCustomerManagementTypeResponseAction {
	readonly type = ActionType.GET_CUSTOMER_MANAGEMENT_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCustomerManagementTypeRequestAction, public data: NameItem[]) {}
}
export class GetCustomerManagementTypeErrorAction {
	readonly type = ActionType.GET_CUSTOMER_MANAGEMENT_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetCustomerManagementTypeRequestAction, public error: string) {}
}
export class GetZisManagementTypeRequestAction {
	readonly type = ActionType.GET_ZIS_MANAGEMENT_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetZisManagementTypeResponseAction {
	readonly type = ActionType.GET_ZIS_MANAGEMENT_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetZisManagementTypeRequestAction, public data: NameItem[]) {}
}
export class GetZisManagementTypeErrorAction {
	readonly type = ActionType.GET_ZIS_MANAGEMENT_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetZisManagementTypeRequestAction, public error: string) {}
}
export class GetServerTypeRequestAction {
	readonly type = ActionType.GET_SERVER_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetServerTypeResponseAction {
	readonly type = ActionType.GET_SERVER_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServerTypeRequestAction, public data: NameItem[]) {}
}
export class GetServerTypeErrorAction {
	readonly type = ActionType.GET_SERVER_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServerTypeRequestAction, public error: string) {}
}
export class GetServerVpsTypeRequestAction {
	readonly type = ActionType.GET_SERVER_VPS_TYPE
	readonly mode = AsyncActionMode.REQUEST
	constructor() {''}
}
export class GetServerVpsTypeResponseAction {
	readonly type = ActionType.GET_SERVER_VPS_TYPE
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServerVpsTypeRequestAction, public data: NameItem[]) {}
}
export class GetServerVpsTypeErrorAction {
	readonly type = ActionType.GET_SERVER_VPS_TYPE
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServerVpsTypeRequestAction, public error: string) {}
}
export class RemoveIpFromServerRequestAction {
	readonly type = ActionType.REMOVE_IP_FROM_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: ServerAddRemoveIpParams) {}
}
export class RemoveIpFromServerResponseAction {
	readonly type = ActionType.REMOVE_IP_FROM_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: RemoveIpFromServerRequestAction, public data: ServerModel) {}
}
export class RemoveIpFromServerErrorAction {
	readonly type = ActionType.REMOVE_IP_FROM_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: RemoveIpFromServerRequestAction, public error: string) {}
}

export class AddIpToServerRequestAction {
	readonly type = ActionType.ADD_IP_TO_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: ServerAddRemoveIpParams) {}
}
export class AddIpToServerResponseAction {
	readonly type = ActionType.ADD_IP_TO_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: AddIpToServerRequestAction, public data: AddRemoveIpResponse) {}
}
export class AddIpToServerErrorAction {
	readonly type = ActionType.ADD_IP_TO_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: AddIpToServerRequestAction, public error: string) {}
}

export class CreateServerRequestAction {
	readonly type = ActionType.CREATE_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreateUpdateServerParams) {}
}
export class CreateServerResponseAction {
	readonly type = ActionType.CREATE_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreateServerRequestAction, public data: ServerModel) {}
}
export class CreateServerErrorAction {
	readonly type = ActionType.CREATE_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreateServerRequestAction, public error: string) {}
}

export class GetServerRequestAction {
	readonly type = ActionType.GET_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetServerResponseAction {
	readonly type = ActionType.GET_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServerRequestAction, public data: ServerModel) {}
}
export class GetServerErrorAction {
	readonly type = ActionType.GET_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServerRequestAction, public error: string) {}
}

export class GetServersRequestAction {
	readonly type = ActionType.GET_SERVERS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetServersResponseAction {
	readonly type = ActionType.GET_SERVERS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetServersRequestAction, public data: ServerGrid) {}
}
export class GetServersErrorAction {
	readonly type = ActionType.GET_SERVERS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetServersRequestAction, public error: string) {}
}

export class GetPhysicalServersRequestAction {
	readonly type = ActionType.GET_PHYSICAL_SERVERS2
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetPhysicalServersResponseAction {
	readonly type = ActionType.GET_PHYSICAL_SERVERS2
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetPhysicalServersRequestAction, public data: PhysicalServerModel[]) {}
}
export class GetPhysicalServersErrorAction {
	readonly type = ActionType.GET_PHYSICAL_SERVERS2
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetPhysicalServersRequestAction, public error: string) {}
}

export class UpdateServerRequestAction {
	readonly type = ActionType.UPDATE_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: CreateUpdateServerParams) {}
}
export class UpdateServerResponseAction {
	readonly type = ActionType.UPDATE_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdateServerRequestAction, public data: ServerModel) {}
}
export class UpdateServerErrorAction {
	readonly type = ActionType.UPDATE_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdateServerRequestAction, public error: string) {}
}

export class DeleteServerRequestAction {
	readonly type = ActionType.DELETE_SERVER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeleteServerResponseAction {
	readonly type = ActionType.DELETE_SERVER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeleteServerRequestAction, public data: ServerModel) {}
}
export class DeleteServerErrorAction {
	readonly type = ActionType.DELETE_SERVER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeleteServerRequestAction, public error: string) {}
}
