import {
    CreateUserMailRequestAction,
    CreateUserMailResponseAction,
    CreateUserMailErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {CreateUserMailParams, UserMailDetail} from '../models'

export default (params: CreateUserMailParams, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (args: CreateUserMailRequestAction | CreateUserMailResponseAction | CreateUserMailErrorAction) => void) => {
        const request = new CreateUserMailRequestAction(params)
        dispatch(request)

        protectedApiClient.post<UserMailDetail>('/mail', params)
            .then(response => {
                dispatch(new CreateUserMailResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch(error => {
                dispatch(new CreateUserMailErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}

