import {
  GetVpsSettingsRequestAction,
  GetVpsSettingsResponseAction,
  GetVpsSettingsErrorAction
} from '.'

import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

import { VpsSettingModel } from '../models'

export default (cb?: (isSuccess: boolean) => void) => {
  return (
      dispatch: (
          arg: GetVpsSettingsRequestAction | GetVpsSettingsResponseAction | GetVpsSettingsErrorAction,
      ) => void,
  ) => {
      const request = new GetVpsSettingsRequestAction()
      dispatch(request)

      protectedApiClient
          .get<VpsSettingModel[]>(`/vpsadmin/setting`)
          .then((response) => {
              dispatch(new GetVpsSettingsResponseAction(request, response.data))
              cb && cb(true)
          })
          .catch((error) => {
              dispatch(new GetVpsSettingsErrorAction(request, error))
              handleApiErrorWithNotification(error)
              cb && cb(false)
          })
  }
}

