import {
  GetSubnetIpRequestAction,
  GetSubnetIpResponseAction,
  GetSubnetIpErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { IpSubnetModel } from '../models'

export default (id: number, expand: string, cb?: (isSuccess: boolean) => void) => {
  return (
    dispatch: (
      arg:
        | GetSubnetIpRequestAction
        | GetSubnetIpResponseAction
        | GetSubnetIpErrorAction,
    ) => void,
  ) => {
    const request = new GetSubnetIpRequestAction(id)
    dispatch(request)

    let url = `/ip/subnet/${id}`
    if (expand) {
      url = `/ip/subnet/${id}?expand=${expand}`
    }
    protectedApiClient
      .get<IpSubnetModel>(url)
      .then((response) => {
        dispatch(new GetSubnetIpResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new GetSubnetIpErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
