import {
    GetContainersHistoriesResponseAction,
    GetContainersHistoriesErrorAction,
    GetContainersHistoriesRequestAction
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { HistoryModel } from "../models";


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetContainersHistoriesRequestAction | GetContainersHistoriesResponseAction | GetContainersHistoriesErrorAction,
        ) => void,
    ) => {

        const request = new GetContainersHistoriesRequestAction(id)
        dispatch(request)
        protectedApiClient
            .get<HistoryModel[]>(`/vpsadmin/container/${id}/history`)
            .then((response) => {
                dispatch(new GetContainersHistoriesResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetContainersHistoriesErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
