import { ActionType, CountriesActions } from "./actions";
import { AsyncActionMode } from "../../../common/models";
import { CountriesPageState, Country } from "./models";


const COUNTRIES_PAGE_INITIAL_STATE: CountriesPageState = {
    isLoading: false,
    isSaving: false,
    countries: [],
    country: undefined,
    error: undefined
}


export default (
    state = COUNTRIES_PAGE_INITIAL_STATE, action: CountriesActions): CountriesPageState => {
    switch (action.type) {
        case ActionType.GET_COUNTRIES:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    countries: action.data,
                    isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_COUNTRY:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    country: action.data,
                    isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break


        case ActionType.UPDATE_COUNTRY:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {

                return {
                    ...state,
                    countries: state.countries?.map((country) =>
                        country.id === action.data.id ? { ...action.data } : country,
                    ),
                    isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error
                }
            }
            break

    }

    return state
}