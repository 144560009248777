import {
    AddRoleInGroupRequestAction,
    AddRoleInGroupErrorAction,
    AddRoleInGroupResponseAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {AddDeleteRoleToGroupParams, UserGroupModel} from '../models'


export default (
    params: AddDeleteRoleToGroupParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | AddRoleInGroupRequestAction
                | AddRoleInGroupErrorAction
                | AddRoleInGroupResponseAction,
        ) => void,
    ) => {
        const request = new AddRoleInGroupRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<UserGroupModel>(`/user-group/${params.id}/add-role`, params)
            .then((response) => {
                dispatch(new AddRoleInGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new AddRoleInGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
