import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Button, Col, Input, message, Radio, Row, Select, Spin, Switch} from 'antd'
import {AppState} from 'common/models'
import createUser from './actions/createUser'
import {Store} from 'antd/lib/form/interface'
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {formItemLayout, tailLayout} from "../../helpers/layoutHelpers";
import Item from "antd/lib/form/FormItem";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import Form, {useForm} from "antd/lib/form/Form";
import {sort_label} from "../../common/sorting";
import {CreateUserParams, UserDetails} from "./models";
import {ROLE_APP_ADMIN, ROLE_USER_MANAGER} from "../../common/LoggedUser";
import {_parsePhone, REG_IPV4, REG_USERNAME, removeDiac} from "../../common/fce";
import {euPhoneNumberRegex} from "../../helpers/stringHelpers";


const FormStates = {
    LOADING: 0,
    HUMAN: 1,
    DAEMON: 2,
    SELECTION: 3
}

interface Props {
    setModalVisible: (visible: boolean) => void
    alwaysHook: number
}

const CreateUserPage = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form] = useForm()
    const {isSaving} = useSelector((state: AppState) => state.user)
    const {customers} = useSelector((state: AppState) => state.auth.tables)
    const {fontSize} = useSelector((state: AppState) => state.font)

    const [pageState, setPageState] = useState<number>(0)
    const [dataToUpdate, setDataToUpdate] = useState<CreateUserParams | undefined>()
    const [checked, setChecked] = useState(true)
    const [isSystem, setSystem] = useState(false)
    const [updateStatus, setUpdateStatus] = useState(false)
    const [custOptions, setCustOptions] = useState<{ label: string, value: number }[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number>(0)
    const [isEditor, setEditor] = useState(false)
    const [isAdmin, setAdmin] = useState(false)	// admin can edit daemons
    const [canCreate, setCanCreate] = useState<boolean>(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // this is called always after opening modal (open / close / open)
        startUp()
    }, [props.alwaysHook])

    useEffect(() => {
        setEditor(loggedUser.hasRole(ROLE_USER_MANAGER))
        setAdmin(loggedUser.hasRole(ROLE_USER_MANAGER) && loggedUser.hasRole(ROLE_APP_ADMIN))
        setCanCreate(loggedUser.hasRole(ROLE_USER_MANAGER))
        transitionTo(FormStates.LOADING)
    }, [])

    useEffect(() => {
        if (canCreate) {
            populateCustomerOptions()
            startUp()
        }
    }, [canCreate])

    const startUp = () => {
        if (isEditor) {
            if (isAdmin) {
                transitionTo(FormStates.SELECTION)
            }
            else {
                transitionTo(FormStates.HUMAN)
            }
            populateCustomerOptions()
        }
        else {
            transitionTo(FormStates.LOADING)
        }
    }

    const populateCustomerOptions = () => {
        let data = {
            username: '',
            email: '',
            password: '',
            password_repeat: '',
            name: '',
            surname: '',
            is_unix: 1,
            is_system: 0,
            phone: '',
            whitelist_ip: '',
            customer_id: loggedUser.customer.id
        }
        if (loggedUser.user.is_zcom) {
            // ZCOM can see all customers
            const items = customers.map(cust => ({label: cust.company!.name, value: cust.id}))
            setCustOptions(items.sort(sort_label))

        } else {
            // non-zcom = customer
            const items = [{label: loggedUser.company.name, value: loggedUser.customer.id}]
            setCustOptions(items.sort(sort_label))
        }
        setSelectedCustomerId(loggedUser.customer.id)
        setDataToUpdate(data)
        if (form) {
            form.setFieldsValue(data)
        }
    }

    const handleSave = (values: Store): void => {
        let params: CreateUserParams
        if (isSystem) {
            params = {
                username: values.username,
                email: values.username + '@zcom.cz',
                password: values.password,
                password_repeat: values.password,
                name: 'bot',
                surname: values.surname,
                is_unix: values.is_unix ? 1 : 0,
                is_system: 1,
                phone: '',
                note: values.note,
                whitelist_ip: values.whitelist_ip,
                customer_id: values.customer_id
            }
        }
        else {
            params = {
                username: values.username,
                email: values.email,
                password: '',
                password_repeat: '',
                name: values.name,
                surname: values.surname,
                is_unix: values.is_unix ? 1 : 0,
                is_system: 0,
                phone: _parsePhone(values.phone),
                note: values.note,
                customer_id: values.customer_id
            }
        }
        console.log(JSON.stringify(params))
        dispatch(createUser(params, (suc) => {
                if (suc) {
                    message.success(<span dangerouslySetInnerHTML={{__html: t('createUserPage.created', {username: values.username})}}></span>)
                    props.setModalVisible(false)
                }
            })
        )
    }


    const transitionTo = (page: number) => {
        setPageState(page)
    }

    const renderSelection = () => {
        return (
            <Row gutter={[0, 0]}>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Radio defaultChecked={!isSystem} checked={!isSystem} onClick={() => { setSystem(false) }}>
                        Human
                    </Radio>
                    <Radio defaultChecked={isSystem} checked={isSystem} onClick={() => { setSystem(true) }}>
                        Daemon
                    </Radio>
                    <br/><br/>
                    <Button type="primary" onClick={() => {
                        transitionTo(isSystem ? FormStates.DAEMON : FormStates.HUMAN)
                    }} style={{marginTop: 16}}>
                        {t('general.continue')}
                    </Button>

                </Col>
            </Row>
        )
    }

    const renderHuman = () => {
        return (
            <Form
                {...formItemLayout}
                form={form}
                name='create-user'
                className='create-user-form'
                initialValues={dataToUpdate}
                onFinish={handleSave}
                onChange={() => setUpdateStatus(true)}
            >

                {
                    loggedUser.user.is_zcom &&
                    <Item name='customer_id' label={t('createUserPage.customer_id')}
                          rules={[{required: true, message: t('createUserPage.err_customer')}]}
                          hasFeedback
                    >
                        <Select
                            showSearch
                            optionFilterProp='label'
                            filterOption={(val, opt) => {
                                return !!opt && removeDiac(opt.label).includes(removeDiac(val))
                            }}
                            style={{display: 'block'}}
                            size={fontSize}
                            options={custOptions}
                        />
                    </Item>
                }

                <Item name='username' label={t('createUserPage.username')}
                      rules={[
                          {required: true, message: t('loginPage.err_username')},
                          {pattern: REG_USERNAME, message: 'Invalid format.'}
                      ]}
                      hasFeedback
                >
                    <Input size={fontSize}
                           autoComplete="off"
                           data-gramm="false"
                           data-gramm_editor="false"
                           data-enable-grammarly="false" />
                </Item>

                <Item name='email' label={t('createUserPage.email')}
                      rules={[{
                          required: true,
                          type: "email",
                          message: t('errors.error_email')
                      }]}
                      hasFeedback
                >
                    <Input type='email' size={fontSize}
                           autoComplete="off"
                           data-gramm="false"
                           data-gramm_editor="false"
                           data-enable-grammarly="false" />
                </Item>

                <Item name='name' label={t('createUserPage.name')}>
                    <Input size={fontSize}
                           autoComplete="off"
                           data-gramm="false"
                           data-gramm_editor="false"
                           data-enable-grammarly="false" />
                </Item>

                <Item name='surname'
                      rules={[{required: true, message: t('createUserPage.err_surname')}]}
                      label={t('createUserPage.surname')}
                      hasFeedback
                >
                    <Input size={fontSize}
                           autoComplete="off"
                           data-gramm="false"
                           data-gramm_editor="false"
                           data-enable-grammarly="false" />
                </Item>

                <Item name='is_unix'
                      label={t('createUserPage.is_unix.title')}
                      valuePropName='checked'
                >
                    <Switch
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                    />
                </Item>

                <Item name='phone'
                      label={t('createUserPage.phone')}
                      rules={[{
                          pattern: new RegExp(euPhoneNumberRegex),
                          message: t('errors.error_phone')
                      }]}
                      hasFeedback>
                    <Input placeholder='+420 777 654 321'
                           autoComplete="off"
                           data-gramm="false"
                           data-gramm_editor="false"
                           data-enable-grammarly="false" />
                </Item>

                <Item name='note' label={t('createUserPage.note')}>
                    <Input size={fontSize}
                           autoComplete="off"
                           data-gramm="false"
                           data-gramm_editor="false"
                           data-enable-grammarly="false" />
                </Item>


                <Item {...tailLayout}>
                    <Button type='primary' loading={isSaving} htmlType='submit' className='login-form-button'
                            size={fontSize}>
                        {t('general.create')}
                    </Button>
                </Item>
            </Form>
        )
    }

    const renderDaemon = () => {
        return (
            <Form
                {...formItemLayout}
                form={form}
                name='create-user'
                className='create-user-form'
                initialValues={dataToUpdate}
                onFinish={handleSave}
                onChange={() => setUpdateStatus(true)}
            >

                {
                    loggedUser.user.is_zcom &&
                    <Item name='customer_id' label={t('createUserPage.customer_id')}
                          rules={[{required: true, message: t('createUserPage.err_customer')}]}
                          hasFeedback
                    >
                        <Select
                            showSearch
                            style={{display: 'block'}}
                            optionFilterProp='label'
                            size={fontSize}
                            options={custOptions}
                        />
                    </Item>
                }

                <Item name='surname' label={t('createUserPage.daemonName')}
                      rules={[{required: true, message: t('errors.field_required')}]}
                      hasFeedback>
                    <Input size={fontSize}
                           autoComplete="off"
                           data-gramm="false"
                           data-gramm_editor="false"
                           data-enable-grammarly="false"/>
                </Item>

                <Item name='username' label={t('createUserPage.username')}
                      rules={[
                          {required: true, message: t('loginPage.err_username')},
                          {pattern: REG_USERNAME, message: 'Invalid format.'}
                      ]}
                      hasFeedback>
                    <Input size={fontSize}
                           autoComplete="off"
                           data-gramm="false"
                           data-gramm_editor="false"
                           data-enable-grammarly="false"/>
                </Item>

                <Item name='password' label={t('createUserPage.password')}
                      rules={[{required: true, message: t('errors.field_required')}]}>
                    <Input.Password size={fontSize}
                                    autoComplete="off"
                                    data-gramm="false"
                                    data-gramm_editor="false"
                                    data-enable-grammarly="false"/>
                </Item>

                <Item name='whitelist_ip' label={t('createUserPage.whitelistIp')}
                      rules={[{required: true, message: t('errors.field_required')},
                          {pattern: REG_IPV4, message: 'Invalid format for IPv4.'}]}
                      hasFeedback>
                    <Input size={fontSize}/>
                </Item>

                <Item name='note' label={t('createUserPage.note')}>
                    <Input size={fontSize}
                           autoComplete="off"
                           data-gramm="false"
                           data-gramm_editor="false"
                           data-enable-grammarly="false"/>
                </Item>

                <Item {...tailLayout}>
                    <Button type='primary'
                            htmlType='submit'
                            className='login-form-button'
                            size={fontSize}
                            loading={isSaving}
                            disabled={!updateStatus}>
                        {t('general.create')}
                    </Button>
                </Item>
            </Form>
        )
    }

    const renderLoading = () => {
        return (
            <Row gutter={[0, 0]}>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Spin/>
                </Col>
            </Row>
        )
    }


    // render
    switch (pageState) {
        case FormStates.LOADING:
            return renderLoading()

        case FormStates.HUMAN:
            return renderHuman()

        case FormStates.DAEMON:
            return renderDaemon()

        case FormStates.SELECTION:
            return renderSelection()

        default:
            return renderLoading()
    }


}
export default CreateUserPage
