import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { enGB, cs } from 'date-fns/locale'
import "./date-range.scss"
import {
    CalendarOutlined, CloseCircleOutlined, EditTwoTone, SwapOutlined
} from "@ant-design/icons";
import Button from "antd-button-color";
import { Popover, Tag } from "antd";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { FILTER_PERIOD_BASIC } from "../../common/enums";
import {formatISO, SQL_DATE_FORMAT} from "../../common/fce";
import {getLanguage} from "../../helpers/langHelpers";

registerLocale('cs', cs)

interface Props {
    format: string
    initStart?: string
    initEnd?: string
    onChange: (dt1: string, dt2: string) => void
    trigger?: number
    showClear?: boolean
    // setPeriod: (dt1: string, dt2: string) => void
}

const addDays = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

const addMonths = (date, months) => {
    let result = new Date(date);
    result.setMonth(result.getDate() + months);
    return result;
}



const validFromOrAll = (strDt) => {
    try {
        return new Date(strDt)
    }
    catch (e) {
        return new Date('2000-01-01')
    }
}
const validToOrAll = (strDt) => {
    try {
        return new Date(strDt)
    }
    catch (e) {
        return addMonths(new Date(), 1)
    }
}

const DateRange = (props: Props) => {
    const { t } = useTranslation()
    const actHalfYear: number = (moment().quarter() < 3) ? 1 : 2

    const startRef = React.useRef<any>(null)
    const endRef = React.useRef<any>(null)

    const initStart = validFromOrAll(props.initStart)
    const initEnd = validToOrAll(props.initEnd)
    const [showClear, setShowClear] = useState<boolean>(props.showClear === false ? false : true);
    const [startDate, setStartDate] = useState<Date | undefined>(initStart);
    const [endDate, setEndDate] = useState<Date | undefined>(initEnd);
    const [startDateOrig, setStartDateOrig] = useState<Date | undefined>(initStart);
    const [endDateOrig, setEndDateOrig] = useState<Date | undefined>(initEnd);
    const [startDateStr, setStartDateStr] = useState<string>('');
    const [endDateStr, setEndDateStr] = useState<string>('');


    const [open, setOpen] = useState(false);

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    }

    const getPeriodName = (name: string) => {
        // returns translated name of period
        let splited = name.split('_')
        let filterName = ''
        let r = getRanges(name)
        if (splited[0] === 'ALL') { filterName = `${t(`RangeFilter.ALL`)}` }
        if (splited[1] === 'WEEK') {
            splited[0] === 'ACT' ?
                filterName = `${t(`RangeFilter.ACT`)} ${t(`RangeFilter.${splited[1]}`)}` :
                splited[0] === 'PREV' ?
                    filterName = `${t(`RangeFilter.PREV`)} ${t(`RangeFilter.${splited[1]}`)}` :
                    filterName = `${t(`RangeFilter.${splited[1]}`)} ${r && r[0].week()}`
        }
        if (splited[1] === 'MONTH') {
            filterName = t(`RangeFilter.mes_${r && r[0].month()}`) + ` ${r && r[0].year()}`
        }
        if (splited[1] === 'QUARTER') {
            filterName = `${r && r[0].quarter()}${t(`RangeFilter.${splited[1]}`)} ${r && r[0].year()}`
        }
        if (splited[1] === 'YEAR') {
            filterName = `${t(`RangeFilter.YEAR`)} ${r && r[0].year()}`
        }
        if (splited[1] === 'HALF') {
            if (splited[0] === 'SECOND') filterName = `${r && r[1].quarter() < 3 ? 1 : 2} ${t('RangeFilter.HALF')} ${r && r[0].year()}`
            if (splited[0] === 'THIRD') filterName = `${r && r[1].quarter() < 3 ? 1 : 2} ${t('RangeFilter.HALF')} ${r && r[0].year()}`
            if (splited[0] === 'ACT') filterName = `${t(`RangeFilter.${splited[0]}`)} ${r && r[0].year()}`
            if (splited[0] === 'FIRST') filterName = `${t(`RangeFilter.${splited[0]}`)} ${r && r[0].year()}`
        }
        if (splited[1] === 'BEFORE') {
            if (splited[0] === 'MONTH') filterName = `${r && r[1].format('MMMM')} ${r && r[0].year()}`
            if (splited[0] === 'QUARTER') filterName = `${r && r[1].quarter()} ${t('RangeFilter.QUARTER')} ${r && r[0].year()}`
        }
        return filterName
    }

    const renderPeriod = (name: string) => {
        if (name.includes('MONTH')) {
            return (<Tag className='period-name' color="cyan" key={`m_${name}`} onClick={() => handlePeriodClicked(name)}>{getPeriodName(name)}</Tag>)
        }
        if (name.includes('QUARTER')) {
            return (<Tag className='period-name' color="blue" key={`q_${name}`} onClick={() => handlePeriodClicked(name)}>{getPeriodName(name)}</Tag>)
        }
        if (name.includes('HALF')) {
            return (<Tag className='period-name' color="orange" key={`h_${name}`} onClick={() => handlePeriodClicked(name)}>{getPeriodName(name)}</Tag>)
        }
        if (name.includes('YEAR')) {
            return (<Tag className='period-name' color="purple" key={`y_${name}`} onClick={() => handlePeriodClicked(name)}>{getPeriodName(name)}</Tag>)
        }
        if (name.includes('TIME')) {
            return (<Tag className='period-name' color="red" key={`mt_${name}`} onClick={() => handlePeriodClicked(name)}>{getPeriodName(name)}</Tag>)
        }
        return (<Tag className='period-name' color="gold" key={`x_${name}`} onClick={() => handlePeriodClicked(name)}>{getPeriodName(name)}</Tag>)
    }

    const handlePeriodClicked = (name: string) => {
        const range: [Moment, Moment] = getRanges(name)
        const start = range[0].format(SQL_DATE_FORMAT)
        const end = range[1].format(SQL_DATE_FORMAT)
        setStartDate(new Date(start))
        setEndDate(new Date(end))
        setOpen(false)
    }

    const getPeriods = () => {
        return (
            <div>
                <div style={{ display: 'flex' }}>{FILTER_PERIOD_BASIC.filter(n => n.includes('MONTH')).map(n => renderPeriod(n))}</div>
                <div style={{ display: 'flex' }}>{FILTER_PERIOD_BASIC.filter(n => n.includes('QUARTER')).map(n => renderPeriod(n))}</div>
                <div style={{ display: 'flex' }}>{FILTER_PERIOD_BASIC.filter(n => n.includes('HALF')).map(n => renderPeriod(n))}</div>
                <div style={{ display: 'flex' }}>{FILTER_PERIOD_BASIC.filter(n => n.includes('YEAR')).map(n => renderPeriod(n))}</div>
                <div style={{ display: 'flex' }}>{FILTER_PERIOD_BASIC.filter(n => n.includes('TIME')).map(n => renderPeriod(n))}</div>
            </div>
        )
    }

    const getRanges = (v): [Moment, Moment] => {
        // returns Date from the name
        switch (v) {
            case 'ALL_TIME':
                return [moment('2000-01-01').startOf('year'), moment().endOf('year')]
            case 'ACT_WEEK':
                return [moment().startOf('isoWeek'), moment().endOf('isoWeek')]
            case 'ACT_MONTH':
                return [moment().startOf('month'), moment().endOf('month')]
            case 'ACT_QUARTER':
                return [moment().startOf('quarters'), moment().endOf('quarter')]
            case 'ACT_HALF':
                if (actHalfYear === 1)
                    return [moment().startOf('year'), moment().startOf('year').month(5).endOf('month')]
                else
                    return [moment().startOf('year').month(7), moment().endOf('year')]

            case 'ACT_YEAR':
                return [moment().startOf('year'), moment().endOf('year')]

            case 'PREV_WEEK':
                return [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')]
            case 'PREV_MONTH':
                return [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            case 'PREV_QUARTER':
                return [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')]
            case 'PREV_YEAR':
                return [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]

            case 'FIRST_HALF_AGO':
                if (actHalfYear === 1)
                    return [moment().subtract(1, 'year').month(6).startOf('month'), moment().subtract(1, 'year').endOf('year')]
                if (actHalfYear === 2)
                    return [moment().startOf('year'), moment().month(5).endOf('month')]
                break
            case 'SECOND_HALF_AGO':
                if (actHalfYear === 1)
                    return [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').month(5).endOf('year')]
                if (actHalfYear === 2)
                    return [moment().subtract(1, 'year').month(6).startOf('month'), moment().subtract(1, 'year').endOf('year')]
                break
            case 'THIRD_HALF_AGO':
                if (actHalfYear === 1)
                    return [moment().subtract(2, 'year').month(6).startOf('month'), moment().subtract(2, 'year').endOf('year')]
                if (actHalfYear === 2)
                    return [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').month(5).endOf('month')]
                break

            case 'TWO_MONTH_AGO':
                return [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')]
            case 'TWO_QUARTER_AGO':
                return [moment().subtract(2, 'quarter').startOf('quarter'), moment().subtract(2, 'quarter').endOf('quarter')]
            case 'TWO_YEAR_AGO':
                return [moment().subtract(2, 'year').startOf('year'), moment().subtract(2, 'year').endOf('year')]

            case 'THREE_MONTH_AGO':
                return [moment().subtract(3, 'month').startOf('month'), moment().subtract(3, 'month').endOf('month')]
            case 'THREE_QUARTER_AGO':
                return [moment().subtract(3, 'quarter').startOf('quarter'), moment().subtract(3, 'quarter').endOf('quarter')]
            case 'THREE_YEAR_AGO':
                return [moment().subtract(3, 'year').startOf('year'), moment().subtract(3, 'year').endOf('year')]

            case 'MONTH_BEFORE_YEAR':
                return [moment().subtract(1, 'year').startOf('month'), moment().subtract(1, 'year').endOf('month')]
            case 'QUARTER_BEFORE_YEAR':
                return [moment().subtract(1, 'year').startOf('quarter'), moment().subtract(1, 'year').endOf('quarter')]
        }
        return [moment().startOf('year'), moment().endOf('year')]
    }

    useEffect(() => {
        if (!startDate) {
            setStartDateStr('')
        }
        else {
            if (!endDate) {
                setEndDateStr('')
            }
            else {
                const startDt = formatISO(startDate)
                if (startDt != startDateStr) {
                    setStartDateStr(startDt)
                    setStartDateOrig(startDate)
                }
                const endDt = formatISO(endDate)
                if (endDt != endDateStr) {
                    setEndDateStr(endDt)
                    setEndDateOrig(endDate)
                }
                if (startDt != startDateStr || endDt != endDateStr) {
                    props.onChange(startDt, endDt)  // raise event
                }
            }
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (props.initStart) {
            if (props.initStart != startDateStr) {
                setStartDate(new Date(props.initStart))
            }
            setStartDateOrig(new Date(props.initStart))
        }
    }, [props.initStart]);

    useEffect(() => {
        if (props.initEnd) {
            if (props.initEnd != endDateStr) {
                setEndDate(new Date(props.initEnd))
            }
            setEndDateOrig(new Date(props.initEnd))
        }
    }, [props.initEnd]);

    const onKeyDownStart = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false)
        }
        if (e.keyCode === 13 || e.which === 13) {
            endRef.current!.input.focus()
        }
    }

    const onKeyDownEnd = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            endRef.current.setOpen(false);
        }
    }

    const handleCalendarStartClose = () => {
        if (!startDate) {
            setStartDate(startDateOrig)
        }
        if (!endDate) {
            setEndDate(endDateOrig)
        }
    }

    const handleCalendarEndClose = () => {
        if (!startDate) {
            setStartDate(startDateOrig)
        }
        if (!endDate) {
            setEndDate(endDateOrig)
        }
    }

    return (
        <table className="date-range-box" width={showClear ? 320 : 280}>
            <tbody>
            <tr>
                <td valign='top' align='center' style={{ width: 28, paddingLeft: '5px', paddingTop: '4px' }}>
                    <Popover placement="bottom" overlayStyle={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
                             content={getPeriods()}
                             title={t('RangeFilter.choose_period')}
                             trigger="click"
                             visible={open}
                             onVisibleChange={handleOpenChange}
                    >
                        <CalendarOutlined color='gray' style={{ color: '#aaaaaa', cursor: 'pointer' }} onClick={e => e.preventDefault()} />
                    </Popover>
                </td>
                <td>
                    <DatePicker locale={getLanguage()}
                                id='date_start'
                                ref={startRef}
                                selected={startDate}
                                onChange={(dt) => dt && setStartDate(dt)}
                                onKeyDown={onKeyDownStart}
                                onCalendarClose={handleCalendarStartClose}
                                shouldCloseOnSelect={true}
                                dateFormat={props.format}   // ReactDatePicker is not using Moment formats! use d.M.yyyy
                                selectsStart
                                popperProps={{ strategy: 'fixed' }}
                                className="date-range-start"
                                tabIndex={1}
                                autoComplete='off'
                                placeholderText='start'
                    />
                </td>
                <td valign='top' align='center' style={{ width: 28, paddingLeft: '6px', paddingTop: '4px' }}>
                    <SwapOutlined width={24} color='gray' style={{ color: '#aaaaaa' }} />
                </td>
                <td>
                    <DatePicker locale={getLanguage()}
                                id='date_end'
                                ref={endRef}
                                selected={endDate}
                                onChange={(dt) => dt && setEndDate(dt)}
                                onKeyDown={onKeyDownEnd}
                                onCalendarClose={handleCalendarEndClose}
                                dateFormat={props.format}   // ReactDatePicker is not using Moment formats! use d.M.yyyy
                                selectsEnd
                                popperProps={{ strategy: 'fixed' }}
                                className="date-range-end"
                                tabIndex={2}
                                autoComplete='off'
                                placeholderText='end'
                    />
                </td>
                <td valign='top'>
                    <Button title='Clear' size='small'
                            onClick={() => {
                                setStartDate(undefined)
                                setEndDate(undefined)
                                startRef.current!.input.focus()
                                // console.log('onClick: '+inputRef.current.input.id)
                            }}
                            icon={<EditTwoTone twoToneColor='green' width={24} style={{ color: '#CC6666' }} />}
                            className='actionButton'
                            style={{ cursor: 'hand', marginTop: '5px', marginLeft: '6px', marginRight: 0, paddingRight: 0 }}
                    />
                    {
                        showClear && (
                            <Button title='Clear' size='small'
                                    onClick={() => handlePeriodClicked('ALL_TIME')}
                                    icon={<CloseCircleOutlined width={24} color='red' style={{ color: '#CC6666' }} />}
                                    className='actionButton'
                                    style={{ cursor: 'hand', marginTop: '5px', marginLeft: '1px', marginRight: 0, paddingRight: 0 }}
                            />
                        )
                    }

                </td>
            </tr>
            </tbody>
        </table>
    )
}

export default DateRange
