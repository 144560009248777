import {
    GetAllComponentsRequestAction,
    GetAllComponentsResponseAction,
    GetAllComponentsErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import {InventoryModel} from "../../inventory/models";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";

export default (filter: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetAllComponentsRequestAction
                | GetAllComponentsResponseAction
                | GetAllComponentsErrorAction,
        ) => void,
    ) => {
        const request = new GetAllComponentsRequestAction()
        dispatch(request)

        protectedApiClient
            .get<InventoryModel[]>(`/inventory/inventory?${filter}`)
            .then((response) => {
                dispatch(new GetAllComponentsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetAllComponentsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
