import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    GetInventoryPageLocationsRequestAction,
    GetInventoryPageLocationsResponseAction,
    GetInventoryPageLocationsErrorAction,
} from '.'
import {InventoryLocationModel} from "../../location/models";


export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetInventoryPageLocationsRequestAction
                | GetInventoryPageLocationsResponseAction
                | GetInventoryPageLocationsErrorAction,
        ) => void,
    ) => {
        const request = new GetInventoryPageLocationsRequestAction()
        dispatch(request)

        protectedApiClient
            .get<InventoryLocationModel[]>('/inventory/location')
            .then((response) => {
                dispatch(
                    new GetInventoryPageLocationsResponseAction(request, response.data),
                )
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetInventoryPageLocationsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
