import {Card, Col, message, Modal, Row, Space, Switch, Table} from "antd";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../common/models";
import React, {useEffect, useRef, useState} from "react";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import usePageSize from "../../../common/usePageSize";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import Button from "antd-button-color";
import {CheckOutlined, CloseOutlined, DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import {PlusCircleOutlined, ShopOutlined} from "@ant-design/icons/lib/icons";
import {DeleteDnsZoneParams, DnsRecordModel, RemoveDnsRecordParams} from "./models";
import {Link, useParams} from "react-router-dom";
import {useHistory} from "react-router";
import removeDnsRecord from "./actions/removeDnsRecord";
import {sort_searchable} from "../../../common/sorting";
import deleteDnsZone from "./actions/deleteDnsZone";

import {_addLastComma, _remLastComma} from "../../../common/fce";
import getZone from "./actions/getZone";
import ZoneEditRecordForm from "./ZoneEditRecordForm";
import getDnsService from "./actions/getDnsService";
import useLogger from "../../../common/useLogger";

interface ParamTypes {
    service_url: string
    domain_url: string
}

const { confirm } = Modal

const ZoneRecordsPage = () => {
    const CONTROL_NAME = 'page_zone_dns_records'
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const {service_url, domain_url} = useParams<ParamTypes>()

    const {isLoadingZone, isLoadingDomain, dnsZone, dnsRecords, dnsService} = useSelector((state: AppState) => state.dnsservice)

    const [dataSource, setDataSource] = useState<DnsRecordModel[]>()
    const [selectedRecord, setSelectedRecord] = useState<DnsRecordModel>()
    const [pageSize, setPageSize] = useState<string>()
    const [isRecordModalVisible, setRecordModalVisible] = useState(false)

    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    // permissions
    const [isZoneRecordsViewer, setZoneRecordsViewer] = useState(false)
    const [isZoneRecordsCreator, setZoneRecordsCreator] = useState(false)
    const [isZoneRecordsEditor, setZoneRecordsEditor] = useState(false)
    const [isZoneRecordsDeleter, setZoneRecordsDeleter] = useState(false)
    const [isZoneDeleter, setZoneDeleter] = useState(false)


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }


    // settings
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = useLogger(appSetting, 'ZoneRecordsPage')
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        // load servicetypes from API
        // trigger ONCE
        const access = loggedUser.hasAccess('page_zone_dns_records')
        setZoneRecordsViewer(access)
        setZoneRecordsCreator(loggedUser.hasAccess('page_zone_dns_records_create_button'))
        setZoneRecordsEditor(loggedUser.hasAccess('page_zone_dns_records_edit_button'))
        setZoneRecordsDeleter(loggedUser.hasAccess('page_zone_dns_records_delete_button'))
        setZoneDeleter(loggedUser.hasAccess('page_dns_zones_delete_button'))
        if (!access) {
            // failover 403
            history.replace('/')
        }

        let sid = parseInt(service_url)
        if (!sid) {
            // failover 404
            message.error('Missing dns service.')
            history.replace('/dns/services')
            return
        }

        if (!dnsService) {
            // failover 404
            dispatch(getDnsService(sid))
            return
        }

        // dnsService is selected
        logger('domain_url: '+domain_url)
        dispatch(getZone(dnsService.id, domain_url))
    }, [])

    useEffect(() => {
        logger('dnsZone: '+JSON.stringify(dnsZone))
        if (dnsService) {
            dispatch(getZone(dnsService.id, domain_url))
        }
    }, [dnsService])

    useEffect(() => {
        logger('dnsZone: '+JSON.stringify(dnsZone))
        if (dnsZone && _remLastComma(dnsZone.zone).toLowerCase() === domain_url.toLowerCase()) {
            const data = dnsZone.records.map(rec => {return {...rec, searchableName: getRecordOrder(rec)}})
            setDataSource(data.sort(sort_searchable))
        }
    }, [dnsZone])

    const getRecordOrder = (rec: DnsRecordModel):string => {
        if (rec.type === 'SOA') {
            return '1'
        }
        if (rec.type === 'NS') {
            return '2'
        }
        return rec.type
    }

    const showConfirmDelete = (r: DnsRecordModel, title: string) => {
        if (!r || !dnsService) {
            return
        }

        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{r.type} {r.name} {r.content}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    const params: RemoveDnsRecordParams = {
                        id: dnsService.id,
                        domain: domain_url,
                        digest: r.digest!
                    }
                    dispatch(removeDnsRecord(params, suc => {
                        dispatch(getZone(dnsService.id, domain_url))
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        suc && message.success(t('general.success'))
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }


    const showConfirmDeleteZone = (zone: string | undefined, title: string) => {
        if (!zone || !dnsService) {
            return
        }

        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{zone}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    const params: DeleteDnsZoneParams = {
                        id: dnsService.id,
                        zone: domain_url,
                    }
                    dispatch(deleteDnsZone(params, suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        suc && message.success(t('general.success'))
                        history.replace('/dns/domains')
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }


    const columns = [
        {
            title: t('dnsPage.record_type'),
            key: 'type',
            dataIndex: 'type',
        },
        {
            title: t('dnsPage.record_name'),
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: t('dnsPage.record_content'),
            key: 'content',
            dataIndex: 'content',
        },
        {
            title: t('dnsPage.ttl'),
            key: 'ttl',
            dataIndex: 'ttl',
        },
        {
            title: t('general.actions'),
            key: 'action',
            width: '100px',
            dataIndex: 'action',
            render: (text: string, rec: DnsRecordModel) => (
                <Space size='small'>
                    <Button type='text' size='small'
                            disabled={!isZoneRecordsEditor}
                            onClick={() => {
                                setSelectedRecord(rec)
                                setRecordModalVisible(true)
                            }}
                            className='actionButton'
                            icon={<EditTwoTone twoToneColor={isZoneRecordsEditor ? "green" : "#ccc"} />}
                    />
                    <Button type='text' danger size='small'
                            disabled={!isZoneRecordsDeleter}
                            className='actionButton'
                            onClick={() => showConfirmDelete(rec, t('zonesPage.confirm_delete'))}
                            icon={<DeleteTwoTone twoToneColor={isZoneRecordsDeleter ? "red" : "#ccc"}/>}
                    />
                </Space>
            ),
        },
    ]

    return (
        <>
            <Card
                title={
                    <Row>
                        <Col span={10}>
                            {t('dnsPage.zone') + ': '}<b>{domain_url}</b>
                            <Button type='text' danger size='small'
                                    className='actionButton'
                                    disabled={!isZoneDeleter}
                                    onClick={() => showConfirmDeleteZone(domain_url, t('zonesPage.confirm_delete'))}
                                    icon={<DeleteTwoTone twoToneColor={isZoneDeleter ? "red" : "#ccc"} title={t('dnsPage.remove_zone')}/>} />
                        </Col>
                        <Col span={8}>
                            <span style={{fontSize: '0.8em'}}>
                                DNS: <Link className='linkUnder' to={`/dns/services/${dnsService?.id}`}>{dnsService?.host}</Link>
                            </span>
                        </Col>
                        <Col span={6}><span style={{fontSize: '0.8em'}}>DNSSEC:&nbsp;</span>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={true}
                                disabled={true}
                                onChange={() => {}}
                            />
                        </Col>
                    </Row>
                }
                extra={
                    <Button
                        type='primary'
                        disabled={!isZoneRecordsCreator}
                        onClick={() => {
                            if (isZoneRecordsCreator) {
                                setSelectedRecord(undefined)
                                setRecordModalVisible(true)
                            }
                        }}>
                        <PlusCircleOutlined /> {t('dnsPage.create_record')}
                    </Button>
                }
                className='DnsRecordPage'
            >
                {
                    appSetting &&
                    <Table<DnsRecordModel>
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columns}
                        loading={isLoadingDomain || isLoadingZone}
                        dataSource={dataSource}
                        rowKey={(record) => `${record.type}-${record.name}-${record.content}`}
                        showHeader={true}
                        pagination={false}
                        onChange={(ev) => {setPageSize(`${ev.pageSize}`)}}
                    />
                }

            </Card>

            <Modal
                destroyOnClose={true}
                style={{top: 20}}
                title={
                    <>
                        <ShopOutlined />{' '} {t('dnsPage.zone') } {' '} <b>{domain_url}</b>
                    </>
                }
                width={600}
                visible={isRecordModalVisible}
                onCancel={() => setRecordModalVisible(false)}
                maskClosable={false}
                footer={null}>
                <ZoneEditRecordForm
                    zone={domain_url}
                    digest={selectedRecord?.digest}
                    type={selectedRecord?.type}
                    name={selectedRecord?.name}
                    content={selectedRecord?.content}
                    ttl={selectedRecord?.ttl}
                    is_new={!selectedRecord}
                    closeModal={() => setRecordModalVisible(false)}
                />
            </Modal>

        </>
    )
}

export default ZoneRecordsPage

