import {
    UpdateContactRequestAction,
    UpdateContactResponseAction,
    UpdateContactErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ContactModel } from '../models'

export default (params: ContactModel, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | UpdateContactRequestAction
                | UpdateContactResponseAction
                | UpdateContactErrorAction,
        ) => void,
    ) => {
        const request = new UpdateContactRequestAction(params)
        dispatch(request)

        protectedApiClient
            .put<ContactModel>(`/contact/${params.id}`, params)
            .then((response) => {
                dispatch(new UpdateContactResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateContactErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
