import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {EndpointParams, RoleModel} from "../models";
import {UpdateEndpointErrorAction, UpdateEndpointRequestAction, UpdateEndpointResponseAction} from "./index";


export default (
    params: EndpointParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | UpdateEndpointRequestAction
                | UpdateEndpointResponseAction
                | UpdateEndpointErrorAction,
        ) => void,
    ) => {
        const request = new UpdateEndpointRequestAction(params)
        dispatch(request)
        protectedApiClient
            .put<RoleModel>(
                `/role/${params.casbin_key}/endpoint?expand=endpoints`,
                params,
            )
            .then((response) => {
                dispatch(
                    new UpdateEndpointResponseAction(
                        request,
                        response.data,
                    ),
                )
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateEndpointErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
