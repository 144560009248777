export interface CostAllocationCategoryState {
  isLoading: boolean;
  isSaving: boolean;
  costAllocationCategory?: CostAllocationCategoryDetails;
  costAllocationCategories: CostAllocationCategoryDetails[];
  error?: string;
}

export interface CostAllocationCategoryDetails {
  id?: number;
  name: string;
  typ?: string;
}

export interface CreateCostAllocationParam {
  name: string;
  typ?: string;
}

export interface UpdateCostAllocationParams {
  id: number;
  name: string;
  type?: string;
}

export const typeCategory = ["InvoiceCategory", "BillCategory"];
