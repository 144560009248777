import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {IdPayload, InvoiceDetails} from '../models'
import {FinalizeInvoiceErrorAction, FinalizeInvoiceRequestAction, FinalizeInvoiceResponseAction} from "./index";

export default (params: IdPayload, cb?: (isSuccess: boolean, document: InvoiceDetails) => void) => {
  return (
    dispatch: (args: FinalizeInvoiceRequestAction | FinalizeInvoiceResponseAction | FinalizeInvoiceErrorAction) => void
  ) => {
    const request = new FinalizeInvoiceRequestAction(params)
    dispatch(request)

    protectedApiClient.post<InvoiceDetails>(`/billing/invoice/${params.id}/finalize`, params)
      .then(response => {
        dispatch(new FinalizeInvoiceResponseAction(request, response.data))
        cb && cb(true, response.data)
      })
      .catch(error => {
        dispatch(new FinalizeInvoiceErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false, error)
      })
  }
}
