import React, { useEffect } from 'react'
import './HypervisorsPage.scss'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { HypervisorModel, stateHypervisorSetting, VpsHelper } from './models'
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import { AutoComplete, Card, Col, message, Row, Space, Select, Table, Tag } from "antd"
import { PAGING } from "../../common/enums"
import TotalNum from "../../components/TotalNum/TotalNum"
import { OneToOneOutlined } from "@ant-design/icons"
import getHypervisorContainers from './actions/getHypervisorContainers'
import { Link } from 'react-router-dom'
import getVpsContainers from './actions/getVpsContainers'
import {getPercent, stopPropagation} from "../../common/fce";
import {ColumnsType} from "antd/lib/table";
import {sort_name, sort_number, sort_str} from "../../common/sorting";
import usePageSize from "../../common/usePageSize";


const sort_procentsRam = (a, b) => {
    let procentA = getPercent(a.ram_total - a.ram_free, a.ram_total)
    let procentB = getPercent(b.ram_total - b.ram_free, b.ram_total)
    return procentA - procentB
}

const HypervisorsPage = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [dataSource, setDataSource] = useState<any[]>([])
    const [searchName, setSearchName] = useState('')
    const [searchName2, setSearchName2] = useState('')
    const [searchHost, setSearchHost] = useState('')
    const [searchHost2, setSearchHost2] = useState('')
    const [searchPhysicalId, setSearchPhysicalId] = useState('')
    const [searchVersion, setSearchVersion] = useState('')

    const [searchState, setSearchState] = useState<number>()
    const [isModalVisible, setModalVisible] = useState(false)
    const { hypervisors, isLoading, vpsServers } = useSelector((state: AppState) => state.vpsadmin)
    const [pageSize, setPageSize] = useState<string>()

    const { Option } = Select
    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // logger
    const logger = (msg) => {if (appSetting && appSetting.debug) { console.log('HypervisorsPage: ' + msg)}}

    useEffect(() => {
        // load hypervisors from API
        // trigger ONCE
        dispatch(getHypervisorContainers())
        dispatch(getVpsContainers())
        logger('getHypervisorContainers..')
        logger('getVpsContainers..')
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }
    }, [searchName2, searchHost2, searchVersion, searchPhysicalId, searchState, hypervisors, isModalVisible])

    const refreshGrid = () => {
        let patternName = ''
        let patternHost = ''
        let patternVersion = ''
        let patternPhysicalId, patternState

        if (searchName2) {
            patternName = searchName2.replace(/\s/g, '')
        }
        if (searchHost2) {
            patternHost = searchHost2.replace(/\s/g, '')
        }
        if (searchPhysicalId) {
            patternPhysicalId = searchPhysicalId.replace(/\s/g, '')
        }
        if (searchVersion) {
            patternVersion = searchVersion.replace(/\s/g, '')
        }
        if (searchState) {
            patternState = searchState
        }

        const newData = filtered(patternName, patternHost, patternPhysicalId, patternVersion, patternState)
        logger(`refreshGrid name: ${patternName}, name: ${patternHost},  hypervisors: ${hypervisors?.length}, dataSource: ${newData.length}`)
        setDataSource(newData)
    }

    const filtered = (name: string, host: string, physicalId: number, version: string, state) => {
        if (!hypervisors) {
            return []
        }

        const qName = (h) => h.name.toLowerCase().includes(name.toLowerCase())
        const qHost = (h) => h.host.toLowerCase().includes(host.toLowerCase())
        const qPhysicalId = (h) => h.physical_id == physicalId
        const qVersion = (h) => h.version.toLowerCase().includes(version.toLowerCase())
        const qState = (h) => h.state == state

        let result = hypervisors
        if (name) {
            result = result.filter((t) => qName(t))
        }
        if (host) {
            result = result.filter((t) => qHost(t))
        }
        if (version) {
            result = result.filter((t) => qVersion(t))
        }
        if (physicalId) {
            result = result.filter((t) => qPhysicalId(t))
        }
        if (state) {
            result = result.filter((t) => qState(t))
        }
        return result.sort(sort_name)
    }

    const renderCount = (rec: HypervisorModel) => {
        let cpus = usedCpus(rec.host)
        if (cpus > rec.cpu_count) {
            return (<Tag color='error' >{cpus} </Tag>)
        }
        if (cpus === rec.cpu_count) {
            return (<Tag color='blue' >{cpus} </Tag>)
        }
        return (<Tag color='green' >{cpus} </Tag>)
    }

    const usedCpus = (name) => {
        let result = 0
        if (vpsServers) {
            vpsServers.map(elem => {
                if (elem['host'] == name) {
                    result = result + elem.cpu_occupied
                }
            })
        }
        return result
    }

    const getTotalUesdMemory = (storages) => {
        let result = 0
        storages.map((item) => {
            result = result + item.free_space
        })
        return VpsHelper.transformToGB(result)
    }

    const sort_cpusUsed = (a, b) => {
        let usedCpusA = usedCpus(a.host)
        let usedCpusB = usedCpus(b.host)
        return usedCpusA - usedCpusB
    }

    const sort_procentsCpus = (a, b) => {
        let procentA = getPercent(usedCpus(a.host), a.cpu_count)
        let procentB = getPercent(usedCpus(b.host), b.cpu_count)
        return procentA - procentB
    }

    const FilterByNameInput = (
        <AutoComplete
            placeholder={t('vpsadmin.hypervisors.name')}
            style={{ width: '160px' }}
            value={searchName}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(currValue) => {
                setSearchName(currValue)
                if (currValue && currValue.length > 2) {
                    setSearchName2(currValue)
                }
                else {
                    setSearchName2('')
                }
            }}
        />
    )

    const FilterByHostInput = (
        <AutoComplete
            placeholder={t('vpsadmin.hypervisors.host')}
            style={{ width: '160px' }}
            value={searchHost}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(currValue) => {
                setSearchHost(currValue)
                if (currValue && currValue.length > 2) {
                    setSearchHost2(currValue)
                }
                else {
                    setSearchHost2('')
                }
            }}
        />
    )

    const FilterByPhysicalIdInput = (
        <AutoComplete
            placeholder='ID'
            style={{ width: '50px' }}
            allowClear={true}
            value={searchPhysicalId}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(currValue) => {
                setSearchPhysicalId(currValue)
            }}
        />
    )

    const FilterByVersionInput = (
        <AutoComplete
            placeholder={t('vpsadmin.hypervisors.version')}
            style={{ width: '60px' }}
            allowClear={true}
            value={searchVersion}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(currValue) => {
                setSearchVersion(currValue)
            }}
        />
    )

    const FilterByStatusInput = (
        <Select
            style={{ width: 80 }}
            placeholder={t('vpsadmin.vps.state')}
            allowClear={true}
            onChange={(currValue) => {
                setSearchState(VpsHelper.reConvertedState(currValue))
            }} >
            {
                stateHypervisorSetting.map((value) => {
                    return <Option value={value} key={value}>{value}</Option>
                })
            }
        </Select>
    )

    const columns: ColumnsType<HypervisorModel> = [
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            key: 'name',
            className: 'left',
            width: 160,
            fixed: 'left',
            render: (text, record) => (
                <Link to={`/vpsadmin/hypervisor/${record.id}`}>
                    {text}
                </Link>
            ),
            sorter: (a, b) => sort_name(a, b),
        },
        {
            title: FilterByHostInput,
            dataIndex: 'host',
            key: 'host',
            className: 'left ',
            sorter: (a, b) => sort_str(a.host, b.host),
        },
        {
            title: FilterByPhysicalIdInput,
            dataIndex: 'physical_id',
            key: 'physical_id',
            className: 'center',
        },
        {
            title: t('vpsadmin.vps.cpuused'),
            dataIndex: 'cpu_count',
            key: 'cpu_used',
            className: 'center cpu',
            width: '5%',
            render: (item, rec) => renderCount(rec),
            sorter: (a, b) => sort_cpusUsed(a, b),

        },
        {
            title: t('vpsadmin.vps.cpuusedprocent'),
            dataIndex: 'cpu_count',
            key: 'cpu_procent',
            className: 'right cpu',
            width: '5%',
            render: (item, record) => {
                return getPercent(usedCpus(record.host), item) + '%'
            },
            sorter: (a, b) => sort_procentsCpus(a, b),
        },
        {
            title: t('vpsadmin.vps.cputotal'),
            dataIndex: 'cpu_count',
            key: 'cpu_count',
            className: 'right cpu',
            width: '5%',
            sorter: (a, b) => sort_number(a.cpu_count, b.cpu_count),

        },
        // RAM
        {
            title: t('vpsadmin.vps.ramused'),
            dataIndex: 'ram_total',
            key: "ram_used",
            className: 'right',
            width: '5%',
            render: (_, record: HypervisorModel) => (
                <>
                    {VpsHelper.transformToGB(record.ram_total - record.ram_free)}
                    {` ${t('vpsadmin.gb')}`}
                </>
            ),
            sorter: (a, b) => VpsHelper.sort_numbers(a.ram_total - a.ram_free, b.ram_total - b.ram_free),
        },
        {
            title: t('vpsadmin.vps.ramusedprocent'),
            dataIndex: 'ram_total',
            key: "ram_procent",
            className: 'right',
            width: '5%',
            render: (_, record: HypervisorModel) => (
                <>
                    {getPercent(record.ram_total - record.ram_free, record.ram_total) + "%"}
                </>
            ),
            sorter: (a, b) => sort_procentsRam(a, b),
        },
        {
            title: t('vpsadmin.vps.ramtotal'),
            dataIndex: 'ram_total',
            key: "ram_total",
            className: 'right',
            width: '5%',
            render: (_, record: HypervisorModel) => (
                <>
                    {VpsHelper.transformToGB(record.ram_total)}
                    {` ${t('vpsadmin.gb')}`}
                </>
            ),
            sorter: (a, b) => VpsHelper.sort_numbers(a.ram_total, b.ram_total),
        },
        {
            title: t('vpsadmin.vps.disk'),
            dataIndex: 'disk_total',
            className: 'right',
            width: '10%',
            render: (_, record: HypervisorModel) => {
                return (
                    <>{getTotalUesdMemory(record.storages) + "/"}
                        {VpsHelper.transformToGB(record.disk_total)}
                        {` ${t('vpsadmin.gb')}`}
                    </>
                )
            },
            sorter: (a, b) => sort_number(a.disk_total, b.disk_total),
        },
        {
            title: FilterByVersionInput,
            dataIndex: 'version',
            className: 'center ',
            sorter: (a, b) => sort_str(a.version, b.version),
        },
        {
            title: FilterByStatusInput,
            dataIndex: 'state',
            className: 'center ',
            render: (_, record: HypervisorModel) => (
                <>
                    {VpsHelper.convertedState(record.state)}
                </>
            )
        },
        {
            title: t('vpsadmin.hypervisors.updated'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (text) => text && <span style={{whiteSpace: 'nowrap'}}>{appSetting.renderDateTime(text)}</span>
        },
        {
            title: t('vpsadmin.hypervisors.uptime'),
            dataIndex: 'uptime',
            key: 'uptime',
            className: 'right align',
            width: '5%',
            render: (_, record: HypervisorModel) => (
                <>
                    {VpsHelper.transformToDays(record.uptime) + ' d'}
                </>
            ),
            sorter: (a, b) => sort_number(a.uptime, b.uptime),
        },
    ]


    return (
        <>
            <Card className='VpsAdminPage'
                title={
                    <Row>
                        <Col span={6}>
                            <OneToOneOutlined />{` ${t('vpsadmin.hypervisors.title')}`}
                        </Col>
                    </Row>}
            >
                {
                    appSetting &&
                    <Table
                        bordered={true}
                        columns={columns}
                        loading={isLoading}
                        dataSource={dataSource}
                        className='HypervisorsTable'
                        rowKey='id'
                        rowClassName={(rec) => {
                            if (!rec) {
                                return ''
                            }
                            let cpus = usedCpus(rec.host)
                            if (cpus > rec.cpu_count) {
                                return 'high'
                            }
                            if (cpus === rec.cpu_count) {
                                return 'middle'
                            }
                            return 'low'
                        }}
                        pagination={{
                            defaultPageSize: appSetting?.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => { setPageSize(`${ev.pageSize}`) }}
                        footer={() => TotalNum(Number(dataSource?.length), 'Hypervisors', dataSource)}
                    />
                }
            </Card>
        </>
    )
}

export default HypervisorsPage
