import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    GetInventoryPageTypesRequestAction,
    GetInventoryPageTypesResponseAction,
    GetInventoryPageTypesErrorAction,
} from '.'
import {InventoryTypeModel} from "../../type/models";


export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetInventoryPageTypesRequestAction
                | GetInventoryPageTypesResponseAction
                | GetInventoryPageTypesErrorAction,
        ) => void,
    ) => {
        const request = new GetInventoryPageTypesRequestAction()
        dispatch(request)

        protectedApiClient
            .get<InventoryTypeModel[]>('/inventory/type?expand=usables')
            .then((response) => {
                dispatch(new GetInventoryPageTypesResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetInventoryPageTypesErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
