import React, {useEffect, useState} from 'react'
import {Card, Table, Input, Select} from 'antd'
import {useTranslation} from 'react-i18next'
import {AppState} from 'common/models'
import {useDispatch, useSelector} from 'react-redux'
import {ColumnsType} from 'antd/lib/table'
import getMails from './actions/getMails'
import {MailDetails} from './models'
import {MailOutlined} from "@ant-design/icons";
import {Link, useLocation} from 'react-router-dom'
import updateSetting from "../../login/actions/updateSetting"
import TotalNum from "../../../components/TotalNum/TotalNum";
import {PAGING} from "../../../common/enums";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import queryString, {ParsedQuery} from "query-string";
import {_isNotEmptyObject, removeDiac, stopPropagation} from "../../../common/fce";
import getCustomers from "../customer/actions/getCustomers";
import {useHistory} from "react-router";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {sort_create, sort_label} from "../../../common/sorting";
import usePageSize from "../../../common/usePageSize";

export enum MAIL_STATE {
  STATE_PREPARED = 1,
  STATE_SENT = 2,
  STATE_ERROR = 3
}


const MailPage = () => {
  const CONTROL_NAME = 'page_billing_mail'
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const {search} = useLocation()

  const {mails, isLoading} = useSelector((state : AppState) => state.mail)
  const {customers} = useSelector((state: AppState) => state.auth.tables)

  const [searchableMails, setSearchableMails] = useState<MailDetails[]>()
  const [dataSource, setDataSource] = useState<MailDetails[]>()
  const [pageSize, setPageSize] = useState<string>()

  const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
  //const [customerName, setCustomerName] = useState<string>('')
  const [custOptions, setCustOptions] = useState<{ label: string, value: number }[]>([])
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>(0)

  // get settings and logged user from store
  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    return (
        <div className="fullwidth-loading" >
          <LoadingIndicator/>
        </div>
    )
  }

  // required authorization
  if (!loggedUser.hasAccess(CONTROL_NAME)) {
    return <ErrorPage403 />
  }

  // settings
  const appSetting = loggedUser.getAppSettings()
  usePageSize(appSetting, loggedUser.user.id, pageSize)

  // logger
  const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('MailPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('MailPage: ' + msg)} }


  useEffect(() => {
    // Customer Options
    if (customers && customers.length) {
      if ( loggedUser.user.is_zcom ) {
        const items = customers.map(c => ({label: c.company!.name ? c.company!.name : '', value: c.id}))
        setCustOptions(items.sort(sort_label))
      }
      else {
        setCustOptions([])
      }
      setSelectedCustomerId(loggedUser.user.customer_id)
    }
    else {
      dispatch(getCustomers( 'company,last_invoice'))
    }
  }, [customers])

  useEffect(() => {
    if (selectedCustomerId) {
      dispatch(getMails(selectedCustomerId, suc => {
        handleQueryStringFilters()
      }))
    }
    else {
      dispatch(getMails(0, suc => {
        handleQueryStringFilters()
      }))
    }
  },[selectedCustomerId])

  useEffect(() => {
    // update datasource when data was changed    => create mail.searchableName
    if (customers && customers.length && mails && mails.length) {
      setSearchableMails(mails.map(m => {
        const cust = customers.find((c) => c.id === m.customer_id)
        let name = cust && cust.company!.name ? cust.company!.name : ''
        return {...m, customerName: name, searchableName: removeDiac(name)}
      }))
    }
  }, [customers, mails])

  useEffect(() => {
    refreshGrid()
  }, [searchableMails, selectedCustomerId])

  const handleQueryStringFilters = () => {
    // /mails?name=com&customer_id=1
    if (parsed && _isNotEmptyObject(parsed)) {
      const qs_customer_id: string | string[] | null = parsed['customer_id']
      if (qs_customer_id && typeof qs_customer_id === 'string') {
        const cid = parseInt(qs_customer_id)
        if (cid > 0) {
          const cc = customers.find((c) => c.id === cid)
          if (cc) {
            setSelectedCustomerId(cc.id)
          }
        }
      }
    }
  }

  const filtered = () => {
    if (!searchableMails || searchableMails.length === 0) {
      return []
    }

    let qs:string[] = []
    let data = searchableMails
    if (selectedCustomerId) {
      data = data.filter((m) => m.customer_id === selectedCustomerId)
      qs.push('customer_id='+selectedCustomerId)
    }

    history.replace('/billing/mail?' + qs.join("&"))
    return data.sort(sort_create)
  }

  const refreshGrid = () => {
      logger('refreshGrid')
      setDataSource(filtered())
  }

  const FilterByCustomer = mails && (
  <Select
      showSearch
      placeholder={t('billing.invoice.table.search_customer')}
      style={{width: '200px', textAlign: 'left'}}
      onClick={stopPropagation}
      onChange={(value => setSelectedCustomerId(value))}
      optionFilterProp='children'
      allowClear={true}
      options={custOptions}
      value={selectedCustomerId}
      filterOption={(input, opt) => removeDiac(opt?.label ?? '').includes(removeDiac(input))}
      filterSort={sort_label}
  />
  )

  const columns: ColumnsType<MailDetails> = [
    { title: t('billing.mail.table.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => text && appSetting?.renderDate(text),
    },
    { title: t('billing.mail.table.state'), dataIndex: 'state', key: 'state',
      render: text => {
        switch (text) {
          case MAIL_STATE.STATE_PREPARED:
            return t('billing.mail.state.prepared')
          case MAIL_STATE.STATE_SENT:
            return t('billing.mail.state.sent')
          case MAIL_STATE.STATE_ERROR:
            return t('billing.mail.state.error')
        }
      }
    },
    { title:
      FilterByCustomer,
      dataIndex: 'customerName',
      key: 'customerName',
    },
    { title: t('billing.mail.table.subject'), dataIndex: 'subject', key: 'subject' },
    { title: t('billing.mail.table.reference'), dataIndex: 'reference', key: 'reference',
      render: text => {
        if (text && text.includes("Invoice")) {
          const link = text.replace('Invoice:','')
          return <Link to={`/billing/invoice/edit/${link}`}>{text}</Link>
        }
        else {
          return text
        }
      }
    },
    { title: t('billing.mail.table.count'),
      dataIndex: 'count',
      key: 'count',
      render: (text, record) => record.sent?.length }
  ]

  const expandedRowRender = (parentRecord, index: number) => {
    const columns = [
      { title: t('billing.mail.table.mail_to'), dataIndex: 'mail_to', key: 'mail_to'},
      { title: t('billing.mail.table.sent_at'),
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text) => text && appSetting.renderDateTime(text)
      },
    ]
    return (
      <Table
        columns={columns}
        dataSource={dataSource && dataSource[index]?.sent}
        pagination={false}
        rowKey={record => record.id}
        size='small'
      />
    )
  }

  return (
    <Card title={ <><MailOutlined /> &nbsp; {t('billing.mail.title')}</> }>
      {
        <Table
            dataSource={dataSource}
            rowKey='id'
            loading={isLoading}
            columns={columns}
            bordered
            expandable={{expandedRowRender}}
            pagination={{
              defaultPageSize: appSetting?.grid_page_size,
              pageSizeOptions: PAGING,
              showSizeChanger: true
            }}
            onChange={(ev) => {setPageSize(`${ev.pageSize}`)}}
            footer={() => TotalNum(Number(dataSource?.length), 'Mail', dataSource)}
        />
      }
    </Card>
  )
}

export default MailPage
