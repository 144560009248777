import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {GetGroupsByCustomerErrorAction, GetGroupsByCustomerRequestAction, GetGroupsByCustomerResponseAction} from "./index";
import {UserGroupModel} from "../../usergroups/models";


export default (customer_id: number, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (args: GetGroupsByCustomerRequestAction | GetGroupsByCustomerResponseAction | GetGroupsByCustomerErrorAction) => void) => {
        const request = new GetGroupsByCustomerRequestAction()
        dispatch(request)

        protectedApiClient.get<UserGroupModel[]>(`/customer/${customer_id}/user-group`)
            .then((response) => {
                dispatch(new GetGroupsByCustomerResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch(error => {
                dispatch(new GetGroupsByCustomerErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}