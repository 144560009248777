import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {ResEntity} from "../models";
import {GetEntitiesErrorAction, GetEntitiesRequestAction, GetEntitiesResponseAction} from "./index";


export default (id: number, customer_id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetEntitiesRequestAction
                | GetEntitiesResponseAction
                | GetEntitiesErrorAction,
        ) => void,
    ) => {
        const request = new GetEntitiesRequestAction()
        dispatch(request)

        protectedApiClient
            .get<ResEntity[]>(`/resource/${id}/entity?customer_id=${customer_id}`)
            .then((response) => {
                dispatch(new GetEntitiesResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetEntitiesErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
