import React, {useEffect, useRef, useState} from 'react'
import {Card, Table, Space, message, Modal, Input, AutoComplete, Col, Switch, Row} from 'antd'
import {
	ExpandOutlined,
	PercentageOutlined,
	PlusCircleOutlined,
	ShoppingOutlined,
	MenuOutlined, CheckOutlined, CloseOutlined,
} from '@ant-design/icons/lib/icons'
import {AppState} from 'common/models'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import getProducts from './actions/getProducts'
import getProduct from './actions/getProduct'
import ProductForm from 'pages/billing/product/ProductForm'
import deleteProduct from './actions/deleteProduct'
import {ProductDetails} from './models'
import getTaxes from '../tax/actions/getTaxes'
import Badge from 'antd/lib/badge'
import Button from 'antd-button-color'
import './ProductsPage.scss'
import {
	DeleteTwoTone,
	EditTwoTone, EuroOutlined,
	ExclamationCircleOutlined,
	InfoCircleTwoTone
} from '@ant-design/icons'
import getServiceTypes from '../servicetype/actions/getServiceTypes'
import {ColumnsType} from 'antd/lib/table'
import {PAGING} from "../../../common/enums";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import {removeDiac} from "../../../common/fce";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import setOrderProduct from "./actions/setOrderProduct";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import usePageSize from "../../../common/usePageSize";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {sort_num} from "../../../common/sorting";
import {useHistory} from "react-router";
import HistoryModal from "../../../components/History/HistoryModal";

const { confirm } = Modal

const ProductsPage = () => {
	const CONTROL_NAME = 'page_billing_product'
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	const {isLoading, products, product} = useSelector((state: AppState) => state.product)
	const {taxes} = useSelector((state: AppState) => state.tax)
	const {servicetypes} = useSelector((state: AppState) => state.servicetype)

	const [pageSize, setPageSize] = useState<string>()
	const [searchName, setSearchName] = useState('')
	const [searchableProducts, setSearchableProducts] = useState<ProductDetails[]>([])
	const [dataSource, setDataSource] = useState<ProductDetails[]>([])

	const [productId, setProductId] = useState<number | undefined>()
	const [isCreate, setIsCreate] = useState(false)
	const [isModalVisible, setModalVisible] = useState(false)
	const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
	const [historyTitle, setHistoryTitle] = useState('')
	const [historyModelId, setHistoryModelId] = useState<number | undefined>()
	const [hideExpired, setHideExpired] = useState<boolean>(true)
	const [selectedItems, setSelectedItems] = useState([])

	const [openDelete, setOpenDelete] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)

	const [isViewer, setViewer] = useState(false)
	const [isCreator, setCreator] = useState(false)
	const [isEditor, setEditor] = useState(false)
	const [isDeleter, setDeleter] = useState(false)

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator/>
			</div>
		)
	}

	// required authorization
	if (!loggedUser.hasAccess(CONTROL_NAME)) {
		return <ErrorPage403 />
	}

	// settings
	const appSetting = loggedUser.getAppSettings()
	const SEARCH_MIN = appSetting.min_search_length

	// logger
	const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('BillingProductPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('BillingProductPage: ' + msg)} }
	usePageSize(appSetting, loggedUser.user.id, pageSize)

	// history drag modal
	const draggleRef = useRef<HTMLDivElement>(null)
	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggleRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	}

	useEffect(() => {
		// when user logged => set permissions
		const viewer = loggedUser.hasAccess('page_billing_product')
		setViewer(viewer)
		setCreator(loggedUser.hasAccess('page_billing_product_create_button'))
		setEditor(loggedUser.hasAccess('page_billing_product_edit_button'))
		setDeleter(loggedUser.hasAccess('page_billing_product_delete_button'))
		if (!viewer) {
			// go to dashboard
			history.replace('/')
		}
	}, [])

	useEffect(() => {
		// load products from API
		// trigger ONCE
		if(!taxes) {
			dispatch(getTaxes())
			return
		}
		if(!servicetypes) {
			dispatch(getServiceTypes())
			return
		}
		dispatch(getProducts())
	}, [taxes, servicetypes])

	useEffect(() => {
		if (products && products.length && servicetypes.length) {
			setSearchableProducts(products.map(p => {
				return {...p,
					service_type: servicetypes.find(s => s.id === p.service_type_id)!.name,
					searchableName: removeDiac(p.name)}
			}))
		}
		else {
			setSearchableProducts([])
		}
	}, [products, product, servicetypes])

	useEffect(() => {
		// update datasource when data was changed
		if (!isModalVisible) {
			refreshGrid()
		}
	}, [searchableProducts, isModalVisible, searchName, hideExpired])

	const filtered = (name: string) => {
		// search for pattern
		let data: ProductDetails[] | undefined = searchableProducts
		if (hideExpired) {
			data = searchableProducts.filter((p) => !p.expired)
		}
		if (name && name.length > SEARCH_MIN) {
			data = searchableProducts.filter((p) => p.searchableName?.includes(removeDiac(name)))
		}
		return data.sort((a, b) => sort_num(a.invoice_order, b.invoice_order))
	}

	const refreshGrid = () => {
		logger('refreshGrid..')
		const pattern = searchName.replace(/\s/g, '')
		setDataSource(filtered(pattern))
	}

	const FilterByName = products && (
		<AutoComplete
			placeholder={t('billing.product.name')}
			style={{width: 200}}
			value={searchName}
			allowClear={true}
			onChange={(v) => {
				setSearchName(v)
			}}
		/>
	)

	const setNewOrder = (id, ordering) => {
		let params: any = {id: id, order: ordering}
		dispatch(setOrderProduct(params, suc => {
			// updates products
			suc && dispatch(getProducts())
		}))
	}

	const DragHandle = SortableHandle(({ active }) => (
		<MenuOutlined style={{ cursor: "grab", color: active ? "blue" : "#999" }} />
	));

	const SortableItem = SortableElement((props) => <tr {...props} />);
	const SortableContainers = SortableContainer((props) => <tbody {...props} />);

	const merge = (a, b, i = 0) => {
		let aa = [...a];
		return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		logger('onSortEnd: '+ oldIndex + ', ' + newIndex)
		let orderId, newOrder
		if (dataSource[newIndex].invoice_order === undefined) {
			return
		}
		if (oldIndex > newIndex) {
			orderId = dataSource[oldIndex].id;
			newOrder = dataSource[newIndex].invoice_order
			isEditor && setNewOrder(orderId, newOrder)
		} else {
			orderId = dataSource[oldIndex].id;
			newOrder = dataSource[newIndex].invoice_order
			isEditor && setNewOrder(orderId, newOrder)
		}
		let tempDataSource = dataSource;

		if (oldIndex !== newIndex) {
			if (!selectedItems.length) {
				let movingItem = tempDataSource[oldIndex];
				tempDataSource.splice(oldIndex, 1);
				tempDataSource = merge(tempDataSource, [movingItem], newIndex);
			} else {
				let filteredItems = [];
				selectedItems.forEach((d) => {
					// @ts-ignore
					filteredItems.push(tempDataSource[d]);
				});
				let newData = [];
				tempDataSource.forEach((d, i) => {
					// @ts-ignore
					if (!selectedItems.includes(i)) {
						// @ts-ignore
						newData.push(d);
					}
				});
				tempDataSource = [...newData];
				tempDataSource = merge(tempDataSource, filteredItems, newIndex);
			}
			setDataSource(tempDataSource);
			setSelectedItems([]);
		}
	};

	const DraggableContainer = (props) => {
		return (
		<SortableContainers
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={onSortEnd}
			{...props}
		/>
	)}

	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		const pos: number = parseInt(restProps["data-row-key"])
		const index = dataSource.findIndex((x) => x.id === pos );
		return (
			<SortableItem
				index={index}
				{...restProps}
				selected={selectedItems.length}
				onClick={(e) => {
					if (e.ctrlKey || e.metaKey) {
						// @ts-ignore
						selectedItems.includes(index) ? selectedItems.splice(selectedItems.indexOf(index), 1) : selectedItems.push(index);
						setSelectedItems(selectedItems);
					} else {
						setSelectedItems([]);
					}
				}}
			/>
		);
	};

	const showConfirmDelete = (b: ProductDetails, title: string) => {
		if (!b) {
			return
		}
		confirm({
				icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
				title: title,
				content: <p>{b.name}</p>,
				okText: t('general.yes'),
				cancelText: t('general.cancel'),
				okButtonProps: { loading: confirmDelete },
				className: 'confirm-alert',
				onOk() {
					setConfirmDelete(true)
					dispatch(deleteProduct(b.id,suc => {
						setConfirmDelete(false)
						setOpenDelete(false)
						if (suc) {
							message.success(t('general.success'))
						}
					}))
				},
				onCancel() {
					setOpenDelete(false)
				},
			}
		)
	}

	const columns: ColumnsType<ProductDetails> = [
		{
			title: t('billing.product.order'),
			key: 'invoice_order',
			dataIndex: 'invoice_order',
			width: '40px',
			align: 'left',
			fixed: 'left',
			className: "drag-visible",
			render: (d, dd, i ) => ( isEditor &&
				<>
					<DragHandle active={selectedItems.includes(i as never)} />
				</>
			)
		},
		{
			title: FilterByName,
			dataIndex: 'name',
			key: 'name',
			align: 'left',
			fixed: 'left',
			width: '35%',
			render: (name, record) => {
				return (<div onClick={(e) => e.stopPropagation()}>{name}</div>)
			}
		},
		{
			title: t('billing.product.service_type'),
			dataIndex: 'service_type',
			key: 'service_type',
			render: (text: string) => <div onClick={(e) => e.stopPropagation()}>{text}</div>
		},
		{
			title: t('billing.product.unit'),
			dataIndex: 'unit',
			key: 'unit',
			width: 100,
			align: 'center',
			render: (text: string) => <div onClick={(e) => e.stopPropagation()}>{text}</div>
		},
		{
			title: t('billing.product.tax_id'),
			key: 'tax_id',
			dataIndex: 'tax_id',
			width: 100,
			align: 'center',
			render: (id: number) => {
				const tax = taxes?.find((tax) => tax.id === Number(id))
				if (!tax) return ''
				else return appSetting?.renderPercent(tax.rate)
			},
		},
		{
			title: t('billing.product.expired'),
			key: 'expired',
			dataIndex: 'expired',
			width: 50,
			align: 'center',
			render: (n: number) => n === 1 ? <Badge status='error' /> : <Badge status='success' />
		},
		{
			title: t('billing.product.is_price_prorated'),
			key: 'is_price_prorated',
			dataIndex: 'is_price_prorated',
			width: 50,
			align: 'center',
			render: (n: number) => n === 1 ? <Badge status='success' /> : <Badge status='error' />
		},
		{
			title: 'Action',
			key: 'action',
			width: '140px',
			align: 'center',
			dataIndex: 'action',
			render: (text, record: ProductDetails) => (
				<Space size='small' onClick={(e) => e.stopPropagation()}>
					{
						isEditor && (
							<Button type='text'
									size='small'
									disabled={!isEditor}
									onClick={() => {
										setIsCreate(false)
										setProductId(record.id)
										dispatch(getProduct(record.id));	// load fresh record
										setModalVisible(true)
									}}
									className='actionButton'
									icon={<EditTwoTone twoToneColor='green' />}
							/>
						)
					}

					{
						isDeleter && (
							<Button type='text' danger size='small'
									className='actionButton'
									disabled={!isEditor}
									onClick={() => showConfirmDelete(record, t('billing.product.confirm_delete'))}
									icon={<DeleteTwoTone twoToneColor='red'/>} />
						)
					}

					<Button title={t('general.btnHistory')} size='small'
							onClick={() => {
								setHistoryTitle(record.name)
								setHistoryModelId(record.id)
								setHistoryModalVisible(true)
							}}
							icon={<InfoCircleTwoTone />}
							className='actionButton'
					/>
				</Space>
			),
		},
	]

	return (
		<>
			<Card
				className='BillingProductsPage'
				title={


						<Row>
							<Col span={6}>
								<ShoppingOutlined /> {t('billing.products.title')}
							</Col>
							<Col span={18}>
								<Space style={{float: 'right', marginRight: '20px'}}>
									<div style={{float: "left", marginRight: "10px"}}>
										{t('billing.products.hide_expired')}
									</div>
									<Switch
										checkedChildren={<CheckOutlined/>}
										unCheckedChildren={<CloseOutlined/>}
										checked={hideExpired}
										onChange={() => setHideExpired(!hideExpired)}
									/>
									<div style={{width: '60px'}}>&nbsp;</div>
								</Space>
							</Col>
						</Row>
				}
				extra={
					<Space>
						<Button
							type='primary'
							disabled={!isCreator}
							onClick={() => {
								setIsCreate(true)
								setProductId(undefined)
								setModalVisible(true)
							}}>
							<PlusCircleOutlined/> {t('billing.product.create')}
						</Button>
					</Space>
				}>
				{
					appSetting && taxes &&
					<Table<ProductDetails>
						columns={columns}
						bordered={true}
						rowKey={(record) => `${record.id}`}
						loading={isLoading}
						dataSource={dataSource}
						pagination={{
							defaultPageSize: appSetting.grid_page_size,
							pageSizeOptions: PAGING,
							showSizeChanger: true
						}}
						components = {{
							body: {
								wrapper: DraggableContainer,
								row: DraggableBodyRow
							}
						}}
						onChange={(ev) => {setPageSize(`${ev.pageSize}`)}}
						footer={() => t('billing.product.order_text') }
					/>
				}

			</Card>
			{
				appSetting &&
				<>
					<Modal
						destroyOnClose={true}
						style={{top: 20}}
						title={
							<>
								<PercentageOutlined/>{' '}
								{(product && product.id) ? t('billing.product.update_title') : t('billing.product.create_title')}
							</>
						}
						visible={isModalVisible}
						onCancel={() => setModalVisible(false)}
						maskClosable={false}
						footer={null}>
						<ProductForm isCreate={isCreate} productId={productId} setModalVisible={setModalVisible} />
					</Modal>

					<HistoryModal service='billing' model='Product'
								  modelId={historyModelId}
								  title={historyTitle}
								  isModalVisible={isHistoryModalVisible}
								  setModalVisible={() => setHistoryModalVisible(false)}
								  modalRender={(modal) => (
									  <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
										  <div ref={draggleRef}>{modal}</div>
									  </Draggable>
								  )}
					/>
				</>
			}
		</>
	)
}

export default ProductsPage
