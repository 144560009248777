import React, {useEffect, useState} from 'react'
import {
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Spin,
    Switch,
    Tag,
    Tooltip,
} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {labelTopLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import createCustomerService from 'pages/billing/customerservice/actions/createCustomerService'
import TextArea from 'antd/lib/input/TextArea'
import {CustomerServiceDetails} from 'pages/billing/customerservice/models'
import {ProductDetails} from 'pages/billing/product/models'
import moment from 'moment'
import './CustomerServiceForm.scss'
import {CheckOutlined, CloseOutlined, InfoCircleOutlined} from '@ant-design/icons'
import Button from 'antd-button-color'
import {AppState} from 'common/models'
import {CalculationType, ProductPriceDetails} from 'pages/billing/productprice/models'
import {useLoggedUser} from "../../../../helpers/loginUserHelper";
import DateInput from "../../../../components/RangeFilter/DateInput";
import {getMax, getPriceNames, getProdOptions, getRoundingPrice, getServiceOptions} from "../utils";
import {_parsePrice, decEn, getDecSeparator} from "../../../../common/fce";
import {sort_name} from "../../../../common/sorting";


interface Props {
    setModalVisible: (param: boolean) => void
    serviceId?: number
    updateHistory: () => void
    onRefreshGrid?: () => void
}

const {Option} = Select
const {Item} = Form


const CustomerServiceFormUpgrade = (props: Props) => {
    const inputRef = React.useRef<any>(null)
    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {products} = useSelector((state: AppState) => state.product)
    const {customerservices, customerservice, pricelist, isSaving} = useSelector((state: AppState) => state.customerservice)
    const {customer} = useSelector((state: AppState) => state.customer)

    const [mainService, setMainService] = useState<CustomerServiceDetails | undefined>(undefined)
    const [mainServicesOptions, setMainServicesOptions] = useState<{
        label: React.ReactNode,
        value: number,
        key: string,
        name: string,
        cal_type: CalculationType,
        price: number
    }[]>([])
    const [filteredProductPrices, setFilteredProductPrices] = useState<ProductPriceDetails[]>([])
    const [productPriceOptions, setProductPriceOptions] = useState<{
        label: React.ReactNode,
        value: number,
        key: string,
        name: string,
        cal_type: CalculationType,
        price: number
    }[]>([])
    const [checked, setChecked] = useState(true)
    const [amount, setAmount] = useState<string>('1')
    const [selectedProductPrice, setSelectedProductPrice] = useState<ProductPriceDetails>()
    const [selectedProduct, setSelectedProduct] = useState<ProductDetails>()
    const [isAdditional, setAdditional] = useState<boolean>(false)
    const [calculationType, setCalculationType] = useState<string>()


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()
    const logger = (msg, obj:any=null) => {
        appSetting && appSetting.log('CustomerServiceFormUpgrade', msg, obj)
    }

    useEffect(() => {
        if(!customer) {
            logger('ERROR: Missing customer')
            message.error(t('general.error'))
            props.setModalVisible(false)
            return
        }
        if(!pricelist) {
            logger('ERROR: Missing pricelist')
            message.error(t('general.error'))
            props.setModalVisible(false)
            return
        }
        if(!products || products.length === 0) {
            logger('ERROR: Missing products')
            message.error(t('general.error'))
            props.setModalVisible(false)
            return
        }

    }, [])

    useEffect(() => {
        if (customerservice && pricelist) {
            setFilteredProductPrices(getPriceNames(pricelist, products))
        }
    }, [customerservice, pricelist])


    useEffect(() => {
        if (customerservice && props.serviceId === customerservice.id && filteredProductPrices.length) {
            const setPP = filteredProductPrices.find((pp) => pp.product_id === customerservice.product_id)
            if (setPP) {
                setSelectedProductPrice(setPP)
                setSelectedProduct(products.find(p => p.id === setPP.product_id))
            }
            setChecked(customerservice.billable === 1)
            setAmount(appSetting.renderCount(customerservice.unit_count))
            pricelist && setProductPriceOptions(getProdOptions(filteredProductPrices, pricelist.currency, appSetting))
            pricelist && setMainServicesOptions(getServiceOptions(getCurrentMainServices(), pricelist.currency, appSetting))
            if (customerservice.parent_id) {
                const mcs = customerservices.find(cs => cs.id === customerservice.parent_id)
                if (mcs) {
                    setAdditional(true)
                    setMainService(mcs)
                }
            }
        }
    }, [customerservice, filteredProductPrices])

    useEffect(() => {
        if (selectedProductPrice) {
            setCalculationType(selectedProductPrice.calculation_type)
        }
    }, [selectedProductPrice])

    useEffect(() => {
        if (mainServicesOptions.length && inputRef.current) {
            inputRef.current!.focus()
        }
    }, [mainServicesOptions])

    const getCurrentMainServices = () => {
        if (!customerservice) {
            return []
        }
        const currentServices = customerservices.filter(cs => !cs.parent_id && (!cs.date_to || (new Date(cs.date_to) >= new Date())) )
        return currentServices.filter(cs => cs.id != customerservice.id).sort(sort_name)
    }

    const validateForm = (values: Store): boolean => {
        let unit_price = 0
        let unit_count = 0
        if ( calculationType === 'range' ) {
            unit_price = 0
        }
        else {
            try {
                unit_price = appSetting.parsePrice(values.unit_price)
            }
            catch (e) {
                logger('validateForm: ', values)
                message.error(t('general.error') + ': unit_price')
                return false
            }
        }

        if ( calculationType === 'unit' || calculationType === 'range' ) {
            try {
                unit_count = appSetting.parseCount(values.unit_count)
            } catch (e) {
                message.error(t('general.error') + ': unit_count')
                return false
            }
        }
        else {
            unit_count = 1000   // fix
        }

        if ( calculationType === 'unit' && unit_count === 0 ) {
            message.error(t('errors.field_error') + ': unit_count')
            return false
        }
        return true
    }

    const onFormSubmit = (values: Store) => {
        // validate
        if (!validateForm(values)) {
            return
        }
        if (!customer) {
            return
        }

        let unitPrice = 0
        let unitCount = 1000

        if ( calculationType != 'range' ) {
            unitPrice = appSetting.parsePrice(values.unit_price)
        }

        // upgrade
        if (customerservice && customerservice.id) {
            if (customerservice.price?.calculation_type === 'unit' || customerservice.price?.calculation_type === 'range') {
                unitCount = appSetting.parseCount(amount)
            }
            const params = {
                customer_id: values.customer_id,
                name: values.name,
                parent_id: customerservice.parent_id,
                previous_id: customerservice.id,
                product_id: values.product_id,
                unit_count: unitCount,
                unit_price: unitPrice,
                description: values.description,
                internal_note: values.internal_note,
                date_from: appSetting.renderDateSQL(values.date_from),
                date_to: appSetting.renderDateSQL(values.date_to),
                billable: values.billable ? 1 : 0,
                is_otp: 0
            }
            dispatch(
                createCustomerService(params, (suc: boolean) => {
                        if (suc) {
                            message.success(t('general.success'), 2)
                            props.onRefreshGrid && props.onRefreshGrid()
                            props.updateHistory()
                            props.setModalVisible(false)
                        }
                    },
                ),
            )
        }
    }

    const handleMainServiceSelect = (v, e) => {
        const mcs = customerservices.find(cs => cs.id === v)
        setMainService(mcs)
    }

    const getAmountTitle = (obj: ProductPriceDetails | undefined) => {
        if (!obj) {
            return ''
        }
        if (obj.unit) {
            return obj.unit + ' => ' + t('billing.customer-services.precision') + `: ${obj.decimal_places} ` + t('billing.customer-services.dec_places')
        }
        return t('billing.customer-services.precision') + `: ${obj.decimal_places} ` + t('billing.customer-services.dec_places')

    }

    const onCountKeyPress = (e) => {
        if (selectedProductPrice && selectedProductPrice.decimal_places === 0) {
            // no decimal numb.
            if (e.which < 48 || e.which > 57) {
                e.preventDefault();
            }
        }
    }

    const handleFormatter = e => {
        let value = appSetting.renderPrice(_parsePrice(e.target.value))
        form.setFieldsValue({'unit_price': decEn(value)})
    }

    const onCountChange = (value: string | null) => {
        if (selectedProductPrice && value) {
            value = value.toString()
            let places = selectedProductPrice.decimal_places
            if (!places || places === 0) {
                places = 0
            }
            const count = appSetting.parseCountDecPlaces(value, places)
            setAmount(appSetting.renderCount(count))
        }
    }

    if (!customer) {
        return (<Spin/>)
    }

    if (!customerservice || props.serviceId != customerservice.id) {
        // wait for customer service loaded
        return (<Spin/>)
    }

    return (
        <Form form={form}
            {...labelTopLayout}
            className='CustomerServiceFormUpgrade'
            onFinish={onFormSubmit}
            initialValues={{
                ...customerservice,
                date_from: moment(),    // from today
                date_to: undefined,
                unit_price: decEn(appSetting.renderPrice(customerservice.unit_price)),
                unit_count: decEn(amount),
                is_additional: isAdditional ? 1 : 0
            }}
            autoComplete='off'>

            <Row gutter={16} style={{display: 'none'}}>
                <Col span={12} style={{display: 'none'}}>
                    <Item name='customer_id'>
                        <Input type='hidden' />
                    </Item>
                </Col>

                <Col span={12} style={{display: 'none'}}>
                    <Item name='previous_id'>
                        <Input type='hidden' />
                    </Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24}>
                    <Item name='product_id'
                        label={t('billing.customer-services.product_id')}
                        rules={[{required: true, message: t('billing.customer-services.error.product_id')}]}>
                        <Select
                            optionFilterProp='name'
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            virtual={false}
                            style={{width: '100%'}}
                            loading={!filteredProductPrices}
                            options={productPriceOptions}
                        />
                    </Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={16}>
                    <Item name='name'
                        label={t('billing.customer-services.name')}
                        rules={[{required: true, message: t('billing.customer-services.error.name')}]}>
                        <Input ref={inputRef}
                               autoComplete='off'
                               data-gramm="false"
                               data-gramm_editor="false"
                               data-enable-grammarly="false"
                        />
                    </Item>
                </Col>
                <Col span={8}>
                    {
                        selectedProductPrice && (selectedProductPrice.calculation_type === "fix" || selectedProductPrice.calculation_type === "unit") && (
                            <Item name='unit_price'
                                  label={t('billing.customer-services.price')}
                                  rules={[() => (
                                      {
                                          validator(_, value) {
                                              if (isNaN(value)) {
                                                  return Promise.reject("Please enter Price");
                                              }
                                              return Promise.resolve();
                                          }
                                      }
                                  )]}>
                                <InputNumber precision={2}
                                             decimalSeparator=','
                                             className='inputPriceField'
                                             onFocus={(event) => event.target.select()}
                                             onKeyPress={(e) => {if (e.key === 'Enter') {handleFormatter(e)}}}
                                             onBlur={handleFormatter}
                                             addonAfter={customer?.billing_currency}
                                />
                            </Item>
                        )
                    }
                    {
                        selectedProductPrice && selectedProductPrice.calculation_type === "range" && (
                            <Item name='unit_count'
                                  label={t('billing.customer-services.amount')}
                                  rules={[{required: true, message: t('billing.customer-services.error.unit_count')}]}>
                                <Tooltip title={getAmountTitle(selectedProductPrice)}>
                                    <InputNumber type='number'
                                                 addonAfter={selectedProductPrice?.unit}
                                                 step={getRoundingPrice(selectedProductPrice)}
                                                 className='inputPriceField'
                                                 style={{width: '100%'}}
                                                 onChange={onCountChange}
                                                 onKeyPress={onCountKeyPress}
                                                 defaultValue={decEn(amount)}
                                                 max={getMax(selectedProductPrice)}
                                                 stringMode
                                    />
                                </Tooltip>
                            </Item>
                        )
                    }
                </Col>
            </Row>

            {
                selectedProductPrice && selectedProductPrice.calculation_type === "unit" && (
                    <Row gutter={16}>
                        <Col span={16}>&nbsp;</Col>
                        <Col span={8}>
                            <Item name='unit_count'
                                  label={t('billing.customer-services.amount')}
                                  rules={[{required: true, message: t('billing.customer-services.error.unit_count')}]}>
                                <Tooltip title={getAmountTitle(selectedProductPrice)}>
                                    <InputNumber type='number'
                                                 addonAfter={selectedProductPrice?.unit}
                                                 step={getRoundingPrice(selectedProductPrice)}
                                                 className='inputPriceField'
                                                 style={{width: '100%'}}
                                                 onChange={onCountChange}
                                                 onKeyPress={onCountKeyPress}
                                                 defaultValue={decEn(amount)}
                                                 max={getMax(selectedProductPrice)}
                                                 stringMode
                                    />
                                </Tooltip>
                            </Item>
                        </Col>
                    </Row>
                )
            }

            <Row gutter={16}>
                <Col span={6} style={{paddingBottom: 0}}>
                    <Item name='additional'
                          label={t('billing.customer-services.is_additional')}
                        // initialValue={checked}
                          valuePropName='checked'
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={isAdditional}
                            onChange={() => setAdditional(!isAdditional)}
                        />
                    </Item>
                </Col>
                <Col span={18} style={{paddingBottom: 0}}>
                    <Item name='parent_id' label={t('billing.customer-services.parent')}>
                        <Select
                            showSearch
                            allowClear
                            autoFocus
                            optionFilterProp='name'
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            virtual={false}
                            style={{width: '100%'}}
                            disabled={!isAdditional}
                            options={mainServicesOptions}
                            onSelect={handleMainServiceSelect}
                        />
                    </Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={8}>
                    <Item name='billable'
                        label={t('createUserPage.billable')}
                        // initialValue={checked}
                        valuePropName='checked'
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                        />
                    </Item>
                </Col>

                <Col span={8}>
                    <Item name='date_from'
                        label={t('billing.customer-services.date_from')}
                        rules={[{required: checked, message: t('billing.customer-services.error.date_from')}]}
                    >
                        <DateInput htmlId='__date_from'
                                   initDateStr={appSetting.renderDateSQL(new Date())}
                                   disabled={!checked}
                                   required={true}
                                   format={appSetting.date_picker_format} // not Moment formats!!
                                   onChange={(date) => {
                                       // console.log('date_from: '+appSetting.renderDateSQL(date))
                                       form.setFieldsValue({date_from: appSetting.renderDateSQL(date)})
                                   }}
                        />
                    </Item>
                </Col>

                <Col span={8}>
                    <Item name='date_to' label={t('billing.customer-services.date_to')}>
                        <DateInput htmlId='__date_to'
                                   initDateStr={''}
                                   disabled={!checked}
                                   minDateStr={form.getFieldValue('date_from')}
                                   format={appSetting.date_picker_format} // not Moment formats!!
                                   onChange={(date) => {
                                       // console.log('date_to: '+appSetting.renderDateSQL(date))
                                       form.setFieldsValue({date_to: appSetting.renderDateSQL(date)})
                                   }}
                        />
                    </Item>
                </Col>
            </Row>

            <Item name='description' label={t('billing.customer-services.description')}>
                <TextArea data-gramm="false"
                          data-gramm_editor="false"
                          data-enable-grammarly="false" />
            </Item>

            <Item name='internal_note' label={t('billing.customer-services.internal_note')}>
                <TextArea data-gramm="false"
                          data-gramm_editor="false"
                          data-enable-grammarly="false" />
            </Item>

            <Row>
                <Col span={8} style={{textAlign: 'center'}}>&nbsp;</Col>
                <Col span={8}>
                    <Button type='primary'
                            onClick={() => {
                                form && form.submit()
                            }}
                            loading={isSaving}
                            className='upgrade-form-button gold'> {t('billing.customer-services.header.btn_upgrade')}</Button>
                </Col>
                <Col span={8} style={{textAlign: 'center'}}>&nbsp;</Col>
            </Row>

        </Form>
    )
}

export default CustomerServiceFormUpgrade
