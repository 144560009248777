import {
    UpdateInventoryTypeRequestAction,
    UpdateInventoryTypeResponseAction,
    UpdateInventoryTypeErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {InventoryTypeModel, InventoryTypeParams} from '../models'

export default (
    params: InventoryTypeParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | UpdateInventoryTypeRequestAction
                | UpdateInventoryTypeResponseAction
                | UpdateInventoryTypeErrorAction,
        ) => void,
    ) => {
        const request = new UpdateInventoryTypeRequestAction(params)
        dispatch(request)
        const id = params.id
        protectedApiClient
            .put<InventoryTypeModel>(`/inventory/type/${id}?expand=usableIds`, params)
            .then((response) => {
                dispatch(new UpdateInventoryTypeResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateInventoryTypeErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
