import React, {useEffect, useState} from 'react'
import {Button, Card, Col, Form, Input, message, Row, Tag} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import {SettingsDetails} from "../settings/models";
import SettingItem, {renderScope} from "./SettingItem";
import {sort_name} from "../../common/sorting";
import {UpdateUserParams} from "./models";
import updateUser from "./actions/updateUser";
import {REG_IPV4} from "../../common/fce";
import Item from "antd/lib/form/FormItem";


const PanelWhitelistIP = (props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {user} = useSelector((state: AppState) => state.user)

    const [whiteIP, setWhiteIP] = useState<string>('')
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)


    useEffect(() => {
        if (user && user.whitelist_ip) {
            setWhiteIP(user.whitelist_ip)
        }
    }, []);

    const saveIP = () => {
        if (user && user.id) {
            if (user.is_system === 1) {
                // bot
                const params: UpdateUserParams = {
                    id: user.id,
                    whitelist_ip: whiteIP
                }
                dispatch(updateUser(params, suc => {
                    if (suc) {
                        message.success(t('general.success'))
                    }
                }))
            }
        }
    }


    return (
        <Card title={t('usersPage.menu_allow_ip')} className='fblock'
              style={{marginTop: 0}}>

            <Row className='settingItem'>
                <Col span={10} className='labelCol'><span className='label'> {t('usersPage.whitelistIp')} </span> </Col>
                <Col span={10} className='valueCol'>
                    <Input type='text' style={{width: '240px'}}
                           onChange={(el) => {setWhiteIP(el.target.value);setUpdateStatus(true)}}
                           value={whiteIP}/>
                </Col>
                <Col span={4} className='btnCol'>
                    <Button size='small' type='primary'
                            onClick={() => saveIP()}
                            disabled={!updateStatus}>{t('general.save')}</Button>
                </Col>
                <Col span={24}>
                    <span className='textGray'>{t('usersPage.whitelist_ip_desc')}</span>
                </Col>
            </Row>

        </Card>
    )
}

export default PanelWhitelistIP
