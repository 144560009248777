import {ClearCompanyRequestAction} from "./index";


export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (arg: ClearCompanyRequestAction) => void,
    ) => {
        const request = new ClearCompanyRequestAction()
        dispatch(request)
    }
}