import {
    SendConfirmErrorAction,
    SendConfirmRequestAction,
    SendConfirmResponseAction
} from "./index";
import {apiClient} from "../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../helpers/errorHandling";
import {Params2FA, ParamsSendCode, SendCodeResponseData} from "../models";


export default (params: ParamsSendCode, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | SendConfirmRequestAction
                | SendConfirmResponseAction
                | SendConfirmErrorAction,
        ) => void,
    ) => {
        const request = new SendConfirmRequestAction(params)
        dispatch(request)
        apiClient
            .post<SendCodeResponseData>(`/auth/send-confirm`, params)
            .then((response) => {
                dispatch(new SendConfirmResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new SendConfirmErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
