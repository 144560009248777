import {CreateMailTemplateRequestAction, CreateMailTemplateResponseAction, CreateMailTemplateErrorAction} from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {CreateMailTemplateParams, MailTemplateDetails} from '../models'

export default (params: CreateMailTemplateParams, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (arg: CreateMailTemplateRequestAction | CreateMailTemplateResponseAction | CreateMailTemplateErrorAction) => void) => {
        const request = new CreateMailTemplateRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<MailTemplateDetails>('/mail-template', params)
            .then((response) => {
                dispatch(new CreateMailTemplateResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new CreateMailTemplateErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
