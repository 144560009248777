import {
    GetResGroupRequestAction,
    GetResGroupResponseAction,
    GetResGroupErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ResGroupModel } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetResGroupRequestAction
                | GetResGroupResponseAction
                | GetResGroupErrorAction,
        ) => void,
    ) => {
        const request = new GetResGroupRequestAction(id)
        dispatch(request)

        protectedApiClient
            .get<ResGroupModel>(`/resource-group/${id}?expand=resources`)
            .then((response) => {
                dispatch(new GetResGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetResGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
