import { ActionType, GroupActions } from "./actions";
import { AsyncActionMode } from "common/models";
import {UserGroupModel, GroupsPageState} from "./models";
import {sort_title} from "../../../common/sorting";


const emptyGroup: UserGroupModel = {
    title: 'empty',
    type: 'group',
    id: 1,
    customer_id: 1,
    key: '1_0',
    children: [],
    users: [],
    roles: [],
    resourceGroups: []
}

const INITIAL_STATE: GroupsPageState = {
    isSaving: false,
    isLoading: false,
    error: undefined,
    root_id: 0,
    hierarchy: emptyGroup,
    nodes: [],
    users: [],
    roles: [],
    resGroups: []
};

const updateNodes = (oldNodes: UserGroupModel[], data: UserGroupModel[]) => {
    // this will update nodes (UserGroupModel)
    for (let newNode of data) {
        let old = oldNodes.find( n => n.id === newNode.id && n.parent_id === newNode.parent_id && n.type === newNode.type)
        if (old && old.id === newNode.id) {
            // replace
            console.log('updateNodes -- replace')
            oldNodes = oldNodes.map((n) => n.id === newNode.id && n.parent_id === newNode.parent_id && n.type === newNode.type ? newNode : n)
        }
        else {
            // add
            console.log('updateNodes -- add')
            oldNodes = oldNodes.concat(newNode)
        }
    }

    return oldNodes
}

const renderHierarchy = (rootId: number, data: UserGroupModel[]):UserGroupModel => {
    if (!data) {
        return emptyGroup
    }

    const nodes = data.sort(sort_title)

    // set root
    let root: UserGroupModel | undefined
    if (nodes.length === 1) {
        root = nodes[0]
        return root
    }

    root = nodes.find(g => g.id === rootId && g.type === 'group')
    if (!root) {
        return emptyGroup
    }

    root = {...root, children: []}
    let pids: number[] = []
    let pid = 0
    pids.push(rootId)
    while (pids.length > 0) {
        let c = pids.pop()
        if (!c) {
            break
        }
        pid = c
        let item = nodes.find(g => g.id === pid && g.type === 'group')
        if (!item) {
            continue
        }
        if (item.id === rootId) {
            item = root
        } else {
            item = findItemById(pid, root)
        }
        if (!item) {
            continue
        }

        if (!item.children) {
            item.children = []
        }
        let pos = item.title.indexOf(';')
        let name  = pos>-1 ? item.title.substr(0, pos) : item.title
        //item.title = name + ';' + item.key

        let children = nodes.filter(g => g.parent_id && g.parent_id === pid)
        for (let child of children) {
            child.key = item.key + '-' + child.id
            pos = child.title.indexOf(';')
            name  = pos>-1 ? child.title.substr(0, pos) : child.title
            //child.title = name + ';' + child.key
            //console.log('child...'+name)
            item.children.push({...child})
        }

        for (let child of item.children) {
            if (child.type === 'group') {
                if (!pids.find(x => x === child.id)) {
                    pids.push(child.id)
                }
            }
        }

        // delete correctly
        for (let k in pids) {
            let v = pids[k]
            if (v === pid) {
                delete pids[k];
            }
        }
    }
    return root
}

const findItemById = (pid: number, root) => {
    // find reference for a node by id
    if (!root.children) {
        return
    }

    for (let item of root.children) {
        if (item.type != 'group') {
            continue
        }
        if (item.id === pid) {
            return item
        }

        let found = findItemById(pid, item)
        if (found) {
            return found
        }
    }
}


export default (
    state: GroupsPageState = INITIAL_STATE,
    action: GroupActions
): GroupsPageState => {
    switch (action.type) {
        case ActionType.CREATE_USER_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                const rid = state.root_id
                const nodes = updateNodes(state.nodes, [action.data])
                return {
                    ...state,
                    isLoading: false,
                    nodes: nodes,
                    hierarchy: renderHierarchy(rid, nodes),
                    users: action.data.users,
                    roles: action.data.roles,
                    resGroups: action.data.resourceGroups
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                };
            }
            break;
        case ActionType.GET_USER_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                const rid = state.root_id
                const nodes = updateNodes(state.nodes, [action.data])
                return {
                    ...state,
                    isLoading: false,
                    nodes: nodes,
                    hierarchy: renderHierarchy(rid, nodes),
                    users: action.data.users,
                    roles: action.data.roles,
                    resGroups: action.data.resourceGroups
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                    data: undefined,
                };
            }
            break;
        case ActionType.GET_ROOT_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                const rid = action.data.id
                let root = {...action.data, children: []}
                const nodes = [root]
                return { ...state,
                    isLoading: false,
                    root_id: rid,
                    nodes: nodes,
                    hierarchy: renderHierarchy(rid, nodes),
                    users: action.data.users,
                    roles: action.data.roles,
                    resGroups: action.data.resourceGroups
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                    data: undefined,
                };
            }
            break;
        case ActionType.GET_USER_GROUP_BY_PARENT:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                const rid = state.root_id
                const nodes = updateNodes(state.nodes, action.data)
                let parentId = 0
                for (let item of action.data) {
                    if (item.parent_id) {
                        parentId = item.parent_id
                        break
                    }
                }
                // delete
                const ids = action.data.map(g => g.id)
                let nodes2: UserGroupModel[] = []
                for (let oldNode of nodes) {
                    if (oldNode.parent_id === parentId && !ids.includes(oldNode.id)) {
                        continue
                    }
                    nodes2.push(oldNode)
                }
                return {
                    ...state,
                    isLoading: false,
                    root_id: rid,
                    nodes: nodes2,
                    hierarchy: renderHierarchy(rid, nodes2),
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                };
            }
            break;
        case ActionType.DELETE_USER_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                // returns nothing 204
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                };
            }
            break;
        case ActionType.UPDATE_USER_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                const rid = state.root_id
                const nodes = updateNodes(state.nodes, [action.data])
                return {
                    ...state,
                    isLoading: false,
                    nodes: nodes,
                    hierarchy: renderHierarchy(rid, nodes),
                    users: action.data.users,
                    roles: action.data.roles
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                };
            }
            break;
            
        case ActionType.REMOVE_ROLES_IN_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                const rid = state.root_id
                const nodes = updateNodes(state.nodes, [action.data])
                return {
                    ...state,
                    isLoading: false,
                    root_id: rid,
                    nodes: nodes,
                    hierarchy: renderHierarchy(rid, nodes),
                    roles: action.data.roles,
                    resGroups: action.data.resourceGroups
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                };
            }
            break;
        case ActionType.ADD_ROLES_IN_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                const rid = state.root_id
                const nodes = updateNodes(state.nodes, [action.data])
                return {
                    ...state,
                    isLoading: false,
                    root_id: rid,
                    nodes: nodes,
                    hierarchy: renderHierarchy(rid, nodes),
                    roles: action.data.roles,
                    resGroups: action.data.resourceGroups
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                };
            }
            break;

        case ActionType.ADD_RES_GROUPS_IN_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                const rid = state.root_id
                const nodes = updateNodes(state.nodes, [action.data])
                return {
                    ...state,
                    isLoading: false,
                    root_id: rid,
                    nodes: nodes,
                    hierarchy: renderHierarchy(rid, nodes),
                    roles: action.data.roles,
                    resGroups: action.data.resourceGroups
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                };
            }
            break;
        case ActionType.REMOVE_RES_GROUPS_IN_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                const rid = state.root_id
                const nodes = updateNodes(state.nodes, [action.data])
                return {
                    ...state,
                    isLoading: false,
                    root_id: rid,
                    nodes: nodes,
                    hierarchy: renderHierarchy(rid, nodes),
                    roles: action.data.roles,
                    resGroups: action.data.resourceGroups
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                };
            }
            break;

        case ActionType.USERGROUP_ADD_USER_TO_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                const nodes = updateNodes(state.nodes, [action.data])
                return {
                    ...state,
                    isLoading: false,
                    nodes: nodes,
                    users: action.data.users,
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                };
            }
            break;

        case ActionType.USERGROUP_REMOVE_USER_FROM_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                const nodes = updateNodes(state.nodes, [action.data])
                return {
                    ...state,
                    isLoading: false,
                    nodes: nodes,
                    users: action.data.users,
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                };
            }
            break;

    }
    return state;
};
