import { UpdateUserSettingRequestAction, UpdateUserSettingResponseAction, UpdateUserSettingErrorAction } from "./index";
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {SettingsDetails, SettingsParams} from "../../settings/models";


export default (params: SettingsParams, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (args: UpdateUserSettingRequestAction | UpdateUserSettingResponseAction | UpdateUserSettingErrorAction) => void) => {
        const request = new UpdateUserSettingRequestAction(params)
        dispatch(request)
        const id = params['id']
        delete params['id']
        protectedApiClient.post<SettingsDetails>(`/user/${id}/setting`, params)
            .then(response => {
                dispatch(new UpdateUserSettingResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch(error => {
                dispatch(new UpdateUserSettingErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}