import {useForm} from "antd/lib/form/Form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Input, message, Select} from "antd";
import {formItemLayout, tailLayout} from "../../../helpers/layoutHelpers";
import {UpdateMailTemplateParams} from "./models";
import {Store} from "antd/lib/form/interface";
import updateMailTemplate from "./actions/updateMailTemplate";
import createMailTemplate from "./actions/createMailTemplate";
import ReactQuill from "react-quill";
import './MailTemplateForm.scss'
import {_validateEmail} from "../../../common/fce";
import {ValidateStatus} from "antd/lib/form/FormItem";
import sendTestMailTemplate from "./actions/sendTestMailTemplate";


interface Props {
    dataToUpdate?: UpdateMailTemplateParams
    pageCallback?: (modalVisible: boolean, isSuccess: boolean) => void
}

interface EmailField {
    value: string,
    validateStatus: ValidateStatus,
    errorMsg: string
}

const validateEmail = (emailStr: string, msg: string): EmailField => {
    if (!emailStr) {
        // empty email is OK
        return {
            value: emailStr,
            validateStatus: 'success',
            errorMsg: ''
        }
    }
    if (!_validateEmail(emailStr)) {
        return {
            value: emailStr,
            validateStatus: 'error',
            errorMsg: msg
        }
    }
    else {
        return {
            value: emailStr,
            validateStatus: 'success',
            errorMsg: ''
        }
    }
}

const {Item} = Form

const MailTemplateForm = ({dataToUpdate, pageCallback}: Props) => {
    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [emailField, setEmailField] = useState<EmailField>({value: '', validateStatus: 'success', errorMsg: ''})
    const inputEmailRef = React.useRef<any>(null)

    const onFormSubmit = (values: Store) => {
        setIsSaving(true)
        dataToUpdate ?
            dispatch(updateMailTemplate({
                id: dataToUpdate.id,
                sid: values.sid,
                subject: values.subject,
                content: values.content
                }, (isSuccess: boolean) => {
                    pageCallback && pageCallback(false, isSuccess)
                    isSuccess && message.info(t('billing.mail_template.form.updated'))
                    setIsSaving(false)
            })) :
            dispatch(createMailTemplate({
                sid: values.sid,
                subject: values.subject,
                content: values.content
                }, (isSuccess: boolean) => {
                    pageCallback && pageCallback(false, isSuccess)
                    isSuccess && message.info(t('billing.mail_template.form.created'))
                    setIsSaving(false)
                }
            ))
    }

    const onSendEmail = (values: Store) => {
        const emailStr = inputEmailRef.current.state.value.toString()
        const field = validateEmail(emailStr, t('billing.mail_template.form.error_email'))
        setEmailField(field)
        if (field.validateStatus === 'success' && emailStr.length > 2 && dataToUpdate && dataToUpdate.id) {
            // email is OK
            dispatch(sendTestMailTemplate({
                email: field.value,
                template_name: dataToUpdate.sid
            }, (isSuccess: boolean) => {
                isSuccess && message.success(t('billing.mail_template.form.mail_sent') + `: ${emailStr}`)
            }))
        }
    }

    return (
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={
                {
                    ...dataToUpdate,
                    sid: dataToUpdate?.sid ? dataToUpdate?.sid : '',
                    subject: dataToUpdate?.subject ? dataToUpdate?.subject : '',
                    content: dataToUpdate?.content ? dataToUpdate?.content : '',
                }
            }
        >
            <Item
                name='sid'
                rules={[{required: true, message: t('billing.mail_template.form.error_required')}]}
                label={t('billing.mail_template.form.name')}
                hasFeedback
            >
                <Input placeholder={'your template name'}/>
            </Item>

            <Item
                name='subject'
                rules={[{required: true, message: t('billing.mail_template.form.error_required')}]}
                label={t('billing.mail_template.form.subject')}
                hasFeedback
            >
                <Input placeholder={'subject'}/>
            </Item>

            <Item
                name='content'
                  label={t('billing.mail_template.form.content')}
                  rules={[{required: true, message: t('billing.mail_template.form.error_required')}]}
            >
                <ReactQuill theme="snow" onChange={() => setUpdateStatus(true)} />
            </Item>

            {
                dataToUpdate &&
                <Item
                    name='email'
                    label={t('billing.mail_template.form.test_email')}
                    hasFeedback
                    validateStatus={emailField.validateStatus}
                    help={emailField.errorMsg}
                >
                    <Input type='email' placeholder={'email'} ref={inputEmailRef} style={{width: '200px'}}/>
                    <Button type='primary'
                            onClick={onSendEmail}
                            className='login-form-button'>
                        { t('billing.mail_template.form.btn_test_email')}
                    </Button>
                </Item>
            }

            <Item {...tailLayout}>
                <Button loading={isSaving} type='primary' htmlType='submit' className='login-form-button' disabled={!updateStatus}>
                    {
                        dataToUpdate ? t('billing.mail_template.update') : t('billing.mail_template.create')
                    }
                </Button>
            </Item>
        </Form>
    )
}

export default MailTemplateForm