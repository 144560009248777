import {
    GetMailTemplatesResponseAction,
    GetMailTemplatesErrorAction,
    GetMailTemplatesRequestAction
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { MailTemplateDetails } from '../models'

export default (cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (args: GetMailTemplatesRequestAction | GetMailTemplatesResponseAction | GetMailTemplatesErrorAction) => void) => {
        const request = new GetMailTemplatesRequestAction()
        dispatch(request)

        protectedApiClient.get<MailTemplateDetails[]>('/mail-template')
            .then(response => {
                dispatch(new GetMailTemplatesResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch(error => {
                dispatch(new GetMailTemplatesErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
