import React, {ReactNode, useEffect, useRef, useState} from 'react'
import './UsersPage.scss'
import {
    ApiOutlined,
    CheckCircleTwoTone,
    CheckOutlined,
    CloseCircleTwoTone,
    CloseOutlined, CommentOutlined,
    DeleteTwoTone
} from '@ant-design/icons'
import {renderNo, renderYes} from "../../components/Tags/Tags";
import {
    Button,
    Card,
    Col,
    FormProps,
    Input, message,
    Modal,
    Radio,
    Row,
    Select,
    Skeleton,
    Space,
    Spin,
    Switch,
    Table,
    Tag, Tooltip
} from "antd";
import Form, {useForm} from "antd/lib/form/Form";
import {formItemLayout} from "../../helpers/layoutHelpers";
import Item from "antd/lib/form/FormItem";
import {euPhoneNumberRegex} from "../../helpers/stringHelpers";
import {_parsePhone, _renderPhone, _validatePhone, stopPropagation} from "../../common/fce";
import {AuthenticationModel, LogRecord2fa, UserAuthenticationModel, UserVerificationModel} from "./models";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../components";
import {AppState} from "../../common/models";
import {AUTH_SMS, AUTH_GA} from "../../common/enums";
import getAllAuthentications from "./actions/getAllAuthentications";
import {sort_create, sort_name} from "../../common/sorting";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import VerifyGA from "./VerifyGA";
import VerifySMS from "./VerifySMS";
import createUserAuthentication from "./actions/createUserAuthentication";

interface Props {
    saveSmsAuth: (selectedUserAuthentication: UserAuthenticationModel) => void
    saveGAuth: (selectedUserAuthentication: UserAuthenticationModel) => void
    deleteLog: (log_id:number) => void
    deleteAuth: (selectedUserAuthentication: UserAuthenticationModel) => void
}

const Auth2faStates = {
    LOADING_AUTH: -1,
    NO_AUTH: 0,
    AUTH_SMS: 1,
    AUTH_GA: 2,
}

const isIdentVerified = (field: 'phone' | 'email', value: string, verifications: UserVerificationModel[]): boolean => {
    if (!value) {
        return false
    }
    let ident = ''
    if (field === 'phone') {
        ident = _parsePhone(value)
    }
    if (field === 'email') {
        ident = value.toLowerCase()
    }
    if (verifications && verifications.length) {
        const ver = verifications.filter(v => v.field === field).find(v => v.value === ident)
        if (ver) {
            return ver.is_verified === 1
        }
    }
    return false
}

const Panel2FA = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {self} = useSelector((state : AppState) => state.auth)
    const {user, allAuthentications, authentications, verification,
        verifications, isSavingAuthentication, auth_2fa_ips} = useSelector((state: AppState) => state.user)

    const [pageNum, setPageNum] = useState<number>(-1)
    const [updateStatusSMS, setUpdateStatusSMS] = useState<boolean>(false)
    const [updateStatusGA, setUpdateStatusGA] = useState<boolean>(false)
    const [selectedAuthenticationId, setSelectedAuthenticationId] = useState<number>(0)
    const [methodName, setMethodName] = useState<string>('')
    const [dataSourceLog2FA, setDataSourceLog2FA] = useState<LogRecord2fa[]>([])

    const [validityOptions, setValidityOptions] = useState<{ label: string, value: number }[]>([])
    const [optionsAllAuthentications, setOptionsAllAuthentications] = useState<{ label: string | ReactNode, value: number }[]>([])
    const [userAuthentications, setUserAuthentications] = useState<UserAuthenticationModel[]>([])
    const [selectedUserAuthentication, setSelectedUserAuthentication] = useState<UserAuthenticationModel>()
    const [isModalVerifySmsVisible, setModalVerifySmsVisible] = useState<boolean>(false)
    const [isModalVerifyGaVisible, setModalVerifyGaVisible] = useState<boolean>(false)
    const [isPhoneRO, setIsPhoneRO] = useState<boolean>(false)
    const [verifyPhone, setVerifyPhone] = useState<string | undefined>()

    const [isViewer, setViewer] = useState(false)
    const [isEditor, setEditor] = useState(false)

    // get settings and logged user from store
    let loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()
    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('Panel2FA: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('Panel2FA: ' + msg)} }

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        // trigger ONCE
        logger('------------ trigger ONCE ')
        // load Options for refresh F5
        loadOptions()
    }, [])

    useEffect(() => {
        if (user) {
            logger('user loaded: '+user.id)
            setEditor(true)
        }
    }, [user])

    useEffect(() => {
        if (allAuthentications && allAuthentications.length) {
            if (authentications && authentications.length) {
                logger('authentications loaded: '+authentications.length)
                setOptionsAllAuthentications(allAuthentications.map(a2 => ({label: renderAuthState(a2), value: a2.id})).sort(sort_name))
                setUserAuthentications(authentications.map(item => { return {...item, name: allAuthentications.find(a => a.id === item.authentication_id)!.name }}))

                // after update in authentications
                // we need to choose active tab [SMS, GA]
                // choose active auth
                let activeAuths = authentications.filter(a => a.is_active === 1)
                if (activeAuths.length === 0) {
                    // find all active
                    activeAuths = [authentications[0]]
                }

                if (!selectedAuthenticationId) {
                    // set first active
                    logger('authentications no selectedAuthenticationId'+authentications.length)
                    setSelectedAuthenticationId(activeAuths[0].authentication_id)
                }
                else {
                    // reload selected to update data
                    setSelectedUserAuthentication(authentications.find(ua => ua.authentication_id === selectedAuthenticationId))
                }
            }
            else {
                // no saved userAuths
                setSelectedAuthenticationId(allAuthentications[0].id)
                setOptionsAllAuthentications(allAuthentications.map(a2 => ({label: renderAuthState(a2), value: a2.id})).sort(sort_name))
                if (userAuthentications.length === 0) {
                    transitionTo(Auth2faStates.NO_AUTH)
                }
            }
        }
    }, [allAuthentications, authentications, isSavingAuthentication])

    useEffect(() => {
        if (user && allAuthentications.length) {
            logger('selectedAuthenticationId: '+selectedAuthenticationId)
            if (selectedAuthenticationId) {
                const method = allAuthentications.find(a => a.id === selectedAuthenticationId)?.name
                method && setMethodName(method)
                let userAuth = userAuthentications.find(ua => ua.authentication_id === selectedAuthenticationId)
                if (!userAuth) {
                    // userAuthentications not exist yet
                    // New one
                    transitionTo(Auth2faStates.NO_AUTH)
                    return
                }
                else {
                    logger('UserAuthentication found: ', userAuth)
                    if (userAuth && !userAuth.expiration) {
                        userAuth = {...userAuth, expiration: 0}
                    }
                    setSelectedUserAuthentication(userAuth)
                }
            }
            else {
                // set the first active
                let activeAuths = authentications.filter(a => a.is_active === 1)
                let sid = (activeAuths.length > 0) ? activeAuths[0].authentication_id : allAuthentications[0].id
                setSelectedAuthenticationId(sid)
            }
        }
    }, [selectedAuthenticationId])

    useEffect(() => {
        if (user && user.id) {
            if (selectedUserAuthentication && selectedUserAuthentication.authentication_id) {
                if (selectedUserAuthentication.id) {
                    logger('selectedUserAuthentication: ', selectedUserAuthentication)

                    if (selectedUserAuthentication.authentication_id === AUTH_SMS) {
                        selectedUserAuthentication.ident ? setVerifyPhone(selectedUserAuthentication.ident) : setVerifyPhone('')
                    }
                }

                transitionTo(selectedUserAuthentication.authentication_id)
            }
        }
    }, [selectedUserAuthentication])

    useEffect(() => {
        if (user && verification && verification.user_id === user.id) {
            logger('verification: '+ verification.value)
            if (selectedUserAuthentication) {
                setSelectedUserAuthentication({...selectedUserAuthentication, is_verified: verification.is_verified})
                setUpdateStatusSMS(verification.is_verified === 1)
                setUpdateStatusGA(verification.is_verified === 1)
            }
            selectedUserAuthentication && setIsPhoneRO((verification.is_verified === 1) && !!selectedUserAuthentication.id)
        }
    }, [verification])

    useEffect(() => {
        if (user && selectedUserAuthentication && selectedUserAuthentication.id) {
            logger('auth_2fa_ips: '+ auth_2fa_ips.length)
            setDataSourceLog2FA(
                auth_2fa_ips
                    .filter(r => r.user_authentication_id === selectedUserAuthentication.id)
                    .map(rec => {return {...rec, methodName: methodName}})
                    .sort(sort_create)
            )
        }
    }, [auth_2fa_ips, selectedUserAuthentication?.id])

    const transitionTo = (page: number) => {
        setPageNum(page)
    }

    const createGA = (selUserAuth: UserAuthenticationModel) => {
        const params = {
            user_id: selUserAuth.user_id,
            authentication_id: selUserAuth.authentication_id,
            is_active: selUserAuth.is_active,
            ident: '',
            expiration: 0
        }
        dispatch(createUserAuthentication(params))
    }

    const addNewUserAuth = (auth_id: number) => {
        if (user) {
            logger('addNewUserAuth----------')
            const selAuth = {
                id: undefined,
                authentication_id: auth_id,
                name: allAuthentications.find(a => a.id === auth_id)?.name,
                user_id: user.id,
                is_active: 1,
                ident: auth_id === AUTH_SMS ? user.phone : '',
                is_verified: auth_id === AUTH_SMS ? (isPhoneVerified(user.phone) ? 1 : 0) : 0,
                expiration: 0
            }
            if (auth_id === AUTH_SMS) {
                setVerifyPhone(selAuth.ident)
                setUpdateStatusSMS(true)
            }
            if (auth_id === AUTH_GA) {
                createGA(selAuth)
                setUpdateStatusGA(true)
            }
            setSelectedAuthenticationId(auth_id)
            setSelectedUserAuthentication(selAuth)
        }
    }

    const renderAuthState = (a: AuthenticationModel) => {
        const items = authentications.filter(item => (item.authentication_id === a.id) && (item.is_active === 1) && (item.is_verified === 1))
        if (items.length === 0) {
            return (<span style={{whiteSpace: 'nowrap'}}><CloseCircleTwoTone twoToneColor="#cccccc" />&nbsp;&nbsp;{a.name}</span>)
        }
        else {
            return (<span style={{whiteSpace: 'nowrap'}}><CheckCircleTwoTone twoToneColor="#52c41a" />&nbsp;&nbsp;{a.name}</span>)
        }
    }


    const loadOptions = () => {
        // load Options for refresh F5
        dispatch(getAllAuthentications())

        setValidityOptions([
            { label: 'Vždy', value: 0},
            { label: '1x denně', value: 1},
            { label: '1x za 3 dny', value: 3},
            { label: '1x za týden', value: 7},
            { label: '1x za 14dní', value: 14},
            { label: '1x za měsíc', value: 30},
            { label: '1x za 3 měsíce', value: 90}
        ])

    }

    const isPhoneValid = (): boolean => {
        const phone = _parsePhone(verifyPhone)
        return _validatePhone(phone) && phone.length > 8
    }

    const isAuthDisabled = ():boolean => {
        if (!selectedUserAuthentication) {
            return true
        }
        return selectedUserAuthentication.is_active === 0
    }

    const isPhoneVerified = (phone: string | undefined): boolean => {
        if (!phone) {
            return false
        }
        return isIdentVerified('phone', phone, verifications)
    }

    const isEmailVerified = (email: string | undefined): boolean => {
        if (!email) {
            return false
        }
        return isIdentVerified('email', email, verifications)
    }

    const isGAVerified = (): boolean => {
        if (!selectedUserAuthentication || selectedUserAuthentication.authentication_id != AUTH_GA) {
            return false
        }
        return selectedUserAuthentication.is_verified === 1
    }


    const renderPhoneVerification = (phone?: string, isDisabled: boolean = false) => {
        if (isPhoneVerified(phone)) {
            if (isDisabled) {
                return (<Tag color='default' style={{color: '#bcbcbc'}}>{t('usersPage.phone_verified')}</Tag>)
            }
            return (<Tag color='success'>{t('usersPage.phone_verified')}</Tag>)
        }
        if (isDisabled) {
            return (<Tag color='default' style={{color: '#bcbcbc'}}>{t('usersPage.phone_not_verified')}</Tag>)
        }
        return (<Tag color='error'>{t('usersPage.phone_not_verified')}</Tag>)
    }

    const renderEmailVerification = (email: string | undefined) => {
        if (isEmailVerified(email)) {
            return (<Tag color='success'>{t('usersPage.email_verified')}</Tag>)
        }
        return (<Tag color='error'>{t('usersPage.email_not_verified')}</Tag>)
    }

    const renderGAVerification = () => {
        if (isGAVerified()) {
            return (<Tag color='success'>{t('usersPage.ga_verified')}</Tag>)
        }
        return (<Tag color='error'>{t('usersPage.ga_not_verified')}</Tag>)
    }

    const getIdent = (auth2fa?: UserAuthenticationModel) => {
        // return phone, email or GA link like a field 'ident'
        if (!auth2fa) {
            return ''
        }
        if (auth2fa.authentication_id === AUTH_SMS) {
            if (auth2fa.ident) {
                return auth2fa.ident
            }
            if (user && user.phone) {
                return user.phone
            }
        }
        if (auth2fa.ident) {
            return auth2fa.ident.trim()
        }
        return ''
    }

    const columns = [
        {
            title: t('Force2FA.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            width: 120,
            showSorterTooltip: false,
            render: (ts: number) => appSetting.renderDateTime(ts)
        },
        {
            title: t('Force2FA.remote_ip'),
            dataIndex: 'remote_ip',
            key: 'remote_ip',
            width: '60%',
            showSorterTooltip: false,

        },
        {
            title: t('Force2FA.action'),
            key: 'id',
            dataIndex: 'id',
            width: 40,
            showSorterTooltip: false,
            render: (id) => (
                <Space size={1}>
                    {
                        id > 0 && (
                            <Button type='text' danger size='small'
                                    className='actionButton'
                                    onClick={() => {
                                        props.deleteLog(id)
                                    }}
                                    icon={<DeleteTwoTone twoToneColor='red'/>}/>
                        )
                    }
                </Space>
            )
        }
    ]

    if (!user) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    const renderLoading = () => {
        return (
            <Row gutter={[0,0]}>
                <Col span={24}>
                    <div className="loading">
                        <span style={{margin: '20px'}}>loading..</span> <Spin />
                    </div>
                </Col>
            </Row>
        )
    }

    const renderCreateAuth= () => {
        return (
            <Row gutter={[0,0]}>
                <Col span={24} style={{textAlign: 'center', padding: '45px'}}>
                    <h2>{t('Force2FA.create')}</h2>
                    <h2>{methodName}</h2>
                    <Button type='primary'
                            className='login-form-button'
                            onClick={() => {
                                addNewUserAuth(selectedAuthenticationId)
                            }}
                            size='small'>
                        {t('general.create')}
                    </Button>
                </Col>
            </Row>
        )
    }

    const renderMethodSMS = () => {
        return (
            <>
                    <Row gutter={[0, 0]}>
                        <Col span={8} className='labelCol'><span className='label'>{t('Force2FA.active')}</span></Col>
                        <Col span={16}>
                            <Switch
                                checkedChildren={<CheckOutlined/>}
                                unCheckedChildren={<CloseOutlined/>}
                                checked={selectedUserAuthentication?.is_active === 1}
                                onChange={() => {
                                    if (selectedUserAuthentication && selectedUserAuthentication.is_verified === 1) {
                                        selectedUserAuthentication && setSelectedUserAuthentication({
                                            ...selectedUserAuthentication,
                                            is_active: selectedUserAuthentication.is_active === 0 ? 1 : 0
                                        })
                                        setUpdateStatusSMS(true)
                                        selectedUserAuthentication && setIsPhoneRO(selectedUserAuthentication.is_verified === 1 && !!selectedUserAuthentication.id)
                                    }
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={[0, 0]}>
                        <Col span={8} className='labelCol'><span className='label'>{t('createUserPage.phone')}</span></Col>
                        <Col span={16}>
                            {
                                selectedUserAuthentication && selectedUserAuthentication.is_active === 1 && (

                                    <Input name='ident'
                                           style={{width: '200px'}}
                                           readOnly={!!selectedUserAuthentication.id && isPhoneVerified(verifyPhone)}
                                           value={verifyPhone}
                                           onChange={(el) => {
                                               setVerifyPhone(el.target.value)
                                               setSelectedUserAuthentication({
                                                   ...selectedUserAuthentication,
                                                   ident: el.target.value,
                                                   is_verified: isPhoneVerified(el.target.value) ? 1 : 0
                                               })
                                               setUpdateStatusSMS(isPhoneVerified(el.target.value))
                                           }}
                                    />
                                )
                            }

                            {
                                selectedUserAuthentication && selectedUserAuthentication.is_active === 0 && (
                                    <b className='text-disabled'>{selectedUserAuthentication && selectedUserAuthentication.ident}
                                        <Input type='hidden'/>
                                    </b>
                                )
                            }
                        </Col>
                    </Row>
                    <Row gutter={[0, 0]}>
                        <Col span={8} className='labelCol'><span className='label'>{t('Force2FA.verification')}</span></Col>
                        <Col span={16}>
                            {selectedUserAuthentication && renderPhoneVerification(selectedUserAuthentication.ident, isAuthDisabled())}
                            {
                                selectedUserAuthentication && !isPhoneVerified(selectedUserAuthentication.ident) && (
                                    <Button type='primary'
                                            className='login-form-button'
                                            disabled={isAuthDisabled()}
                                            onClick={() => {
                                                // phone is valid
                                                setSelectedUserAuthentication({
                                                    ...selectedUserAuthentication,
                                                    ident: verifyPhone
                                                })
                                                setModalVerifySmsVisible(true)
                                            }}
                                            size='small'>
                                        {t('Force2FA.verify')}
                                    </Button>
                                )
                            }
                        </Col>
                    </Row>
                    <Row gutter={[0, 0]} >
                        <Col span={8} className='labelCol'><span className='label' title={t('Force2FA.expiration_desc')}>{t('Force2FA.expiration')}</span></Col>
                        <Col span={16}>
                                {
                                    selectedUserAuthentication && selectedUserAuthentication.is_active === 1 && (
                                        <Select
                                            options={validityOptions}
                                            value={selectedUserAuthentication.expiration}
                                            disabled={isAuthDisabled()}
                                            style={{width: '200px'}}
                                            onClick={stopPropagation}
                                            onChange={(v) => {
                                                setUpdateStatusSMS(true)
                                                setSelectedUserAuthentication({
                                                    ...selectedUserAuthentication,
                                                    expiration: v
                                                })
                                                logger('expiration: ', {...selectedUserAuthentication, expiration: v})
                                            }}
                                        />
                                    )
                                }

                                {
                                    selectedUserAuthentication && selectedUserAuthentication.is_active === 0 && (
                                        <b className='text-disabled' style={{margin: '5px'}}>{validityOptions.find(opt => opt.value === selectedUserAuthentication.expiration)?.label}</b>
                                    )
                                }
                        </Col>

                        <Col span={8}>
                            {
                                selectedUserAuthentication && selectedUserAuthentication.id &&
                                (
                                    <Button type='link' style={{margin: '8px'}}
                                            className='btnDelete'
                                            size='small'
                                            onClick={() => {
                                                props.deleteAuth(selectedUserAuthentication)
                                                setSelectedUserAuthentication(undefined)
                                            }}
                                    >
                                        {t('general.delete')}
                                    </Button>
                                )
                            }
                        </Col>
                        <Col span={8} style={{textAlign: 'left'}}>
                            <Item>
                                {
                                    selectedUserAuthentication && (
                                        <Button type='primary'
                                                className='login-form-button'
                                                disabled={!updateStatusSMS || (selectedUserAuthentication && (selectedUserAuthentication.is_verified != 1))}
                                                onClick={() => {
                                                    const paramSms = {
                                                        ...selectedUserAuthentication,
                                                        ident: verifyPhone,
                                                    }
                                                    props.saveSmsAuth(paramSms)
                                                    setUpdateStatusSMS(false)
                                                }}
                                        >
                                            {t('general.save')}
                                        </Button>
                                    )
                                }
                            </Item>
                        </Col>
                        <Col span={8}></Col>
                    </Row>

            </>
        )
    }


    const renderMethodGA = () => {
        return (
            <>

                    <Row gutter={[0, 0]}>
                        <Col span={8} className='labelCol'><span className='label'>{t('Force2FA.active')}</span></Col>
                        <Col span={16}>
                                <Switch
                                    checkedChildren={<CheckOutlined/>}
                                    unCheckedChildren={<CloseOutlined/>}
                                    checked={selectedUserAuthentication && selectedUserAuthentication.is_verified === 1 && selectedUserAuthentication.is_active === 1}
                                    style={{paddingTop: 0, marginTop: 0}}
                                    onChange={() => {
                                        if (selectedUserAuthentication && selectedUserAuthentication.is_verified === 1) {
                                            setSelectedUserAuthentication({
                                                ...selectedUserAuthentication,
                                                is_active: selectedUserAuthentication.is_active === 0 ? 1 : 0
                                            })
                                            setUpdateStatusGA(true)
                                        }
                                    }}
                                />
                        </Col>
                    </Row>

                    <Row gutter={[0, 0]}>
                        <Col span={8} className='labelCol'><span className='label' title={t('Force2FA.verification_desc')}>{t('Force2FA.verification')}</span></Col>
                        <Col span={16} style={{marginTop: '4px'}}>
                            {selectedUserAuthentication && renderGAVerification()}
                            {
                                selectedUserAuthentication && selectedUserAuthentication.is_verified === 0 && (
                                    <Button type='primary'
                                            className='login-form-button'
                                            onClick={() => {
                                                setModalVerifyGaVisible(true)
                                            }}
                                            size='small'>
                                        {t('Force2FA.verify')}
                                    </Button>
                                )
                            }
                        </Col>
                    </Row>

                <Row gutter={[0, 0]}>
                    <Col span={8} className='labelCol'><span className='label' title={t('Force2FA.expiration_desc')}>{t('Force2FA.expiration')}</span></Col>
                    <Col span={16} style={{marginTop: '4px'}}>
                        {
                            selectedUserAuthentication && selectedUserAuthentication.is_active === 1 && (
                                <Select
                                    options={validityOptions}
                                    value={selectedUserAuthentication.expiration}
                                    disabled={isAuthDisabled()}
                                    style={{width: '200px'}}
                                    onClick={stopPropagation}
                                    onChange={(v) => {
                                        setUpdateStatusGA(true)
                                        setSelectedUserAuthentication({
                                            ...selectedUserAuthentication,
                                            expiration: v
                                        })
                                        logger('expiration: ', {...selectedUserAuthentication, expiration: v})
                                    }}
                                />
                            )
                        }

                        {
                            selectedUserAuthentication && selectedUserAuthentication.is_active === 0 && (
                                <div className='text-disabled'>
                                    {validityOptions.find(opt => opt.value === selectedUserAuthentication.expiration)?.label}
                                    <Input type='hidden'/>
                                </div>
                            )
                        }
                    </Col>
                </Row>

                    <Row gutter={[0, 0]} style={{marginTop: '15px'}}>
                        <Col span={8}>
                            {
                                selectedUserAuthentication && selectedUserAuthentication.id &&
                                (
                                    <Button type='link' style={{margin: '8px'}}
                                            className='btnDelete'
                                            size='small'
                                            onClick={() => {
                                                props.deleteAuth(selectedUserAuthentication)
                                            }}
                                    >
                                        {t('general.delete')}
                                    </Button>
                                )
                            }
                        </Col>
                        <Col span={8} style={{textAlign: 'left'}}>
                            {
                                selectedUserAuthentication && (
                                    <Button type='primary'
                                            className='login-form-button'
                                            onClick={() => {
                                                props.saveGAuth(selectedUserAuthentication)
                                                setUpdateStatusGA(false)
                                            }}
                                            disabled={!updateStatusGA || !selectedUserAuthentication || selectedUserAuthentication.is_verified === 0}>
                                        {t('general.save')}
                                    </Button>
                                )
                            }
                        </Col>
                        <Col span={8}></Col>
                    </Row>

            </>
        )
    }

    const getContent = () => {
        // render
        switch(pageNum) {
            case Auth2faStates.LOADING_AUTH:
                return renderLoading()

            case Auth2faStates.NO_AUTH:
                return renderCreateAuth()

            case Auth2faStates.AUTH_SMS:
                return renderMethodSMS()

            case Auth2faStates.AUTH_GA:
                return renderMethodGA()

            default:
                return renderLoading()
        }
    }


    return (
        <Card title={t('Force2FA.title2')}
              extra={appSetting.auth_2fa_required ? (<>{t('Force2FA.is_required')}:&nbsp;{renderYes(t('general.yes'))}</>) : (<>{t('Force2FA.is_required')}:&nbsp;{renderNo(t('general.no'))}</>)}
              className='fblock'
              bodyStyle={{height: '500px'}}
              style={{height: '500px'}}>

            <Row gutter={[0,0]}>
                <Col span={8} className='labelCol' style={{paddingRight: '4px'}}>
                    <b className='text-disabled'>{t('Force2FA.method')}</b>
                </Col>
                <Col span={16} style={{textAlign: 'right'}}>
                    {
                        allAuthentications.length === 0 && (<span>{t('Force2FA.methods_not_allowed')}</span>)
                    }
                    {
                        allAuthentications.length && (
                            <Radio.Group
                                options={optionsAllAuthentications}
                                onChange={(v) => setSelectedAuthenticationId(v.target.value)}
                                value={selectedAuthenticationId}
                                optionType="button"
                                buttonStyle="solid"
                                style={{display: 'inline-flex', borderRadius: '4px'}}
                            />
                        )
                    }
                </Col>
            </Row>

                {
                    getContent()
                }

            {
                ((pageNum === Auth2faStates.AUTH_SMS) || (pageNum === Auth2faStates.AUTH_GA)) && (
                    <Row gutter={[0,0]}>
                        <Col span={24}>
                            {
                                selectedUserAuthentication && selectedUserAuthentication.id && (
                                    <Table<LogRecord2fa>
                                        dataSource={dataSourceLog2FA}
                                        columns={columns}
                                        size='small'
                                        scroll={{ y: 120 }}
                                        className='Log2faTable'
                                        rowKey={(record) => `${record.id}`}
                                        showHeader={true}
                                        bordered={true}
                                        loading={false}
                                        pagination={false}
                                    />
                                )
                            }
                        </Col>
                    </Row>
                )
            }

            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                     onMouseOut={() => {setDisabled(true)}}
                     onFocus={() => {}}
                     onBlur={() => {}}
                >
                    <><CommentOutlined/> &nbsp; {t('Force2FA.verify_phone')}</>
                </div>
            }
                   destroyOnClose
                   className='modalComment'
                   style={{top: 20}}
                   visible={isModalVerifySmsVisible}
                   onOk={() => setModalVerifySmsVisible(false)}
                   onCancel={() => setModalVerifySmsVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   getContainer={false}
                   footer={null}
                   confirmLoading={true}
            >
                <VerifySMS ident={verifyPhone} setModalVisible={setModalVerifySmsVisible} />
            </Modal>

            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                     onMouseOut={() => {setDisabled(true)}}
                     onFocus={() => {}}
                     onBlur={() => {}}
                >
                    <>2FA Google Authenticator</>
                </div>
            }
                   destroyOnClose
                   className='modalComment'
                   style={{top: 20}}
                   visible={isModalVerifyGaVisible}
                   onOk={() => setModalVerifyGaVisible(false)}
                   onCancel={() => setModalVerifyGaVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   getContainer={false}
                   footer={null}
                   confirmLoading={true}
            >
                <VerifyGA auth2FA={selectedUserAuthentication} setModalVisible={setModalVerifyGaVisible} />
            </Modal>

        </Card>
    )
}

export default Panel2FA
