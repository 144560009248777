import { AsyncActionMode } from 'common/models'
import {
    UserGroupModel,
    CreateGroupParams,
    UpdateGroupParams,
    AddDeleteRoleToGroupParams, AddDeleteResGroupToGroupParams,
} from '../models'
import {AddDeleteUserToGroupParams} from "../../users/models";


export enum ActionType {
    CREATE_USER_GROUP = 'CREATE_USER_GROUP',
    GET_USER_GROUP = 'GET_USER_GROUP',
    UPDATE_USER_GROUP = 'UPDATE_USER_GROUP',
    DELETE_USER_GROUP = 'DELETE_USER_GROUP',
    GET_USER_GROUP_BY_PARENT = 'GET_USER_GROUP_BY_PARENT',
    GET_ROOT_GROUP = 'GET_ROOT_GROUP',
    REMOVE_ROLES_IN_GROUP = 'REMOVE_ROLES_IN_GROUP',
    ADD_ROLES_IN_GROUP = 'ADD_ROLES_IN_GROUP',
    REMOVE_RES_GROUPS_IN_GROUP = 'REMOVE_RES_GROUPS_IN_GROUP',
    ADD_RES_GROUPS_IN_GROUP = 'ADD_RES_GROUPS_IN_GROUP',
    USERGROUP_ADD_USER_TO_GROUP = 'USERGROUP_ADD_USER_TO_GROUP',
    USERGROUP_REMOVE_USER_FROM_GROUP = 'USERGROUP_REMOVE_USER_FROM_GROUP',
}

export type GroupActions =
    | CreateUserGroupRequestAction
    | CreateUserGroupResponseAction
    | CreateUserGroupErrorAction
    | GetUserGroupRequestAction
    | GetUserGroupResponseAction
    | GetUserGroupErrorAction
    | UpdateUserGroupRequestAction
    | UpdateUserGroupResponseAction
    | UpdateUserGroupErrorAction
    | DeleteUserGroupRequestAction
    | DeleteUserGroupResponseAction
    | DeleteUserGroupErrorAction
    | GetUserGroupByParentRequestAction
    | GetUserGroupByParentResponseAction
    | GetUserGroupByParentErrorAction
    | GetRootGroupRequestAction
    | GetRootGroupResponseAction
    | GetRootGroupErrorAction
    | RemoveRoleInGroupRequestAction
    | RemoveRoleInGroupResponseAction
    | RemoveRoleInGroupErrorAction
    | AddRoleInGroupRequestAction
    | AddRoleInGroupResponseAction
    | AddRoleInGroupErrorAction
    | RemoveResGroupInGroupRequestAction
    | RemoveResGroupInGroupResponseAction
    | RemoveResGroupInGroupErrorAction
    | AddResGroupInGroupRequestAction
    | AddResGroupInGroupResponseAction
    | AddResGroupInGroupErrorAction
    | UserGroupAddUserToGroupRequestAction
    | UserGroupAddUserToGroupResponseAction
    | UserGroupAddUserToGroupErrorAction
    | UserGroupRemoveUserFromGroupRequestAction
    | UserGroupRemoveUserFromGroupResponseAction
    | UserGroupRemoveUserFromGroupErrorAction



export class CreateUserGroupRequestAction {
    readonly type = ActionType.CREATE_USER_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: CreateGroupParams) {}
}
export class CreateUserGroupResponseAction {
    readonly type = ActionType.CREATE_USER_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: CreateUserGroupRequestAction,
        public data: UserGroupModel
    ) {}
}
export class CreateUserGroupErrorAction {
    readonly type = ActionType.CREATE_USER_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: CreateUserGroupRequestAction,
        public error: string,
    ) {}
}

export class GetUserGroupRequestAction {
    readonly type = ActionType.GET_USER_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetUserGroupResponseAction {
    readonly type = ActionType.GET_USER_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetUserGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class GetUserGroupErrorAction {
    readonly type = ActionType.GET_USER_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetUserGroupRequestAction,
        public error: string,
    ) {}
}

export class GetRootGroupRequestAction {
    readonly type = ActionType.GET_ROOT_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public customer_id: number | undefined) {
        ''
    }
}

export class GetRootGroupResponseAction {
    readonly type = ActionType.GET_ROOT_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetRootGroupRequestAction, public data: UserGroupModel) {
    }
}

export class GetRootGroupErrorAction {
    readonly type = ActionType.GET_ROOT_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetRootGroupRequestAction, public error: string) {
    }
}

export class GetUserGroupByParentRequestAction {
    readonly type = ActionType.GET_USER_GROUP_BY_PARENT
    readonly mode = AsyncActionMode.REQUEST
    constructor(public id: number) {
        ''
    }
}

export class GetUserGroupByParentResponseAction {
    readonly type = ActionType.GET_USER_GROUP_BY_PARENT
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetUserGroupByParentRequestAction, public data: UserGroupModel[]) {
    }
}

export class GetUserGroupByParentErrorAction {
    readonly type = ActionType.GET_USER_GROUP_BY_PARENT
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetUserGroupByParentRequestAction, public error: string) {
    }
}

export class UpdateUserGroupRequestAction {
    readonly type = ActionType.UPDATE_USER_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: UpdateGroupParams) {}
}


export class UpdateUserGroupResponseAction {
    readonly type = ActionType.UPDATE_USER_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: UpdateUserGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class UpdateUserGroupErrorAction {
    readonly type = ActionType.UPDATE_USER_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: UpdateUserGroupRequestAction,
        public error: string,
    ) {}
}

export class DeleteUserGroupRequestAction {
    readonly type = ActionType.DELETE_USER_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public id: number) {}
}
export class DeleteUserGroupResponseAction {
    readonly type = ActionType.DELETE_USER_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: DeleteUserGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class DeleteUserGroupErrorAction {
    readonly type = ActionType.DELETE_USER_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: DeleteUserGroupRequestAction,
        public error: string,
    ) {}
}

export class AddRoleInGroupRequestAction {
    readonly type = ActionType.ADD_ROLES_IN_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: AddDeleteRoleToGroupParams) {}
}
export class AddRoleInGroupResponseAction {
    readonly type = ActionType.ADD_ROLES_IN_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: AddRoleInGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class AddRoleInGroupErrorAction {
    readonly type = ActionType.ADD_ROLES_IN_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: AddRoleInGroupRequestAction,
        public error: string,
    ) {}
}

export class RemoveRoleInGroupRequestAction {
    readonly type = ActionType.REMOVE_ROLES_IN_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: AddDeleteRoleToGroupParams) {}
}
export class RemoveRoleInGroupResponseAction {
    readonly type = ActionType.REMOVE_ROLES_IN_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: RemoveRoleInGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class RemoveRoleInGroupErrorAction {
    readonly type = ActionType.REMOVE_ROLES_IN_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: RemoveRoleInGroupRequestAction,
        public error: string,
    ) {}
}

export class AddResGroupInGroupRequestAction {
    readonly type = ActionType.ADD_RES_GROUPS_IN_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: AddDeleteResGroupToGroupParams) {}
}
export class AddResGroupInGroupResponseAction {
    readonly type = ActionType.ADD_RES_GROUPS_IN_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: AddResGroupInGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class AddResGroupInGroupErrorAction {
    readonly type = ActionType.ADD_RES_GROUPS_IN_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: AddResGroupInGroupRequestAction,
        public error: string,
    ) {}
}


export class UserGroupAddUserToGroupRequestAction {
    readonly type = ActionType.USERGROUP_ADD_USER_TO_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: AddDeleteUserToGroupParams) {}
}
export class UserGroupAddUserToGroupResponseAction {
    readonly type = ActionType.USERGROUP_ADD_USER_TO_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: UserGroupAddUserToGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class UserGroupAddUserToGroupErrorAction {
    readonly type = ActionType.USERGROUP_ADD_USER_TO_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: UserGroupAddUserToGroupRequestAction,
        public error: string,
    ) {}
}
export class UserGroupRemoveUserFromGroupRequestAction {
    readonly type = ActionType.USERGROUP_REMOVE_USER_FROM_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: AddDeleteUserToGroupParams) {}
}
export class UserGroupRemoveUserFromGroupResponseAction {
    readonly type = ActionType.USERGROUP_REMOVE_USER_FROM_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: UserGroupRemoveUserFromGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class UserGroupRemoveUserFromGroupErrorAction {
    readonly type = ActionType.USERGROUP_REMOVE_USER_FROM_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: UserGroupRemoveUserFromGroupRequestAction,
        public error: string,
    ) {}
}

export class RemoveResGroupInGroupRequestAction {
    readonly type = ActionType.REMOVE_RES_GROUPS_IN_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: AddDeleteResGroupToGroupParams) {}
}
export class RemoveResGroupInGroupResponseAction {
    readonly type = ActionType.REMOVE_RES_GROUPS_IN_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: RemoveResGroupInGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class RemoveResGroupInGroupErrorAction {
    readonly type = ActionType.REMOVE_RES_GROUPS_IN_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: RemoveResGroupInGroupRequestAction,
        public error: string,
    ) {}
}
