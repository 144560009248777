import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    UpdateNoteErrorAction,
    UpdateNoteRequestAction,
    UpdateNoteResponseAction
} from "./index";
import {InvoiceDetails, NoteInvoicePayload} from "../models";


export default (
    params: NoteInvoicePayload,
    cb?: (isSuccess: boolean) => void,
    onProgress?: (ProgressEvent: any) => void,
) => {
    return (
        dispatch: (
            arg:
                | UpdateNoteRequestAction
                | UpdateNoteResponseAction
                | UpdateNoteErrorAction
        ) => void,
    ) => {
        const request = new UpdateNoteRequestAction(params)
        dispatch(request)
        protectedApiClient
            .put<InvoiceDetails>(
                `/billing/invoice/${params.id}/note`,
                params,
                { onUploadProgress: onProgress },
            )
            .then((response) => {
                dispatch(
                    new UpdateNoteResponseAction(request, response.data),
                )
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateNoteErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
