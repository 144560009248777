import { ActionType, InventoryTypeActions } from './actions'
import { AsyncActionMode } from 'common/models'
import {InventoryTypeModel, InventoryTypeState} from './models'

const GET_INVENTORY_TYPES_INITIAL_STATE: InventoryTypeState = {
  isLoading: false,
  isSaving: false,
  inventory_types: [],
  error: undefined
}

export default (
  state = GET_INVENTORY_TYPES_INITIAL_STATE,
  action: InventoryTypeActions,
): InventoryTypeState => {
  switch (action.type) {
    case ActionType.GET_INVENTORY_TYPES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          inventory_types: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_INVENTORY_TYPE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          inventory_types: state.inventory_types?.concat(action.data),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_INVENTORY_TYPE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          inventory_types: state.inventory_types!.map((item) =>
              item.id === action.data.id ? { ...action.data } : item,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_INVENTORY_TYPE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          inventory_types: state.inventory_types!.filter(
              (item) => item.id !== action.request.id,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

  }
  return state
}
