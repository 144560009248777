import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "antd/lib/form/Form";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {Button, Col, Form, Input, message, Row, Select, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {tailLayout} from "../../../helpers/layoutHelpers";
import {Store} from "antd/lib/form/interface";
import {UpdateIpParams} from "./models";
import {AppState} from "../../../common/models";
import {sort_label} from "../../../common/sorting";
import updateIP from "./actions/updateIP";
import TextArea from "antd/lib/input/TextArea";
import tableCustomers from "../../login/actions/tableCustomers";
import getDnsServices from "./actions/getDnsServices";

interface DnsModel {
    id: number
    host: string
    address: string
}

interface Props {
    id: number
    onClose: () => void
}

const EditIpForm = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form1] = useForm()

    const {customers} = useSelector((state: AppState) => state.auth.tables)
    const {isLoadingIp, ip, dnsServiceNames, isSaving} = useSelector((state: AppState) => state.ipSubnet)

    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        if (!dnsServiceNames || !dnsServiceNames.length) {
            dispatch(getDnsServices())
        }
        if(customers && customers.length) {
            const data = customers.map((c) => {return {
                value: c.id,
                key: c.id,
                label: c.company!.name
            }})
            setCustomerOptions(data.sort(sort_label))
        }
        else {
            dispatch(tableCustomers())
        }
    }, [])

    useEffect(() => {
        setSelectedCustomerId(loggedUser.customer.id)
    }, [customers])

    useEffect(() => {
        if (ip && ip.id === props.id) {
            setSelectedCustomerId(ip.customer_id)
        }
    }, [ip])

    const submitIP = (values: Store): void => {
        const params: UpdateIpParams = {
            id: props.id,
            reverse_domain: values.reverse_domain,
            note: values.note
        }
        dispatch(updateIP(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                props.onClose()
            }
        }))
    }

    if (isLoadingIp || !ip) {
        return <Spin />
    }

    return (
        <>
            <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '0'}}>
                <Col span={6} className='right' >{t('ipSubnetPage.newSubnet.customer')}:&nbsp;</Col>
                <Col span={18} >
                    <Select optionFilterProp='label'
                            style={{width: '100%'}}
                            options={customerOptions}
                            defaultValue={ip.customer_id}
                            disabled={true}
                    />
                </Col>
            </Row>

            <Row justify='space-between' style={{marginTop: '25px'}}>
                <Col span={24} >

                    <Form name='create-ip'
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          layout="horizontal"
                          form={form1}
                          initialValues={{...ip}}
                          onFinish={submitIP}
                    >

                        <Form.Item name='address' label={t('ipSubnetPage.address')}>
                            <Input disabled />
                        </Form.Item>

                        <Form.Item name='reverse_domain' label={t('ipSubnetPage.newSubnet.reverse')}>
                            <Input placeholder='reverse.record.com' />
                        </Form.Item>

                        <Form.Item name='note' label={t('ipSubnetPage.newSubnet.note')}>
                            <TextArea data-gramm="false"
                                      data-gramm_editor="false"
                                      data-enable-grammarly="false" />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type='primary' htmlType='submit'
                                    loading={isSaving}
                                    className='create-button'>
                                {t('general.update')}
                            </Button>
                        </Form.Item>
                    </Form>

                </Col>
            </Row>
        </>
    )

}

export default EditIpForm