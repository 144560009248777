import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {GetUserWithGroupsErrorAction, GetUserWithGroupsRequestAction, GetUserWithGroupsResponseAction} from "./index";
import {UserGroupModel} from "../../usergroups/models";
import {UserDetails} from "../../../user/models";

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (args: GetUserWithGroupsRequestAction | GetUserWithGroupsResponseAction | GetUserWithGroupsErrorAction) => void) => {
        const request = new GetUserWithGroupsRequestAction(id)
        dispatch(request)

        protectedApiClient.get<UserDetails>(`/user/${id}?expand=roles,groups,resourceGroups`)
            .then((response) => {
                dispatch(new GetUserWithGroupsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch(error => {
                dispatch(new GetUserWithGroupsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}