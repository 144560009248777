import React, { useEffect, useRef, useState } from 'react'
import {Progress, Upload, Skeleton, Spin, message} from 'antd'
import {PlusOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/es/upload/interface'
import { useDispatch, useSelector } from 'react-redux'
import createInvoiceAttachment from '../../pages/billing/invoice/actions/createInvoiceAttachment'
import { AppState } from 'common/models'
import getInvoiceAttachments from '../../pages/billing/invoice/actions/getInvoiceAttachments'
import deleteInvoiceAttachment from '../../pages/billing/invoice/actions/deleteInvoiceAttachment'
import './InvoiceAttachment.scss'
import Button from 'antd-button-color'
import {protectedApiClient} from 'helpers/api'
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../index";


interface Props {
  invoice_id: number
}

const InvoiceAttachment = (props: Props) => {
  const {invoice_id} = props
  const dispatch = useDispatch()

  const {invoice, attachments} = useSelector((state: AppState) => state.invoice)

  const [previewName, setPreviewName] = useState<string>()
  const [fileList, setFileList] = useState<any[]>([])
  const [progress, setProgress] = useState(0)
  const [downloading, setDownloading] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)
  const mounted = useRef(true)

  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    return (<Spin/>)
  }

  useEffect(() => {
    if (invoice) {
      if (attachments.length) {
        const data = attachments.map(attch => {
            return {
              uid: attch.id,
              name: attch.name,
              invoice_id: invoice_id,
              mime_type: attch.mime_type
            }
        })
        setFileList(data)
      }
    }
  }, [invoice])


  useEffect(() => {
    mounted.current = true
    if (mounted.current && attachments.length) {
      setFileList(attachments.map(attch => {
        return {
          uid: attch.id,
          name: attch.name,
          invoice_id: invoice_id,
          percent: 100,
          status: 'done',
          mime_type: attch.mime_type
        }
      }))
    }
    return () => { mounted.current = false }
  }, [attachments.length])

  const handlePreview = file => {
    handleDownload(file)
  }

  const handleDownload = (file) => {
    setDownloading(true)
    protectedApiClient.get<string>(`/billing/invoice-attachment/${file.uid}/content`,{onDownloadProgress: () => {
      }})
      .then(response => {
        const downloadLink = document.createElement("a")
        downloadLink.href = `data:${file.mime_type};base64,${response.data}`
        downloadLink.download = file.name
        downloadLink.click()
        setDownloading(false)
      })
      .catch(error => {
        console.log(error)
        message.error(error.message)
        setDownloading(false)
      })
  }

  const handleUpload = (info) => {
    if (fileList && info.fileList.length > fileList.length) {
      let reader = new FileReader()
      setUploading(true)
      reader.onload = (e) => {
        info.file.base64 = e.target?.result
        dispatch(
            createInvoiceAttachment({
                  invoice_id: invoice_id,
                  name: info.file.name,
                  content: (e.target?.result as string).split(',')[1]
                },
                (attachment_id, suc) => {
                  setUploading(false)
                  !suc && message.error('Problem with upload..')
                },
                progressEvent => {
                  const percent = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                  setProgress(percent)
                })
        )
      }
      reader.readAsDataURL(info.file)
    }
  }

  const onRemove = (file: UploadFile) => {
    if (!loggedUser.hasAccess('page_billing_invoice_edit_button')) {
      // permission
      return
    }

    dispatch(
        deleteInvoiceAttachment(
            Number(file.uid),
            isSuccess => isSuccess &&
                dispatch(getInvoiceAttachments(invoice_id))
        )
    )
  }

  function downloadBase64File(base64Data, fileName) {
    const linkSource = `${base64Data}`
    const downloadLink = document.createElement("a")
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
    setPreviewName(fileName)
  }

  return (
    <>
      {uploading ? <Progress percent={progress} /> : null}

      <Upload
        className='InvoiceAttachment'
        accept='.png, .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .ppt, .pptx'
        listType='text'
        fileList={fileList}
        onPreview={handlePreview}
        onDownload={handleDownload}
        onChange={handleUpload}
        onRemove={onRemove}
        disabled={!invoice_id || !loggedUser.hasAccess('page_billing_invoice_edit_button')}
        beforeUpload={() => false}
        maxCount={10}
        showUploadList={{showDownloadIcon: true, downloadIcon: <ArrowDownOutlined />}}
      >

        <Button icon={<PlusOutlined />}>Upload</Button>
        { downloading && (<Spin />) }
      </Upload>

    </>
  )

}

export default InvoiceAttachment
