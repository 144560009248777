import {AsyncActionMode} from "../../../../common/models";
import {UserGroupModel} from "../../usergroups/models";
import {UserDetails} from "../../../user/models";
import {AddDeleteUserToGroupParams} from "../models";


export enum ActionType {
    GET_USER_GROUP = 'GET_USER_GROUP',
    GET_USER_WITH_GROUPS = 'GET_USER_WITH_GROUPS',
    GET_USERS_RIGHTS = 'GET_USERS_RIGHTS',
    GET_GROUPS_ALL = 'GET_GROUPS_ALL',
    GET_GROUPS_BY_CUSTOMER = 'GET_GROUPS_BY_CUSTOMER',
    REMOVE_USER_FROM_GROUP = 'REMOVE_USER_FROM_GROUP',
    ADD_USER_TO_GROUP = 'ADD_USER_TO_GROUP',
}


export type UserRightsActions =
    | GetGroupRequestAction
    | GetGroupResponseAction
    | GetGroupErrorAction
    | GetUsersRightsRequestAction
    | GetUsersRightsResponseAction
    | GetUsersRightsErrorAction
    | GetUserWithGroupsRequestAction
    | GetUserWithGroupsResponseAction
    | GetUserWithGroupsErrorAction
    | GetGroupsAllRequestAction
    | GetGroupsAllResponseAction
    | GetGroupsAllErrorAction
    | GetGroupsByCustomerErrorAction
    | GetGroupsByCustomerRequestAction
    | GetGroupsByCustomerResponseAction
    | AddUserToGroupRequestAction
    | AddUserToGroupResponseAction
    | AddUserToGroupErrorAction
    | RemoveUserFromGroupRequestAction
    | RemoveUserFromGroupResponseAction
    | RemoveUserFromGroupErrorAction


export class GetUsersRightsRequestAction {
    readonly type = ActionType.GET_USERS_RIGHTS
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetUsersRightsResponseAction {
    readonly type = ActionType.GET_USERS_RIGHTS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetUsersRightsRequestAction,
        public data: UserDetails[],
    ) {}
}
export class GetUsersRightsErrorAction {
    readonly type = ActionType.GET_USERS_RIGHTS
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetUsersRightsRequestAction, public error: string) {}
}


export class GetGroupRequestAction {
    readonly type = ActionType.GET_USER_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(id: number) {
        ''
    }
}
export class GetGroupResponseAction {
    readonly type = ActionType.GET_USER_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class GetGroupErrorAction {
    readonly type = ActionType.GET_USER_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetGroupRequestAction,
        public error: string,
    ) {}
}


export class GetUserWithGroupsRequestAction {
    readonly type = ActionType.GET_USER_WITH_GROUPS
    readonly mode = AsyncActionMode.REQUEST
    constructor(user_id: number) {
        ''
    }
}
export class GetUserWithGroupsResponseAction {
    readonly type = ActionType.GET_USER_WITH_GROUPS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetUserWithGroupsRequestAction,
        public data: UserDetails,
    ) {}
}
export class GetUserWithGroupsErrorAction {
    readonly type = ActionType.GET_USER_WITH_GROUPS
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetUserWithGroupsRequestAction,
        public error: string,
    ) {}
}


export class GetGroupsAllRequestAction {
    readonly type = ActionType.GET_GROUPS_ALL
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetGroupsAllResponseAction {
    readonly type = ActionType.GET_GROUPS_ALL
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetGroupsAllRequestAction,
        public data: UserGroupModel[],
    ) {}
}
export class GetGroupsAllErrorAction {
    readonly type = ActionType.GET_GROUPS_ALL
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetGroupsAllRequestAction,
        public error: string,
    ) {}
}


export class GetGroupsByCustomerRequestAction {
    readonly type = ActionType.GET_GROUPS_BY_CUSTOMER
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetGroupsByCustomerResponseAction {
    readonly type = ActionType.GET_GROUPS_BY_CUSTOMER
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetGroupsByCustomerRequestAction,
        public data: UserGroupModel[],
    ) {}
}
export class GetGroupsByCustomerErrorAction {
    readonly type = ActionType.GET_GROUPS_BY_CUSTOMER
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetGroupsByCustomerRequestAction,
        public error: string,
    ) {}
}

export class AddUserToGroupRequestAction {
    readonly type = ActionType.ADD_USER_TO_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: AddDeleteUserToGroupParams) {}
}
export class AddUserToGroupResponseAction {
    readonly type = ActionType.ADD_USER_TO_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: AddUserToGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class AddUserToGroupErrorAction {
    readonly type = ActionType.ADD_USER_TO_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: AddUserToGroupRequestAction,
        public error: string,
    ) {}
}


export class RemoveUserFromGroupRequestAction {
    readonly type = ActionType.REMOVE_USER_FROM_GROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: AddDeleteUserToGroupParams) {}
}
export class RemoveUserFromGroupResponseAction {
    readonly type = ActionType.REMOVE_USER_FROM_GROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: RemoveUserFromGroupRequestAction,
        public data: UserGroupModel,
    ) {}
}
export class RemoveUserFromGroupErrorAction {
    readonly type = ActionType.REMOVE_USER_FROM_GROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: RemoveUserFromGroupRequestAction,
        public error: string,
    ) {}
}