import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "antd/lib/form/Form";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {Button, Col, Form, Input, message, Row, Select, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {tailLayout} from "../../../helpers/layoutHelpers";
import {Store} from "antd/lib/form/interface";
import ipRegex from 'ip-regex';
import {AddIpParams} from "./models";
import {AppState} from "../../../common/models";
import addIP from "./actions/addIP";


interface Props {
    onClose: () => void
}


const NewIpv4 = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form1] = useForm()

    const {ip} = useSelector((state: AppState) => state.ipSubnet)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once

    }, [])

    const submitSubnet = (values: Store): void => {
        if (ip) {
            const params: AddIpParams = {
                id: ip?.id,
                address: values.address,
            }
            dispatch(addIP(params, suc => {
                if (suc) {
                    message.success(t('general.success'))
                    props.onClose()
                }
            }))
        }
    }

    if (!ip) {
        return (<Spin/>)
    }

    return (
        <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '0'}}>
            <Col span={24} >
                <Form name='create-subnet'
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      layout="horizontal"
                      form={form1}
                      initialValues={{address: ip?.proposedIpv6}}
                      onFinish={submitSubnet}
                >
                    <Form.Item name='address'
                               label={t('ipSubnetPage.newSubnet.ipv4')}
                               rules={[
                                   {required: true, message: t('errors.field_required')},
                                   {validator: (_, value) => {
                                           if (ipRegex.v4(value)) {
                                               return Promise.resolve();
                                           } else {
                                               return Promise.reject('Validation IPv4 failed.');
                                           }
                                       }, message: t('errors.field_error')
                                   }
                               ]}>
                        <Input placeholder='123.123.123.123' />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type='primary' htmlType='submit' className='create-button'>
                            {t('ipSubnetPage.newSubnet.create_ipv4')}
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}

export default NewIpv4