import {
  UpdateCostAllocationRequestAction,
  UpdateCostAllocationResponseAction,
  UpdateCostAllocationErrorAction,
} from ".";
import { protectedApiClient } from "helpers/api";
import { handleApiErrorWithNotification } from "helpers/errorHandling";
import {
  UpdateCostAllocationParams,
  CostAllocationCategoryDetails,
} from "../models";

export default (
  params: UpdateCostAllocationParams,
  cb?: (isSuccess: boolean) => void
) => {
  console.log(params);
  return (
    dispatch: (
      arg:
        | UpdateCostAllocationRequestAction
        | UpdateCostAllocationResponseAction
        | UpdateCostAllocationErrorAction
    ) => void
  ) => {
    const request = new UpdateCostAllocationRequestAction(params);
    dispatch(request);

    protectedApiClient
      .put<CostAllocationCategoryDetails>(
        `/billing/cost-allocation-category/${params.id}`,
        params
      )
      .then((response) => {
        dispatch(
          new UpdateCostAllocationResponseAction(request, response.data)
        );
        cb && cb(true);
      })
      .catch((error) => {
        dispatch(new UpdateCostAllocationErrorAction(request, error));
        handleApiErrorWithNotification(error);
        cb && cb(false);
      });
  };
};
