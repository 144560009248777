import { protectedApiClient } from 'helpers/api';
import {handleApiError, handleApiErrorWithNotification} from 'helpers/errorHandling';

import {ChangePasswordErrorAction, ChangePasswordRequestAction, ChangePasswordResponseAction} from "./index";
import {Auth2faResponseData} from "../../login/models";
import {ChangePasswordParams} from "../models";

export default (params: ChangePasswordParams, cb?: (isSuccess: boolean) => void) => {
  return (
    dispatch: (
      arg:
        | ChangePasswordRequestAction
        | ChangePasswordResponseAction
        | ChangePasswordErrorAction
    ) => void
  ) => {
    const request = new ChangePasswordRequestAction(params);
    dispatch(request);

    protectedApiClient
      .post<Auth2faResponseData>('/user/change-password', params)
      .then((response) => {
        dispatch(new ChangePasswordResponseAction(request, response.data));
        cb && cb(true);
      })
      .catch((error) => {
        dispatch(new ChangePasswordErrorAction(request, error));
        handleApiErrorWithNotification(error);
        cb && cb(false);
      });
  };
};
