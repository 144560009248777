import {
    ChangePassword2faConfirmErrorAction,
    ChangePassword2faConfirmRequestAction,
    ChangePassword2faConfirmResponseAction
} from "./index";
import {apiClient} from "../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../helpers/errorHandling";
import {Params2FA} from "../../login/models";


export default (params: Params2FA, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | ChangePassword2faConfirmRequestAction
                | ChangePassword2faConfirmResponseAction
                | ChangePassword2faConfirmErrorAction,
        ) => void,
    ) => {
        const request = new ChangePassword2faConfirmRequestAction(params)
        dispatch(request)
        apiClient
            .put<string>(`/auth/confirm`, params)
            .then((response) => {
                dispatch(new ChangePassword2faConfirmResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new ChangePassword2faConfirmErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}

