import {
  GetPhysicalServerRequestAction,
  GetPhysicalServerResponseAction,
  GetPhysicalServerErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { PhysicalServerModel } from '../models'

export default (id: number, expand: string, cb?: (isSuccess: boolean) => void) => {
  return (
    dispatch: (
      arg:
        | GetPhysicalServerRequestAction
        | GetPhysicalServerResponseAction
        | GetPhysicalServerErrorAction,
    ) => void,
  ) => {
    const request = new GetPhysicalServerRequestAction(id)
    dispatch(request)

    let url = `/inventory/physical-server/${id}`
    if (expand) {
      url = `/inventory/physical-server/${id}?expand=${expand}`
    }

    protectedApiClient
      .get<PhysicalServerModel>(url)
      .then((response) => {
        dispatch(new GetPhysicalServerResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new GetPhysicalServerErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
