import {AutoComplete, Badge, Button, Card, Col, Form, message, Modal, Row, Space, Spin, Table} from "antd";
import {Link, RouteComponentProps, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import {AppState} from "../../common/models";
import queryString, {ParsedQuery} from "query-string";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../components";
import ErrorPage403 from "../../components/Errors/ErrorPage403";
import usePageSize from "../../common/usePageSize";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import getCompanies from "./actions/getCompanies";
import {_isNotEmptyObject, removeDiac, stopPropagation} from "../../common/fce";
import {CompanyDetails, CountryModel} from "./models";
import {sort_num, sort_searchable, sort_str} from "../../common/sorting";
import {ColumnsType} from "antd/es/table";
import Highlighter from "react-highlight-words";
import getCompany from "./actions/getCompany";
import {
    DeleteOutlined,
    DeleteTwoTone, EditOutlined,
    EditTwoTone,
    ExclamationCircleOutlined,
    IdcardOutlined,
    InfoCircleTwoTone
} from "@ant-design/icons";
import './CompaniesPage.scss'
import {PlusCircleOutlined} from "@ant-design/icons/lib/icons";
import Pager from "../../components/pagination/pager";
import lookupCompany from "./actions/lookupCompany";
import deleteCompany from "./actions/deleteCompany";
import HistoryModal from "../../components/History/HistoryModal";
import {IoIosBusiness} from "react-icons/io";
import CompanyForm from "./CompanyForm";
import clearCompany from "./actions/clearCompany";
import tableCustomers from "../login/actions/tableCustomers";
import {
    ROLE_ACCESS_MANAGER, ROLE_BILLING_EDITOR, ROLE_BILLING_VIEWER,
    ROLE_COMPANY_MANAGER,
    ROLE_CUSTOMER_MANAGER,
    ROLE_USER_MANAGER,
    ROLE_USER_ZCOM
} from "../../common/LoggedUser";


const { confirm } = Modal


const CompaniesPage = (props: RouteComponentProps) => {
    const CONTROL_NAME = 'page_billing_company'
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const {search} = useLocation()

    const {customers: tCustomers, companies: tCompanies} = useSelector((state: AppState) => state.auth.tables)
    const {isLoading, companies, company, pager, isLoadingLookup, lookup_number, lookup_company} = useSelector((state: AppState) => state.company)
    const {billing_companies} = useSelector((state: AppState) => state.sidenav)

    // data
    const [dataSource, setDataSource] = useState<CompanyDetails[]>([])
    const [searchableCompanies, setSearchableCompanies] = useState<CompanyDetails[]>([])
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [qsFilter, setQsFilter] = useState<string>('')
    const [customerCompanyIds, setCustomerCompanyIds] = useState<number[]>([])

    // options
    const [numberOptions, setNumberOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [companyOptions, setCompanyOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [countryOptions, setCountryOptions] = useState<{ label: string | ReactNode, value: string }[]>([])

    // filters
    const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
    const [iid, setIid] = useState(0)
    const [searchCompanyLookup, setSearchCompanyLookup] = useState<string>('')
    const [searchCompany, setSearchCompany] = useState<string>('')
    const [searchNumber, setSearchNumber] = useState<string>('')
    const [searchNumberLookup, setSearchNumberLookup] = useState<string>('')
    const [searchCountry, setSearchCountry] = useState<string>('')


    // page states
    const [ready, setReady] = useState<number>(0)
    const [isModalVisible, setModalVisible] = useState<boolean>(false)
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [openDelete, setOpenDelete] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    // permissions
    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)

    const existCustomer = (companyId: number): boolean => {
        return customerCompanyIds.includes(companyId)
    }

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('CompaniesPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('CompaniesPage: ' + msg)} }
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    // to handle SideNav menu pressed after 1sec
    const [triggerOneTimer, setTriggerOneTimer] = useState(0)

    useEffect(() => {
        // trigger ONCE
        setTriggerOneTimer(Date.now().valueOf())
        logger('=== ONCE  url='+search)
        setPageSize(appSetting.grid_page_size)

        const viewer = loggedUser.hasAccess('page_billing_company')
        setViewer(viewer)
        setCreator(loggedUser.hasAccess('page_billing_company_create_button'))
        setEditor(loggedUser.hasAccess('page_billing_company_edit_button'))
        setDeleter(loggedUser.hasAccess('page_billing_company_delete_button'))
        if (!viewer) {
            // go to dashboard
            message.error(t('general.error403'))
            history.replace('/')
            return
        }

        const hasBilling = loggedUser!.hasRole(ROLE_BILLING_VIEWER) || loggedUser!.hasRole(ROLE_BILLING_EDITOR)
        const hasCustomer = loggedUser!.hasRole(ROLE_CUSTOMER_MANAGER) || loggedUser!.hasRole(ROLE_ACCESS_MANAGER) || loggedUser!.hasRole(ROLE_USER_MANAGER)
        if ((hasCustomer || hasBilling) && tCustomers.length === 0) {
            dispatch(tableCustomers())
        }

        // load Options for refresh F5
        handleQueryStringFilters()
    }, [])

    useEffect(() => {
        // SideNav menu pressed
        const when = triggerOneTimer + 1000
        if (triggerOneTimer > 0 && when < Date.now().valueOf()) {
            logger('--- SideNav menu pressed')
            clearFilters()
            fetchRecords(1, pageSize)
        }
    }, [billing_companies])

    useEffect(() => {
        // check if Ready
        if (tCustomers.length) {
            setCustomerCompanyIds(tCustomers.map(c => c.id))
        }
        logger('===tCompanies='+tCompanies.length)
        // options are loaded
        const duplCountries: string[] = tCompanies.map(co => co.country)
        const uniqCountries: string[] = [...new Set(duplCountries)].filter(c => c.length > 1).sort(sort_str);
        setCountryOptions(uniqCountries.map(c => ({label: c, value: c})))
        setReady(ready+1)
    }, [tCustomers, tCompanies])

    useEffect(() => {
        // populate companyOptions
        setCompanyOptions(lookup_company.map(s => ({label: s, value: s})))
    }, [lookup_company])

    useEffect(() => {
        // populate numberOptions
        setNumberOptions(lookup_number.map(s => ({label: s, value: s})))
    }, [lookup_number])

    useEffect(() => {
        // process Options
        if (ready) {
            logger(`Ready=${ready} iid=${iid}`)
            //setCompanyOptions(companies.sort(sort_searchable).map(t => ({label: t.name, value: t.id})))
            //setLocationOptions(locations.map(t => ({label: t.name, value: t.id})).sort(sort_label))
            fetchRecords(pageNumber, pageSize)
        }
    }, [ready])

    useEffect(() => {
        replaceHistory()
    }, [pageSize, pageNumber, qsFilter])

    useEffect(() => {
        // update QsFilter
        if (!isModalVisible) {
            const qs: string = prepareQsFilter()
            logger(`qsFilter:${qs}`)
            if (qs != qsFilter) {
                setQsFilter(qs)
            }
        }
    }, [searchNumber, searchCompany, searchCountry])

    useEffect(() => {
        // when filter is changed
        // show the first page
        logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
        let pn = pageNumber
        if (ready) {    // do not change page for F5
            pn = 1
        }
        fetchRecords(pn, pageSize)
    }, [qsFilter])

    useEffect(() => {
        // process new server-data
        if (!ready) {
            return
        }
        if (companies.items && filterIsValid()) {
            setSearchableCompanies(companies.items.map(item => {
                return {
                    ...item,
                    searchableName: removeDiac(item.name)
                }
            }))
        }
    }, [companies.items])

    useEffect(() => {
        // render data
        if (!ready) {
            return
        }
        if (filterIsValid()) {
            refreshGrid()
        }
    }, [searchableCompanies])



    // ------------------------------ end hooks

    const fetchRecords = (pn: number, ps: number) => {
        if (!isViewer) {
            return
        }
        setPageNumber(pn)
        setPageSize(ps)
        if (ready && filterIsValid()) {
            // purchase_from is required
            logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
            dispatch(getCompanies(ps, pn - 1, qsFilter, suc => {}))
        }
    }

    const refreshGrid = () => {
        logger('refreshGrid '+iid)
        setDataSource(searchableCompanies.sort((a, b) => sort_str(b.name, a.name)))
    }

    const replaceHistory = () => {
        if (filterIsValid()) {
            const url = `/billing/companies?pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}&` + qsFilter
            history.replace(url)
        }
    }

    const checkMinSearch = (val: string | undefined) => {
        if (!val) {
            return false
        }
        return val.length > appSetting.min_search_length
    }

    const filterIsValid = (): boolean => {
        // no required fields
        return true
    }

    const getQSFilter = (): string[] => {
        return []
    }

    const prepareQsFilter = (): string => {
        if (iid > 0) {
            let qs: string[] = []
            // inv_no is uniq
            qs.push('id='+iid)
            return qs.join("&")
        }
        // load filtered data from server
        let qs: string[]
        qs = getQSFilter()
        if (checkMinSearch(searchCompany)) {
            qs.push('name=' + encodeURIComponent(searchCompany))
        }
        if (checkMinSearch(searchNumber)) {
            qs.push('company_number=' + encodeURIComponent(searchNumber))
        }
        if (searchCountry && searchCountry.length > 1) {
            qs.push('country=' + encodeURIComponent(searchCountry))
        }
        logger('prepareQsFilter: '+qs.join("&"))
        return qs.join("&")
    }


    const clearFilters = () => {
        // used when clicked on left menu
        logger('clearFilters')
        setIid(0)
        onClearNumber()
        onClearCompany()
    }

    const onClearCompany = () => {
        setSearchCompanyLookup('')
        setSearchCompany('')
        setCompanyOptions([])
    }

    const onSelectCompany = (data: string) => {
        setSearchCompany(data)
    }

    const onChangeCompanyLookup = (data: string) => {
        if (!data) {
            if (searchCompanyLookup.length === 1) {
                setSearchCompanyLookup('')
                fetchCompanyLookup('')
            }
            return
        }
        if (data != searchCompanyLookup){
            setSearchCompanyLookup(data)
            fetchCompanyLookup(data)
        }
    }

    const fetchCompanyLookup = (searchText: string) => {
        // call lookup for company_name
        if (checkMinSearch(searchText)) {
            let qs: string[]
            qs = getQSFilter()
            if (checkMinSearch(searchNumber)) {
                qs.push('company_number=' + encodeURIComponent(searchNumber))
            }
            if (checkMinSearch(searchText)) {
                qs.push('field=name')
                qs.push('value=' + encodeURIComponent(searchText))
                logger('lookupCompany: ' + qs.join("&"))
                dispatch(lookupCompany('name', qs.join("&")))
            }
        }
    }

    const onClearNumber = () => {
        setSearchNumberLookup('')
        setSearchNumber('')
        setNumberOptions([])
    }

    const onChangeNumberLookup = (data: string) => {
        if (!data) {
            if (searchNumberLookup.length === 1) {
                setSearchNumberLookup('')
                fetchNumberLookup('')
            }
            return
        }
        if (data != searchNumberLookup){
            setSearchNumberLookup(data)
            fetchNumberLookup(data)
        }
    }

    const fetchNumberLookup = (searchText: string) => {
        // call lookup serial
        if (checkMinSearch(searchText)) {
            let qs: string[]
            qs = getQSFilter()
            if (checkMinSearch(searchCompany)) {
                qs.push('name=' + encodeURIComponent(searchCompany))
            }
            if (checkMinSearch(searchText)) {
                qs.push('field=company_number')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupCompany: '+qs.join("&"))
                dispatch(lookupCompany('company_number', qs.join("&")))
            }
        }
    }

    const onSelectNumber = (data: string) => {
        setSearchNumber(data)
    }

    const handleQueryStringFilters = () => {
        // parse query string
        // and set filters
        // /billing/bills?filter_id=6&number=122&from=2022-06-01&to=2022-08-10
        if (parsed && _isNotEmptyObject(parsed)) {
            logger(`handleQueryStringFilters..`)
            const qs_id: string | string[] | null = parsed['id']
            if (qs_id && typeof qs_id === 'string') {
                // call by ID
                const id = parseInt(qs_id)
                if (id > 0) {
                    setIid(id)
                    return
                }
            }

            // iid=0
            const qs_name: string | string[] | null = parsed['name']
            if (qs_name && typeof qs_name === 'string') {
                setSearchCompany(qs_name)
            }
            const qs_company_number: string | string[] | null = parsed['company_number']
            if (qs_company_number && typeof qs_company_number === 'string') {
                setSearchNumber(qs_company_number)
            }
            const qs_size: string | string[] | null = parsed['pagination[pageSize]']
            if (qs_size && typeof qs_size === 'string') {
                const ps = parseInt(qs_size)
                if (ps > 0) {
                    setPageSize(ps)
                }
            }
            const qs_num: string | string[] | null = parsed['pagination[page]']
            if (qs_num && typeof qs_num === 'string') {
                const pn = parseInt(qs_num)
                setPageNumber(pn)
            }
        }
    }

    const showConfirmDelete = (c: CompanyDetails, title: string) => {
        if (!c) {
            return
        }

        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{c.name}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteCompany(c.id,suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const FilterByCompany = (
        <AutoComplete
            showSearch
            value={searchCompanyLookup}
            options={companyOptions}
            style={{ width: 240 }}
            dropdownMatchSelectWidth={400}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectCompany(e.currentTarget.value)
                }
            }}
            onSelect={onSelectCompany}
            onSearch={onChangeCompanyLookup}
            onChange={onChangeCompanyLookup}
            onClear={onClearCompany}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={false}
            optionFilterProp='label'
            placeholder={t('companiesPage.name')}
            allowClear={true}
        />
    )

    const FilterByNumber = (
        <AutoComplete
            showSearch
            value={searchNumberLookup}
            options={numberOptions}
            style={{ width: 120 }}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectNumber(e.currentTarget.value)
                }
            }}
            onSelect={onSelectNumber}
            onSearch={onChangeNumberLookup}
            onChange={onChangeNumberLookup}
            onClear={onClearNumber}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={false}
            optionFilterProp='label'
            placeholder={t('billing.bill.number')}
            allowClear={true}
        />
    )

    const FilterByCountry = (
        <AutoComplete
            placeholder={t('companiesPage.country')}
            options={countryOptions}
            style={{width: 60}}
            value={searchCountry}
            allowClear
            optionFilterProp='label'
            onChange={(value) => {
                setSearchCountry(value)
            }}
        />
    )

    const columns: ColumnsType<CompanyDetails> = [
        {
            title: FilterByCompany,
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: '30%',
            sorter: (a, b) => sort_searchable(a, b),
            render: (text: string) => (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        color: '#2d9259',
                        padding: 0,
                    }}
                    searchWords={[searchCompany]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ),
        },
        {
            title: FilterByNumber,
            dataIndex: 'company_number',
            key: 'company_number',
            width: 180,
            sorter: (a, b) => sort_str(a.company_number, b.company_number),
            render: (text: string) => (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        color: '#2d9259',
                        padding: 0,
                    }}
                    searchWords={[searchNumber]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ),
        },
        {
            title: FilterByCountry,
            dataIndex: 'country',
            key: 'country',
            width: 100
        },
        {
            title: t('companiesPage.email'),
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => sort_str(a.email, b.email),
        },
        {
            title: t('companiesPage.vat_payer'),
            dataIndex: 'vat_payer',
            key: 'vat_payer',
            className: 'centered',
            width: 60,
            render: (record: number) =>
                record === 1 ? <Badge status='success' /> : <Badge status='error' />,
            sorter: (a, b) => sort_num(a.vat_payer, b.vat_payer),
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            className: 'centered',
            width: '140px',
            render: (_: string, record: CompanyDetails) => (
                <Space size='small'>
                    {
                        isEditor && (
                            <Button type='text' size='small' className='actionButton'
                                    onClick={() => {
                                        dispatch(getCompany(record.id))	// load fresh record
                                        setModalVisible(true)
                                    }}
                                    icon={<EditTwoTone twoToneColor='green' />}
                            />
                        )
                    }
                    {
                        !isEditor && (
                            <Button type='text' size='small' className='actionButton'
                                    icon={<EditOutlined style={{color: 'lightgray'}} />}
                            />
                        )
                    }
                    {
                        isDeleter && (
                            <Button type='text' danger size='small'
                                    className='actionButton'
                                    disabled={!!record.deleted_at}
                                    onClick={() => showConfirmDelete(record, t('companiesPage.confirm_delete'))}
                                    icon={<DeleteTwoTone twoToneColor='red'/>} />
                        )
                    }
                    {
                        !isDeleter && (
                            <Button type='text' size='small' className='actionButton'
                                    icon={<DeleteOutlined style={{color: 'lightgray'}} />}
                            />
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(record.id)
                                setHistoryTitle(record.name)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                </Space>
            ),
        }]

    const expandedRowRender = (record: CompanyDetails) => {
        return (
            <Row className='companyDetail'>
                <Col span='12'>
                    <Form>
                        <Form.Item label={t('companiesPage.address')}>
                            <span>{record.street + ' ' + record.city + ' ' + record.region + ' ' + record.zip + ' ' + record.country}</span>
                        </Form.Item>
                        <Form.Item label={t('companiesPage.city')}>
                            <span>{record.city}</span>
                        </Form.Item>
                        <Form.Item label={t('companiesPage.zip')}>
                            <span>{record.zip}</span>
                        </Form.Item>
                        <Form.Item label={t('companiesPage.region')}>
                            <span>{record.region}</span>
                        </Form.Item>
                        <Form.Item label={t('companiesPage.country')}>
                            <span>{record.country}</span>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span='12'>
                    <Form>
                        <Form.Item label={t('companiesPage.file_number')}>
                            <span>{record.file_number}</span>
                        </Form.Item>
                        <Form.Item label={t('companiesPage.note')}>
                            <span>{record.note}</span>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        )
    }


    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    if (!ready) {
        return (<Spin/>)
    }

    return (
        <>
            <Card className='billsPage'
                  title={
                      <Row>
                          <Col span={12}>
                              <IdcardOutlined /> &nbsp; {t('companiesPage.title')}
                          </Col>
                          <Col span={12}>&nbsp;</Col>
                      </Row>
                  }
                  extra={
                      <>
                          {
                              isCreator && (
                                  <Button type='primary'
                                          disabled={!isCreator}
                                          onClick={() => {
                                              dispatch(clearCompany())	// clear record
                                              setModalVisible(true)
                                          }}>
                                      <PlusCircleOutlined/> {t('companiesPage.create_title')}{' '}
                                  </Button>
                              )
                          }
                          {
                              !isCreator && (
                                  <Button type='primary' disabled={true}>
                                      <PlusCircleOutlined/> {t('companiesPage.create_title')}
                                  </Button>
                              )
                          }
                      </>
                  }
            >

                <Table<CompanyDetails>
                    className='CompaniesTable'
                    showHeader={true}
                    size='small'
                    bordered={true}
                    columns={columns}
                    scroll={{ x: 680 }}
                    dataSource={dataSource}
                    expandable={{expandedRowRender}}
                    loading={isLoading}
                    rowKey='id'
                    pagination={false}
                    footer={() => Pager({
                        filename: 'fn',
                        total: pager.totalCount,
                        current: pager.page,
                        pageSize: pager.pageSize,
                        data: dataSource,
                        fetchRecords: fetchRecords
                    })}
                    onChange={(ev) => {
                        ev.pageSize && setPageSize(ev.pageSize)
                    }}
                />
            </Card>

            <Modal
                destroyOnClose
                style={{top: 20}}
                width={600}
                title={
                    <>
                        <IoIosBusiness/> &nbsp;
                        {company && company.id ? t('companiesPage.update-title') : t('companiesPage.create-title')}
                    </>
                }
                visible={isModalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                maskClosable={false}
                confirmLoading={true}>
                <CompanyForm
                    setModalVisible={setModalVisible}
                    existCustomer={existCustomer}
                />
            </Modal>

            <HistoryModal service='company' model='Company'
                          modelId={historyModelId}
                          title={historyTitle}
                          isModalVisible={isHistoryModalVisible}
                          setModalVisible={() => setHistoryModalVisible(false)}
                          modalRender={(modal) => (
                              <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                  <div ref={draggleRef}>{modal}</div>
                              </Draggable>
                          )}
            />
        </>
    )






}

export default CompaniesPage