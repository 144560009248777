import AppSetting from "./AppSettings";


const useLogger = (appSetting: AppSetting, name: string) => {

    // logger
    return (msg, obj:any=null) => {
        if (appSetting && appSetting.debug) {
            obj ? console.log(name + ': ' + msg + ' > ' + JSON.stringify(obj)) : console.log(name + ': ' + msg)
        }
    }
};

export default useLogger;