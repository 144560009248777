import {ActionType, HistoryActions} from './actions'
import {AsyncActionMode} from 'common/models'
import {HistoryState} from './models'
import {LogRecord} from "../../pages/logviewer/models";
import {getZisLevelValue, ZIS_LEVEL_ENUM} from "../../common/enums";

const HISTORY_INITIAL_STATE: HistoryState = {
	historyLog: [],
	indexHistory: {},
	isLoading: false,
	isLoadingDetails: false,
	records: [],
	entries: []
}

const getParents = (data: LogRecord[]) => {
	// add user_id for NOTICE requests
	let notices = data.filter((rec) => rec.zis_level === getZisLevelValue(ZIS_LEVEL_ENUM.NOTICE))
	for (const rec of notices) {
		let user_rec = data.find(r => r.zis_request_id === rec.zis_request_id && r.zis_user_id > 0)
		rec.zis_user_id = user_rec ? user_rec.zis_user_id : 0
		rec.username = user_rec ? user_rec.username : 'guest'
	}
	return notices
}

export default (state = HISTORY_INITIAL_STATE, action: HistoryActions): HistoryState => {
	switch (action.type) {
		case ActionType.GET_HISTORY:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state,
					historyLog: [],
					isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					historyLog: action.data,
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_HISTORY2:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state,
					isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {

				if (action.data && action.data.length) {
					const h1 = action.data[0]
					const parentId = h1.parent_id.toString()
					let oldHistory = state.indexHistory
					oldHistory[parentId] = action.data.map((h) => {return {...h, key: h.id}})
					return {
						...state,
						indexHistory: oldHistory,
						isLoading: false
					}
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_HISTORY_LOGS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state,
					records: [],
					// entries: [],
					isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					entries: action.data,
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_HISTORY_DETAILS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state,
					isLoadingDetails: true}
			}

			const mergeById = (oldData: LogRecord[], newData: LogRecord[]): LogRecord[] => {
				for (const newItem of newData) {
					const existItem = oldData.find(item => item.id === newItem.id)
					if (!existItem) {
						// newItem is not in oldData
						oldData.push(newItem)
					}
				}

				return oldData
			}

			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					records: mergeById(state.records, action.data),
					isLoadingDetails: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingDetails: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_HISTORY_VPSADMIN:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state,
					records: [],
					entries: [],
					isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					entries: action.data,
					// entries: getParents(action.data),
					isLoading: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
	}
	return state
}
