import React, { useState, useEffect } from 'react'
import { formItemLayout, tailLayout } from 'helpers/layoutHelpers'
import { useForm } from 'antd/lib/form/Form'
import { Form, Input, Switch, Button, Select, message, Spin } from 'antd'


import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'common/models'

import { CostAllocationCategoryDetails, typeCategory } from '../models'

import getCostAllocation from '../actions/getCostAllocation'
import updateCostAllocation from '../actions/updateCostAllocation'
import setDefaultCostAllocationCategory from '../actions/setDefaultCostAllocationCategory'
import createCostAllocation from '../actions/createCostAllocation'


interface Props {
    setModalVisible: (isShow: boolean) => void
    updateId: number
}

const emptyAresData = {
    id: 0,
    name: '',
    typ: '',
}



const { Item } = Form


const CostAllocationCategoryForm = ({ setModalVisible, updateId }: Props) => {
    const { t } = useTranslation()
    const [form] = useForm()

    const dispatch = useDispatch()
    const { fontSize } = useSelector((state: AppState) => state.font)
    const { costAllocationCategories, costAllocationCategory } = useSelector((state: AppState) => state.costAllocationCategory)
    const [updateItem, setUpdateItem] = useState<CostAllocationCategoryDetails>(emptyAresData)
    const [dataToUpdate, setDataToUpdate] = useState<CostAllocationCategoryDetails>(emptyAresData)


    const { Option } = Select

    useEffect(() => {
        setDataToUpdate(dataToUpdate)
        setUpdateItem(emptyAresData)

        if (updateId) {
            // try call local DB
            const existingItem = costAllocationCategories.find(item => item.id === updateId)
            if (existingItem) {
                dispatch(getCostAllocation(existingItem.id!))
            }
        }
    }, [])

    useEffect(() => {

        if (costAllocationCategory) {
            const type = costAllocationCategory.typ!
            setUpdateItem({ ...costAllocationCategory, typ: typeCategory[type] })
        }

    }, [costAllocationCategory])


    useEffect(() => {
        // update values to form
        form.setFieldsValue(updateItem)
    }, [updateItem])

    const onFinish = (values) => {

        if (updateItem.id !== 0) {

            dispatch(updateCostAllocation(
                {
                    ...values, id: updateItem.id, typ: typeCategory.indexOf(values.typ)
                },
                (suc: boolean) => {
                    if (suc) {
                        message.success(t('general.success'))
                        dispatch(setDefaultCostAllocationCategory())
                        setModalVisible(false)
                    }
                },
            ))

        } else {
            dispatch(createCostAllocation(
                {
                    ...values, typ: typeCategory.indexOf(values.typ)
                },
                (isSuccess: boolean) => {
                    if (isSuccess) {
                        message.success(
                            <span
                                dangerouslySetInnerHTML={{ __html: t('costAllocationPage.create_title', { name: values.name }) }} />,
                        )
                        dispatch(setDefaultCostAllocationCategory())
                        setModalVisible(false)
                    }
                },
            ))
        }

    }

    return (
        <>
            <Form
                form={form}
                {...formItemLayout}
                name='create-cost-allocation-category'
                className=''
                onFinish={onFinish}
                initialValues={{ ...updateItem }}
            >
                <Item name='name'
                    rules={[{ required: true, message: t('errors.field_required') }]}
                    label={t('costAllocationPage.name')}
                    hasFeedback>
                    <Input size={fontSize} />
                </Item>
                <Item name='typ'
                    rules={[{ required: true, message: t('errors.field_required') }]}
                    label={t('costAllocationPage.typ')}
                    hasFeedback>
                    <Select style={{ width: 200 }}
                        disabled={(updateItem && updateItem.id !== 0) ? true : false}

                    >
                        {
                            typeCategory!.map((value, index) => {
                                return <Option value={value} key={index}>{value}</Option>
                            })
                        }
                    </Select>
                </Item>



                <Item {...tailLayout}>
                    <Button type='primary' htmlType='submit'
                        className='login-form-button'
                        size={fontSize}>
                        {(updateItem && updateItem.id) ? t('costAllocationPage.update_title') : t('costAllocationPage.create_title')}
                    </Button>
                </Item>
            </Form>
        </>

    )
}

export default CostAllocationCategoryForm
