import {Button, Col, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import './SelectCompany.css'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import selectCustomer from "../../pages/login/actions/selectCustomer";
import getSettingsSelf from "../../pages/login/actions/getSettingsSelf";
import {useHistory} from "react-router-dom";
import getCustomerSelf from "../../pages/login/actions/getCustomerSelf";
import getSelf from "../../pages/login/actions/getSelf";
import moment from "moment";
import LoggedUser from "../../common/LoggedUser";
import logoutAsUser from "../../pages/login/actions/logoutAsUser";


const SelectCompany = () => {
    const {customers: tCustomers} = useSelector((state: AppState) => state.auth.tables)
    const {self, self_customer} = useSelector((state: AppState) => state.auth)
    const {auth} = useSelector((state: AppState) => state)
    const [custOptions, setCustOptions] = useState<{ label: string, value: number }[]>([])
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (self) {
            if (self.validCustomers && self.validCustomers.length) {
                const items = self.validCustomers.map(c => ({label: '123', value: c.id}))
                setCustOptions(items)
            }
        }
    }, [self])

    const onExit = () => {
        if (auth.token) {
            dispatch(logoutAsUser({token: auth.token}, suc => {
                setCustOptions([])
                dispatch(getSelf('validCustomers,roles,resourceGroups,groups'))
                dispatch(getSettingsSelf())
                dispatch(getCustomerSelf('company'))
                history.push('/')
            }))
        }
    }

    const getCustomerIdent = (cid: number | undefined): string => {
        if (!cid) {
            return ''
        }
        const cust = tCustomers.find(c => c.id === cid)
        return (cust && cust.company!.name) ? cust.company!.name : '??'
    }

    const getSelectedCustomer = (cid) => {
        dispatch(selectCustomer(isSuccess => {
            if (isSuccess) {
                // reload a different customer
                dispatch(getSelf('validCustomers,roles,resourceGroups,groups'))
                dispatch(getSettingsSelf())
                dispatch(getCustomerSelf('company'))
                history.push('/')
            }
        }, cid))
    }

    if (self && self_customer) {
        const loggedUser = new LoggedUser(auth)
        if (custOptions && custOptions.length) {
            return (
                <>
                    {
                        !loggedUser.isOriginUser() &&
                        <Button type="primary" danger size='small' onClick={onExit}>Exit</Button>
                    }
                    <Select
                        className={loggedUser.isOriginUser() ? 'select-company-width' : 'select-company-loginAs-width'}
                        dropdownClassName='selector-dropdown'
                        optionFilterProp='label'
                        value={loggedUser.isOriginUser() ? self_customer.company?.name : 'login as ' + loggedUser.user.title}
                        options={custOptions}
                        showArrow={false}
                        disabled={custOptions && custOptions.length === 1}
                        onChange={(id) => getSelectedCustomer(id)}
                    >
                    </Select>
                </>

            )
        }
        else {
            return (
                <Row>
                    <Col span={24}>
                        {
                            !loggedUser.isOriginUser() &&
                            <><Button type="primary" danger size='small' onClick={onExit}>Exit</Button>&nbsp;</>
                        }
                        <span className={loggedUser.isOriginUser() ? 'companyName' : 'companyName-loginAs'} >
                            {loggedUser.isOriginUser() ? self_customer.company!.name : 'login as @' + loggedUser.user.username}&nbsp;
                        </span>
                    </Col>
                </Row>
            )
        }
    }
    return (<span>&nbsp;</span>)
}

export default SelectCompany
