import {
    ScanVpsSettingRequestAction,
    ScanVpsSettingResponseAction,
    ScanVpsSettingErrorAction
} from '.'

import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: ScanVpsSettingRequestAction | ScanVpsSettingResponseAction | ScanVpsSettingErrorAction,
        ) => void,
    ) => {
        const request = new ScanVpsSettingRequestAction(id)
        dispatch(request)
        protectedApiClient
            .post(`/vpsadmin/setting/${id}/scan`)
            .then((response) => {
                dispatch(new ScanVpsSettingResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new ScanVpsSettingErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}

