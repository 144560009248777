import {UserRightsPageState} from "./models";
import {AsyncActionMode} from "../../../common/models";
import {UserRightsActions} from "./actions";
import {ActionType} from "./actions";


const USER_RIGHTS_PAGE_INITIAL_STATE: UserRightsPageState = {
    isLoadingUser: false,
    isLoadingGroup: false,
    isLoadingGroupAll: false,
    isLoadingRole: false,
    isLoadingRes: false,
    isSaving: false,
    users: [],
    groups: [],
    groupsAll: [],
    roles: [],
    resGroups: [],
    error: undefined
}

export default (
    state = USER_RIGHTS_PAGE_INITIAL_STATE,
    action: UserRightsActions,
): UserRightsPageState => {
    switch (action.type) {
        case ActionType.GET_USER_WITH_GROUPS:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoadingGroup: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoadingGroup: false,
                    user: action.data,
                    groups: action.data.groups,
                    roles: action.data.roles,
                    resGroups: action.data.resourceGroups
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingGroup: false,
                    error: action.error
                };
            }
            break;
        case ActionType.GET_GROUPS_ALL:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoadingGroupAll: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoadingGroupAll: false,
                    groupsAll: action.data,
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingGroupAll: false,
                    error: action.error
                };
            }
            break;
        case ActionType.GET_USERS_RIGHTS:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoadingUser: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    users: action.data,
                    isLoadingUser: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingUser: false,
                    error: action.error,
                }
            }
            break
        case ActionType.ADD_USER_TO_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isSaving: false,
                    groups: state.groups.concat(action.data)
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error
                };
            }
            break;
        case ActionType.REMOVE_USER_FROM_GROUP:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true };
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isSaving: false,
                    groups: state.groups.filter(u => u.id != action.data.id) ,
                };
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error
                };
            }
            break;
    }

    return { ...state}
}