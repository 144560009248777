import {SelectCustomer, UserDetails} from 'pages/user/models'
import {CustomerDetails} from "../billing/customer/models"
import {SettingsDetails} from "../settings/models"
import {CompanyDetails} from "../company/models";
import {TaxDetails} from "../billing/tax/models";
import {Country} from "../billing/countries/models";

export interface IPermission {
  element: string
  roles: string[]
}

export interface AuthState {
  authenticated: boolean
  isLoading: boolean
  isSaving: boolean
  error?: string | {}
  token?: string | null
  reason?: string
  status: number
  methods: Method2FA[]
  message?: string
  self?: UserDetails
  self_customer?: CustomerDetails
  self_settings: SettingsDetails[]
  setting?: SettingsDetails
  menu_roles?: {}
  select_customer?: SelectCustomer
  last_update?: number
  origin_user?: UserDetails
  origin_token?: string
  buffer: string[]
  right_sidebar?: RightSiderContent
  docs_content?: string
  tables: GlobalTables
}

export interface GlobalTables {
  companies: CompanyDetails[]
  customers: CustomerDetails[]
  companyNames?: Map<number, string>
  customerNames?: Map<number, string>
  countries: Country[]
  currencies: string[]
  taxes: TaxDetails[]
}

export const GTABLE1 = {
  companies: [],
  customers: [],
  companyNames: undefined,
  customerNames: undefined,
  countries: [],
  currencies: [],
  taxes: []
}


export interface Method2FA {
  method: string
  request: string
  token?: string
  is_ident_verified?: number
  image?: string
}

export interface Params2FA {
  request: string,
  confirm: string
}

export interface ParamsSendCode {
  request: string
  ident?: string
}

export interface LoginParams {
  username: string
  password: string
}

export interface SetPasswordParams {
  password: string
  password_repeat: string
}

export interface Auth2faResponseData {
  token?: string
  reason?: string
  methods?: Method2FA[]
  error?: string
}

export interface SendCodeResponseData {
  message: string
}

export interface LoginAsParams {
  user_id: number
}

export interface LogoutAsParams {
  token: string
}

export interface RightSiderContent {
  title: string
  body: React.ReactNode
  footer?: string
  path: string
  show: boolean
}

export interface HelperDocument {
  id?: number
  url: string
  tag: string
  content: string
}