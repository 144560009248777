import {
    GetLogsScanningResponseAction,
    GetLogsScanningErrorAction,
    GetLogsScanningRequestAction
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {LogRecord} from "../../../pages/logviewer/models";


export default (id, last_days, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetLogsScanningRequestAction | GetLogsScanningResponseAction | GetLogsScanningErrorAction,
        ) => void,
    ) => {
        const request = new GetLogsScanningRequestAction(id, last_days)
        dispatch(request)
        protectedApiClient
            .get<LogRecord[]>(`/vpsadmin/setting/${id}/log?last_days=${last_days}`)
            .then((response) => {
                dispatch(new GetLogsScanningResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetLogsScanningErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
