import { GenerateCopyRequestAction, GenerateCopyResponseAction, GenerateCopyErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ProductPriceDetails, GenerateMakeCopyParams } from '../models'


export default (params: GenerateMakeCopyParams, cb?: (isSuccess: boolean, id: number | undefined) => void) => {
    return (dispatch: (args: GenerateCopyRequestAction | GenerateCopyResponseAction | GenerateCopyErrorAction) => void) => {
        const request = new GenerateCopyRequestAction(params)
        dispatch(request)

        protectedApiClient.post<ProductPriceDetails>(`/billing/pricelist/${params.id}/copy`, params)
            .then(response => {
                dispatch(new GenerateCopyResponseAction(request, response.data))
                cb && cb(true, response.data.id)
            })
            .catch(error => {
                dispatch(new GenerateCopyErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false, undefined)
            })

    }
}
