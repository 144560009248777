import {
    LookupInventoryRequestAction,
    LookupInventoryResponseAction,
    LookupInventoryErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'


export default (field: string, qs: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: LookupInventoryRequestAction | LookupInventoryResponseAction | LookupInventoryErrorAction,
        ) => void,
    ) => {
        const request = new LookupInventoryRequestAction(field)
        dispatch(request)

        protectedApiClient
            .get<string[]>(`/inventory/inventory/lookup?`+qs)
            .then((response) => {
                dispatch(new LookupInventoryResponseAction(request, field, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new LookupInventoryErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
