import {
  CreateBillCostAllocationRequestAction,
  CreateBillCostAllocationResponseAction,
  CreateBillCostAllocationErrorAction,
} from './index'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { BillCostAllocationModel } from '../models'

export default (
  params: BillCostAllocationModel,
  cb?: (isSuccess: boolean) => void,
) => {
  return (
    dispatch: (
      arg:
        | CreateBillCostAllocationRequestAction
        | CreateBillCostAllocationResponseAction
        | CreateBillCostAllocationErrorAction,
    ) => void,
  ) => {
    const request = new CreateBillCostAllocationRequestAction(params)
    dispatch(request)
    protectedApiClient
      .post<BillCostAllocationModel>(
        `/billing/bill/${params.bill_id}/bill-cost-allocation`,
        params,
      )
      .then((response) => {
        dispatch(
          new CreateBillCostAllocationResponseAction(request, response.data),
        )
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new CreateBillCostAllocationErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
