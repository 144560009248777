import {
    DeleteMailTemplateErrorAction,
    DeleteMailTemplateRequestAction,
    DeleteMailTemplateResponseAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | DeleteMailTemplateRequestAction
                | DeleteMailTemplateResponseAction
                | DeleteMailTemplateErrorAction,
        ) => void,
    ) => {
        const request = new DeleteMailTemplateRequestAction(id)
        dispatch(request)

        protectedApiClient
            .delete(`/mail-template/${id}`)
            .then((response) => {
                dispatch(new DeleteMailTemplateResponseAction(request, id))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteMailTemplateErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
