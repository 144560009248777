import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "antd/lib/form/Form";
import {AppState} from "../../../common/models";
import React, {useEffect, useState} from "react";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {Button, Col, Form, Input, message, Row, Select, Spin} from "antd";
import {sort_label} from "../../../common/sorting";
import {Store} from "antd/lib/form/interface";
import ipRegex from "ip-regex";
import {CreateIpParams} from "./models";
import createIP from "./actions/createIP";
import {tailLayout} from "../../../helpers/layoutHelpers";
import TextArea from "antd/lib/input/TextArea";
import tableCustomers from "../../login/actions/tableCustomers";
import {ZCOM_CUSTOMER_ID} from "../../../common/fce";


interface Props {
    onClose: () => void
}

const NewExtIpForm = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form2] = useForm()

    const {customers} = useSelector((state: AppState) => state.auth.tables)
    const {subnet, isSaving} = useSelector((state: AppState) => state.ipSubnet)

    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        if(customers && customers.length) {
            const data = customers.map((c) => {return {
                value: c.id,
                key: c.id,
                label: c.company!.name
            }})
            setCustomerOptions(data.sort(sort_label))
        }
        else {
            dispatch(tableCustomers())
        }
    }, [])

    useEffect(() => {
        setSelectedCustomerId(loggedUser.customer.id)
    }, [dispatch, customers])

    const isPIv6 = (address: string) => {
        return ipRegex.v6({exact: true}).test(address)
    }

    const submitIP = (values: Store): void => {
        const sid = isPIv6(values.address) ? 2 : 1
        const params: CreateIpParams = {
            address: values.address,
            note: values.note,
            reverse_domain: values.reverse_domain,
            customer_id: selectedCustomerId ? selectedCustomerId : loggedUser.customer.id,
            subnet_id: sid
        }
        dispatch(createIP(params, suc => {
            suc && message.success(t('general.success'))
            props.onClose()
        }))
    }

    return (
        <>
            <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '25px'}}>
                <Col span={24} >

                    <Form name='create-subnet'
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          layout="horizontal"
                          form={form2}
                          initialValues={{customer_id:ZCOM_CUSTOMER_ID}}
                          onFinish={submitIP}
                    >
                        <Form.Item name='customer_id' label={t('ipSubnetPage.newSubnet.customer')}>
                            <Select optionFilterProp='label'
                                    style={{width: '100%'}}
                                    options={customerOptions}
                                    onChange={ (value: number) => {
                                        // setSelectedCustomerId(value)
                                    }}
                                    disabled={true}
                            />
                        </Form.Item>

                        <Form.Item name='address' label={t('ipSubnetPage.newSubnet.address')}
                                   rules={[
                                       {required: true, message: t('errors.field_required')},
                                       {validator: (_, value) => {
                                               if (ipRegex.v4({exact: true}).test(value) || ipRegex.v6({exact: true}).test(value)) {
                                                   return Promise.resolve();

                                               } else {
                                                   return Promise.reject('Validation IP failed.');
                                               }
                                           }, message: t('errors.field_error')
                                       }
                                   ]}>
                            <Input placeholder={subnet?.id === 2 ? '2A0A:6580:0013:B9B2:ACCA' : '185.172.35.1'} />
                        </Form.Item>

                        <Form.Item name='reverse_domain' label={t('ipSubnetPage.newSubnet.reverse')}>
                            <Input placeholder='reverse.record.com' />
                        </Form.Item>

                        <Form.Item name='note' label={t('ipSubnetPage.newSubnet.note')}>
                            <TextArea placeholder={t('ipSubnetPage.newSubnet.note')}
                                      data-gramm="false"
                                      data-gramm_editor="false"
                                      data-enable-grammarly="false" />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type='primary' htmlType='submit'
                                    loading={isSaving}
                                    className='create-button'>
                                {t('ipSubnetPage.newSubnet.create_ip')}
                            </Button>
                        </Form.Item>
                    </Form>

                </Col>
            </Row>
        </>
    )

}

export default NewExtIpForm
