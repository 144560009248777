import React, {useEffect, useState} from 'react'
import {Card, Form, Divider, Space, message, Row, Col, Select, InputNumber, Input} from 'antd'
import {MoneyCollectOutlined} from '@ant-design/icons/lib/icons'
import './CreateInvoicePage.scss'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from 'common/models'
import {useForm} from 'antd/lib/form/Form'
import {CustomerDetails} from 'pages/billing/customer/models'
import getTaxes from '../tax/actions/getTaxes'
import Button from 'antd-button-color'
import {Link} from 'react-router-dom'
import createInvoice from './actions/createInvoice'
import createInvoiceItem from './actions/createInvoiceItem'
import {useTranslation} from 'react-i18next'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import {formItemLayout, invoiceItemLabelLayout} from "../../../helpers/layoutHelpers";
import {TaxDetails} from "../tax/models";
import {useHistory} from "react-router";
import {sort_rate} from "../../../common/sorting";
import DateInput from "../../../components/RangeFilter/DateInput";
import {recountPriceVAT, recountPriceWithoutVAT} from "./common";
import {removeDiac} from "../../../common/fce";

const {Item} = Form


const CreateInvoicePage = () => {
    const {t} = useTranslation()
    const [form] = useForm()
    const dispatch = useDispatch()
    const history = useHistory()

    const {customers} = useSelector((state: AppState) => state.auth.tables)
    const {taxes} = useSelector((state: AppState) => state.tax)

    const [selectedCustomer, setSelectedCustomer] = useState<CustomerDetails>()
    const [custOptions, setCustOptions] = useState<{ label: string, value: number }[]>([])
    const [taxSource, setTaxSource] = useState<TaxDetails[]>([])
    const [taxDate, setTaxDate] = useState<Date | undefined>(undefined)
    const [maturityDate, setMaturityDate] = useState<Date | undefined>(undefined)
    const [issueDate, setIssueDate] = useState<Date | undefined>(undefined)
    const [isCreator, setCreator] = useState<boolean>(false)

  // get settings and logged user from store
  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    return (
        <div className="fullwidth-loading">
          <LoadingIndicator/>
        </div>
    )
  }
  const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        setCreator(loggedUser.hasAccess('page_billing_invoice_create_button'))
        const dt = new Date()
        const dt14 = new Date(Date.now()  + 1209600000);  // +14 days
        setIssueDate(dt)
        setMaturityDate(dt14)
        setTaxDate(dt)
    }, [])

    useEffect(() => {
        if(customers && customers.length) {
            setCustOptions(customers.filter((c) => c.id > 1).map(cust => ({label: cust.company!.name, value: cust.id})))
        }
    }, [dispatch, customers])

    useEffect(() => {
        if(taxes && taxes.length) {
            const data = taxes.filter((t) => {
                return t.country === 'CZ' && moment().isBetween(t.valid_from, t.valid_to ? t.valid_to : moment(), undefined, '[]')
            })
            setTaxSource(data.sort(sort_rate))
        }
        else {
            dispatch(getTaxes())
        }
    }, [dispatch, taxes])


    useEffect(() => {
        selectedCustomer && form.setFieldsValue({'currency': selectedCustomer?.billing_currency})
    }, [selectedCustomer])

    const formatDate = (dt: string) => {
        return appSetting.renderDate(dt)
    }


    const defaultFormValues = {
        date_of_issue: moment(),
        date_of_maturity: moment().add(14, 'days'),
        date_of_taxing: moment(),
        calculation_type: 'unit',
        currency: 'CZK',
        unit_count: 1,
        invoiceItems: [{position: 1}]
    }

    const onFinish = values => {
        dispatch(
            createInvoice(
                {
                    customer_id: values.customer_id,
                    date_of_issue: appSetting.renderDateSQL(values.date_of_issue),
                    date_of_maturity: appSetting.renderDateSQL(values.date_of_maturity),
                    date_of_taxing: appSetting.renderDateSQL(values.date_of_taxing),
                    invoiceItems: [],
                    generate_customer_services: 0,
                    total_without_vat: 0,
                    total_with_vat: 0,
                    total_vat: 0,
                },
                (isSuccess, invoice_id) => {
                    if (isSuccess && invoice_id) {
                        dispatch(
                            createInvoiceItem(
                                {
                                    invoice_id: invoice_id,
                                    name: values.name,
                                    tax_id: values.tax_id,
                                    unit: values.unit,
                                    calculation_type: 'unit',
                                    unit_count: values.unit_count * 1000,
                                    price_per_unit: values.price_per_unit * 100,
                                    total_without_vat: values.unit_count * values.price_per_unit * 100,
                                    total_with_vat: values.total_with_vat * 100,
                                    total_vat: (values.total_with_vat * 100) - values.unit_count * values.price_per_unit * 100
                                },
                                (isSuccess) => {
                                    if (isSuccess) {
                                        // redirect EDIT
                                        message.success(t('billing.invoice.invoice-created'))
                                        history.replace('/billing/invoice/edit/'+invoice_id)
                                    }
                                    else {
                                        message.success(t('errors.create'))
                                    }
                                }
                            )
                        )
                    }
                }
            ))
    }

  return (
      <>
        <Space direction='vertical' style={ { minWidth: '1248px', width: '100%' } }>
          <Form
              form={form}
              labelCol={{flex: '1'}}
              wrapperCol={{flex: '1'}}
              autoComplete='off'
              onFinish={onFinish}
              initialValues={defaultFormValues}
              validateMessages={{required: ''}}
          >
            <Card
                className='CreateInvoicePage'
                title={<><MoneyCollectOutlined /> &nbsp; {t('billing.invoice.create-invoice-title')} </>}
                style={{maxWidth: '1760px'}}
            >

              <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '25px'}}>
                <Col span={12} >
                  <Item name='customer_id'
                        rules={[{required: true}]}
                        label={t('billing.invoice.customer')}
                        {...formItemLayout}
                  >
                    <Select placeholder={t('billing.invoice.select-customer')}
                            size='middle'
                            style={{width: 300}}
                            showSearch
                            optionFilterProp='label'
                            filterOption={(val, opt) => {
                                return !!opt && removeDiac(opt.label).includes(removeDiac(val))
                            }}
                            options={custOptions}
                    />
                  </Item>
                  <Item name='currency' label={t('billing.invoice.currency')} {...formItemLayout} >
                      <Input size='middle' style={{width: 80, fontWeight: 'bold'}} readOnly bordered={false} />
                  </Item>

                </Col>
                <Col span={12} >
                  <Item
                      name='date_of_issue'
                      rules={[{required: true}]}
                      label={t('billing.invoice.date_of_issue')}
                  >
                      <DateInput htmlId='__date_of_issue'
                                 initDateStr={appSetting.renderDateSQL(issueDate)}
                                 disabled={!isCreator}
                                 required={true}
                                 format={appSetting.date_picker_format} // not Moment formats!!
                                 onChange={(date) => {
                                     date && form.setFieldsValue({date_of_issue: appSetting.renderDateSQL(date)})
                                     date &&  setIssueDate(new Date(date))
                                 }}
                      />
                  </Item>
                  <Item
                      name='date_of_maturity'
                      rules={[{required: true}]}
                      label={t('billing.invoice.date_of_maturity')}
                  >
                      <DateInput htmlId='__date_of_maturity'
                                 initDateStr={appSetting.renderDateSQL(maturityDate)}
                                 disabled={!isCreator}
                                 required={true}
                                 format={appSetting.date_picker_format} // not Moment formats!!
                                 onChange={(date) => {
                                     date && form.setFieldsValue({date_of_maturity: appSetting.renderDateSQL(date)})
                                     date &&  setMaturityDate(new Date(date))
                                 }}
                      />
                  </Item>

                  <Item
                      name='date_of_taxing'
                      rules={[{required: true}]}
                      label={t('billing.invoice.date_of_taxing')}
                  >
                      <DateInput htmlId='__date_of_taxing'
                                 initDateStr={appSetting.renderDateSQL(taxDate)}
                                 disabled={!isCreator}
                                 required={true}
                                 format={appSetting.date_picker_format} // not Moment formats!!
                                 onChange={(date) => {
                                     date && form.setFieldsValue({date_of_taxing: appSetting.renderDateSQL(date)})
                                     date && setTaxDate(new Date(date))
                                 }}
                      />
                  </Item>

                </Col>
              </Row>

              <Divider />

              <Row className='InvoiceTopForm' style={{padding: '10px'}}>
                <Col span={24} >
                  <div className='invoice_items'>
                    <Space size='middle' className='itemBody'>
                    <Row style={{padding: '2px'}} gutter={[16,8]}>
                      <Col span={11} >
                        <Item name='name' label={t('billing.invoice.name')}
                              rules={[{required: true}]} {...invoiceItemLabelLayout}>
                          <Input size='middle' style={{width: '100%'}} />
                        </Item>
                      </Col>
                      <Col span={2} >
                        <Item name='unit_count' label={t('billing.invoice.unit-count')} {...invoiceItemLabelLayout}
                              rules={[{required: true}]}  >
                          <InputNumber size='middle' min={1} style={{width: '100%'}} decimalSeparator=',' />
                        </Item>
                      </Col>
                      <Col span={2} >
                        <Item name='unit' label={t('billing.invoice.unit')} {...invoiceItemLabelLayout}>
                          <Input size='middle' title='ks,TB,MHz,month' style={{width: '100%'}} />
                        </Item>
                      </Col>
                      <Col span={2}  >
                        <Item name='price_per_unit' label={t('billing.invoice.unit-price')} {...invoiceItemLabelLayout}
                              rules={[{required: true}]} >
                            <InputNumber precision={2}
                                         className='inputPerUnit'
                                         style={{width: '100%'}}
                                         decimalSeparator=','
                                         onFocus={(event) => event.target.select()}
                                         onKeyPress={(e) => {if (e.key === 'Enter') {recountPriceVAT(form, appSetting, taxSource)}}}
                                         onBlur={() => recountPriceVAT(form, appSetting, taxSource)}
                                         addonAfter={selectedCustomer?.billing_currency}
                            />
                        </Item>
                      </Col>
                      <Col span={2} >
                        <Item name='tax_id'
                              rules={[{required: true}]}
                              label={t('billing.invoice.tax')} {...invoiceItemLabelLayout}>
                          <Select size='middle' style={{width: '100%'}}
                              optionLabelProp='id'
                              showSearch
                              optionFilterProp='label'
                              onChange={() => form.getFieldValue('price_per_unit') ? recountPriceVAT(form, appSetting, taxSource) : recountPriceWithoutVAT(form, appSetting, taxSource)}
                              options={
                                  taxSource.map(t => ({
                                      label: `${t.country} ${t.rate / 100} %`,
                                      value: t.id,
                                      id: `${t.rate / 100} %`
                                  }))}
                                  defaultValue={0}
                          />
                        </Item>
                      </Col>
                        <Col span={2}  >
                            <Item name='total_with_vat'
                                  rules={[{required: true}]}
                                  label={t('billing.invoice.price_vat')} {...invoiceItemLabelLayout}>
                                <InputNumber precision={2}
                                             className='inputPerUnit'
                                             style={{width: '100%'}}
                                             decimalSeparator=','
                                             onFocus={(event) => event.target.select()}
                                             onKeyPress={(e) => {if (e.key === 'Enter') {recountPriceWithoutVAT(form, appSetting, taxSource)}}}
                                             onBlur={() => recountPriceWithoutVAT(form, appSetting, taxSource)}
                                             addonAfter={selectedCustomer?.billing_currency}
                                />
                            </Item>
                        </Col>
                    </Row>

                      </Space>
                  </div>
                </Col>
              </Row>

              <Divider />

              <Row justify="space-between">
                <Col span={12}>
                  <Space>

                    <Form.Item>
                      <Link to='/billing/invoice'>
                        <Button type='lightdark' size='middle'>{t('general.cancel')}</Button>
                      </Link>
                    </Form.Item>

                  </Space>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Button type='success'
                            onClick={() => {
                                form && form.submit()
                            }}
                            size='middle'>{t('billing.invoice.create_new')}</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

          </Form>

        </Space>
      </>
  )
}

export default CreateInvoicePage
