import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import React, {useEffect, useRef, useState} from "react";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../components";
import ErrorPage403 from "../../components/Errors/ErrorPage403";
import useLogger from "../../common/useLogger";
import {DraggableData, DraggableEvent} from "react-draggable";
import getRdbServices from "./actions/getRdbServices";
import {removeDiac, stopPropagation} from "../../common/fce";
import {DbServiceModel} from "./models";
import {ColumnsType} from "antd/lib/table";
import {AutoComplete, Card, message, Space, Table, Tag} from "antd";
import Button from "antd-button-color";
import {
    ConsoleSqlOutlined,
    DatabaseOutlined,
    DeleteTwoTone,
    EditTwoTone,
    InfoCircleTwoTone,
    WarningTwoTone
} from "@ant-design/icons";
import PopConfirmZis from "../../components/popconfirm/PopConfirmZis";
import getHistoryLogs from "../../components/History/actions/getHistoryLogs";
import {GlobalOutlined, PlusCircleOutlined} from "@ant-design/icons/lib/icons";
import {DnsServiceModel} from "../dns/service/models";


const renderDriver = (name: string) => {
    if (!name) {
        return ''
    }
    if (name.toLowerCase() === 'percona8') {
        return (<Tag color="geekblue">{name}</Tag>)
    }
    else {
        return (<Tag color="orange">{name}</Tag>)
    }
}


const DatabasesPage = () => {
    const CONTROL_NAME = 'page_databases'
    const {t} = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const {isLoadingService, service, services} = useSelector((state: AppState) => state.rdbservice)
    const {rdb_services} = useSelector((state: AppState) => state.sidenav)

    const [isDbServiceViewer, setDbServiceViewer] = useState(false)
    const [isDbServiceCreator, setDbServiceCreator] = useState(false)
    const [isDbServiceEditor, setDbServiceEditor] = useState(false)
    const [isDbServiceDeleter, setDbServiceDeleter] = useState(false)

    const [dataToUpdate, setDataToUpdate] = useState<DbServiceModel>()
    const [dataSource, setDataSource] = useState<DbServiceModel[]>([])
    const [sourceFull, setSourceFull] = useState<DbServiceModel[]>([])

    const [host, setHost] = useState('')
    const [selectedDbServiceId, setSelectedDbServiceId] = useState(0)
    const [isDbServiceModalVisible, setDbServiceModalVisible] = useState<boolean>(false)
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')

    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'DatabasesPage')

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        // trigger ONCE
        const access = loggedUser.hasAccess('page_databases')
        setDbServiceViewer(access)
        setDbServiceCreator(loggedUser.hasAccess('page_rdb_service_create_button'))
        setDbServiceEditor(loggedUser.hasAccess('page_rdb_service_edit_button'))
        if (!isLoadingService && (access)) {
            dispatch(getRdbServices())
        }
    }, [])

    useEffect(() => {
        // trigger from Side menu clicked
        if (!isLoadingService && (isDbServiceViewer)) {
            // reload
            dispatch(getRdbServices())
        }
    }, [rdb_services])

    useEffect(() => {
        if (services) {
            setSourceFull(services.map( s => {
                return {...s,
                    searchableName: removeDiac(s.name),
                    driver_name: s.driver ? s.driver : ''
                }
            }))
        }
        refreshGrid()
    }, [services, host])

    const refreshGrid = () => {
        setDataSource(filtered())
    }

    const filtered = () => {
        // search for pattern
        if (!sourceFull) {
            return []
        }
        let qs: string[] = []
        let data = sourceFull
        if (host && host.length > SEARCH_MIN) {
            data = data.filter((s) => s.name && removeDiac(s.name).includes(removeDiac(host)))
            qs.push('host=' + removeDiac(host))
        }

        // history.replace('/dns/services?' + qs.join("&"))
        logger(`filtered sourceFull: ${sourceFull.length}, dataSource: ${data.length}`)
        return data
    }

    const fetchHistory = (service: string, model: string, mid: number | string) => {
        setHistoryTitle(`${service} / ${model} / ${mid}`)
        dispatch(getHistoryLogs(service, model, mid))
    }

    const handleCreate = () => {
        setDataToUpdate(undefined)
        setDbServiceModalVisible(true)
    }

    const handleDelete = (id: number) => {
        // dispatch(deleteDnsService(id, isOk => isOk && message.success(t('databasesPage.deleted'))))
    }

    const FilterByHost = (
        <AutoComplete
            placeholder={t('databasesPage.host')}
            style={{ width: '120px' }}
            value={host}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(v) => { setHost(v) }}
        />
    )

    const columns: ColumnsType<DbServiceModel> = [
        {
            title: FilterByHost,
            dataIndex: 'name',
            key: 'name',
            width: 200,
            align: 'left',
            fixed: 'left'
        },
        {
            title: t('databasesPage.port'),
            dataIndex: 'port',
            key: 'port',
            width: 80,
            align: 'left',
            fixed: 'left'
        },
        {
            title: t('databasesPage.driver'),
            dataIndex: 'driver_name',
            key: 'driver_name',
            width: 80,
            render: (name: string, rec: DbServiceModel) => renderDriver(name)
        },
        {
            title: t('databasesPage.daemon'),
            dataIndex: 'daemon',
            key: 'daemon',
            width: 80,
        },

        {
            title: t('databasesPage.comment'),
            dataIndex: 'comment',
            key: 'comment',
            width: '40%',
        },
        {
            title: (<div className='center'>Action</div>),
            key: 'action',
            dataIndex: 'action',
            width: '140px',
            align: 'right',
            render: (text: string, record: DbServiceModel) => (
                <Space size={1}  onClick={(e) => e.stopPropagation()}>
                    {
                        isDbServiceEditor && (
                            <Button title={t('general.update')}
                                    type='text' size='small'
                                    onClick={() => {
                                        setDataToUpdate(record)
                                        setDbServiceModalVisible(true)
                                    }}
                                    className='actionButton'
                                    icon={<EditTwoTone twoToneColor='green'/>}
                            />
                        )
                    }

                    {
                        isDbServiceDeleter && (
                            <PopConfirmZis title={t('databasesPage.for_delete_rdb')} onConfirm={() => handleDelete(record.id)}>
                                <Button title={t('general.delete')}
                                        type='text' danger size='small'
                                        className='actionButton'
                                        icon={<DeleteTwoTone twoToneColor='red'/>}
                                />
                            </PopConfirmZis>
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                fetchHistory('dns', 'DnsService', record.id)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone/>}
                            className='actionButton'
                    />
                </Space>
            ),
        }
    ]


    return (
        <>
            <Card title={<><ConsoleSqlOutlined /> &nbsp;{t('databasesPage.title')}</>}
                loading={isLoadingService}
                className='DbServicePage'
                extra={
                    isDbServiceCreator && (
                        <Button type='primary' onClick={handleCreate}>
                            <PlusCircleOutlined/> {t('databasesPage.new_db_server')}
                        </Button>
                    )
                }
            >
                <Table<DbServiceModel>
                    dataSource={dataSource}
                    rowKey='id'
                    columns={columns}
                    loading={isLoadingService}
                    className='dbServicesTable'
                    // expandable={{
                    //     expandedRowRender,
                    //     expandRowByClick: true,
                    //     expandedRowClassName: (rec, index, indent) => 'expandedRow',
                    //     expandedRowKeys: expandedRowKeys
                    // }}
                    // onExpand={handleExpand}
                    // onRow={(rec:DbServiceModel, rowIndex) => {
                    //     if (expandedRowKeys && expandedRowKeys.includes(rec.id)) {
                    //         return { className: 'expandedRowBold' };
                    //     }
                    //     return { className: '' };
                    // }}
                    showHeader={true}
                    size='small'
                    style={{whiteSpace: 'pre'}}
                    scroll={{ x: 680 }}
                    bordered={true}
                    pagination={false}
                />


            </Card>
        </>
    )
}

export default DatabasesPage