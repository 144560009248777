import {
  GetCompaniesRequestAction,
  GetCompaniesResponseAction,
  GetCompaniesErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {CompanyDetails, CompanyGrid} from '../models'

export default (pageSize: number, pageNumber: number, filter: string, cb?: (isSuccess: boolean) => void) => {
  return (
    dispatch: (
      arg:
        | GetCompaniesRequestAction
        | GetCompaniesResponseAction
        | GetCompaniesErrorAction,
    ) => void,
  ) => {
    const request = new GetCompaniesRequestAction()
    dispatch(request)

    let url = `/company/company?${filter}&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`
    protectedApiClient
      .get<CompanyGrid>(url)
      .then((response) => {
        dispatch(new GetCompaniesResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new GetCompaniesErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
