import moment, {Moment} from "moment";
import {SQL_DATE_FORMAT} from "./fce";



class UserStorage {

    // FilterDateFrom
    public getFilterDateFrom(): string | undefined {
        const data = localStorage.getItem('filterDateFrom')
        if (!data) {
            return undefined
        }
        return data
    }
    public setFilterDateFrom(data: string): void {
        if (data) {
            localStorage.setItem('filterDateFrom', data)
        }
    }
    public removeFilterDateFrom(): void {
        localStorage.removeItem('filterDateFrom')
    }

    // FilterDateTo
    public getFilterDateTo(): string | undefined {
        const data = localStorage.getItem('filterDateTo')
        if (!data) {
            return undefined
        }
        return data
    }
    public setFilterDateTo(data: string): void {
        if (data) {
            localStorage.setItem('filterDateTo', data)
        }
    }
    public removeFilterDateTo(): void {
        localStorage.removeItem('filterDateTo')
    }

    public static GetInitRange = (): [Moment, Moment] => {
        // init value for Date Filter
        const us = new UserStorage()
        let dateFrom = us.getFilterDateFrom()
        let dateTo = us.getFilterDateTo()
        if (!dateFrom) {
            // return current year
            if (moment().month() === 0) {
                // in January show the last month
                return [moment().startOf('year').add(-1,'year'), moment().endOf('month')]
            }
            return [moment().startOf('year'), moment().endOf('year')]
        }

        let dt1 = moment(dateFrom)
        if (!dateTo) {
            return [dt1, dt1.endOf('month')]
        }

        return [dt1, moment(dateTo)]
    }

    public static GetInitRangeStr = ():[string, string] => {
        let dt: [Moment, Moment] = UserStorage.GetInitRange()
        return [dt[0].format(SQL_DATE_FORMAT), dt[1].format(SQL_DATE_FORMAT)]
    }
}

export default UserStorage