import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "antd/lib/form/Form";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {Button, Col, Form, Input, message, Row, Select, Spin, Switch, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import {tailLayout} from "../../../helpers/layoutHelpers";
import {Store} from "antd/lib/form/interface";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {UpdateSubnetIpParams} from "./models";
import {CustomerDetails} from "../../billing/customer/models";
import {AppState} from "../../../common/models";
import {sort_ident} from "../../../common/sorting";
import updateIpSubnet from "./actions/updateIpSubnet";
import tableCustomers from "../../login/actions/tableCustomers";
import {isSubnetExternal} from "./ip_common";
import getDnsServices from "./actions/getDnsServices";


interface Props {
    id: number
    onClose: () => void
}

const EditSubnetForm = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form1] = useForm()

    const {customers} = useSelector((state: AppState) => state.auth.tables)
    const {isLoadingSubnet, subnet, dnsServiceNames, isSaving} = useSelector((state: AppState) => state.ipSubnet)

    const [customerSource, setCustomerSource] = useState<CustomerDetails[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [ipmiChecked, setIpmiChecked] = useState<boolean>()


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        if (!dnsServiceNames || !dnsServiceNames.length) {
            dispatch(getDnsServices())
        }
        if(customers && customers.length) {
            const data = customers.map(c => {return {...c, ident: c.company!.name}})
            setCustomerSource(data.sort(sort_ident))
        }
        else {
            dispatch(tableCustomers())
        }
    }, [])

    useEffect(() => {
        setSelectedCustomerId(loggedUser.customer.id)
    }, [customers])

    useEffect(() => {
        console.log('dnsServiceNames: '+dnsServiceNames.length)
    }, [dnsServiceNames])

    useEffect(() => {
        if (subnet && subnet.id === props.id) {
            setSelectedCustomerId(subnet.customer_id)
        }
    }, [subnet])

    const submitSubnet = (values: Store): void => {
        if (!subnet) {
            return
        }
        const params: UpdateSubnetIpParams = {
            id: subnet.id,
            customer_id: selectedCustomerId ? selectedCustomerId : subnet.customer_id,
            note: values.note,
            reverse_domain: values.reverse_domain,
            ipmi: values.ipmi ? 1 : 0,
            vlan: values.vlan && Number(values.vlan) > 0 ? Number(values.vlan) : undefined,
            location: values.location ? values.location : undefined,
            nameserver_id: values.nameserver_id ? values.nameserver_id : undefined
        }
        dispatch(updateIpSubnet(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                props.onClose()
            }
        }))
    }

    if (isLoadingSubnet || !subnet) {
        return <Spin />
    }

    return (
        <>
            <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '0'}}>
                <Col span={6} className='right' >{t('ipSubnetPage.newSubnet.customer')}:&nbsp;</Col>
                <Col span={18} >
                    <Select optionFilterProp='label'
                            style={{width: '100%'}}
                            options={customerSource.map(i => ({
                                value: i.id,
                                key: i.id,
                                label: i.ident
                            }))}
                            defaultValue={subnet.customer_id}
                            value={selectedCustomerId}
                            disabled={isSubnetExternal(subnet.id)}
                            onChange={ (value: number) => { setSelectedCustomerId(value) }}
                            showSearch
                    />
                </Col>
            </Row>

            <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '25px'}}>
                <Col span={24} >

                            <Form name='create-subnet'
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                  layout="horizontal"
                                  form={form1}
                                  initialValues={{...subnet, vlan: subnet.vlan ? subnet.vlan : undefined}}
                                  onFinish={submitSubnet}
                            >
                                <Form.Item name='note' label={t('ipSubnetPage.newSubnet.name')}
                                           rules={[{required: true, message: t('errors.field_required')}]}>
                                    <Input placeholder='cloud1' />
                                </Form.Item>
                                <Form.Item name='cidr' label={t('ipSubnetPage.newSubnet.cidr')}>
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item name='reverse_domain' label={t('ipSubnetPage.reverse_zone')}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name='location' label={t('ipSubnetPage.newSubnet.location')}>
                                    <Input placeholder='TTC' disabled={isSubnetExternal(subnet.id)} />
                                </Form.Item>
                                <Form.Item name='nameserver_id' label={t('ipSubnetPage.newSubnet.ns')}>
                                    <Select size='middle'
                                            options={dnsServiceNames.map(i => ({
                                                value: i.id,
                                                key: i.id,
                                                label: i.name
                                            }))}
                                            showSearch
                                            disabled={isSubnetExternal(subnet.id)}
                                            optionFilterProp='label'
                                    />
                                </Form.Item>
                                <Form.Item name='vlan' label={t('ipSubnetPage.newSubnet.vlan')}>
                                    <Input type='number' min={1} disabled={isSubnetExternal(subnet.id)} />
                                </Form.Item>
                                <Form.Item name='ipmi' label={t('ipSubnetPage.newSubnet.ipmi')}
                                           valuePropName='checked'
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={ipmiChecked}
                                        disabled={isSubnetExternal(subnet.id)}
                                        onChange={() => setIpmiChecked(!ipmiChecked)}
                                    />
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button type='primary' htmlType='submit'
                                            loading={isSaving}
                                            className='create-button'>
                                        {t('general.update')}
                                    </Button>
                                </Form.Item>
                            </Form>

                </Col>
            </Row>
        </>
    )

}

export default EditSubnetForm