import {
    UserGroupAddUserToGroupRequestAction,
    UserGroupAddUserToGroupErrorAction,
    UserGroupAddUserToGroupResponseAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { UserGroupModel} from '../models'
import {AddDeleteUserToGroupParams} from "../../users/models";


export default (
    params: AddDeleteUserToGroupParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | UserGroupAddUserToGroupRequestAction
                | UserGroupAddUserToGroupErrorAction
                | UserGroupAddUserToGroupResponseAction,
        ) => void,
    ) => {
        const request = new UserGroupAddUserToGroupRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<UserGroupModel>(`/user-group/${params.id}/user`, params)
            .then((response) => {
                dispatch(new UserGroupAddUserToGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UserGroupAddUserToGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
