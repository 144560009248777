import { SUPPORTED_LANGUAGES } from 'i18n'
import moment from 'moment'
import React from 'react'
import {Menu, Dropdown, MenuProps} from 'antd'
import {DownOutlined, GlobalOutlined, MailOutlined} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import './LanguageSwitcher.scss'
import {NavLink} from "react-router-dom";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language, () => {
      moment.locale(i18n.language)
    })
  }

  const getMenuItems = (): MenuItem[] => {
    return SUPPORTED_LANGUAGES.map((lng, i) => getItem((
        <div data-value={lng} onClick={(e) => changeLanguage(e.currentTarget.dataset.value!)} >{i18n.t(`languageSwitcher.${lng}`)}</div>), i))
  }


  const langDropdown = (
      <Menu mode="vertical" items={getMenuItems()} style={{marginTop: '-8px'}} />
  )

  return (
    <Dropdown overlay={langDropdown}>
      <div className='ant-dropdown-link'>
        <GlobalOutlined />
        <span className='text-uppercase'>{i18n.language}  </span> <DownOutlined />
      </div>
    </Dropdown >
  )
}

export default LanguageSwitcher
