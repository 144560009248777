import {
    GetEndpointRequestAction,
    GetEndpointResponseAction,
    GetEndpointErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {EndPointModel} from '../models'


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetEndpointRequestAction
                | GetEndpointResponseAction
                | GetEndpointErrorAction,
        ) => void,
    ) => {
        const request = new GetEndpointRequestAction(id)
        dispatch(request)

        protectedApiClient
            .get<EndPointModel>(`/endpoint/${id}`)
            .then((response) => {
                dispatch(new GetEndpointResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetEndpointErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
