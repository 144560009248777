import {
    LookupIpRequestAction,
    LookupIpResponseAction,
    LookupIpErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'


export default (field: string, type_id: number, qs: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: LookupIpRequestAction | LookupIpResponseAction | LookupIpErrorAction,
        ) => void,
    ) => {
        const request = new LookupIpRequestAction(field, type_id)
        dispatch(request)

        protectedApiClient
            .get<string[]>(`/ip/ip/lookup?`+qs)
            .then((response) => {
                dispatch(new LookupIpResponseAction(request, field, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new LookupIpErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
