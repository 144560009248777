import {
    CreateInventoryTypeRequestAction,
    CreateInventoryTypeResponseAction,
    CreateInventoryTypeErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {InventoryTypeParams, InventoryTypeModel} from '../models'

export default (
    params: InventoryTypeParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | CreateInventoryTypeRequestAction
                | CreateInventoryTypeResponseAction
                | CreateInventoryTypeErrorAction,
        ) => void,
    ) => {
        const request = new CreateInventoryTypeRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<InventoryTypeModel>('/inventory/type?expand=usableIds', params)
            .then((response) => {
                dispatch(new CreateInventoryTypeResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new CreateInventoryTypeErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
