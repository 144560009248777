import {
    AllAuthenticationsRequestAction,
    AllAuthenticationsResponseAction,
    AllAuthenticationsErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {AuthenticationModel} from '../models'


export default (cb?: (suc: boolean) => void) => {
    return (
        dispatch: (
            arg: AllAuthenticationsRequestAction | AllAuthenticationsResponseAction | AllAuthenticationsErrorAction,
        ) => void,
    ) => {
        const request = new AllAuthenticationsRequestAction()
        dispatch(request)

        protectedApiClient
            .get<AuthenticationModel[]>(`/authentication`)
            .then((response) => {
                dispatch(new AllAuthenticationsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new AllAuthenticationsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
