import { AsyncActionMode } from 'common/models'
import {BillParams, BillModel, BillAttachmentModel, BillGrid} from '../models'
import {BillVatSummaryModel, BillCostAllocationModel} from "../models";

import {CostAllocationCategoryDetails} from "../../costallocation/models";

export enum ActionType {
  CREATE_BILL = 'CREATE_BILL',
  GET_BILLS = 'GET_BILLS',
  GET_BILL = 'GET_BILL',
  UPDATE_BILL = 'UPDATE_BILL',
  DELETE_BILL = 'DELETE_BILL',
  CLEAR_BILL = 'CLEAR_BILL',
  CREATE_BILL_ATTACHMENT = 'CREATE_BILL_ATTACHMENT',
  GET_BILL_ATTACHMENTS = 'GET_BILL_ATTACHMENTS',
  GET_BILL_ATTACHMENT = 'GET_BILL_ATTACHMENT',
  DELETE_BILL_ATTACHMENT = 'DELETE_BILL_ATTACHMENT',

  GET_BILLVATSUMMARY_BY_BILL = 'GET_BILLVATSUMMARY_BY_BILL',
  CREATE_BILLVATSUMMARY = 'CREATE_BILLVATSUMMARY',
  GET_BILLVATSUMMARY = 'GET_BILLVATSUMMARY',
  UPDATE_BILLVATSUMMARY = 'UPDATE_BILLVATSUMMARY',
  DELETE_BILLVATSUMMARY = 'DELETE_BILLVATSUMMARY',

  CREATE_BILL_COSTALLOCATION = 'CREATE_BILL_COSTALLOCATION',
  GET_BILL_COSTALLOCATIONS = 'GET_BILL_COSTALLOCATIONS',
  GET_BILL_COSTALLOCATIONS_BY_CATEGORY = 'GET_BILL_COSTALLOCATIONS_BY_CATEGORY',
  GET_BILL_COSTALLOCATION = 'GET_BILL_COSTALLOCATION',
  UPDATE_BILL_COSTALLOCATION = 'UPDATE_BILL_COSTALLOCATION',
  DELETE_BILL_COSTALLOCATION = 'DELETE_BILL_COSTALLOCATION',
  GET_BILL_COSTALLOCATIONS_BY_BILL = 'GET_BILL_COSTALLOCATIONS_BY_BILL',
  GET_COST_CATEGORIES_BILL = 'GET_COST_CATEGORIES_BILL',
  LOOKUP_BILL = 'LOOKUP_BILL',
  BILL_LOOKUP_COMPANY = 'BILL_LOOKUP_COMPANY'
}

export type BillActions =
  | CreateBillRequestAction
  | CreateBillResponseAction
  | CreateBillErrorAction
  | GetBillsRequestAction
  | GetBillsResponseAction
  | GetBillsErrorAction
  | GetBillRequestAction
  | GetBillResponseAction
  | GetBillErrorAction
  | ClearBillRequestAction
  | ClearBillResponseAction
  | UpdateBillRequestAction
  | UpdateBillResponseAction
  | UpdateBillErrorAction
  | DeleteBillRequestAction
  | DeleteBillResponseAction
  | DeleteBillErrorAction
    | LookupBillRequestAction
    | LookupBillResponseAction
    | LookupBillErrorAction
    | LookupCompanyRequestAction
    | LookupCompanyResponseAction
    | LookupCompanyErrorAction
  | CreateBillAttachmentRequestAction
  | CreateBillAttachmentResponseAction
  | CreateBillAttachmentErrorAction
  | GetBillAttachmentsRequestAction
  | GetBillAttachmentsResponseAction
  | GetBillAttachmentsErrorAction
  | GetBillAttachmentRequestAction
  | GetBillAttachmentResponseAction
  | GetBillAttachmentErrorAction
  | DeleteBillAttachmentRequestAction
  | DeleteBillAttachmentResponseAction
  | DeleteBillAttachmentErrorAction
  | GetBillVatSummaryByBillRequestAction
  | GetBillVatSummaryByBillResponseAction
  | GetBillVatSummaryByBillErrorAction
  | CreateBillVatSummaryRequestAction
  | CreateBillVatSummaryResponseAction
  | CreateBillVatSummaryErrorAction
  | GetBillVatSummaryRequestAction
  | GetBillVatSummaryResponseAction
  | GetBillVatSummaryErrorAction
  | UpdateBillVatSummaryRequestAction
  | UpdateBillVatSummaryResponseAction
  | UpdateBillVatSummaryErrorAction
  | DeleteBillVatSummaryRequestAction
  | DeleteBillVatSummaryResponseAction
  | DeleteBillVatSummaryErrorAction

    | CreateBillCostAllocationRequestAction
    | CreateBillCostAllocationResponseAction
    | CreateBillCostAllocationErrorAction
    | GetBillCostAllocationsRequestAction
    | GetBillCostAllocationsResponseAction
    | GetBillCostAllocationsErrorAction
    | GetBillCostAllocationRequestAction
    | GetBillCostAllocationResponseAction
    | GetBillCostAllocationErrorAction
    | GetBillCostAllocationByCategoryRequestAction
    | GetBillCostAllocationByCategoryResponseAction
    | GetBillCostAllocationByCategoryErrorAction
    | UpdateBillCostAllocationRequestAction
    | UpdateBillCostAllocationResponseAction
    | UpdateBillCostAllocationErrorAction
    | DeleteBillCostAllocationRequestAction
    | DeleteBillCostAllocationResponseAction
    | DeleteBillCostAllocationErrorAction
    | GetBillCostAllocationByBillRequestAction
    | GetBillCostAllocationByBillResponseAction
    | GetBillCostAllocationByBillErrorAction
    | GetCostAllocationsRequestAction
    | GetCostAllocationsResponseAction
    | GetCostAllocationsErrorAction


export class ClearBillRequestAction {
  readonly type = ActionType.CLEAR_BILL
  readonly mode = AsyncActionMode.REQUEST
  constructor() {}
}
export class ClearBillResponseAction {
  readonly type = ActionType.CLEAR_BILL
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: ClearBillRequestAction) {}
}

export class CreateBillRequestAction {
  readonly type = ActionType.CREATE_BILL
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: BillParams) {}
}
export class CreateBillResponseAction {
  readonly type = ActionType.CREATE_BILL
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: CreateBillRequestAction,
    public data: BillModel,
  ) {}
}
export class CreateBillErrorAction {
  readonly type = ActionType.CREATE_BILL
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: CreateBillRequestAction,
    public error: string,
  ) {}
}

export class GetBillRequestAction {
  readonly type = ActionType.GET_BILL
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetBillResponseAction {
  readonly type = ActionType.GET_BILL
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: GetBillRequestAction, public data: BillModel) {}
}
export class GetBillErrorAction {
  readonly type = ActionType.GET_BILL
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: GetBillRequestAction, public error: string) {}
}

export class GetBillsRequestAction {
  readonly type = ActionType.GET_BILLS
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetBillsResponseAction {
  readonly type = ActionType.GET_BILLS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: GetBillsRequestAction,
    public data: BillGrid,
  ) {}
}
export class GetBillsErrorAction {
  readonly type = ActionType.GET_BILLS
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: GetBillsRequestAction, public error: string) {}
}

export class UpdateBillRequestAction {
  readonly type = ActionType.UPDATE_BILL
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: BillParams) {}
}
export class UpdateBillResponseAction {
  readonly type = ActionType.UPDATE_BILL
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: UpdateBillRequestAction,
    public data: BillModel,
  ) {}
}
export class UpdateBillErrorAction {
  readonly type = ActionType.UPDATE_BILL
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: UpdateBillRequestAction,
    public error: string,
  ) {}
}

export class DeleteBillRequestAction {
  readonly type = ActionType.DELETE_BILL
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}
export class DeleteBillResponseAction {
  readonly type = ActionType.DELETE_BILL
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: DeleteBillRequestAction,
    public data: BillModel,
  ) {}
}
export class DeleteBillErrorAction {
  readonly type = ActionType.DELETE_BILL
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: DeleteBillRequestAction,
    public error: string,
  ) {}
}


export class CreateBillAttachmentRequestAction {
  readonly type = ActionType.CREATE_BILL_ATTACHMENT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: BillAttachmentModel) {}
}
export class CreateBillAttachmentResponseAction {
  readonly type = ActionType.CREATE_BILL_ATTACHMENT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: CreateBillAttachmentRequestAction,
      public data: BillAttachmentModel,
  ) {}
}
export class CreateBillAttachmentErrorAction {
  readonly type = ActionType.CREATE_BILL_ATTACHMENT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: CreateBillAttachmentRequestAction,
      public error: string,
  ) {}
}

export class GetBillAttachmentRequestAction {
  readonly type = ActionType.GET_BILL_ATTACHMENT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetBillAttachmentResponseAction {
  readonly type = ActionType.GET_BILL_ATTACHMENT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetBillAttachmentRequestAction,
      public data: BillAttachmentModel,
  ) {}
}
export class GetBillAttachmentErrorAction {
  readonly type = ActionType.GET_BILL_ATTACHMENT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetBillAttachmentRequestAction,
      public error: string,
  ) {}
}

export class GetBillAttachmentsRequestAction {
  readonly type = ActionType.GET_BILL_ATTACHMENTS
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetBillAttachmentsResponseAction {
  readonly type = ActionType.GET_BILL_ATTACHMENTS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetBillAttachmentsRequestAction,
      public data: BillAttachmentModel[],
  ) {}
}
export class GetBillAttachmentsErrorAction {
  readonly type = ActionType.GET_BILL_ATTACHMENTS
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetBillAttachmentsRequestAction,
      public error: string,
  ) {}
}

export class DeleteBillAttachmentRequestAction {
  readonly type = ActionType.DELETE_BILL_ATTACHMENT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}
export class DeleteBillAttachmentResponseAction {
  readonly type = ActionType.DELETE_BILL_ATTACHMENT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: DeleteBillAttachmentRequestAction,
      public data: BillAttachmentModel,
  ) {}
}
export class DeleteBillAttachmentErrorAction {
  readonly type = ActionType.DELETE_BILL_ATTACHMENT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: DeleteBillAttachmentRequestAction,
      public error: string,
  ) {}
}

export class GetBillVatSummaryByBillRequestAction {
  readonly type = ActionType.GET_BILLVATSUMMARY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetBillVatSummaryByBillResponseAction {
  readonly type = ActionType.GET_BILLVATSUMMARY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetBillVatSummaryByBillRequestAction,
      public data: BillVatSummaryModel[],
  ) {}
}
export class GetBillVatSummaryByBillErrorAction {
  readonly type = ActionType.GET_BILLVATSUMMARY
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetBillVatSummaryByBillRequestAction,
      public error: string,
  ) {}
}

export class CreateBillVatSummaryRequestAction {
  readonly type = ActionType.CREATE_BILLVATSUMMARY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: BillVatSummaryModel) {}
}
export class CreateBillVatSummaryResponseAction {
  readonly type = ActionType.CREATE_BILLVATSUMMARY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: CreateBillVatSummaryRequestAction,
      public data: BillVatSummaryModel[],
  ) {}
}
export class CreateBillVatSummaryErrorAction {
  readonly type = ActionType.CREATE_BILLVATSUMMARY
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: CreateBillVatSummaryRequestAction,
      public error: string,
  ) {}
}

export class GetBillVatSummaryRequestAction {
  readonly type = ActionType.GET_BILLVATSUMMARY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetBillVatSummaryResponseAction {
  readonly type = ActionType.GET_BILLVATSUMMARY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetBillVatSummaryRequestAction,
      public data: BillVatSummaryModel[],
  ) {}
}
export class GetBillVatSummaryErrorAction {
  readonly type = ActionType.GET_BILLVATSUMMARY
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetBillVatSummaryRequestAction,
      public error: string,
  ) {}
}

export class UpdateBillVatSummaryRequestAction {
  readonly type = ActionType.UPDATE_BILLVATSUMMARY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: BillVatSummaryModel) {}
}
export class UpdateBillVatSummaryResponseAction {
  readonly type = ActionType.UPDATE_BILLVATSUMMARY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: UpdateBillVatSummaryRequestAction,
      public data: BillVatSummaryModel,
  ) {}
}
export class UpdateBillVatSummaryErrorAction {
  readonly type = ActionType.UPDATE_BILLVATSUMMARY
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: UpdateBillVatSummaryRequestAction,
      public error: string,
  ) {}
}

export class DeleteBillVatSummaryRequestAction {
  readonly type = ActionType.DELETE_BILLVATSUMMARY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}
export class DeleteBillVatSummaryResponseAction {
  readonly type = ActionType.DELETE_BILLVATSUMMARY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: DeleteBillVatSummaryRequestAction,
      public data: BillVatSummaryModel[],
  ) {}
}
export class DeleteBillVatSummaryErrorAction {
  readonly type = ActionType.DELETE_BILLVATSUMMARY
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: DeleteBillVatSummaryRequestAction,
      public error: string,
  ) {}
}


export class GetBillCostAllocationByBillRequestAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATIONS_BY_BILL
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetBillCostAllocationByBillResponseAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATIONS_BY_BILL
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetBillCostAllocationByBillRequestAction,
      public data: BillCostAllocationModel[],
  ) {}
}
export class GetBillCostAllocationByBillErrorAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATIONS_BY_BILL
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetBillCostAllocationByBillRequestAction,
      public error: string,
  ) {}
}

export class GetBillCostAllocationByCategoryRequestAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATIONS_BY_CATEGORY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetBillCostAllocationByCategoryResponseAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATIONS_BY_CATEGORY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetBillCostAllocationByCategoryRequestAction,
      public data: BillCostAllocationModel[],
  ) {}
}
export class GetBillCostAllocationByCategoryErrorAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATIONS_BY_CATEGORY
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetBillCostAllocationByCategoryRequestAction,
      public error: string,
  ) {}
}

export class CreateBillCostAllocationRequestAction {
  readonly type = ActionType.CREATE_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: BillCostAllocationModel) {}
}
export class CreateBillCostAllocationResponseAction {
  readonly type = ActionType.CREATE_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: CreateBillCostAllocationRequestAction,
      public data: BillCostAllocationModel,
  ) {}
}
export class CreateBillCostAllocationErrorAction {
  readonly type = ActionType.CREATE_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: CreateBillCostAllocationRequestAction,
      public error: string,
  ) {}
}

export class GetBillCostAllocationRequestAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetBillCostAllocationResponseAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetBillCostAllocationRequestAction,
      public data: BillCostAllocationModel,
  ) {}
}
export class GetBillCostAllocationErrorAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetBillCostAllocationRequestAction,
      public error: string,
  ) {}
}

export class GetBillCostAllocationsRequestAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATIONS
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetBillCostAllocationsResponseAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATIONS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetBillCostAllocationsRequestAction,
      public data: BillCostAllocationModel[],
  ) {}
}
export class GetBillCostAllocationsErrorAction {
  readonly type = ActionType.GET_BILL_COSTALLOCATIONS
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetBillCostAllocationsRequestAction,
      public error: string,
  ) {}
}

export class UpdateBillCostAllocationRequestAction {
  readonly type = ActionType.UPDATE_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: BillCostAllocationModel) {}
}
export class UpdateBillCostAllocationResponseAction {
  readonly type = ActionType.UPDATE_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: UpdateBillCostAllocationRequestAction,
      public data: BillCostAllocationModel,
  ) {}
}
export class UpdateBillCostAllocationErrorAction {
  readonly type = ActionType.UPDATE_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: UpdateBillCostAllocationRequestAction,
      public error: string,
  ) {}
}

export class DeleteBillCostAllocationRequestAction {
  readonly type = ActionType.DELETE_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}
export class DeleteBillCostAllocationResponseAction {
  readonly type = ActionType.DELETE_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: DeleteBillCostAllocationRequestAction,
      public data: BillCostAllocationModel,
  ) {}
}
export class DeleteBillCostAllocationErrorAction {
  readonly type = ActionType.DELETE_BILL_COSTALLOCATION
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: DeleteBillCostAllocationRequestAction,
      public error: string,
  ) {}
}


export class GetCostAllocationsRequestAction {
  readonly type = ActionType.GET_COST_CATEGORIES_BILL
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetCostAllocationsResponseAction {
  readonly type = ActionType.GET_COST_CATEGORIES_BILL
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetCostAllocationsRequestAction,
      public data: CostAllocationCategoryDetails[],
  ) {}
}
export class GetCostAllocationsErrorAction {
  readonly type = ActionType.GET_COST_CATEGORIES_BILL
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetCostAllocationsRequestAction,
      public error: string,
  ) {}
}

export class LookupBillRequestAction {
  readonly type = ActionType.LOOKUP_BILL
  readonly mode = AsyncActionMode.REQUEST
  constructor(public field: string) {
    ''
  }
}
export class LookupBillResponseAction {
  readonly type = ActionType.LOOKUP_BILL
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: LookupBillRequestAction,
      public field: string,
      public data: string[],
  ) {}
}
export class LookupBillErrorAction {
  readonly type = ActionType.LOOKUP_BILL
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: LookupBillRequestAction, public error: string) {}
}

export class LookupCompanyRequestAction {
  readonly type = ActionType.BILL_LOOKUP_COMPANY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public field: string) {
    ''
  }
}
export class LookupCompanyResponseAction {
  readonly type = ActionType.BILL_LOOKUP_COMPANY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: LookupCompanyRequestAction,
      public field: string,
      public data: string[],
  ) {}
}
export class LookupCompanyErrorAction {
  readonly type = ActionType.BILL_LOOKUP_COMPANY
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: LookupCompanyRequestAction, public error: string) {}
}