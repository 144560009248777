import React, {useEffect, useRef, useState} from 'react'
import {
	Button,
	message,
	Modal,
	Space,
	Table,
	Tag,
	AutoComplete,
	Input,
	Row, Col, Card, Tooltip, Checkbox
} from 'antd'
import {
	EuroCircleOutlined,
	PlusCircleOutlined,
	CopyOutlined,
} from '@ant-design/icons/lib/icons'
import {ProductPriceDetails} from './models'
import {AppState} from 'common/models'
import ProductPriceForm from './ProductPriceForm'
import {useDispatch, useSelector} from 'react-redux'
import deleteProductPrice from './actions/deleteProductPrice'
import getProductPriceRanges from '../productpricerange/actions/getProductPriceRanges'
import getPricelist from '../pricelist/actions/getPricelist'
import {useTranslation} from 'react-i18next'
import getProductPricesByPricelist from './actions/getProductPricesByPricelist'
import {useParams} from 'react-router-dom'
import {CgInfinity} from 'react-icons/cg'
import './ProductPricePage.scss'
import {
	DeleteTwoTone,
	EditTwoTone, ExclamationCircleOutlined,
	InfoCircleOutlined,
	LockTwoTone,
} from '@ant-design/icons'
import makePricelistCopy from './actions/makePricelistCopy'
import moment from 'moment'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {ProductDetails} from "../product/models";
import getProducts from "../product/actions/getProducts";
import {LoadingIndicator} from "../../../components";
import {protectedApiClient} from "../../../helpers/api";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {useHistory} from "react-router";
import activatePricelist from "./actions/activatePricelist";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import HistoryLog from "../../../components/History/HistoryLog";
import getHistoryLogs from "../../../components/History/actions/getHistoryLogs";
import usePageSize from "../../../common/usePageSize";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {sort_name} from "../../../common/sorting";
import {removeDiac} from "../../../common/fce";
import DateInput from "../../../components/RangeFilter/DateInput";
import deletePricelist from "../pricelist/actions/deletePricelist";
import HistoryModal from "../../../components/History/HistoryModal";

const { confirm } = Modal

interface ParamTypes {
	id: string
}

const getProductName = (id: number, products: ProductDetails[]) => {
	let p = products.find((p) => p.id === id)
	return p ? p.name : ''
}
const getServiceType = (id: number, products: ProductDetails[]) => {
	let p = products.find((p) => p.id === id)
	return p ? p.service_type : ''
}

const ProductPricePage = () => {
	const CONTROL_NAME = 'page_billing_pricelist_products'
	const {id} = useParams<ParamTypes>()
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	const {products} = useSelector((state: AppState) => state.product)
	const {pricelist} = useSelector((state: AppState) => state.pricelist)
	const {productprices, productprice, isLoading, isSaving} = useSelector((state: AppState) => state.productprice)

	const [plId, setPlId] = useState(0)
	const [isModalVisible, setProductPricesModalVisible] = useState(false)
	const [isProductPricesCopyVisible, setProductPricesCopyVisible] = useState(false)
	const [activateVisible, setActivateVisible] = useState(false)
	const [pricelistActivating, setPricelistActivating] = useState(false)

	const [showNewPriceList, setShowNewPriceList] = useState(true)
	const [dataProductPricesToUpdate, setProductPricesDataToUpdate] = useState<ProductPriceDetails>()

	const [dataSource,setDataSource] = useState<ProductPriceDetails[]>()
	const [historyModelId, setHistoryModelId] = useState<number | undefined>()
	const [forceUpdateId, setForceUpdateId] = useState<number>(1)

	const [openDelete, setOpenDelete] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)

	const [searchName, setSearchName] = useState<string>('')
	const [searchServiceType, setSearchServiceType] = useState<string>('')
	const [pageSize, setPageSize] = useState<string>()
	const [lastInvoiceDate, setLastInvoiceDate] = useState<string>('')
	const [copyFromDate, setCopyFromDate] = useState<string | null>()

	const [isEditor, setEditor] = useState(false)

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator/>
			</div>
		)
	}

	// required authorization
	if (!loggedUser.hasAccess(CONTROL_NAME)) {
		return <ErrorPage403 />
	}

	// settings
	const appSetting = loggedUser.getAppSettings()
	const SEARCH_MIN = appSetting.min_search_length

	// logger
	const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('BillingProductPricePage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('BillingProductPricePage: ' + msg)} }
	usePageSize(appSetting, loggedUser.user.id, pageSize)

	// history drag modal
	const draggleRef = useRef<HTMLDivElement>(null)
	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggleRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	}

	useEffect(() => {
		// trigger ONCE
		setEditor(loggedUser.hasAccess('page_billing_pricelist_edit_button'))
		setCopyFromDate(appSetting.renderDateSQL(new Date()))
		dispatch(getProducts())
	}, [])

	useEffect(() => {
		logger('id..'+id)
		if (id && parseInt(id) > 0 && plId != parseInt(id)) {
			setPlId(parseInt(id))
		}
		if (!id) {
			history.push('/billing/pricelist')
		}
	}, [id])


	useEffect(() => {
		// load products from API
		if (plId) {
			dispatch(getPricelist(plId))
			dispatch(getProductPricesByPricelist(plId))
			setHistoryModelId(plId)
		}
	}, [plId])

	useEffect(() => {
		if (pricelist && pricelist.id === plId) {
			setLastInvoiceDate(pricelist.valid_from.toString())
			if (pricelist.customer_id && pricelist.customer_id>1) {
				fetchLastInvoice(pricelist.customer_id)
			}
			setForceUpdateId(forceUpdateId+1)	// update history
		}
	}, [pricelist])

	useEffect(() => {
		// update datasource when data was changed
		if (!isModalVisible) {
			refreshGrid()
		}
	}, [productprices, isModalVisible, searchName, searchServiceType])

	const fetchLastInvoice = (cid: number) => {
		protectedApiClient.get<string>(`/customer/${cid}/last-invoice`)
			.then(response => {
				if (response) {
					const invoice = response['data']
					let s_date = invoice['period_to']
					if (s_date) {
						setLastInvoiceDate(moment(s_date).add(1, 'days').toString())
					}
					else {
						s_date = invoice['date_of_taxing']
						if (s_date) {
							setLastInvoiceDate(moment(s_date).add(1, 'days').endOf('day').toString())
						}
					}
				}
			})
			.catch(error => {
				setLastInvoiceDate('')
				logger(error.toString())
			})
	}

	const filtered = (name: string, service: string) => {
		// search for pattern
		if (!productprices) {
			return []
		}
		if (!products) {
			return []
		}
		let data = productprices.map((item: ProductPriceDetails) => ({
			...item,
			name: getProductName(item.product_id, products),
			service_type: getServiceType(item.product_id, products),
			searchableName: removeDiac(getProductName(item.product_id, products))
		}))

		if (name && name.length > SEARCH_MIN) {
			data = data.filter((p) => p.searchableName.toLowerCase().includes(removeDiac(name)))
		}
		if (service && service.length > SEARCH_MIN) {
			data = data.filter((p) => p.service_type?.toLowerCase().includes(service.toLowerCase()))
		}
		return data.sort(sort_name)
	}

	const refreshGrid = () => {
		const name = searchName.replace(/\s/g, '')
		const service = searchServiceType.replace(/\s/g, '')
		setDataSource(filtered(name, service))
	}

	const handlePriceListCopy = () => {
		copyFromDate && dispatch(makePricelistCopy({ id: parseInt(id), valid_from: copyFromDate},
			(suc, pl_id) => {
						setProductPricesCopyVisible(false)
						if (suc) {
							message.info(<>{t('billing.productprice.copy_success')}</>, 2)
							setForceUpdateId(forceUpdateId+1)	// update history
							if (showNewPriceList) {
								history.replace('/billing/pricelist-products/' + pl_id)
							}
						}
					}
				)
		)
	}

	const handlePriceListActivate = () => {
		if (pricelist) {
			setPricelistActivating(true)
			dispatch(activatePricelist(pricelist.id, (suc) => {
					if (suc) {
						setForceUpdateId(forceUpdateId+1)	// update history
						message.success(t('billing.productprice.activated'))
						setActivateVisible(false)
						setPricelistActivating(false)
					}
					else {
						message.error(t('general.error'))
					}
				}
			))
		}
	}

	const productPricesUpdate = (record: ProductPriceDetails) => {
		if (record && record.id) {
			setProductPricesDataToUpdate(record)
			if (record.calculation_type === 'range') {
				dispatch(getProductPriceRanges(record.id, suc => {
					suc && setProductPricesModalVisible(true)
					setForceUpdateId(forceUpdateId+1)	// update history
				}))
			}
			else {
				dispatch(getProductPriceRanges(record.id, suc => {
					suc && setProductPricesModalVisible(true)
					setForceUpdateId(forceUpdateId+1)  // update history
				}))
			}
		}
	}

	const renderPrice = (text: number, record: ProductPriceDetails) => {
		if (record.calculation_type === 'range' && record.productPriceRanges) {
			return record.productPriceRanges
				.sort((a, b) => a.volume_from - b.volume_from)
				.map((v, i) => (
					<div key={v.id} className='Price'>
						<Tag className='Price_Tag' color='green'>
							{v.volume_from / 1000} - &nbsp;
							{record.productPriceRanges && record.productPriceRanges[i + 1] ? (
								record.productPriceRanges[i + 1].volume_from / 1000
							) : (
								<div className='Price_Tag_iconWrapper'>
									<CgInfinity size={22} className='Price_Tag_iconWrapper_icon' />
								</div>
							)}
							&nbsp; <span className='Price_Tag_unit'>{record.unit}</span>
						</Tag>
						<span className='Price_priceWrapper'>
							<span className='Price_priceWrapper_price'>{appSetting?.renderPrice(v.price)}</span> &nbsp;
						</span>
					</div>
				))
		} else {
			return appSetting?.renderPrice(text)
		}
	}

	const FilterByName = productprices && (
		<AutoComplete
			placeholder={t('billing.productprice.name')}
			style={{ width: 250 }}
			value={searchName}
			allowClear={true}
			onChange={(value) => { setSearchName(value) }}
		/>
	)

	const FilterByServiceType = productprices && (
		<AutoComplete
			placeholder={t('billing.productprice.service_type')}
			style={{ width: 180 }}
			value={searchServiceType}
			allowClear={true}
			onChange={v => { setSearchServiceType(v) }}
		/>
	)

	const refreshPricelist = () => {
		if (plId) {
			dispatch(getProductPricesByPricelist(plId))
		}
	}

	const showConfirmDelete = (b: ProductPriceDetails, title: string) => {
		if (!b || !b.id) {
			return
		}
		// if (!isDeleter) {
		//     message.error(t('general.error403'))
		//     return
		// }
		confirm({
				icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
				title: title,
				content: <p>{b.name}</p>,
				okText: t('general.yes'),
				cancelText: t('general.cancel'),
				okButtonProps: { loading: confirmDelete },
				className: 'confirm-alert',
				onOk() {
					setConfirmDelete(true)
					b.id && dispatch(deleteProductPrice(b.id, (suc) => {
							setConfirmDelete(false)
							setOpenDelete(false)
							if (suc) {
								message.success(t('general.success'))
								getProductPricesByPricelist(Number(id))
								setForceUpdateId(forceUpdateId+1)	// update history
							}
						}
					))
				},
				onCancel() {
					setOpenDelete(false)
				},
			}
		)
	}

	const columns = [
		{
			title: FilterByName,
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			width: '40%'
		},
		{
			title: FilterByServiceType,
			dataIndex: 'service_type',
			key: 'service_type'
		},
		{
			title: t('billing.productprice.calculation_type'),
			key: 'calculation_type',
			dataIndex: 'calculation_type',
			render: (text: string, record: ProductPriceDetails) => (
				<>
					<Tag color={text === 'range' ? 'green' : text === 'unit' ? 'blue' : 'red'} >{text?.toUpperCase()}</Tag>
					<Tag>{record.unit}</Tag>
				</>
			),
		},
		{
			title: t('billing.productprice.price') + ' (' + pricelist?.currency + ')',
			key: 'price',
			dataIndex: 'price',
			className: 'priceCell',
			render: renderPrice,
		},
		{
			title: 'Action',
			key: 'action',
			width: '100px',
			dataIndex: 'action',
			render: (text, record) => (
				<Space size={1}>
					{
						pricelist && isEditablePricelist(pricelist) &&
						<>
							<Button type='text' size='small'
								disabled={false}
								className='actionButton'
								onClick={(e) => productPricesUpdate(record)}
								icon={<EditTwoTone twoToneColor='green' />}
							/>
							<Button type='text' danger size='small'
									className='actionButton'
									onClick={() => showConfirmDelete(record, t('billing.productprice.confirm_delete'))}
									icon={<DeleteTwoTone twoToneColor='red'/>} />
						</>
					}
					{
						pricelist && !isEditablePricelist(pricelist) &&
						<>
							<Button type='text' size='small'
								className='actionButton'
								title={t('billing.productprice.pricelist_is_used')}
								disabled={true}
								icon={<EditTwoTone twoToneColor='gray' />}
							/>
							<Button type='text' danger size='small'
									className='actionButton'
									title={t('billing.productprice.pricelist_is_used')}
									disabled={true}
									icon={<DeleteTwoTone twoToneColor='gray'/>} />
						</>
					}
				</Space>
			),
		},
	]

	const isEditablePricelist = (pl) => {
		if (!isEditor) {
			// permissions
			return false
		}
		//if (pl && !pl.parent_id) {
			// default pricelist
			// return pl.source_id && pl.source_id > 0
		//}

		// derived pricelist
		return pricelist && pricelist.is_used === 0
	}


	return (
		<>
			<Card
				title={
					<>
						<Row>
							<Col span={12}>
								{t('billing.productprice.pricelist_id')}: <span style={{fontSize: '1.4rem'}}>{pricelist?.name}</span>&nbsp;&nbsp;
								{
									pricelist && pricelist.is_used === 1 &&
									<Tooltip title={t('billing.productprice.pricelist_is_used')}>
										<LockTwoTone twoToneColor='red' />
									</Tooltip>
								}
							</Col>
							<Col span={12} style={{verticalAlign: 'bottom', textAlign: 'center', marginTop: '8px'}}>
								{t('billing.productprice.valid')} {t('billing.productprice.from')} {appSetting.renderDate(pricelist?.valid_from) }&nbsp;
								{
									pricelist && pricelist.valid_to &&
									<>
										{t('billing.productprice.to')}&nbsp;{appSetting.renderDate(pricelist.valid_to)}
									</>
								}
							</Col>
						</Row>
					</>
				}
				extra={
					<>
						{
							pricelist && !isEditablePricelist(pricelist) &&
							<Button style={{marginTop: '10px'}} type='primary' size='small'
								disabled={!isEditor}
								onClick={() => {
									setProductPricesCopyVisible(true)
								}}>
								<CopyOutlined /> {t('billing.productprice.make_copy')}
							</Button>
						}

						{
							pricelist &&
							<Button style={{marginTop: '10px'}} type='primary' size='small'
								disabled={!isEditor || !!pricelist.is_used}
								onClick={() => {
									setProductPricesDataToUpdate(undefined)
									setProductPricesModalVisible(true)
								}}>
								<PlusCircleOutlined /> {t('billing.productprice.add')}
							</Button>
						}
					</>
				}
				className='ProductPricePage'
				loading={isLoading}>

				<Row gutter={[8, 8]}>
					<Col span={24}>
						<Table<ProductPriceDetails>
							rowClassName={() => 'highlight'}
							bordered={true}
							columns={columns}
							dataSource={dataSource}
							rowKey={(record) => `${record.id}`}
							pagination={false}
							loading={isLoading}
						/>
					</Col>
				</Row>

				<Row gutter={[8, 8]}>
					<Col span={24} style={{backgroundColor: '#ededed'}}>&nbsp;</Col>
				</Row>

				<Row gutter={[8, 8]}>
					<Col span={24}>
						<HistoryLog service='billing'
									model='Pricelist'
									modelId={historyModelId}
									forceUpdateId={forceUpdateId}
									isModal={false}
									showTitle={true} />
					</Col>
				</Row>

			</Card>

			{
				appSetting && pricelist &&
				<Modal
					destroyOnClose={true}
					style={{top: 20}}
					width={600}
					title={
						<>
							<EuroCircleOutlined/>{' '}
							{productprice ? t('billing.productprice.update') : t('billing.productprice.add')}
						</>
					}
					visible={isModalVisible}
					onCancel={() => setProductPricesModalVisible(false)}
					maskClosable={false}
					footer={null}>
					<ProductPriceForm
						dataToUpdate={dataProductPricesToUpdate}
						setModalVisible={setProductPricesModalVisible}
						updateHistory={() => {
							setForceUpdateId(forceUpdateId+1)
							setTimeout(refreshPricelist, 500)
						}}
					/>
				</Modal>
			}

			<Modal title={
				<>
					<CopyOutlined />{' '}
					{t('billing.productprice.copy_title')}
				</>
			}
				destroyOnClose={true}
				style={{top: 20}}
				width={600}
				visible={isProductPricesCopyVisible}
				onCancel={() => setProductPricesCopyVisible(false)}
				maskClosable={false}
				footer={null}>

				<div style={{marginTop: '15px', textAlign: 'center'}}>
					<Row>
						<Col span={12} style={{textAlign: 'right', padding: '4px'}}>
							<b>{t('billing.productprice.select_start_date')}</b>
						</Col>
						<Col span={12}>
							<DateInput htmlId='__start_date'
									   initDateStr={copyFromDate ? copyFromDate : ''}
									   disabled={false}
									   required={true}
									   minDateStr={lastInvoiceDate && lastInvoiceDate}
									   format={appSetting.date_picker_format} // not Moment formats!!
									   onChange={(date) => {
										   setCopyFromDate(appSetting.renderDateSQL(date))
									   }}
							/>
						</Col>
					</Row>

					<div style={{margin: '2rem 0'}}>
						<Space>
							<Button type='default' size='middle' onClick={() => setProductPricesCopyVisible(false)} >
								{t('general.cancel')}
							</Button>
							<Button type='primary' size='middle'
									loading={isSaving}
									onClick={handlePriceListCopy} >
								{t('billing.productprice.create_copy')}
							</Button>
						</Space>
					</div>
					<div style={{margin: '2rem 0', textAlign: 'center'}}>
						<Checkbox onChange={(e: CheckboxChangeEvent) => setShowNewPriceList(e.target.checked)} checked={true}>{t('billing.productprice.show_new_pricelist')}</Checkbox>
					</div>
				</div>
			</Modal>

			<Modal
				destroyOnClose={true}
				style={{top: 20}}
				width={600}
				title={
					<>
						<CopyOutlined />{' '}
						{t('billing.productprice.activate_title')}
					</>
				}
				visible={activateVisible}
				onCancel={() => setActivateVisible(false)}
				maskClosable={false}
				footer={null}>

				<Row>
					<Col style={{width: '100%'}}>
						<b>{t('billing.productprice.activate_help')}</b>
					</Col>
				</Row>
				<Row>
					<Col style={{width: '100%'}}>
						<div style={{margin: '2rem 0', textAlign: 'center'}}>
							<Space>
								<Button
									type='default'
									size='middle'
									onClick={() => setActivateVisible(false)}
								>
									{t('general.cancel')}
								</Button>
								<Button
									className='success'
									size='middle'
									loading={pricelistActivating}
									onClick={handlePriceListActivate}
								>
									{t('billing.productprice.activate')}
								</Button>
							</Space>
						</div>
					</Col>
				</Row>
			</Modal>

		</>
	)
}

export default ProductPricePage
