import {ActionType, UserActions} from './actions'
import {AsyncActionMode} from 'common/models'
import {UserDetails, UserState} from './models'
import {removeDiac} from "../../common/fce";

const INITIAL_STATE: UserState = {
  isSaving: false,
  isLoading: false,
  error: undefined,
  user: undefined,
  users: [],
  user_settings: [],
  user_settings_scope: '',
  last_seen: [],
  user_roles: [],
  user_groups: [],
  select_customer: [],
  usermail: undefined,
  allAuthentications: [],
  isLoadingAuthentications: false,
  authentications: [],
  isSavingAuthentication: false,
  verifications: [],
  auth_2fa_ips: [],
  response2fa: undefined
}

export default (
    state: UserState = INITIAL_STATE,
    action: UserActions,
): UserState => {

  function processUsers(data: UserDetails[]): UserDetails[] {
    if (data && data.length > 0) {
      return data.map(u => {
        u.searchableName = removeDiac(`${u.username}|${u.email}|${u.title}|${u.phone}`)
        return u
      })
    }
    return []
  }

  switch (action.type) {
    case ActionType.ALL_USERS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          users: processUsers(action.data),
          isLoading: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_LAST_SEEN:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          last_seen: action.data,
          isLoading: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break

    case ActionType.CREATE_USER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          user: action.data,
          users: state.users.concat(action.data)
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_USER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        const newOne = action.data
        const oldOne = state.users.find((u) => u.id === action.data.id)
        if (oldOne) {
          newOne.roles = oldOne.roles
          newOne.groups = oldOne.groups
        }
        return {
          ...state,
          isSaving: false,
          user: action.data,
          users: state.users
              ? state.users.map((user) =>
                  user.id === action.data.id ? newOne : user,
              )
              : [newOne]
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.PROFILE_USER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          user: action.data
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_USER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          users: state.users.map((user) =>
                  user.id === action.data.id ? {...user, deleted_at: action.data.deleted_at} : user,
              ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.RESET_ALL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.CHANGE_PASSWORD:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          response2fa: {reason: 'change-password'},
          isLoading: true };
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          response2fa: action.data,
        };
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
      }
      break;
    case ActionType.CHANGE_PASSWORD_2FA_CONFIRM:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          isLoading: true };
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          response2fa: {reason: 'change-password-confirmed'},
        };
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
      }
      break;

    case ActionType.GET_USER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true, authentications: [] }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          error: undefined,
          user: action.data,
          users: state.users.map((user) =>
                  user.id === action.data.id ? action.data : user,
              ),
          isLoadingAuthentications: false,
          authentications: (action.data.userAuthentications && action.data.userAuthentications.length) ? action.data.userAuthentications : [],
          verifications: (action.data.userVerifications && action.data.userVerifications.length) ? action.data.userVerifications : [],
          auth_2fa_ips: (action.data.auth_2fa_ips && action.data.auth_2fa_ips.length) ? action.data.auth_2fa_ips : []
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_USER_GROUPS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          error: undefined,
          user: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_USER_SETTINGS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, user_settings_scope: 'user', isLoading: false }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          error: undefined,
          user_settings: action.data,
          user_settings_scope: 'user'
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
    case ActionType.GET_CUSTOMER_SETTINGS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, user_settings_scope: 'customer', isLoading: false }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          error: undefined,
          user_settings: action.data,
          user_settings_scope: 'customer'
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
    case ActionType.GET_APP_SETTINGS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, user_settings_scope: 'app', isLoading: false }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          error: undefined,
          user_settings: action.data,
          user_settings_scope: 'app'
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break

    case ActionType.UPDATE_USER_SETTINGS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          user_settings: state.user_settings.map((s) =>
              s.id === action.data.id ? action.data : s,
          )
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_CUSTOMER_SETTINGS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          user_settings: state.user_settings.map((s) =>
              s.id === action.data.id ? action.data : s,
          )
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_APP_SETTINGS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          user_settings: state.user_settings.map((s) =>
              s.id === action.data.id ? action.data : s,
          )
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_USER_ROLES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return {...state, isSaving: true}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          error: undefined,
          user_roles: action.data
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error
        }
      }
      break
    case ActionType.SEND_WELCOME_EMAIL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_USER_MAIL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return {...state, isSaving: true}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state, usermail: action.data, isSaving: false
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {...state, isSaving: false, error: action.error}
      }
      break
    case ActionType.ALL_AUTHENTICATIONS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoadingAuthentications: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          allAuthentications: action.data,
          isLoadingAuthentications: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingAuthentications: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_USER_AUTHENTICATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingAuthentication: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSavingAuthentication: false,
          authentications: state.authentications.map(x => x).concat(action.data)
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingAuthentication: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_USER_AUTHENTICATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingAuthentication: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSavingAuthentication: false,
          authentications: state.authentications
              ? state.authentications.map((ua) =>
                  ua.id === action.data.id ? action.data : ua,
              )
              : [action.data]
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingAuthentication: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_USER_AUTHENTICATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingAuthentication: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSavingAuthentication: false,
          authentications: state.authentications.filter((ua) => ua.id != action.id)
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingAuthentication: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_USER_AUTH_LOG:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          auth_2fa_ips: state.auth_2fa_ips.filter((rec) => rec.id != action.id)
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_USER_AUTHENTICATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          authentications: state.authentications.concat(action.data),
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break

    case ActionType.REQUEST_VERIFICATION_CODE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          verification: action.data
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
    case ActionType.CONFIRM_VERIFICATION_CODE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          verification: action.data,
          verifications: action.data.is_verified === 1 ? state.verifications.concat(action.data) : state.verifications
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          verification: state.verification ? {...state.verification, is_verified: 0} : undefined,
          error: action.error,
        }
      }
      break
  }
  return state
}
