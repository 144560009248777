import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {HistoryDetails} from "./models";
import moment from "moment";
import {UserOutlined} from "@ant-design/icons";
import {Card, Col, Row, Table} from "antd";
import {AppState} from "../../common/models";
import getHistory from "./actions/getHistory";
import {useTranslation} from "react-i18next";
import './History.scss'

interface Props {
    url: string
    id?: number
    tiny?: boolean
    historyName: string
    showTitle?: boolean
    scrollY?: string
}

const sort_id = (a, b) => {if(!a) return -1; if(!b) return 1;  return (a.id > b.id) ? -1 : 1;}

const History2 = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    let name = props.historyName
    if (props.id) {
        name = props.historyName + '-' + props.id
    }
    let tiny = false
    if (props.tiny) {
        tiny = props.tiny
    }
    const history = useSelector((state: AppState) => state.historylog.historyLog)
    const [dataSource, setDataSource] = useState<HistoryDetails[]>([])
    const [historyName, setHistoryName] = useState<string>(name)

    useEffect(() => {
        dispatch(getHistory(props.url))
    }, [historyName])

    useEffect(() => {
        if (history) {
            const filtered = history.sort(sort_id)
            setDataSource(filtered)
        }
    }, [history])

    const columns: ColumnsType<HistoryDetails> = [
        {
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'histTD',
            render: (text, rec) => {
                if (tiny) {
                    return (
                        <Row className='historyGrid'>
                            <Col>
                                <span style={{cursor: 'hand'}} title={moment.unix(rec.created_at).format('YYYY-MM-DD HH:mm')} >
                                    {moment.unix(rec.created_at).format('YYYY-MM-DD')}|
                                </span>
                            </Col>
                            <Col>
                                <span style={{cursor: 'hand'}} title={`${rec.userName} from IP: ${rec.remote_ip}`} >
                                    &nbsp;<UserOutlined style={{cursor: 'hand'}} />&nbsp;{`${rec.operation}`}
                                </span>
                            </Col>
                        </Row>
                    )
                }
                return (
                    <Row className='historyGrid'>
                        <Col>{moment.unix(rec.created_at).format('YYYY-MM-DD HH:mm')}|</Col>
                        <Col><span title={'IP:'+rec.remote_ip}>{`${rec.userName}`}</span>|</Col>
                        <Col><span title={rec.operation}>{`${rec.operation}`.substring(0, 565)}</span></Col>
					</Row>
                )
            },
        }
    ]

    return (
        <Card title={ props.showTitle && t('history')} className='historyCard'>
            <Table<HistoryDetails>
                columns={columns}
                style={{minHeight: 100}}
                dataSource={dataSource}
                pagination={false}
                rowKey={(record) => record.id}
                size='small'
                scroll={{ x: '3000px', y: props.scrollY && props.scrollY }}
                showHeader={false}
            />
        </Card>
    )
}

export default History2
