import {
    RemoveResGroupInGroupRequestAction,
    RemoveResGroupInGroupErrorAction,
    RemoveResGroupInGroupResponseAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {UserGroupModel, AddDeleteResGroupToGroupParams} from '../models'


export default (
    params: AddDeleteResGroupToGroupParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | RemoveResGroupInGroupRequestAction
                | RemoveResGroupInGroupErrorAction
                | RemoveResGroupInGroupResponseAction,
        ) => void,
    ) => {
        const request = new RemoveResGroupInGroupRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<UserGroupModel>(`/user-group/${params.id}/remove-resource-group`, params)
            .then((response) => {
                dispatch(new RemoveResGroupInGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new RemoveResGroupInGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
