import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    GetLogRecordRequestAction,
    GetLogRecordResponseAction,
    GetLogRecordErrorAction,
} from '.'
import { LogRecord } from '../models'

export default (lid: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetLogRecordRequestAction
                | GetLogRecordResponseAction
                | GetLogRecordErrorAction,
        ) => void,
    ) => {
        const request = new GetLogRecordRequestAction()
        dispatch(request)

        protectedApiClient
            .get<LogRecord>('/log/'+lid)
            .then((response) => {
                dispatch(
                    new GetLogRecordResponseAction(request, response.data),
                )
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetLogRecordErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
