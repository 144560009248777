import React, {useEffect, useState} from 'react'
import {Button, Form, Input, message, Select, Spin} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {CreateGroupParams, UpdateGroupParams, UserGroupModel} from "./models";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {sort_label} from "../../../common/sorting";
import createGroup from "./actions/createGroup";
import updateGroup from "./actions/updateGroup";


interface Props {
    setModalVisible: (param: boolean) => void
    customerId: number
    parentId: number
    group?: UserGroupModel
}

const {Item} = Form
const REG_USER_GROUP = new RegExp('^[A-Za-z0-9-._ ]{2,28}$')        // we dont use it for UserGroup

const NewUserGroupForm = ({setModalVisible, parentId, customerId, group}: Props) => {

    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {isLoading, isSaving} = useSelector((state: AppState) => state.groupsPage)
    const {customers} = useSelector((state: AppState) => state.auth.tables)

    const [isValid, setValid] = useState<boolean>(false)
    const [title, setTitle] = useState<string>('')
    const [custOptions, setCustOptions] = useState<{ label: string, value: number }[]>([])
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // init groupKey for editing
        group && setTitle(group.title)
    }, [])

    useEffect(() => {
        if (customers && customers.length) {
            if ( loggedUser.user.is_zcom ) {
                const items = customers.map(c => ({label: c.company!.name ? c.company!.name : '', value: c.id}))
                setCustOptions(items.sort(sort_label))
            }
        }
    }, [customers])

    const onFormSubmit = (values: Store) => {
        // save data
        if (group && group.id) {
            // edit
            const params: UpdateGroupParams = {id: group.id, title: title}
            dispatch(updateGroup(params, (isSuccess: boolean) => {
                    if (isSuccess) {
                        setModalVisible(false)
                        message.success(t('general.success'))
                    }
                }
            ))
        }
        else {
            // insert
            const params: CreateGroupParams = {title: title, parent_id: parentId, customer_id: customerId}
            dispatch(createGroup(params, (isSuccess: boolean) => {
                    if (isSuccess) {
                        setModalVisible(false)
                        message.success(t('general.success'))
                    }
                }
            ))
        }
    }

    return (
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={group ? {...group} : {customer_id: customerId}}
        >
            {
                loggedUser &&
                loggedUser.user.is_zcom &&
                <Item
                    name='customer_id'
                    label={t('rights.userGroups.customer_id')}
                    rules={[{ required: true, message: t('rights.userGroups.err_customer') }]}
                >
                    <Select style={{display: 'block'}}
                        disabled={true}
                        optionFilterProp='label'
                        options={custOptions}
                    />
                </Item>
            }

            <Item name='title' label={t('rights.userGroups.title')}
                  rules={[
                      {required: true, message: t('errors.field_required')},
                  ]}
                  hasFeedback
            >
                <Input placeholder='User Group' autoFocus
                       onChange={(e) => setTitle(e.target.value)} />
            </Item>

            <Item {...tailLayout}>
                <Button loading={isSaving} type='primary' htmlType='submit' disabled={!updateStatus}>
                    { group ? t('general.save') : t('general.create')}
                </Button>
            </Item>

        </Form>
    )
}

export default NewUserGroupForm
