import React from 'react'
import {Col, Row, Space, message, Card, Select, Input, Divider, InputNumber} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'common/models'
import {Link, RouteComponentProps, useParams} from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import Button from 'antd-button-color'
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { formItemLayout } from 'helpers/layoutHelpers'
import createBill from './actions/createBill'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import {FileDoneOutlined} from "@ant-design/icons";
import {useHistory} from "react-router";
import {CompanyDetails} from "../../company/models";
import getCountries from "../../../components/SelectCountries/actions/getCountries";
import {sort_label, sort_name} from "../../../common/sorting";
import DateInput from "../../../components/RangeFilter/DateInput";
import {_parsePrice, decEn, removeDiac, SQL_DATE_FORMAT} from "../../../common/fce";
import AlertBox from "../../../components/popconfirm/AlertBox";
import {BillModel} from "./models";
import tableCompanies from "../../login/actions/tableCompanies";

const CreateBillPage = (props: RouteComponentProps) => {
    const {t} = useTranslation()
    const [form] = useForm()
    const dispatch = useDispatch()
    const history = useHistory()

    const {companies} = useSelector((state: AppState) => state.auth.tables)
    const {countries} = useSelector((state: AppState) => state.countries)
    const {currencies} = useSelector((state: AppState) => state.currencies)
    const {isLoading, isSaving, bills} = useSelector((state: AppState) => state.bill)

    const [currentCompany, setCurrentCompany] = useState<CompanyDetails | undefined>(undefined)
    const [currentCurrency, setCurrentCurrency] = useState<string>('CZK')
    const [openModalWarning1, setOpenModalWarning1] = useState(false)
    const [showWarningPopup, setShowWarningPopup] = useState(false)
    const [docExistsText, setDocExistsText] = useState('')
    const [docExistsId, setDocExistsId] = useState(0)


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator/>
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('CreateBillPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('CreateBillPage: ' + msg)} }

    useEffect(() => {
        if (!countries) {
            dispatch(getCountries())
            return
        }
        if (!companies || companies.length===0) {
            dispatch(tableCompanies())
        }
        if (!bills || bills.items.length===0) {
            // load bills if not loaded
            loadBills()
        }
    }, [countries])

    useEffect(() => {
        if (currentCompany) {
            const c = countries.find(c => c.iso === currentCompany.country)
            if (c) {
                form.setFieldsValue( {"currency": c.currency})
                setCurrentCurrency(c.currency)
            }
            else {
                form.setFieldsValue( {"currency": undefined})
                setCurrentCurrency('CZK')
            }
        }
    }, [currentCompany])

    const loadBills = () => {
        // load time data of bills
        if (isLoading) {
            return
        }
        const current = moment().startOf("day")
        const dateFrom = moment(current).subtract(1,'months').startOf('year');
        const dateTo = moment(current).endOf('year');
        let qs: string[] = []
        qs.push('taxing_from=' + dateFrom.format(SQL_DATE_FORMAT))
        qs.push('taxing_to=' + dateTo.format(SQL_DATE_FORMAT))
        logger('loadBills: '+qs.join("&"))
        //dispatch(getBillls(qs.join("&"), suc => {}))
    }

    const onChangeCompany = (value: number, option: any) => {
        const c = companies.find(c => c.id === value)
        setCurrentCompany(c)
    }

    const submitForm = () => {
        if (form) {
            form.submit()
        }
    }

    const getExistingDoc = (companyId: number, docNumber: string): BillModel | undefined => {
        const num = docNumber.toLowerCase().trim().replace(/[^\w\s-]/g, '')
        const myBills = bills.items.filter(doc => doc.company_id === companyId)
        for (const myBill of myBills) {
            if (myBill.number && myBill.number.trim() === num) {
                return myBill
            }
        }
        return undefined
    }

    const handleSubmit = (values) => {
        const doc = getExistingDoc(values.company_id, values.number)
        if (doc) {
            setDocExistsText(t('billing.bill.doc_exists_text') + ': ' + currentCompany?.name)
            setDocExistsId(doc.id)
            setShowWarningPopup(true)
            setOpenModalWarning1(true)
            return
        }

        const params = {
            ...values,
            number: values.number.trim().replace(/[^\w\s-]/g, ''),
            date_of_issue: appSetting.renderDateSQL(values.date_of_issue),
            date_of_maturity: appSetting.renderDateSQL(values.date_of_maturity),
            date_of_taxing: appSetting.renderDateSQL(values.date_of_taxing),
            total_without_vat: 0,
            total_vat: 0,
            total_with_vat: 0,
            amount_due: 0
        }
        logger('saveBill: ' + JSON.stringify(params))
        dispatch(createBill(params, (suc, bill_id) => {
                if (suc && bill_id) {
                    message.success(t('general.success'))
                    history.push('/billing/bill/edit/'+bill_id)
                }
            }
        ))
    }

    const initForm = () => {
        return {
            number: '',
            date_of_issue: moment(),
            date_of_maturity: moment(),
            date_of_taxing: moment(),
            currency: 'CZK',
            total_vat: 0,
            total_with_vat: 0,
            total_without_vat: 0,
            amount_due: 0
        }
    }

    const handleFormatter = e => {
        let v = decEn(`${e.target.value}`)
        let value = appSetting.renderPrice(_parsePrice(v))
        form.setFieldsValue({'amount_due': decEn(value)})
    }

    const getCompanyOptions = () => {
        return companies.sort(sort_name).map(i => ({
            value: i.id,
            key: i.id,
            label: i.name
        }))
    }

    return (
        <>
            <Card className='CreateBillPage'
                title={
                    <Row>
                        <Col span={3}>
                            <FileDoneOutlined /> &nbsp; {t('billing.bill.create-page')}
                        </Col>
                        <Col span={21}>&nbsp;</Col>
                    </Row>
                }
                loading={isLoading}
            >

                <Row style={{padding: '24px'}} >
                    <Col span={24}>
                        <Form
                            form={form}
                            {...formItemLayout}
                            className='Bill_Form'
                            preserve={false}
                            initialValues={initForm()}
                            onFinish={handleSubmit}
                        >

                            <Row gutter={[8, 8]}>
                                <Col span={10}>
                                    <Form.Item name='company_id' rules={[{required: true}]} label={t('billing.bill.company_id')}>
                                        <Select
                                            showSearch
                                            filterOption={(val, opt) => {
                                                return !!opt && removeDiac(opt.label).includes(removeDiac(val))
                                            }}
                                            optionFilterProp='label'
                                            options={getCompanyOptions()}
                                            placeholder={t('billing.bill.select-company')}
                                            onChange={onChangeCompany}>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name='number'
                                               rules={[{required: true}]}
                                               label={t('billing.bill.number')}>
                                        <Input type='text' onChange={(v) => {
                                            setShowWarningPopup(false)
                                        }}/>
                                    </Form.Item>

                                    <Form.Item name='currency'
                                               rules={[{required: true}]}
                                               label={t('billing.bill.currency')}>
                                        <Select className='Bill_Form_CurrencySelect'
                                            options={currencies.map(c => ({
                                                value: c,
                                                key: c,
                                                label: c
                                            })).sort(sort_label)}
                                        >
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name='date_of_issue'
                                               rules={[{required: true}]}
                                               label={t('billing.bill.date_of_issue')}>
                                        <DateInput htmlId='__date_of_issue'
                                                   initDateStr={appSetting.renderDateSQL(moment().toDate())}
                                                   disabled={false}
                                                   required={true}
                                                   format={appSetting.date_picker_format} // not Moment formats!!
                                                   onChange={(date) => {
                                                       date && form.setFieldsValue({date_of_maturity: appSetting.renderDateSQL(date)})
                                                   }}
                                        />
                                    </Form.Item>

                                    <Form.Item name='date_of_maturity'
                                               rules={[{required: true}]}
                                               label={t('billing.bill.date_of_maturity')}>
                                        <DateInput htmlId='__date_of_maturity'
                                                   initDateStr={appSetting.renderDateSQL(moment().toDate())}
                                                   disabled={false}
                                                   required={true}
                                                   format={appSetting.date_picker_format} // not Moment formats!!
                                                   onChange={(date) => {
                                                       date && form.setFieldsValue({date_of_maturity: appSetting.renderDateSQL(date)})
                                                   }}
                                        />
                                    </Form.Item>

                                    <Form.Item name='date_of_taxing' rules={[{required: true}]} label={t('billing.bill.date_of_taxing')}>
                                        <DateInput htmlId='__date_of_taxing'
                                                   initDateStr={appSetting.renderDateSQL(moment().toDate())}
                                                   disabled={false}
                                                   required={true}
                                                   format={appSetting.date_picker_format} // not Moment formats!!
                                                   onChange={(date) => {
                                                       date && form.setFieldsValue({date_of_taxing: appSetting.renderDateSQL(date)})
                                                   }}
                                        />
                                    </Form.Item>

                                    <Divider/>

                                    <Row >
                                        <Col span={8} className='center'>
                                            <Link to='/billing/bill'>
                                                <Button type='default'>{t('general.back')}</Button>
                                            </Link>
                                        </Col>
                                        <Col span={16}>
                                            <AlertBox title={t('billing.bill.doc_exists_header')}
                                                      text={docExistsText}
                                                      openModal={openModalWarning1}
                                                      setOpenModal={setOpenModalWarning1}
                                                      onConfirm={() => {history.push('/billing/bill/edit/'+docExistsId)}}
                                                      okText='zobrazit doklad'
                                                      condition={showWarningPopup}>
                                            <Button onClick={() => submitForm()} loading={isSaving} type='success'>{t('billing.bill.update')}</Button>
                                            </AlertBox>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={14}>

                                </Col>
                            </Row>
                        </Form>
                    </Col>



                </Row>
            </Card>
        </>
    )
}

export default CreateBillPage
