import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {LookupBillErrorAction, LookupBillRequestAction, LookupBillResponseAction} from "./index";


export default (field: string, qs: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: LookupBillRequestAction | LookupBillResponseAction | LookupBillErrorAction,
        ) => void,
    ) => {
        const request = new LookupBillRequestAction(field)
        dispatch(request)
        let url = `/billing/bill/lookup?`+qs
        protectedApiClient
            .get<string[]>(url)
            .then((response) => {
                dispatch(new LookupBillResponseAction(request, field, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new LookupBillErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
