import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CreateDomainParam, DomainModel} from '../models'
import {CreateDomainErrorAction, CreateDomainRequestAction, CreateDomainResponseAction} from '.';


export default (
    params: CreateDomainParam,
    cb?: (isSuccess: boolean) => void
) => {
    return (
        dispatch: (
            arg:
                | CreateDomainRequestAction
                | CreateDomainResponseAction
                | CreateDomainErrorAction
        ) => void
    ) => {
        const request = new CreateDomainRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<DomainModel>(`/customer/${params.id}/domain?expand=services`, params)
            .then((response) => {
                dispatch(new CreateDomainResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new CreateDomainErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
