import {Col, Row} from "antd";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import logout from "../../pages/login/actions/logout";

interface Props {
    logoutLimit: number
    showClock: number
}

const TodayTime = (props: Props) => {
    const dispatch = useDispatch()
    const {last_update, authenticated, self_customer, self_settings} = useSelector((state: AppState) => state.auth)
    const [time, setTime] = useState<string>('')
    const [counter, setCounter] = useState<number>(0)
    const [className, setClassName] = useState<string>('')
    const format = 'H:mm:ss'

    const isReady = () => {
        return Boolean(authenticated && self && self_customer && self_settings && self_settings.length > 0)
    }

    const tick = () => {
        if (isReady() && last_update && props.logoutLimit > 0) {
            const current_update = new Date().getTime()
            const diff = Math.round((current_update - last_update) / 1000)

            // console.log('last_update=' + last_update + ', diff='+diff + ', logoutLimit='+props.logoutLimit)
            if ((props.logoutLimit*60 - diff) < 60) {
                // last minute
                setClassName('todayTimeRed')
            }
            else {
                setClassName('todayTime')
            }
            if (diff > props.logoutLimit * 60) {
                console.log('Odhlaseno: last_update=' + last_update + ', diff='+diff + ', logoutLimit='+props.logoutLimit)
                dispatch(logout())
                return
            }
        }
        setTime(moment().format(format))
    }

    useEffect(() => {
        // increment counter every second
        const interval = setInterval(() => {
            setCounter((counter) => counter + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // handle counter changes
        tick()
    }, [counter]);


    if (isReady()) {
        return (
            <Row>
                <Col span={24}>
                    &nbsp;<span className={className} title={className === '' ? 'One minute to logout' : ''}>
                    { props.showClock ? time : '' }
                </span>
                </Col>
            </Row>
        )
    }
    else {
        return (
            <Row>
                <Col span={24}><span className={className}>&nbsp;</span></Col>
            </Row>
        )
    }

}

export default TodayTime

