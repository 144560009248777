import { ActionType, BillAttachmentActions } from './actions'
import { AsyncActionMode } from 'common/models'
import {BillAttachmentDetails, BillAttachmentState} from './models'

const BILLATTACHMENT_INITIAL_STATE: BillAttachmentState = {
    // isLoading: false,
    isSaving: false,
    error: undefined,
    billattachments: []
}

export default (
    state = BILLATTACHMENT_INITIAL_STATE,
    action: BillAttachmentActions,
): BillAttachmentState => {
    switch (action.type) {
        case ActionType.GET_BILL_ATTACHMENT:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    billattachments:
                        state.billattachments && state.billattachments.length
                            ? state.billattachments.concat(action.data)
                            : [action.data],
                    isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break

        case ActionType.CREATE_BILL_ATTACHMENT:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    billattachments: state.billattachments?.concat(action.data),
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_BILL_ATTACHMENTS:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoading: false,
                    billattachments: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break
        case ActionType.DELETE_BILL_ATTACHMENT:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    billattachments: state.billattachments!.filter(
                        (billattachment) => billattachment.id !== action.request.id,
                    ),
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break
    }
    return state
}
