import {
    RemoveRoleInGroupRequestAction,
    RemoveRoleInGroupErrorAction,
    RemoveRoleInGroupResponseAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {UserGroupModel, AddDeleteRoleToGroupParams} from '../models'


export default (
    params: AddDeleteRoleToGroupParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | RemoveRoleInGroupRequestAction
                | RemoveRoleInGroupErrorAction
                | RemoveRoleInGroupResponseAction,
        ) => void,
    ) => {
        const request = new RemoveRoleInGroupRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<UserGroupModel>(`/user-group/${params.id}/remove-role`, params)
            .then((response) => {
                dispatch(new RemoveRoleInGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new RemoveRoleInGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
