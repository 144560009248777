import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useLocation} from "react-router-dom";
import {
    AutoComplete,
    Badge,
    Button,
    Card, Col,
    message,
    Modal,
    Row,
    Space,
    Spin, Switch,
    Table,
    TableColumnsType, Tag,
    Tooltip
} from "antd";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import useLogger from "../../../common/useLogger";
import usePageSize from "../../../common/usePageSize";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {AppState} from "../../../common/models";
import {_isNotEmptyObject, stopPropagation} from "../../../common/fce";
import queryString, {ParsedQuery} from "query-string";
import {IpAddressModel, IpSubnetModel} from "./models";
import {sort_label, sort_num, sort_str} from "../../../common/sorting";
import {
    DeleteTwoTone,
    EditTwoTone, ExclamationCircleOutlined,
    InfoCircleTwoTone,
    PartitionOutlined,
} from "@ant-design/icons";
import {ApartmentOutlined, CheckOutlined, CloseOutlined, PlusCircleOutlined} from "@ant-design/icons/lib/icons";
import Pager from "../../../components/pagination/pager";
import getIpSubnet from "./actions/getIpSubnet";
import HistoryModal from "../../../components/History/HistoryModal";
import {ColumnsType} from "antd/es/table";
import deleteIpSubnet from "./actions/deleteIpSubnet";
import getIpSubnets from "./actions/getIpSubnets";
import NewSubnetForm from "./NewSubnetForm";
import './SubnetsPage.scss'
import lookupSubnet from "./actions/lookupSubnet";
import tableCustomers from "../../login/actions/tableCustomers";
import EditSubnetForm from "./EditSubnetForm";
import {isSubnetExternal} from "./ip_common";

const { confirm } = Modal


const SubnetsPage = () => {
    const CONTROL_NAME = 'page_subnet'
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {search} = useLocation()
    const history = useHistory()

    const {customers, customerNames} = useSelector((state: AppState) => state.auth.tables)
    const {ip_subnets} = useSelector((state: AppState) => state.sidenav)
    const {subnets, pager, isLoadingSubnets, lookup_subnet_cidr, lookup_subnet_note, lookup_subnet_reverse, isLoadingLookup, ip, ips} = useSelector((state: AppState) => state.ipSubnet)

    // options
    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: string }[]>([])
    const [noteOptions, setNoteOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [cidrOptions, setCidrOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [reverseOptions, setReverseOptions] = useState<{ label: string | ReactNode, value: string }[]>([])

    // data
    const [dataSource, setDataSource] = useState<IpSubnetModel[]>([])
    const [searchableSubnets, setSearchableSubnets] = useState<IpSubnetModel[]>([])
    const [dataSourceIp, setDataSourceIp] = useState<IpAddressModel[]>()
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [selectedSubnetId, setSelectedSubnetId] = useState(0)
    const [qsFilter, setQsFilter] = useState<string>('')
    const [checkedInternal, setCheckedInternal] = useState<boolean>(true)
    const [checkedPublic, setCheckedPublic] = useState<boolean>(true)

    // filters
    const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
    const [iid, setIid] = useState(0)
    const [searchNoteLookup, setSearchNoteLookup] = useState<string>('')
    const [searchNote, setSearchNote] = useState<string>('')
    const [searchCidrLookup, setSearchCidrLookup] = useState<string>('')
    const [searchCidr, setSearchCidr] = useState<string>('')
    const [searchReverseLookup, setSearchReverseLookup] = useState<string>('')
    const [searchReverse, setSearchReverse] = useState<string>('')

    const [searchCustomer, setSearchCustomer] = useState<string>('')
    const [searchCustomerId, setSearchCustomerId] = useState<number | undefined>(undefined)
    const [ready, setReady] = useState<number>(0)

    // page
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [isModalNewVisible, setModalNewVisible] = useState(false)
    const [isModalEditVisible, setModalEditVisible] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    // permissions
    const [isViewer, setViewer] = useState(false)
    const [isSubnetCreator, setSubnetCreator] = useState(false)
    const [isSubnetEditor, setSubnetEditor] = useState(false)
    const [isSubnetDeleter, setSubnetDeleter] = useState(false)


    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'SubnetsPage')
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    // to handle SideNav menu pressed after 1sec
    const [triggerOneTimer, setTriggerOneTimer] = useState(0)

    useEffect(() => {
        // trigger ONCE
        setTriggerOneTimer(Date.now().valueOf())
        logger('=== ONCE  url='+search)
        setPageSize(appSetting.grid_page_size)

        setViewer(loggedUser.hasAccess('page_subnet'))
        setSubnetCreator(loggedUser.hasAccess('page_subnet_create_button'))
        setSubnetEditor(loggedUser.hasAccess('page_subnet_edit_button'))
        setSubnetDeleter(loggedUser.hasAccess('page_subnet_delete_button'))

        // load Options for refresh F5
        loadOptions()
        handleQueryStringFilters()
    }, [])

    useEffect(() => {
        // handle a single item by ID
        logger('=== IID '+iid)

    }, [iid])

    useEffect(() => {
        // SideNav menu pressed
        const when = triggerOneTimer + 1000
        if (triggerOneTimer > 0 && when < Date.now().valueOf()) {
            logger('--- SideNav menu pressed')
            clearFilters()
            fetchRecords(1, pageSize)
        }
    }, [ip_subnets])

    useEffect(() => {
        // check if Ready
        if (customers && customers.length) {
            logger(`customers=${customers.length}`)
            const data = customers.map(c => ({value: `${c.id}`, label: getCustomerName(c.id)}))
            setCustomerOptions(data.sort(sort_label))
            setReady(ready+1)
        }
    }, [customers, customerNames])

    useEffect(() => {
        // process Options
        if (ready) {
            logger(`Ready=${ready} iid=${iid}`)
            fetchRecords(pageNumber, pageSize)
        }
    }, [ready])

    useEffect(() => {
        // populate numberOptions
        setCidrOptions(lookup_subnet_cidr.map(s => ({label: s, value: s})))
    }, [lookup_subnet_cidr])

    useEffect(() => {
        // populate noteOptions
        setNoteOptions(lookup_subnet_note.map(s => ({label: s, value: s})))
    }, [lookup_subnet_note])


    useEffect(() => {
        // populate reverseOptions
        setReverseOptions(lookup_subnet_reverse.map(s => ({label: s, value: s})))
    }, [lookup_subnet_reverse])

    useEffect(() => {
        replaceHistory()
    }, [pageSize, pageNumber, qsFilter])

    useEffect(() => {
        // update QsFilter
        const qs: string = prepareQsFilter()
        logger(`qsFilter:${qs}`)
        if (qs != qsFilter) {
            logger(`qsFilter2:${qs}`)
            setQsFilter(qs)
        }
    }, [searchCustomerId, searchCidr, searchNote, searchReverse, searchCustomer, checkedInternal, checkedPublic])

    useEffect(() => {
        // when filter is changed
        // show the first page
        logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
        let pn = pageNumber
        if (ready) {    // do not change page for F5
            pn = 1
        }
        if( !checkedInternal && !checkedPublic ) {
            message.error('You must choose Internal or External or All!')
            setDataSource([])
            return
        }
        else {
            fetchRecords(pn, pageSize)
        }
    }, [qsFilter])

    useEffect(() => {
        // process new server-data
        if (!ready) {
            logger(`ready:false`)
            return
        }
        if (!customerNames) {
            logger(`customerNames:false`)
            return
        }
        if (subnets.items && filterIsValid()) {
            const data = subnets.items.filter(s => s.id > 2).map(item => {
                return {
                    ...item,
                    customer_name: customerNames!.get(item.customer_id),
                    searchableName: item.cidr
                }
            })
            setSearchableSubnets(data)
        }
    }, [subnets.items, customerNames])

    useEffect(() => {
        // render data
        if (!ready) {
            return
        }
        if (filterIsValid()) {
            refreshGrid()
        }
    }, [searchableSubnets])



    // ------------------------------ end hooks

    const loadOptions = () => {
        // load Options for refresh F5
        if (!customers || customers.length === 0) {
            dispatch(tableCustomers())
        }
    }

    const getCustomerName = (cid) => {
        if (customerNames) {
            let name = customerNames!.get(cid)
            return name ? name : `cust.${cid}`
        }
        return `err.${cid}`
    }

    const replaceHistory = () => {
        if (filterIsValid()) {
            const url = `/ip/subnets?pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}&` + qsFilter
            history.replace(url)
        }
    }

    const filterIsValid = (): boolean => {
        // no required fields
        return true
    }

    const checkMinSearch = (val: string | undefined) => {
        if (!val) {
            return false
        }
        return val.length > appSetting.min_search_length
    }

    const fetchRecords = (pn: number, ps: number) => {
        if (!isViewer) {
            return
        }
        setPageNumber(pn)
        setPageSize(ps)
        if (ready && filterIsValid()) {
            // purchase_from is required
            logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
            dispatch(getIpSubnets(ps, pn - 1, qsFilter, suc => {}))
        }
    }


    const showConfirmDelete = (s: IpSubnetModel, title: string) => {
        if (!s) {
            return
        }

        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{s.cidr}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteIpSubnet(s.id,suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const fetchCidrLookup = (searchText: string) => {
        // call lookup for cidr
        searchText = searchText.trim()
        logger('fetchCidrLookup: '+searchText)
        if (checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (checkMinSearch(searchNote)) {
                qs.push('note=' + encodeURIComponent(searchNote))
            }
            if (checkMinSearch(searchReverse)) {
                qs.push('reverse_domain=' + encodeURIComponent(searchReverse))
            }
            if (checkMinSearch(searchText)) {
                qs.push('field=cidr')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupSubnet: ' + qs.join("&"))
                dispatch(lookupSubnet('cidr', qs.join("&")))
            }
        }
    }

    const fetchNoteLookup = (searchText: string) => {
        // call lookup for note
        searchText = searchText.trim()
        if (checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (checkMinSearch(searchCidr)) {
                qs.push('cidr=' + encodeURIComponent(searchCidr))
            }
            if (checkMinSearch(searchReverse)) {
                qs.push('reverse_domain=' + encodeURIComponent(searchReverse))
            }
            if (checkMinSearch(searchText)) {
                qs.push('field=note')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupSubnet: ' + qs.join("&"))
                dispatch(lookupSubnet('note', qs.join("&")))
            }
        }
    }

    const fetchReverseLookup = (searchText: string) => {
        // call lookup for Reverse
        searchText = searchText.trim()
        if (checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (checkMinSearch(searchCidr)) {
                qs.push('cidr=' + encodeURIComponent(searchCidr))
            }
            if (checkMinSearch(searchNote)) {
                qs.push('note=' + encodeURIComponent(searchNote))
            }
            if (checkMinSearch(searchText)) {
                qs.push('field=reverse_domain')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupSubnet: ' + qs.join("&"))
                dispatch(lookupSubnet('reverse_domain', qs.join("&")))
            }
        }
    }

    const refreshGrid = () => {
        logger('refreshGrid '+iid)
        setDataSource(searchableSubnets)  // .sort((a, b) => sort_str(a.searchableName, b.searchableName)))
    }

    const clearFilters = () => {
        // used when clicked on left menu
        logger('clearFilters')
        setIid(0)
        setSearchNoteLookup('')
        setSearchCidrLookup('')
        setSearchNote('')
        setSearchCidr('')
        setSearchCustomer('')
        setSearchCustomerId(undefined)
    }

    const getQSFilter = (): string[] => {
        let qs: string[] = []
        if (searchCustomerId && searchCustomerId > 0) {
            qs.push('customer_id=' + searchCustomerId)
        }
        return qs
    }

    const prepareQsFilter = (): string => {
        if (iid > 0) {
            let qs: string[] = []
            // inv_no is uniq
            qs.push('id='+iid)
            return qs.join("&")
        }
        // load filtered data from server
        let qs: string[]
        qs = getQSFilter()
        if( !checkedInternal || !checkedPublic ) {
            if (!checkedInternal) {
                // external only
                qs.push('is_internal=0')
            }
            if (!checkedPublic) {
                qs.push('is_internal=1')
            }
        }
        if (checkMinSearch(searchCidr)) {
            qs.push('cidr=' + encodeURIComponent(searchCidr))
        }
        if (checkMinSearch(searchNote)) {
            qs.push('note=' + encodeURIComponent(searchNote))
        }
        if (checkMinSearch(searchReverse)) {
            qs.push('reverse_domain=' + encodeURIComponent(searchReverse))
        }
        logger('prepareQsFilter: '+qs.join("&"))
        return qs.join("&")
    }

    const onClearCidr = () => {
        setSearchCidrLookup('')
        setSearchCidr('')
        setCidrOptions([])
    }

    const onSelectCidr = (data: string) => {
        setSearchCidr(data)
    }

    const onChangeCidrLookup = (data: string) => {
        if (!data) {
            if (searchCidrLookup.length === 1) {
                setSearchCidrLookup('')
                fetchCidrLookup('')
            }
            return
        }
        logger('onChangeCidrLookup: '+data)
        if (data != searchCidrLookup){
            setSearchCidrLookup(data)
            fetchCidrLookup(data)
        }
    }

    const onClearNote = () => {
        setSearchNoteLookup('')
        setSearchNote('')
        setNoteOptions([])
    }

    const onSelectNote = (data: string) => {
        setSearchNote(data)
    }

    const onChangeNoteLookup = (data: string) => {
        if (!data) {
            if (searchNoteLookup.length === 1) {
                setSearchNoteLookup('')
                fetchNoteLookup('')
            }
            return
        }
        if (data != searchNoteLookup){
            setSearchNoteLookup(data)
            fetchNoteLookup(data)
        }
    }

    const onClearReverse = () => {
        setSearchReverseLookup('')
        setSearchReverse('')
        setReverseOptions([])
    }

    const onSelectReverse = (data: string) => {
        setSearchReverse(data)
    }

    const onChangeReverseLookup = (data: string) => {
        if (!data) {
            if (searchReverseLookup.length === 1) {
                setSearchReverseLookup('')
                fetchReverseLookup('')
            }
            return
        }
        if (data != searchReverseLookup){
            setSearchReverseLookup(data)
            fetchReverseLookup(data)
        }
    }

    const onClearCustomer = () => {
        setSearchCustomer('')
        setSearchCustomerId(undefined)
    }

    const onSelectCustomer = (v: string) => {
        const cid = parseInt(v)
        if (cid > 0) {
            logger('onSelectCustomer.. '+v)
            setSearchCustomerId(cid)
            const name = customerNames!.get(cid)
            name && setSearchCustomer(name)
        }
        else {
            setSearchCustomerId(undefined)
            v && setSearchCustomer(v)
        }
    }

    const onChangeCustomerLookup = (data: string) => {
        if (!data) {
            if (searchCustomer.length === 1) {
                setSearchCustomer('')
            }
            return
        }
        if (data != searchCustomer){
            setSearchCustomer(data)
        }
    }




    const handleQueryStringFilters = () => {
        // parse query string
        // and set filters
        // /billing/invoice?filter_id=6&number=122&from=2022-06-01&to=2022-08-10
        let passedRange = 0
        if (parsed && _isNotEmptyObject(parsed)) {
            logger(`handleQueryStringFilters..`)
            const qs_id: string | string[] | null = parsed['id']
            if (qs_id && typeof qs_id === 'string') {
                // call by ID
                const id = parseInt(qs_id)
                if (id > 0) {
                    setIid(id)
                    return
                }
            }

            // iid=0
            const qs_note: string | string[] | null = parsed['note']
            if (qs_note && typeof qs_note === 'string') {
                setSearchNoteLookup(qs_note)  // textbox
                setSearchNote(qs_note) // filter
            }
            const qs_Cidr: string | string[] | null = parsed['cidr']
            if (qs_Cidr && typeof qs_Cidr === 'string') {
                setSearchCidrLookup(qs_Cidr)  // textbox
                setSearchCidr(qs_Cidr) // filter
            }
            const qs_Reverse: string | string[] | null = parsed['reverse_domain']
            if (qs_Reverse && typeof qs_Reverse === 'string') {
                setSearchReverseLookup(qs_Reverse)  // textbox
                setSearchReverse(qs_Reverse) // filter
            }
            const qs_customer_id: string | string[] | null = parsed['customer_id']
            if (qs_customer_id && typeof qs_customer_id === 'string') {
                const cid = parseInt(qs_customer_id)
                if (cid > 0) {
                    setSearchCustomerId(cid)
                }
            }

            const qs_size: string | string[] | null = parsed['pagination[pageSize]']
            if (qs_size && typeof qs_size === 'string') {
                const ps = parseInt(qs_size)
                if (ps > 0) {
                    setPageSize(ps)
                }
            }
            const qs_num: string | string[] | null = parsed['pagination[page]']
            if (qs_num && typeof qs_num === 'string') {
                const pn = parseInt(qs_num)
                setPageNumber(pn)
            }
        }
    }

    const FilterByCustomer = (
        <AutoComplete
            showSearch
            placeholder={t('ipSubnetPage.customer')}
            style={{ width: '150px' }}
            value={searchCustomer}
            options={customerOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectCustomer(e.currentTarget.value)
                }
            }}
            onSelect={onSelectCustomer}
            //onSearch={onChangeCustomerLookup}
            onChange={onChangeCustomerLookup}
            onClear={onClearCustomer}
            onClick={stopPropagation}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )

    const FilterByCIDR = (
        <AutoComplete
            showSearch
            placeholder={t('ipSubnetPage.cidr')}
            style={{ width: '120px' }}
            value={searchCidrLookup}
            options={cidrOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectCidr(e.currentTarget.value)
                }
            }}
            onSelect={onSelectCidr}
            //onSearch={onChangeCidrLookup}
            onChange={onChangeCidrLookup}
            onClear={onClearCidr}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )

    const FilterByNote = (
        <AutoComplete
            showSearch
            placeholder={t('ipSubnetPage.note')}
            style={{ width: '140px' }}
            value={searchNoteLookup}
            options={noteOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectNote(e.currentTarget.value)
                }
            }}
            onSelect={onSelectNote}
            //onSearch={onChangeNoteLookup}
            onChange={onChangeNoteLookup}
            onClear={onClearNote}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )

    const FilterByReverse = (
        <AutoComplete
            showSearch
            placeholder={t('ipSubnetPage.reverse_zone')}
            style={{ width: '120px' }}
            value={searchReverseLookup}
            options={reverseOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectReverse(e.currentTarget.value)
                }
            }}
            onSelect={onSelectReverse}
            //onSearch={onChangeReverseLookup}
            onChange={onChangeReverseLookup}
            onClear={onClearReverse}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )

    const renderSubnet = (sub: IpSubnetModel) => {
        if (!sub) {
            return <Tag color='red'>ERROR</Tag>
        }
        if (sub.is_internal) {
            return (
                <Tag className='tagInternal' title={sub.is_internal ? t('ipSubnetPage.internal_subnet') : t('ipSubnetPage.public_subnet')} >
                    <Link to={`/ip/subnet/${sub.id}`}>
                        {sub.cidr}
                    </Link>
                </Tag>
            )
        }
        else {
            if (isSubnetExternal(sub.id)) {
                return (
                    <Tag className='tagExternal' title={sub.is_internal ? t('ipSubnetPage.internal_subnet') : t('ipSubnetPage.public_subnet')} >
                        <Link to={`/ip/subnet/${sub.id}`}>
                            {sub.cidr}
                        </Link>
                    </Tag>
                )
            }
            else {
                return (
                    <Tag className='tagPublic' title={sub.is_internal ? t('ipSubnetPage.internal_subnet') : t('ipSubnetPage.public_subnet')} >
                        <Link to={`/ip/subnet/${sub.id}`}>
                            {sub.cidr}
                        </Link>
                    </Tag>
                )
            }
        }
    }

    const columns: ColumnsType<IpSubnetModel> = [
        {
            title: FilterByCIDR,
            dataIndex: 'cidr',
            className: 'marked',
            key: 'cidr',
            width: '160px',
            fixed: 'left',
            render: (text: string, rec) => renderSubnet(rec),
        },
        {
            title: FilterByNote,
            dataIndex: 'note',
            width: '20%',
            key: 'note',
            ellipsis: {showTitle: false},
            render: (text: string, rec) => (
                <Link to={`/ip/subnet/${rec.id}`}>
                    {text}
                </Link>
            ),
        },
        {
            title: t('ipSubnetPage.revers'),
            dataIndex: 'reverse_domain',
            key: 'reverse_domain',
            width: 160,
            render: (text: string) => text ? text : ''
        },
        {
            title: <span style={{fontSize: '0.7em'}}>ipmi</span>,
            dataIndex: 'ipmi',
            key: 'ipmi',
            width: 40,
            className: 'centered',
            render: (rec: number) => rec === 1 ? <Badge status='success' /> : <Badge status='error' />
        },
        {
            title: t('ipSubnetPage.vlan'),
            dataIndex: 'vlan',
            key: 'vlan',
            width: 50,
            sorter: (a, b) => sort_num(a.vlan, b.vlan),
            render: vlan => vlan ? vlan : ''
        },
        {
            title: t('ipSubnetPage.location'),
            dataIndex: 'location',
            key: 'location',
            width: 160,
            ellipsis: {showTitle: false},
            render: location => (
                <Tooltip placement="topLeft" title={location}>
                    {location}
                </Tooltip>
            ),
        },
        {
            title: FilterByCustomer,
            dataIndex: 'customer_name',
            key: 'customer_name',
            width: 180,
            ellipsis: {showTitle: false},
            render: name => (
                <Tooltip placement="topLeft" title={name}>
                    {name}
                </Tooltip>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            align: 'center',
            fixed: 'right',
            width: 120,
            render: (text: string, rec: IpSubnetModel) => (
                <Space size={1} >
                    <Button type="text"
                            size="small"
                            disabled={!isSubnetEditor}
                            className='actionButton'
                            title={t('general.change')}
                            onClick={() => {
                                if (isSubnetEditor) {
                                    setSelectedSubnetId(rec.id)
                                    dispatch(getIpSubnet(rec.id, ''))
                                    setModalEditVisible(true)
                                }
                            }
                            }
                            icon={<EditTwoTone twoToneColor={isSubnetEditor ? "green" : "#ccc"} />}
                    />

                    <Button type="text" danger size="small"
                            disabled={!isSubnetDeleter}
                            title={t('general.delete')}
                            onClick={() => {
                                showConfirmDelete(rec, t('ipSubnetPage.confirm_delete'))
                            }}
                            className='actionButton'
                            icon={<DeleteTwoTone twoToneColor={isSubnetDeleter ? "red" : "#ccc"} />}
                    />

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(rec.id)
                                setHistoryTitle(rec.cidr)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                    <span>&nbsp;</span>
                </Space>
            ),
        },
    ]


    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin/>)
    }


    return (
        <>
            <Card title={
                <Row>
                    <Col span={4}>
                        <PartitionOutlined /> {t('ipSubnetPage.title')}
                    </Col>
                    <Col span={20}>
                        <Space style={{margin: '10px', verticalAlign: 'top'}}><b>{t('ipSubnetPage.show_subnets')}</b></Space>
                        <Space style={{margin: '10px', verticalAlign: 'top'}}>
                            <div style={{
                                float: "left",
                                borderBottom: 'solid 3px #cffcd3',
                                marginRight: "1px"
                            }}>{t('ipSubnetPage.show_public')}</div>
                            <Tooltip title={t('ipSubnetPage.show_public_text')}>
                                <Switch
                                    checkedChildren={<CheckOutlined/>}
                                    unCheckedChildren={<CloseOutlined/>}
                                    checked={checkedPublic}
                                    onChange={() => setCheckedPublic(!checkedPublic)}
                                />
                            </Tooltip>
                        </Space>
                        <Space style={{margin: '10px', marginLeft: '20px', verticalAlign: 'top'}}>
                            <div style={{
                                float: "left",
                                borderBottom: 'solid 3px #fce7d2',
                                marginRight: "1px"
                            }}>{t('ipSubnetPage.show_internal')}</div>
                            <Tooltip title={t('ipSubnetPage.show_internal_text')}>
                                <Switch
                                    checkedChildren={<CheckOutlined/>}
                                    unCheckedChildren={<CloseOutlined/>}
                                    checked={checkedInternal}
                                    onChange={() => setCheckedInternal(!checkedInternal)}
                                />
                            </Tooltip>
                        </Space>
                        <Space style={{margin: '10px', marginLeft: '40px', verticalAlign: 'top'}}><b>{t('ipSubnetPage.show_external')}:</b></Space>
                        <Space style={{margin: '10px 5px', verticalAlign: 'top'}}>
                            <Tag className='tagExternal'>
                                <Link to={'/ip/subnet/1'}>IPv4</Link>
                            </Tag>
                        </Space>
                        <Space style={{margin: '10px 5px', verticalAlign: 'top'}}>
                            <Tag className='tagExternal'>
                                <Link to={'/ip/subnet/2'}>IPv6</Link>
                            </Tag>
                        </Space>
                    </Col>
                </Row>
            }
                  extra={
                <Row>
                    <Col span={24}>
                        <Button type='primary'
                                disabled={!isSubnetCreator}
                                style={{margin: '10px', verticalAlign: 'top'}}
                                onClick={() => isSubnetCreator && setModalNewVisible(true)}>
                            <PlusCircleOutlined/> {t('ipSubnetPage.add_button')}
                        </Button>
                    </Col>
                </Row>

                  }
                  className='IpSubnetsPage'
            >

                <Table<IpSubnetModel>
                    className='subnetsTable'
                    rowClassName={(rec:IpSubnetModel) => {
                        if (rec.id === 1 || rec.id === 2) {
                            return 'external'
                        }
                        if (rec.is_internal) {
                            return 'private'
                        }
                        else {
                            return 'public'
                        }
                    }}
                    rowKey='id'
                    showHeader={true}
                    size='small'
                    bordered={true}
                    columns={columns}
                    scroll={{ x: 680 }}
                    dataSource={dataSource}
                    loading={isLoadingSubnets}
                    pagination={false}
                    footer={() => Pager({
                        filename: 'fn',
                        total: pager.totalCount,
                        current: pager.page,
                        pageSize: pager.pageSize,
                        data: dataSource,
                        fetchRecords: fetchRecords
                    })}
                    onChange={(ev) => {
                        ev.pageSize && setPageSize(ev.pageSize)
                    }}
                />

            </Card>

            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                     onMouseOut={() => {setDisabled(true)}}
                     onFocus={() => {}}
                     onBlur={() => {}}
                >
                    <><ApartmentOutlined/> &nbsp; {t('ipSubnetPage.create_subnet')}</>
                </div>
            }
                   style={{top: 20}}
                   width={480}
                   visible={isModalNewVisible}
                   onCancel={() => setModalNewVisible(false)}
                   maskClosable={false}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   destroyOnClose
                   footer={null}
            >
                <NewSubnetForm onClose={() => {setReady(ready+1); setModalNewVisible(false)}}  />
            </Modal>

            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                     onMouseOut={() => {setDisabled(true)}}
                     onFocus={() => {}}
                     onBlur={() => {}}
                >
                    <><ApartmentOutlined/> &nbsp; {t('ipSubnetPage.update_subnet')}</>
                </div>
            }
                   style={{top: 20}}
                   width={480}
                   visible={isModalEditVisible}
                   onCancel={() => setModalEditVisible(false)}
                   maskClosable={false}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   destroyOnClose
                   footer={null}
            >
                <EditSubnetForm id={selectedSubnetId} onClose={() => {setSelectedSubnetId(0);setModalEditVisible(false)}}  />
            </Modal>

            <HistoryModal service='ip' model='Subnet'
                          modelId={historyModelId}
                          title={historyTitle}
                          isModalVisible={isHistoryModalVisible}
                          setModalVisible={() => setHistoryModalVisible(false)}
                          modalRender={(modal) => (
                              <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                  <div ref={draggleRef}>{modal}</div>
                              </Draggable>
                          )}
            />
        </>
    )

}

export default SubnetsPage