import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {Alert, Button, Col, Divider, Form, Input, InputRef, message, Row, Select, Space, Spin, TreeSelect} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {formItemLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {PhysicalServerModel} from "./models";
import updatePhysicalServer from "./actions/updatePhysicalServer";
import getNewServers from "./actions/getNewServers";
import {InventoryModel} from "../inventory/models";
import getPhysicalServer from "./actions/getPhysicalServer";
import {removeDiac, stopPropagation} from "../../../common/fce";
import {sort_id} from "../../../common/sorting";
import {renderInventoryItem} from "../common";
import {useLoggedUser} from "../../../helpers/loginUserHelper";


interface Props {
    dataToUpdate?: PhysicalServerModel
    setModalVisible: (param: boolean) => void
}

// sorting
const sort_name = (a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1

const {Item} = Form

const NoInventoryForm = ({dataToUpdate, setModalVisible}: Props) => {
    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const inputRef = useRef<InputRef>(null)

    const {physicalServer, newServers, isSaving} = useSelector((state: AppState) => state.inventoryphysicalserver)

    const [selectedInventory, setSelectedInventory] = useState<InventoryModel | undefined>()
    const [dataSource, setDataSource] = useState<{value: number, title: string | ReactNode, name: string, children: any[]}[]>([]);
    const [items, setItems] = useState<{value: number, title: string | ReactNode, name: string, children: any[]}[]>([]);
    const [serial, setSerial] = useState('')


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    useEffect(() => {
        if (dataToUpdate && physicalServer && physicalServer.id === dataToUpdate.id) {
            dataToUpdate.ident = physicalServer.ident
            dataToUpdate.inventory_id = physicalServer.inventory_id
            dataToUpdate.rack_id = physicalServer.rack_id
            dataToUpdate.rack_pos = physicalServer.rack_pos
            dataToUpdate.container_id = physicalServer.container_id
            dispatch(getNewServers())
        }
    }, [physicalServer])

    useEffect(() => {
        if (newServers) {
            // render select dropdown
            const arr: {value: number, title: string | ReactNode, name: string, children: any[]}[] = []
            for (const item of newServers.sort(sort_id)) {
                arr.push({value: item.id, title: renderInventoryItem(item), name: `${item.name}|${item.serial}`, children: []})
            }
            if (physicalServer?.inventory) {
                // selected inventoryItem
                arr.push({value: physicalServer?.inventory.id, title: renderInventoryItem(physicalServer?.inventory), name: `${physicalServer?.inventory.name}|${physicalServer?.inventory.serial}`, children: []})
            }
            setItems(arr)
        }
    }, [newServers])

    useEffect(() => {
        if (serial) {
            refreshGridItems()
        }
    }, [serial])

    useEffect(() => {
        if (physicalServer && physicalServer.id > 0) {
            refreshGridItems()
            physicalServer.inventory && form.setFieldsValue({name: physicalServer.inventory.id})
        }
    }, [items])


    const onSerialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSerial(event.target.value);
    }

    const checkMinSearch = (val: string | undefined) => {
        if (!val) {
            return false
        }
        return val.length > SEARCH_MIN
    }

    const refreshGridItems = () => {
        setDataSource(filtered().sort((a, b) => sort_name(b, a)))
    }

    const filtered = () => {
        if (!items || items.length === 0) {
            return []
        }

        let data = items
        if (checkMinSearch(serial)) {
            // inventory
            const qName = (s: {value: number, title: string | ReactNode, name: string, children: any[]}) => removeDiac(s.name).includes(removeDiac(serial))
            data = data.filter((c) => qName(c))
        }
        return data
    }

    const onFormSubmit = (values: Store) => {
        if (dataToUpdate && selectedInventory && selectedInventory.id > 0) {
                const item = {
                    id: dataToUpdate.id,
                    inventory_id: selectedInventory.id,
                }
                dispatch(updatePhysicalServer(item, (suc: boolean) => {
                    if (suc) {
                        if (selectedInventory) {
                            // update inventory after update
                            dispatch(getPhysicalServer(dataToUpdate.id, 'inventory'))
                        }
                        setModalVisible(false)
                        message.success(t('general.success'))
                    }
                }))
        }
    }

    const onChange = (newValue: number | undefined) => {
        if (newValue && newValue < 1000000) {
            const item = newServers.find(s => s.id === newValue)
            setSelectedInventory(item);
        }
        else {
            setSelectedInventory(undefined);
        }
    }

    const isFormValid = () => {
        if (!selectedInventory) {
            return false
        }
        return true
    }

    if (!physicalServer || physicalServer.id < 1) {
        return (<Spin />)
    }

    return (
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
            initialValues={{...dataToUpdate,
                name: selectedInventory ? selectedInventory.name : ''
            }}
        >
            <Row>
                <Col span={24}>
                    <Item name='ident' labelCol={{span: 4}} wrapperCol={{span: 20}} label={t('physicalServersPage.ident')} >
                        <Input readOnly style={{width: '120px', border: 0, fontWeight: 'bold'}} />
                    </Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Item name='name' labelCol={{span: 4}} wrapperCol={{span: 20}} label={t('physicalServersPage.inventory')} >
                        <Select
                            style={{ width: '100%' }}
                            className='selectComponent80'
                            placement='topLeft'
                            dropdownStyle={{ maxHeight: 600, overflow: 'auto' }}
                            placeholder={t('physicalServersPage.inventory_select')}
                            allowClear
                            showSearch
                            dropdownRender={menu => (
                                <>
                                    <Space style={{ padding: '0 8px 4px' }}>
                                        <span className='text-disabled'>Filter: </span>
                                        <Input
                                            placeholder="Serial"
                                            ref={inputRef}
                                            value={serial}
                                            onChange={onSerialChange}
                                        />
                                        <span className='text-disabled'>({dataSource.length} items)</span>
                                    </Space>
                                    <Divider style={{ margin: '8px 0' }} />
                                    {menu}
                                </>
                            )}
                            onClick={stopPropagation}
                            onChange={onChange}
                            options={dataSource.map(item => ({ label: item.title, value: item.value }))}
                        />

                    </Item>
                </Col>
            </Row>

            <Row>
                <Col span={4}>&nbsp;</Col>
                <Col span={20}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Button type='primary' htmlType='submit'
                                disabled={!isFormValid()}
                                loading={isSaving}>
                            {t('general.save')}
                        </Button>
                    </Space>
                </Col>
            </Row>

            <Row style={{marginTop: '35px'}}>
                <Col span={24}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Alert type="error"
                               message={t('general.warning')}
                               description={t('physicalServersPage.no_inventory_warning')}
                        />
                    </Space>
                </Col>
            </Row>
        </Form>
    )
}

export default NoInventoryForm
