import { GetNewServersRequestAction, GetNewServersResponseAction, GetNewServersErrorAction} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {InventoryModel} from "../../inventory/models";

export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetNewServersRequestAction
                | GetNewServersResponseAction
                | GetNewServersErrorAction,
        ) => void,
    ) => {
        const request = new GetNewServersRequestAction()
        dispatch(request)

        protectedApiClient
            .get<InventoryModel[]>('/inventory/select/unused-servers')
            .then((response) => {
                dispatch(new GetNewServersResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetNewServersErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
