import {
    GetDbServicesRequestAction,
    GetDbServicesResponseAction,
    GetDbServicesErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DbServiceModel } from '../models'

export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetDbServicesRequestAction
                | GetDbServicesResponseAction
                | GetDbServicesErrorAction
        ) => void
    ) => {
        const request = new GetDbServicesRequestAction()
        dispatch(request)

        protectedApiClient
            .get<DbServiceModel[]>('/server/service/rdb-list?expand=driver')
            .then((response) => {
                dispatch(new GetDbServicesResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetDbServicesErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
