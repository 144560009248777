import React, {useEffect} from 'react'
import {Button, Form, Input, message, Radio, Select, Space, Spin, TreeSelect} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {formItemLayout, fullWidthLayout, tailLayout} from 'helpers/layoutHelpers'
import createProductPrice from 'pages/billing/productprice/actions/createProductPrice'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {ProductPriceDetails} from 'pages/billing/productprice/models'
import updateProductPrice from 'pages/billing/productprice/actions/updateProductPrice'
import {ProductDetails} from 'pages/billing/product/models'
import {MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons'
import {useState} from 'react'
import deleteProductPriceRange from 'pages/billing/productpricerange/actions/deleteProductPriceRange'
import createProductPriceRange from 'pages/billing/productpricerange/actions/createProductPriceRange'
import updateProductPriceRange from 'pages/billing/productpricerange/actions/updateProductPriceRange'
import InputNumber from 'antd/lib/input-number'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {useHistory} from "react-router";
import {sort_name} from "../../../common/sorting";
import getProductPricesByPricelist from "./actions/getProductPricesByPricelist";
import {_parsePrice, decEn, removeDiac} from "../../../common/fce";

interface Props {
	dataToUpdate?: ProductPriceDetails
	setModalVisible: (param: boolean) => void
	selectedPricelistData?: ProductPriceDetails[]
	updateHistory: () => void
}
const {Item} = Form

const ProductPriceForm = ({
	dataToUpdate,
	setModalVisible,
	updateHistory}: Props) => {
	const [isRangeVisible, setRangeVisible] = useState<boolean>()
	const [form] = useForm()
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	const {products} = useSelector((state: AppState) => state.product)
	const {pricelist} = useSelector((state: AppState) => state.pricelist)
	const {productprices, isSaving: isSaving2} = useSelector((state: AppState) => state.productprice)
	const {productpriceranges, isSaving, isLoading} = useSelector((state: AppState) => state.productpricerange)

	const [selectedProduct, setSelectedProduct] = useState<ProductDetails>()
	const [productOptions, setProductOptions] = useState<{label: string, value: number, disabled: boolean}[]>([])
	const [cbCounter, setCbCounter] = useState<number>(0)
	const [btnPressed, setBtnPressed] = useState<boolean>(false)

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (<Spin />)
	}
	const appSetting = loggedUser.getAppSettings()

	useEffect(() => {
		setRangeVisible(dataToUpdate?.calculation_type === 'range')
	}, [])

	useEffect(() => {
		if (dataToUpdate) {
			setSelectedProduct(products.find((i) => i.id === dataToUpdate.product_id))
		}
		if (products && products.length) {
			setProductOptions(products.sort(sort_name).map(p => ({ label: p.name, value: p.id, disabled: prodExist(p.id) })))
		}
	}, [dataToUpdate, products])

	const prodExist = (pid: number) => {
		if (productprices && productprices.length) {
			if (productprices.find(pp => pp.product_id === pid)) {
				return true
			}
		}
		return false
	}

	const onFormSubmit = (values: Store) => {
		if (!pricelist) {
			setModalVisible(false)
			message.error(t('billing.productprice.error.pricelist_id'))
			history.replace('/billing/pricelist')
			return
		}

		const rangeObjectsToDelete = values.ranges && productpriceranges?.filter((e) => !values.ranges.find((a) => e.id === a.id))
		const rangeObjectsToUpdate =
			values.ranges &&
			values.ranges.filter((x) => !productpriceranges?.includes(x) && x.id).map((v) => ({...v, price: v.price * 100}))
		const rangeObjectsToCreate =
			values.ranges &&
			values.ranges.filter((x) => !productpriceranges?.includes(x) && !x.id).map((v) => ({...v, price: v.price * 100}))
		setBtnPressed(true)

		// ----
		if (dataToUpdate && dataToUpdate.id) {
			const params = {
				id: dataToUpdate.id,
				product_id: values.product_id,
				pricelist_id: pricelist.id,
				calculation_type: values.calculation_type,
				price: values.price * 100 || 0,
				unit: values.unit || ''
			}
			dispatch(updateProductPrice(params, (suc: boolean) => {
					if (suc) {
						if (values.calculation_type === 'range') {
							// first volume_from == 0 than others
							rangeObjectsToUpdate?.length && setCbCounter(cbCounter-rangeObjectsToUpdate.length)
							rangeObjectsToDelete?.length && rangeObjectsToDelete.map((i) => { dispatch(deleteProductPriceRange(i.id, (b) => {setModalVisible(false); updateHistory()}))})
							rangeObjectsToUpdate?.length && rangeObjectsToUpdate.map((i) => { dispatch(updateProductPriceRange({...i, volume_from: i.volume_from * 1000}, (b) => {setModalVisible(false); updateHistory()}))})
							if (rangeObjectsToCreate && rangeObjectsToCreate.length) {
								let firstItem = undefined
								if (rangeObjectsToCreate.filter(item => item.volume_from === 0).length > 0) {
									firstItem = rangeObjectsToCreate.filter(item => item.volume_from === 0)[0]
									// @ts-ignore
									dispatch(createProductPriceRange({price: firstItem.price, volume_from: 0, product_price_id: params.id}, (suc2) => {
										const otherItems = rangeObjectsToCreate.filter(item => item.volume_from > 0)
										otherItems.map((i) => { dispatch(createProductPriceRange({...i, volume_from: i.volume_from * 1000, product_price_id: params.id}, (b) => {setModalVisible(false); updateHistory()}))})
									}))
								}
								else {
									const otherItems = rangeObjectsToCreate.filter(item => item.volume_from > 0)
									otherItems.map((i) => { dispatch(createProductPriceRange({...i, volume_from: i.volume_from * 1000, product_price_id: params.id}, (b) => {setModalVisible(false); updateHistory()}))})
								}
							}
						}
						setModalVisible(false)
						updateHistory()
					}
				})
			)
		}
		else {
				const params = {
					product_id: values.product_id,
					pricelist_id: pricelist.id,
					calculation_type: values.calculation_type,
					price: values.price * 100 || rangeObjectsToCreate[0].price,
					unit: values.unit || ''
				}
				dispatch(createProductPrice(params, (suc, recId) => {
					if (suc && recId) {
						if (rangeObjectsToCreate && rangeObjectsToCreate.length) {
							rangeObjectsToCreate.filter(p => p.volume_from === 0).map((i, index) => {
									rangeObjectsToCreate.filter(p => p.volume_from > 0).map((i, index) => {
											// the next items: volume_from > 0
											let par2 = {...i, volume_from: i.volume_from * 1000, product_price_id: recId}
											dispatch(createProductPriceRange(par2, suc => {
												if (suc) {
													updateHistory()
													message.info(t('billing.productprice.created'))
													dispatch(getProductPricesByPricelist(pricelist.id))
													setModalVisible(false)
												} else {
													message.info(t('general.error'))
												}
											}))
										}
									)
								}
							)
						}
						else {
							updateHistory()
							message.info(t('billing.productprice.created'))
							setModalVisible(false)
						}
					}
				}))
		}


		//
		// dataToUpdate
		// 	? dispatch(
		// 	updateProductPrice(
		// 		{
		// 			id: dataToUpdate.id,
		// 			name: values.name,
		// 			product_id: values.product_id,
		// 			pricelist_id: pricelist ? pricelist.id : 0,
		// 			calculation_type: values.calculation_type,
		// 			price: values.price * 100 || 0,
		// 			unit: values.unit || ''
		// 		},
		// 		async(isSuccess: boolean) => {
		// 			if (isSuccess) {
		// 				if (values.calculation_type === 'range') {
		// 					rangeObjectsToUpdate?.length && setCbCounter(cbCounter-rangeObjectsToUpdate.length)
		// 					rangeObjectsToDelete?.length && await rangeObjectsToDelete.map((i) => { dispatch(deleteProductPriceRange(i.id, (b) => setModalVisible(false)))})
		// 					rangeObjectsToUpdate?.length && await rangeObjectsToUpdate.map((i) => { dispatch(updateProductPriceRange({...i, volume_from: i.volume_from * 1000}, (b) => setModalVisible(false)))})
		// 					rangeObjectsToCreate?.length && await rangeObjectsToCreate.map((i) => { dispatch(createProductPriceRange({...i, volume_from: i.volume_from * 1000}, (b) => setModalVisible(false)))})
		// 				} else {
		// 					setModalVisible(false)
		// 				}
		// 			}
		// 		},
		// 	),
		// 	)
		// 	: dispatch(
		// 	createProductPrice(
		// 		{
		// 			name: values.name,
		// 			product_id: values.product_id,
		// 			pricelist_id: pricelist ? pricelist.id : 0,
		// 			calculation_type: values.calculation_type,
		// 			price: values.price * 100 || rangeObjectsToCreate[0].price,
		// 			unit: values.unit || ''
		// 		},
		// 		async(isSuccess: boolean, responseId?: number) => {
		// 			if (isSuccess) {
		// 				rangeObjectsToCreate?.length &&
		// 				rangeObjectsToCreate.map((i, index) =>
		// 					index !== 0 && dispatch(createProductPriceRange({...i, volume_from: i.volume_from * 1000, product_price_id: responseId})),
		// 				)
		// 				setModalVisible(false)
		// 				message.info(t('billing.productprice.created'))
		// 			}
		// 		},
		// 	),
		// 	)
	}

	const handleFormatter = e => {
		let value = appSetting.renderPrice(_parsePrice(e.target.value))
		form.setFieldsValue({price: decEn(value)})
	}

	return (
		<>
			{
				appSetting &&
				<Form
					{...formItemLayout}
					onFinish={onFormSubmit}
					form={form}
					className='ProductPriceForm'
					id='ProductPriceFormId'
					initialValues={{
						...dataToUpdate,
						price: dataToUpdate && dataToUpdate.price / 100,
						unit: dataToUpdate && dataToUpdate.unit,
						ranges:
							productpriceranges?.length && dataToUpdate
								? productpriceranges
									.map((v) => ({...v, price: v.price / 100, volume_from: v.volume_from / 1000}))
									.sort((a, b) => a.volume_from - b.volume_from)
								: [{product_price_id: dataToUpdate?.id}],
					}}>

					<Item name='product_id'
						rules={[{required: true, message: t('billing.productprice.error.product_id')}]}
						label={t('billing.productprice.product_id')}
						hasFeedback>
						<Select
							showSearch
							allowClear
							loading={isLoading}
							virtual={false}
							disabled={!!dataToUpdate}
							optionFilterProp='label'
							filterOption={(inputValue, opt) =>
								removeDiac(opt!.label).indexOf(removeDiac(inputValue)) !== -1
							}
							options={productOptions}
							onChange={(v) => {
								let prod = products?.find((i) => i.id === v)
								setSelectedProduct(prod)
								form.setFieldsValue({name: prod?.name})
							}}


						/>
					</Item>

					<Item name='unit'
						label={t('billing.productprice.unit')}
						hasFeedback>
						<Input />
					</Item>

					<Item
						name='calculation_type'
						label={t('billing.productprice.calculation_type')}
						rules={[{required: true, message: t('billing.productprice.error.calculation_type')}]}>
						<Radio.Group
							options={[
								{label: 'fix', value: 'fix'},
								{label: 'unit', value: 'unit'},
								{label: 'range', value: 'range'},
							]}
							// disabled={dataToUpdate?.calculation_type === 'range'}
							onChange={(e) => (setRangeVisible(e.target.value === 'range'))}
							optionType='button'
							buttonStyle='solid'
							className='ProductPriceForm_buttongroup'
						/>
					</Item>

					{isRangeVisible && (
						<Form.List name='ranges' {...fullWidthLayout}>
							{(fields, {add, remove}) => (
								<>
										{fields.map((field, index) => (

											<Space align='baseline' key={index}>
												<Form.Item
													name={[field.name, 'id']}
													key={'id_'+index}
													style={{display: 'none'}}>
													<InputNumber type='hidden' />
												</Form.Item>

												<Form.Item
													name={[field.name, 'product_price_id']}
													key={'iproduct_price_id_'+index}
													style={{display: 'none'}}>
													<InputNumber type='hidden' />
												</Form.Item>

												<Form.Item
													label={t('billing.productprice.from')}
													key={'volume_from_'+index}
													name={[field.name, 'volume_from']}
													rules={[{required: true, message: 'From'}]}>
													<InputNumber
														min={0}
														max={index === 0 ? 0 : undefined}
														placeholder='From'
														step="any"
														style={{width: 'auto'}}
													/>
												</Form.Item>
												<Button className='btnPrefix' disabled>
													{selectedProduct ? selectedProduct.unit : ' '}
												</Button>

												<Form.Item
													label={t('billing.productprice.price')}
													key={'price_'+index}
													name={[field.name, 'price']}
													rules={[{required: true, message: 'Price'}]}>
													<InputNumber
														type='number'
														min={0}
														step="any"
														placeholder={t('billing.productprice.price')}
														style={{width: 'auto'}}
													/>
												</Form.Item>
												<Button className='btnPrefix' disabled>
													{pricelist ? pricelist.currency : ''}
												</Button>

												<Form.Item key={`button-${field.name}`} {...fullWidthLayout}>
													<Button
														type='primary'
														disabled={index === 0}
														onClick={() => { remove(field.name) }}>
														<MinusCircleOutlined />
													</Button>
												</Form.Item>
											</Space>
										))}

									<Form.Item className='ProductPriceForm_space_button-add'>
										<Button
											type='primary'
											style={{background: '#2d9259', border: '#2d9259'}}
											onClick={() => {
												add({product_price_id: dataToUpdate?.id})
											}}
											block>
											<PlusCircleOutlined /> Add range
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
					)}

					<Item name='price'
						rules={[{required: !isRangeVisible, message: t('billing.productprice.error.price')}]}
						label={t('billing.productprice.price')}
						className='totalPrice'>
						<Input disabled={isRangeVisible} type='number' step={0.1} addonAfter={pricelist ? pricelist.currency : ''} onBlur={handleFormatter}/>
					</Item>

					<Item {...tailLayout}>
						<Button loading={isSaving || isSaving2}
								type='primary'
								htmlType='submit'
								className='login-form-button'>
							{dataToUpdate ? t('billing.productprice.update') : t('billing.productprice.add')}
						</Button>
					</Item>
				</Form>
			}
		</>
	)
}

export default ProductPriceForm
