import React, { useEffect } from 'react'
import './VpsContainersPage.scss'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { HypervisorModel, VpsHelper, vpsTypes } from './models'
import updateSetting from "../login/actions/updateSetting"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import { AutoComplete, Card, Col, message, Row, Select, Space, Table } from "antd"
import { PAGING } from "../../common/enums"
import TotalNum from "../../components/TotalNum/TotalNum"
import {OneToOneOutlined, PicRightOutlined} from "@ant-design/icons"
import { stateVpsSetting } from "./models"
import getVpsContainers from './actions/getVpsContainers'
import { Link } from 'react-router-dom'
import {sort_name, sort_str} from "../../common/sorting";
import {stopPropagation} from "../../common/fce";
import usePageSize from "../../common/usePageSize";

// sorting


const sort_numbers = (a, b) => a - b


const VpsContainersPage = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { vpsServers, isLoading } = useSelector((state: AppState) => state.vpsadmin)

    const [dataSource, setDataSource] = useState<HypervisorModel[]>([])
    const [searchName, setSearchName] = useState('')
    const [searchName2, setSearchName2] = useState('')
    const [searchHost, setSearchHost] = useState('')
    const [searchHost2, setSearchHost2] = useState('')
    const [searchVpsId, setSearchVpsId] = useState('')
    const [searchState, setSearchState] = useState<number>()
    const [searchType, setSearchType] = useState<number>()
    const [isModalVisible, setModalVisible] = useState(false)

    const [pageSize, setPageSize] = useState<string>()


    const { Option } = Select
    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg) => {if (appSetting && appSetting.debug) {console.log('VpsContainersPage: ' + msg)}}
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    useEffect(() => {
        // load hypervisors from API
        // trigger ONCE
        dispatch(getVpsContainers())
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }
    }, [vpsServers, searchName2, searchHost2, searchVpsId, searchState, searchType, isModalVisible])

    const refreshGrid = () => {
        let patternName = ''
        let patternHost = ''
        let patternVpsId
        let patternState
        let patternType
        if (searchState) {
            patternState = searchState
        }
        if (searchType) {
            patternType = searchType
        }
        if (searchName2) {
            patternName = searchName2.replace(/\s/g, '')
        }
        if (searchHost2) {
            patternHost = searchHost2.replace(/\s/g, '')
        }
        if (searchVpsId) {
            patternVpsId = searchVpsId.replace(/\s/g, '')
        }
        let newData = filtered(patternName, patternHost, patternVpsId, patternState, patternType)
        logger(`refreshGrid name: ${patternName}, hypervisors: ${vpsServers?.length}, dataSource: ${newData.length}`)
        setDataSource(newData)
    }

    const filtered = (name: string, host: string, vpsId: number, state: number, type: number) => {
        if (!vpsServers) {
            return []
        }

        const qName = (h) => h.name.toLowerCase().includes(name.toLowerCase())
        const qHost = (h) => h.host.toLowerCase().includes(host.toLowerCase())
        const qVpsId = (h) => h.vps_id == vpsId
        const qState = (h) => h.state == state
        const qType = (h) => h.type == type

        let result = vpsServers
        if (name) {
            result = result.filter((t) => qName(t))
        }
        if (host) {
            result = result.filter((t) => qHost(t))
        }
        if (vpsId) {
            result = result.filter((t) => qVpsId(t))
        }
        if (state) {
            result = result.filter((t) => qState(t))
        }
        if (type) {
            result = result.filter((t) => qType(t))
        }
        return result.sort(sort_name)
    }


    const FilterByNameInput = (
        <AutoComplete
            style={{ width: '160px' }}
            value={searchName}
            allowClear={true}
            placeholder={t('vpsadmin.vps.name')}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(currValue) => {
                setSearchName(currValue)
                if (currValue && currValue.length > 2) {
                    setSearchName2(currValue)
                }
                else {
                    setSearchName2('')
                }
            }}
        />
    )

    const FilterByHostInput = (
        <AutoComplete
            style={{ width: '160px' }}
            value={searchHost}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            placeholder={t('vpsadmin.vps.host')}
            onChange={(currValue) => {
                setSearchHost(currValue)
                if (currValue && currValue.length > 2) {
                    setSearchHost2(currValue)
                }
                else {
                    setSearchHost2('')
                }
            }}
        />
    )

    const FilterByVpsIdInput = (
        <AutoComplete
            style={{ width: '60px' }}
            value={searchVpsId}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            placeholder={t('vpsadmin.vps.vmid')}
            onChange={(currValue) => {
                setSearchVpsId(currValue)
            }}
        />
    )

    const FilterByStatusInput = (
        <Select
            style={{ width: 120 }}
            placeholder={t('vpsadmin.vps.state')}
            allowClear={true}
            onChange={(currValue) => {
                setSearchState(VpsHelper.reConvertedState(currValue))
            }} >
            {
                stateVpsSetting.map((value) => {
                    return <Option value={value} key={value}>{value}</Option>
                })
            }
        </Select>
    )

    const FilterByTypeInput = (
        <Select
            style={{ width: 120 }}
            placeholder={t('vpsadmin.vps.type')}
            allowClear={true}
            onChange={(currValue) => {
                setSearchType(VpsHelper.resetTypeVps(currValue))
            }} >
            {
                vpsTypes.map((value) => {
                    return <Option value={value} key={value}>{value}</Option>
                })
            }
        </Select>
    )

    const columns = [
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            render: (text, record) => (
                <Link to={`/vpsadmin/vps/${record.id}`}>
                    {text}
                </Link>
            ),
            sorter: sort_name
        },
        {
            title: FilterByHostInput,
            dataIndex: 'host',
            key: 'host',
            className: 'left',
            width: '30%',
            sorter: (a, b) => sort_str(a.host, b.host)
        },
        {
            title: FilterByVpsIdInput,
            dataIndex: 'vps_id',
            key: 'vps_id',
            className: 'center',
            width: '60px',
            sorter: (a, b) => sort_numbers(a.vps_id, b.vps_id)
        },
        {
            title: t('vpsadmin.vps.cpu'),
            dataIndex: 'cpu_occupied',
            key: 'cpu_count',
            className: 'center',
            width: '40px'
        },
        {
            title: t('vpsadmin.vps.ram'),
            dataIndex: 'ram_total',
            className: 'right',
            width: '5%',
            render: (_, record: HypervisorModel) => (
                <>
                    {VpsHelper.transformToGB(record.ram_total)}
                    {` ${t('vpsadmin.gb')}`}
                </>
            ),
            sorter: (a, b) => sort_numbers(a.ram_total, b.ram_total)
        },
        {
            title: t('vpsadmin.vps.disk'),
            dataIndex: 'disk_total',
            className: 'right',
            width: '10%',
            render: (_, record: HypervisorModel) => (
                <>
                    {VpsHelper.transformToGB(record.disk_total)}
                    {` ${t('vpsadmin.gb')}`}
                </>
            ),
            sorter: (a, b) => sort_numbers(a.disk_total, b.disk_total)
        },
        {
            title: FilterByTypeInput,
            dataIndex: 'type',
            className: 'left ',
            width: '5%',
            render: (_, record: HypervisorModel) => (
                <>
                    {VpsHelper.setTypeVps(+record.type)}
                </>
            )
        },
        {
            title: FilterByStatusInput,
            dataIndex: 'state',
            className: 'left ',
            width: '5%',
            render: (_, record: HypervisorModel) => (
                <>
                    {VpsHelper.convertedState(record.state)}
                </>
            )
        },


    ]


    return (
        <>
            <Card className='VpsAdminPage'
                title={
                    <Row>
                        <Col span={6}>
                            <PicRightOutlined />{` ${t('vpsadmin.vps.title')}`}
                        </Col>
                    </Row>}
            >
                {
                    appSetting &&
                    <Table<HypervisorModel>
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columns}
                        loading={isLoading}
                        dataSource={dataSource}
                        className='HypervisorsTable'
                        rowKey='id'
                        pagination={{
                            defaultPageSize: appSetting?.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => { setPageSize(`${ev.pageSize}`) }}
                        footer={() => TotalNum(Number(dataSource?.length), 'Hypervisors', dataSource)}
                    />
                }
            </Card>
        </>
    )
}

export default VpsContainersPage
