import {
    AddUserToGroupRequestAction,
    AddUserToGroupErrorAction,
    AddUserToGroupResponseAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {UserGroupModel} from "../../usergroups/models";
import {AddDeleteUserToGroupParams} from "../models";


export default (
    params: AddDeleteUserToGroupParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | AddUserToGroupRequestAction
                | AddUserToGroupErrorAction
                | AddUserToGroupResponseAction,
        ) => void,
    ) => {
        const request = new AddUserToGroupRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<UserGroupModel>(`/user-group/${params.id}/user`, params)
            .then((response) => {
                dispatch(new AddUserToGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new AddUserToGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
