import {AsyncActionMode} from 'common/models'
import {UserDetails, SelectCustomer} from 'pages/user/models'
import {
    LoginParams,
    AuthState,
    LoginAsParams,
    LogoutAsParams,
    Auth2faResponseData,
    Params2FA,
    SendCodeResponseData, ParamsSendCode, SetPasswordParams, RightSiderContent, HelperDocument
} from '../models'
import {CustomerDetails} from "../../billing/customer/models";
import {ErrorSetting, SettingsDetails, SettingsParams} from "../../settings/models";
import {CompanyDetails} from "../../company/models";


export enum ActionType {
    LOGIN_USER = 'LOGIN_USER',
    SET_PASSWORD = 'SET_PASSWORD',
    SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST',
    SEND_2FA_CODE = 'SEND_2FA_CODE',
    LOGIN_2FA = 'LOGIN_2FA',
    LOGOUT_USER = 'LOGOUT_USER',
    USER_DETAILS = 'USER_DETAILS',
    USER_SIGNED_OUT = 'USER_SIGNED_OUT',
    RELOAD_TOKEN = 'RELOAD_TOKEN',
    SET_LAST_UPDATE = 'SET_LAST_UPDATE',
    TOKEN_EXPIRED = 'TOKEN_EXPIRED',
    TRY_TO_LOAD_USER = 'TRY_TO_LOAD_USER',
    API_ERROR = 'API_ERROR',
    GET_MENU_ROLES = 'GET_MENU_ROLES',
    SELECT_CUSTOMER = 'SELECT_CUSTOMER',
    GET_CUSTOMER_SELF = 'GET_CUSTOMER_SELF',
    GET_SETTING_SELF = 'GET_SETTING_SELF',
    UPDATE_SETTING_ITEM = 'UPDATE_SETTING_ITEM',
    UPDATE_SELF_SETTINGS = 'UPDATE_SELF_SETTINGS',
    LOGIN_USER_AS = 'LOGIN_USER_AS',
    LOGOUT_USER_AS = 'LOGOUT_USER_AS',
    LOG_URL = 'LOG_URL',
    TABLE_COMPANY = 'TABLE_COMPANY',
    TABLE_CUSTOMER = 'TABLE_CUSTOMER',
    SHOW_RIGHT_SIDEBAR = 'SHOW_RIGHT_SIDEBAR',
    UPDATE_RIGHT_SIDEBAR = 'UPDATE_RIGHT_SIDEBAR',
    GET_DOCS = 'GET_DOCS'
}


export type LoginActions =
    | UserLoginRequestAction
    | UserLoginResponseAction
    | UserLoginErrorAction
    | SetPasswordRequestAction
    | SetPasswordResponseAction
    | SetPasswordErrorAction
    | SetPassRequestRequestAction
    | SetPassRequestResponseAction
    | SetPassRequestErrorAction
    | SendConfirmRequestAction
    | SendConfirmResponseAction
    | SendConfirmErrorAction
    | AuthConfirmRequestAction
    | AuthConfirmResponseAction
    | AuthConfirmErrorAction
    | UserLogoutRequestAction
    | UserLogoutResponseAction
    | UserLogoutErrorAction
    | TokenExpiredAction
    | UserDetailsRequestAction
    | UserDetailsResponseAction
    | UserDetailsErrorAction
    | TryToLoadUserRequestAction
    | TryToLoadUserResponseAction
    | TryToLoadUserErrorAction
    | GetMenuRolesRequestAction
    | GetMenuRolesResponseAction
    | GetMenuRolesErrorAction
    | SelectCustomerRequestAction
    | SelectCustomerResponseAction
    | SelectCustomerErrorAction
    | GetCustomerSelfRequestAction
    | GetCustomerSelfResponseAction
    | GetCustomerSelfErrorAction
    | ReloadTokenRequestAction
    | ReloadTokenResponseAction
    | ReloadTokenErrorAction
    | GetSettingSelfRequestAction
    | GetSettingSelfResponseAction
    | GetSettingSelfErrorAction
    | UpdateSelfSettingRequestAction
    | UpdateSelfSettingResponseAction
    | UpdateSelfSettingErrorAction
    | UpdateSettingRequestAction
    | UpdateSettingResponseAction
    | UpdateSettingErrorAction
    | SetLastUpdateRequestAction
    | SetLastUpdateResponseAction
    | LoginAsUserRequestAction
    | LoginAsUserResponseAction
    | LoginAsUserErrorAction
    | LogoutAsUserRequestAction
    | LogoutAsUserResponseAction
    | LogoutAsUserErrorAction
    | LogUrlRequestAction
    | LogUrlResponseAction
    | LogUrlErrorAction
    | TableCompanyRequestAction
    | TableCompanyResponseAction
    | TableCompanyErrorAction
    | TableCustomerRequestAction
    | TableCustomerResponseAction
    | TableCustomerErrorAction
    | ShowRightSidebarAction
    | UpdateRightSidebarAction
    | GetDocsRequestAction
    | GetDocsResponseAction
    | GetDocsErrorAction

export class SetLastUpdateRequestAction {
    readonly type = ActionType.SET_LAST_UPDATE
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class SetLastUpdateResponseAction {
    readonly type = ActionType.SET_LAST_UPDATE
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: SetLastUpdateRequestAction
    ) {
    }
}


export class ReloadTokenRequestAction {
    readonly type = ActionType.RELOAD_TOKEN
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class ReloadTokenResponseAction {
    readonly type = ActionType.RELOAD_TOKEN
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: ReloadTokenRequestAction,
        public token: string | null
    ) {
    }
}

export class ReloadTokenErrorAction {
    readonly type = ActionType.RELOAD_TOKEN
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: ReloadTokenRequestAction,
        public error: string,
    ) {
    }
}


export class TryToLoadUserRequestAction {
    readonly type = ActionType.TRY_TO_LOAD_USER
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class TryToLoadUserResponseAction {
    readonly type = ActionType.TRY_TO_LOAD_USER
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: TryToLoadUserRequestAction,
        public data: UserDetails,
    ) // public auth: AuthState,
    {
    }
}

export class TryToLoadUserErrorAction {
    readonly type = ActionType.TRY_TO_LOAD_USER
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: TryToLoadUserRequestAction,
        public error: string,
    ) {
    }
}

export class SelectCustomerRequestAction {
    readonly type = ActionType.SELECT_CUSTOMER
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class SelectCustomerResponseAction {
    readonly type = ActionType.SELECT_CUSTOMER
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: SelectCustomerRequestAction,
        public select_customer: SelectCustomer,
    ) // public auth: AuthState,
    {
    }
}

export class SelectCustomerErrorAction {
    readonly type = ActionType.SELECT_CUSTOMER
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: SelectCustomerRequestAction,
        public error: string,
    ) {
    }
}


export class GetCustomerSelfRequestAction {
    readonly type = ActionType.GET_CUSTOMER_SELF
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class GetCustomerSelfResponseAction {
    readonly type = ActionType.GET_CUSTOMER_SELF
    readonly mode = AsyncActionMode.RESPONSE

    constructor(public request: GetCustomerSelfRequestAction, public data: CustomerDetails) {
    }
}

export class GetCustomerSelfErrorAction {
    readonly type = ActionType.GET_CUSTOMER_SELF
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: GetCustomerSelfRequestAction, public error: string) {
    }
}

export class UserDetailsRequestAction {
    readonly type = ActionType.USER_DETAILS
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class UserDetailsResponseAction {
    readonly type = ActionType.USER_DETAILS
    readonly mode = AsyncActionMode.RESPONSE

    constructor(
        public request: UserDetailsRequestAction,
        public data: UserDetails,
    ) {
    }
}

export class UserDetailsErrorAction {
    readonly type = ActionType.USER_DETAILS
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: UserDetailsRequestAction,
        public error: string,
    ) {
    }
}

export class GetMenuRolesRequestAction {
    readonly type = ActionType.GET_MENU_ROLES
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class GetMenuRolesResponseAction {
    readonly type = ActionType.GET_MENU_ROLES
    readonly mode = AsyncActionMode.RESPONSE

    constructor(public request: GetMenuRolesRequestAction, public data: any) {
    }
}

export class GetMenuRolesErrorAction {
    readonly type = ActionType.GET_MENU_ROLES
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: GetMenuRolesRequestAction, public error: string) {
    }
}

export class TokenExpiredAction {
    readonly type = ActionType.TOKEN_EXPIRED

    constructor() {
        ''
    }
}

export class UserLoginRequestAction {
    readonly type = ActionType.LOGIN_USER
    readonly mode = AsyncActionMode.REQUEST

    constructor(public payload: LoginParams) {
    }
}

export class UserLoginResponseAction {
    readonly type = ActionType.LOGIN_USER
    readonly mode = AsyncActionMode.RESPONSE

    constructor(public request: UserLoginRequestAction, public data: Auth2faResponseData) {
    }
}

export class UserLoginErrorAction {
    readonly type = ActionType.LOGIN_USER
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: UserLoginRequestAction, public error: string) {
    }
}

export class SetPasswordRequestAction {
    readonly type = ActionType.SET_PASSWORD
    readonly mode = AsyncActionMode.REQUEST

    constructor(public payload: SetPasswordParams) {
    }
}

export class SetPasswordResponseAction {
    readonly type = ActionType.SET_PASSWORD
    readonly mode = AsyncActionMode.RESPONSE

    constructor(public request: SetPasswordRequestAction, public data: Auth2faResponseData) {
    }
}

export class SetPasswordErrorAction {
    readonly type = ActionType.SET_PASSWORD
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: SetPasswordRequestAction, public error: string) {
    }
}

export class SetPassRequestRequestAction {
    readonly type = ActionType.SET_PASSWORD_REQUEST;
    readonly mode = AsyncActionMode.REQUEST;

    constructor(public requestId: string) {
    }
}

export class SetPassRequestResponseAction {
    readonly type = ActionType.SET_PASSWORD_REQUEST;
    readonly mode = AsyncActionMode.RESPONSE;

    constructor(
        public request: SetPassRequestRequestAction,
        public data: string
    ) {
    }
}

export class SetPassRequestErrorAction {
    readonly type = ActionType.SET_PASSWORD_REQUEST;
    readonly mode = AsyncActionMode.ERROR;

    constructor(
        public request: SetPassRequestRequestAction,
        public error: string
    ) {
    }
}

export class SendConfirmRequestAction {
    readonly type = ActionType.SEND_2FA_CODE
    readonly mode = AsyncActionMode.REQUEST

    constructor(public payload: ParamsSendCode) {
    }
}

export class SendConfirmResponseAction {
    readonly type = ActionType.SEND_2FA_CODE
    readonly mode = AsyncActionMode.RESPONSE

    constructor(public request: SendConfirmRequestAction, public data: SendCodeResponseData) {
    }
}

export class SendConfirmErrorAction {
    readonly type = ActionType.SEND_2FA_CODE
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: SendConfirmRequestAction, public error: string) {
    }
}


export class AuthConfirmRequestAction {
    readonly type = ActionType.LOGIN_2FA
    readonly mode = AsyncActionMode.REQUEST

    constructor(public payload: Params2FA) {
    }
}

export class AuthConfirmResponseAction {
    readonly type = ActionType.LOGIN_2FA
    readonly mode = AsyncActionMode.RESPONSE

    constructor(public request: AuthConfirmRequestAction, public data: Auth2faResponseData) {
    }
}

export class AuthConfirmErrorAction {
    readonly type = ActionType.LOGIN_2FA
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: AuthConfirmRequestAction, public error: string) {
    }
}

export class UserLogoutRequestAction {
    readonly type = ActionType.LOGOUT_USER
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class UserLogoutResponseAction {
    readonly type = ActionType.LOGOUT_USER
    readonly mode = AsyncActionMode.RESPONSE

    constructor() {
        ''
    }
}

export class UserLogoutErrorAction {
    readonly type = ActionType.LOGOUT_USER
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: UserLogoutRequestAction, public error: string) {
    }
}

export class GetSettingSelfRequestAction {
    readonly type = ActionType.GET_SETTING_SELF
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}

export class GetSettingSelfResponseAction {
    readonly type = ActionType.GET_SETTING_SELF
    readonly mode = AsyncActionMode.RESPONSE

    constructor(public request: GetSettingSelfRequestAction, public data: SettingsDetails[]) {
    }
}

export class GetSettingSelfErrorAction {
    readonly type = ActionType.GET_SETTING_SELF
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: GetSettingSelfRequestAction, public error: ErrorSetting) {
    }
}

export class UpdateSelfSettingRequestAction {
    readonly type = ActionType.UPDATE_SELF_SETTINGS
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: SettingsParams) {}
}
export class UpdateSelfSettingResponseAction {
    readonly type = ActionType.UPDATE_SELF_SETTINGS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: UpdateSelfSettingRequestAction,
        public data: SettingsDetails,
    ) {}
}
export class UpdateSelfSettingErrorAction {
    readonly type = ActionType.UPDATE_SELF_SETTINGS
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: UpdateSelfSettingRequestAction,
        public error: string,
    ) {}
}

export class UpdateSettingRequestAction {
    readonly type = ActionType.UPDATE_SETTING_ITEM
    readonly mode = AsyncActionMode.REQUEST

    constructor(public payload: SettingsDetails) {
    }
}

export class UpdateSettingResponseAction {
    readonly type = ActionType.UPDATE_SETTING_ITEM
    readonly mode = AsyncActionMode.RESPONSE

    constructor(public request: UpdateSettingRequestAction, public data: SettingsDetails) {
        console.log('data: ' + JSON.stringify(data))
    }
}

export class UpdateSettingErrorAction {
    readonly type = ActionType.UPDATE_SETTING_ITEM
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: UpdateSettingRequestAction, public error: ErrorSetting) {
    }
}

export class LoginAsUserRequestAction {
    readonly type = ActionType.LOGIN_USER_AS
    readonly mode = AsyncActionMode.REQUEST

    constructor(public payload: LoginAsParams) {
    }
}

export class LoginAsUserResponseAction {
    readonly type = ActionType.LOGIN_USER_AS
    readonly mode = AsyncActionMode.RESPONSE

    constructor(public request: LoginAsUserRequestAction, public data: AuthState) {
    }
}

export class LoginAsUserErrorAction {
    readonly type = ActionType.LOGIN_USER_AS
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: LoginAsUserRequestAction, public error: string) {
    }
}

export class LogoutAsUserRequestAction {
    readonly type = ActionType.LOGOUT_USER_AS
    readonly mode = AsyncActionMode.REQUEST

    constructor(public payload: LogoutAsParams) {
    }
}

export class LogoutAsUserResponseAction {
    readonly type = ActionType.LOGOUT_USER_AS
    readonly mode = AsyncActionMode.RESPONSE

    constructor(public request: LogoutAsUserRequestAction) {
        ''
    }
}

export class LogoutAsUserErrorAction {
    readonly type = ActionType.LOGOUT_USER_AS
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: LogoutAsUserRequestAction, public error: string) {
    }
}

export class LogUrlRequestAction {
    readonly type = ActionType.LOG_URL
    readonly mode = AsyncActionMode.REQUEST

    constructor() {
        ''
    }
}
export class LogUrlResponseAction {
    readonly type = ActionType.LOG_URL
    readonly mode = AsyncActionMode.RESPONSE

    constructor(public request: LogUrlRequestAction, public url: string) {
    }
}
export class LogUrlErrorAction {
    readonly type = ActionType.LOG_URL
    readonly mode = AsyncActionMode.ERROR

    constructor(public request: LogUrlRequestAction, public error: string) {
    }
}

export class TableCompanyRequestAction {
    readonly type = ActionType.TABLE_COMPANY
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class TableCompanyResponseAction {
    readonly type = ActionType.TABLE_COMPANY
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: TableCompanyRequestAction, public data: CompanyDetails[]) {
    }
}
export class TableCompanyErrorAction {
    readonly type = ActionType.TABLE_COMPANY
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: TableCompanyRequestAction, public error: string) {
    }
}
export class TableCustomerRequestAction {
    readonly type = ActionType.TABLE_CUSTOMER
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class TableCustomerResponseAction {
    readonly type = ActionType.TABLE_CUSTOMER
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: TableCustomerRequestAction, public data: CustomerDetails[]) {
    }
}
export class TableCustomerErrorAction {
    readonly type = ActionType.TABLE_CUSTOMER
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: TableCustomerRequestAction, public error: string) {
    }
}

export class GetDocsRequestAction {
    readonly type = ActionType.GET_DOCS
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetDocsResponseAction {
    readonly type = ActionType.GET_DOCS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetDocsRequestAction, public data: HelperDocument) {
    }
}
export class GetDocsErrorAction {
    readonly type = ActionType.GET_DOCS
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetDocsRequestAction, public error: string) {
    }
}

export class ShowRightSidebarAction {
    readonly type = ActionType.SHOW_RIGHT_SIDEBAR
    constructor(public isVisible: boolean) {}
}

export class UpdateRightSidebarAction {
    readonly type = ActionType.UPDATE_RIGHT_SIDEBAR
    constructor(public payload: RightSiderContent) {}
}
