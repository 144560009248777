import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {ResGroupModel} from "../models";
import {CreateResGroupErrorAction, CreateResGroupRequestAction, CreateResGroupResponseAction} from "./index";


export default (params: ResGroupModel, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | CreateResGroupRequestAction
                | CreateResGroupResponseAction
                | CreateResGroupErrorAction,
        ) => void,
    ) => {
        const request = new CreateResGroupRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<ResGroupModel>(`/customer/${params.customer_id}/resource-group`, params)
            .then((response) => {
                dispatch(new CreateResGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new CreateResGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
