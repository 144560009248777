import { ActionType, IpSubnetActions } from './actions'
import {AsyncActionMode, PAGE1} from 'common/models'
import {IpSubnetModel, SubnetPageState} from './models'

const INITIAL_STATE: SubnetPageState = {
  isSaving: false,
  isLoadingSubnets: false,
  isLoadingSubnet: false,
  isLoadingIps: false,
  isLoadingIp: false,
  error: undefined,
  dnsServiceNames: [],
  subnets: {items: [], pagination: PAGE1},
  pager: PAGE1,
  ips: {items: [], pagination: PAGE1},
  lookup_subnet_note: [],
  lookup_subnet_cidr: [],
  lookup_subnet_reverse: [],
  lookup_ip_note: [],
  lookup_ip_addr4: [],
  lookup_ip_addr6: [],
  type_id: 1,
  isLoadingLookup: false
}

export default (
  state: SubnetPageState = INITIAL_STATE,
  action: IpSubnetActions,
): SubnetPageState => {
  switch (action.type) {
    case ActionType.GET_DNSSERVICES2:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoadingSubnets: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoadingSubnets: false,
          dnsServiceNames: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingSubnets: false,
          error: action.error,
        }
      }
      break
    case ActionType.LIST_SUBNET_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoadingSubnets: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoadingSubnets: false,
          subnets: action.data,
          pager: {...action.data.pagination, page: action.data.pagination.page+1},
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingSubnets: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_SUBNET_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return {
          ...state,
          isLoadingSubnet: true,
          isLoadingIps: true,
          //ips: {items: [], pagination: PAGE1},
          subnet: undefined }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoadingSubnet: false,
          isLoadingIps: false,
          subnet: action.data,
          //ips: {items: action.data.ips, pagination: PAGE1},
          error: ''
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingSubnet: false,
          isLoadingIps: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_SUBNET_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        // add to first pos.
        let new_list = [...state.subnets.items]  // new obj
        new_list.unshift(action.data)
        return {
          ...state,
          isSaving: false,
          subnets: {
            ...state.subnets,
            items: new_list
          },
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_SUBNET_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          subnet: action.data,
          subnets: {
            ...state.subnets,
            items: state.subnets.items.map((s) =>
                s.id === action.data.id ? { ...action.data } : s,
            )},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_SUBNET_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          subnet: undefined,
          subnets: {
            ...state.subnets,
            items: state.subnets.items.filter(
                (s) => s.id !== action.request.id,
            )},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.LOOKUP_SUBNET:
      if (action.mode === AsyncActionMode.REQUEST) {
        if (action.field === 'cidr') {
          return { ...state,
            isLoadingLookup: true,
            lookup_subnet_cidr:[]
          }
        }
        if (action.field === 'note') {
          return { ...state,
            isLoadingLookup: true,
            lookup_subnet_note:[]
          }
        }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        if (action.field === 'cidr') {
          return {
            ...state,
            lookup_subnet_cidr: action.data,
            isLoadingLookup: false
          }
        }
        if (action.field === 'note') {
          return {
            ...state,
            lookup_subnet_note: action.data,
            isLoadingLookup: false
          }
        }
        if (action.field === 'reverse_domain') {
          return {
            ...state,
            lookup_subnet_reverse: action.data,
            isLoadingLookup: false
          }
        }
        console.error('Wrong action field.')
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isLoadingLookup: false
        }
      }
      break

    case ActionType.LOOKUP_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        if (action.field === 'address') {
          return { ...state,
            isLoadingLookup: true,
            type_id: action.type_id,
            lookup_ip_addr4:[],
            lookup_ip_addr6:[]
          }
        }
        if (action.field === 'note') {
          return { ...state,
            isLoadingLookup: true,
            lookup_ip_note:[]
          }
        }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        if (action.field === 'address') {
          if (state.type_id === 2) {
            return { ...state,
              isLoadingLookup: false,
              lookup_ip_addr4: [],
              lookup_ip_addr6: action.data
            }
          }
          return { ...state,
            isLoadingLookup: false,
            lookup_ip_addr4: action.data,
            lookup_ip_addr6:[]
          }
        }
        if (action.field === 'note') {
          return { ...state,
            isLoadingLookup: false,
            lookup_ip_note:action.data,
          }
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isLoadingLookup: false
        }
      }
      break
    case ActionType.LIST_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return {...state, ips: {items: [], pagination: PAGE1}, isLoadingIps: true}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoadingIps: false,
          ips: action.data,
          lookup_ip_addr6: [],
          lookup_ip_note: [],
          lookup_ip_addr4: [],
          lookup_subnet_cidr: [],
          lookup_subnet_note: [],
          lookup_subnet_reverse: [],
          pager: {...action.data.pagination, page: action.data.pagination.page+1},
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingIps: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return {
          ...state,
          isLoadingIp: true,
          ip: undefined
        }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoadingIp: false,
          ip: action.data,
          error: ''
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingIp: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          ip: action.data,
          ips: {
            ...state.ips,
            items: state.ips.items.map((s) =>
                s.id === action.data.id ? { ...action.data } : s,
            )},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.CREATE_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          ip: action.data,
          ips: {
            ...state.ips,
            items: state.ips.items.concat(action.data)
          },
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          ip: undefined,
          ips: {
            ...state.ips,
            items: state.ips.items.filter((ip) => ip.id != action.id),
          },
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.ADD_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          ip: action.data,
          ips: {
            ...state.ips,
            items: state.ips.items.map((ip) =>
                ip.id === action.data.id ? { ...action.data } : ip,
            )},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.REMOVE_IP:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          ip: action.data,
          ips: {
            ...state.ips,
            items: state.ips.items.map((ip) =>
                ip.id === action.data.id ? { ...action.data } : ip,
            )},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
  }
  return state
}
