import React, {useState, useEffect} from 'react'
import {Card, Button, Modal, Table, Space, AutoComplete} from 'antd'
import {
	CheckCircleTwoTone, DeleteTwoTone, EditTwoTone,
	PlusSquareOutlined,
	SafetyCertificateOutlined,
	StopTwoTone
} from '@ant-design/icons/lib/icons'
import './CertificatesPage.scss'
import {useTranslation} from 'react-i18next'
import {CertificateModel, CertificateVhost, CreateCertificateParams, UpdateCertificateParams} from './models'
import {useDispatch, useSelector} from 'react-redux'
import updateCertificate from './actions/updateCertificate'
import createCertificate from './actions/createCertificate'
import CertificateForm from 'pages/certificate/CertificateForm'
import deleteCertificate from './actions/deleteCertificate'
import getCertificates from './actions/getCertificates'
import PopConfirmZis from "../../components/popconfirm/PopConfirmZis";
import {AppState} from "../../common/models";
import TotalNum from "../../components/TotalNum/TotalNum";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../components";
import ErrorPage403 from "../../components/Errors/ErrorPage403";
import {removeDiac, stopPropagation} from "../../common/fce";
import {sort_searchable} from "../../common/sorting";
import usePageSize from "../../common/usePageSize";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import {PAGING} from "../../common/enums";
import {PlusCircleOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import {CompanyDetails} from "../company/models";
import getRdbServices from "../database/actions/getRdbServices";



const CertificatesPage = () => {
	const CONTROL_NAME = 'page_certificates'
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const {search} = useLocation()

	const {certificates, isLoading} = useSelector((state: AppState) => state.certificate)
	const {ssl_certs} = useSelector((state: AppState) => state.sidenav)

	const [dataSource, setDataSource] = useState<CertificateModel[]>([])
	const [searchableCertificates, setSearchableCertificates] = useState<CertificateModel[]>([])

	const [isCertViewer, setCertViewer] = useState(false)
	const [isCertCreator, setCertCreator] = useState(false)
	const [isCertEditor, setCertEditor] = useState(false)
	const [isCertDeleter, setCertDeleter] = useState(false)

	const [isModalVisible, setModalVisible] = useState<boolean>(false)
	const [dataToUpdate, setDataToUpdate] = useState<CertificateModel>()
	const [pageSize, setPageSize] = useState<string>()
	const [searchName, setSearchName] = useState<string>('')
	const [searchComment, setSearchComment] = useState<string>('')

	const hideModal = () => setModalVisible(false)
	const showModal = () => setModalVisible(true)

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator/>
			</div>
		)
	}

	// required authorization
	if (!loggedUser.hasAccess(CONTROL_NAME)) {
		return <ErrorPage403 />
	}

	// settings
	const appSetting = loggedUser.getAppSettings()
	const SEARCH_MIN = appSetting.min_search_length

	// logger
	const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('CertificatesPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('CompaniesPage: ' + msg)} }
	usePageSize(appSetting, loggedUser.user.id, pageSize)

	useEffect(() => {
		const access = loggedUser.hasAccess('page_certificates')
		setCertViewer(access)
		setCertCreator(loggedUser.hasAccess('page_certificates_create_button'))
		setCertEditor(loggedUser.hasAccess('page_certificates_edit_button'))
		if (!isLoading && (access)) {
			dispatch(getCertificates())
		}
		//dispatch(getServers())
	}, [dispatch])

	useEffect(() => {
		// trigger from Side menu clicked
		if (!isLoading && (isCertViewer)) {
			// reload
			dispatch(getCertificates())
		}
	}, [ssl_certs])

	useEffect(() => {
		setSearchableCertificates(certificates.map(c => {
			return {...c, searchableName: removeDiac(c.name)}
		}))
	}, [certificates])

	useEffect(() => {
		if (!isModalVisible) {
			refreshGrid()
		}
	}, [searchableCertificates, searchName, searchComment])

	const filtered = () => {
		if (!searchableCertificates) {
			return []
		}

		let qs:string[] = []
		let data = searchableCertificates.map(c => ({ ...c }))

		for (const item of data) {
			logger(`${item.name} - ${item.valid_to} < ${Date.now()/1000}`)
		}

		if (searchName && searchName.length > SEARCH_MIN) {
			const qName = (c) => c.name.toLowerCase().includes(removeDiac(searchName))
			qs.push('name='+removeDiac(searchName))
			data = data.filter((c) => qName(c))
		}

		if (searchComment && searchComment.length > SEARCH_MIN) {
			const qComment = (c) => removeDiac(c.comment).includes(removeDiac(searchComment))
			qs.push('comment='+removeDiac(searchComment))
			data = data.filter((c) => qComment(c))
		}

		history.replace('/certificates?' + qs.join("&"))
		return data
	}

	const refreshGrid = () => {
		logger(`refreshGrid: ${searchName}`)
		setDataSource(filtered().sort(sort_searchable))
	}

	const onFinish = (values: CreateCertificateParams | UpdateCertificateParams) => {
		dataToUpdate
			? 'id' in values &&
			  dispatch(
					updateCertificate({...values, auto_prolong: values.auto_prolong ? 1 : 0}, (isOk) => isOk && hideModal()),
			  )
			: 'name' in values &&
			  dispatch(
					createCertificate({...values, auto_prolong: values.auto_prolong ? 1 : 0}, (isOk) => isOk && hideModal()),
			  )
	}

	const onDelete = (id: number) => {
		dispatch(deleteCertificate(id, (isOk) => isOk && hideModal()))
	}

	const FilterByName = (
		<AutoComplete
			placeholder={t('certificatesPage.name')}
			style={{width: '200px'}}
			value={searchName}
			allowClear={true}
			onClick={stopPropagation}
			onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
			onChange={(v) => {
				setSearchName(v)
			}}
		/>
	)

	const FilterByComment = (
		<AutoComplete
			placeholder={t('certificatesPage.comment')}
			style={{width: '200px'}}
			value={searchComment}
			allowClear={true}
			onClick={stopPropagation}
			onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
			onChange={(v) => {
				setSearchComment(v)
			}}
		/>
	)

	const childrenColumns = [
		{
			title: t('certificatesPage.usage'),
			ellipsis: true,
			dataIndex: 'usage',
			key: 'usage',
			width: '25%',
		},
		{
			title: t('certificatesPage.challenge'),
			dataIndex: 'challenge',
			key: 'challenge',
			width: '10%',
		},
		{
			title: t('certificatesPage.comment'),
			dataIndex: 'comment',
			key: 'comment',
		},
	]
	const columns: ColumnsType<CertificateModel> = [
		{
			title: FilterByName,
			dataIndex: 'name',
			key: 'name',
			width: '20%',
			className: 'left',
			ellipsis: true,
			fixed: true
		},
		{
			title: t('certificatesPage.valid_to'),
			dataIndex: 'valid_to',
			key: 'valid_to',
			width: '10%',
			render: (val: number, rec: CertificateModel) => appSetting.renderDate(rec.valid_to)
		},
		{
			title: t('certificatesPage.auto_prolong'),
			dataIndex: 'auto_prolong',
			key: 'auto_prolong',
			width: '7%',
			render: (val: number, rec: CertificateModel) =>
				rec.auto_prolong ? <><CheckCircleTwoTone twoToneColor='green' /> </> : <StopTwoTone twoToneColor='red' />,
		},
		{
			title: FilterByComment,
			dataIndex: 'comment',
			key: 'comment',
			width: '50%',
			ellipsis: true,
			render: (val: number, rec: CertificateModel) => <span>{rec.comment}</span>
		},
		{
			title: t('certificatesPage.action'),
			key: 'action',
			width: '100px',
			render: (text, record: CertificateModel) => (
				<Space>
					<PopConfirmZis onConfirm={() => onDelete(record.id)}>
						<Button type='text' icon={<DeleteTwoTone twoToneColor='red' />} />
					</PopConfirmZis>

					<Button
						type='text'
						icon={<EditTwoTone twoToneColor='green' />}
						onClick={() => {
							setDataToUpdate(record)
							showModal()
						}}
					/>
				</Space>
			),
		},
	]

	const nestedTable = (props) => (
		<Table
			{...props}
			scroll
			columns={childrenColumns}
			dataSource={[]}
		/>
	)

	return (
		<>
			<Card
				title={
					<>
						<SafetyCertificateOutlined /> &nbsp; {t('certificatesPage.title')}
					</>
				}
				className='certificatesPage'
				extra={
					loggedUser.hasAccess('page_certificates_create_button') && (
						<Button type='primary'
								onClick={() => {
									setDataToUpdate(undefined)
									setModalVisible(true)
								}}>
							<PlusCircleOutlined/> {t('certificatesPage.create_title')}{' '}
						</Button>
					)
				}
				loading={false}>
				<Table<CertificateModel>
					rowKey='id'
					className='CertificatesTable'
					rowClassName={(rec) => (rec && rec.valid_to && rec.valid_to < Date.now()/1000 ? 'old' : '')}
					columns={columns}
					bordered={true}
					expandable={{
						expandedRowRender: nestedTable,
					}}
					dataSource={dataSource}
					loading={isLoading}

					showHeader={true}
					pagination={{
						defaultPageSize: appSetting.grid_page_size,
						pageSizeOptions: PAGING,
						showSizeChanger: true
					}}
					onChange={(ev) => {
						setPageSize(`${ev.pageSize}`)
					}}
					footer={() => TotalNum(Number(certificates?.length), 'Certificates', certificates)}
				/>
			</Card>

			<Modal
				destroyOnClose
				style={{top: 20}}
				width={600}
				title={
					<>
						<SafetyCertificateOutlined /> &nbsp; {t('certificatesPage.form_title')}
					</>
				}
				visible={isModalVisible}
				onCancel={hideModal}
				footer={null}
				confirmLoading={true}>
				<CertificateForm onFinish={onFinish} dataToUpdate={dataToUpdate} />
			</Modal>
		</>
	)
}

export default CertificatesPage
