import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {SettingsDetails, SettingsParams} from "../../settings/models";
import {UpdateSelfSettingErrorAction, UpdateSelfSettingRequestAction, UpdateSelfSettingResponseAction} from "./index";


export default (params: SettingsParams, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (args: UpdateSelfSettingRequestAction | UpdateSelfSettingResponseAction | UpdateSelfSettingErrorAction) => void) => {
        const request = new UpdateSelfSettingRequestAction(params)
        dispatch(request)

        delete params['id']
        protectedApiClient.post<SettingsDetails>(`/self/setting`, params)
            .then(response => {
                dispatch(new UpdateSelfSettingResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch(error => {
                dispatch(new UpdateSelfSettingErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}