import {
    DeleteInventoryTypeRequestAction,
    DeleteInventoryTypeResponseAction,
    DeleteInventoryTypeErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | DeleteInventoryTypeRequestAction
                | DeleteInventoryTypeResponseAction
                | DeleteInventoryTypeErrorAction,
        ) => void,
    ) => {
        const request = new DeleteInventoryTypeRequestAction(id)
        dispatch(request)

        protectedApiClient
            .delete(`/inventory/type/${id}`)
            .then((response) => {
                dispatch(new DeleteInventoryTypeResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteInventoryTypeErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
