import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {LoadingIndicator} from "../../../components";
import React, {useEffect, useRef, useState} from "react";
import {Card, Col, Input, message, Modal, Row, Select, Space, Table, Tag} from "antd";
import Button from "antd-button-color";
import {
    InfoCircleOutlined,
    InfoCircleTwoTone,
    MinusCircleTwoTone,
    PlusCircleTwoTone,
    UserSwitchOutlined
} from "@ant-design/icons";
import {UserDetails} from "../../user/models";
import {AppState} from "../../../common/models";
import {renderResGroup, renderRole, renderSelected, renderUser, renderUserGroup} from "../common";
import {_isAllowed, EmptyFunction, stopPropagation, ZCOM_CUSTOMER_ID} from "../../../common/fce";
import {sort_label, sort_name, sort_title} from "../../../common/sorting";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {Link, useLocation, useParams} from "react-router-dom";
import queryString from "query-string";
import {ResGroupModel} from "../resources/models";
import {UserGroupModel} from "../usergroups/models";
import {ColumnsType} from "antd/lib/table";
import "./UserRightsPage.scss"
import getGroupsAll from "./actions/getGroupsAll";
import getUserWithGroups from "./actions/getUserWithGroups";
import addUserToGroup from "./actions/addUserToGroup";
import removeUserFromGroup from "./actions/removeUserFromGroup";
import getGroupsByCustomer from "./actions/getGroupsByCustomer";
import HistoryLog from "../../../components/History/HistoryLog";
import getSelf from "../../login/actions/getSelf";
import {useHistory} from "react-router";
import getUsersRights from "./actions/getUsersRights";
import {ROLE_CUSTOMER_MANAGER, ROLE_USER_MANAGER} from "../../../common/LoggedUser";

interface RoleItem {
    label: string
    value: string
}

interface ParamTypes {
    id: number
}


const UserRightsPage = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {search} = useLocation()
    const history = useHistory()
    const {user_id} = queryString.parse(search)

    const {customers} = useSelector((state: AppState) => state.auth.tables)
    const {users, roles, groups,groupsAll, resGroups, isLoadingUser} = useSelector((state: AppState) => state.userRightsPage)
    const {rights_users} = useSelector((state: AppState) => state.sidenav)

    const [custOptions, setCustOptions] = useState<{ label: string, value: number }[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [searchUser, setSearchUser] = useState<string>('')
    const [searchGroupAll, setSearchGroupAll] = useState<string>('')
    const [selectedUser, setSelectedUser] = useState<UserDetails | undefined>()
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [forceUpdateHistoryId, setForceUpdateHistoryId] = useState<number>(1)

    const [userSource, setUserSource] = useState<UserDetails[]>([]);
    const [groupSource, setGroupSource] = useState<UserGroupModel[]>([])
    const [groupAllSource, setAllGroupSource] = useState<UserGroupModel[]>([])
    const [roleSource, setRoleSource] = useState<RoleItem[]>([])
    const [resGroupSource, setResGroupSource] = useState<ResGroupModel[]>([])

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('UserRightsPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('UserRightsPage: ' + msg)} }

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    // permissions
    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isDeleter, setDeleter] = useState(false)

    useEffect(() => {
        const access_users = loggedUser.hasAccess('page_rights_users')
        setViewer(access_users)
        setCreator(loggedUser.hasAccess('page_usergroups_add_member'))
        setDeleter(loggedUser.hasAccess('page_usergroups_remove_member'))
        if (!access_users) {
            // go to dashboard
            message.error(t('general.error403'))
            history.replace('/')
            return
        }

        // set user
        let uid = 0
        let cid = localStorage.getItem('customerId')
        if (!cid) {
            cid = `${ZCOM_CUSTOMER_ID}`
            localStorage.setItem('customerId', cid)
        }
        if (user_id) {
            if (typeof user_id === 'string') {
                uid = parseInt(user_id)
                if (uid > 0) {
                    const u = users.find( u => u.id === uid)
                    if (cid && parseInt(cid) > 0) {
                        if (u && u.customer_id === parseInt(cid)) {
                            setSelectedUser(u)
                            setSelectedCustomerId(parseInt(cid))
                        }
                    }
                    else {
                        setSelectedUser(u)
                    }
                }
            }
        }
        if (uid === 0) {
            setSelectedUser(loggedUser.user)
            if (cid && parseInt(cid) > 0) {
                setSelectedCustomerId(parseInt(cid))
            }
        }
        dispatch(getUsersRights())
    }, [])

    useEffect(() => {
        // Customer Options
        logger('customers.length='+customers.length)
        if (customers && customers.length) {
            if ( loggedUser.user.is_zcom ) {
                const items = customers.map(c => ({label: c.company!.name ? c.company!.name : '', value: c.id}))
                setCustOptions(items.sort(sort_label))
            }
        }
    }, [customers])

    useEffect(() => {
        fetchAllGroups()
        renderUsers()
        renderGroups()
        renderGroupsAll()
        renderResGroups()
    }, [selectedCustomerId])

    useEffect(() => {
        renderGroupsAll()
    }, [groups, groupsAll, searchGroupAll])

    useEffect(() => {
        renderGroups()
    }, [groups])

    useEffect(() => {
        renderRoles()
    }, [roles])

    useEffect(() => {
        renderResGroups()
    }, [resGroups])

    const loadUser = (userId: number) => {
        dispatch(getUserWithGroups(userId, suc => {
            !suc && history.push('/rights/user')
        }))
    }

    useEffect(() => {
        if (selectedUser && selectedUser.id) {
            loadUser(selectedUser.id)
        }
        renderGroups()
        renderRoles()
        renderResGroups()
    }, [selectedUser])

    useEffect(() => {
        renderUsers()
    }, [users, searchUser])

    const fetchAllGroups = () => {
        if (selectedCustomerId && selectedCustomerId > 1) {
            // Customers
            dispatch(getGroupsByCustomer(selectedCustomerId))
        }
        else {
            // ZCOM
            dispatch(getGroupsAll())
        }
    }

    const filteredUsers = () => {
        if (!users || users.length === 0) {
            return []
        }

        let data = users.filter(u => u.deleted_at === 0)
        if (selectedCustomerId) {
            data = data.filter((u) => u.customer_id === selectedCustomerId)
        }
        if (searchUser && searchUser.length>SEARCH_MIN) {
            data = data.filter((u) => u.title?.toLowerCase().includes(searchUser.toLowerCase()))
        }
        return data.sort(sort_title)
    }

    const renderUsers = () => {
        setUserSource(filteredUsers())
    }

    const filteredGroupsAll = () => {
        if (!groupsAll || groupsAll.length === 0) {
            return []
        }
        let data = groupsAll
        data = data.map(g => { return {...g, title: getGroupTitle(g)} })
        if (selectedCustomerId) {
            data = data.filter(g => g.customer_id === selectedCustomerId)
        }
        if (searchGroupAll && searchGroupAll.length>SEARCH_MIN) {
            data = data.filter((u) => u.title?.toLowerCase().includes(searchGroupAll.toLowerCase()))
        }

        const iid = groups.map(r => r.id)
        if (iid) {
            // filter out already selected
            data = data.filter((r) => !iid.includes(r.id))
        }
        return data.sort(sort_title)
    }

    const getCustomerName = (cid: number) => {
        const c = customers.find(c => c.id === cid)
        return c && c.company!.name ? c.company!.name : '?'+cid
    }

    const onChangeCustomer = (value) => {
        setSelectedCustomerId(value)
        localStorage.setItem('customerId', value)
        setSelectedUser(undefined)
    }

    const getGroupTitle = (g: UserGroupModel): string => {
        if (g.title === 'root') {
            return 'Root (' + getCustomerName(g.customer_id) + ')'
        }
        if (g.title === 'login') {
            return 'Login (' + getCustomerName(g.customer_id) + ')'
        }
        return g.title
    }

    const renderGroupsAll = () => {
        setAllGroupSource(filteredGroupsAll())
    }

    const filteredGroups = () => {
        if (!groups || groups.length === 0) {
            return []
        }
        if (!selectedUser) {
            return []
        }
        let data = groups
        if (selectedCustomerId) {
            data = data.filter(g => g.customer_id === selectedCustomerId)
        }
        return data.sort(sort_title)
    }

    const renderGroups = () => {
        setGroupSource(filteredGroups())
    }

    const filteredResGroups = () => {
        if (!resGroups || resGroups.length === 0) {
            return []
        }
        if (!selectedUser) {
            return []
        }

        let data = resGroups
        if (selectedCustomerId) {
            data = data.filter(g => g.customer_id === selectedCustomerId)
        }
        return data.sort(sort_name)
    }

    const renderResGroups = () => {
        setResGroupSource(filteredResGroups())
    }

    const renderRoles = () => {
        if (!selectedUser) {
            setRoleSource([])
        }
        else {
            setRoleSource(roles.map(r => {return {label: r, value: r}}).sort(sort_label))
        }
    }

    const FilterByUser = (
        <Input
            placeholder={t('rights.users.user')}
            style={{ width: '100%' }}
            value={searchUser}
            allowClear={true}
            onClick={stopPropagation}
            onChange={(current) => {
                setSearchUser(current.target.value)
            }}
        />
    )

    const FilterByGroupAll = (
        <Input
            placeholder={t('rights.users.groups_all')}
            style={{ width: '100%' }}
            value={searchGroupAll}
            allowClear={true}
            onClick={stopPropagation}
            onChange={(current) => {
                setSearchGroupAll(current.target.value)
            }}
        />
    )

    const columnsUser: ColumnsType<UserDetails> = [
        {
            title: FilterByUser,
            dataIndex: 'title',
            key: 'id',
            width: '60%',
            className: 'td-blue tdOver',
            render: (_, rec: UserDetails) => renderUser(rec.title, getCustomerName(rec.customer_id), !!selectedUser && selectedUser.id === rec.id, undefined)
        },
        {
            title: 'Company',
            dataIndex: 'customer_id',
            key: 'id',
            ellipsis: true,
            render: (_, rec: UserDetails) => getCustomerName(rec.customer_id)
        }
    ]

    const columnsGroup: ColumnsType<UserGroupModel>  = [
        {
            title: <div style={{height: '32px'}}>{t('rights.users.groups_title') + ': ' + (selectedUser ? selectedUser?.title : '')}</div>,
            dataIndex: 'title',
            key: 'id',
            width: '95%',
            className: 'td-blue tdOver',
            render: (_, rec: UserGroupModel) => renderUserGroup(rec.title, getCustomerName(rec.customer_id),false)
        },
        {
            title: '',
            key: 'action',
            width: '50px',
            dataIndex: 'action',
            fixed: 'right',
            render: (_, rec: UserGroupModel) => (
                <Space size='small'>
                    <Button type='text'
                            size='small'
                            disabled={!selectedUser || !isDeleter}
                            onClick={() => {
                                if (selectedUser && rec.id) {
                                    dispatch(removeUserFromGroup({id: rec.id, user_ids:[selectedUser.id]}, suc => {
                                        loadUser(selectedUser.id)
                                    }))
                                }
                            }}
                            className='actionButton'
                            icon={(selectedUser && isDeleter) ? <MinusCircleTwoTone twoToneColor='red' /> : <MinusCircleTwoTone twoToneColor="#cccccc" />}
                    />
                </Space>
            ),
        },
    ]

    const columnsGroupAll: ColumnsType<UserGroupModel>  = [
        {
            title: FilterByGroupAll,
            dataIndex: 'title',
            key: 'id',
            width: '95%',
            className: 'td-blue tdOver',
            render: (_, rec: UserGroupModel) => rec.title
        },
        {
            title: '',
            key: 'action',
            width: '50px',
            dataIndex: 'action',
            fixed: 'right',
            render: (_, rec: UserGroupModel) => (
                <Space size='small'>
                    <Button type='text'
                            size='small'
                            disabled={!selectedUser || !isCreator}
                            onClick={() => {
                                if (selectedUser && rec.id) {
                                    dispatch(addUserToGroup({id: rec.id, user_ids:[selectedUser.id]}, suc => {
                                        loadUser(selectedUser.id)
                                        if (selectedUser.id === loggedUser.user.id) {
                                            // it is me => reload me
                                            dispatch(getSelf('validCustomers,roles,resourceGroups,groups,userAuthentications,authentications'))
                                        }
                                    }))
                                }
                            }}
                            className='actionButton'
                            icon={(selectedUser && isCreator) ? <PlusCircleTwoTone /> : <PlusCircleTwoTone twoToneColor="#cccccc" />}
                    />
                </Space>
            ),
        },
    ]

    const reRenderRole = (rec: RoleItem) => {
        if (loggedUser.hasAccess('left_menu_rights_roles')) {
            return renderRole(rec.label, false, () => { history.push('/rights/role/'+rec.label)})
        }
        return renderRole(rec.label, false, undefined)
    }

    const columnsRole: ColumnsType<RoleItem> = [
        {
            title: t('rights.users.roles_title') + ': ' + (selectedUser ? selectedUser?.title : ''),
            dataIndex: 'label',
            key: 'label',
            render: (_, rec) => reRenderRole(rec)
        }
    ]

    const reRenderResGroup = (rec: ResGroupModel) => {
        if (loggedUser.hasAccess('left_menu_rights_resources')) {
            return renderResGroup(rec.name, getCustomerName(rec.customer_id), false, () => { history.push('/rights/resource-group/'+rec.id)})
        }
        return renderResGroup(rec.name, getCustomerName(rec.customer_id), false, undefined)
    }

    const columnsRes: ColumnsType<ResGroupModel> = [
        {
            title: t('rights.users.resources_title') + ': ' + (selectedUser ? selectedUser?.title : ''),
            dataIndex: 'name',
            key: 'name',
            render: (_, rec) => reRenderResGroup(rec)
        },
    ]

    return (
        <>
            <Card className='UserRightsPage'
                  title={
                      <Row>
                          <Col span={16}>
                              <UserSwitchOutlined /> &nbsp;{selectedUser ? t('rights.users.user') : t('rights.users.title')} &nbsp;
                              {selectedUser &&  (<><Link to={`/users/${selectedUser?.id}`}><Tag color='blue' key={selectedUser?.id} style={{fontSize: '1em', border: 0}}>{selectedUser?.title}</Tag></Link>&nbsp;</>)}
                              {
                                  selectedUser && (
                                      <Button title={t('general.btnHistory')} size='small'
                                              onClick={() => {
                                                  if (selectedUser.id) {
                                                      setHistoryTitle(`user / User / ${selectedUser.id}`)
                                                      setHistoryModelId(selectedUser.id)
                                                      setHistoryModalVisible(true)
                                                      setForceUpdateHistoryId(forceUpdateHistoryId+1)
                                                  }
                                              }}
                                              icon={<InfoCircleTwoTone />}
                                              className='actionButton'
                                      />
                                  )
                              }
                          </Col>
                          <Col span={8} className='center'>&nbsp;{t('rights.users.filter')} &nbsp;
                              <Select
                                  showSearch
                                  placeholder={t('general.all')}
                                  allowClear={true}
                                  value={selectedCustomerId}
                                  style={{width: '320px', textAlign: 'left', fontSize: '1.2em', fontWeight: 'bold'}}
                                  onChange={onChangeCustomer}
                                  optionFilterProp='label'
                                  options={custOptions}
                              />
                          </Col>
                      </Row>
                  }
            >

                <Row gutter={[8, 8]} style={{borderTop: '1px solid #dcdcdc'}}>
                    <Col span={8} className='panel' style={{marginRight: '0px'}}>
                        <Table<UserDetails>
                            rowClassName={(record) => selectedUser && selectedUser.id === record.id ? 'tr-selected' : 'tr'}
                            bordered={true}
                            columns={columnsUser}
                            onRow={(rec, rowIndex) => {
                                return {
                                    onClick: (event) => {setSelectedUser(rec)}, // click row
                                };
                            }}
                            loading={isLoadingUser}
                            dataSource={userSource}
                            className='users-table'
                            rowKey='id'
                            scroll={{ x: 'none', y: 600 }}
                            pagination={false}
                            footer={() => (
                                <Row>
                                    <Col span={12}><span>{t('rights.users.total')}: {userSource.length}</span></Col>
                                    <Col span={12}><div style={{float: 'right'}}>&nbsp;</div></Col>
                                </Row>
                            )}
                        />
                    </Col>
                    <Col span={8} className='panel' style={{marginRight: '0px'}}>
                        <div style={{overflowY: 'hidden', borderBottom: 'solid 1px #eeeeee'}}>
                            <Table<UserGroupModel>
                                rowClassName={() => 'highlight'}
                                bordered={true}
                                size='small'
                                columns={columnsGroup}
                                dataSource={groupSource}
                                className='group-table'
                                rowKey='id'
                                scroll={{ y: 300 }}
                                pagination={false}
                            />
                        </div>

                        <div style={{overflowY: 'hidden', border: 'solid 1px #eeeeee'}}>
                            <Table<RoleItem>
                                rowClassName={() => 'highlight'}
                                bordered={true}
                                size='small'
                                columns={columnsRole}
                                dataSource={roleSource}
                                className='role-table'
                                rowKey='value'
                                scroll={{ y: 300 }}
                                pagination={false}
                            />
                        </div>
                    </Col>
                    <Col span={8} className='panel' style={{marginRight: '0px'}}>
                        <div style={{overflowY: 'hidden', borderBottom: 'solid 1px #eeeeee'}}>
                            <Table<UserGroupModel>
                                rowClassName={() => 'highlight'}
                                bordered={true}
                                size='small'
                                columns={columnsGroupAll}
                                dataSource={groupAllSource}
                                className='group-table'
                                rowKey='id'
                                scroll={{ y: 300 }}
                                pagination={false}
                            />
                        </div>

                        <div style={{overflowY: 'hidden', border: 'solid 1px #eeeeee'}}>
                            <Table<ResGroupModel>
                                rowClassName={() => 'highlight'}
                                bordered={true}
                                columns={columnsRes}
                                dataSource={resGroupSource}
                                className='res-group-table'
                                rowKey='id'
                                scroll={{ y: 300 }}
                                style={{marginTop: '5px'}}
                                pagination={false}
                            />
                        </div>
                    </Col>
                </Row>

            </Card>

            <Modal
                destroyOnClose
                style={{top: 50}}
                bodyStyle={{height: '60%', minHeight: 450, padding: 2}}
                width='60%'
                className='historyLogModal'
                title={ (<><InfoCircleOutlined />&nbsp;{t('general.history')}: {historyTitle}</>)}
                visible={isHistoryModalVisible}
                onCancel={() => setHistoryModalVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}>

                <HistoryLog isModal={true}
                            service='user'
                            model='User'
                            modelId={historyModelId}
                            forceUpdateId={forceUpdateHistoryId}
                            showTitle={false} />

            </Modal>
        </>
    )
}

export default UserRightsPage