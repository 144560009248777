import {
    UpdateUserAuthenticationRequestAction,
    UpdateUserAuthenticationResponseAction,
    UpdateUserAuthenticationErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {UserAuthenticationModel, UserAuthenticationParams} from '../models'


export default (params: UserAuthenticationParams, cb?: (suc: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | UpdateUserAuthenticationRequestAction
                | UpdateUserAuthenticationResponseAction
                | UpdateUserAuthenticationErrorAction,
        ) => void,
    ) => {
        const request = new UpdateUserAuthenticationRequestAction(params)
        dispatch(request)

        protectedApiClient
            .put<UserAuthenticationModel>(`/user-authentication/${params.id}`, params)
            .then((response) => {
                dispatch(new UpdateUserAuthenticationResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateUserAuthenticationErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
