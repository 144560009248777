import React, { useEffect } from 'react'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { colorsList, disableColor, freeColor, HypervisorModel, VpsHelper } from '../../models'
import GraphLine from '../GraphLine/GraphLine'
import GraphLegendItem from '../GraphLegendItem/GraphLegendItem'
import { Col, Row } from 'antd'
import './GraphComponent.scss'

interface DataPie {
    type: string,
    value: number | string,
    color?: string
    id?: number
}

interface UsedDisk {
    type: string,
    value: number,
    id?: number
}

interface GraphData {
    title: string,
    key: number | string,
    data: DataPie[],
    color?: any
}

interface Color {
    name: string,
    value: string,
}


const GraphComponent = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { vpsServers, hypervisor, vpsServer } = useSelector((state: AppState) => state.vpsadmin)

    const [usedContainers, setUsedContainers] = useState<HypervisorModel[]>([])
    const [graphData, setGraphData] = useState<GraphData[] | undefined>([])
    const [colorsMap, setColorsMap] = useState<Color[]>([])

    useEffect(() => {
        // trigger ONCE
        if (hypervisor && vpsServers && vpsServers.length) {
            setUsedContainers(getUsedContainers())
        }
    }, [])

    useEffect(() => {
        if (hypervisor && vpsServers && vpsServers.length) {
            setUsedContainers(getUsedContainers())
        }
    }, [vpsServers, hypervisor])

    useEffect(() => {
        // set colors
        setColorsMap(setColorsToVPS(usedContainers))
    }, [usedContainers])

    useEffect(() => {
        setRenderData()
    }, [colorsMap])

    const setRenderData = () => {
        console.log('hypervisor vpsServers..')
        if (hypervisor && vpsServers && colorsMap.length) {
            console.log('hypervisor vpsServers..ok')
            let disksList = getListDataDisks()
            let dataToGRaph: GraphData[] = []
            console.log('hypervisor usedContainers.length='+usedContainers.length)
            if (usedContainers.length) {
                dataToGRaph.push({
                    title: t('vpsadmin.cores'),
                    key: 'cores',
                    data: getListDataCores(),
                    color: getColors(getListDataCores())
                })
                console.log('hypervisor getListDataCores().length='+JSON.stringify(getListDataCores()))
                dataToGRaph.push({
                    title: t('vpsadmin.vps.ram'),
                    key: 'ram',
                    data: getListDataRam(),
                    color: getColors(getListDataRam())
                })
                disksList.map(item => {
                    dataToGRaph.push(item)
                })
            }
            setGraphData(dataToGRaph)
        }
    }

    const setColorsToVPS = (usedContainers) => {
        let colors: Color[] = []
        let counter = 0
        usedContainers.map(item => {
            colors.push({
                name: item.name,
                value: colorsList[counter]
            })
            if (usedContainers.length == (counter + 1)) {
                counter = 0
            }
            counter++
        })
        return colors
    }

    const getUsedContainers = () => {
        if (!hypervisor) {
            return []
        }
        let containers: HypervisorModel[] = []
        vpsServers.map(elem => {
            if (hypervisor?.host == elem.host) {
                containers.push(elem)
            }
        })
        return containers
    }

    const getColors = (data) => {
        let colors: string[] = []
        data.map(elem => {
            if (vpsServer && vpsServer?.name !== elem.type && elem.type !== t('vpsadmin.graph.freeSpace')) {
                colors.push(disableColor)
            } else if (elem.type == t('vpsadmin.graph.freeSpace')) {

                colors.push(freeColor)

            } else {
                colorsMap.map((item) => {
                    if (elem.type == item.name) {
                        colors.push(item.value)
                    }
                })
            }

        })

        return colors
    }

    const getListDataCores = () => {
        let data: DataPie[] = []
        usedContainers?.map(elem => {
            data.push({
                type: elem.name,
                value: elem.cpu_occupied,
                id: elem.id
            })
        })
        if (hypervisor) {
            if (VpsHelper.usedCpus(vpsServers, hypervisor) < hypervisor?.cpu_count) {
                data.push(
                    {
                        type: t('vpsadmin.graph.freeSpace'),
                        value: hypervisor?.cpu_count - VpsHelper.usedCpus(vpsServers, hypervisor),
                        id: -1
                    }
                )
            }
        }
        return data;
    }

    const getListDataRam = () => {
        let data: DataPie[] = []
        usedContainers?.map(elem => {
            data.push({
                type: elem.name,
                value: VpsHelper.transformToGB(elem.ram_total),
                id: elem.id
            })
        })

        if (hypervisor) {
            if (VpsHelper.usedRam(vpsServers, hypervisor) < hypervisor?.ram_total) {
                data.push(
                    {
                        type: t('vpsadmin.graph.freeSpace'),
                        value: VpsHelper.transformToGB(hypervisor?.ram_total - VpsHelper.usedRam(vpsServers, hypervisor)),
                    }
                )
            }
        }

        return data;
    }

    const getListDataDisks = () => {
        let disks: GraphData[] = []

        hypervisor?.storages.map((storage) => {
            let usedDisk: UsedDisk[] = []
            let usedArea = 0
            usedContainers.map((item) => {

                item.containerStorages.map((usedStorage) => {
                    if (storage.id == usedStorage.storage_id) {
                        usedDisk.push({
                            type: item.name,
                            value: VpsHelper.transformToGB(usedStorage.disk_size),

                        })
                    }
                })
            })

            usedDisk.map(item => {
                usedArea = usedArea + item.value
            })
            if (usedArea < VpsHelper.transformToGB(storage.total_size)) {
                usedDisk.push({
                    type: t('vpsadmin.graph.freeSpace'),
                    value: VpsHelper.transformToGB(storage.total_size) - usedArea

                })
            }

            disks.push({
                title: storage.type,
                key: storage.id,
                data: usedDisk,
                color: getColors(usedDisk)
            })

        })

        return disks
    }



    const renderStructureGraph = () => {
        let counter = 1
        let result = graphData?.map(elem => {
            let result = (
                <GraphLine key={counter} data={elem} />
            )
            ++counter
            return result
        })
        return result;
    }

    const renderStructureLegend = () => {
        let result: JSX.Element[] = []
        if (graphData && graphData.length > 0) {
            let colors = getColors(getListDataCores())
            let data = graphData[0].data
            for (let i = 0; i < data.length; i++) {
                if (colors[i] !== disableColor) {

                    result.push((<GraphLegendItem key={i} color={colors[i]} {...data[i]}></GraphLegendItem>))
                }

            }
            result.push((<GraphLegendItem key={'free'} {...{ type: t('vpsadmin.graph.freeSpace'), value: 0 }} color={freeColor}></GraphLegendItem >))
        }
        return result
    }


    return (
        <>
            {graphData && renderStructureGraph()}
            <Row justify="end">
                <Col span={9}>
                    <div className='graph-legend'>
                        {t('vpsadmin.graph.legend')}
                        {graphData && <div className='graph-legend-list'>{renderStructureLegend()}</div>}
                    </div>
                </Col>

            </Row>


        </>

    )
}

export default GraphComponent

