import {AsyncActionMode} from 'common/models'
import {InventoryModel} from 'pages/inventory/inventory/models'
import {
  PhysicalServerBase,
  PhysicalServerModel,
  UpdatePhysicalServerParams,
  PhysicalServerAddComponentParams,
  PhysicalServerRemoveComponentParams,
  PhysicalServerSwapComponentParams,
  MovePhysicalServerParams,
  PhysicalServerSetRackParams, PhysicalServerGrid,
} from '../models'
import {InventoryTypeModel} from "../../type/models";
import {
  LookupInventoryErrorAction,
  LookupInventoryRequestAction,
  LookupInventoryResponseAction
} from "../../inventory/actions";


export enum ActionType {
  GET_PHYSICALSERVERS = 'GET_PHYSICALSERVERS',
  LOOKUP_PHYSICALSERVER = 'LOOKUP_PHYSICALSERVER',
  GET_NEW_SERVERS = 'GET_NEW_SERVERS',
  GET_PHYSICALSERVER = 'GET_PHYSICALSERVER',
  GET_ALL_COMPONENTS = 'GET_ALL_COMPONENTS',
  GET_ALL_COMPONENTS_TYPES = 'GET_ALL_COMPONENTS_TYPES',
  CLEAR_ALL_COMPONENTS_TYPES = 'CLEAR_ALL_COMPONENTS_TYPES',
  CREATE_PHYSICALSERVER = 'CREATE_PHYSICALSERVER',
  UPDATE_PHYSICALSERVER = 'UPDATE_PHYSICALSERVER',
  DELETE_PHYSICALSERVER = 'DELETE_PHYSICALSERVER',
  MOVE_PHYSICALSERVER = 'MOVE_PHYSICALSERVER',
  ADD_COMPONENT_TO_PHYSICALSERVER = 'ADD_COMPONENT_TO_PHYSICALSERVER',
  REMOVE_COMPONENT_FROM_PHYSICALSERVER = 'REMOVE_COMPONENT_FROM_PHYSICALSERVER',
  REMOVE_BASE_FROM_PHYSICALSERVER = 'REMOVE_BASE_FROM_PHYSICALSERVER',
  SWAP_COMPONENT_IN_PHYSICALSERVER = 'SWAP_COMPONENT_IN_PHYSICALSERVER',
  REMOVE_PHYSICALSERVER_FROM_RACK = 'REMOVE_PHYSICALSERVER_FROM_RACK',
  SET_PHYSICALSERVER_RACK = 'SET_PHYSICALSERVER_RACK',
}

export type PhysicalServerActions =
    | CreatePhysicalServerRequestAction
    | CreatePhysicalServerResponseAction
    | CreatePhysicalServerErrorAction
    | GetPhysicalServersRequestAction
    | GetPhysicalServersResponseAction
    | GetPhysicalServersErrorAction
    | GetNewServersRequestAction
    | GetNewServersResponseAction
    | GetNewServersErrorAction
    | GetPhysicalServerRequestAction
    | GetPhysicalServerResponseAction
    | GetPhysicalServerErrorAction
    | GetAllComponentsRequestAction
    | GetAllComponentsResponseAction
    | GetAllComponentsErrorAction
    | ClearAllComponentsByTypeRequestAction
    | GetAllComponentsByTypeRequestAction
    | GetAllComponentsByTypeResponseAction
    | GetAllComponentsByTypeErrorAction
    | UpdatePhysicalServerRequestAction
    | UpdatePhysicalServerResponseAction
    | UpdatePhysicalServerErrorAction
    | DeletePhysicalServerRequestAction
    | DeletePhysicalServerResponseAction
    | DeletePhysicalServerErrorAction
    | MovePhysicalServerRequestAction
    | MovePhysicalServerResponseAction
    | MovePhysicalServerErrorAction
    | AddComponentToPhysicalServerRequestAction
    | AddComponentToPhysicalServerResponseAction
    | AddComponentToPhysicalServerErrorAction
    | RemoveComponentFromPhysicalServerRequestAction
    | RemoveComponentFromPhysicalServerResponseAction
    | RemoveComponentFromPhysicalServerErrorAction
    | RemoveInventoryBaseRequestAction
    | RemoveInventoryBaseResponseAction
    | RemoveInventoryBaseErrorAction
    | SwapComponentToPhysicalServerRequestAction
    | SwapComponentToPhysicalServerResponseAction
    | SwapComponentToPhysicalServerErrorAction
    | RemovePhysicalServerFromRackRequestAction
    | RemovePhysicalServerFromRackResponseAction
    | RemovePhysicalServerFromRackErrorAction
    | SetPhysicalServerRackRequestAction
    | SetPhysicalServerRackRackResponseAction
    | SetPhysicalServerRackRackErrorAction
    | LookupPhysicalServerRequestAction
    | LookupPhysicalServerResponseAction
    | LookupPhysicalServerErrorAction

export class SetPhysicalServerRackRequestAction {
  readonly type = ActionType.SET_PHYSICALSERVER_RACK
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: PhysicalServerSetRackParams) {
    ''
  }
}
export class SetPhysicalServerRackRackResponseAction {
  readonly type = ActionType.SET_PHYSICALSERVER_RACK
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: SetPhysicalServerRackRequestAction,
    public data: PhysicalServerModel,
  ) {}
}
export class SetPhysicalServerRackRackErrorAction {
  readonly type = ActionType.SET_PHYSICALSERVER_RACK
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: SetPhysicalServerRackRequestAction,
    public error: string,
  ) {}
}

export class RemovePhysicalServerFromRackRequestAction {
  readonly type = ActionType.REMOVE_PHYSICALSERVER_FROM_RACK
  readonly mode = AsyncActionMode.REQUEST
  constructor(public server_id: number) {
    ''
  }
}
export class RemovePhysicalServerFromRackResponseAction {
  readonly type = ActionType.REMOVE_PHYSICALSERVER_FROM_RACK
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: RemovePhysicalServerFromRackRequestAction,
    public data: PhysicalServerModel,
  ) {}
}
export class RemovePhysicalServerFromRackErrorAction {
  readonly type = ActionType.REMOVE_PHYSICALSERVER_FROM_RACK
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: RemovePhysicalServerFromRackRequestAction,
    public error: string,
  ) {}
}

export class SwapComponentToPhysicalServerRequestAction {
  readonly type = ActionType.SWAP_COMPONENT_IN_PHYSICALSERVER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: PhysicalServerSwapComponentParams) {
    ''
  }
}
export class SwapComponentToPhysicalServerResponseAction {
  readonly type = ActionType.SWAP_COMPONENT_IN_PHYSICALSERVER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: SwapComponentToPhysicalServerRequestAction,
    public data: PhysicalServerModel,
  ) {}
}
export class SwapComponentToPhysicalServerErrorAction {
  readonly type = ActionType.SWAP_COMPONENT_IN_PHYSICALSERVER
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: SwapComponentToPhysicalServerRequestAction,
    public error: string,
  ) {}
}

export class RemoveComponentFromPhysicalServerRequestAction {
  readonly type = ActionType.REMOVE_COMPONENT_FROM_PHYSICALSERVER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: PhysicalServerRemoveComponentParams) {
    ''
  }
}
export class RemoveComponentFromPhysicalServerResponseAction {
  readonly type = ActionType.REMOVE_COMPONENT_FROM_PHYSICALSERVER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: RemoveComponentFromPhysicalServerRequestAction,
    public data: PhysicalServerModel,
  ) {}
}
export class RemoveComponentFromPhysicalServerErrorAction {
  readonly type = ActionType.REMOVE_COMPONENT_FROM_PHYSICALSERVER
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: RemoveComponentFromPhysicalServerRequestAction,
    public error: string,
  ) {}
}

export class RemoveInventoryBaseRequestAction {
  readonly type = ActionType.REMOVE_BASE_FROM_PHYSICALSERVER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public serverId: number) {
    ''
  }
}
export class RemoveInventoryBaseResponseAction {
  readonly type = ActionType.REMOVE_BASE_FROM_PHYSICALSERVER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: RemoveInventoryBaseRequestAction,
      public data: PhysicalServerModel,
  ) {}
}
export class RemoveInventoryBaseErrorAction {
  readonly type = ActionType.REMOVE_BASE_FROM_PHYSICALSERVER
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: RemoveInventoryBaseRequestAction,
      public error: string,
  ) {}
}

export class AddComponentToPhysicalServerRequestAction {
  readonly type = ActionType.ADD_COMPONENT_TO_PHYSICALSERVER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: PhysicalServerAddComponentParams) {}
}
export class AddComponentToPhysicalServerResponseAction {
  readonly type = ActionType.ADD_COMPONENT_TO_PHYSICALSERVER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: AddComponentToPhysicalServerRequestAction,
    public data: PhysicalServerModel,
  ) {}
}
export class AddComponentToPhysicalServerErrorAction {
  readonly type = ActionType.ADD_COMPONENT_TO_PHYSICALSERVER
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: AddComponentToPhysicalServerRequestAction,
    public error: string,
  ) {}
}

export class CreatePhysicalServerRequestAction {
  readonly type = ActionType.CREATE_PHYSICALSERVER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: PhysicalServerBase) {}
}
export class CreatePhysicalServerResponseAction {
  readonly type = ActionType.CREATE_PHYSICALSERVER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: CreatePhysicalServerRequestAction,
    public data: PhysicalServerModel,
  ) {}
}
export class CreatePhysicalServerErrorAction {
  readonly type = ActionType.CREATE_PHYSICALSERVER
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: CreatePhysicalServerRequestAction,
    public error: string,
  ) {}
}

export class GetPhysicalServerRequestAction {
  readonly type = ActionType.GET_PHYSICALSERVER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetPhysicalServerResponseAction {
  readonly type = ActionType.GET_PHYSICALSERVER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: GetPhysicalServerRequestAction,
    public data: PhysicalServerModel,
  ) {}
}
export class GetPhysicalServerErrorAction {
  readonly type = ActionType.GET_PHYSICALSERVER
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: GetPhysicalServerRequestAction,
    public error: string,
  ) {}
}

export class GetPhysicalServersRequestAction {
  readonly type = ActionType.GET_PHYSICALSERVERS
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetPhysicalServersResponseAction {
  readonly type = ActionType.GET_PHYSICALSERVERS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: GetPhysicalServersRequestAction,
    public data: PhysicalServerGrid,
  ) {}
}
export class GetPhysicalServersErrorAction {
  readonly type = ActionType.GET_PHYSICALSERVERS
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: GetPhysicalServersRequestAction,
    public error: string,
  ) {}
}

export class GetNewServersRequestAction {
  readonly type = ActionType.GET_NEW_SERVERS
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetNewServersResponseAction {
  readonly type = ActionType.GET_NEW_SERVERS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetNewServersRequestAction,
      public data: InventoryModel[],
  ) {}
}
export class GetNewServersErrorAction {
  readonly type = ActionType.GET_NEW_SERVERS
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetNewServersRequestAction,
      public error: string,
  ) {}
}

export class GetAllComponentsRequestAction {
  readonly type = ActionType.GET_ALL_COMPONENTS
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetAllComponentsResponseAction {
  readonly type = ActionType.GET_ALL_COMPONENTS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetAllComponentsRequestAction,
      public data: InventoryModel[],
  ) {}
}
export class GetAllComponentsErrorAction {
  readonly type = ActionType.GET_ALL_COMPONENTS
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetAllComponentsRequestAction,
      public error: string,
  ) {}
}

export class GetAllComponentsByTypeRequestAction {
  readonly type = ActionType.GET_ALL_COMPONENTS_TYPES
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetAllComponentsByTypeResponseAction {
  readonly type = ActionType.GET_ALL_COMPONENTS_TYPES
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetAllComponentsByTypeRequestAction,
      public data: InventoryTypeModel,
  ) {}
}
export class GetAllComponentsByTypeErrorAction {
  readonly type = ActionType.GET_ALL_COMPONENTS_TYPES
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetAllComponentsByTypeRequestAction,
      public error: string,
  ) {}
}

export class ClearAllComponentsByTypeRequestAction {
  readonly type = ActionType.CLEAR_ALL_COMPONENTS_TYPES
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}

export class UpdatePhysicalServerRequestAction {
  readonly type = ActionType.UPDATE_PHYSICALSERVER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: UpdatePhysicalServerParams) {}
}
export class UpdatePhysicalServerResponseAction {
  readonly type = ActionType.UPDATE_PHYSICALSERVER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: UpdatePhysicalServerRequestAction,
    public data: PhysicalServerModel,
  ) {}
}
export class UpdatePhysicalServerErrorAction {
  readonly type = ActionType.UPDATE_PHYSICALSERVER
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: UpdatePhysicalServerRequestAction,
    public error: string,
  ) {}
}

export class DeletePhysicalServerRequestAction {
  readonly type = ActionType.DELETE_PHYSICALSERVER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {}
}
export class DeletePhysicalServerResponseAction {
  readonly type = ActionType.DELETE_PHYSICALSERVER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: DeletePhysicalServerRequestAction,
      public data: PhysicalServerModel,
  ) {}
}
export class DeletePhysicalServerErrorAction {
  readonly type = ActionType.DELETE_PHYSICALSERVER
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: DeletePhysicalServerRequestAction,
      public error: string,
  ) {}
}
export class MovePhysicalServerRequestAction {
  readonly type = ActionType.MOVE_PHYSICALSERVER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public params: MovePhysicalServerParams) {
    ''
  }
}
export class MovePhysicalServerResponseAction {
  readonly type = ActionType.MOVE_PHYSICALSERVER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: MovePhysicalServerRequestAction,
    public data: PhysicalServerModel,
  ) {}
}
export class MovePhysicalServerErrorAction {
  readonly type = ActionType.MOVE_PHYSICALSERVER
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: MovePhysicalServerRequestAction,
    public error: string,
  ) {}
}
export class LookupPhysicalServerRequestAction {
  readonly type = ActionType.LOOKUP_PHYSICALSERVER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public field: string) {
    ''
  }
}
export class LookupPhysicalServerResponseAction {
  readonly type = ActionType.LOOKUP_PHYSICALSERVER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: LookupPhysicalServerRequestAction,
      public field: string,
      public data: string[],
  ) {}
}
export class LookupPhysicalServerErrorAction {
  readonly type = ActionType.LOOKUP_PHYSICALSERVER
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: LookupPhysicalServerRequestAction, public error: string) {}
}