import React, { useEffect } from 'react'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import updateSetting from "../login/actions/updateSetting"
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import {Button, Card, Col, Row, Select, Spin} from "antd"
import { RouteComponentProps, useParams } from 'react-router-dom'
import getHypervisorContainer from './actions/getHypervisorContainer'
import getVpsContainers from './actions/getVpsContainers'
import HypervisorDetailComponent from './components/HypervisorDetailComponent/HypervisorDetailComponent'
import setDefaultHypervisor from './actions/setDefaultHypervisor'
import getContainersHistories from './actions/getContainersHistories'
import getContainersChanges from './actions/getContainersChanges'
import GraphComponent from './components/GraphComponent/GraphComponent'
import HistoryTable from './components/HistoryTable/HistoryTable'
import ChangesTable from './components/ChangesTable/ChangesTable'
import { HypervisorModel, VpsHelper } from './models'
import usePageSize from "../../common/usePageSize";


interface ParamTypes {
    hypervisor_id: string
}
interface DataPie {
    type: string,
    value: number | string,
    color?: string
}

interface UsedDisk {
    type: string,
    value: number,
}

interface GraphData {
    title: string,
    key: number | string,
    data: DataPie[],
}


const HypervisorDetailPage = (props: RouteComponentProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { hypervisor_id } = useParams<ParamTypes>()

    const { vpsServers, hypervisor } = useSelector((state: AppState) => state.vpsadmin)

    const [activeTabKey, setActiveTabKey] = useState<string>('graph');
    const [usedContainers, setUsedContainers] = useState<HypervisorModel[]>([])
    const [graphData, setGraphData] = useState<GraphData[]>([])
    const [pageSize, setPageSize] = useState<string>()

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg) => {if (appSetting && appSetting.debug) {console.log('HypervisorDetailPage: ' + msg)}}
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    useEffect(() => {
        // load hypervisors from API
        // trigger ONCE
        graphData.splice(0, graphData.length)
        dispatch(getHypervisorContainer(hypervisor_id))
        logger('getHypervisorContainer..'+hypervisor_id)
        if (vpsServers?.length === 0) {
            dispatch(getVpsContainers())
            logger('getVpsContainers..')
        }
    }, [])

    useEffect(() => {
        if (hypervisor && hypervisor.id === Number(hypervisor_id)) {
            dispatch(getContainersHistories(hypervisor.id))
            dispatch(getContainersChanges(hypervisor.id))
            logger('getContainersHistories..')
            logger('getContainersChanges..')
        }
    }, [hypervisor])

    useEffect(() => {
        let containers: HypervisorModel[] = []
        if (hypervisor && hypervisor.id === Number(hypervisor_id)) {
            vpsServers.map(elem => {
                if (hypervisor?.host == elem.host) {
                    containers.push(elem)
                }
            })
        }
        setUsedContainers(containers)
    }, [vpsServers])


    useEffect(() => {
        if (hypervisor && hypervisor.id === Number(hypervisor_id)) {
            let disksList = getListDataDisks()
            if (usedContainers.length) {
                graphData.push({
                    title: t('vpsadmin.cores'),
                    key: 'cores',
                    data: getListDataCores(),
                })
                graphData.push({
                    title: t('vpsadmin.vps.ram'),
                    key: 'ram',
                    data: getListDataRam(),
                })
                disksList.map(item => {
                    graphData.push(item)
                })
            }
            setGraphData(graphData)
        }
    }, [usedContainers])


    const getListDataCores = () => {
        let data: DataPie[] = []
        usedContainers.map(elem => {
            data.push({
                type: elem.name,
                value: elem.cpu_occupied
            })
        })
        if (hypervisor) {
            if (VpsHelper.usedCpus(vpsServers, hypervisor) < hypervisor?.cpu_count) {
                data.push(
                    {
                        type: 'free space',
                        value: hypervisor?.cpu_count - VpsHelper.usedCpus(vpsServers, hypervisor)
                    }
                )
            }
        }
        return data;
    }

    const getListDataRam = () => {
        let data: DataPie[] = []
        usedContainers.map(elem => {
            data.push({
                type: elem.name,
                value: VpsHelper.transformToGB(elem.ram_total),
            })
        })
        if (hypervisor) {
            if (VpsHelper.usedRam(vpsServers, hypervisor) < hypervisor?.ram_total) {
                data.push(
                    {
                        type: 'free space',
                        value: VpsHelper.transformToGB(hypervisor?.ram_total - VpsHelper.usedRam(vpsServers, hypervisor))
                    }
                )
            }
        }
        return data;
    }

    const getListDataDisks = () => {
        let disks: GraphData[] = []
        hypervisor?.storages.map((storage) => {
            let usedDisk: UsedDisk[] = []
            let usedArea = 0
            usedContainers.map((item) => {
                item.containerStorages.map((usedStorage) => {
                    if (storage.id == usedStorage.storage_id) {
                        usedDisk.push({
                            type: item.name,
                            value: VpsHelper.transformToGB(usedStorage.disk_size)

                        })
                    }
                })
            })
            usedDisk.map(item => {usedArea = usedArea + item.value})
            if (usedArea < VpsHelper.transformToGB(storage.total_size)) {
                usedDisk.push({
                    type: 'Free Space',
                    value: VpsHelper.transformToGB(storage.total_size) - usedArea
                })
            }
            disks.push({
                title: storage.type,
                key: storage.id,
                data: usedDisk
            })
        })
        return disks
    }

    const contentListNoTitle: Record<string, React.ReactNode> = {
        graph: <GraphComponent />,
        history: <HistoryTable type='hypervisor' />,
        changes: <ChangesTable type='hypervisor' />,
    };

    if (!hypervisor) {
        return <Spin />
    }

    return (
        <Card key={hypervisor_id.toString()}>
            <HypervisorDetailComponent hypervisor={hypervisor} />
            <Row style={{marginBottom: '15px', padding: 15, borderBottom: '1px solid #dedede'}}>
                <Col span={3} style={{textAlign: 'center'}}>
                    <Button type="primary"
                            ghost={activeTabKey != 'graph'}
                            onClick={() => setActiveTabKey('graph')}>
                        {t('vpsadmin.graph.title')}
                    </Button>
                </Col>
                <Col span={3} style={{textAlign: 'center'}}>
                    <Button type="primary"
                            ghost={activeTabKey != 'history'}
                            onClick={() => setActiveTabKey('history')}>
                        {t('vpsadmin.history')}
                    </Button>
                </Col>
                <Col span={3} style={{textAlign: 'center'}}>
                    <Button type="primary"
                            ghost={activeTabKey != 'changes'}
                            onClick={() => setActiveTabKey('changes')}>
                        {t('vpsadmin.changes.title')}
                    </Button>
                </Col>
                <Col span={15}>&nbsp;</Col>
            </Row>
            <Row>
                <Col span={24}>
                    {contentListNoTitle[activeTabKey]}
                </Col>
            </Row>
        </Card>
    )
}

export default HypervisorDetailPage

