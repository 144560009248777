import apiClient from 'helpers/api/apiClient'
import { handleApiErrorWithNotification} from 'helpers/errorHandling'

export const passwordRequest = (params: {}, cb?: (isSuccess: boolean, data: any) => void) => {

  apiClient
    .post('/user/password-request', params)
    .then((response) => {
      cb && cb(true, response.data)
    })
    .catch((error) => {
      cb && cb(false, {})
      handleApiErrorWithNotification(error)
    })
}
