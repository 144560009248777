import React, {useEffect, useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Menu, Modal, MenuProps, Divider} from 'antd'
import {AppState} from 'common/models'
import {
	SyncOutlined,
	UserOutlined,
	LogoutOutlined, SettingOutlined, MenuOutlined,
} from '@ant-design/icons'
import {AiOutlineUserAdd} from "react-icons/ai";
import ProfileForm from "../../pages/user/ProfileForm";
import PasswordChangeForm from "../../pages/user/PasswordChangeForm";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
	label: React.ReactNode,
	key?: React.Key | null,
	icon?: React.ReactNode,
	children?: MenuItem[],
	type?: string
): MenuItem {
	return {
		key,
		icon,
		children,
		label,
		type,
	} as MenuItem;
}

const ProfileDropdown = () => {
	const {t} = useTranslation()

	const {authenticated, self} = useSelector((state: AppState) => state.auth)
	const [current, setCurrent] = useState('');

	useEffect(() => {

	},[])


	const onClick: MenuProps['onClick'] = e => {
		setCurrent('');
	};

	const getMenuItems = (): MenuItem[] => {
		if (authenticated && self) {
			return [
				getItem(self.title, 'user-name', <UserOutlined />, [
					getItem(<NavLink to='/users/me' className='nav-text'>{t('updateUserPage.title') + ' @' + self.username}</NavLink>, 'profile', <UserOutlined />),
					getItem(<NavLink to='/settings' className='nav-text'>{t('settingsPage.title')}</NavLink>, 'settings', <SettingOutlined /> ),
					getItem(<NavLink to='/logout' className='nav-text'>Log out</NavLink>, 'logout', <LogoutOutlined />),
				]),
			];
		}
		return [];
	}

	if (!authenticated) {
		return <div></div>
	}

	return (
		<>
			<Menu onClick={onClick}
				  selectedKeys={[current]}
				  mode="vertical"
				  items={getMenuItems()}
				  expandIcon={<MenuOutlined />}
				  // triggerSubMenuAction='click'
				  style={{marginTop: '10px', width: '200px'}}
			/>


		</>
	)
}

export default ProfileDropdown
