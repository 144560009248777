import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../common/models";
import React, {
    ChangeEvent,
    forwardRef,
    Ref,
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {Col, Form, Input, Layout, message, Row, Select, Spin, Table} from "antd";
import {ContactModel, CustomerDetails} from "./models";
import getContacts from "./actions/getContacts";
import {Content} from "antd/lib/layout/layout";
import TextArea from "antd/lib/input/TextArea";
import {UserDetails} from "../../user/models";
import getCustomerUsers from "./actions/getCustomerUsers";
import createContact from "./actions/createContact";
import deleteContact from "./actions/deleteContact";
import updateContact from "./actions/updateContact";
import {_parsePhone, _renderPhone, _validateEmail, _validatePhone, ValidateParams} from "../../../common/fce";
import {CompanyDetails} from "../../company/models";
import getCustomer from "./actions/getCustomer";
import tableCompanies from "../../login/actions/tableCompanies";
import {ROLE_CUSTOMER_MANAGER, ROLE_CUSTOMER_SERVICE_EDITOR, ROLE_USER_MANAGER} from "../../../common/LoggedUser";

const { Item } = Form

interface Props {
    setModalVisible: (param: boolean) => void
    setContactSelected: (param: boolean) => void
    customer: CustomerDetails | undefined
}

interface UserSelectItem {
    label: string
    value: number
    key: number
}

export interface IContactForm {
    // this method is called from parent control CustomerPage
    setNewContact: () => void
    onUpdateContact: () => void
    onDeleteContact: () => void
}

const ContactForm = forwardRef(({setModalVisible, setContactSelected, customer}: Props, ref: Ref<IContactForm>) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const inputRef = React.useRef<any>(null)

    const {error, contacts, customer_users} = useSelector((state: AppState) => state.customer)
    const {companies} = useSelector((state: AppState) => state.auth.tables)
    const [contactName, setContactName] = useState<string>('')
    const [contactEmail, setContactEmail] = useState<string>('')
    const [contactPhone, setContactPhone] = useState<string>('')
    const [contactNote, setContactNote] = useState<string>('')
    const [contactUserId, setContactUserId] = useState<number | undefined>(undefined)
    const [currentContact, setCurrentContact] = useState<ContactModel | undefined>(undefined)
    const [createSuccess, setCreateSuccess] = useState<boolean>(false)  // force update state after create
    const [company, setCompany] = useState<CompanyDetails | undefined>(undefined)
    const [contactSource, setContactSource] = useState<ContactModel[]>([])
    const [userSource, setUserSource] = useState<UserSelectItem[]>([])
    const [invoicingField, setInvoicingField] = useState<ValidateParams>({value: '', validateStatus: 'success', errorMsg: ''})
    const [phoneField, setPhoneField] = useState<ValidateParams>({value: '', validateStatus: 'success', errorMsg: ''})

    const [isContactEditor, setContactEditor] = useState(false)

    useImperativeHandle(ref, () => ({ setNewContact, onUpdateContact, onDeleteContact }));

    if (!customer) {
        return (<Spin/>)
    }

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }

    useEffect(() => {
        const ce = loggedUser.hasRole(ROLE_CUSTOMER_MANAGER) || loggedUser.hasRole(ROLE_USER_MANAGER) || loggedUser.hasRole(ROLE_CUSTOMER_SERVICE_EDITOR)
        setContactEditor(ce)
        if (!companies || companies.length === 0) {
            dispatch(tableCompanies())
        }
        if (customer) {
            // load contacts for the customer
            setContactSource([])
            dispatch(getContacts(customer.id))
            dispatch(getCustomerUsers(customer.id))
            setCompany(companies.find( c => c.id === customer.company_id))
        }
    }, [customer])

    useEffect(() => {
        if (contacts.length > 0) {
            setContactSource(contacts.map((item) => { return {...item, title: item.name+item.email}}))
        }
        else {
            setContactSource([])
        }
        setContactSelected(false)
    }, [contacts])

    useEffect(() => {
        if (customer_users.length > 0) {
            setUserSource(customer_users.map((user: UserDetails) => { return {value: user.id, key: user.id, label: user.title} }))
        }
    }, [customer_users])

    useEffect(() => {
        // select a contact
        if (currentContact) {
            setContactName(currentContact.name)
            setContactEmail(currentContact.email)
            setContactPhone(_renderPhone(currentContact.phone))
            setContactNote(currentContact.note)
            setContactUserId(currentContact.id)
            setContactUserId(currentContact.user_id)
            setContactSelected(true)
        }
        else {
            setContactName('')
            if (contactSource.length === 0) {
                // first contact
                setContactEmail(company?.email ? company.email : '')
            }
            else {
                setContactEmail('')
            }
            setContactPhone('')
            setContactNote('')
            setContactUserId(undefined)
            setContactSelected(false)
        }
    }, [currentContact, createSuccess])

    const isContactSelected = () => {
        return currentContact && currentContact.id
    }

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        let name = e.target.value
        setContactName(name)
        setContactSelected(name.length > 0 || contactEmail.length > 0)
    }

    const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        let email = e.target.value
        email = email.toLowerCase()
        setContactEmail(email)
        checkEmail(email)
        setContactSelected(email.length > 0 || contactName.length > 0)
    }

    const checkEmail = (email: string) => {
        if (!email) {
            setInvoicingField({
                value: email,
                validateStatus: 'success',
                errorMsg: ''
            })
            return true
        }
        const result = _validateEmail(email)
        if (!result) {
            setInvoicingField({
                value: email,
                validateStatus: 'error',
                errorMsg: t('errors.error_email')
            })
            return false
        }
        else {
            setInvoicingField({
                value: email,
                validateStatus: 'success',
                errorMsg: ''
            })
            return true
        }
    }


    const onChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
        let phone = e.target.value
        phone = phone.replace('  ', ' ')
        setContactPhone(phone)
        checkPhone(phone)
        setContactSelected(contactEmail.length > 0 || contactName.length > 0)
    }

    const checkPhone = (phone: string) => {
        console.log('checkPhone: ...')
        if (!phone) {
            setPhoneField({
                value: phone,
                validateStatus: 'success',
                errorMsg: ''
            })
            return true
        }
        const result = _validatePhone(phone)
        console.log('checkPhone: '+result)
        if (!result) {
            setPhoneField({
                value: phone,
                validateStatus: 'error',
                errorMsg: t('errors.error_phone')
            })
            return false
        }
        else {
            setPhoneField({
                value: phone,
                validateStatus: 'success',
                errorMsg: ''
            })
            return true
        }
    }

    const onChangeNote = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setContactNote(e.target.value)
        setContactSelected(contactEmail.length > 0 || contactName.length > 0)
    }

    const onSelectUser = (v: number, u: UserSelectItem | undefined) => {
        if (u) {
            setContactUserId(u.value)
            setContactSelected(contactEmail.length > 0 && contactName.length > 0)
        }
    }

    const onClickContact = (e) => {
        if (e && e.target && e.target.value) {
            setCurrentContact(contacts.find( (c) => (c.id.toString() === e.target.value)))
        }
    }

    const setNewContact = () => {
        setCurrentContact(undefined)
        inputRef.current!.focus()
        setContactSelected(false)
    }

    const onUpdateContact = () => {
        if (!checkPhone(contactPhone)) {
            return
        }
        if (!checkEmail(contactEmail)) {
            return
        }
        if (!contactEmail && !contactPhone) {
            setInvoicingField({
                value: contactEmail,
                validateStatus: 'error',
                errorMsg: 'Email is required.'
            })
            return
        }

        if (currentContact) {
            // update
            const data = {...currentContact,
                name: contactName,
                email: contactEmail,
                phone: _parsePhone(contactPhone),
                note: contactNote,
                user_id: contactUserId}
            dispatch(updateContact(data, suc => {
                if (suc) {
                    message.success(t('customerPage.contact_updated'))
                    dispatch(getCustomer(currentContact.customer_id, 'contacts,last_invoice,company'))
                }
                else {
                    if (error) {
                        message.error(error)
                    }
                }
            }))
        }
        else {
            // insert
            const data = {
                customer_id: customer.id,
                name: contactName,
                email: contactEmail,
                phone: _parsePhone(contactPhone),
                note: contactNote,
                user_id: contactUserId}
            dispatch(createContact(data, suc => {
                if (suc) {
                    message.success(t('customerPage.contact_created'))
                    setCreateSuccess(!createSuccess)
                    dispatch(getCustomer(customer.id, 'contacts,last_invoice,company'))
                }
                else {
                    if (error) {
                        message.error(error)
                    }
                }
            }))
        }
    }

    const onDeleteContact = () => {
        if (currentContact) {
            // update
            dispatch(deleteContact(currentContact.id, suc => {
                if (suc) {
                    message.success(t('customerPage.contact_deleted'))
                    setCurrentContact(undefined)
                }
                else {
                    if (error) {
                        message.error(error)
                    }
                }
            } ))
        }
    }

    return (
        <Layout className='contactForm'>
            <Row>
                <Col span={8}>
                    <select style={{width: '100%', height: '300px', marginTop: '10px'}} size={10}
                            onClick={onClickContact} >
                        {
                            contactSource.map( (c: ContactModel) => {
                                return (<option key={c.id} value={c.id}>{c.name + `  (${c.email ? c.email : c.phone})`}</option>)
                            })
                        }
                    </select>
                </Col>
                <Col span={16}>
                    <Content>
                        <Row style={{marginTop: '5px'}}>
                            <Col span={6} className='label'>{t('customerPage.contact_name')}</Col>
                            <Col span={12} className='field'>
                                <Item>
                                    <Input type='text' name='txtName' ref={inputRef} value={contactName} onChange={onChangeName} />
                                </Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} className='label'>{t('customerPage.contact_email')}</Col>
                            <Col span={12} className='field'>
                                <Item
                                      validateStatus={invoicingField.validateStatus}
                                      help={invoicingField.errorMsg}
                                      hasFeedback>
                                    <Input type='text' name='txtEmail' value={contactEmail} onBlur={(e) => checkEmail(e.target.value)} onChange={onChangeEmail} />
                                </Item>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} className='label'>{t('customerPage.contact_phone')}</Col>
                            <Col span={12} className='field'>
                                <Item validateStatus={phoneField.validateStatus}
                                    help={phoneField.errorMsg}
                                    hasFeedback>
                                    <Input type='text' name='txtPhone' placeholder='+420777123456' value={contactPhone} onBlur={(e) => checkPhone(e.target.value)} onChange={onChangePhone} />
                                </Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} className='label'>{t('customerPage.contact_note')}</Col>
                            <Col span={12} className='field'>
                                <TextArea name='txtName'
                                          data-gramm="false"
                                          data-gramm_editor="false"
                                          data-enable-grammarly="false"
                                          style={{height: '100px'}}
                                          value={contactNote} onChange={onChangeNote} />
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '25px'}}>
                            <Col span={6} className='label'>{t('customerPage.contact_user')}</Col>
                            <Col span={12} className='field'>
                                <Select style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 200}}
                                    allowClear={true}
                                    options={userSource}
                                    value={contactUserId}
                                    onSelect={onSelectUser}
                                />
                            </Col>
                        </Row>
                    </Content>
                </Col>
            </Row>
        </Layout>
    )

})



export default ContactForm