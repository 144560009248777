import { ActionType, ProductActions } from './actions'
import { AsyncActionMode } from 'common/models'
import {ProductDetails, ProductState} from './models'

const PRODUCT_INITIAL_STATE: ProductState = {
  isLoading: false,
  isSaving: false,
  error: undefined,
  products: []
}

export default (
    state = PRODUCT_INITIAL_STATE,
    action: ProductActions,
): ProductState => {
  switch (action.type) {
    case ActionType.GET_PRODUCT:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: false }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          product: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_PRODUCT:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          products: state.products?.concat(action.data),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_PRODUCTS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          products: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_PRODUCT:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          products: state.products!.filter(
              (product) => product.id !== action.request.id,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.UPDATE_PRODUCT:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          product: undefined,
          isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          product: action.data,
          products: state.products.map((p) => p.id === action.data.id ? { ...action.data } : p),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
  }
  return state
}
