import {
    RemoveDomainDnsRecordRequestAction,
    RemoveDomainDnsRecordResponseAction,
    RemoveDomainDnsRecordErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { RemoveDnsRecordParams} from '../models'


export default (
    params: RemoveDnsRecordParams,
    cb?: (isSuccess: boolean) => void
) => {
    return (
        dispatch: (
            arg:
                | RemoveDomainDnsRecordRequestAction
                | RemoveDomainDnsRecordResponseAction
                | RemoveDomainDnsRecordErrorAction
        ) => void
    ) => {
        const request = new RemoveDomainDnsRecordRequestAction(params)
        dispatch(request)
        protectedApiClient
            .delete(`/domain/${params.id}/dns/record?zone=${params.domain}&digest=${params.digest}`, params)
            .then((response) => {
                dispatch(new RemoveDomainDnsRecordResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new RemoveDomainDnsRecordErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
