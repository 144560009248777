import React, {useEffect, useState} from 'react'
import {Modal, Space, message, Spin, Col, Row, Card, Tag, Tooltip, Typography, Table} from 'antd'
import {
	CustomerServiceOutlined,
	DeleteOutlined,
	MenuOutlined
} from '@ant-design/icons/lib/icons'
import {useTranslation} from 'react-i18next'
import CustomerServiceForm from 'pages/billing/customerservice/components/CustomerServiceForm'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from 'common/models'
import getCustomerServicesByCustomer from './actions/getCustomerServiceByCustomer'
import deleteCustomerService from './actions/deleteCustomerService'
import moment, {Moment} from 'moment'
import {Link, RouteComponentProps, useHistory, useParams} from 'react-router-dom'
import {CustomerServiceDetails, UpdateCustomerServiceParams} from './models'
import {useLocation} from 'react-router-dom'
import './CustomerServicePage.scss'
import CustomerServiceOneTimeForm from './components/CustomerServiceOneTimeForm'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import Customer from "../../../common/Customer";
import {_isNotEmptyObject, removeDiac, SQL_DATE_FORMAT} from "../../../common/fce";
import {LoadingIndicator} from "../../../components";
import History2 from "../../../components/History/History2";
import Button from "antd-button-color";
import CustomerServiceFormUpgrade from "./components/CustomerServiceFormUpgrade";
import queryString, {ParsedQuery} from "query-string";
import InvoiceCustomerForm from "../../../components/Invoice/InvoiceCustomerForm";
import {
	BorderLeftOutlined,
	BorderRightOutlined,
	CheckSquareOutlined, CommentOutlined, EditOutlined, ExclamationCircleOutlined, ForwardOutlined, InteractionTwoTone,
	MoneyCollectOutlined, SaveOutlined, SaveTwoTone,
	ToolTwoTone
} from "@ant-design/icons";

import HistoryLog from "../../../components/History/HistoryLog";
import DateRange from "../../../components/RangeFilter/DateRange";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import getCustomerPricelist from "./actions/getCustomerPricelist";
import setOrder from "./actions/setOrder";
import getProducts from "../product/actions/getProducts";
import {ColumnsType} from "antd/es/table";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import getCustomer from "../customer/actions/getCustomer";
import CustomerForm from "../customer/CustomerForm";
import TextArea from "antd/es/input/TextArea";
import CommentForm from "../../../components/Comment/CommentForm";
import updateCustomer from "../customer/actions/updateCustomer";
import getCustomerService from "./actions/getCustomerService";
import {ROLE_BILLING_EDITOR, ROLE_BILLING_VIEWER} from "../../../common/LoggedUser";



const { confirm } = Modal
const {Paragraph, Text, Title} = Typography

interface ParamTypes {
	customer_id: string
}

const InvoiceDetail = [
	"single_item","group_item","all_details"
]

const renderBadgeContent = (unit_count: number, unit?: string) => (unit ? `${unit_count} ${unit}` : unit_count)

const getBillingPeriod = (customer) => {
	let period = customer?.billing_period.toUpperCase()
	if (period.includes('_')) {
		const arr = period.split('_')
		return arr[0]
	}
	return period
}

const lastInvoiceFunc = (rec, appSetting) => {
	if (rec.last_invoice) {
		return appSetting.renderDate(rec.last_invoice.period_from) + ' - ' + appSetting.renderDate(rec.last_invoice.period_to)
	} else {
		return ''
	}
}

const lastInvoiceFuncMark = (rec, t) => {
	if (rec.last_invoice) {
		if (rec.last_invoice.number) {
			if (rec.last_invoice.sent) {
				return (<span><b>{rec.last_invoice.number}</b> <i>{t('billing.customer-services.sent')}</i></span>)
			}
			else {
				return (<span><b>{rec.last_invoice.number}</b> <i>{t('billing.customer-services.unsent')}</i></span>)
			}
		}
		else {
			return (<i style={{color: 'red'}}>{t('billing.customer-services.draft')}</i>)
		}
	} else {
		return ' - '
	}
}

const sort_order = (b: CustomerServiceDetails, a:CustomerServiceDetails) => {
	if(!a.ordering) return 1;
	if(!b.ordering) return -1;
	if(a.ordering === b.ordering) {
		return (Date.parse(a.date_from) > Date.parse(b.date_from)) ? -1 : 1;
	}
	return (a.ordering > b.ordering) ? -1 : 1;
}

const CustomerServicePage = (props: RouteComponentProps) => {
	const CONTROL_NAME = 'page_billing_customer_detail'
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const {search} = useLocation()
	const {customer_id} = useParams<ParamTypes>()

	const {customerservices, pricelist, isLoading} = useSelector((state: AppState) => state.customerservice)
	const {customer, isLoading: isLoadingCustomer} = useSelector((state: AppState) => state.customer)
	const {productprices} = useSelector((state: AppState) => state.productprice)
	const {products} = useSelector((state: AppState) => state.product)

	const [serviceId, setServiceId] = useState<number | undefined>(undefined)
	const [isCreate, setIsCreate] = useState(false)
	const [isModalServiceVisible, setModalServiceVisible] = useState(false)
	const [isModalUpgradeVisible, setModalUpgradeVisible] = useState(false)
	const [isOneTimeModalVisible, setOneTimeModalVisible] = useState(false)
	const [isModalEditCustomerVisible, setModalEditCustomerVisible] = useState(false)
	const [isModalCommentVisible, setModalCommentVisible] = useState(false)

	const [historyModal, setHistoryModal] = useState<boolean>(false)

	const [dataToUpdate, setDataToUpdate] = useState<UpdateCustomerServiceParams>()
	const [expandedTableRows, setExpandedTableRows] = useState<(string | number)[]>()

	const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
	const [range, setRange] = useState<[Moment, Moment] | undefined>(undefined)

	const [dataSource, setDataSource] = useState<CustomerServiceDetails[]>([])
	const [isVatPayer, setVatPayer] = useState(false)
	const [billingCurrency, setBillingCurrency] = useState<string>('')
	const [historyModelId, setHistoryModelId] = useState<number | undefined>()
	const [forceUpdateId, setForceUpdateId] = useState<number>(1)

	const [isViewer, setViewer] = useState(false)
	const [isCreator, setCreator] = useState(false)
	const [isEditor, setEditor] = useState(false)
	const [hasBilling, setHasBilling] = useState(false)
	const [isCustomerEditor, setCustomerEditor] = useState(false)
	const [isDeleter, setDeleter] = useState(false)
	const [canGenerateInvoice, setGenerateInvoice] = useState(false)
	const [canTerminate, setTerminate] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [isIrregular, setIrregular] = useState(false)

	const SortableItem = SortableElement((props) => <tr {...props} />)
	const SortableContainers = SortableContainer((props) => <tbody {...props} />)
	const [selectedItems, setSelectedItems] = useState([])

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator/>
			</div>
		)
	}

	// required authorization
	if (!loggedUser.hasAccess(CONTROL_NAME)) {
		return <ErrorPage403 />
	}

	// settings
	const appSetting = loggedUser.getAppSettings()

	// logger
	const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('CustomerServicePage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('CustomerServicePage: ' + msg)} }

	useEffect(() => {
		// find customer, company, pricelist
		if (!customer_id) {
			// error
			message.error('Customer not found.')
			history.replace('/billing/customer')
			return
		}

		const viewer = loggedUser.hasAccess('page_billing_customer_detail')
		setViewer(viewer)
		setCreator(loggedUser.hasAccess('page_billing_customer_services_create_service'))
		setEditor(loggedUser.hasAccess('page_billing_customer_services_edit_service'))
		setDeleter(loggedUser.hasAccess('page_billing_customer_services_delete_service'))
		setGenerateInvoice(loggedUser.hasAccess('page_billing_customer_services_generate_invoice'))
		setTerminate(loggedUser.hasAccess('page_billing_customer_delete_button'))
		setCustomerEditor(loggedUser.hasAccess('page_billing_customer_edit_button'))
		const billing = loggedUser!.hasRole(ROLE_BILLING_VIEWER) || loggedUser!.hasRole(ROLE_BILLING_EDITOR)
		setHasBilling(billing)
		if (!viewer) {
			// go to customers
			history.replace('/billing/customer')
			return
		}

		const cid = parseInt(customer_id)
		if (cid < 1) {
			// error
			message.error('Customer not found.')
			history.replace('/billing/customer')
			return
		}
		logger('loading customer id = ' + cid)
		dispatch(getProducts())
		dispatch(getCustomer(cid, 'last_invoice,company'))
		dispatch(getCustomerPricelist(cid))
	}, [customer_id])

	useEffect(() => {
		if (!customer) {
			return
		}
		const cid = parseInt(customer_id)
		if (customer.id != cid) {
			return
		}

		logger('customer loaded id = ' + customer.id)
		logger('customer.last_invoice.period_from = ' + customer.last_invoice?.period_from)
		setHistoryModelId(cid)
		setVatPayer(Boolean(customer.company?.vat_payer))
		setBillingCurrency(customer.billing_currency)
		setIrregular(customer.billing_period === 'iregular')
		let isRange = false
		if (parsed && _isNotEmptyObject(parsed)) {
			// apply QS filters
			// and set filters
			isRange = handleQueryStringFilters()
		}
		if (!isRange) {
			if (range === undefined) {
				// the first time
				// without querystring
				// dont set filter (it will be calculated on fly)
				const c = new Customer(customer)
				setRange(c.getCurrentBillingPeriod())	// force load data
			}
		}
		setForceUpdateId(forceUpdateId+1)	// update history
	}, [customer])

	useEffect(() => {
		if (range && range[0] && range[1] && customer) {
			logger('customerservices range:' + appSetting.renderDateTime(range[0].format(SQL_DATE_FORMAT)))
			loadCustomerServices()
		}
	}, [range])

	useEffect(() => {
		if (customerservices) {
			// customer services loaded
			refreshGrid()
		}
	}, [customerservices])

	const handleQueryStringFilters = ():boolean => {
		// use QS filters /billing/customer-services/14?from=2022-08-01&to=2022-10-31
		let s_from
		let s_to
		const qs_from: string | string[] | null = parsed['from']
		let isRange = false
		if (qs_from && typeof qs_from === 'string' && qs_from.length > 8) {
			const qs_to: string | string[] | null = parsed['to']
			if (qs_to && typeof qs_to === 'string' && qs_to.length > 8) {
				s_from = qs_from
				s_to = qs_to
			} else {
				s_from = qs_from
				s_to = moment().endOf('year').format(SQL_DATE_FORMAT)
			}
			isRange = true
			setRange([moment(s_from), moment(s_to)])	// force load data
		}
		return isRange
	}

	const loadCustomerServices = () => {
		// load ALL customer services
		if (!range) {
			// not ready
			return
		}
		const filter_from: Moment = range[0]
		const filter_to: Moment = range[1]

		if (!isLoading && customer) {
			dispatch(getCustomerServicesByCustomer(customer.id, filter_from.format(SQL_DATE_FORMAT), filter_to.format(SQL_DATE_FORMAT), suc => {
				logger('loadCustomerServices..completed: '+ customerservices.length)
			}))
		}
	}

	const filtered = () => {
		if (!customerservices || customerservices.length === 0) {
			return []
		}
		if (!range) {
			return customerservices.sort(sort_order)
		}

		let qs:string[] = []
		qs.push('from='+range[0].format(SQL_DATE_FORMAT))
		qs.push('to='+range[1].format(SQL_DATE_FORMAT))
		const from = range[0]
		const to = range[1]
		logger('filtered='+ qs.join("&"))
		const qService1 = (service) => moment(service.date_from).unix() <= to.unix() && service.date_to == null && service.is_otp === 0
		const qService2 = (service) => moment(service.date_from).unix() <= to.unix() && moment(service.date_to).unix() >= from.unix() && service.is_otp === 0
		const qOTP = (service) => moment(service.date_from).unix() <= to.unix() && moment(service.date_from).unix() >= from.unix() && service.is_otp === 1
		let data = customerservices.filter((cs) => qService1(cs) || qService2(cs) || qOTP(cs))
		history.replace('/billing/customer-services/' + customer_id + '?' + qs.join("&"))
		setParsed(queryString.parse(search))
		// logger('filtered=', data)
		data = data.map(cs => {return {...cs, is_additional: !cs.parent_id ? 0 : 1}})
		return data.sort(sort_order)
	}

	const refreshGrid = () => {
		logger('customerservices refreshGrid ')
		setDataSource(filtered())
	}

	const showConfirmDelete = (rec: CustomerServiceDetails, title: string) => {
		if (!rec) {
			return
		}
		confirm({
				icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
				title: title,
				content: <p>{rec.name}</p>,
				okText: t('general.yes'),
				cancelText: t('general.cancel'),
				okButtonProps: { loading: confirmDelete },
				className: 'confirm-alert',
				onOk() {
					setConfirmDelete(true)
					dispatch(deleteCustomerService(rec.id, suc => {
						setConfirmDelete(false)
						setOpenDelete(false)
						if (suc) {
							message.success(t('general.success'))
							setForceUpdateId(forceUpdateId+1)	// update history
						}
					}))
				},
				onCancel() {
					setOpenDelete(false)
				},
			}
		)
	}

	const onRefresh = (): void => {
		onRefreshGrid()
	}

	const onRefreshGrid = () => {
		// called after create a new service to calculate prices by range
		if (!customer) {
			return
		}

		// reload customer services
		loadCustomerServices()
	}

	const handleDateChange = (dt_from: string, dt_to: string) => {
		// use SQL_DATE_FORMAT
		if (dt_from && dt_to) {
			const dt1: Moment = moment(dt_from)
			const dt2: Moment = moment(dt_to)
			setRange([dt1, dt2])
		}
	}

	const allowUpgrade = (cs: CustomerServiceDetails):boolean => {
		if (cs.date_to) {
			return true
		}
		return !!cs.is_otp
	}

	const saveComment = (note?: string) => {
		if (customer && customer.id) {
			if (!note) {
				note = ''
			}
			const params = {id: customer.id, note: note}
			dispatch(updateCustomer(params, suc => {
				setModalCommentVisible(false)
				// dispatch(getCustomer(customer.id, 'last_invoice,company'))
			}))
		}
	}

	const isExpanded = (record: CustomerServiceDetails) => expandedTableRows?.find((i) => i === record.key)

	const getPriceType = (record) => {
		if (!record || !record.price || !record.price.calculation_type) {
			return 'price'
		}
		if (record.price.calculation_type.toUpperCase() === 'FIX') {
			return 'priceFix'
		}
		if (record.price.calculation_type.toUpperCase() === 'UNIT') {
			return 'priceUnit'
		}
		if (record.price.calculation_type.toUpperCase() === 'RANGE') {
			return 'priceRange'
		}
		return 'price'
	}

	const rowClassName = (record) => {
		if (record.previous_id) {
			return customerservices?.find((service) => service.previous_id === record.id)
				? 'upgradedRow'
				: record.is_otp
					? "finishedRow"
					: ""
		}
		return record.is_otp ? 'otpRow' : 'xxxxx'
	}

	const setNewOrder = (id, ordering) => {
		let params: any = {id: id, ordering: ordering}
		dispatch(setOrder(params, suc => {
			// updates services
			suc && loadCustomerServices()
		}))
	}
	const DragHandle = SortableHandle(({ active }) => (
		<MenuOutlined style={{ cursor: "grab", color: active ? "blue" : "#999" }} />
	));

	const merge = (a, b, i = 0) => {
		let aa = [...a];
		return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		logger('===== onSortEnd: '+ oldIndex + ', ' + newIndex)
		let orderId, newOrder
		if (dataSource[newIndex].ordering === undefined) {
			return
		}
		if (oldIndex > newIndex) {
			// move up
			orderId = dataSource[oldIndex].id;
			newOrder = dataSource[newIndex].ordering
			isEditor && setNewOrder(orderId, newOrder)
		} else {
			// move down
			orderId = dataSource[oldIndex].id;
			newOrder = dataSource[newIndex].ordering
			isEditor && setNewOrder(orderId, newOrder+1)
		}
		let tempDataSource = dataSource;

		if (oldIndex !== newIndex) {
			if (!selectedItems.length) {
				let movingItem = tempDataSource[oldIndex];
				tempDataSource.splice(oldIndex, 1);
				tempDataSource = merge(tempDataSource, [movingItem], newIndex);
			} else {
				let filteredItems = [];
				selectedItems.forEach((d) => {
					// @ts-ignore
					filteredItems.push(tempDataSource[d]);
				});
				let newData = [];
				tempDataSource.forEach((d, i) => {
					// @ts-ignore
					if (!selectedItems.includes(i)) {
						// @ts-ignore
						newData.push(d);
					}
				});
				tempDataSource = [...newData];
				tempDataSource = merge(tempDataSource, filteredItems, newIndex);
			}
			setDataSource(tempDataSource);
			setSelectedItems([]);
		}
	};

	const DraggableContainer = (props) => {
		return (
			<SortableContainers
				useDragHandle
				disableAutoscroll
				helperClass="row-dragging"
				onSortEnd={onSortEnd}
				{...props}
			/>
		)}

	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		const pos: number = parseInt(restProps["data-row-key"])
		const index = dataSource.findIndex((x) => x.id === pos );
		return (
			<SortableItem
				index={index}
				{...restProps}
				selected={selectedItems.length}
				onClick={(e) => {
					if (e.ctrlKey || e.metaKey) {
						// @ts-ignore
						selectedItems.includes(index) ? selectedItems.splice(selectedItems.indexOf(index), 1) : selectedItems.push(index);
						setSelectedItems(selectedItems);
					} else {
						setSelectedItems([]);
					}
				}}
			/>
		);
	};

	const columns: ColumnsType<CustomerServiceDetails> = [
		{
			title: '',
			key: 'ordering',
			dataIndex: 'ordering',
			width: 30,
			className: "drag-visible",
			fixed: 'left',
			render: (d, cs, i ) => {
				if (cs.parent_id) {
					return (<div onClick={(e) => e.stopPropagation()}>&nbsp;</div>)
				} else {
					return (<DragHandle active={selectedItems.includes(i as never)} />)
				}
			},
		},
		{
			title: t('billing.customer-services.name'),
			dataIndex: 'name',
			key: 'name',
			width: 280,
			fixed: 'left',
			render: (d, cs, i ) =>
				{
					if (!cs.parent_id) {
						return (<div onClick={(e) => e.stopPropagation()}>{d}</div>)
					}
					else {
						return (
							<div onClick={(e) => e.stopPropagation()}
								 style={{paddingLeft: '15px'}}>{d}</div>
						)
					}
				}
		},
		{
			title: t('billing.customer-services.service'),
			dataIndex: 'product_id',
			key: 'product_id',
			width: '14%',
			ellipsis: true,
			render: (text: number) => (
				<div onClick={(e) => e.stopPropagation()}>{products?.find((i) => i.id === text)?.name}</div>
			),
		},
		{
			dataIndex: 'internal_note',
			key: 'internal_note',
			width: '12%',
			align: 'center',
			title: t('billing.customer-services.internal_note'),
			ellipsis: true
		},
		{
			title: t('billing.customer-services.unit-price') + ' (' + billingCurrency + ')',
			dataIndex: 'price.price_per_unit',
			width: 110,
			align: 'right',
			key: 'price.price_per_unit',
			className: 'price',
			render: (text, record) => {
				if (!record || !record.price) {
					return ''
				}
				return (<div onClick={(e) => e.stopPropagation()} className={getPriceType(record)}>{appSetting.renderPrice(record.price.price_per_unit)}</div>)
			},
		},
		{
			title: t('billing.customer-services.amount'),
			dataIndex: 'unit_count',
			key: 'unit_count',
			align: 'right',
			width: 70,
			render: (x: number, record) => {
				let productprice = productprices?.find((i) => i.product_id === record.product_id)
				if (!isExpanded(record)) {
					// @ts-ignore
					return <div onClick={(e) => e.stopPropagation()}>{renderBadgeContent(appSetting.renderCount(x), productprice?.unit )}</div>
				} else {
					// @ts-ignore
					return x ? <div onClick={(e) => e.stopPropagation()}>{renderBadgeContent(appSetting.renderCount(x), productprice?.unit)}</div> : ''
				}
			},
		},
		{
			title: t('billing.customer-services.price') + ' (' + billingCurrency + ')',
			dataIndex: 'price.without_vat',
			width: 100,
			align: 'right',
			key: 'price.without_vat',
			className: 'price',
			render: (text, record) => {
				if (!record || !record.price) {
					return ''
				}
				if (!isExpanded(record)) {
					return record.children ? (
						<div onClick={(e) => e.stopPropagation()}>
							{appSetting.renderPrice(record.children.reduce(
								(acc, val) => {
									if (!val || !val.price) {
										return acc
									}
									else {
										return acc + (isVatPayer ? val.price.without_vat : val.price.without_vat)
									}
								},
								isVatPayer ? record.price.without_vat : record.price.without_vat,
							))}{' '}
						</div>
					) : (
						<div onClick={(e) => e.stopPropagation()}>
							{ appSetting.renderPrice(isVatPayer ? record.price.without_vat : record.price.without_vat) }
						</div>
					)
				} else {
					return (
						<div onClick={(e) => e.stopPropagation()}>
							{ appSetting.renderPrice(isVatPayer ? record.price.without_vat : record.price.without_vat) }
						</div>
					)
				}
			},
		},
		{
			dataIndex: 'invoiced_by',
			key: 'invoiced_by',
			width: 110,
			align: 'center',
			title: t('billing.customer-services.invoiced_by'),
			render: (text: Date) => <div onClick={(e) => e.stopPropagation()}>{appSetting.renderDate(text)}</div>
		},
		{
			title: t('billing.customer-services.date_from'),
			dataIndex: 'date_from',
			key: 'date_from',
			width: 100,
			className: 'centered bold',
			render: (text: Date, record) => record.billable ? <div onClick={(e) => e.stopPropagation()}>{appSetting.renderDate(text)}</div> : (<BorderLeftOutlined />)
		},
		{
			dataIndex: 'date_to',
			key: 'date_to',
			width: 100,
			title: t('billing.customer-services.date_to'),
			className: 'centered bold',
			render: (text: Date, record) => {
				if (record.is_otp === 1) {
					return (<CheckSquareOutlined title={t('billing.customer-services.onetime_product')} />)
				}
				else {
					if (text) {
						return record.billable ? <div onClick={(e) => e.stopPropagation()}>{appSetting.renderDate(text)}</div> : (<BorderRightOutlined />)
					}
					else {
						return record.billable ? (<ForwardOutlined title={t('billing.customer-services.active_service')} />) : (<BorderRightOutlined />)
					}
				}
			}
		},
		{
			title: t('billing.customer-services.action_title'),
			key: 'action',
			width: 120,
			align: 'center',
			showSorterTooltip: false,
			fixed: 'right',
			render: (text, record) =>
				record.id && (
					<Space size={1}>
						{
							isEditor && (
								<Tooltip title={t('billing.customer-services.edit')} placement='bottom'>
									<Button type='text' size='small' className='actionButton'
											onClick={() => {
												dispatch(getCustomerService(record.id))
												setServiceId(record.id)
												setIsCreate(false)
												record.is_otp === 0 ? setModalServiceVisible(true) : setOneTimeModalVisible(true)
											}}>
										<ToolTwoTone twoToneColor='#1890ff' />
									</Button>
								</Tooltip>
							)
						}

						{
							(isEditor && allowUpgrade(record)) && (
								<Button type='text' size='small' className='actionButton'>
									<InteractionTwoTone twoToneColor='#cccccc' style={{cursor: 'not-allowed'}}/>
								</Button>
							)
						}
						{
							(isEditor && !allowUpgrade(record)) && (
								<Tooltip title={t('billing.customer-services.upgrade')} placement='bottom'>
									<Button type='text' size='small' className='actionButton'
											onClick={() => {
												dispatch(getCustomerService(record.id))
												setServiceId(record.id)
												setIsCreate(false)
												setModalUpgradeVisible(true)
											}}>
										<InteractionTwoTone twoToneColor='#50C878'/>
									</Button>
								</Tooltip>
							)
						}

						<div onClick={(e) => e.stopPropagation()}>
							<Tooltip title={t('billing.customer-services.delete')} placement='bottom'>
								<Button type='text' size='small' className='actionButton'
										disabled={!isDeleter}
										onClick={() => showConfirmDelete(record, t('billing.customer-services.delete_confirm'))}
										danger>
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</div>
					</Space>
				),
		},
	]

	if (isLoadingCustomer || !customer) {
		return <Spin />
	}

	return (
		<>
			<Card className='CustomerServicePage'
				title={<><CustomerServiceOutlined/>&nbsp;{t('billing.customer-services.title')} - <b>{customer.company?.name}</b></>}
				extra={
						isCustomerEditor ? (
							<Button type='primary'
									onClick={() => setModalEditCustomerVisible(true)}
									icon={<EditOutlined />}>{t('billing.customer.edit_customer')}</Button>)
							:
							(<Button type='primary' disabled={true}
									 icon={<EditOutlined />}>{t('billing.customer.edit_customer')}</Button>)
				}
			>
				<Row gutter={[8, 8]}>
					<Col span={24} className='center'>
						<Row justify='space-around' align='top' style={{paddingTop: '5px'}}>
							<Col span={10}>
								<Row>
									<Col xs={4} xl={6} className='right pad4'>
										<Text type='secondary'>{t('billing.customer-services.header.id_number')}: </Text>
									</Col>
									<Col xs={20} xl={18} className='left pad4'>
										{
											customer.company && (
												<Link to={`/billing/companies?company_number=${customer.company.company_number}`}>{customer.company.company_number}</Link>
											)
										}
									</Col>
								</Row>
								<Row>
									<Col xs={4} xl={6} className='right pad4'>
										<Text type='secondary'>{t('billing.customer-services.header.address')}: </Text>
									</Col>
									<Col xs={20} xl={18} className='left pad4'>
										<Text>
											{customer.company?.street}, {customer.company?.zip} {customer.company?.city} {customer.company?.country}
										</Text>
									</Col>
								</Row>
								<Row>
									<Col xs={4} xl={6} className='right pad4'>
										<Text type='secondary'>{t('billing.customer-services.header.email')}: </Text>
									</Col>
									<Col xs={20} xl={18} className='left pad4'>
										<Text>{customer.company?.email ?? '-'}</Text>
									</Col>
								</Row>
								<Row>
									<Col xs={4} xl={6} className='right pad4'>
										<Text type='secondary'>{t('billing.customer-services.header.phone')}: </Text>
									</Col>
									<Col xs={20} xl={18} className='left pad4'>
										<Text>{ appSetting.renderPhone(customer.company?.phone) ?? '-'}</Text>
									</Col>
								</Row>
								<Row>
									<Col xs={4} xl={6} className='right pad4'>
										<Text type='secondary'>{t('billing.customer.note')}: </Text><br/>
									</Col>
									<Col xs={20} xl={18} className='left pad4'>
										<TextArea readOnly={true}
												  onClick={() => {
													  if (isEditor) {
														  setModalCommentVisible(true)
													  }
												  }}
												  style={{borderColor: '#f0f0f0', cursor: 'pointer'}}
												  value={customer.note}
												  data-gramm="false" />&nbsp;
									</Col>
								</Row>
							</Col>

							<Col span={14}>
								<Row>
									<Col xs={6} xl={8} className='right pad4'>
										<Text type='secondary'>{t('billing.customer-services.header.pricelist')}: </Text>
									</Col>
									<Col xs={18} xl={16} className='left pad4'>
										{
											hasBilling && pricelist && (
												<Link to={`/billing/pricelist-products/${pricelist.id}`}>{pricelist.name}</Link>
											)
										}
										{
											!hasBilling && pricelist && (
												<span>{pricelist.name}</span>
											)
										}
									</Col>
								</Row>
								<Row>
									<Col xs={6} xl={8} className='right pad4'>
										<Text type='secondary'>{t('billing.customer-services.header.valid_from')}: </Text>
									</Col>
									<Col xs={4} xl={4} className='left pad4'>
										<Text>{appSetting.renderDate(pricelist?.valid_from)}</Text>
									</Col>
									<Col xs={2} xl={2} className='right pad4'>
										<Text type='secondary'>{t('billing.customer-services.header.valid_to')}: </Text>
									</Col>
									<Col xs={12} xl={10} className='left pad4'>
										<Text>{pricelist?.valid_to ? appSetting.renderDate(pricelist?.valid_to) : '~'}</Text>
									</Col>
								</Row>
								<Row>
									<Col xs={6} xl={8} className='right pad4'>
										<Text type='secondary'>{t('billing.customer-services.header.currency')}: </Text>
									</Col>
									<Col xs={18} xl={16} className='left pad4'>
										<Text>{pricelist?.currency}</Text>
									</Col>
								</Row>
								<Row>
									<Col xs={6} xl={8} className='right pad4'>
										<Text type='secondary'>{t('billing.customer-services.header.details')}: </Text>
									</Col>
									<Col xs={18} xl={16} className='left pad4'>
										<Text>{customer.invoice_detail && t(`billing.invoice.${InvoiceDetail[customer.invoice_detail]}`)}</Text>
									</Col>
								</Row>
								<Row>
									<Col xs={6} xl={8} className='right pad4'>
										<Text type='secondary'>{t('billing.customer-services.header.billing_period')}: </Text>
									</Col>
									<Col xs={4} xl={4} className='left pad4'>
										<Text>{t(`RangeFilter.${getBillingPeriod(customer)}`)}</Text>
									</Col>
									<Col xs={4} xl={4} className='right pad4'>
										<Text type='secondary'>{t('billing.customer-services.header.is_vat')}: </Text>
									</Col>
									<Col xs={6} xl={8} className='left pad4'>
										<Text>{pricelist?.is_vat_payer === 1 ? t('general.yes') : t('general.no')}</Text>
									</Col>
								</Row>
								<Row>
									<Col xs={6} xl={8} className='right pad4'>
										<Text type='secondary'>{t('customerPage.last_invoice')}: </Text>
									</Col>
									<Col xs={14} xl={16} className='left pad4'>
										<Text>{lastInvoiceFunc(customer, appSetting)}</Text> <Text>{lastInvoiceFuncMark(customer, t)}</Text>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row justify='space-around' align='middle' style={{marginTop: '22px'}}>
							<Col span={14}>
								<Row justify='start'>
									<Paragraph>
										<Space size='middle'>
											{
												!customer.terminated && (
													<>
														<Button type='success'
																disabled={!isEditor || isIrregular}
																onClick={() => {
																	setIsCreate(true)
																	setServiceId(undefined)
																	setModalServiceVisible(true)
																}}>
															{t('billing.customer-services.header.btn_create')}
														</Button>
														<Button type='primary'
																disabled={!isEditor || isIrregular}
																onClick={() => {
																	setIsCreate(true)
																	setServiceId(undefined)
																	setOneTimeModalVisible(true)
																}}>
															{t('billing.customer-services.header.btn_create_otp')}
														</Button>
														<InvoiceCustomerForm
															position='left'
															customer={customer}
															companyName={customer.company!.name}
															title={<h3>{t('billing.invoice.generate-title')}</h3>}>
															<Button type='warning'
																	icon={<MoneyCollectOutlined />}
																	disabled={!canGenerateInvoice || isIrregular || !hasBilling}>
																{t('billing.customer-services.header.gen_invoice')}{' '}
															</Button>
														</InvoiceCustomerForm>
													</>
												)
											}

											<Button color="primary" disabled={!hasBilling}>
												<Link to={`/billing/invoice?customer_id=${customer.id}`}>
													{t('billing.customer-services.header.invoices')}
												</Link>
											</Button>
										</Space>
									</Paragraph>
								</Row>
							</Col>

							<Col span={10} style={{paddingLeft: '10px'}}>

								{
									range && (
										<Paragraph>
											<Space>
												{t('billing.customer-services.header.period')}:
												<DateRange format={appSetting.date_picker_format} // not Moment formats!!
														   initStart={range && range[0] ? range[0].format(SQL_DATE_FORMAT) : ''}
														   initEnd={range && range[1] ? range[1].format(SQL_DATE_FORMAT) : ''}
														   onChange={handleDateChange}
												/>
											</Space>
										</Paragraph>
									)
								}

							</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={[8, 8]}>
					<Col span={24} className='center'>
						<Table
							columns={columns}
							bordered={true}
							dataSource={dataSource}
							rowClassName={(rec) => rowClassName(rec)}
							loading={isLoading}
							rowKey={(record) => record.id}
							className='CustomerServiceTable'
							pagination={false}
							components = {{
								body: {
									wrapper: DraggableContainer,
									row: DraggableBodyRow
								}
							}}
						/>
					</Col>
				</Row>

				<Row gutter={[8, 8]} style={{marginTop: '32px'}}>
					<Col span={24}>
						<HistoryLog service='customer'
									model='Customer'
									modelId={historyModelId}
									forceUpdateId={forceUpdateId}
									isModal={false}
									showTitle={true} />
					</Col>
				</Row>

				<Row gutter={[2, 2]}>
					<Col span={24}>
						<Button type='primary' onClick={() => setHistoryModal(true)}>Stará historie</Button>
					</Col>
				</Row>

			</Card>

			<Modal title={
				<>
					<CustomerServiceOutlined />{' '}
					{!isCreate ? t('billing.customer-services.update_title') : t('billing.customer-services.create_title')}
				</>
			}
				   destroyOnClose={true}
				   style={{top: 20, width: 'auto', minWidth: '700px', minHeight: '730px'}}
				   visible={isModalServiceVisible}
				   onCancel={() => setModalServiceVisible(false)}
				   maskClosable={false}
				   footer={null}>
				<CustomerServiceForm
					isCreate={isCreate}
					serviceId={serviceId}
					setModalVisible={setModalServiceVisible}
					onRefreshGrid={onRefreshGrid}
					updateHistory={() => setForceUpdateId(forceUpdateId+1)}
				/>
			</Modal>

			<Modal title={
				<>
					<CustomerServiceOutlined />{' ' + t('billing.customer-services.upgrade_title')}
				</>
			}
				   destroyOnClose={true}
				   style={{top: 20, width: 'auto', minWidth: '700px'}}
				   visible={isModalUpgradeVisible}
				   onCancel={() => setModalUpgradeVisible(false)}
				   maskClosable={false}
				   footer={null}>
				<CustomerServiceFormUpgrade
					setModalVisible={setModalUpgradeVisible}
					serviceId={serviceId}
					updateHistory={() => setForceUpdateId(forceUpdateId+1)}
					onRefreshGrid={onRefreshGrid}
				/>
			</Modal>

			<Modal title={
				<>
					<CustomerServiceOutlined />{' '}
					{dataToUpdate
						? t('billing.customer-services.onetime_update_title')
						: t('billing.customer-services.onetime_create_title')}
				</>
			}
				   destroyOnClose={true}
				   style={{top: 20, width: 'auto', minWidth: '700px'}}
				   visible={isOneTimeModalVisible}
				   onCancel={() => setOneTimeModalVisible(false)}
				   maskClosable={false}
				   footer={null}>
				<CustomerServiceOneTimeForm
					isCreate={isCreate}
					serviceId={serviceId}
					setModalVisible={setOneTimeModalVisible}
					onRefreshGrid={onRefreshGrid}
					updateHistory={() => setForceUpdateId(forceUpdateId+1)}
				/>
			</Modal>

			<Modal title={ t('billing.customer.modal-update-title') + ' ' + customer.company?.name}
				destroyOnClose
				style={{top: 20}}
				width={600}
				visible={isModalEditCustomerVisible}
				onCancel={() => setModalEditCustomerVisible(false)}
				maskClosable={false}
				footer={null}>
				<CustomerForm isCreate={false}
							  setModalVisible={() => setModalEditCustomerVisible(false)} />
			</Modal>

			<Modal title={<><CommentOutlined/> &nbsp; {t('general.comment')}</>}
				   destroyOnClose
				   className='modalComment'
				   style={{top: 20}}
				   visible={isModalCommentVisible}
				   onOk={() => setModalCommentVisible(false)}
				   onCancel={() => setModalCommentVisible(false)}
				   getContainer={false}
				   footer={null}
				   confirmLoading={true}
			>
				<CommentForm comment={customer.note} setModalVisible={setModalCommentVisible} saveComment={saveComment} />
			</Modal>

			<Modal
				destroyOnClose
				style={{top: 50}}
				bodyStyle={{height: '60%', minHeight: 150, padding: 2}}
				width='60%'
				className='historyModal'
				title={t('usersPage.history')}
				visible={historyModal}
				onCancel={() => setHistoryModal(false)}
				footer={null}
				maskClosable={false}
			>
				<History2
					url={"/model-history?service=customer&model=Customer&id="+customer.id}
					id={customer.id}
					historyName="user"
					showTitle={false}
					scrollY='450px'
				/>
			</Modal>
		</>
	)
}

export default CustomerServicePage
