import {Col, Row, Tag} from "antd";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {UserDetails} from "../../pages/user/models";
import {SUPER_ROLE} from "../../common/LoggedUser";

interface Props {
    role: string
}

const RoleTag = (props: Props) => {
    const {role} = props

    const [color, setColor] = useState<string>('blue')

    useEffect(() => {
        if (role === SUPER_ROLE) {
            setColor('red')
        }
        if (role.includes('billing')) {
            setColor('orange')
        }
    }, [])

    return (<div  key={'role-'+role}><Tag key={'role-'+role} color={color}>{role}</Tag></div>)
}

export default RoleTag