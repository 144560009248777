import {
    AddDomainDnsRecordRequestAction,
    AddDomainDnsRecordResponseAction,
    AddDomainDnsRecordErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { AddDnsRecordParams, DnsZoneModel } from '../models'

export default (
    params: AddDnsRecordParams,
    cb?: (isSuccess: boolean) => void
) => {
    return (
        dispatch: (
            arg:
                | AddDomainDnsRecordRequestAction
                | AddDomainDnsRecordResponseAction
                | AddDomainDnsRecordErrorAction
        ) => void
    ) => {
        const request = new AddDomainDnsRecordRequestAction(params)
        dispatch(request)
        let url = `/domain/${params.id}/dns/record?zone=${params.zone}`
        if (params.digest) {
            url = `/domain/${params.id}/dns/record?zone=${params.zone}&digest=${params.digest}`
        }
        protectedApiClient
            .post<DnsZoneModel>(url, params)
            .then((response) => {
                dispatch(new AddDomainDnsRecordResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new AddDomainDnsRecordErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
