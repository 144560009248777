import {
    SetDefaultScanningAction,
} from '.'

export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: SetDefaultScanningAction
        ) => void,
    ) => {
        dispatch(new SetDefaultScanningAction())

    }
}