import React, { useState, useEffect } from 'react'
import { formItemLayout, tailLayout } from 'helpers/layoutHelpers'
import { useForm } from 'antd/lib/form/Form'
import { Form, Input, Switch, Button, Select, message, Spin } from 'antd'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'common/models'

import { Country } from 'pages/billing/countries/models'
import getCountry from 'pages/billing/countries/actions/getCountry'
import updateCountry from 'pages/billing/countries/actions/updateCountry'


interface Props {
    setModalVisible: (isShow: boolean) => void
    updateId: number
}

const emptyData = {
    id: 0,
    iso: '',
    code: '',
    name_cz: '',
    name_en: '',
    currency: ''

}


const { Item } = Form


const CountriesForm = ({ setModalVisible, updateId }: Props) => {
    const { t } = useTranslation()
    const [form] = useForm()

    const dispatch = useDispatch()
    const { fontSize } = useSelector((state: AppState) => state.font)

    const { countries, country } = useSelector((state: AppState) => state.countriesPage)
    const { currencies } = useSelector((state: AppState) => state.currencies)
    const [updateItem, setUpdateItem] = useState<Country>(emptyData)

    const { Option } = Select
    useEffect(() => {
        setUpdateItem(emptyData)

        if (updateId) {
            // try call local DB
            const existingItem = countries.find(item => item.id === updateId)
            if (existingItem) {
                dispatch(getCountry(existingItem.id!))
            }
        }
    }, [])




    useEffect(() => {
        form.setFieldsValue(country)
        if (country) {
            setUpdateItem(country)
        }

    }, [country])

    const onFinish = (values) => {

        if (updateId !== 0) {
            dispatch(updateCountry(
                {
                    ...values, id: updateItem.id
                },
                (suc: boolean) => {
                    if (suc) {
                        message.success(t('general.success'))
                        setModalVisible(false)
                    }
                },
            ))

        }

    }

    return (
        <>
            <Form
                form={form}
                {...formItemLayout}
                name='create-country'
                className=''
                onFinish={onFinish}
                initialValues={{ ...updateItem }}
            >
                <Item name='iso'
                    rules={[{ required: true, message: t('errors.field_required') }]}
                    label={t('countries.columns.iso')}
                    hasFeedback>
                    <Input disabled={true} size={fontSize} />
                </Item>
                <Item name='code'
                    rules={[{ required: true, message: t('errors.field_required') }]}
                    label={t('countries.columns.code')}
                    hasFeedback>
                    <Input disabled={true} size={fontSize} />
                </Item>
                <Item name='name_cz'
                    rules={[{ required: true, message: t('errors.field_required') }]}
                    label={t('countries.columns.name_cz')}
                    hasFeedback>
                    <Input disabled={true} size={fontSize} />
                </Item>
                <Item name='name_en'
                    rules={[{ required: true, message: t('errors.field_required') }]}
                    label={t('countries.columns.name_en')}
                    hasFeedback>
                    <Input disabled={true} size={fontSize} />
                </Item>
                <Item name='currency'
                    rules={[{ required: true, message: t('errors.field_required') }]}
                    label={t('countries.columns.currency')}
                    hasFeedback>
                    <Select style={{ width: 200 }} >
                        {currencies!.map((value, index) => {
                            return <Option value={value} key={index}>{value}</Option>
                        })}
                    </Select>
                </Item>





                <Item {...tailLayout}>
                    <Button type='primary' htmlType='submit'
                        className='login-form-button'
                        size={fontSize}>
                        {(updateItem && updateItem.id) ? t('countries.update-title') : t('countries.create-title')}
                    </Button>
                </Item>
            </Form>
        </>

    )
}

export default CountriesForm
