import {
    UpdateInventoryRequestAction,
    UpdateInventoryResponseAction,
    UpdateInventoryErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { InventoryModel } from '../models'

export default (
    params: InventoryModel,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | UpdateInventoryRequestAction
                | UpdateInventoryResponseAction
                | UpdateInventoryErrorAction,
        ) => void,
    ) => {
        const request = new UpdateInventoryRequestAction(params)
        dispatch(request)
        const id = params.id
        protectedApiClient
            .put<InventoryModel>(`/inventory/inventory/${id}/decommission-status`, {status_id: params.status_id})
            .then((response) => {
                dispatch(new UpdateInventoryResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateInventoryErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
