import {
    AddDnsServiceRequestAction,
    AddDnsServiceResponseAction,
    AddDnsServiceErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {DomainServiceParams, DnsZoneModel, DomainModel} from '../models'

export default (
    params: DomainServiceParams,
    cb?: (isSuccess: boolean) => void
) => {
    return (
        dispatch: (
            arg:
                | AddDnsServiceRequestAction
                | AddDnsServiceResponseAction
                | AddDnsServiceErrorAction
        ) => void
    ) => {
        const request = new AddDnsServiceRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<DomainModel>(`/domain/${params.id}/dns`, params)
            .then((response) => {
                dispatch(new AddDnsServiceResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new AddDnsServiceErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
