import {
    RemovePtrRequestAction,
    RemovePtrResponseAction,
    RemovePtrErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {EditPtrParams, IpAddressModel} from '../models'

export default (
    id: number,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | RemovePtrRequestAction
                | RemovePtrResponseAction
                | RemovePtrErrorAction,
        ) => void,
    ) => {
        const request = new RemovePtrRequestAction(id)
        dispatch(request)

        protectedApiClient
            .delete(`/ip/ip/${id}/ptr`)
            .then((response) => {
                dispatch(new RemovePtrResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new RemovePtrErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
