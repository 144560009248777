import {
    CreateUserAuthenticationRequestAction,
    CreateUserAuthenticationResponseAction,
    CreateUserAuthenticationErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { UserAuthenticationModel} from '../models'


export default (params: UserAuthenticationModel, cb?: (suc: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | CreateUserAuthenticationRequestAction
                | CreateUserAuthenticationResponseAction
                | CreateUserAuthenticationErrorAction,
        ) => void,
    ) => {
        const request = new CreateUserAuthenticationRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<UserAuthenticationModel>(`/user/${params.user_id}/authentication`, params)
            .then((response) => {
                dispatch(new CreateUserAuthenticationResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new CreateUserAuthenticationErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
