import { AsyncActionMode } from 'common/models'
import { InventoryTypeModel, InventoryTypeParams} from '../models'


export enum ActionType {
  GET_INVENTORY_TYPES = 'GET_INVENTORY_TYPES',
  CREATE_INVENTORY_TYPE = 'CREATE_INVENTORY_TYPE',
  UPDATE_INVENTORY_TYPE = 'UPDATE_INVENTORY_TYPE',
  DELETE_INVENTORY_TYPE = 'DELETE_INVENTORY_TYPE',
}

export type InventoryTypeActions =
    | GetInventoryTypesRequestAction
    | GetInventoryTypesResponseAction
    | GetInventoryTypesErrorAction
    | CreateInventoryTypeRequestAction
    | CreateInventoryTypeResponseAction
    | CreateInventoryTypeErrorAction
    | UpdateInventoryTypeRequestAction
    | UpdateInventoryTypeResponseAction
    | UpdateInventoryTypeErrorAction
    | DeleteInventoryTypeRequestAction
    | DeleteInventoryTypeResponseAction
    | DeleteInventoryTypeErrorAction

export class GetInventoryTypesRequestAction {
  readonly type = ActionType.GET_INVENTORY_TYPES
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetInventoryTypesResponseAction {
  readonly type = ActionType.GET_INVENTORY_TYPES
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: GetInventoryTypesRequestAction,
    public data: InventoryTypeModel[],
  ) {}
}
export class GetInventoryTypesErrorAction {
  readonly type = ActionType.GET_INVENTORY_TYPES
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: GetInventoryTypesRequestAction,
    public error: string,
  ) {}
}


export class CreateInventoryTypeRequestAction {
  readonly type = ActionType.CREATE_INVENTORY_TYPE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: InventoryTypeParams) {
  }
}

export class CreateInventoryTypeResponseAction {
  readonly type = ActionType.CREATE_INVENTORY_TYPE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: CreateInventoryTypeRequestAction,
      public data: InventoryTypeModel,
  ) {
  }
}

export class CreateInventoryTypeErrorAction {
  readonly type = ActionType.CREATE_INVENTORY_TYPE
  readonly mode = AsyncActionMode.ERROR

  constructor(
      public request: CreateInventoryTypeRequestAction,
      public error: string,
  ) {
  }
}

export class UpdateInventoryTypeRequestAction {
  readonly type = ActionType.UPDATE_INVENTORY_TYPE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public item: InventoryTypeParams) {
  }
}

export class UpdateInventoryTypeResponseAction {
  readonly type = ActionType.UPDATE_INVENTORY_TYPE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: UpdateInventoryTypeRequestAction,
      public data: InventoryTypeModel,
  ) {
  }
}

export class UpdateInventoryTypeErrorAction {
  readonly type = ActionType.UPDATE_INVENTORY_TYPE
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: UpdateInventoryTypeRequestAction,
      public error: string,
  ) {
  }
}

export class DeleteInventoryTypeRequestAction {
  readonly type = ActionType.DELETE_INVENTORY_TYPE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}

export class DeleteInventoryTypeResponseAction {
  readonly type = ActionType.DELETE_INVENTORY_TYPE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: DeleteInventoryTypeRequestAction,
      public data: InventoryTypeModel,
  ) {
  }
}

export class DeleteInventoryTypeErrorAction {
  readonly type = ActionType.DELETE_INVENTORY_TYPE
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: DeleteInventoryTypeRequestAction,
      public error: string,
  ) {
  }
}
