import React, {ReactNode, useEffect, useState} from 'react'
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Radio,
    RadioChangeEvent,
    Row,
    Select,
    Spin,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { formItemLayout } from 'helpers/layoutHelpers'
import { useTranslation } from 'react-i18next'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../common/models";
import getInventoryPageLocations from "./actions/getInventoryPageLocations";
import getInventoryPageTypes from "./actions/getInventoryPageTypes";
import createInventory from "./actions/createInventory";
import {_parsePrice, decEn, ZCOM_COMPANY_ID} from "../../../common/fce";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import useLogger from "../../../common/useLogger";
import './inventory.scss'
import getInventoryBills from "./actions/getInventoryBills";
import {BillModel} from "../../billing/bill/models";
import DateInput from "../../../components/RangeFilter/DateInput";
import {_validateInventoryForm} from "../common";
import tableCompanies from "../../login/actions/tableCompanies";

interface Props {
    setModalVisible: (values: boolean) => void
}

const { Item } = Form

const sort_name = (a, b) => {if(!a || !a.name) return -1; if(!b || !b.name) return 1; return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1}

const InventoryCreateForm = (props: Props) => {
    const {t} = useTranslation()
    const [form] = useForm()
    const dispatch = useDispatch()
    const inputNameRef = React.useRef<any>(null)
    const inputPriceRef = React.useRef<any>(null)
    const inputSerialRef = React.useRef<any>(null)
    const labels = [
        t('inventoryPage.create_close'),
        t('inventoryPage.create_close'),
        t('inventoryPage.create_new'),
        t('inventoryPage.create_again')
    ]

    const {isSaving, types, locations, bills} = useSelector((state: AppState) => state.inventory)
    const {companies} = useSelector((state: AppState) => state.auth.tables)

    const [createDate, setCreateDate] = useState<Date | undefined>(new Date())
    const [typeId, setTypeId] = useState<number>(0)
    const [locationId, setLocationId] = useState<number>(0)
    const [vendorId, setVendorId] = useState<number>(0)
    const [ownerId, setOwnerId] = useState<number>(ZCOM_COMPANY_ID)
    const [billId, setBillId] = useState<number>(0)
    const [action, setAction] = useState<number>(1)
    const [actionLabel, setActionLabel] = useState<string>('')
    const [isOwnerZcom, setOwnerZcom] = useState<boolean>(false)

    const [locationOptions, setLocationOptions] = useState<{ label: string, value: number }[]>([])
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: number }[]>([])
    const [ownerOptions, setOwnerOptions] = useState<{ label: string, value: number }[]>([])
    const [billOptions, setBillOptions] = useState<{ label: string | ReactNode, value: number }[]>([])
    const [typeOptions, setTypeOptions] = useState<{ label: string, value: number }[]>([])
    const [isCreator, setCreator] = useState(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()
    const logger = useLogger(appSetting, 'InventoryCreateForm')

    useEffect(() => {
        setAction(1)
        setActionLabel(labels[1])
    }, [])

    useEffect(() => {
        if (loggedUser) {
            setCreator(loggedUser.hasAccess('page_inventory_create_button'))
        }
        if (!companies || companies.length === 0) {
            dispatch(tableCompanies())
            return
        }

        if (!locations || locations.length === 0) {
            dispatch(getInventoryPageLocations())
            return
        }

        if (!types || types.length === 0) {
            dispatch(getInventoryPageTypes())
            return
        }
        setLocationOptions(locations.sort(sort_name).map(t => ({label: t.name, value: t.id})))
        setTypeOptions(types.sort(sort_name).map(t => ({label: t.name, value: t.id})))
        setCompanyOptions(companies.sort(sort_name).map(t => ({label: t.name, value: t.id})))
        setOwnerOptions(companies.sort(sort_name).map(t => ({label: t.name, value: t.id})))
    }, [companies, locations, types])

    useEffect(() => {
        if (vendorId) {
            dispatch(getInventoryBills(vendorId))
        }
    }, [vendorId])

    useEffect(() => {
        if (ownerId) {
            setOwnerZcom(ownerId === ZCOM_COMPANY_ID)
        }
        else {
            setOwnerZcom(false)
        }
        if (!ownerId || ownerId != ZCOM_COMPANY_ID) {
            form.setFields([
                {name: 'vendor_id', errors: undefined},
            ])
        }

    }, [ownerId])

    useEffect(() => {
        if (vendorId && bills) {
            setBillOptions(bills.sort(sort_name).map(b => ({label: renderBill(b), value: b.id})))
        }
    }, [vendorId, bills])

    const renderBill = (item: BillModel) => {
        return (
            <div className='name'>
                <span>{item.number} ({t('inventoryPage.day_at')}:{item.date_of_maturity})</span>
            </div>
        )
    }

    const clear = () => {
        form.setFields([
            {name: 'warranty', value: 1},
            {name: 'vendor_id', value: undefined},
            {name: 'location_id', value: 7},
            {name: 'type_id', value: undefined},
            {name: 'owner_id', value: 44},
            {name: 'bill_id', value: undefined},
            {name: 'price', value: ''},
            {name: 'serial', value: ''},
            {name: 'name', value: ''},
            {name: 'purchase_date', value: appSetting.renderDate(new Date())},
        ])
    }

    const validateForm = () => {
        return _validateInventoryForm(form, isOwnerZcom, t)
    }

    const handleFormatter = e => {
        let value = appSetting.renderPrice(_parsePrice(e.target.value))
        form.setFieldsValue({price: decEn(value)})
    }

    const onFinish = (values) => {
        if (!isCreator) {
            message.error(t('general.error403'))
            return
        }
        if (!validateForm()) {
            return
        }

        //const purchase_date = form.getFieldValue('purchase_date')
        // logger('moment: '+moment(purchase_date, appSetting.date_format).toISOString())
        // const dt = moment(purchase_date, appSetting.date_format).toDate()

        const price = form.getFieldValue('price')
        let params = {
            ...values,
            price: _parsePrice(`${price}`),
            purchase_date: createDate ? appSetting.renderDateSQL(createDate) : null,
            usable_type_id: undefined,
        }
        logger('params: ' + JSON.stringify(params))
        dispatch(createInventory(params, suc => {
                if (suc) {
                    message.success(t('general.success'))
                    if (action === 1) {
                        props.setModalVisible(false)
                    }
                    if (action === 2) {
                        clear();
                        (inputNameRef && inputNameRef.current) && inputNameRef.current.focus()
                    }
                    if (action === 3) {
                        form.setFields([{name: 'serial', value: ''}])
                        inputSerialRef.current && inputSerialRef.current.focus()
                    }
                }
            }
        ))
    }

    const onChangeAction = (e: RadioChangeEvent) => {
        setAction(Number(e.target.value))
        setActionLabel(labels[Number(e.target.value)])
    };

    if (!types || types.length === 0 ) {
        return (<Spin />)
    }
    if (!locations || locations.length === 0 ) {
        return (<Spin />)
    }
    if (!companies || companies.length === 0 ) {
        return (<Spin />)
    }

    // insert
    return (
        <div className='createInventory' style={{width: '100%'}}>
            <Form
                {...formItemLayout}
                onFinish={onFinish}
                form={form}
                initialValues={{
                    warranty: 12,
                    vendor_id: undefined,
                    location_id: 7,
                    type_id: undefined,
                    owner_id: 44,
                    bill_id: undefined,
                    price: '',
                    serial: '',
                    name: '',
                    purchase_date: appSetting.renderDate(createDate)
                }}
            >
                <Row>
                    <Col span={24}>
                        <Item name='name'
                              label={t('inventoryPage.name')}
                              rules={[{required: true, message: t('errors.field_required')}]}>
                            <TextArea autoFocus  style={{height: '32px'}}  ref={inputNameRef}
                                      data-gramm="false"
                                      data-gramm_editor="false"
                                      data-enable-grammarly="false" />
                        </Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Item name='type_id'
                              label={t('inventoryPage.type')}
                              rules={[{required: true, message: t('errors.field_required')}]}>
                            <Select
                                showSearch
                                filterOption={true}
                                optionFilterProp='label'
                                options={typeOptions}
                                onChange={(v) => setTypeId(v)}
                            />
                        </Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Item name='location_id'
                              label={t('inventoryPage.location')}
                              rules={[{required: true, message: t('errors.field_required')}]}>
                            <Select
                                showSearch
                                filterOption={true}
                                optionFilterProp='label'
                                options={locationOptions}
                                onChange={(v) => setLocationId(v)}
                            />
                        </Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Item name='vendor_id'
                              label={t('inventoryPage.vendor')}
                              rules={[
                                  {required: false},
                                  {validator: (_, value) => {
                                          let result = (value === ZCOM_COMPANY_ID)
                                          setOwnerZcom(result)
                                          return Promise.resolve();
                                      },
                                      message: ''
                                  }
                              ]}
                              >
                            <Select
                                showSearch
                                allowClear
                                filterOption={true}
                                optionFilterProp='label'
                                options={companyOptions}
                                onChange={(v) => setVendorId(v)}
                            />
                        </Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Item name='bill_id' label={t('inventoryPage.bill')}>
                            <Select
                                showSearch
                                allowClear
                                filterOption={true}
                                optionFilterProp='label'
                                options={billOptions}
                                onChange={(v) => setBillId(v)}
                            />
                        </Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Item name='purchase_date'
                              label={t('inventoryPage.purchase_date')}
                              rules={[{required: false}]}>

                            <DateInput htmlId='__purchase_date'
                                       initDateStr={appSetting.renderDateSQL(createDate)}
                                       disabled={false}
                                       required={false}
                                       format={appSetting.date_picker_format} // not Moment formats!!
                                       onChange={(date) => {
                                           if (date) {
                                               form.setFieldsValue({purchase_date: date})
                                               setCreateDate( new Date(date))
                                           }
                                           else {
                                               form.setFieldsValue({purchase_date: undefined})
                                           }
                                       }}
                            />
                        </Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Item name='warranty'
                              label={t('inventoryPage.warranty')}
                              rules={[{required: true, message: t('errors.field_required')}]}>
                            <InputNumber addonAfter='měs' style={{width: '160px'}} />
                        </Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Item name='owner_id' label={t('inventoryPage.owner')}>
                            <Select
                                showSearch
                                filterOption={true}
                                optionFilterProp='label'
                                options={ownerOptions}
                                onChange={(v) => setOwnerId(v)}
                            />
                        </Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Item name='serial' label={t('inventoryPage.serial')}>
                            <Input ref={inputSerialRef} style={{width: '160px'}} />
                        </Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Item name='price' label={t('inventoryPage.price')}>
                            <InputNumber
                                precision={2}
                                decimalSeparator=','
                                addonAfter='CZK'
                                style={{width: '160px'}}
                                className="price"
                                onKeyPress={(e) => {if (e.key === 'Enter') {handleFormatter(e)}}}
                                onBlur={handleFormatter} />

                        </Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Item name='comment' label={t('inventoryPage.comment')}>
                            <TextArea style={{height: '32px'}}
                                data-gramm="false"
                                data-gramm_editor="false"
                                data-enable-grammarly="false" />
                        </Item>
                    </Col>
                </Row>

                <Row style={{marginTop: '25px'}}>
                    <Col span={12} style={{textAlign: 'left', padding: '2px'}}>
                        <Radio.Group onChange={onChangeAction} value={action}>
                                <Radio value={1} style={{fontSize: '10px'}}>{t('inventoryPage.create_close')}</Radio>
                                <Radio value={2} style={{fontSize: '10px'}}>{t('inventoryPage.create_new')}</Radio>
                                <Radio value={3} style={{fontSize: '10px'}}>{t('inventoryPage.create_again')}</Radio>
                        </Radio.Group>
                    </Col>
                    <Col span={12} style={{textAlign: 'center'}}>
                        <Item style={{margin: '10px 10%'}}>
                            <Button loading={isSaving} type='primary' style={{width: '200px', whiteSpace: 'normal', height: undefined}} htmlType='submit'>
                                {actionLabel}
                            </Button>
                        </Item>
                    </Col>
                </Row>
                <Row>

                </Row>
            </Form>
        </div>
    )

}

export default InventoryCreateForm