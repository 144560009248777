import { AsyncActionMode } from 'common/models'
import {
  CreateInvoiceParams,
  InvoiceDetails,
  InvoiceError,
  UpdateInvoiceParams,
  CreateInvoiceItemParams,
  InvoiceItemDetails,
  InvoiceItemError,
  UpdateInvoiceItemParams,
  GenerateInvoiceParams,
  CreateInvoiceMailParams,
  InvoiceMailDetail,
  InvoiceSentMailDetail,
  SendInvoiceMailParams,
  NoteInvoicePayload,
  CreateInvoicePayload,
  IdPayload,
  InvoiceAttachmentError,
  InvoiceAttachmentDetails,
  CreateInvoiceAttachmentParams, InvoicePaidPayload
} from '../models'

import {InvoiceDocumentDetails, InvoiceDocumentError} from "../../invoicedocument/models";
import {CustomerDetails} from "../../customer/models";


export enum ActionType {
  CREATE_INVOICE = 'CREATE_INVOICE',
  GET_INVOICES = 'GET_INVOICES',
  GET_INVOICE = 'GET_INVOICE',
  UPDATE_INVOICE = 'UPDATE_INVOICE',
  DELETE_INVOICE = 'DELETE_INVOICE',
  REPAIR_INVOICE = 'REPAIR_INVOICE',
  FINALIZE_INVOICE = 'FINALIZE_INVOICE',
  SET_INVOICE_ORDER = 'SET_INVOICE_ORDER',
  CREATE_INVOICE_ITEM = 'CREATE_INVOICE_ITEM',
  GET_INVOICE_ITEM = 'GET_INVOICE_ITEM',
  GET_INVOICE_ITEMS = 'GET_INVOICE_ITEMS',
  UPDATE_INVOICE_ITEM = 'UPDATE_INVOICE_ITEM',
  DELETE_INVOICE_ITEM = 'DELETE_INVOICE_ITEM',
  GENERATE_INVOICE = 'GENERATE_INVOICE',
  CREATE_INVOICE_MAIL  = 'CREATE_INVOICE_MAIL',
  SEND_INVOICE_MAIL = 'SEND_INVOICE_MAIL',
  CREDIT_NOTE = 'CREDIT_NOTE',
  UPDATE_DATA_OF_PAYMENT = 'UPDATE_DATA_OF_PAYMENT',
  UPDATE_NOTE = 'UPDATE_NOTE',

  CREATE_INVOICEATTACHMENT = 'CREATE_INVOICEATTACHMENT',
  GET_INVOICEATTACHMENTS = 'GET_INVOICEATTACHMENTS',
  GET_INVOICEATTACHMENT = 'GET_INVOICEATTACHMENT',
  DELETE_INVOICEATTACHMENT = 'DELETE_INVOICEATTACHMENT',

  CREATE_INVOICEDOCUMENT = 'CREATE_INVOICEDOCUMENT',
  GET_INVOICEDOCUMENT_BY_INVOICE = 'GET_INVOICEDOCUMENT_BY_INVOICE',
  GET_INVOICEDOCUMENT = 'GET_INVOICEDOCUMENT',
  DELETE_INVOICEDOCUMENT = 'DELETE_INVOICEDOCUMENT'
  // GET_DOCUMENTCONTENT_BY_ID = 'GET_DOCUMENTCONTENT_BY_ID'
}

export type InvoiceActions =
  | CreateInvoiceRequestAction
  | CreateInvoiceResponseAction
  | CreateInvoiceErrorAction

  | GetInvoicesRequestAction
  | GetInvoicesResponseAction
  | GetInvoicesErrorAction

  | GetInvoiceRequestAction
  | GetInvoiceResponseAction
  | GetInvoiceErrorAction
  | UpdateInvoiceRequestAction
  | UpdateInvoiceResponseAction
  | UpdateInvoiceErrorAction

  | RepairInvoiceRequestAction
  | RepairInvoiceResponseAction
  | RepairInvoiceErrorAction
  | SetInvoiceOrderRequestAction
  | SetInvoiceOrderResponseAction
  | SetInvoiceOrderErrorAction
  | FinalizeInvoiceRequestAction
  | FinalizeInvoiceResponseAction
  | FinalizeInvoiceErrorAction
  | DeleteInvoiceRequestAction
  | DeleteInvoiceResponseAction
  | DeleteInvoiceErrorAction
  | CreateInvoiceItemRequestAction
  | CreateInvoiceItemResponseAction
  | CreateInvoiceItemErrorAction
  | GetInvoiceItemRequestAction
  | GetInvoiceItemResponseAction
  | GetInvoiceItemErrorAction
  | GetInvoiceItemsRequestAction
  | GetInvoiceItemsResponseAction
  | GetInvoiceItemsErrorAction
  | UpdateInvoiceItemRequestAction
  | UpdateInvoiceItemResponseAction
  | UpdateInvoiceItemErrorAction
  | DeleteInvoiceItemRequestAction
  | DeleteInvoiceItemResponseAction
  | DeleteInvoiceItemErrorAction
  | GenerateInvoiceRequestAction
  | GenerateInvoiceResponseAction
  | GenerateInvoiceErrorAction
  | CreateInvoiceMailRequestAction
  | CreateInvoiceMailResponseAction
  | CreateInvoiceMailErrorAction
  | SendInvoiceMailRequestAction
  | SendInvoiceMailResponseAction
  | SendInvoiceMailErrorAction
  | GetCreditNoteRequestAction
  | GetCreditNoteResponseAction
  | GetCreditNoteErrorAction
  | UpdateDataOfPaymentRequestAction
  | UpdateDataOfPaymentResponseAction
  | UpdateDataOfPaymentErrorAction
  | UpdateNoteRequestAction
  | UpdateNoteResponseAction
  | UpdateNoteErrorAction
  | CreateInvoiceAttachmentRequestAction
  | CreateInvoiceAttachmentResponseAction
  | CreateInvoiceAttachmentErrorAction
  | GetInvoiceAttachmentsRequestAction
  | GetInvoiceAttachmentsResponseAction
  | GetInvoiceAttachmentsErrorAction
  | GetInvoiceAttachmentRequestAction
  | GetInvoiceAttachmentResponseAction
  | GetInvoiceAttachmentErrorAction
  | DeleteInvoiceAttachmentRequestAction
  | DeleteInvoiceAttachmentResponseAction
  | DeleteInvoiceAttachmentErrorAction
  | CreateInvoiceDocumentRequestAction
  | CreateInvoiceDocumentResponseAction
  | CreateInvoiceDocumentErrorAction
  | GetInvoiceDocumentByInvoiceRequestAction
  | GetInvoiceDocumentByInvoiceResponseAction
  | GetInvoiceDocumentByInvoiceErrorAction
  | GetInvoiceDocumentRequestAction
  | GetInvoiceDocumentResponseAction
  | GetInvoiceDocumentErrorAction
  | DeleteInvoiceDocumentRequestAction
  | DeleteInvoiceDocumentResponseAction
  | DeleteInvoiceDocumentErrorAction


export class GetInvoicesRequestAction {
  readonly type = ActionType.GET_INVOICES
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetInvoicesResponseAction {
  readonly type = ActionType.GET_INVOICES
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: GetInvoicesRequestAction,
    public customers: CustomerDetails[],
    public data: InvoiceDetails[],
  ) {}
}
export class GetInvoicesErrorAction {
  readonly type = ActionType.GET_INVOICES
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: GetInvoicesRequestAction,
    public error: InvoiceError,
  ) {}
}

export class CreateInvoiceRequestAction {
  readonly type = ActionType.CREATE_INVOICE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: CreateInvoicePayload) {}
}
export class CreateInvoiceResponseAction {
  readonly type = ActionType.CREATE_INVOICE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: CreateInvoiceRequestAction,
    public data: InvoiceDetails,
  ) {}
}
export class CreateInvoiceErrorAction {
  readonly type = ActionType.CREATE_INVOICE
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: CreateInvoiceRequestAction,
    public error: InvoiceError,
  ) {}
}

export class CreateInvoiceMailRequestAction {
  readonly type = ActionType.CREATE_INVOICE_MAIL
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: CreateInvoiceMailParams) {
    ''
  }
}

export class CreateInvoiceMailResponseAction {
  readonly type = ActionType.CREATE_INVOICE_MAIL
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: CreateInvoiceMailRequestAction, public data: InvoiceMailDetail) {
  }
}

export class CreateInvoiceMailErrorAction {
  readonly type = ActionType.CREATE_INVOICE_MAIL
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: CreateInvoiceMailRequestAction, public error: InvoiceError) {
  }
}

export class SendInvoiceMailRequestAction {
  readonly type = ActionType.SEND_INVOICE_MAIL
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: SendInvoiceMailParams) {
  }
}

export class SendInvoiceMailResponseAction {
  readonly type = ActionType.SEND_INVOICE_MAIL
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: SendInvoiceMailRequestAction, public data: InvoiceDetails) {
  }
}

export class SendInvoiceMailErrorAction {
  readonly type = ActionType.SEND_INVOICE_MAIL
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: SendInvoiceMailRequestAction, public error: InvoiceError) {
  }
}


export class CreateInvoiceItemRequestAction {
  readonly type = ActionType.CREATE_INVOICE_ITEM
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: CreateInvoiceItemParams) {}
}
export class CreateInvoiceItemResponseAction {
  readonly type = ActionType.CREATE_INVOICE_ITEM
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: CreateInvoiceItemRequestAction,
    public data: InvoiceDetails,
  ) {}
}
export class CreateInvoiceItemErrorAction {
  readonly type = ActionType.CREATE_INVOICE_ITEM
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: CreateInvoiceItemRequestAction,
    public error: InvoiceItemError,
  ) {}
}

export class GenerateInvoiceRequestAction {
  readonly type = ActionType.GENERATE_INVOICE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: GenerateInvoiceParams) {
  }
}

export class GenerateInvoiceResponseAction {
  readonly type = ActionType.GENERATE_INVOICE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(public request: GenerateInvoiceRequestAction, public data: InvoiceDetails) {
  }
}

export class GenerateInvoiceErrorAction {
  readonly type = ActionType.GENERATE_INVOICE
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: GenerateInvoiceRequestAction, public error: InvoiceError) {
  }
}

export class GetInvoiceRequestAction {
  readonly type = ActionType.GET_INVOICE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetInvoiceResponseAction {
  readonly type = ActionType.GET_INVOICE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: GetInvoiceRequestAction,
    public data: InvoiceDetails,
  ) {}
}
export class GetInvoiceErrorAction {
  readonly type = ActionType.GET_INVOICE
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: GetInvoiceRequestAction,
    public error: InvoiceError,
  ) {}
}


export class GetInvoiceItemRequestAction {
  readonly type = ActionType.GET_INVOICE_ITEM
  readonly mode = AsyncActionMode.REQUEST
  constructor(public invoice_item_id: number) {
    ''
  }
}
export class GetInvoiceItemResponseAction {
  readonly type = ActionType.GET_INVOICE_ITEM
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetInvoiceItemRequestAction,
      public data: InvoiceItemDetails,
  ) {}
}
export class GetInvoiceItemErrorAction {
  readonly type = ActionType.GET_INVOICE_ITEM
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetInvoiceItemRequestAction,
      public error: InvoiceItemError,
  ) {}
}

export class GetInvoiceItemsRequestAction {
  readonly type = ActionType.GET_INVOICE_ITEMS
  readonly mode = AsyncActionMode.REQUEST
  constructor(public invoice_id: number) {
    ''
  }
}
export class GetInvoiceItemsResponseAction {
  readonly type = ActionType.GET_INVOICE_ITEMS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: GetInvoiceItemsRequestAction,
    public data: InvoiceItemDetails[],
  ) {}
}
export class GetInvoiceItemsErrorAction {
  readonly type = ActionType.GET_INVOICE_ITEMS
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: GetInvoiceItemsRequestAction,
    public error: InvoiceItemError,
  ) {}
}

export class UpdateInvoiceRequestAction {
  readonly type = ActionType.UPDATE_INVOICE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: UpdateInvoiceParams) {
    console.log('req.')
  }
}
export class UpdateInvoiceResponseAction {
  readonly type = ActionType.UPDATE_INVOICE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: UpdateInvoiceRequestAction,
    public data: InvoiceDetails,
  ) {}
}
export class UpdateInvoiceErrorAction {
  readonly type = ActionType.UPDATE_INVOICE
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: UpdateInvoiceRequestAction,
    public error: InvoiceError,
  ) {}
}

export class RepairInvoiceRequestAction {
  readonly type = ActionType.REPAIR_INVOICE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: IdPayload) {}
}
export class RepairInvoiceResponseAction {
  readonly type = ActionType.REPAIR_INVOICE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: RepairInvoiceRequestAction,
      public data: InvoiceDetails,
  ) {}
}
export class RepairInvoiceErrorAction {
  readonly type = ActionType.REPAIR_INVOICE
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: RepairInvoiceRequestAction,
      public error: InvoiceError,
  ) {}
}

export class FinalizeInvoiceRequestAction {
  readonly type = ActionType.FINALIZE_INVOICE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: IdPayload) {}
}
export class FinalizeInvoiceResponseAction {
  readonly type = ActionType.FINALIZE_INVOICE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: FinalizeInvoiceRequestAction,
      public data: InvoiceDetails,
  ) {}
}
export class FinalizeInvoiceErrorAction {
  readonly type = ActionType.FINALIZE_INVOICE
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: FinalizeInvoiceRequestAction,
      public error: InvoiceError,
  ) {}
}

export class SetInvoiceOrderRequestAction {
  readonly type = ActionType.SET_INVOICE_ORDER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: IdPayload) {}
}
export class SetInvoiceOrderResponseAction {
  readonly type = ActionType.SET_INVOICE_ORDER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: SetInvoiceOrderRequestAction,
      public data: InvoiceDetails,
  ) {}
}
export class SetInvoiceOrderErrorAction {
  readonly type = ActionType.SET_INVOICE_ORDER
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: SetInvoiceOrderRequestAction,
      public error: InvoiceError,
  ) {}
}

export class UpdateInvoiceItemRequestAction {
  readonly type = ActionType.UPDATE_INVOICE_ITEM
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: UpdateInvoiceItemParams) {}
}
export class UpdateInvoiceItemResponseAction {
  readonly type = ActionType.UPDATE_INVOICE_ITEM
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: UpdateInvoiceItemRequestAction,
    public data: InvoiceDetails,
  ) {}
}
export class UpdateInvoiceItemErrorAction {
  readonly type = ActionType.UPDATE_INVOICE_ITEM
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: UpdateInvoiceItemRequestAction,
    public error: InvoiceError,
  ) {}
}

export class DeleteInvoiceRequestAction {
  readonly type = ActionType.DELETE_INVOICE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}
export class DeleteInvoiceResponseAction {
  readonly type = ActionType.DELETE_INVOICE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: DeleteInvoiceRequestAction,
    public data: InvoiceDetails,
  ) {}
}
export class DeleteInvoiceErrorAction {
  readonly type = ActionType.DELETE_INVOICE
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: DeleteInvoiceRequestAction,
    public error: InvoiceError,
  ) {}
}

export class DeleteInvoiceItemRequestAction {
  readonly type = ActionType.DELETE_INVOICE_ITEM
  readonly mode = AsyncActionMode.REQUEST
  constructor(public params: IdPayload) {
    ''
  }
}
export class DeleteInvoiceItemResponseAction {
  readonly type = ActionType.DELETE_INVOICE_ITEM
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: DeleteInvoiceItemRequestAction,
    public data: InvoiceDetails,
  ) {}
}
export class DeleteInvoiceItemErrorAction {
  readonly type = ActionType.DELETE_INVOICE_ITEM
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: DeleteInvoiceItemRequestAction,
    public error: InvoiceError,
  ) {}
}

export class GetCreditNoteRequestAction {
  readonly type = ActionType.CREDIT_NOTE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: {id: number}) {
    ''
  }
}
export class GetCreditNoteResponseAction {
  readonly type = ActionType.CREDIT_NOTE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetCreditNoteRequestAction,
      public data: InvoiceDetails,
  ) {}
}
export class GetCreditNoteErrorAction {
  readonly type = ActionType.CREDIT_NOTE
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetCreditNoteRequestAction,
      public error: InvoiceError,
  ) {}
}

export class UpdateDataOfPaymentRequestAction {
  readonly type = ActionType.UPDATE_DATA_OF_PAYMENT
  readonly mode = AsyncActionMode.REQUEST
  constructor(
      public data: InvoicePaidPayload) {}
}
export class UpdateDataOfPaymentResponseAction {
  readonly type = ActionType.UPDATE_DATA_OF_PAYMENT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: UpdateDataOfPaymentRequestAction,
      public data: InvoiceDetails,
  ) {}
}
export class UpdateDataOfPaymentErrorAction {
  readonly type = ActionType.UPDATE_DATA_OF_PAYMENT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: UpdateDataOfPaymentRequestAction,
      public error: InvoiceError,
  ) {}
}


export class UpdateNoteRequestAction {
  readonly type = ActionType.UPDATE_NOTE
  readonly mode = AsyncActionMode.REQUEST
  constructor(
      public data: NoteInvoicePayload
  ) {}
}
export class UpdateNoteResponseAction {
  readonly type = ActionType.UPDATE_NOTE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: UpdateNoteRequestAction,
      public data: InvoiceDetails,
  ) {}
}
export class UpdateNoteErrorAction {
  readonly type = ActionType.UPDATE_NOTE
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: UpdateNoteRequestAction,
      public error: InvoiceError,
  ) {}
}

export class CreateInvoiceAttachmentRequestAction {
  readonly type = ActionType.CREATE_INVOICEATTACHMENT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: CreateInvoiceAttachmentParams) {}
}
export class CreateInvoiceAttachmentResponseAction {
  readonly type = ActionType.CREATE_INVOICEATTACHMENT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: CreateInvoiceAttachmentRequestAction,
      public data: InvoiceAttachmentDetails,
  ) {}
}
export class CreateInvoiceAttachmentErrorAction {
  readonly type = ActionType.CREATE_INVOICEATTACHMENT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: CreateInvoiceAttachmentRequestAction,
      public error: InvoiceAttachmentError,
  ) {}
}

export class GetInvoiceAttachmentRequestAction {
  readonly type = ActionType.GET_INVOICEATTACHMENT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetInvoiceAttachmentResponseAction {
  readonly type = ActionType.GET_INVOICEATTACHMENT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetInvoiceAttachmentRequestAction,
      public data: InvoiceAttachmentDetails,
  ) {}
}
export class GetInvoiceAttachmentErrorAction {
  readonly type = ActionType.GET_INVOICEATTACHMENT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetInvoiceAttachmentRequestAction,
      public error: InvoiceAttachmentError,
  ) {}
}

export class GetInvoiceAttachmentsRequestAction {
  readonly type = ActionType.GET_INVOICEATTACHMENTS
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetInvoiceAttachmentsResponseAction {
  readonly type = ActionType.GET_INVOICEATTACHMENTS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetInvoiceAttachmentsRequestAction,
      public data: InvoiceAttachmentDetails[],
  ) {}
}
export class GetInvoiceAttachmentsErrorAction {
  readonly type = ActionType.GET_INVOICEATTACHMENTS
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetInvoiceAttachmentsRequestAction,
      public error: InvoiceAttachmentError,
  ) {}
}

export class DeleteInvoiceAttachmentRequestAction {
  readonly type = ActionType.DELETE_INVOICEATTACHMENT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}
export class DeleteInvoiceAttachmentResponseAction {
  readonly type = ActionType.DELETE_INVOICEATTACHMENT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: DeleteInvoiceAttachmentRequestAction,
      public data: InvoiceAttachmentDetails,
  ) {}
}
export class DeleteInvoiceAttachmentErrorAction {
  readonly type = ActionType.DELETE_INVOICEATTACHMENT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: DeleteInvoiceAttachmentRequestAction,
      public error: InvoiceAttachmentError,
  ) {}
}


export class CreateInvoiceDocumentRequestAction {
  readonly type = ActionType.CREATE_INVOICEDOCUMENT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public invoice_id: number) {}
}
export class CreateInvoiceDocumentResponseAction {
  readonly type = ActionType.CREATE_INVOICEDOCUMENT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: CreateInvoiceDocumentRequestAction,
      public data: InvoiceDocumentDetails,
  ) {}
}
export class CreateInvoiceDocumentErrorAction {
  readonly type = ActionType.CREATE_INVOICEDOCUMENT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: CreateInvoiceDocumentRequestAction,
      public error: InvoiceDocumentError,
  ) {}
}

export class GetInvoiceDocumentRequestAction {
  readonly type = ActionType.GET_INVOICEDOCUMENT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetInvoiceDocumentResponseAction {
  readonly type = ActionType.GET_INVOICEDOCUMENT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetInvoiceDocumentRequestAction,
      public data: InvoiceDocumentDetails,
  ) {}
}
export class GetInvoiceDocumentErrorAction {
  readonly type = ActionType.GET_INVOICEDOCUMENT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetInvoiceDocumentRequestAction,
      public error: InvoiceDocumentError,
  ) {}
}
//
// export class GetDocumentContentRequestAction {
//   readonly type = ActionType.GET_DOCUMENTCONTENT_BY_ID
//   readonly mode = AsyncActionMode.REQUEST
//   constructor(public payload: number) {
//     ''
//   }
// }
//
// export class GetDocumentContentResponseAction {
//   readonly type = ActionType.GET_DOCUMENTCONTENT_BY_ID
//   readonly mode = AsyncActionMode.RESPONSE
//   constructor(public request: GetDocumentContentRequestAction, public data: string) {
//   }
// }
//
// export class GetDocumentContentErrorAction {
//   readonly type = ActionType.GET_DOCUMENTCONTENT_BY_ID
//   readonly mode = AsyncActionMode.ERROR
//   constructor(public request: GetDocumentContentRequestAction, public error: InvoiceDocumentError) {
//   }
// }

export class GetInvoiceDocumentByInvoiceRequestAction {
  readonly type = ActionType.GET_INVOICEDOCUMENT_BY_INVOICE
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetInvoiceDocumentByInvoiceResponseAction {
  readonly type = ActionType.GET_INVOICEDOCUMENT_BY_INVOICE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetInvoiceDocumentByInvoiceRequestAction,
      public data: InvoiceDocumentDetails,
  ) {}
}
export class GetInvoiceDocumentByInvoiceErrorAction {
  readonly type = ActionType.GET_INVOICEDOCUMENT_BY_INVOICE
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetInvoiceDocumentByInvoiceRequestAction,
      public error: InvoiceDocumentError,
  ) {}
}

export class DeleteInvoiceDocumentRequestAction {
  readonly type = ActionType.DELETE_INVOICEDOCUMENT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}
export class DeleteInvoiceDocumentResponseAction {
  readonly type = ActionType.DELETE_INVOICEDOCUMENT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: DeleteInvoiceDocumentRequestAction,
      public data: InvoiceDocumentDetails,
  ) {}
}
export class DeleteInvoiceDocumentErrorAction {
  readonly type = ActionType.DELETE_INVOICEDOCUMENT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: DeleteInvoiceDocumentRequestAction,
      public error: InvoiceDocumentError,
  ) {}
}
