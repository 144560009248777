import {Col, Row} from "antd";
import React from "react";
import moment from "moment";

const TodayDate = () => {
    return (
        <Row>
            <Col span={24}>
                <span className='todayDate' >
                    {moment().format('Do MMMM YYYY')} &nbsp; {moment().format('dddd').toUpperCase()}
                </span>
            </Col>
        </Row>
    )
}

export default TodayDate