import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {DeleteRoleErrorAction, DeleteRoleRequestAction, DeleteRoleResponseAction} from "./index";


export default (casbin_key: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | DeleteRoleRequestAction
                | DeleteRoleResponseAction
                | DeleteRoleErrorAction,
        ) => void,
    ) => {
        const request = new DeleteRoleRequestAction(casbin_key)
        dispatch(request)

        protectedApiClient
            .delete(`/role/${casbin_key}`)
            .then((response) => {
                dispatch(new DeleteRoleResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteRoleErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
