import {useTranslation} from "react-i18next";
import {Tag} from "antd";
import React from "react";


const ErrorPage403 = () => {
    const {t} = useTranslation()

    return (
        <div className="fullwidth-error" >
            <Tag color='red'>403 - Not Authorized</Tag>
        </div>
    )
}

export default ErrorPage403