import React, {useEffect, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './VpsSettingPage.scss'
import { AppState } from 'common/models'
import { useLoggedUser } from "../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../components"
import getVpsSettings from './actions/getVpsSettings'
import { useTranslation } from 'react-i18next'
import {
    DeleteTwoTone,
    EditTwoTone,
    InfoCircleOutlined,
    InfoCircleTwoTone,
    OneToOneOutlined,
    SettingOutlined
} from "@ant-design/icons"
import Button from "antd-button-color"
import PopConfirmZis from "../../components/popconfirm/PopConfirmZis"
import { AutoComplete, Badge, Card, Col, message, Modal, Popover, Row, Space, Table, Tag } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons/lib/icons"
import { VpsSettingModel, VpsHelper } from './models'
import { PAGING } from "../../common/enums"
import TotalNum from "../../components/TotalNum/TotalNum"
import VpsSettingForm from "./components/VpsSettingForm/VpsSettingForm"
import deleteVpsSetting from './actions/deleteVpsSetting'
import setDefaultVpsSetting from './actions/setDefaultVpsSetting'
import scanVpsSetting from './actions/scanVpsSetting'
import wipeDatabase from './actions/wipeDatabase'
import scanVpsSettings from './actions/scanVpsSettings'
import notification from 'antd/lib/notification'
import setDefaultScanning from './actions/setDefaultScanning'
import {sort_host} from "../../common/sorting";
import {stopPropagation} from "../../common/fce";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import HistoryLog from "../../components/History/HistoryLog";
import usePageSize from "../../common/usePageSize";
import getLogsScanning from "../../components/History/actions/getLogsScanning";


const VpsSettingsPage = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { vpsSettings, isLoading, resultScaning } = useSelector((state: AppState) => state.vpsadmin)

    const [messageApi, contextHolder] = message.useMessage();
    const [api, contextHolderNotification] = notification.useNotification();
    const key = 'updatable';

    const [isModalVisible, setModalVisible] = useState(false)
    const [searchName, setSearchName] = useState('')
    const [dataSource, setDataSource] = useState<VpsSettingModel[]>([])
    const [pageSize, setPageSize] = useState<string>()
    const [updateId, setUpdateId] = useState<number>(0)
    const [timePassed, setTimePassed] = useState(0)
    const [resultMessege, setResultMessege] = useState<string>('')
    const [logItem, setLogItem] = useState<string | undefined>('')
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')

    notification.config({
        duration: 30,
        maxCount: 1,
    }
    )
    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg) => {if (appSetting && appSetting.debug) {console.log('VpsSettingsPage: ' + msg)}}
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        // load settings from API
        // trigger ONCE
        dispatch(getVpsSettings())
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }
    }, [vpsSettings, searchName, isModalVisible])

    useEffect(() => {
        if (resultScaning !== '') {
            message.destroy()
            if (VpsHelper.getStatusScaning(resultScaning as string)) {
                api.success({
                    message: t('vpsadmin.setting.scanSuccess'),
                    placement: 'top',
                    onClose: () => {
                        notification.destroy()
                    }
                }
                );
            } else {
                api.error({
                    message: t('vpsadmin.setting.scanError'),
                    description: t('vpsadmin.setting.result') + ' ' + resultScaning as string,
                    placement: 'top',
                    onClose: () => {
                        notification.destroy()
                    }
                }
                );
            }
            setResultMessege('')
        }
    }, [resultScaning])

    useEffect(() => {
       // console.log(logItem)
    }, [logItem])

    const refreshGrid = () => {
        let pattern = ''
        if (searchName) {
            pattern = searchName.replace(/\s/g, '')
        }
        const newData = filtered(pattern).sort(sort_host)

        logger(`refreshGrid name: ${pattern}, settings: ${vpsSettings?.length}, dataSource: ${newData.length}`)
        setDataSource(newData)
    }

    const filtered = (name: string) => {
        if (!vpsSettings) {
            return []
        }

        if (name.length === 0) {
            // all records
            return vpsSettings
        }

        const qName = (h) => h.host.toLowerCase().includes(name.toLowerCase())
        return vpsSettings.filter((t) => qName(t))
    }

    const onDelete = (id: number | undefined) => {
        if (id) {
            dispatch(deleteVpsSetting(id!, (suc) => {
                suc && message.success(t('vpsadmin.setting.deleted'))
            }))
        }
    }

    const FilterByNameInput = (
        <AutoComplete
            placeholder={t('vpsadmin.setting.host')}
            style={{ width: '140px' }}
            value={searchName}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(currValue) => {
                setSearchName(currValue)
            }}
        />
    )

    const openMessage = () => {
        messageApi.open({
            key,
            type: 'loading',
            content: t('vpsadmin.setting.loadingProcces')
        });

    };

    const afterScaning = (msg) => {
        setResultMessege(msg)
    }

    const fetchHistory = (service: string, host: string, mid: number) => {
        setHistoryTitle(`${service} / ${host}`)
        dispatch(getLogsScanning(mid, 5))
    }

    const scanItem = (id) => {
        openMessage()
        dispatch(scanVpsSetting(id, () => {
            dispatch(getVpsSettings())
        }))
        if (!isLoading) {
            afterScaning(resultScaning)
        }
        dispatch(setDefaultScanning())
    }

    const columns = [
        {
            title: FilterByNameInput,
            dataIndex: 'host',
            key: 'host',
            width: '20%',
            sorter: sort_host
        },
        {
            title: t('vpsadmin.setting.username'),
            dataIndex: 'username',
            key: 'username',
            width: '100px'
        },
        {
            title: t('vpsadmin.setting.password'),
            dataIndex: 'password',
            key: 'password',
            width: '100px'
        },
        {
            title: t('vpsadmin.setting.state'),
            dataIndex: 'state',
            key: 'state',
            width: '5%',
            render: (state) => VpsHelper.convertedState(state),
        },
        {
            title: t('vpsadmin.setting.note'),
            dataIndex: 'note',
            key: 'note',
            width: '20%'
        },
        {
            title: t('vpsadmin.setting.onlineStatus'),
            dataIndex: 'result',
            key: 'result',
            className: 'center',
            width: '5%',
            render: (result) =>
                VpsHelper.getStatusScaning(result)
                    ?
                    <Tag color="success" >OK</Tag>
                    :
                    <Popover placement="top" content={result} trigger="hover">
                        <Tag color='error' style={{cursor: 'pointer'}}>Error</Tag>
                    </Popover>
        },
        {
            title: t('vpsadmin.setting.updated'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            width: '120px',
            render: (text) => text && appSetting.renderDateTime(text)
        },
        {
            title: 'Action',
            key: 'action',
            width: '100px',
            dataIndex: 'action',
            render: (_, record: VpsSettingModel) => (
                <Space size='small'>
                    <Button
                        key={record.id}
                        type="primary"
                        size='small'
                        onClick={() => {
                            record.id &&
                                scanItem(record.id)
                        }}
                    >
                        {t('vpsadmin.setting.scan')}
                    </Button>
                    <Button type='text'
                        size='small'
                        onClick={() => {
                            record.id && setUpdateId(record.id)
                            setModalVisible(true)
                        }}
                        icon={<EditTwoTone twoToneColor='green' />}
                    />
                    <PopConfirmZis onConfirm={() => onDelete(record.id)}>
                        <Button type='text'
                            danger
                            size='small'
                            icon={<DeleteTwoTone twoToneColor='red' />}
                        />
                    </PopConfirmZis>

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                if (record && record.id) {
                                    fetchHistory('vpsadmin', record.host, record.id)
                                    setHistoryModalVisible(true)
                                }
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                    <span>&nbsp;</span>
                </Space>
            ),
        },
    ]


    return (
        <>
            {contextHolder}
            <div key='process loading'></div>
            <div key='notification after scaning'>{contextHolderNotification}</div>

            <Card className='VpsAdminPage'
                title={
                    <>
                        <Row>
                            <Col span={15}>
                                <SettingOutlined />{` ${t('vpsadmin.setting.title')}`}
                            </Col>
                            <Col span={9}>
                                <PopConfirmZis onConfirm={() => {
                                    dispatch(wipeDatabase(() => {
                                        dispatch(getVpsSettings())
                                    }))
                                }}>
                                    <Button type='danger' size='small' disabled={true}>
                                        {t('vpsadmin.setting.wipedb')}
                                    </Button>
                                </PopConfirmZis>


                                <PopConfirmZis onConfirm={() => {
                                    dispatch(scanVpsSettings(() => {
                                        dispatch(getVpsSettings())
                                    }))
                                }}>
                                    <Button type='primary' size='small' disabled={true} >
                                        {t('vpsadmin.setting.scanall')}
                                    </Button>
                                </PopConfirmZis>
                            </Col>
                        </Row>

                    </>
                }

                extra={
                    <Button type='primary' onClick={() => {
                        setUpdateId(0)
                        setModalVisible(true)
                    }}>
                        <PlusCircleOutlined /> {t('vpsadmin.setting.addServer')}
                    </Button>
                }
            >
                {
                    appSetting &&
                    <Table<VpsSettingModel>
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columns}
                        loading={isLoading}
                        dataSource={dataSource}
                        className='SettingsTable'
                        rowKey='id'
                        pagination={{
                            defaultPageSize: appSetting?.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => {
                            setPageSize(`${ev.pageSize}`)
                        }}
                        footer={() => TotalNum(Number(dataSource?.length), 'Settings', dataSource)}
                    />
                }
            </Card>
            <Modal key='Change'
                destroyOnClose
                style={{ top: 20 }}
                width={800}
                title={
                    <>
                        {updateId > 0 ? t('companiesPage.update-title') : t('companiesPage.create-title')}
                    </>
                }
                visible={isModalVisible}
                onCancel={() => {
                    setUpdateId(0)
                    dispatch(setDefaultVpsSetting())
                    setModalVisible(false)
                }}
                footer={null}
                maskClosable={true}
                confirmLoading={true}>
                {<VpsSettingForm updateId={updateId} setModalVisible={setModalVisible} />}
            </Modal>

            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                     onMouseOut={() => {setDisabled(true)}}
                     onFocus={() => {}}
                     onBlur={() => {}}
                >
                    <><InfoCircleOutlined />&nbsp;{t('general.history')}: {historyTitle}</>
                </div>
            }
                   destroyOnClose
                   style={{top: 50}}
                   bodyStyle={{height: '60%', minHeight: 450, padding: 2}}
                   width='60%'
                   className='historyLogModal'
                   visible={isHistoryModalVisible}
                   onCancel={() => setHistoryModalVisible(false)}
                   maskClosable={false}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   footer={null}>

                <HistoryLog isModal={true} showTitle={false} />
            </Modal>

        </>
    )
}

export default VpsSettingsPage
