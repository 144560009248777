import {
    SendWelcomeEmailRequestAction,
    SendWelcomeEmailResponseAction,
    SendWelcomeEmailErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { SendWElcomeEmail } from '../models'

export default (
    params: SendWElcomeEmail,
    cb?: (isSuccess: boolean) => void
) => {
    return (
        dispatch: (
            arg:
                | SendWelcomeEmailRequestAction
                | SendWelcomeEmailResponseAction
                | SendWelcomeEmailErrorAction,
        ) => void,
    ) => {
        const request = new SendWelcomeEmailRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<SendWElcomeEmail>(`/user/${params.user_id}/send-welcome-mail`, params )
            .then((response) => {
                dispatch(new SendWelcomeEmailResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new SendWelcomeEmailErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
