import {
    CreateBillAttachmentRequestAction,
    CreateBillAttachmentResponseAction,
    CreateBillAttachmentErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    CreateBillAttachmentParams,
    BillAttachmentDetails,
} from '../models'

export default (
    params: CreateBillAttachmentParams,
    cb?: (id: number, isSuccess: boolean) => void,
    onProgress?: (ProgressEvent: any) => void,
) => {
    return (
        dispatch: (
            arg:
                | CreateBillAttachmentRequestAction
                | CreateBillAttachmentResponseAction
                | CreateBillAttachmentErrorAction,
        ) => void,
    ) => {
        const request = new CreateBillAttachmentRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<BillAttachmentDetails>(
                `/billing/bill/${params.bill_id}/attachment`, params,   { onUploadProgress: onProgress },
            )
            .then((response) => {
                dispatch(
                    new CreateBillAttachmentResponseAction(request, response.data),
                )
                cb && cb(response.data.id, true)
            })
            .catch((error) => {
                dispatch(new CreateBillAttachmentErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(0, false)
            })
    }
}
