import {
    AddResGroupInGroupRequestAction,
    AddResGroupInGroupErrorAction,
    AddResGroupInGroupResponseAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {AddDeleteResGroupToGroupParams, UserGroupModel} from '../models'


export default (
    params: AddDeleteResGroupToGroupParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | AddResGroupInGroupRequestAction
                | AddResGroupInGroupErrorAction
                | AddResGroupInGroupResponseAction,
        ) => void,
    ) => {
        const request = new AddResGroupInGroupRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<UserGroupModel>(`/user-group/${params.id}/add-resource-group`, params)
            .then((response) => {
                dispatch(new AddResGroupInGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new AddResGroupInGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
