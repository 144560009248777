

export enum PROTOCOL_ENUM {
	VHOST_HTTP_1 = 'http/1',
	VHOST_HTTP_2 = 'http/2',
	VHOST_HTTP_3 = 'http/3',
}

export enum WEBSERVER_ENUM {
	APACHE_2_2 = 'apache2-2',
	APACHE_2_4_PREFORK = 'apache2-4-prefork',
	APACHE_2_4_EVENT = 'apache2-4-event',
	NGINX_1 = 'nginx1',
}

export enum BACKEND_ENUM {
	STATIC = 'static',
	PHP_PREFORK = 'php_prefork',
	PHP_FPM_5_6 = 'php_fpm_5-6',
	PHP_FPM_7_0 = 'php_fpm_7-0',
	PHP_FPM_7_2 = 'php_fpm_7-2',
	PHP_FPM_7_3 = 'php_fpm_7-3',
	PHP_FPM_7_4 = 'php_fpm_7-4',
	PHP_FPM_8_0 = 'php_fpm_8-0',
	RUBY_PUMA = 'ruby_puma',
}

export enum VALIDATE_STATUS_ENUM {
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error',
	VALIDATION = 'validating',
}

export enum FILTER_PERIOD_ENUM {
	'ACT_HALF',
	'ACT_MONTH',
	'ACT_QUARTER',
	'ACT_YEAR',
	'FIRST_HALF_AGO',
	'PREV_MONTH',
	'PREV_QUARTER',
	'PREV_HALF',
	'PREV_YEAR',
	'SECOND_HALF_AGO',
	'TWO_MONTH_AGO',
	'TWO_QUARTER_AGO',
	'TWO_YEAR_AGO',
	'THIRD_HALF_AGO',
	'THREE_MONTH_AGO',
	'THREE_QUARTER_AGO',
	'THREE_YEAR_AGO',
	'MONTH_BEFORE_YEAR',
	'QUARTER_BEFORE_YEAR',
	'ALL_TIME'
}

export const FILTER_PERIOD_BASIC = [
	'THREE_MONTH_AGO', 'TWO_MONTH_AGO', 'PREV_MONTH', 'ACT_MONTH',
	'THREE_QUARTER_AGO', 'TWO_QUARTER_AGO', 'PREV_QUARTER', 'ACT_QUARTER',
	'FIRST_HALF_AGO', 'ACT_HALF',
	'PREV_YEAR', 'ACT_YEAR',
	'ALL_TIME'
]

// obsolate
export const FILTER_PERIOD_NAMES = [
	'ACT_HALF',
	'ACT_MONTH',
	'ACT_QUARTER',
	'ACT_YEAR',
	'FIRST_HALF_AGO',
	'PREV_MONTH',
	'PREV_QUARTER',
	'PREV_YEAR',
	'SECOND_HALF_AGO',
	'TWO_MONTH_AGO',
	'TWO_QUARTER_AGO',
	'TWO_YEAR_AGO',
	'THIRD_HALF_AGO',
	'THREE_MONTH_AGO',
	'THREE_QUARTER_AGO',
	'THREE_YEAR_AGO',
	'MONTH_BEFORE_YEAR',
	'QUARTER_BEFORE_YEAR',
	'ALL_TIME'
]

export const CZECH_VAT_ENUM = Object.freeze({
	21: '21%',
	15: '15%',
	5: '5%',
	0: '0%',
})

export const PAGING = ['10', '25', '100', '500', '1000', '10000']

export enum UNIT_ENUM {
	MB = 'MB',
	GB = 'GB',
	TB = 'TB',
	MHZ = 'MHz',
	KS = 'KS',
}

export enum MODUL_ENUM {
	MODUL_BACKEND = 'zis3-backend',
	MODUL_BILLING = 'service-billing',
	MODUL_COMPANY = 'service-company',
	MODUL_INVENTORY = 'service-inventory',
	MODUL_DATACENTER = 'service-datacenter',
	MODUL_VPSADMIN = 'service-vpsadmin',
	MODUL_IP = 'service-ip',
	MODUL_DNS = 'service-dns',
	MODUL_EMERGENCY = 'service-emergency',
	MODUL_SSL = 'service-certificate',
	MODUL_ASYNC = 'service-async',
	MODUL_DATABASE = 'service-db',
	MODUL_WEB = 'service-web',
	MODUL_LOGIC_SERVER = 'service-server',
	MODUL_MAIL = 'service-mail'
}

export type ModulName = 'zis3-backend'
	|'service-billing'
	|'service-company'
	|'service-inventory'
	|'service-datacenter'
	|'service-vpsadmin'
	|'service-ip'
	|'service-dns'
	|'service-emergency'
	|'service-certificate'
	|'service-async'
	|'service-db'
	|'service-web'
	|'service-mail'
	|'service-server'


export const getShortModulName = (fn: string) => {
	if (!fn) return ''
	let sn = fn.toLowerCase()
	if (sn.includes('zis3-')) {
		sn = sn.toLowerCase().replace('zis3-', '')
	}
	if (sn.includes('zis-')) {
		sn = sn.toLowerCase().replace('zis-', '')
	}
	if (sn.includes('service-')) {
		sn = sn.toLowerCase().replace('service-', '')
	}
	return sn
}

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
	return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export const getAllModulNames = (): string[] => {
	const result:string[] = []
	for (const value of enumKeys(MODUL_ENUM)) {
		result.push(MODUL_ENUM[value]);
	}
	return result
}

const getModulColors = () => {
	let colors = {}
	colors[MODUL_ENUM.MODUL_BACKEND] = 'geekblue'
	colors[MODUL_ENUM.MODUL_BILLING] = 'orange'
	colors[MODUL_ENUM.MODUL_COMPANY] = 'blue'
	colors[MODUL_ENUM.MODUL_INVENTORY] = 'gold'
	colors[MODUL_ENUM.MODUL_DATACENTER] = 'lime'
	colors[MODUL_ENUM.MODUL_IP] = 'cyan'
	colors[MODUL_ENUM.MODUL_DNS] = 'purple'
	colors[MODUL_ENUM.MODUL_LOGIC_SERVER] = 'green'
	colors[MODUL_ENUM.MODUL_SSL] = 'red'
	colors[MODUL_ENUM.MODUL_ASYNC] = '#e6b6f6'
	colors[MODUL_ENUM.MODUL_MAIL] = '#aec7fa'
	colors[MODUL_ENUM.MODUL_DATABASE] = '#f5c038'
	colors[MODUL_ENUM.MODUL_WEB] = '#c3f364'
	colors[MODUL_ENUM.MODUL_VPSADMIN] = '#358337'
	colors[MODUL_ENUM.MODUL_EMERGENCY] = '#4a4b4d'
	return colors
}

export const getModulColor = (name): string => {
	let colors = getModulColors()
	return colors[name]
}

export const getModulColorByShort = (shortName: string): string => {
	shortName = '-' + shortName
	const names = getAllModulNames()
	for (const longName of names) {
		if (longName.includes(shortName)) {
			return getModulColor(longName)
		}
	}
	return '#880000'
}




export enum LOG_LEVEL_ENUM {
	EMERGENCY = 'emergency',
	ALERT     = 'alert',
	CRITICAL  = 'critical',
	ERROR     = 'error',
	WARNING   = 'warning',
	NOTICE    = 'notice',
	INFO      = 'info',
	DEBUG     = 'debug'
}

export const MAX_LEVEL = 7
export const getLogLevelOptions = (): {label: string, value: number}[] => {
	let options: {label: string, value: number}[] = []
	options.push({label: LOG_LEVEL_ENUM.EMERGENCY, value: 0})
	options.push({label: LOG_LEVEL_ENUM.ALERT, value: 1})
	options.push({label: LOG_LEVEL_ENUM.CRITICAL, value: 2})
	options.push({label: LOG_LEVEL_ENUM.ERROR, value: 3})
	options.push({label: LOG_LEVEL_ENUM.WARNING, value: 4})
	options.push({label: LOG_LEVEL_ENUM.NOTICE, value: 5})
	options.push({label: LOG_LEVEL_ENUM.INFO, value: 6})
	options.push({label: LOG_LEVEL_ENUM.DEBUG, value: 7})
	return options
}

export const getLogLevelById = (levelId: number): string => {
	switch (levelId) {
		case 0: return LOG_LEVEL_ENUM.EMERGENCY.toLowerCase()
		case 1: return LOG_LEVEL_ENUM.ALERT.toLowerCase()
		case 2: return LOG_LEVEL_ENUM.CRITICAL.toLowerCase()
		case 3: return LOG_LEVEL_ENUM.ERROR.toLowerCase()
		case 4: return LOG_LEVEL_ENUM.WARNING.toLowerCase()
		case 5: return LOG_LEVEL_ENUM.NOTICE.toLowerCase()
		case 6: return LOG_LEVEL_ENUM.INFO.toLowerCase()
		case 7: return LOG_LEVEL_ENUM.DEBUG.toLowerCase()
	}
	return '?'
}

export const getLogColorById = (levelId: number): string => {
	switch (levelId) {
		case 0: return '#c00404'
		case 1: return '#c00404'
		case 2: return '#c00404'
		case 3: return '#880000'
		case 4: return '#b25b04'
		case 5: return '#0e5012'
		case 6: return '#1c1c1c'
		case 7: return '#646464'
	}
	return 'black'
}

export const MIN_ZIS_LEVEL = 97
export const MAX_ZIS_LEVEL = 500
export enum ZIS_LEVEL_ENUM {
	EMERGENCY = 'emergency',
	ALERT     = 'alert',
	CRITICAL  = 'critical',
	ERROR     = 'error',
	WARNING   = 'warning',
	NOTICE    = 'notice',
	INFO      = 'info',
	DEBUG     = 'debug',
	DEBUG2     = 'debug2',
	DEBUG3     = 'debug3'
}

export const getZisLevels = (): {} => {
	let data = {}
	data[ZIS_LEVEL_ENUM.EMERGENCY] = 600
	data[ZIS_LEVEL_ENUM.ALERT] = 550
	data[ZIS_LEVEL_ENUM.CRITICAL] = 500
	data[ZIS_LEVEL_ENUM.ERROR] = 400
	data[ZIS_LEVEL_ENUM.WARNING] = 300
	data[ZIS_LEVEL_ENUM.NOTICE] = 250
	data[ZIS_LEVEL_ENUM.INFO] = 200
	data[ZIS_LEVEL_ENUM.DEBUG] = 100
	data[ZIS_LEVEL_ENUM.DEBUG2] = 98
	data[ZIS_LEVEL_ENUM.DEBUG3] = 97
	return data
}

export const getZisLevelOptions = (): {label: string, value: number}[] => {
	const levels = getZisLevels()
	let options: {label: string, value: number}[] = []
	options.push({label: ZIS_LEVEL_ENUM.EMERGENCY, value: levels[ZIS_LEVEL_ENUM.EMERGENCY]})
	options.push({label: ZIS_LEVEL_ENUM.ALERT, value: levels[ZIS_LEVEL_ENUM.ALERT]})
	options.push({label: ZIS_LEVEL_ENUM.CRITICAL, value: levels[ZIS_LEVEL_ENUM.CRITICAL]})
	options.push({label: ZIS_LEVEL_ENUM.ERROR, value: levels[ZIS_LEVEL_ENUM.ERROR]})
	options.push({label: ZIS_LEVEL_ENUM.WARNING, value: levels[ZIS_LEVEL_ENUM.WARNING]})
	options.push({label: ZIS_LEVEL_ENUM.NOTICE, value: levels[ZIS_LEVEL_ENUM.NOTICE]})
	options.push({label: ZIS_LEVEL_ENUM.INFO, value: levels[ZIS_LEVEL_ENUM.INFO]})
	options.push({label: ZIS_LEVEL_ENUM.DEBUG, value: levels[ZIS_LEVEL_ENUM.DEBUG]})
	options.push({label: ZIS_LEVEL_ENUM.DEBUG2, value: levels[ZIS_LEVEL_ENUM.DEBUG2]})
	options.push({label: ZIS_LEVEL_ENUM.DEBUG3, value: levels[ZIS_LEVEL_ENUM.DEBUG3]})
	return options
}

export const getZisLevelValue = (level: ZIS_LEVEL_ENUM): number => {
	const levels = getZisLevels()
	return levels[level]
}

export const getZisLevelById = (levelId: number): string => {
	const levels = getZisLevels()
	if (levelId) {
		switch (levelId) {
			case levels[ZIS_LEVEL_ENUM.EMERGENCY]: return ZIS_LEVEL_ENUM.EMERGENCY.toLowerCase()
			case levels[ZIS_LEVEL_ENUM.ALERT]: return ZIS_LEVEL_ENUM.ALERT.toLowerCase()
			case levels[ZIS_LEVEL_ENUM.CRITICAL]: return ZIS_LEVEL_ENUM.CRITICAL.toLowerCase()
			case levels[ZIS_LEVEL_ENUM.ERROR]: return ZIS_LEVEL_ENUM.ERROR.toLowerCase()
			case levels[ZIS_LEVEL_ENUM.WARNING]: return ZIS_LEVEL_ENUM.WARNING.toLowerCase()
			case levels[ZIS_LEVEL_ENUM.NOTICE]: return ZIS_LEVEL_ENUM.NOTICE.toLowerCase()
			case levels[ZIS_LEVEL_ENUM.INFO]: return ZIS_LEVEL_ENUM.INFO.toLowerCase()
			case levels[ZIS_LEVEL_ENUM.DEBUG]: return ZIS_LEVEL_ENUM.DEBUG.toLowerCase()
			case levels[ZIS_LEVEL_ENUM.DEBUG2]: return ZIS_LEVEL_ENUM.DEBUG2.toLowerCase()
			case levels[ZIS_LEVEL_ENUM.DEBUG3]: return ZIS_LEVEL_ENUM.DEBUG3.toLowerCase()
		}
	}
	else {
		return 'null'
	}
	return `${levelId}`
}

export const getZisColorById = (levelId: number): string => {
	const levels = getZisLevels()
	switch (levelId) {
		case levels[ZIS_LEVEL_ENUM.EMERGENCY]: return '#c00404'
		case levels[ZIS_LEVEL_ENUM.ALERT]: return '#c00404'
		case levels[ZIS_LEVEL_ENUM.CRITICAL]: return '#c00404'
		case levels[ZIS_LEVEL_ENUM.ERROR]: return '#880000'
		case levels[ZIS_LEVEL_ENUM.WARNING]: return '#b25b04'
		case levels[ZIS_LEVEL_ENUM.NOTICE]: return '#0e5012'
		case levels[ZIS_LEVEL_ENUM.INFO]: return '#1c1c1c'
		case levels[ZIS_LEVEL_ENUM.DEBUG]: return '#646464'
		case levels[ZIS_LEVEL_ENUM.DEBUG2]: return '#646464'
		case levels[ZIS_LEVEL_ENUM.DEBUG3]: return '#646464'
	}
	return 'black'
}

export enum HTTP_METHOD_ENUM {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
	DELETE = 'delete',
	OPTIONS = 'options'
}


export const getHttpMethodOptions = (): {label: string, value: string}[] => {
	let options: {label: string, value: string}[] = []
	options.push({label: HTTP_METHOD_ENUM.GET.toUpperCase(), value: HTTP_METHOD_ENUM.GET.toLowerCase()})
	options.push({label: HTTP_METHOD_ENUM.POST.toUpperCase(), value: HTTP_METHOD_ENUM.POST.toLowerCase()})
	options.push({label: HTTP_METHOD_ENUM.PUT.toUpperCase(), value: HTTP_METHOD_ENUM.PUT.toLowerCase()})
	options.push({label: HTTP_METHOD_ENUM.DELETE.toUpperCase(), value: HTTP_METHOD_ENUM.DELETE.toLowerCase()})
	options.push({label: HTTP_METHOD_ENUM.OPTIONS.toUpperCase(), value: HTTP_METHOD_ENUM.OPTIONS.toLowerCase()})
	return options
}

export const INTENT_START = 'INTENT_START'
export const INTENT_END = 'INTENT_END'
export const INTENT_HISTORY = 'HISTORY'

export const AUTH_SMS = 1
export const AUTH_GA = 2