import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {GetAllEndpointsErrorAction, GetAllEndpointsRequestAction, GetAllEndpointsResponseAction} from "./index";
import {EndPointModel} from "../models";


export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetAllEndpointsRequestAction
                | GetAllEndpointsResponseAction
                | GetAllEndpointsErrorAction,
        ) => void,
    ) => {
        const request = new GetAllEndpointsRequestAction()
        dispatch(request)

        protectedApiClient
            .get<EndPointModel[]>('/endpoint')
            .then((response) => {
                dispatch(new GetAllEndpointsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetAllEndpointsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
