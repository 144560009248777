import { AsyncActionMode } from "../../common/models";
import { ActionType, HypervisorActions, VpsSettingActions, HistoryActions, ChangesActions } from "./actions";
import { HypervisorPageState } from "./models";


const HYPERVISOR_PAGE_INITIAL_STATE: HypervisorPageState = {
    isLoading: false,
    isSaving: false,
    hypervisors: [],
    vpsServers: [],
    containerChanges: [],
    hypervisorHystories: [],
    vpsSettings: [],
    resultScaning: '',
    logsSacanning: [],
    error: undefined
}

export default (
    state = HYPERVISOR_PAGE_INITIAL_STATE, action: HypervisorActions | VpsSettingActions | HistoryActions | ChangesActions): HypervisorPageState => {
    switch (action.type) {


        case ActionType.GET_CHANGES_BY_ID:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoading: false,
                    containerChanges: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_HISTORIES_BY_HOST_NAME:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoading: false,
                    hypervisorHystories: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break
        case ActionType.GET_HYPERVISOR_BY_HOST:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state,
                    hypervisor: undefined,
                    isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    hypervisor: action.data[0],
                    isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break
        case ActionType.GET_HYPERVISOR:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state,
                    hypervisor: undefined,
                    vpsServer: undefined,
                    isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    hypervisor: action.data,
                    isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_VPS_SERVER:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    vpsServer: action.data,
                    isLoadingVps: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_HYPERVISORS:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state,
                    hypervisor: undefined,
                    vpsServer: undefined,
                    isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoading: false,
                    hypervisors: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_VPS_SERVERS:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state,
                    hypervisor: undefined,
                    vpsServer: undefined,
                    isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoading: false,
                    vpsServers: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_VPSADMIN_SETTINGS:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoading: false,
                    vpsSettings: action.data
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error
                }
            }
            break

        case ActionType.CREATE_VPSADMIN_SETTING:
            if (action.mode === AsyncActionMode.REQUEST) {
                return {
                    ...state,
                    isLoading: true,
                    isSaving: true
                }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoading: false,
                    vpsSettings: state.vpsSettings?.concat(action.data),
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    isSaving: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_VPSADMIN_SETTING:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoading: false,
                    vpsSetting: action.data
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error
                }
            }
            break

        case ActionType.UPDATE_VPSADMIN_SETTING:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    vpsSettings: state.vpsSettings!.map((vpsSetting) =>
                        vpsSetting.id === action.data.id ? { ...action.data } : vpsSetting,
                    ),
                    isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error
                }
            }
            break

        case ActionType.DELETE_VPSADMIN_SETTING:

            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    vpsSettings: state.vpsSettings!.filter(
                        (setting) => setting.id !== action.request.id,
                    ),
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break

        case ActionType.SCAN_VPSADMIN_SETTING:

            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state, isLoading: false, resultScaning: action.data
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break

        case ActionType.SCAN_VPSADMIN_SETTINGS:

            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state, isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break

        case ActionType.WIPE_DATABASE:

            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state, isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break

        case ActionType.SET_DEFAULT_VPSADMIN_SETTING:
            return {
                ...state,
                isLoading: false,
                vpsSetting: undefined
            }
            break

        case ActionType.SET_DEFAULT_HYPERVISOR:
            return {
                ...state,
                isLoading: false,
                vpsServer: undefined,
                hypervisor: undefined
            }
            break

        case ActionType.SET_DEFAULT_SCANNING:
            return {
                ...state,
                resultScaning: '',
            }
            break
    }

    return state
}


