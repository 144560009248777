import React, {useEffect, useState} from 'react'
import {Button, Form, Input, message} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import createRole from "./actions/createRole";
import {RoleModel} from "./models";
import TextArea from "antd/lib/input/TextArea";


interface Props {
    setModalVisible: (param: boolean) => void
}

const {Item} = Form
const REG_CASBIN = new RegExp('^[a-z]{2,12}-[a-z-]{3,24}$')

const NewRoleForm = ({setModalVisible}: Props) => {

    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {isSaving} = useSelector((state: AppState) => state.rolesPage)
    const [isValid, setValid] = useState<boolean>(false)
    const [casbinKey, setCasbinKey] = useState<string>('')

    useEffect(() => {
        // validate casbin_key
        if (casbinKey) {
            setValid(REG_CASBIN.test(casbinKey))
        }
    }, [casbinKey])

    const onFormSubmit = (values: Store) => {
        // save data
        if (isRoleValid()) {
            const arr = casbinKey.trim().split('-')
            for (let i=0; i<arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
            }
            const name = arr.join(' ')
            const role: RoleModel = {
                name: name,
                description: values.description,
                casbin_key: casbinKey,
                key: casbinKey}
            dispatch(createRole(role, (isSuccess: boolean) => {
                    if (isSuccess) {
                        setModalVisible(false)
                        message.success(t('general.success'))
                    }
                }
            ))
        }
    }

    const isRoleValid = () => {
        // "billing-viewer"
        if (!casbinKey) {
            return false
        }

        return REG_CASBIN.test(casbinKey)
    }

    const handleCasbinKeyPressed = (e) => {
        if (e.target.value && e.target.value.toString().includes('-')) {
            const arr = e.target.value.split('-')
            if (arr.length === 2) {
                if ((e.which < 97 || e.which > 122) && e.which != 45) {
                    e.preventDefault();
                }
            }
            else {
                if ((e.which < 97 || e.which > 122)) {
                    e.preventDefault();
                }
            }
        }
        else {
            if ((e.which < 97 || e.which > 122) && e.which != 45) {
                e.preventDefault();
            }
        }
    }

    return (
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
            initialValues={{}}
        >
            <Item name='casbin_key' label={t('rights.roles.casbin_key')}
                  rules={[
                      {required: true, message: t('errors.field_required')},
                      {pattern: REG_CASBIN, message: 'casbin_key has not valid format.'}
                  ]}
                  hasFeedback
            >
                <Input placeholder='service-action' autoFocus
                       onKeyPress={handleCasbinKeyPressed}
                       onChange={(e) => setCasbinKey(e.target.value)} />
            </Item>

            <Item name='description' label={t('rights.roles.description')}>
                <TextArea data-gramm="false"
                          data-gramm_editor="false"
                          data-enable-grammarly="false" />
            </Item>

            <Item {...tailLayout}>
                <Button loading={isSaving} type='primary' htmlType='submit' disabled={!isValid}>
                    { t('general.create')}
                </Button>
            </Item>

        </Form>
    )
}

export default NewRoleForm
