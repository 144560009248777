import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import {LogRecord} from "./models";
import {AppState} from "../../common/models";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../components";
import updateSetting from "../login/actions/updateSetting";
import {
    Card,
    Checkbox,
    Col,
    Input,
    InputNumber,
    Popover,
    Row,
    Select,
    Space, Switch,
    Table,
    Tag
} from "antd";
import Button from "antd-button-color";
import {
    FunnelPlotOutlined,
    InfoCircleOutlined,
    UpSquareOutlined,
    ZoomInOutlined
} from "@ant-design/icons";
import {
    getAllModulNames, getHttpMethodOptions,
    getLogColorById,
    getLogLevelOptions,
    getModulColor,
    getModulColorByShort,
    getShortModulName,
    getZisColorById,
    getZisLevelById,
    getZisLevelOptions,
    getZisLevelValue,
    MAX_LEVEL,
    MAX_ZIS_LEVEL,
    MIN_ZIS_LEVEL,
    PAGING, ZIS_LEVEL_ENUM
} from "../../common/enums";
import TotalNum from "../../components/TotalNum/TotalNum";
import {UserDetails} from "../user/models";
import Modal from "antd/lib/modal/Modal";
import LogForm from "./LogForm";
import "./LogViewer.scss"
import getLogRecord from "./actions/getLogRecord";
import getLogRecords from "./actions/getLogRecords";
import {ColumnsType} from "antd/lib/table";
import {_isNotEmptyObject, removeDiac, stopPropagation} from "../../common/fce";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import CheckableTag from "antd/lib/tag/CheckableTag";
import ErrorPage403 from "../../components/Errors/ErrorPage403";
import queryString, {ParsedQuery} from "query-string";
import usePageSize from "../../common/usePageSize";


const RECORD_LIMIT = 1000
const DEFAULT_ZIS_LEVEL = 100

const LogPage = (props) => {
    const CONTROL_NAME = 'page_logviewer'
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {record, records, requests, isLoading} = useSelector((state: AppState) => state.logs)
    const {users} = useSelector((state: AppState) => state.user)

    const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(props.location.search))
    const [dataSource, setDataSource] = useState<LogRecord[]>([])
    const [groupSource, setGroupSource] = useState<LogRecord[]>([])
    const [logUsers, setLogUsers] = useState<UserDetails[]>([])
    const [logServices, setLogServices] = useState<string[]>([])
    const [logLevels, setLogLevels] = useState<{ label: string, value: number }[]>()
    const [logId, setLogId] = useState<string>('')
    const [groupedView, setGroupedView] = useState(false)
    const [groupedView2, setGroupedView2] = useState(false)
    const [groupedViewLoading, setGroupedViewLoading] = useState(false)

    const [userOptions, setUserOptions] = useState<{ label: string, value: number }[]>([])
    const [serviceOptions, setServiceOptions] = useState<{ label: string, value: string }[]>([])
    const [levelOptions, setLevelOptions] = useState<{ label: string, value: string }[]>([])
    const [methodOptions, setMethodOptions] = useState<{ label: string, value: string }[]>([])
    const [lowestLevel, setLowestLevel] = useState<number>(DEFAULT_ZIS_LEVEL)

    const [selectedLevelOptions, setSelectedLevelOptions] = useState<string[]>([])
    const [usedLevelOptions, setUsedLevelOptions] = useState<{ label: string, value: string }[]>([])
    const [hiddenLevelOptions, setHiddenLevelOptions] = useState<string[]>([])

    const [selectedServiceOptions, setSelectedServiceOptions] = useState<string[]>([])
    const [usedServiceOptions, setUsedServiceOptions] = useState<string[]>([])
    const [hiddenServiceOptions, setHiddenServiceOptions] = useState<string[]>([])
    const [selectedMethodOptions, setSelectedMethodOptions] = useState<string[]>([])

    const [selectedDuration, setSelectedDuration] = useState<number>(2)
    const [useFrom, setUseFrom] = useState<Boolean>(false)
    const [startDate, setStartDate] = useState(new Date());

    const [searchUserId, setSearchUserId] = useState<string | undefined>(undefined)
    const [searchMessage, setSearchMessage] = useState<string>('')
    const [searchMessage2, setSearchMessage2] = useState<string>('')
    const [searchIP, setSearchIP] = useState<string>('')
    const [searchIP2, setSearchIP2] = useState<string>('')

    const [isModalVisible, setModalVisible] = useState(false)
    const [pageSize, setPageSize] = useState<string>()


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }
    const appSetting = loggedUser.getAppSettings()
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    const loadLevels = () => {
        return getZisLevelOptions().filter(opt => opt.value < 550 && opt.value > 97)
    }

    // logger
    const logger = (msg) => { if (appSetting && appSetting.debug) {console.log('logViewer: ' + msg)} }

    useEffect(() => {
        let qs = ''
        if (parsed && _isNotEmptyObject(parsed)) {
            // QS params
            const qs_name: string | string[] | null = parsed['service_name[]']
            if (qs_name && typeof qs_name === 'string') {
                qs = 'service_name[]=' + qs_name + '&'
                setSelectedServiceOptions([qs_name])
            }
        }
        qs = qs + `last_hours=2&level=${DEFAULT_ZIS_LEVEL}&limit=${appSetting.logviewer_limit}`   // default call
        logger('qs: '+qs)
        dispatch(getLogRecords(qs, suc => {}))
        setLogLevels(loadLevels())
        setLogServices(getAllModulNames())
        setMethodOptions(getHttpMethodOptions())
        setSelectedMethodOptions(getHttpMethodOptions().filter(opt => opt.value != 'options'). map(u => u.value))
    }, [])

    useEffect(() => {
        if (records) {
            const ids: number[] = Array.from(new Set(records.map(r => r.zis_user_id && r.zis_user_id)))
            setLogUsers(users.filter(u => ids.includes(u.id)))

            setLogLevels(loadLevels())
            const uniqLevels: { label: string, value: string }[] = Array.from(new Set(records.map(r => r.zis_level ? r.zis_level : getZisLevelValue(ZIS_LEVEL_ENUM.DEBUG2)))).map(i => {return {label: getZisLevelById(i), value: i.toString()}})
            setUsedLevelOptions(uniqLevels)
            setHiddenLevelOptions([])

            const uniqServices: string[] = Array.from(new Set(records.map(r => r.service_name && r.service_name)))
            setUsedServiceOptions(uniqServices)
            setHiddenServiceOptions([])
            refreshGrid()
        }
    }, [records])


    useEffect(() => {
        if (logUsers) {
            setUserOptions(logUsers.map(u => {return {label: u.title, value: u.id}}))
        }
    }, [logUsers])

    useEffect(() => {
        if (logServices) {
            setServiceOptions(logServices.map(s => {return {label: s, value: s}}))
        }
    }, [logServices])

    useEffect(() => {
        if (logLevels) {
            setLevelOptions(logLevels.map(l => { return {label: l.label.toUpperCase(), value: `${l.value}`} } ))
        }
    }, [logLevels])

    useEffect(() => {
        refreshGrid()
    }, [groupedView, pageSize, hiddenServiceOptions, selectedMethodOptions, hiddenLevelOptions,
        searchUserId, searchMessage2, searchIP2, useFrom && startDate])

    useEffect(() => {
        setGroupedViewLoading(true)
        setTimeout(() => setGroupedView(groupedView2), 100);
    }, [groupedView2])

    useEffect(() => {
        if (logId) {
            // load log record
            // to be displayed in the form
            dispatch(getLogRecord(logId, suc => {}))
        }
    }, [logId])

    const clear = () => {
        logger('clear')
        setLogLevels(getLogLevelOptions())
        setLogServices(getAllModulNames())

        setUsedLevelOptions([])
        setHiddenLevelOptions([])

        setSelectedServiceOptions([])
        setUsedServiceOptions([])
        setHiddenServiceOptions([])
        setSearchMessage('')
        setSearchMessage2('')
        setSearchUserId(undefined)
        setDataSource([])
        setGroupSource([])
    }

    const search = () => {
        // fetch data from API
        const arr: string[] = []
        arr.push('last_hours=' + selectedDuration)
        let qs = ''

        if (useFrom) {
            let s = startDate.toISOString().split('.')[0]
            qs = 'date_to=' + s.replace('T', '%20')
            qs = qs.replace(':', '%3A')
            qs = qs.replace(':', '%3A')
            arr.push(qs)
        }
        if (selectedServiceOptions.length > 0) {
            for (const serviceName of selectedServiceOptions) {
                qs = 'service_name[]=' + serviceName
                logger(qs)
                arr.push(qs)
            }
        }

        if (lowestLevel > MIN_ZIS_LEVEL) {
            qs = 'level=' + lowestLevel
            arr.push(qs)
        }
        if (searchUserId && parseInt(searchUserId) > 0) {
            qs = 'user_id=' + searchUserId
            arr.push(qs)
        }
        if (searchMessage2 && searchMessage2.length > 2) {
            qs = 'message=' + searchMessage2
            arr.push(qs)
        }
        arr.push('limit='+ appSetting.logviewer_limit)

        qs = arr.join('&')

        const levels = getZisLevelOptions().filter(opt => opt.value <= MAX_ZIS_LEVEL).map(lvl => lvl.label)
        setSelectedLevelOptions(levels)
        dispatch(getLogRecords(qs, suc => {

        }))
    }

    const groupFiltered = () => {
        logger('groupFiltered requests = '+requests.length)
        if (!requests || requests.length === 0) {
            return []
        }

        let data: LogRecord[] = requests
        if (groupedView && searchIP2 && searchIP2.length>2) {
            const qMsg = (rec) => rec.ip.includes(searchIP2)
            data = data.filter((rec) => qMsg(rec))
        }
        if (groupedView && selectedMethodOptions.length < methodOptions.length) {
            const allMethods = selectedMethodOptions.join(',')  // get,post,put,delete,options
            const qMethod = (rec) => allMethods.toUpperCase().includes(rec.http_method)
            data = data.filter((rec) => qMethod(rec))
        }

        return data
    }

    const filtered = () => {
        logger('filtered records = '+records.length)
        if (!records || records.length === 0) {
            return []
        }

        let data: LogRecord[] = records.map(rec => {return {...rec, username: rec.zis_user_id && parseInt(rec.zis_user_id.toString()) > 0 ? users.find(u => u.id === parseInt(rec.zis_user_id.toString()))?.username : 'guest'}})
        if (hiddenLevelOptions && hiddenLevelOptions.length) {
            for (const opt of hiddenLevelOptions) {
                data = data.filter(rec => rec.zis_level != parseInt(opt))
                logger('filtered zis_level: != ' + opt)
            }
        }

        if (hiddenServiceOptions && hiddenServiceOptions.length) {
            for (const opt of hiddenServiceOptions) {
                data = data.filter(rec => rec.service_name != opt)
                logger('filtered service_name: != '+opt)
            }
        }

        if (searchUserId && searchUserId.length>0) {
            data = data.filter(rec => rec.zis_user_id === parseInt(searchUserId))
            logger('filtered zis_user_id: = '+searchUserId)
        }

        if (searchMessage2 && searchMessage2.length>2) {
            const qMsg = (rec) => rec.searchableName.includes(removeDiac(searchMessage2))
            data = data.filter((rec) => qMsg(rec))
            logger('filtered message: = '+searchMessage2)
        }

        if (groupedView && searchIP2 && searchIP2.length>2) {
            const qMsg = (rec) => rec.ip.includes(removeDiac(searchIP2))
            data = data.filter((rec) => qMsg(rec))
            logger('filtered IP: = '+searchIP2)
        }

        return data
    }

    const refreshGrid = () => {
        logger('refreshGrid')
        setDataSource(filtered())
        setGroupSource(groupFiltered())
        setGroupedViewLoading(false)
    }

    const handleMessageKeyPressed = (e) => {
        if (e.target.value && e.target.value.length>2) {
            if (e.which === 13) {
                setSearchMessage2(e.target.value)
            }
        }
    }

    const FilterMessage = (<Input
        placeholder={t('logViewer.message')}
        style={{width: '400px'}}
        value={searchMessage}
        allowClear
        onClick={stopPropagation}
        onKeyPress={handleMessageKeyPressed}
        onChange={(e) => {
            setSearchMessage(e.target.value)
            if (!e.target.value) {
                setSearchMessage2('')
            }
        }}
    />)

    const FilterIP = (<Input
        placeholder={t('logViewer.ip')}
        style={{width: '100px'}}
        value={searchIP}
        allowClear
        onClick={stopPropagation}
        onChange={(e) => {
            setSearchIP(e.target.value)
            if (e.target.value && e.target.value.length > 2) {
                setSearchIP2(e.target.value)
            }
            else {
                setSearchIP2('')
            }
        }}
    />)

    const selectMethod = (never, item: {label: string, value: string}) => {
        const newList: string[] = selectedMethodOptions.join(',').split(',') // to force new var
        newList.push(item.value)
        setSelectedMethodOptions(newList.filter(item => item.length))
    }
    const deselectMethod = (never, item: {label: string, value: string}) => {
        const newList: string[] = []
        for (const option of selectedMethodOptions) {
            if (option != item.value) {
                newList.push(option)
            }
        }
        setSelectedMethodOptions(newList.filter(item => item.length))
    }

    const FilterHttpMethod = (
        <Select
            placeholder='HTTP'
            mode="multiple"
            inputValue=''
            showArrow
            maxTagCount={0}
            maxTagPlaceholder={<span>HTTP</span>}
            defaultValue={[]}
            style={{ width: '100px'}}
            options={methodOptions}
            value={selectedMethodOptions}
            onSelect={selectMethod}
            onDeselect={deselectMethod}
        />
    )

    const FilterByUserInput = users && (
        <Select
            placeholder={t('logViewer.user')}
            options={users.map((u: UserDetails) => ({value: u.id.toString(), label: u.title}))}
            style={{width: 100}}
            dropdownMatchSelectWidth={200}
            value={searchUserId}
            showSearch
            allowClear
            optionFilterProp='label'
            onClear={() => setSearchUserId(undefined)}
            onSelect={(value: string, opt: {value: string, label: string}) => {
                setSearchUserId(opt.value)
            }}
            filterOption={(inputValue, option) => option!.label?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1}
        />
    )

    const serviceOptionRender = (props: CustomTagProps) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag color={getModulColorByShort(getShortModulName(value))}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    }

    const levelOptionRender = (props: CustomTagProps) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag color={getLogColorById(parseInt(value))}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    }

    const handleChangeService = (tag: string, checked: boolean) => {
        const nextSelectedTags = checked ? [...hiddenServiceOptions, tag] : hiddenServiceOptions.filter(t => t !== tag);
        setHiddenServiceOptions(nextSelectedTags);
    };

    const renderFilterService = (name: string) => {
        return (
            <CheckableTag key={name}
                          checked={hiddenServiceOptions.indexOf(name) > -1}
                          onChange={checked => handleChangeService(name, checked)}
                 style={{ marginRight: 3}}
            >{getShortModulName(name)}</CheckableTag>
        )
    }
    const renderFilterServices = () => {
        return usedServiceOptions.map(opt => renderFilterService(opt));
    }

    const handleChangeLevel = (lid: string, checked: boolean) => {
        const nextSelectedTags = checked ? [...hiddenLevelOptions, lid] : hiddenLevelOptions.filter(t => t !== lid);
        setHiddenLevelOptions(nextSelectedTags);
    };
    const renderFilterLevel = (item: { label: string, value: string }) => {
        return (
            <CheckableTag key={item.value}
                          checked={hiddenLevelOptions.indexOf(item.value) > -1}
                          onChange={checked => handleChangeLevel(item.value, checked)}
                          style={{marginRight: 3}}
            >{item.label.toUpperCase()}</CheckableTag>
        )
    }
    const renderFilterLevels = () => {
        return usedLevelOptions.map(opt => renderFilterLevel(opt));
    }

    const changeLevel = (value) => {
        setLowestLevel(value)
    }

    const selectService = (never, item: {label: string, value: string}) => {
        const newList: string[] = selectedServiceOptions.join(',').split(',') // to force new var
        logger('selectService: '+selectedServiceOptions.join(','))
        newList.push(item.value)
        setSelectedServiceOptions(newList.filter(item => item.length))
    }
    const deselectService = (never, item: {label: string, value: string}) => {
        const newList: string[] = []
        for (const option of selectedServiceOptions) {
            if (option != item.value) {
                newList.push(option)
            }
        }
        setSelectedServiceOptions(newList.filter(item => item.length))
    }



    const changeDuration = (value) => {
        setSelectedDuration(value)
    }

    const changeUseFrom = (e: CheckboxChangeEvent) => {
        setUseFrom(e.target.checked)
    }

    const getDetail = (rec: LogRecord) => {
        return (
            <pre>
                {JSON.stringify(rec, null, 2)}
            </pre>
        )
    }

    const requestColumns: ColumnsType<LogRecord> = [
        {
            title: t('logViewer.date'),
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: 120,
            className: 'left',
            fixed: 'left',
            render: (_, record: LogRecord) => (<span style={{flexWrap:'nowrap'}}>{appSetting.renderLoggerDateTime(_)}</span>)
        },
        {
            title: FilterByUserInput,
            dataIndex: 'username',
            key: 'username',
            className: 'left',
            width: 100,
            fixed: 'left',
            render: (_, record: LogRecord) => (<div>{record.username}</div>)
        },
        {
            title: FilterHttpMethod,
            dataIndex: 'http_method',
            key: 'http_method',
            className: 'right',
            width: 100,
            fixed: 'left',
            render: (_, record: LogRecord) => (<b>{record.http_method}</b>)
        },
        {
            title: FilterMessage,
            dataIndex: 'message',
            key: 'message',
            width: '60%',
            className: 'msg-col',
            render: (_, record: LogRecord) => (<div className='msg-box'>{record.message}</div>)
        },
        {
            title: FilterIP,
            dataIndex: 'ip',
            key: 'ip',
            className: 'center',
            width: 120,
            render: (_, record: LogRecord) => (<div>{record.ip}</div>)
        }
    ]

    const expandedRowRender = (parent: LogRecord) => {
        let data: LogRecord[] = [];
        data = dataSource.filter(r => r.zis_request_id === parent.zis_request_id)
        data = data.map(r => {return {...r, key: r.id}})
        return <Table columns={subColumns} dataSource={data} pagination={false} className='SubLogTable' />;
    };


    const subColumns: ColumnsType<LogRecord> = [
        {
            title: t('logViewer.date'),
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: 100,
            className: 'left',
            fixed: 'left',
            render: (_, record: LogRecord) => (<span style={{flexWrap:'nowrap'}}>{appSetting.renderLoggerDateTimeMS(_)}</span>)
        },
        {
            title: t('logViewer.service'),
            dataIndex: 'service_name',
            key: 'service_name',
            width: 80,
            className: 'center',
            fixed: 'left',
            render: (_, record: LogRecord) => (<Tag style={{width: '80px', textAlign: 'center'}} color={getModulColorByShort(getShortModulName(_))}>{getShortModulName(_)}</Tag>)
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            className: 'left',
            width: 100,
            fixed: 'left',
            render: (_, record: LogRecord) => (<div>{record.username}</div>)
        },
        {
            title: t('logViewer.zis_level'),
            dataIndex: 'zis_level',
            key: 'zis_level',
            width: 60,
            className: 'right',
            render: (_, record: LogRecord) => (<span style={{fontWeight: 'bold', color: getZisColorById(record.zis_level)}}>{getZisLevelById(record.zis_level).toUpperCase()}</span>)
        },
        {
            title: t('logViewer.message'),
            dataIndex: 'message',
            key: 'message',
            width: '60%',
            className: 'left',
            render: (_, record: LogRecord) => (
                <div className='msg-box' style={{color: getLogColorById(record.level)}}>
                    <Popover content={getDetail(record)} title="Message" trigger="click">
                        <UpSquareOutlined style={{marginRight: '4px'}} />{record.message}
                    </Popover>
                </div>)
        },
        {
            title: '',
            key: 'action',
            width: 32,
            dataIndex: 'action',
            className: 'left',
            fixed: 'right',
            render: (_, record: LogRecord) => (
                <Space size={1} style={{backgroundColor: 'white'}}>
                    <Button
                        type='text'
                        size='small'
                        onClick={() => {
                            setLogId(record.id)
                            setModalVisible(true)
                        }}
                        icon={<ZoomInOutlined />}
                    />
                </Space>
            ),
        },
    ]

    const columns: ColumnsType<LogRecord> = [
        {
            title: t('logViewer.date'),
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: 120,
            className: 'left',
            fixed: 'left',
            render: (_, record: LogRecord) => (<span style={{flexWrap:'nowrap'}}>{appSetting.renderLoggerDateTime(_)}</span>)
        },
        {
            title: t('logViewer.service'),
            dataIndex: 'service_name',
            key: 'service_name',
            width: 80,
            className: 'left',
            fixed: 'left',
            render: (_, record: LogRecord) => (<Tag style={{width: '80px', textAlign: 'center'}} color={getModulColorByShort(getShortModulName(_))}>{getShortModulName(_)}</Tag>)
        },
        {
            title: FilterByUserInput,
            dataIndex: 'username',
            key: 'username',
            className: 'left',
            width: 100,
            fixed: 'left',
            render: (_, record: LogRecord) => (<div>{record.username}</div>)
        },
        {
            title: t('logViewer.zis_level'),
            dataIndex: 'zis_level',
            key: 'zis_level',
            width: 60,
            className: 'right',
            render: (_, record: LogRecord) => (<span style={{fontWeight: 'bold', color: getZisColorById(record.zis_level)}}>{getZisLevelById(record.zis_level).toUpperCase()}</span>)
        },
        {
            title: FilterMessage,
            dataIndex: 'message',
            key: 'message',
            width: '60%',
            className: 'msg-col',
            render: (_, record: LogRecord) => (
                <div className='msg-box' style={{color: getLogColorById(record.level)}}>
                    <Popover content={getDetail(record)} title="Message" trigger="click">
                        <UpSquareOutlined style={{marginRight: '4px'}} />{record.message}
                    </Popover>
                </div>)
        },
        {
            title: '',
            key: 'action',
            width: '20px',
            dataIndex: 'action',
            fixed: 'right',
            render: (_, record: LogRecord) => (
                <Space size={1} style={{backgroundColor: 'white'}}>
                    <Button
                        type='text'
                        size='small'
                        onClick={() => {
                            setLogId(record.id)
                            setModalVisible(true)
                        }}
                        icon={<ZoomInOutlined />}
                    />
                </Space>
            ),
        },
    ]

    return (
        <>
            <Card className='logViewerPage' style={{order: 0}}
                  title={
                    <>
                      <Row>
                          <Col span={3} className='space'>
                              <FunnelPlotOutlined />{` ${t('logViewer.title')}`}
                          </Col>
                          <Col span={9} className='space right'>
                              <Space size='middle'>
                                  {t('logViewer.from')} <span>
                                  {
                                      !useFrom && (<b><Tag color='#108ee9'>{t('logViewer.NOW')}</Tag></b>)
                                  }
                                  {
                                      useFrom && (<Tag color='#dcdcdc'>{t('logViewer.NOW')}</Tag>)
                                  }

                                  {t('logViewer.or_use')}: </span>
                              <Checkbox onChange={changeUseFrom} />
                              <DatePicker
                                  selected={startDate}
                                  popperProps={{strategy: 'fixed'}}
                                  onChange={(date) => setStartDate(date)}
                                  showTimeSelect
                                  disabled={!useFrom}
                                  dateFormat="yyyy-MM-dd h:mm"
                              />
                              </Space>
                          </Col>
                          <Col span={8} className='space right'>
                              <Space size='middle'>
                                  <span>{t('logViewer.duration')}</span>
                                  <InputNumber value={selectedDuration}
                                           onChange={changeDuration}
                                           min={1} max={1000}
                                           style={{width: '140px', textAlign: 'right'}}
                                           addonAfter={t('logViewer.hour')} />
                              </Space>
                          </Col>
                          <Col span={4} className='space center'>
                              <Space size='middle'>
                                  <Button type='link' size='small' onClick={clear}>{t('logViewer.clear')}</Button>
                                  <Button type='success' size='large' onClick={search}>{t('logViewer.search')}</Button>
                              </Space>
                          </Col>
                      </Row>
                      <Row>
                          <Col span={3} className='space'>
                              {t('logViewer.view')}: <Switch checked={groupedView}
                                                             loading={groupedViewLoading}
                                                             checkedChildren="Request"
                                                             unCheckedChildren="Request"
                                                             onChange={(checked: boolean) => {
                                                                 setGroupedViewLoading(true);
                                                                 setGroupedView2(checked)
                                                             }} />
                           </Col>
                          <Col span={9} className='space right'>
                              <Space size='middle'>
                                  <span>{t('logViewer.modules')}</span>
                                  <Select
                                      mode="multiple"
                                      showArrow
                                      tagRender={serviceOptionRender}
                                      defaultValue={[]}
                                      style={{ width: '240px' }}
                                      options={serviceOptions}
                                      value={selectedServiceOptions}
                                      onSelect={selectService}
                                      onDeselect={deselectService}
                                  />
                              </Space>
                          </Col>
                          <Col span={8} className='space right'>
                              <Space size='middle'>
                                  <span>{t('logViewer.levels')}</span>
                                  <Select
                                      showArrow
                                      style={{ width: '140px', textAlign: 'left' }}
                                      options={levelOptions}
                                      value={lowestLevel.toString().toUpperCase()}
                                      onChange={changeLevel}
                                  />
                              </Space>
                          </Col>
                          <Col span={4} className='space center'>
                            <span className='small'>
                                {t('logViewer.total')}: {records.length}<br/>
                                {t('logViewer.visible')}: {dataSource?.length}
                                </span>
                          </Col>
                      </Row>
                    </>
                  }
            >
                <Row>
                    <Col span={12} className='space left'>
                        {t('logViewer.hide')}: {renderFilterServices()}
                    </Col>
                    <Col span={12} className='space right'>
                        {t('logViewer.hide')}: {renderFilterLevels()}
                    </Col>
                </Row>
                {
                    !groupedView &&
                    <Table<LogRecord>
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columns}
                        loading={isLoading}
                        dataSource={dataSource}
                        className='LogTable'
                        rowKey='id'
                        pagination={{
                            defaultPageSize: appSetting?.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => {setPageSize(`${ev.pageSize}`)}}
                        footer={() => TotalNum(Number(dataSource?.length), 'Records', dataSource)}
                    />
                }

                {
                    groupedView &&
                    <Table<LogRecord>
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        size="small"
                        columns={requestColumns}
                        expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                        expandRowByClick={true}
                        loading={isLoading}
                        dataSource={groupSource}
                        className='LogTable'
                        rowKey='id'
                        pagination={{
                            defaultPageSize: appSetting?.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => {setPageSize(`${ev.pageSize}`)}}
                        footer={() => TotalNum(Number(groupSource?.length), 'Requests', groupSource)}
                    />
                }
            </Card>

            <Modal
                destroyOnClose={true}
                style={{top: 45}}
                width='60%'
                title={
                    <>
                        <InfoCircleOutlined /> Message
                    </>
                }
                visible={isModalVisible}
                onCancel={() => setModalVisible(false)}
                maskClosable={false}
                footer={null}>
                <LogForm lid={logId} setModalVisible={setModalVisible} />
            </Modal>
        </>
    )

}

export default LogPage