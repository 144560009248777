import {
    GetInvoiceItemRequestAction,
    GetInvoiceItemResponseAction,
    GetInvoiceItemErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { InvoiceItemDetails } from '../models'

export default (
    id: number,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | GetInvoiceItemRequestAction
                | GetInvoiceItemResponseAction
                | GetInvoiceItemErrorAction,
        ) => void,
    ) => {
        const request = new GetInvoiceItemRequestAction(id)
        dispatch(request)

        protectedApiClient
            .get<InvoiceItemDetails>(`/billing/invoice-item/${id}`)
            .then((response) => {
                dispatch(new GetInvoiceItemResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetInvoiceItemErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
