import React from 'react'
import {Col, Row} from "antd";
import {CSVLink} from 'react-csv'
import {Button, message} from 'antd'

const TotalNum = (num: Number, title, data) => {
    return (
        <Row>
            <Col span={12}>
                <span >Total Records: {num}</span>
            </Col>
            <Col span={12}>
                <div style={{float: 'right'}}>
                    <Button type="primary">
                        {data && (
                            <CSVLink
                                filename={title}
                                data={data}
                                className="btn btn-primary"
                                onClick={()=>{
                                    message.success("The file is downloading").then()
                                }}
                            >
                                Export to CSV
                            </CSVLink>
                        )}
                    </Button>
                </div>
            </Col>
        </Row>
    )
}

export default TotalNum
