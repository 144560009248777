import { ActionType, BillActions } from './actions'
import {AsyncActionMode, PAGE1} from 'common/models'
import { BillPageState } from './models'


const BILL_INITIAL_STATE: BillPageState = {
  isLoading: false,
  isSaving: false,
  error: undefined,
  bills: {items: [], pagination: PAGE1},
  pager: PAGE1,
  lookup_number: [],
  lookup_company: [],
  isLoadingLookup: false,
  attachments: [],
  vatSummaries: [],
  isVatSummaryLoading: false,
  isAllocationLoading: false,
  isAllocationSaving: false,
  billAllocation: undefined,
  costAllocations: [],
  costAllocationCategories: []
}

export default (state = BILL_INITIAL_STATE, action: BillActions): BillPageState => {
  switch (action.type) {
    case ActionType.GET_BILL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          bill: action.data,
          vatSummaries: action.data.vatSummaries ? action.data.vatSummaries : [],
          attachments: action.data.attachments ? action.data.attachments : [],
          costAllocations: action.data.costAllocations ? action.data.costAllocations : []
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.CLEAR_BILL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, bill: undefined }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          bill: undefined,
          vatSummaries: [],
          attachments: [],
        }
      }
      break
    case ActionType.CREATE_BILL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          bills: {
            ...state.bills,
            items: state.bills.items.concat(action.data)
          },
          bill: action.data,
          vatSummaries: [],
          attachments: [],
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_BILLS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          bill: undefined,
          bills: action.data,
          pager: {...action.data.pagination, page: action.data.pagination.page+1},
          isLoadingLookup: false,
          attachment: undefined,
          attachments: []
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          bill: undefined,
          attachment: undefined,
          attachments: [],
          error: action.error,
        }
      }
      break
    case ActionType.LOOKUP_BILL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          isLoadingLookup: true,
        }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        if (action.field === 'number') {
          return {
            ...state,
            lookup_number: action.data,
            lookup_company: [],
            isLoadingLookup: false
          }
        }
        console.error('Wrong action field.')
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isLoadingLookup: false
        }
      }
      break
    case ActionType.BILL_LOOKUP_COMPANY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          isLoadingLookup: true,
        }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        if (action.field === 'name') {
          return {
            ...state,
            lookup_company: action.data,
            lookup_number: [],
            isLoadingLookup: false
          }
        }
        console.error('Wrong action field.')
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isLoadingLookup: false
        }
      }
      break


    case ActionType.DELETE_BILL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          bills: {
            ...state.bills,
            items: state.bills.items!.filter((bill) => bill.id !== action.request.id)
          },
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.UPDATE_BILL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          bill: action.data,
          bills: {
            ...state.bills,
            items: state.bills.items!.map((bill) =>
                bill.id === action.data.id ? { ...action.data } : bill,
            )
          },
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_BILL_ATTACHMENT:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          attachments:
              state.attachments && state.attachments.length
                  ? state.attachments.concat(action.data)
                  : [action.data],
          isLoading: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break

    case ActionType.CREATE_BILL_ATTACHMENT:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isUploading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          attachments: state.attachments?.concat(action.data),
          isUploading: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isUploading: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_BILL_ATTACHMENTS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          attachments: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_BILL_ATTACHMENT:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          attachments: state.attachments!.filter(
              (attachment) => attachment.id !== action.request.id,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_BILLVATSUMMARY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isVatSummaryLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isVatSummaryLoading: false,
          vatSummaries: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isVatSummaryLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_BILLVATSUMMARY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isVatSummaryLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          vatSummaries: state.vatSummaries?.concat(action.data),
          isVatSummaryLoading: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isVatSummaryLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_BILLVATSUMMARY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isVatSummaryLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          vatSummaries: state.vatSummaries!.filter(
              (tax) => tax.id !== action.request.id,
          ),
          isVatSummaryLoading: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isVatSummaryLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_BILLVATSUMMARY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isVatSummaryLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          vatSummaries: state.vatSummaries!.map((v) =>
              v.id === action.data.id ? { ...action.data } : v,
          ),
          isVatSummaryLoading: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isVatSummaryLoading: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_BILL_COSTALLOCATIONS_BY_BILL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          costAllocations: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_BILL_COSTALLOCATIONS_BY_CATEGORY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          costAllocations: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_BILL_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          billAllocation: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_BILL_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          costAllocations: state.costAllocations?.concat(action.data),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_BILL_COSTALLOCATIONS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          costAllocations: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_BILL_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          costAllocations: state.costAllocations!.filter(
              (allocation) => allocation.id !== action.request.id,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_BILL_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          costAllocations: state.costAllocations!.map((allocation) =>
              allocation.id === action.data.id ? { ...action.data } : allocation,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_COST_CATEGORIES_BILL:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          costAllocationCategories: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
  }
  return state
}
