import React, { useState, useEffect } from 'react'
import { formItemLayout, tailLayout } from 'helpers/layoutHelpers'
import { useForm } from 'antd/lib/form/Form'
import { Form, Input, Switch, Button, Select, message, Spin } from 'antd'


import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'common/models'
import createVpsSetting from 'pages/vpsadmin/actions/createVpsSetting'
import updateVpsSetting from 'pages/vpsadmin/actions/updateVpsSetting'
import getVpsSetting from 'pages/vpsadmin/actions/getVpsSetting'
import { VpsHelper, VpsSettingModel } from '../../models'
import setDefaultVpsSetting from 'pages/vpsadmin/actions/setDefaultVpsSetting'

import { stateHypervisorSetting, } from '../../models'

interface Props {
    setModalVisible: (isShow: boolean) => void
    updateId: number
}

const emptyAresData = {
    id: 0,
    host: '',
    username: '',
    password: '',
    result: '',
    state: '',
    note: ''
}


const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const VpsSettingForm = ({ setModalVisible, updateId }: Props) => {
    const { t } = useTranslation()
    const [form] = useForm()

    const dispatch = useDispatch()
    const { fontSize } = useSelector((state: AppState) => state.font)
    const { isLoading } = useSelector((state: AppState) => state.vpsadmin)
    const vpsSettings = useSelector((state: AppState) => state.vpsadmin.vpsSettings)
    const vpsSetting = useSelector((state: AppState) => state.vpsadmin.vpsSetting)
    const [updateItem, setUpdateItem] = useState<VpsSettingModel>(emptyAresData)
    const [dataToUpdate, setDataToUpdate] = useState<VpsSettingModel>(emptyAresData)
    const [itemStatus, setItemStatus] = useState<string>('')



    useEffect(() => {
        setDataToUpdate(dataToUpdate)
        setUpdateItem(emptyAresData)

        if (updateId) {
            // try call local DB
            const existingItem = vpsSettings.find(item => item.id === updateId)
            if (existingItem) {
                dispatch(getVpsSetting(existingItem.id!))
            }
        }
    }, [])

    useEffect(() => {
        const result = vpsSetting
        if (result) {
            setItemStatus(VpsHelper.convertedState(result.state))
            setUpdateItem(result)
        }
    }, [vpsSetting])

    useEffect(() => {

        setDataToUpdate({ ...updateItem, state: itemStatus })

    }, [updateItem])

    useEffect(() => {
        // update values to form
        form.setFieldsValue(dataToUpdate)
    }, [dataToUpdate])

    const onFinish = (values) => {
        values.state = VpsHelper.reConvertedState(values.state)
        if (dataToUpdate.id !== 0) {
            dispatch(updateVpsSetting(
                {
                    ...values, id: dataToUpdate.id
                },
                (suc: boolean) => {
                    if (suc) {
                        message.success(t('general.success'))
                        dispatch(setDefaultVpsSetting())
                        setModalVisible(false)
                    }
                },
            ))

        } else {
            dispatch(createVpsSetting(
                {
                    ...values,
                },
                (suc: boolean) => {
                    if (suc) {
                        message.success(t('general.success'))
                        dispatch(setDefaultVpsSetting())
                        setModalVisible(false)
                    }
                },
            ))
        }

    }

    return (
        <>
            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...formItemLayout}
                    name='create-vps-setting'
                    className=''
                    onFinish={onFinish}
                    initialValues={{ ...dataToUpdate }}
                >
                    <Item name='host'
                        rules={[{ required: true, message: t('errors.field_required') }]}
                        label={t('vpsadmin.setting.host')}
                        hasFeedback>
                        <Input size={fontSize} />
                    </Item>
                    <Item name='username'
                        rules={[{ required: true, message: t('errors.field_required') }]}
                        label={t('vpsadmin.setting.username')}
                        hasFeedback>
                        <Input size={fontSize} />
                    </Item>
                    <Item name='password'
                        rules={[{ required: true, message: t('errors.field_required') }]}
                        label={t('vpsadmin.setting.password')}
                        hasFeedback>
                        <Input size={fontSize} />
                    </Item>
                    <Item name='state'
                        rules={[{ required: true, message: t('errors.field_required') }]}
                        label={t('vpsadmin.setting.state')}
                        hasFeedback>
                        <Select style={{ width: 200 }} >
                            {stateHypervisorSetting.map((value) => {
                                return (<Option value={value} key={value}>{value}</Option>)
                            })}
                        </Select>
                    </Item>

                    <Item name='note'
                        label={t('vpsadmin.setting.note')}
                        hasFeedback>
                        <TextArea rows={4} placeholder={t('vpsadmin.setting.note')} />
                    </Item>

                    <Item {...tailLayout}>
                        <Button type='primary' htmlType='submit'
                            className='login-form-button'
                            size={fontSize}>
                            {(dataToUpdate && dataToUpdate.id) ? t('vpsadmin.setting.update') : t('vpsadmin.setting.create')}
                        </Button>
                    </Item>
                </Form>
            </Spin>
        </>

    )
}

export default VpsSettingForm
