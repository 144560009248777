import { AsyncActionMode } from 'common/models'
import {
	CreatePricelistParams,
	DeletePricelistParams,
	PricelistDetails,
	PricelistError,
	UpdatePricelistParams
} from '../models'
import { HistoryDetails } from "../../../../components/History/models";

export enum ActionType {
	CREATE_PRICELIST = 'CREATE_PRICELIST',
	GET_PRICELISTS = 'GET_PRICELISTS',
	GET_PRICELIST_BY_CUSTOMER = 'GET_PRICELIST_BY_CUSTOMER',
	GET_CURRENT_PRICELIST = 'GET_CURRENT_PRICELIST',
	GET_HISTORY_PRICELIST_BY_CUSTOMER = 'GET_HISTORY_PRICELIST_BY_CUSTOMER',
	GET_PRICELIST = 'GET_PRICELIST',
	UPDATE_PRICELIST = 'UPDATE_PRICELIST',
	DELETE_PRICELIST = 'DELETE_PRICELIST',
	ACTIVATE_PRICELIST = 'ACTIVATE_PRICELIST'

}

export type PricelistActions =
	| CreatePricelistRequestAction
	| CreatePricelistResponseAction
	| CreatePricelistErrorAction
	| GetPricelistsRequestAction
	| GetPricelistsResponseAction
	| GetPricelistsErrorAction
	| GetPricelistRequestAction
	| GetPricelistResponseAction
	| GetPricelistErrorAction
	| UpdatePricelistRequestAction
	| UpdatePricelistResponseAction
	| UpdatePricelistErrorAction
	| DeletePricelistRequestAction
	| DeletePricelistResponseAction
	| DeletePricelistErrorAction
	| GetPricelistsByCustomerRequestAction
	| GetPricelistsByCustomerResponseAction
	| GetPricelistsByCustomerErrorAction
	| GetCurrentPricelistRequestAction
	| GetCurrentPricelistResponseAction
	| GetCurrentPricelistErrorAction
	| GetHistoryPricelistsByCustomerRequestAction
	| GetHistoryPricelistsByCustomerResponseAction
	| GetHistoryPricelistsByCustomerErrorAction
	| ActivatePricelistRequestAction
	| ActivatePricelistResponseAction
	| ActivatePricelistErrorAction


export class GetPricelistsByCustomerRequestAction {
	readonly type = ActionType.GET_PRICELIST_BY_CUSTOMER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public customer_id: number) { }
}
export class GetPricelistsByCustomerResponseAction {
	readonly type = ActionType.GET_PRICELIST_BY_CUSTOMER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetPricelistsByCustomerRequestAction, public data: PricelistDetails[]) { }
}
export class GetPricelistsByCustomerErrorAction {
	readonly type = ActionType.GET_PRICELIST_BY_CUSTOMER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetPricelistsByCustomerRequestAction, public error: PricelistError) { }
}

export class CreatePricelistRequestAction {
	readonly type = ActionType.CREATE_PRICELIST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: CreatePricelistParams) { }
}
export class CreatePricelistResponseAction {
	readonly type = ActionType.CREATE_PRICELIST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: CreatePricelistRequestAction, public data: PricelistDetails) { }
}
export class CreatePricelistErrorAction {
	readonly type = ActionType.CREATE_PRICELIST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: CreatePricelistRequestAction, public error: PricelistError) { }
}

export class GetPricelistRequestAction {
	readonly type = ActionType.GET_PRICELIST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetPricelistResponseAction {
	readonly type = ActionType.GET_PRICELIST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetPricelistRequestAction, public data: PricelistDetails) { }
}
export class GetPricelistErrorAction {
	readonly type = ActionType.GET_PRICELIST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetPricelistRequestAction, public error: PricelistError) { }
}

export class GetPricelistsRequestAction {
	readonly type = ActionType.GET_PRICELISTS
	readonly mode = AsyncActionMode.REQUEST
	constructor() {
		''
	}
}
export class GetPricelistsResponseAction {
	readonly type = ActionType.GET_PRICELISTS
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetPricelistsRequestAction, public data: PricelistDetails[]) { }
}
export class GetPricelistsErrorAction {
	readonly type = ActionType.GET_PRICELISTS
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetPricelistsRequestAction, public error: PricelistError) { }
}

export class UpdatePricelistRequestAction {
	readonly type = ActionType.UPDATE_PRICELIST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: UpdatePricelistParams) { }
}
export class UpdatePricelistResponseAction {
	readonly type = ActionType.UPDATE_PRICELIST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: UpdatePricelistRequestAction, public data: PricelistDetails) { }
}
export class UpdatePricelistErrorAction {
	readonly type = ActionType.UPDATE_PRICELIST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: UpdatePricelistRequestAction, public error: PricelistError) { }
}

export class DeletePricelistRequestAction {
	readonly type = ActionType.DELETE_PRICELIST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) {
		''
	}
}
export class DeletePricelistResponseAction {
	readonly type = ActionType.DELETE_PRICELIST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: DeletePricelistRequestAction, public data: PricelistDetails) { }
}
export class DeletePricelistErrorAction {
	readonly type = ActionType.DELETE_PRICELIST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: DeletePricelistRequestAction, public error: PricelistError) { }
}

export class GetHistoryPricelistsByCustomerRequestAction {
	readonly type = ActionType.GET_HISTORY_PRICELIST_BY_CUSTOMER
	readonly mode = AsyncActionMode.REQUEST
	constructor(public customer_id: number) { }
}
export class GetHistoryPricelistsByCustomerResponseAction {
	readonly type = ActionType.GET_HISTORY_PRICELIST_BY_CUSTOMER
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetHistoryPricelistsByCustomerRequestAction, public data: HistoryDetails[]) { }
}
export class GetHistoryPricelistsByCustomerErrorAction {
	readonly type = ActionType.GET_HISTORY_PRICELIST_BY_CUSTOMER
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetHistoryPricelistsByCustomerRequestAction, public error: PricelistError) { }
}

export class ActivatePricelistRequestAction {
	readonly type = ActionType.ACTIVATE_PRICELIST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public id: number) { }
}
export class ActivatePricelistResponseAction {
	readonly type = ActionType.ACTIVATE_PRICELIST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: ActivatePricelistRequestAction, public data: PricelistDetails) { }
}
export class ActivatePricelistErrorAction {
	readonly type = ActionType.ACTIVATE_PRICELIST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: ActivatePricelistRequestAction, public error: string) { }
}

export class GetCurrentPricelistRequestAction {
	readonly type = ActionType.GET_CURRENT_PRICELIST
	readonly mode = AsyncActionMode.REQUEST
	constructor(public payload: number) {
		''
	}
}
export class GetCurrentPricelistResponseAction {
	readonly type = ActionType.GET_CURRENT_PRICELIST
	readonly mode = AsyncActionMode.RESPONSE
	constructor(public request: GetCurrentPricelistRequestAction, public data: PricelistDetails) { }
}
export class GetCurrentPricelistErrorAction {
	readonly type = ActionType.GET_CURRENT_PRICELIST
	readonly mode = AsyncActionMode.ERROR
	constructor(public request: GetCurrentPricelistRequestAction, public error: PricelistError) { }
}
