import moment from "moment";
import { Form } from "antd";
import isValidDomain from "is-valid-domain";
import {MINIMAL_ROLE, SUPER_ROLE} from "./LoggedUser";
import ipRegex from "ip-regex";
import {euPhoneNumberRegex} from "../helpers/stringHelpers";
import {getLanguage} from "../helpers/langHelpers";

export const SQL_DATE_FORMAT = 'YYYY-MM-DD'
export const ZCOM_COMPANY_ID = 44
export const ZCOM_CUSTOMER_ID = 1

export const REG_USERNAME = new RegExp('^[A-Za-z][A-Za-z0-9_]+$')
export const REG_IPV4 = new RegExp('^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$')

export function _sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export interface EmptyFunction {
    (): void
}

export const getInvoiceCurrency = (items: string[]) => {
    // returns currencies for invoices
    return items.filter(c => c === 'CZK' || c === 'EUR')
}

export function _getRoles(ctrlName: string, menu_roles: {}): string[] {
    // returns roles needed for displaying a control element
    if (!menu_roles) {
        // minimal roles
        return [MINIMAL_ROLE]
    }
    const item = Object.keys(menu_roles).find(s => s === ctrlName)
    if (!item) {
        return []
    }

    if (menu_roles[ctrlName]) {
        return menu_roles[ctrlName]
    }

    // no roles
    return []  //[MINIMAL_ROLE]
}


export function _isAllowed(neededRoles: string[], roles: string[]): boolean {
    // returns true if the user has access to control(page)
    if (roles.includes(SUPER_ROLE)) {
        return true
    }
    if (neededRoles) {
        for (const neededRole of neededRoles) {
            if (roles.includes(neededRole)) {
                return true
            }
        }
    }
    return false
}

export function _isValidDomain(domain: string): boolean {
    return isValidDomain(domain)
}

export function _isValidIP4(address: string): boolean {
    return ipRegex.v4({exact: true}).test(address)
}

export function _isValidIP6(address: string): boolean {
    return ipRegex.v6({exact: true}).test(address)
}

export function _validateEmail(emailStr: string): boolean {
    if (!emailStr) {
        return false
    }
    const emailText = emailStr.toLowerCase()
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    return pattern.test(emailText)
}

export const onKeyPressOnlyNum = (e) => {
    if (!only_numbers(e)) {
        // allow only decimal numb.
        e.preventDefault();
    }
}

export function only_numbers(evt) {
    let ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
        return false
    }
    return true
}

export function _validatePhone(phone: string): boolean {
    if (!phone) {
        return false
    }
    let phoneText = phone
    const pattern = new RegExp(euPhoneNumberRegex);
    return pattern.test(phoneText)
}

export function _validateUsername(username: string): boolean {
    if (!username) {
        return false
    }
    if (username.length < 4) {
        return false
    }
    const usernameText = username.toLowerCase()
    const pattern = /^[A-Za-z][A-Za-z0-9_]+$/;
    return pattern.test(usernameText)
}

export type ValidateParams = {
    value?: string,
    validateStatus: ValidateStatus,
    errorMsg: string
}
export type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];
export const validateInvoicingEmail = (emailStr: string): ValidateParams => {
    // validate invoicing emails
    const emails = emailStr.trim().split(',')
    let result = true
    for (const email of emails) {
        result = _validateEmail(email)
        if (!result) break
    }
    return !result ? {
        validateStatus: 'error',
        errorMsg: 'Should be comma-separated emails'
    } :
        {
            validateStatus: 'success',
            errorMsg: ''
        }
}

export function isAlphaNumCode(keyCode: number): boolean {
    if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || keyCode == 32){
        return true
    }
    return false
}

export function remLastSlash(url: string) {
    if (url.length > 1 && url[url.length - 1] === '/') {
        // last char
        return url.substr(0, url.length - 1)
    }
    return url
}

export function jsonStringifyRecursive(obj) {
    const cache = new Set();
    return JSON.stringify(obj, (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (cache.has(value)) {
                // Circular reference found, discard key
                return;
            }
            // Store value in our collection
            cache.add(value);
        }
        if (typeof value === 'bigint') {
            return `${value}`
        }
        return value;
    }, 4);
}

export function _censor(censor) {
    // helper for JSON.stringify recursion
    let i = 0
    return function (key, value) {
        if (i !== 0 && typeof (censor) === 'object' && typeof (value) == 'object' && censor == value)
            return '[Circular]';

        if (i >= 16) // seems to be a harded maximum of 30 serialized objects?
            return '[Unknown]';

        ++i; // so we know we aren't using the original object anymore
        return value;
    }
}

export function _renderPercent(n: number) {
    return `${n / 100}%`
}

export const getPercent = (a, b) => {
    return Math.round(a * 100 / b)
}

export function _renderPrice(n: number, round: number = 2) {
    const price = (n / 100).toFixed(round)
    return `${price}`.replace('.', ',')
}

export function _formatPriceStr(s: string) {
    if (!s) {
        return ''
    }
    let s2 = s.replace(',', '.')
    let d = parseFloat(s2)
    return _formatPrice(d)
}

export function _formatPrice(n: number) {
    if (!n) {
        return ''
    }
    const parts = new Intl.NumberFormat('cs').formatToParts(n)
    let fraction = ''
    let price = ''
    for (const part of parts) {
        if (part.type === 'fraction') {
            fraction = part.value + '00'
        }
        else {
            price = price + part.value
        }
    }
    return price + fraction.substr(0, 2)
}

export function _renderDate(dt: Date | string, format: string = 'YYYY-MM-DD') {
    return moment(dt).format(format)
}

export function _renderDateTime(dt: Date | string, format: string = 'YYYY-MM-DD H:i') {
    return moment(dt).format(format)
}

export function _renderFullDateTime(dt: Date | string, format: string = 'YYYY-MM-DD HH:mm:ss') {
    return moment(dt).format(format)
}

export function reverseString(str: string | undefined): string {
    if (!str) {
        return ''
    }
    let arr = str.split('')
    let reverseArr = arr.reverse()
    return reverseArr.join('')
}

export function _parsePhone(phone: string | undefined): string {
    if (!phone) {
        return ''
    }
    return phone.replace(/\s/g, '')
}

export function _renderPhone(phone: string | undefined): string {
    if (!phone) {
        return ''
    }
    // +420123456789  => +420 123 456 789
    let reversePhone: string = reverseString(phone.replace(/\s/g, ''))
    let i = 1
    let newPhone = ''
    for (let char of reversePhone) {
        newPhone = newPhone + char
        if ((newPhone.length < 12) && (i % 3 === 0))  {
            newPhone = newPhone + ' '
        }
        i++
    }
    return reverseString(newPhone)
}

export const _parsePrice = (value: string) => {
    if (!value) {
        return 0
    }
    value = value.replace(/\s/g, '')
    value = value.replace(',', '.')
    let price = parseFloat(value) * 100
    return parseInt(price.toFixed(0))
}

export const _parseCount = (value) => {
    if (!value) {
        return 0
    }
    value = value.replace(',', '.')
    let count = parseFloat(value) * 1000
    return parseInt(count.toFixed(0))
}

export const _stoUniq = (arr: string[]) => {
    return arr.filter((n, i) => arr.indexOf(n) === i)
}
export const _toInt = (b) => b ? 1 : 0

export const slugify = (name: string | undefined) => {
    if (!name) {
        return ''
    }
    name = removeDiac(name)
    name = name.toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/\./g, '-')
        .replace(/,/g, '')
        .replace(/:/g, '')
        .replace(/\?/g, '')
        .replace(/^-+|-+$/g, '')
        .replace(/---/g, '-')
        .replace(/--/g, '-')
    return name
}

export const removeDiac = (name: string | undefined) => {
    // remove diacritics from the name
    if (!name) {
        return ''
    }
    const lineA = 'áčďéěíľňóřšťúůýž'
    const lineB = 'acdeeilnorstuuyz'
    let result = name.toLowerCase()
    for (let i = 0; i < result.length; i++) {
        let chOld = result.substr(i, 1)
        let pos = lineA.indexOf(chOld)
        if (pos > -1) {
            let chNew = lineB.substr(pos, 1)
            result = result.replace(chOld, chNew)
        }
    }
    return result
}

export const getDecSeparator = () => {
    if (getLanguage() === 'en') {
        return '.'
    }
    else {
        return ','
    }
}

export function decEn(s: string) {
    if (!s) {
        return ''
    }
    return s.replace(',', '.')
}

export function decCs(s: string) {
    if (!s) {
        return ''
    }
    return s.replace('.', ',')
}

export const _isNotEmptyObject = (obj) => {
    return obj && Object.keys(obj).length > 0
}

export const _isString = (obj) => {
    return typeof obj === 'string' || obj instanceof String
}

export const _isObject = (obj) => {
    return typeof obj === 'object' || obj instanceof Object
}

export const getJsonParam = (strParams: any, name: string) => {
    if (!strParams) {
        return undefined
    }
    let params: object | undefined
    if (_isString(strParams)) {
        params = JSON.parse(strParams)
    }
    if (_isObject(strParams)) {
        params = strParams
    }
    if (!params || !_isNotEmptyObject(params)) {
        return undefined
    }
    if (Object.keys(params).includes(name)) {
        return params[name]
    }
    return undefined
}

export const getJsonKeys = (strParams: any) => {
    if (!strParams || !_isString(strParams)) {
        return undefined
    }
    let params: object | undefined
    if (_isString(strParams)) {
        params = JSON.parse(strParams)
    }
    if (_isObject(strParams)) {
        params = strParams
    }
    if (!params || !_isNotEmptyObject(params)) {
        return undefined
    }
    return Object.keys(params)
}

export const stopPropagation = (e) => {
    e.stopPropagation()
    e.preventDefault()
}

export const formatISO = (dt) => {
    if (dt) {
        // return SQL_DATE_FORMAT
        const m = dt.getMonth() + 1
        const mm = `00${m}`.slice(-2)
        const d = dt.getDate()
        const dd = `00${d}`.slice(-2)
        return `${dt.getFullYear()}-${mm}-${dd}`
    }
    return ''
}

export const _remLastComma = (str: string): string => {
    return str.replace(/\.$/, "");
}

export function _addLastComma(str: string): string {
    const s = str.replace(/\.$/, "");
    return s + '.'
}