import {
    GetContainersChangesResponseAction,
    GetContainersChangesErrorAction,
    GetContainersChangesRequestAction
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ChangesModel } from "../models";


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetContainersChangesRequestAction | GetContainersChangesResponseAction | GetContainersChangesErrorAction,
        ) => void,
    ) => {
        const request = new GetContainersChangesRequestAction(id)
        dispatch(request)
        protectedApiClient
            .get<ChangesModel[]>(`/vpsadmin/container/${id}/change`)
            .then((response) => {
                dispatch(new GetContainersChangesResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetContainersChangesErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
