import { Badge } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import './GraphLegendItem.scss'

interface LegendItem {
    type: string,
    value: number | string,
    color?: string
    id?: number
}


const GraphLegendItem = (props: LegendItem) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()


    useEffect(() => {

    }, [])

    const link = (id, name) => {
        let result
        if (id > 0 && id != undefined) {
            return <Link to={`/vpsadmin/vps/${id}`}>
                {props.type}
            </Link>
        } else {
            return <>{name}</>
        }

    }


    return (
        <>
            <div className='GraphLegendItem'>
                <div className='legend-color' style={{ backgroundColor: props.color }}></div>

                {link(props.id, props.type)}
            </div>

        </>

    )
}

export default GraphLegendItem

