import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {
    Col, Divider,
    Form,
    Input, InputRef,
    message, Modal,
    Row,
    Select, Space,
    Spin,
    Table,
    Tag,
} from 'antd'
import Button from 'antd-button-color'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {Link} from "react-router-dom";
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import getInventoryPageTypes from "../inventory/actions/getInventoryPageTypes";
import {UpdatePhysicalServerParams} from "./models";
import {InventoryModel} from "../inventory/models";
import {InventoryTypeModel} from "../type/models";
import TextArea from "antd/lib/input/TextArea";
import getNewServers from "./actions/getNewServers";
import updatePhysicalServer from "./actions/updatePhysicalServer";
import removeInventoryBase from "./actions/removeInventoryBase";
import {removeDiac, stopPropagation} from "../../../common/fce";
import {DeleteTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import {sort_id, sort_name} from "../../../common/sorting";
import {renderInventoryItem} from "../common";
import deletePhysicalServer from "./actions/deletePhysicalServer";

interface Props {
    id: number
    setModalVisible: (param: boolean) => void
}

const { Item } = Form
const { confirm } = Modal

const ServerCaseForm = ({id, setModalVisible}: Props) => {

    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const inputRef = useRef<InputRef>(null)

    const {inventory_types} = useSelector((state: AppState) => state.inventorytype)
    const {physicalServer, newServers, newServersIsLoading, isSaving} = useSelector((state: AppState) => state.inventoryphysicalserver)

    const [componentSource, setComponentSource] = useState<InventoryModel[]>([])
    const [inventoryValue, setInventoryValue] = useState<InventoryModel | undefined>()
    const [type, setType] = useState<InventoryTypeModel | undefined>(undefined)
    const [items, setItems] = useState<{value: number, title: string | ReactNode, name: string, children: any[]}[]>([]);
    const [dataSource, setDataSource] = useState<{value: number, title: string | ReactNode, name: string, children: any[]}[]>([]);

    const [componentOptions, setComponentOptions] = useState<{ label: string, value: number }[]>([])
    const [editValid, setEditValid] = useState<boolean>(false)
    const [removeValid, setRemoveValid] = useState<boolean>(false)
    const [serial, setSerial] = useState('')
    const [openDelete, setOpenDelete] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [isViewer, setViewer] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    useEffect(() => {
        // trigger ONCE
        setViewer(loggedUser.hasAccess('page_inventory_physical'))
        setEditor(loggedUser.hasAccess('page_inventory_physical_edit_button'))
        setDeleter(loggedUser.hasAccess('page_inventory_physical_delete_button'))
    }, [])

    useEffect(() => {
        if (!inventory_types || inventory_types.length === 0) {
            dispatch(getInventoryPageTypes())
            return
        }
        if (!id || id < 1) {
            message.error('error in loading')
        }
    }, [inventory_types])

    useEffect(() => {
        if (physicalServer && physicalServer.id === id) {
            if (physicalServer.inventory) {
                setInventoryValue(physicalServer.inventory)
                const tid = physicalServer.inventory.type_id
                setType(inventory_types.find(t => t.id === tid))
            }
            !newServersIsLoading && dispatch(getNewServers())
            if (physicalServer.server_components) {
                setComponentSource(physicalServer.server_components)
                setComponentOptions(physicalServer.server_components.map(r => ({label: r.name, value: r.id})))
            }
            else {
                setComponentSource([])
                setComponentOptions([])
            }
        }
    }, [physicalServer])

    useEffect(() => {
        if (newServers) {
            // render select dropdown
            const arr: {value: number, title: string | ReactNode, name: string, children: any[]}[] = []
            for (const item of newServers.sort(sort_id)) {
                arr.push({value: item.id, title: renderInventoryItem(item), name: `${item.name}|${item.serial}`, children: []})
            }
            if (inventoryValue) {
                // selected inventoryItem
                setEditValid(true)
                arr.push({value: inventoryValue.id, title: renderInventoryItem(inventoryValue), name: `${inventoryValue.name}|${inventoryValue.serial}`, children: []})
            }
            setItems(arr)
        }
    }, [newServers])

    useEffect(() => {
        if (serial) {
            refreshGridItems()
        }
    }, [serial])

    useEffect(() => {
        if (physicalServer && physicalServer.id === id) {
            refreshGridItems()
            inventoryValue && form.setFieldsValue({name: inventoryValue.id})
        }
    }, [items])

    const removeBase = () => {
        if (physicalServer && physicalServer.id > 0) {
            dispatch(removeInventoryBase(physicalServer.id, suc => {
                if (suc) {
                    setModalVisible(false)
                    message.success(t('general.success'))
                }
            }))
        }
    }

    const getServerCaseOut = () => {
        return inventory_types.filter(t => t.id === 1 || t.id === 2 || t.id === 4 || t.id === 15)
    }

    const getServerCaseIn = () => {
        return inventory_types.filter(t => t.id > 4 && t.id < 15)
    }

    const onDelete = (id: number) => {
        dispatch(deletePhysicalServer(id,suc => {
            suc && message.success(t('general.success'))
            setModalVisible(false)
        }))
    }

    const showConfirmDelete = (title: string) => {
        if (!physicalServer) {
            return
        }
        if (!isDeleter) {
            message.error(t('general.error403'))
            return
        }
        confirm({
            icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
            title: title,
            content: <p>{physicalServer && physicalServer.conf}</p>,
            okText: t('general.yes'),
            cancelText: t('general.cancel'),
            okButtonProps: { loading: confirmDelete },
            className: 'confirm-alert',
            onOk() {
                setConfirmDelete(true)
                dispatch(deletePhysicalServer(physicalServer.id,suc => {
                    setConfirmDelete(false)
                    setOpenDelete(false)
                    suc && message.success(t('general.success'))
                    setModalVisible(false)
                }))
            },
            onCancel() {
                setOpenDelete(false)
            },
            }
        )
    }

    const onFormSubmit = (values: Store) => {
        if (physicalServer && physicalServer.id > 0 && inventoryValue) {
            const params: UpdatePhysicalServerParams = {
                id: id,
                ident: values.ident,
                inventory_id: inventoryValue.id,
                power: parseInt(values.power),
                conf: values.conf
            }
            dispatch(updatePhysicalServer(params, (suc: boolean) => {
                if (suc) {
                    setModalVisible(false)
                    message.success(t('general.success'))
                }
            }))
        }
    }

    const onChange = (newValue: number|undefined) => {
        if (!newValue) {
            setInventoryValue(undefined)
            setEditValid(false)
            setRemoveValid(true)
            return
        }
        if (newValue < 1000000) {
            const item = newServers.find(s => s.id === newValue)
            setInventoryValue(item)
            if (item) {
                setType(inventory_types.find(t => t.id === item.type_id))
                setEditValid(true)
                setRemoveValid(false)
            }
        }
    }

    const canRemoveBase = () => {
        return removeValid && componentSource.length === 0
    }

    const canDeleteServer = () => {
        return physicalServer && physicalServer.id && componentSource.length === 0 && removeValid
    }

    const onSerialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSerial(event.target.value);
    }

    const checkMinSearch = (val: string | undefined) => {
        if (!val) {
            return false
        }
        return val.length > SEARCH_MIN
    }

    const refreshGridItems = () => {
        setDataSource(filtered().sort((a, b) => sort_name(b, a)))
    }

    const filtered = () => {
        if (!items || items.length === 0) {
            return []
        }

        let data = items
        if (checkMinSearch(serial)) {
            // inventory
            const qName = (s: {value: number, title: string | ReactNode, name: string, children: any[]}) => removeDiac(s.name).includes(removeDiac(serial))
            data = data.filter((c) => qName(c))
        }
        return data
    }

    const columns = [
        {
            title: t('physicalServersPage.serial'),
            dataIndex: 'serial',
            width: '100px',
            key: 'serial',
        },
        {
            title: t('physicalServersPage.name'),
            dataIndex: 'name',
            width: '480px',
            key: 'name',
        }
    ]

    if (!physicalServer || physicalServer.id != id) {
        return (<Spin />)
    }

    return (
        <>
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
            initialValues={{
                ...physicalServer,
                name: physicalServer?.inventory?.name,
                location: physicalServer?.inventory?.location_id,
                type: physicalServer?.inventory?.type_id,
                }}
        >
            <Row>
                <Col span={16}>
                    <Form.Item name='ident' labelCol={{span: 6}} wrapperCol={{span: 18}} label={t('physicalServersPage.ident')} >
                        <Input style={{width: '120px', fontWeight: 'bold'}} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    Typ:&nbsp;<Tag color='blue'>{type && type.name}</Tag>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item name='name' labelCol={{span: 4}} wrapperCol={{span: 20}} label={(<>
                        <div style={{display:'block'}}>
                            <Link className='link' to={`/inventory/${physicalServer.inventory_id}`}>{t('physicalServersPage.base')}</Link>
                        </div>
                    </>)} >
                        <Select
                            style={{ width: '100%' }}
                            className='inventoryItem'
                            dropdownStyle={{ maxHeight: 600, overflow: 'auto' }}
                            placeholder={t('physicalServersPage.inventory_select')}
                            allowClear={componentSource.length === 0}
                            showSearch
                            dropdownRender={menu => (
                                <>
                                    <Space style={{ padding: '0 8px 4px' }}>
                                        <span className='text-disabled'>Filter: </span>
                                        <Input
                                            placeholder="Serial"
                                            ref={inputRef}
                                            value={serial}
                                            onChange={onSerialChange}
                                        />
                                        <span className='text-disabled'>({dataSource.length} items)</span>
                                    </Space>
                                    <Divider style={{ margin: '8px 0' }} />
                                    {menu}
                                </>
                            )}
                            onClick={stopPropagation}
                            onChange={onChange}
                            options={dataSource.map(item => ({ label: item.title, value: item.value }))}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row style={{marginTop: '10px'}}>
                <Col span={24}>
                    <Form.Item name='conf' labelCol={{span: 4}} wrapperCol={{span: 20}} label={t('physicalServersPage.conf')} >
                        <TextArea data-gramm="false"
                                  data-gramm_editor="false"
                                  data-enable-grammarly="false"
                                  style={{height: '60px', fontSize: '1.1em', fontWeight: 'bold'}} />
                    </Form.Item>
                </Col>
            </Row>

            <Row style={{marginTop: '5px'}}>
                <Col span={4}>{t('physicalServersPage.components')}:</Col>
                <Col span={20}>
                    <Table<InventoryModel>
                        showHeader={true}
                        bordered={true}
                        style={{height: '160px', overflowY: 'auto', overflowX: 'auto', border: 'lightgray 1px solid', color: 'red'}}
                        columns={columns}
                        dataSource={componentSource}
                        rowKey='id'
                        pagination={false}
                    />
                </Col>
            </Row>

            <Row style={{marginTop: '20px', marginBottom: '20px'}}>
                <Col span={4} className='right' style={{paddingRight: '10px'}}>{t('physicalServersPage.location_id')}:</Col>
                <Col span={14}>&nbsp;
                    <span>{physicalServer.text_location}</span>

                </Col>
                <Col span={6}>&nbsp;
                    <span>{t('physicalServersPage.rack_pos')}:</span>&nbsp;
                    <span>{physicalServer.rack_pos}</span>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item name='power' labelCol={{span: 4}} wrapperCol={{span: 20}} label={t('physicalServersPage.power')} >
                        <Input type='number' style={{width: '120px', textAlign: 'center'}} suffix='W' step="1" />
                    </Form.Item>
                </Col>
            </Row>

            <Row style={{marginTop: '0px', marginBottom: '10px'}}>
                <Col span={4} className='right' style={{paddingRight: '10px'}}>{t('physicalServersPage.created_at')}:</Col>
                <Col span={20}>&nbsp;
                    <span>{physicalServer.created_at && appSetting.renderDate(physicalServer.created_at) }</span>
                </Col>
            </Row>

            <Row>
                <Col span={24} className='center'>
                    <Button type='primary' htmlType='submit'
                            loading={isSaving}
                            disabled={!editValid}>
                        {t('general.save')}
                    </Button>
                </Col>
            </Row>

            <Row style={{borderTop: '1px solid #dcdcdc', marginTop: '5px', paddingTop: '5px'}}>
                <Col span={6}>
                    <Button danger size='small'
                            style={{margin: '4px', width: '140px'}}
                            disabled={!canRemoveBase()}
                            onClick={() => removeBase()}
                            icon={<DeleteTwoTone twoToneColor='red' />}>{t('physicalServersPage.remove_inventory')}</Button>
                    <Button danger size='small'
                            style={{margin: '4px', width: '140px'}}
                            disabled={!canDeleteServer()}
                            onClick={() => showConfirmDelete(t('inventoryPage.confirm_delete_item'))}
                            icon={<DeleteTwoTone twoToneColor='red' />}>{t('physicalServersPage.delete_server')}</Button>
                </Col>
                <Col span={12} className='left' style={{color: '#ccc', padding: '4px'}}>
                    <span>{!canRemoveBase() && t('physicalServersPage.remove_inventory_text')}</span>
                </Col>
                <Col span={6}>

                </Col>
            </Row>
        </Form>
        </>
    )
}

export default ServerCaseForm
