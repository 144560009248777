import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {ResGroupModel} from "../models";
import {UpdateResGroupErrorAction, UpdateResGroupRequestAction, UpdateResGroupResponseAction} from "./index";


export default (params: ResGroupModel, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | UpdateResGroupRequestAction
                | UpdateResGroupResponseAction
                | UpdateResGroupErrorAction,
        ) => void,
    ) => {
        const request = new UpdateResGroupRequestAction(params)
        dispatch(request)
        protectedApiClient
            .put<ResGroupModel>(`/resource-group/${params.id}`, {id: params.id, name: params.name})
            .then((response) => {
                dispatch(new UpdateResGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateResGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
