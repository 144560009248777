import React, {FC, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-input.scss"
import {EditTwoTone} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {_renderDate, formatISO, SQL_DATE_FORMAT} from "../../common/fce";
import Button from "antd-button-color";
import {getLanguage} from "../../helpers/langHelpers";
import moment from "moment";

const validDate = (strDt) => {
    if (strDt) {
        try {
            return  new Date(strDt)
        }
        catch (e) {
            console.error('Date string => '+strDt)
            return undefined
        }
    }
    return undefined
}
const validDateStr = (strDt) => {
    if (strDt) {
        try {
            const dt = new Date(strDt)
            return _renderDate(dt)
        }
        catch (e) {
            console.error('Date string => '+strDt)
            return ''
        }
    }
    return ''
}

interface Props {
    htmlId: string
    format: string
    disabled: boolean
    required?: boolean
    initDateStr?: string
    minDateStr?: string
    onChange: (sdate: string) => void
    customInput?: JSX.Element
    trigger?: number
}

const DateInput = (props: Props) => {
    const {t} = useTranslation()
    const dateRef = React.useRef<any>(null)

    const [isDisabled, setDisabled] = useState<boolean>(props.disabled)
    const [isRequired, setRequired] = useState<boolean>(!!props.required)
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(validDate(props.initDateStr))
    const [selectedDateOrig, setSelectedDateOrig] = useState<Date>(props.initDateStr ? new Date(props.initDateStr) : new Date())
    const [selectedDateStr, setSelectedDateStr] = useState<string>(validDateStr(props.initDateStr))
    const [minDateStr, setMinDateStr] = useState<string | undefined>(validDateStr(props.minDateStr))
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (!selectedDate) {
            setSelectedDateStr('')
            props.onChange('')  // raise event
        }
        else {
            const sDate = formatISO(selectedDate)
            if (sDate != selectedDateStr) {
                setSelectedDateStr(sDate)
            }
            if (sDate != selectedDateStr) {
                props.onChange(sDate)  // raise event
            }
            setSelectedDateOrig(selectedDate)
        }
        // console.log('selectedDate changed: '+selectedDate)
    }, [selectedDate])

    useEffect(() => {
        if (props.initDateStr) {
            if (props.initDateStr != selectedDateStr) {
                setSelectedDate(new Date(props.initDateStr))
            }
        }
    }, [props.initDateStr])

    useEffect(() => {
        if (props.minDateStr) {
            if (props.minDateStr != minDateStr) {
                setMinDateStr(props.minDateStr)
            }
        }
    }, [props.minDateStr])

    const onKeyDownStart = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            dateRef.current.setOpen(false)
        }
    }

    const handleCalendarClose = () => {
        if (!selectedDate && isRequired) {
            // console.log("Calendar closed: "+ moment(selectedDateOrig).format(SQL_DATE_FORMAT))
            setSelectedDate(selectedDateOrig)
        }
    }

    return (
        <div className='DateInput' >
            <DatePicker locale={getLanguage()}
                        id={props.htmlId}
                        ref={dateRef}
                        customInput={props.customInput && props.customInput}
                        selected={selectedDate}
                        onChange={(dt) => dt && setSelectedDate(dt)}
                        onKeyDown={onKeyDownStart}
                        onCalendarClose={handleCalendarClose}
                        shouldCloseOnSelect={true}
                        dateFormat={props.format}   // ReactDatePicker is not using Moment formats! use d.M.yyyy
                        selectsStart
                        popperProps={{strategy: 'fixed'}}
                        className='date-input-box'
                        disabled={props.disabled}
                        minDate={props.minDateStr && validDate(props.minDateStr)}
                        tabIndex={1}
                        autoComplete='off'
                        placeholderText=''
            />

            <div className='date-input-side'>
                <Button title='Clear' size='small'
                        onClick={() => {
                            if (!props.disabled) {
                                setSelectedDate(undefined)
                                dateRef.current!.input.focus()
                            }
                        }}
                        disabled={props.disabled}
                        icon={<EditTwoTone twoToneColor='green' width={24} style={{ color: '#CC6666' }} />}
                        className='actionButton'
                        style={{ cursor: 'hand', marginTop: '5px', marginLeft: '6px', marginRight: 0, paddingRight: 0 }}
                />
            </div>
        </div>
    )
}

export default DateInput