import {
    GetCostAllocationsRequestAction,
    GetCostAllocationsResponseAction,
    GetCostAllocationsErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {CostAllocationCategoryDetails} from "../../costallocation/models";


export default (typ: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetCostAllocationsRequestAction
                | GetCostAllocationsResponseAction
                | GetCostAllocationsErrorAction,
        ) => void,
    ) => {
        const request = new GetCostAllocationsRequestAction()
        dispatch(request)

        protectedApiClient
            .get<CostAllocationCategoryDetails[]>(`/billing/cost-allocation-category?typ=${typ}`)
            .then((response) => {
                dispatch(new GetCostAllocationsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetCostAllocationsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
