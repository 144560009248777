import {Card, Col, message, Modal, Row, Space, Switch, Table} from "antd";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../common/models";
import React, {useEffect, useRef, useState} from "react";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import usePageSize from "../../../common/usePageSize";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import Button from "antd-button-color";
import {CheckOutlined, CloseOutlined, DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import {PlusCircleOutlined, ShopOutlined} from "@ant-design/icons/lib/icons";
import {DeleteDnsZoneParams, DnsRecordModel, DnsZoneModel, DomainModel, RemoveDnsRecordParams} from "./models";
import {Link, useParams} from "react-router-dom";
import getDomain from "./actions/getDomain";
import getDnsZone from "./actions/getDnsZone";
import getDnsService from "./actions/getDnsService";
import {getDnsServiceId, hasDnsService} from "./common";
import DnsRecordForm from "./DnsRecordForm";
import {useHistory} from "react-router";
import {sort_searchable} from "../../../common/sorting";
import removeDomainDnsRecord from "./actions/removeDomainDnsRecord";
import deleteDomainDnsZone from "./actions/deleteDomainDnsZone";
import useLogger from "../../../common/useLogger";

interface ParamTypes {
    domain_url: string
}

const { confirm } = Modal

const DnsRecordsPage = () => {
    const CONTROL_NAME = 'page_domain_dns_records'
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const {domain_url} = useParams<ParamTypes>()

    const {isLoadingZone, isLoadingDomain, domain, dnsZone, dnsRecords, dnsService} = useSelector((state: AppState) => state.dnsservice)

    const [dataSource, setDataSource] = useState<DnsRecordModel[]>()
    const [selectedRecord, setSelectedRecord] = useState<DnsRecordModel>()
    const [pageSize, setPageSize] = useState<string>()
    const [isRecordModalVisible, setRecordModalVisible] = useState(false)

    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    // permissions
    const [isDomainRecordsViewer, setDomainRecordsViewer] = useState(false)
    const [isDomainRecordsCreator, setDomainRecordsCreator] = useState(false)
    const [isDomainRecordsEditor, setDomainRecordsEditor] = useState(false)
    const [isDomainRecordsDeleter, setDomainRecordsDeleter] = useState(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }


    // settings
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = useLogger(appSetting, 'DnsRecordsPage')
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        // load servicetypes from API
        // trigger ONCE
        const access = loggedUser.hasAccess('page_domain_dns_records')
        setDomainRecordsViewer(access)
        setDomainRecordsCreator(loggedUser.hasAccess('page_domain_dns_records_create_button'))
        setDomainRecordsEditor(loggedUser.hasAccess('page_domain_dns_records_edit_button'))
        setDomainRecordsDeleter(loggedUser.hasAccess('page_domain_dns_records_delete_button'))
        if (!access) {
            // failover 403
            history.replace('/')
        }
        logger('domain_url:'+domain_url)
        dispatch(getDomain(domain_url))
    }, [])

    useEffect(() => {
        logger('domain_url:'+domain_url)
        if (domain && domain.name.toLowerCase() != domain_url.toLowerCase()) {
            domain && logger('domain:' + domain.name)
            dispatch(getDomain(domain_url))
        }
    }, [domain_url])

    useEffect(() => {
        if (domain && domain.name.toLowerCase() === domain_url.toLowerCase()) {
            dispatch(getDnsZone(domain.id))
            if (hasDnsService(domain)) {
                const sid = getDnsServiceId(domain)
                sid && dispatch(getDnsService(sid))
            }
        }
    }, [domain])


    useEffect(() => {
        logger('dnsZone: '+JSON.stringify(dnsZone))
        if (dnsZone && domain && domain.name === domain_url) {
            const data = dnsZone.records.map(rec => {return {...rec, searchableName: getRecordOrder(rec)}})
            setDataSource(data.sort(sort_searchable))
        }
    }, [dnsZone])

    const getRecordOrder = (rec: DnsRecordModel):string => {
        if (rec.type === 'SOA') {
            return '1'
        }
        if (rec.type === 'NS') {
            return '2'
        }
        return rec.type
    }

    const showConfirmDelete = (r: DnsRecordModel, title: string) => {
        if (!r || !domain) {
            return
        }

        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{r.type} {r.name} {r.content}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    const params: RemoveDnsRecordParams = {
                        id: domain.id,
                        domain: domain.name,
                        digest: r.digest!
                    }
                    dispatch(removeDomainDnsRecord(params, suc => {
                        dispatch(getDnsZone(domain.id))
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        suc && message.success(t('general.success'))
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }


    const showConfirmDeleteZone = (d: DomainModel | undefined, title: string) => {
        if (!d || !domain) {
            return
        }

        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{domain.name}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    const params: DeleteDnsZoneParams = {
                        id: domain.id,
                        zone: domain.name,
                    }
                    dispatch(deleteDomainDnsZone(params, suc => {
                        history.replace('/dns/domains')
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        suc && message.success(t('general.success'))
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }


    const columns = [
        {
            title: t('dnsPage.record_type'),
            key: 'type',
            dataIndex: 'type',
        },
        {
            title: t('dnsPage.record_name'),
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: t('dnsPage.record_content'),
            key: 'content',
            dataIndex: 'content',
        },
        {
            title: t('dnsPage.ttl'),
            key: 'ttl',
            dataIndex: 'ttl',
        },
        {
            title: t('general.actions'),
            key: 'action',
            width: '100px',
            dataIndex: 'action',
            render: (text: string, rec: DnsRecordModel) => (
                <Space size='small'>

                    <Button type='text' size='small'
                            disabled={!isDomainRecordsEditor}
                            onClick={() => {
                                setSelectedRecord(rec)
                                setRecordModalVisible(true)
                            }}
                            className='actionButton'
                            icon={<EditTwoTone twoToneColor={isDomainRecordsEditor ? "green" : "#ccc"} />}
                    />

                    <Button type='text' danger size='small'
                            disabled={!isDomainRecordsDeleter}
                            className='actionButton'
                            onClick={() => showConfirmDelete(rec, t('zonesPage.confirm_delete'))}
                            icon={<DeleteTwoTone twoToneColor={isDomainRecordsDeleter ? "red" : "#ccc"} />}
                    />

                </Space>
            ),
        },
    ]

    return (
		<>
			<Card
                title={
                    <Row>
                        <Col span={10}>
                            {t('dnsPage.domain') + ': '}<b>{domain_url}</b>
                            <Button type='text' danger size='small'
                                    className='actionButton'
                                    disabled={!isDomainRecordsEditor}
                                    onClick={() => showConfirmDeleteZone(domain, t('zonesPage.confirm_delete'))}
                                    icon={<DeleteTwoTone twoToneColor='red' title={t('dnsPage.remove_zone')}/>} />
                        </Col>
                        <Col span={8}>
                            {
                                dnsService && (
                                    <span style={{fontSize: '0.8em'}}>
                                DNS: <Link className='linkUnder' to={`/dns/services/${dnsService.id}`}>{dnsService.host}</Link>
                            </span>
                                )
                            }

                        </Col>
                        <Col span={6}><span style={{fontSize: '0.8em'}}>DNSSEC:&nbsp;</span>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={true}
                                disabled={true}
                                onChange={() => {}}
                            />
                        </Col>
                    </Row>
                }
				extra={
					<Button
						type='primary'
						disabled={!isDomainRecordsCreator}
						onClick={() => {
                            if (isDomainRecordsCreator) {
                                setSelectedRecord(undefined)
                                setRecordModalVisible(true)
                            }
						}}>
						<PlusCircleOutlined /> {t('dnsPage.create_record')}
					</Button>
				}
				className='DnsRecordPage'
				>
				{
					appSetting &&
					<Table<DnsRecordModel>
						rowClassName={() => 'highlight'}
						bordered={true}
						columns={columns}
						loading={isLoadingDomain || isLoadingZone}
						dataSource={dataSource}
						rowKey={(record) => `${record.type}-${record.name}-${record.content}`}
						showHeader={true}
						pagination={false}
						onChange={(ev) => {setPageSize(`${ev.pageSize}`)}}
					/>
				}

			</Card>

			<Modal
				destroyOnClose={true}
				style={{top: 20}}
				title={
					<>
						<ShopOutlined />{' '} {t('dnsPage.zone') } {' '} <b>{domain && domain.name}</b>
                    </>
				}
                width={600}
				visible={isRecordModalVisible}
				onCancel={() => setRecordModalVisible(false)}
				maskClosable={false}
				footer={null}>
				<DnsRecordForm
                    zone={domain?.name}
                    digest={selectedRecord?.digest}
                    type={selectedRecord?.type}
                    name={selectedRecord?.name}
                    content={selectedRecord?.content}
                    ttl={selectedRecord?.ttl}
                    is_new={!selectedRecord}
                    closeModal={() => setRecordModalVisible(false)}
				/>
			</Modal>

		</>
	)
}

export default DnsRecordsPage

