
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { BillVatSummaryModel } from '../models'
import {
  CreateBillVatSummaryErrorAction,
  CreateBillVatSummaryRequestAction,
  CreateBillVatSummaryResponseAction
} from "./index";

export default (
  params: BillVatSummaryModel,
  cb?: (isSuccess: boolean) => void,
) => {
  return (
    dispatch: (
      arg:
        | CreateBillVatSummaryRequestAction
        | CreateBillVatSummaryResponseAction
        | CreateBillVatSummaryErrorAction,
    ) => void,
  ) => {
    const request = new CreateBillVatSummaryRequestAction(params)
    dispatch(request)
    protectedApiClient
      .post<BillVatSummaryModel[]>(`/billing/bill/${params.bill_id}/bill-vat-summary`, params)
      .then((response) => {
        dispatch(new CreateBillVatSummaryResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new CreateBillVatSummaryErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
