import { UpdateCustomerSettingRequestAction, UpdateCustomerSettingResponseAction, UpdateCustomerSettingErrorAction } from "./index";
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {SettingsDetails, SettingsParams} from "../../settings/models";


export default (params: SettingsParams, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (args: UpdateCustomerSettingRequestAction | UpdateCustomerSettingResponseAction | UpdateCustomerSettingErrorAction) => void) => {
        const request = new UpdateCustomerSettingRequestAction(params)
        dispatch(request)

        const id = params['id']
        delete params['id']
        protectedApiClient.post<SettingsDetails>(`/customer/${id}/setting`, params)
            .then(response => {
                dispatch(new UpdateCustomerSettingResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch(error => {
                dispatch(new UpdateCustomerSettingErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}