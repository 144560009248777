import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    GetHistoryDetailsRequestAction,
    GetHistoryDetailsResponseAction,
    GetHistoryDetailsErrorAction,
} from '.'
import {LogRecord} from "../../../pages/logviewer/models";


export default (request_id: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetHistoryDetailsRequestAction
                | GetHistoryDetailsResponseAction
                | GetHistoryDetailsErrorAction,
        ) => void,
    ) => {
        const request = new GetHistoryDetailsRequestAction()
        dispatch(request)
        let url = '/history/model/' + request_id
        console.log(url)

        protectedApiClient
            .get<LogRecord[]>(url)
            .then((response) => {
                dispatch(
                    new GetHistoryDetailsResponseAction(request, response.data),
                )
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetHistoryDetailsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
