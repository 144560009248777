import {AuthState} from "../pages/login/models";
import {UserDetails} from "../pages/user/models";
import {CustomerDetails} from "../pages/billing/customer/models";
import {CompanyDetails} from "../pages/company/models";
import {ILoggedUser} from "./models";
import {SettingsDetails} from "../pages/settings/models";
import AppSettings from "./AppSettings";
import {_isAllowed} from "./fce";
import {ResGroupModel} from "../pages/rights/resources/models";
import {UserGroupModel} from "../pages/rights/usergroups/models";

export const SUPER_ROLE = 'zcom-root'

export const ROLE_USER_BASE = 'user-base'
export const MINIMAL_ROLE = ROLE_USER_BASE

export const ROLE_USER_ZCOM = 'zcom-staff'
export const ROLE_COMPANY_MANAGER = 'company-manager'
export const ROLE_ZCOM_USER_MANAGER = 'zcom-staff-manager'
export const ROLE_USER_MANAGER = 'customer-user-manager'
export const ROLE_CUSTOMER_MANAGER = 'customer-manager'
export const ROLE_CUSTOMER_SERVICE_EDITOR = 'customer-service-editor'
export const ROLE_ACCESS_MANAGER = 'access-manager'
export const ROLE_APP_ADMIN = 'global-zis-admin'
export const ROLE_CUSTOMER_OWNER = 'customer-owner'

export const ROLE_BILLING_VIEWER = 'billing-viewer'
export const ROLE_BILLING_EDITOR = 'billing-admin'

export const ROLE_ZCOM_ADMIN = 'zcom-admin'


class LoggedUser implements ILoggedUser {
    public authenticated: boolean
    public token?: string
    public user: UserDetails
    public groups: UserGroupModel[]
    public roles: string[]
    public resourceGroups: ResGroupModel[]
    public customer: CustomerDetails
    public company: CompanyDetails
    public validCustomers: CustomerDetails[]
    public page_permissions: {}
    public settings: SettingsDetails[]
    public originUser?: UserDetails
    public originToken?: string

    data: AuthState
    hasSuperRole: boolean

    constructor(data: AuthState) {
        // constructor raises an error when something is wrong
        this.data = data
        if (!data) {
            throw Error('LoggedUser: Data is empty.')
        }
        this.authenticated = data.authenticated
        if (!data.authenticated || data.self === undefined) {
            throw Error('LoggedUser: User is not authenticated')
        }
        if (!data.self.roles) {
            throw Error('LoggedUser: User has no roles')
        }
        if (data.self_customer === undefined || data.self_customer.company === undefined) {
            throw Error('LoggedUser: User has no Customer or Company')
        }

        this.user = data.self
        if (data.token) {
            this.token = data.token
        }
        this.groups = data.self.groups
        this.roles = data.self.roles
        this.resourceGroups = data.self.resourceGroups ? data.self.resourceGroups : []
        this.customer = data.self_customer
        this.company = data.self_customer.company
        this.validCustomers = data.self.validCustomers && data.self.validCustomers.length ? data.self.validCustomers : [data.self_customer]
        this.hasSuperRole = this.roles.includes(SUPER_ROLE)
        this.page_permissions = data.menu_roles ? data.menu_roles : {}
        this.settings = data.self_settings
        if (data.origin_token) {
            this.originUser = data.origin_user
            this.originToken = data.origin_token
        }
    }

    public getData(): AuthState {
        return this.data
    }

    public isLoaded(): boolean  {
        // returns TRUE if the user data is loaded
        return (this.user && this.customer && this.company && this.settings && this.settings.length > 0)
    }

    public isOriginUser(): boolean  {
        // returns TRUE if the current user is the real user (not used LoginAs somebody else)
        if(!this.originToken) {
            return true
        }
        return this.originToken === this.token
    }

    public hasRole(name: string): boolean  {
        // return true is user has rights
        if (this.hasSuperRole) {
            return true     // zcom-root can access always
        }
        return this.roles.includes(name)
    }

    public hasAccess(ctrlName: string): boolean  {
        // returns true if the user has access to control(page)
        const neededRoles = this.page_permissions[ctrlName]
        return this.isAllowed(neededRoles)
    }

    public isAllowed(neededRoles: string[]): boolean  {
        // returns true if the user has access to control(page)
        return _isAllowed(neededRoles, this.roles) // TODO false
    }

    public getAppSettings(): AppSettings {
        // create appSettings from settings
        if (!this.settings || !this.page_permissions || this.settings.length === 0) {
            // it is good for using general fce like: renderPrice()
            // it uses default values
            return new AppSettings([], [])
        }
        return new AppSettings(this.settings, this.page_permissions)
    }
}

export default LoggedUser