import {
  GetInvoiceDocumentByInvoiceRequestAction,
  GetInvoiceDocumentByInvoiceResponseAction,
  GetInvoiceDocumentByInvoiceErrorAction,
} from '../../invoicedocument/actions'
import { protectedApiClient } from 'helpers/api'
import { InvoiceDocumentDetails } from '../../invoicedocument/models'

export default (invoice_id: number, cb?: (isSuccess: boolean, data: InvoiceDocumentDetails[] | undefined) => void) => {
  return (
    dispatch: (
      arg:
        | GetInvoiceDocumentByInvoiceRequestAction
        | GetInvoiceDocumentByInvoiceResponseAction
        | GetInvoiceDocumentByInvoiceErrorAction,
    ) => void,
  ) => {
    const request = new GetInvoiceDocumentByInvoiceRequestAction(invoice_id)
    dispatch(request)

    protectedApiClient
      .get<InvoiceDocumentDetails[]>(
        `/billing/invoice/${invoice_id}/invoice-document`,
      )
      .then((response) => {
        dispatch(new GetInvoiceDocumentByInvoiceResponseAction(request, response.data))
        cb && cb(true, response.data)
      })
      .catch((error) => {
        dispatch(new GetInvoiceDocumentByInvoiceErrorAction(request, error))
        // handleApiErrorWithNotification(error)
        cb && cb(false, undefined)
      })
  }
}
