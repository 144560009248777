import {useForm} from "antd/lib/form/Form"
import {useTranslation} from "react-i18next"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Button, Form, Input, message, Select, Space, Spin, Switch, Tag} from "antd"
import {formItemLayout, tailLayout} from "../../../helpers/layoutHelpers"
import {CheckOutlined, CloseOutlined} from "@ant-design/icons"
import {SettingsDetails} from "../models"
import {AppState} from "../../../common/models"
import {useLoggedUser} from "../../../helpers/loginUserHelper"
import updateSetting from "../../login/actions/updateSetting"
import {_toInt} from "../../../common/fce";


interface Props {
    settingToUpdate?: SettingsDetails
    setModalVisible: (param: boolean) => void
}



const SettingForm = ({settingToUpdate, setModalVisible}: Props) => {
    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch();

    const {self_settings} = useSelector((state: AppState) => state.auth)
    const {fontSize} = useSelector((state: AppState) => state.font)
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [frozen, setFrozen] = useState(true)
    const [hidden, setHidden] = useState(true)
    const [scope, setScope] = useState<string>('user')
    const [scopeOptions, setScopeOptions] = useState<{ label: string, value: string }[]>([])
    const [dataToUpdate, setDataToUpdate] = useState<SettingsDetails | undefined>(settingToUpdate)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()

    // permissions
    const [editApp, setEditApp] = useState(false)
    const [editCustomer, setEditCustomer] = useState(false)

    useEffect(() => {
        // when user logged => set permissions
        if (loggedUser) {
            let items = [{label: 'User only', value: 'user'}]
            const _app = loggedUser.hasRole('zcom-admin')
            setEditApp(_app)
            if (_app) {
                items.push({label: 'Application', value: 'app'})
            }
            const _cust = loggedUser.hasRole('billing-customer')
            setEditCustomer(_cust)
            if (_cust) {
                items.push({label: 'Customer', value: 'customer'})
            }
            setScopeOptions(items)
        }
    }, [])

    useEffect(() => {
        if (dataToUpdate) {
            setFrozen(dataToUpdate.frozen === 1)
            setHidden(dataToUpdate.hidden === 1)
            console.log('dataToUpdate: '+JSON.stringify(dataToUpdate))
        }
    }, [dataToUpdate])

    useEffect(() => {
        console.log('Scope: '+scope)
        const newItem = self_settings.find(s => (s.name === dataToUpdate?.name && s.scope === scope))
        if (newItem) {
            console.log('found: newItem')
            setDataToUpdate(newItem)
            if (form) {
                form.setFieldsValue(newItem)
            }
        }
        else {
            console.log('not found')
            console.log(JSON.stringify(self_settings))
        }
    }, [scope])

    const onFormSubmit = (values) => {
        onFinish({...values, frozen: 0, hidden: 0, scope: 'user', object_id: loggedUser.user.id })
    }

    const onFormSubmitAdmin = (values) => {
        let object_id
        if (values.scope === 'user') {
            object_id = loggedUser.user.id
        }
        if (values.scope === 'customer') {
            object_id = loggedUser.user.customer_id
        }
        const h = editApp ? _toInt(hidden) : 0   // only app level can set hidden
        onFinish({...values, frozen: _toInt(frozen), hidden: h, object_id: object_id})
    }

    const onFinish = (values: any) => {
        if (dataToUpdate) {
            dispatch(
                updateSetting({...values, id: dataToUpdate.id}, (suc: boolean) => {
                    if (suc) {
                        message.success(t('settingsPage.edit_done'))
                    } else {
                        message.error(t('settingsPage.error.update'))
                    }
                    setModalVisible(false)
                }),
            )
        }
    }

    const changeScope = (value: string) => {
        setScope(value)
        setUpdateStatus(true)
    }

    if (dataToUpdate) {
        if (editCustomer || editApp) {
            return (
                <Form {...formItemLayout} onFinish={onFormSubmitAdmin} form={form} onChange={() => setUpdateStatus(true)}>
                    <Form.Item name='name' label={t('settingsPage.name')}
                               rules={[{required: true, message: t('errors.field_required')}]}
                               initialValue={dataToUpdate.name}>
                        <Space>
                            <Form.Item noStyle>
                                <h2>{dataToUpdate.name}</h2>
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name='group' label={t('settingsPage.group')}
                               rules={[{required: true, message: t('errors.field_required')}]}
                               initialValue={dataToUpdate.group}>
                        <Input />
                    </Form.Item>

                    <Form.Item name='value' label={t('settingsPage.value')}
                               rules={[{required: true, message: t('errors.field_required')}]}
                               initialValue={dataToUpdate.value}>
                        <Input />
                    </Form.Item>

                    <Form.Item name='scope' label={t('settingsPage.scope')}
                               initialValue='user'>
                        <Select
                            optionFilterProp='label'
                            size={fontSize}
                            style={{width: '120px'}}
                            onChange={changeScope}
                            options={scopeOptions}
                        />
                    </Form.Item>

                    <Form.Item label={t('settingsPage.frozen')} valuePropName='frozen' initialValue={dataToUpdate.frozen === 1}>
                        <Form.Item name="frozen" noStyle>
                            <Switch
                                checkedChildren={<CheckOutlined/>}
                                unCheckedChildren={<CloseOutlined/>}
                                checked={frozen}
                                onChange={() => {setFrozen(!frozen); setUpdateStatus(true)}}
                            />
                        </Form.Item>
                    </Form.Item>
                    {
                        editApp &&
                        <Form.Item label={t('settingsPage.hidden')} valuePropName='hidden' initialValue={dataToUpdate.hidden === 1}>
                            <Form.Item name="frozen" noStyle>
                                <Switch
                                    checkedChildren={<CheckOutlined/>}
                                    unCheckedChildren={<CloseOutlined/>}
                                    checked={hidden}
                                    onChange={() => [setHidden(!hidden), setUpdateStatus(true)]}
                                />
                            </Form.Item>
                        </Form.Item>
                    }

                    <Form.Item {...tailLayout}>
                        <Button type='primary' htmlType='submit' className='login-form-button'
                                size={fontSize} disabled={!updateStatus}>
                            {t('settingsPage.save')}
                        </Button>
                    </Form.Item>
                </Form>
            )
        }
        else {
            return (
                <Form {...formItemLayout} onFinish={onFormSubmit} form={form} onChange={() => setUpdateStatus(true)}>
                    <Form.Item name='name' label={t('settingsPage.name')}
                               rules={[{required: true, message: t('errors.field_required')}]}
                               initialValue={dataToUpdate.name}>
                        <Space>
                            <Form.Item name="name" noStyle>
                                <h2>{dataToUpdate.name}</h2>
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item name='value' label={t('settingsPage.value')}
                               rules={[{required: true, message: t('errors.field_required')}]}
                               initialValue={dataToUpdate.value}>
                        <Input/>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type='primary' htmlType='submit' className='login-form-button'
                                size={fontSize} disabled={!updateStatus}>
                            {t('settingsPage.save')}
                        </Button>
                    </Form.Item>
                </Form>
            )
        }

    }
    else {
        return (
            <Spin/>
        )
    }
}

export default SettingForm
