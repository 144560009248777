import {
    GetInventoryBillsRequestAction,
    GetInventoryBillsResponseAction,
    GetInventoryBillsErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {BillModel} from "../../../billing/bill/models";


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetInventoryBillsRequestAction | GetInventoryBillsResponseAction | GetInventoryBillsErrorAction,
        ) => void,
    ) => {
        const request = new GetInventoryBillsRequestAction()
        dispatch(request)

        protectedApiClient
            .get<BillModel[]>(`/company/company/${id}/bill`)
            .then((response) => {
                dispatch(new GetInventoryBillsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetInventoryBillsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
