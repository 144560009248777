import { ActionType, CostAllocationActions } from './actions'
import { AsyncActionMode } from 'common/models'
import { CostAllocationCategoryDetails, CostAllocationCategoryState } from './models'

const COSTALLOCATION_INITIAL_STATE: CostAllocationCategoryState = {
  isLoading: false,
  isSaving: false,
  error: undefined,
  costAllocationCategories: []
}

export default (
  state = COSTALLOCATION_INITIAL_STATE,
  action: CostAllocationActions,
): CostAllocationCategoryState => {
  switch (action.type) {
    case ActionType.GET_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          costAllocationCategory: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          costAllocationCategories: state.costAllocationCategories?.concat(action.data),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_COSTALLOCATIONS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          costAllocationCategories: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          costAllocationCategories: state.costAllocationCategories!.filter(
            (allocation) => allocation.id !== action.request.id,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        console.log(action)
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.UPDATE_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          costAllocationCategories: state.costAllocationCategories!.map((allocation) =>
            allocation.id === action.data.id ? { ...action.data } : allocation,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.SET_DEFAULT_COSTALLOCATION:

      return {
        ...state,
        isLoading: false,
        costAllocationCategory: undefined
      }
      break
  }
  return state
}
