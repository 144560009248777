import React, {useEffect} from 'react'
import {Card} from 'antd'
import {AppState} from 'common/models'
import {useSelector} from 'react-redux'

interface Props {
    lid: string
    setModalVisible: (param: boolean) => void
}

const LogForm = ({lid, setModalVisible}: Props) => {

    const {record} = useSelector((state: AppState) => state.logs)

    useEffect(() => {

    }, [])

    return (
        <Card title={null} >
            <pre style={{width: '100%'}}>
                {JSON.stringify(record, null, 2)}
            </pre>
            <div style={{height: '30px', width: '100%'}}>

            </div>
        </Card>
    )
}

export default LogForm
