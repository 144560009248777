import {ActionType, PhysicalServerActions, RemoveInventoryBaseRequestAction} from './actions'
import {AsyncActionMode, PAGE1} from 'common/models'
import {PhysicalServerModel, PhysicalServerPageState} from './models'


const PHYSICALSERVER_INITIAL_STATE: PhysicalServerPageState = {
  isLoading: false,
  isSaving: false,
  physicalServer: undefined,
  pager: PAGE1,
  physicalServers: {items: [], pagination: PAGE1},
  newServers: [],
  newServersIsLoading: false,
  all_components: [],
  all_comp_loading: false,
  lookup_ident: [],
  lookup_conf: [],
  lookup_inv_no: [],
  isLoadingLookup: false,
  error: ''
}

export default (
  state = PHYSICALSERVER_INITIAL_STATE,
  action: PhysicalServerActions,
): PhysicalServerPageState => {
  switch (action.type) {
    case ActionType.REMOVE_PHYSICALSERVER_FROM_RACK:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          physicalServers: {
            ...state.physicalServers,
            items: state.physicalServers.items!.map((s) =>
                s.id === action.data.id ? action.data : s,
            )},
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.SWAP_COMPONENT_IN_PHYSICALSERVER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          physicalServer: action.data,
          physicalServers: {
            ...state.physicalServers,
            items: state.physicalServers.items!.map((s) =>
                s.id === action.request.payload.physical_server_id ? action.data : s,
            )},
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.SET_PHYSICALSERVER_RACK:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          physicalServers: {
            ...state.physicalServers,
            items: state.physicalServers.items!.map((s) =>
                s.id === action.data.id ? action.data : s,
            )},
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.REMOVE_COMPONENT_FROM_PHYSICALSERVER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          physicalServer: action.data,
          physicalServers: {
            ...state.physicalServers,
            items: state.physicalServers.items!.map((s) =>
                s.id === action.request.payload.physical_server_id ? action.data : s,
            )},
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.REMOVE_BASE_FROM_PHYSICALSERVER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          physicalServer: action.data,
          physicalServers: {
            ...state.physicalServers,
            items: state.physicalServers.items!.map((s) =>
                s.id === action.request.serverId ? action.data : s,
            )},
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.ADD_COMPONENT_TO_PHYSICALSERVER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSaving: false,
          physicalServer: action.data,
          physicalServers: {
            ...state.physicalServers,
            items: state.physicalServers.items!.map((s) =>
                s.id === action.request.payload.physical_server_id ? action.data : s,
            )},
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_PHYSICALSERVER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          physicalServer: action.data,
          physicalServers: {
            ...state.physicalServers,
            items: state.physicalServers.items!.map((s) =>
                s.id === action.data.id ? action.data : s,
            )},
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_PHYSICALSERVER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          physicalServers: {
            ...state.physicalServers,
            items: state.physicalServers.items.concat(action.data)},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_PHYSICALSERVERS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          pager: {...action.data.pagination, page: action.data.pagination.page+1},
          physicalServers: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_NEW_SERVERS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, newServersIsLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          newServersIsLoading: false,
          newServers: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          newServersIsLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_ALL_COMPONENTS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, all_components: [] }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          all_components: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
    case ActionType.GET_ALL_COMPONENTS_TYPES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          all_comp_loading: true,
          all_components: []
        }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          all_comp_loading: false,
          all_components: action.data.inventory ? action.data.inventory : [],
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          all_comp_loading: false,
          error: action.error,
        }
      }
      break
    case ActionType.CLEAR_ALL_COMPONENTS_TYPES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          all_components: []
        }
      }
      break
    case ActionType.MOVE_PHYSICALSERVER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          physicalServers: {
            ...state.physicalServers,
            items: state.physicalServers.items!.map((s) =>
                s.id === action.data.id ? action.data : s,
            )},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.DELETE_PHYSICALSERVER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          physicalServer: undefined,
          physicalServers: {
            ...state.physicalServers,
            items: state.physicalServers.items.filter((s) => s.id != action.request.id)},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_PHYSICALSERVER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        const sid = action.data.id
        const obj: PhysicalServerModel = action.data
        obj.inventory = state.physicalServers.items.find(s => s.id === sid)?.inventory
        return {
          ...state,
          physicalServer: {...obj},
          physicalServers: {
            ...state.physicalServers,
            items: state.physicalServers.items!.map((s) =>
                {
                  if (s.id === action.data.id) {
                    if (obj) {
                      // console.log('inventory: '+JSON.stringify(obj))
                      return obj;
                    }
                  }
                  return s;
                }
            )},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.LOOKUP_PHYSICALSERVER:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          isLoadingLookup: true,
          lookup_ident:[]
        }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        if (action.field === 'ident') {
          return {
            ...state,
            lookup_ident: action.data,
            isLoadingLookup: false
          }
        }
        if (action.field === 'conf') {
          return {
            ...state,
            lookup_conf: action.data,
            isLoadingLookup: false
          }
        }
        if (action.field === 'inv_no') {
          return {
            ...state,
            lookup_inv_no: action.data,
            isLoadingLookup: false
          }
        }
        console.error('Wrong action field.')
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isLoadingLookup: false
        }
      }
      break
  }
  return state
}
