import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {RoleModel, RoleParams} from "../models";
import {UpdateRoleErrorAction, UpdateRoleRequestAction, UpdateRoleResponseAction} from "./index";


export default (params: RoleParams, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | UpdateRoleRequestAction
                | UpdateRoleResponseAction
                | UpdateRoleErrorAction,
        ) => void,
    ) => {
        const request = new UpdateRoleRequestAction(params)
        dispatch(request)
        const casbin_key = params.casbin_key
        protectedApiClient
            .put<RoleModel>(`/role/${casbin_key}`, {casbin_key: casbin_key, description: params.description})
            .then((response) => {
                dispatch(new UpdateRoleResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateRoleErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
