import { AsyncActionMode } from 'common/models'
import {ContactBase, ContactModel, CustomerDetails, CustomerParams, CustomerTerminatedParams} from '../models'
import {UserDetails} from "../../../user/models";


export enum ActionType {
  GET_CUSTOMER = 'GET_CUSTOMER',
  GET_CUSTOMERS = 'GET_CUSTOMERS',
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  DELETE_CUSTOMER = 'DELETE_CUSTOMER',
  GET_CUSTOMER_SELF = 'GET_CUSTOMER_SELF',
  GET_SOFT_DELETE = "GET_SOFT_DELETE",
  CLEAR_CUSTOMER = 'CLEAR_CUSTOMER',
  GET_CUSTOMER_USERS = 'GET_CUSTOMER_USERS',
  FLAG_UP = "FLAG_UP",
  SET_TERMINATED = "SET_TERMINATED",
  GET_CONTACT = 'GET_CONTACT',
  GET_CONTACTS = 'GET_CONTACTS',
  GET_CONTACTS_ALL = 'GET_CONTACTS_ALL',
  CREATE_CONTACT = 'CREATE_CONTACT',
  UPDATE_CONTACT = 'UPDATE_CONTACT',
  DELETE_CONTACT = 'DELETE_CONTACT',
}

export type CustomerActions =
    | GetCustomerRequestAction
    | GetCustomerResponseAction
    | GetCustomerErrorAction
    | GetCustomersRequestAction
    | GetCustomersResponseAction
    | GetCustomersErrorAction
    | CreateCustomerRequestAction
    | CreateCustomerResponseAction
    | CreateCustomerErrorAction
    | UpdateCustomerRequestAction
    | UpdateCustomerResponseAction
    | UpdateCustomerErrorAction
    | DeleteCustomerRequestAction
    | DeleteCustomerResponseAction
    | DeleteCustomerErrorAction
    | GetSoftDeleteRequestAction
    | GetSoftDeleteResponseAction
    | GetSoftDeleteErrorAction
    | ClearCustomerRequestAction
    | GetCustomerUsersRequestAction
    | GetCustomerUsersResponseAction
    | GetCustomerUsersErrorAction
    | SetTerminatedRequestAction
    | SetTerminatedResponseAction
    | SetTerminatedErrorAction
    | FlagUpRequestAction
    | FlagUpResponseAction
    | FlagUpErrorAction
    | GetContactRequestAction
    | GetContactResponseAction
    | GetContactErrorAction
    | GetContactsRequestAction
    | GetContactsResponseAction
    | GetContactsErrorAction
    | GetContactsAllRequestAction
    | GetContactsAllResponseAction
    | GetContactsAllErrorAction
    | CreateContactRequestAction
    | CreateContactResponseAction
    | CreateContactErrorAction
    | UpdateContactRequestAction
    | UpdateContactResponseAction
    | UpdateContactErrorAction
    | DeleteContactRequestAction
    | DeleteContactResponseAction
    | DeleteContactErrorAction

export class SetTerminatedRequestAction {
  readonly type = ActionType.SET_TERMINATED
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: CustomerTerminatedParams) {}
}
export class SetTerminatedResponseAction {
  readonly type = ActionType.SET_TERMINATED
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: SetTerminatedRequestAction,
      public data: CustomerDetails,
  ) {}
}
export class SetTerminatedErrorAction {
  readonly type = ActionType.SET_TERMINATED
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: SetTerminatedRequestAction,
      public error: string,
  ) {}
}

export class FlagUpRequestAction {
  readonly type = ActionType.FLAG_UP
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {}
}
export class FlagUpResponseAction {
  readonly type = ActionType.FLAG_UP
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: FlagUpRequestAction,
      public data: CustomerDetails,
  ) {}
}
export class FlagUpErrorAction {
  readonly type = ActionType.FLAG_UP
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: FlagUpRequestAction,
      public error: string,
  ) {}
}

export class ClearCustomerRequestAction {
  readonly type = ActionType.CLEAR_CUSTOMER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {}
}

export class DeleteCustomerRequestAction {
  readonly type = ActionType.DELETE_CUSTOMER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}
export class DeleteCustomerResponseAction {
  readonly type = ActionType.DELETE_CUSTOMER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: DeleteCustomerRequestAction,
      public data: CustomerDetails,
  ) {}
}
export class DeleteCustomerErrorAction {
  readonly type = ActionType.DELETE_CUSTOMER
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: DeleteCustomerRequestAction,
      public error: string,
  ) {}
}

export class UpdateCustomerRequestAction {
  readonly type = ActionType.UPDATE_CUSTOMER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: CustomerParams) {}
}
export class UpdateCustomerResponseAction {
  readonly type = ActionType.UPDATE_CUSTOMER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: UpdateCustomerRequestAction,
      public data: CustomerDetails,
  ) {}
}
export class UpdateCustomerErrorAction {
  readonly type = ActionType.UPDATE_CUSTOMER
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: UpdateCustomerRequestAction,
      public error: string,
  ) {}
}

export class GetCustomerRequestAction {
  readonly type = ActionType.GET_CUSTOMER
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetCustomerResponseAction {
  readonly type = ActionType.GET_CUSTOMER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetCustomerRequestAction,
      public data: CustomerDetails,
  ) {}
}
export class GetCustomerErrorAction {
  readonly type = ActionType.GET_CUSTOMER
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: GetCustomerRequestAction, public error: string) {}
}

export class GetCustomersRequestAction {
  readonly type = ActionType.GET_CUSTOMERS
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetCustomersResponseAction {
  readonly type = ActionType.GET_CUSTOMERS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetCustomersRequestAction,
      public data: CustomerDetails[],
  ) {}
}
export class GetCustomersErrorAction {
  readonly type = ActionType.GET_CUSTOMERS
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetCustomersRequestAction,
      public error: string,
  ) {}
}

export class CreateCustomerRequestAction {
  readonly type = ActionType.CREATE_CUSTOMER
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: CustomerParams) {}
}
export class CreateCustomerResponseAction {
  readonly type = ActionType.CREATE_CUSTOMER
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: CreateCustomerRequestAction,
      public data: CustomerDetails,
  ) {}
}
export class CreateCustomerErrorAction {
  readonly type = ActionType.CREATE_CUSTOMER
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: CreateCustomerRequestAction,
      public error: string,
  ) {}
}

// Customer softdelete action
export class GetSoftDeleteRequestAction {
  readonly type = ActionType.GET_SOFT_DELETE
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetSoftDeleteResponseAction {
  readonly type = ActionType.GET_SOFT_DELETE
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetSoftDeleteRequestAction,
      public data: CustomerDetails,
  ) {}
}
export class GetSoftDeleteErrorAction {
  readonly type = ActionType.GET_SOFT_DELETE
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: GetSoftDeleteRequestAction, public error: string) {}
}

export class GetCustomerUsersRequestAction {
  readonly type = ActionType.GET_CUSTOMER_USERS
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetCustomerUsersResponseAction {
  readonly type = ActionType.GET_CUSTOMER_USERS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetCustomerUsersRequestAction,
      public data: UserDetails[],
  ) {}
}
export class GetCustomerUsersErrorAction {
  readonly type = ActionType.GET_CUSTOMER_USERS
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetCustomerUsersRequestAction,
      public error: string,
  ) {}
}





export class GetContactRequestAction {
  readonly type = ActionType.GET_CONTACT
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetContactResponseAction {
  readonly type = ActionType.GET_CONTACT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetContactRequestAction,
      public data: ContactModel,
  ) {}
}
export class GetContactErrorAction {
  readonly type = ActionType.GET_CONTACT
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: GetContactRequestAction, public error: string) {}
}

export class GetContactsRequestAction {
  readonly type = ActionType.GET_CONTACTS
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetContactsResponseAction {
  readonly type = ActionType.GET_CONTACTS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetContactsRequestAction,
      public data: ContactModel[],
  ) {}
}
export class GetContactsErrorAction {
  readonly type = ActionType.GET_CONTACTS
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetContactsRequestAction,
      public error: string,
  ) {}
}


export class GetContactsAllRequestAction {
  readonly type = ActionType.GET_CONTACTS_ALL
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetContactsAllResponseAction {
  readonly type = ActionType.GET_CONTACTS_ALL
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: GetContactsAllRequestAction,
      public data: ContactModel[],
  ) {}
}
export class GetContactsAllErrorAction {
  readonly type = ActionType.GET_CONTACTS_ALL
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: GetContactsAllRequestAction,
      public error: string,
  ) {}
}

export class CreateContactRequestAction {
  readonly type = ActionType.CREATE_CONTACT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: ContactBase) {}
}
export class CreateContactResponseAction {
  readonly type = ActionType.CREATE_CONTACT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: CreateContactRequestAction,
      public data: ContactModel,
  ) {}
}
export class CreateContactErrorAction {
  readonly type = ActionType.CREATE_CONTACT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: CreateContactRequestAction,
      public error: string,
  ) {}
}

export class UpdateContactRequestAction {
  readonly type = ActionType.UPDATE_CONTACT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: ContactModel) {}
}
export class UpdateContactResponseAction {
  readonly type = ActionType.UPDATE_CONTACT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: UpdateContactRequestAction,
      public data: ContactModel,
  ) {}
}
export class UpdateContactErrorAction {
  readonly type = ActionType.UPDATE_CONTACT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: UpdateContactRequestAction,
      public error: string,
  ) {}
}


export class DeleteContactRequestAction {
  readonly type = ActionType.DELETE_CONTACT
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}
export class DeleteContactResponseAction {
  readonly type = ActionType.DELETE_CONTACT
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: DeleteContactRequestAction,
      public data: ContactModel,
  ) {}
}
export class DeleteContactErrorAction {
  readonly type = ActionType.DELETE_CONTACT
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: DeleteContactRequestAction,
      public error: string,
  ) {}
}