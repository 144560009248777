import React, {useEffect, useRef, useState} from 'react'
import {Button, Form, Input, Checkbox, message, Divider, Tag, InputRef, Select, Row, Col, Alert} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {InventoryTypeModel} from "./models";
import updateType from "./actions/updateType";
import createType from "./actions/createType";
import {PlusOutlined} from "@ant-design/icons";
import getTypes from "./actions/getTypes";
import Zalert from "../../../components/Messages/Zalert";


interface Props {
    dataToUpdate?: InventoryTypeModel
    setModalVisible: (param: boolean) => void
}

const {Item} = Form

const InventoryTypeForm = ({dataToUpdate, setModalVisible}: Props) => {

    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {inventory_types, isLoading} = useSelector((state: AppState) => state.inventorytype)
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)

    const [selectedUsable, setSelectedUsable] = useState<string>('');
    const [parameters, setParameters] = useState<string[]>([]);
    const [usableIds, setUsableIds] = useState<number[]>([]);
    const [typesOptions, setTypesOptions] = useState<{label: string, value: number}[]>([]);

    const [inputVisible, setInputVisible] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [])

    useEffect(() => {
        if (inventory_types) {
            if (dataToUpdate && dataToUpdate.usableIds) {
                setTypesOptions(inventory_types.filter(t2 => dataToUpdate.usableIds && !dataToUpdate.usableIds.includes(t2.id) ).map( t => {return {label: t.name, value: t.id}}))
            }
            else {
                setTypesOptions(inventory_types.map( t => {return {label: t.name, value: t.id}}))
            }
        }
    }, [inventory_types, dataToUpdate])

    useEffect(() => {
        // load keys and usableIds
        if (dataToUpdate) {
            (dataToUpdate.keys && dataToUpdate.keys.length) ? setParameters(dataToUpdate.keys) : setParameters([])
            dataToUpdate.usableIds ? setUsableIds(dataToUpdate.usableIds) : setUsableIds([])
        }
        else {
            setParameters([])
            setUsableIds([])
        }
    }, [dataToUpdate])


    const onFormSubmit = (values: Store) => {
        // save data
        console.log('Submit '+JSON.stringify(values))
        if (dataToUpdate && dataToUpdate.id > 0) {
            const param1 = {
                id: dataToUpdate.id,
                name: values.name,
                is_server_case: values.is_server_case ? 1 : 0,
                is_server_component: values.is_server_component ? 1 : 0,
                is_rack: values.is_rack ? 1 : 0,
                is_rack_equipment: values.is_rack_equipment ? 1 : 0,
                is_blade_container: values.is_blade_container ? 1 : 0,
                is_blade_module: values.is_blade_module ? 1 : 0,
                has_management_ip: values.has_management_ip ? 1 : 0,
                keys: parameters && parameters.length ? parameters : [],
                usableIds: usableIds && usableIds.length ? usableIds : []
            }
            dispatch(updateType(param1, (suc: boolean) => {
                if (suc) {
                    dispatch(getTypes(suc => {setModalVisible(false)}))
                    message.success(t('general.success'))
                }
            }))
        } else {
            const param2 = {
                name: values.name,
                is_server_case: values.is_server_case ? 1 : 0,
                is_server_component: values.is_server_component ? 1 : 0,
                is_rack: values.is_rack ? 1 : 0,
                is_rack_equipment: values.is_rack_equipment ? 1 : 0,
                is_blade_container: values.is_blade_container ? 1 : 0,
                is_blade_module: values.is_blade_module ? 1 : 0,
                has_management_ip: values.has_management_ip ? 1 : 0,
                keys: parameters && parameters.length ? parameters : [],
                usableIds: usableIds && usableIds.length ? usableIds : []
            }
            dispatch(createType(param2, (suc: boolean) => {
                    if (suc) {
                        dispatch(getTypes(suc => {setModalVisible(false)}))
                        message.success(t('general.success'))
                    }
                }
            ))
        }
    }


    const handleCloseParameter = (removedTag: string) => {
        const newParameters = parameters.filter(tag => tag !== removedTag)
        setParameters(newParameters)
        setUpdateStatus(true)
    }

    const handleCloseUsable = (tagId: number) => {
        const newUsables = usableIds.filter(tag => tag !== tagId)
        setUsableIds(newUsables)
        setUpdateStatus(true)
    }

    const renderUsable = (tagId: number) => {
        const tag: InventoryTypeModel | undefined = inventory_types.find(t => t.id === tagId)
        if (tag) {
            const tagElem = (
                <div style={{ display: 'block' }}>
                    <Tag color="green" closable
                         onClose={e => {
                             e.preventDefault();
                             handleCloseUsable(tag.id);
                         }}
                    >
                        {tag.name}
                    </Tag>
                </div>
            )
            return (
                <span key={tag.name} style={{ display: 'inline-block' }}>
                    {tagElem}
                </span>
            )
        }
        return (
            <span style={{ display: 'inline-block' }}>&nbsp;</span>
        )
    }

    const renderParameter = (tag: string, closable: boolean) => {
        const tagElem = (
            <Tag color="#aaaaaa" closable={closable}
                 onClose={e => {
                     if( closable ) {
                         handleCloseParameter(tag);
                         e.preventDefault()
                     }
                 }}
            >
                {tag}
            </Tag>
        )
        return (
            <span key={tag} style={{ display: 'inline-block' }}>
                {tagElem}
            </span>
        )
    }

    const isReadOnly = (): boolean => {
        if (!dataToUpdate) {
            return false
        }
        return !!dataToUpdate.is_used
    }

    const showInput = () => {
        setInputVisible(true)
        inputRef.current?.focus()
        setTimeout(() => {inputRef.current?.focus()}, 200);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    }

    const handleAddUsable = (v, e) => {
        setUsableIds([...usableIds, parseInt(v)])
        setSelectedUsable('')
        setUpdateStatus(true)
    }

    const handleInputConfirm = () => {
        if (inputValue && parameters.indexOf(inputValue) === -1) {
            setParameters([...parameters, inputValue]);
            setUpdateStatus(true)
        }
        setInputVisible(false);
        setInputValue('');
    };

    return (
        <>
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={{
                ...dataToUpdate,
                is_server_case: dataToUpdate && dataToUpdate.is_server_case === 1,
                is_server_component: dataToUpdate && dataToUpdate.is_server_component === 1,
                is_rack: dataToUpdate && dataToUpdate.is_rack === 1,
                is_rack_equipment: dataToUpdate && dataToUpdate.is_rack_equipment === 1,
                is_blade_container: dataToUpdate && dataToUpdate.is_blade_container === 1,
                is_blade_module: dataToUpdate && dataToUpdate.is_blade_module === 1,
                has_management_ip: dataToUpdate && dataToUpdate.has_management_ip === 1
            }}
        >
            <Item name='name'
                  labelCol={{span: 4}}
                  wrapperCol={{span: 20}}
                  label={t('inventoryTypePage.name')} className='formItem'
                  rules={[{required: true, message: t('errors.field_required')}]} hasFeedback>
                <Input disabled={isReadOnly()} />
            </Item>

            <Row style={{marginTop: '25px'}}>
                <Col span={4}>&nbsp;</Col>
                <Col span={10}>
                    <Item name='is_server_case'
                          className='formItem' valuePropName="checked">
                        <Checkbox disabled={isReadOnly()}>{t('inventoryTypePage.is_server_case')}</Checkbox>
                    </Item>
                </Col>
                <Col span={10}>
                    <Item name='is_server_component'
                          className='formItem' valuePropName="checked">
                        <Checkbox disabled={isReadOnly()}>{t('inventoryTypePage.is_server_component')}</Checkbox>
                    </Item>
                </Col>
            </Row>

            <Row>
                <Col span={4}>&nbsp;</Col>
                <Col span={10}>
                    <Item name='is_rack'
                          className='formItem' valuePropName="checked">
                        <Checkbox disabled={isReadOnly()}>{t('inventoryTypePage.is_rack')}</Checkbox>
                    </Item>
                </Col>
                <Col span={10}>
                    <Item name='is_rack_equipment'
                          className='formItem' valuePropName="checked">
                        <Checkbox disabled={isReadOnly()}>{t('inventoryTypePage.is_rack_equipment')}</Checkbox>
                    </Item>
                </Col>
            </Row>

            <Row>
                <Col span={4}>&nbsp;</Col>
                <Col span={10}>
                    <Item name='is_blade_container'
                          className='formItem' valuePropName="checked">
                        <Checkbox disabled={isReadOnly()}>{t('inventoryTypePage.is_blade_container')}</Checkbox>
                    </Item>
                </Col>
                <Col span={10}>
                    <Item name='is_blade_module'
                          className='formItem' valuePropName="checked">
                        <Checkbox disabled={isReadOnly()}>{t('inventoryTypePage.is_blade_module')}</Checkbox>
                    </Item>
                </Col>
            </Row>

            <Row>
                <Col span={4}>&nbsp;</Col>
                <Col span={10}>
                    <Item name='has_management_ip'
                          className='formItem' valuePropName="checked">
                        <Checkbox disabled={isReadOnly()}>{t('inventoryTypePage.has_management_ip')}</Checkbox>
                    </Item>
                </Col>
                <Col span={10}>

                </Col>
            </Row>

            <Divider />

            <Item name='usables' label={t('inventoryTypePage.usables')} className='formItem'>
                <div style={{ marginBottom: 16 }}>
                    {usableIds.map(renderUsable)}
                </div>
                {
                    !isReadOnly() &&
                    <Select
                        optionFilterProp='label'
                        options={typesOptions}
                        size='small'
                        allowClear={true}
                        autoClearSearchValue={true}
                        showSearch={true}
                        dropdownMatchSelectWidth={200}
                        onSelect={handleAddUsable}
                        value={selectedUsable}
                        placeholder={t('inventoryTypePage.new_usable')}
                        style={{width: 160, margin: '4px'}}
                    />
                }
            </Item>

            <Divider />


            <Item name='keys' label={t('inventoryTypePage.parameters')} className='formItem'>
                <div style={{ marginBottom: 16 }}>
                        {parameters.map(p => p && renderParameter(p, !isReadOnly()))}
                </div>
                {!isReadOnly() && inputVisible && (
                    <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                    />
                )}
                {!isReadOnly() && !inputVisible && (
                    <Tag onClick={showInput} className="site-tag-plus">
                        <PlusOutlined /> {t('inventoryTypePage.new_parameter')}
                    </Tag>
                )}
            </Item>

            <Divider />

            <Row>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Button loading={isLoading} type='primary' htmlType='submit' disabled={!updateStatus || isReadOnly()}>
                        {
                            dataToUpdate ? t('general.update') : t('general.create')
                        }
                    </Button>
                </Col>
            </Row>

            <Zalert type="warning"
                    condition={isReadOnly()}
                    message={t('inventoryTypePage.type_is_used')}  />

        </Form>

    </>
    )
}

export default InventoryTypeForm
