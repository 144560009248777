import {
    UpdateInventoryLocationRequestAction,
    UpdateInventoryLocationResponseAction,
    UpdateInventoryLocationErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { InventoryLocationModel } from '../models'

export default (
    params: InventoryLocationModel,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | UpdateInventoryLocationRequestAction
                | UpdateInventoryLocationResponseAction
                | UpdateInventoryLocationErrorAction,
        ) => void,
    ) => {
        const request = new UpdateInventoryLocationRequestAction(params)
        dispatch(request)
        const id = params.id
        protectedApiClient
            .put<InventoryLocationModel>(`/inventory/location/${id}`, params)
            .then((response) => {
                dispatch(new UpdateInventoryLocationResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateInventoryLocationErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
