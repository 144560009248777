import React, {useEffect, useState} from 'react'
import {Button, Card, Col, Empty, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import {SettingsDetails} from "../settings/models";
import SettingItem from "./SettingItem";
import {sort_name} from "../../common/sorting";
import {_stoUniq} from "../../common/fce";
import LoggedUser from "../../common/LoggedUser";
import getUserSettings from "./actions/getUserSettings";
import getCustomerSettings from "./actions/getCustomerSettings";
import getAppSettings from "./actions/getAppSettings";


interface Props {
    category: string
    scope: string
    loggedUser: LoggedUser
}


const PanelSettings = ({category, scope, loggedUser}: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {user, user_settings, user_settings_scope} = useSelector((state: AppState) => state.user)
    const {self, isLoading} = useSelector((state: AppState) => state.auth)

    const [dataSource, setDataSource] = useState<SettingsDetails[]>([])
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)

    useEffect(() => {
        if (user) {
            if (scope != user_settings_scope) {
                if (scope === 'user') {
                    console.log('getUserSettings()..')
                    dispatch(getUserSettings(user.id))
                }
                if (scope === 'customer') {
                    console.log('getCustomerSettings()..')
                    dispatch(getCustomerSettings(user.customer_id))
                }
                if (scope === 'app') {
                    console.log('getAppSettings()..')
                    dispatch(getAppSettings())
                }
            }
        }
    }, []);

    useEffect(() => {
        console.log(`user_settings: scope: ${scope}  :`+user_settings.length)
        if (category === 'app') {
            // app  frozen === 1
            setDataSource(user_settings.filter(s => s.group === category && s.frozen === 1 ).sort(sort_name))
        }
        else {
            setDataSource(user_settings.filter(s => s.group === category ).sort(sort_name))
        }
    }, [user_settings]);

    if (dataSource.length === 0) {
        return (
            <Card title={t(`usersPage.menu_${category}_settings`)}
                  className='fblock'
                  style={{marginTop: 0}}>
                <div style={{width: '99%', textAlign: 'center'}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
            </Card>
        )
    }

    return (
        <Card title={t(`usersPage.menu_${category}_settings`)} className='fblock'
              style={{marginTop: 0}}>

            {
                dataSource.map( s => (
                    <SettingItem item={s} key={s.name} loggedUser={loggedUser} />
                ))
            }

            <Row>
                <Col span={12}></Col>
                <Col span={12} style={{textAlign: 'left'}}>
                    <Button type='primary' disabled={!updateStatus}/>
                </Col>
            </Row>
        </Card>
    )
}

export default PanelSettings
