import {
    GetVpsServerResponseAction,
    GetVpsServerErrorAction,
    GetVpsServerRequestAction
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { HypervisorModel } from "../models";


export default (id: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetVpsServerRequestAction | GetVpsServerResponseAction | GetVpsServerErrorAction,
        ) => void,
    ) => {
        const request = new GetVpsServerRequestAction(id)
        dispatch(request)

        protectedApiClient
            .get<HypervisorModel>(`/vpsadmin/container/${id}`)
            .then((response) => {
                dispatch(new GetVpsServerResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetVpsServerErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
