import {
  AddComponentToPhysicalServerRequestAction,
  AddComponentToPhysicalServerResponseAction,
  AddComponentToPhysicalServerErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
  PhysicalServerAddComponentParams,
  PhysicalServerModel,
} from '../models'

export default (
  params: PhysicalServerAddComponentParams,
  cb?: (isSuccess: boolean) => void,
) => {
  return (
    dispatch: (
      arg:
        | AddComponentToPhysicalServerRequestAction
        | AddComponentToPhysicalServerResponseAction
        | AddComponentToPhysicalServerErrorAction,
    ) => void,
  ) => {
    const request = new AddComponentToPhysicalServerRequestAction(params)
    dispatch(request)
    protectedApiClient
      .put<PhysicalServerModel>(
        `/inventory/physical-server/${params.physical_server_id}/add-component?expand=inventory,server_components,text_ipmi,rack,text_location`,
          {server_component_id: params.server_component_id},
      )
      .then((response) => {
        dispatch(
          new AddComponentToPhysicalServerResponseAction(
            request,
            response.data,
          ),
        )
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new AddComponentToPhysicalServerErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
