import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {GetResGroupsErrorAction, GetResGroupsRequestAction, GetResGroupsResponseAction} from "./index";
import {ResGroupModel} from "../models";


export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetResGroupsRequestAction | GetResGroupsResponseAction | GetResGroupsErrorAction,
        ) => void,
    ) => {
        const request = new GetResGroupsRequestAction()
        dispatch(request)
        protectedApiClient
            .get<ResGroupModel[]>(`/resource-group`)
            .then((response) => {
                dispatch(new GetResGroupsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetResGroupsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
