import {LogRecord, LogState} from "./models";
import {LogActions} from "./actions";
import {ActionType} from "./actions";
import {AsyncActionMode} from "../../common/models";
import {removeDiac} from "../../common/fce";
import {getZisLevelValue, INTENT_START, ZIS_LEVEL_ENUM} from "../../common/enums";


const INITIAL_STATE: LogState = {
    isLoading: false,
    record: undefined,
    records: [],
    requests: []
}

const getRequests = (data: LogRecord[]) => {
    // add user_id for NOTICE requests INTENT_START
    let notices = data.filter((rec) => rec.zis_level === getZisLevelValue(ZIS_LEVEL_ENUM.NOTICE) && rec.zis_log_category === INTENT_START)
    for (const notice of notices) {
        let user_rec = data.find(r => r.zis_request_id === notice.zis_request_id && r.zis_user_id > 0)
        notice.zis_user_id = user_rec ? user_rec.zis_user_id : 0
        notice.username = user_rec ? user_rec.username : 'guest'
    }
    return notices
}

export default (
    state: LogState = INITIAL_STATE,
    action: LogActions,
): LogState => {

    switch (action.type) {
        case ActionType.GET_LOG_RECORD:
            if (action.mode === AsyncActionMode.REQUEST) {
                return {...state, isLoading: true}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    record: action.data,
                    isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    error: action.error,
                    isLoading: false,
                }
            }
            break
        case ActionType.GET_LOG_RECORDS:

            if (action.mode === AsyncActionMode.REQUEST) {
                return {...state, isLoading: true}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    records: action.data.map((rec) => {
                        return {...rec, searchableName: rec.message ? removeDiac(rec.message) : ''}}
                    ),
                    requests: getRequests(action.data),
                    isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    error: action.error,
                    isLoading: false,
                }
            }
            break
    }
    return state
}