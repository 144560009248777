import {SetPassRequestErrorAction, SetPassRequestRequestAction, SetPassRequestResponseAction} from "./index";


export default (requestId: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | SetPassRequestRequestAction
                | SetPassRequestResponseAction
                | SetPassRequestErrorAction,
        ) => void,
    ) => {
        const request = new SetPassRequestRequestAction(requestId)
        dispatch(request)

        if (requestId && requestId.length > 5) {
            dispatch(new SetPassRequestResponseAction(request, 'ok'))
            cb && cb(true)
        }
        else {
            dispatch(new SetPassRequestErrorAction(request, 'request not found'))
            cb && cb(false)
        }
    }
}