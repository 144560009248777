import {
    GetAppSettingsRequestAction,
    GetAppSettingsResponseAction,
    GetAppSettingsErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {SettingsDetails} from "../../settings/models";

export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetAppSettingsRequestAction | GetAppSettingsResponseAction | GetAppSettingsErrorAction,
        ) => void,
    ) => {
        const request = new GetAppSettingsRequestAction()
        dispatch(request)

        protectedApiClient
            .get<SettingsDetails[]>(`/app/setting`)
            .then((response) => {
                dispatch(new GetAppSettingsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetAppSettingsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
