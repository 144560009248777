import {
    RemoveUserFromGroupRequestAction,
    RemoveUserFromGroupErrorAction,
    RemoveUserFromGroupResponseAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {UserGroupModel} from "../../usergroups/models";
import {AddDeleteUserToGroupParams} from "../models";


export default (
    params: AddDeleteUserToGroupParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | RemoveUserFromGroupRequestAction
                | RemoveUserFromGroupErrorAction
                | RemoveUserFromGroupResponseAction,
        ) => void,
    ) => {
        const request = new RemoveUserFromGroupRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<UserGroupModel>(`/user-group/${params.id}/remove-user`, params)
            .then((response) => {
                dispatch(new RemoveUserFromGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new RemoveUserFromGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
