import {ActionType, ServerActions} from './actions'
import {AsyncActionMode, NameItem, PAGE1} from 'common/models'
import {ServerModel, ServersPageState} from './models'

const SERVER_INITIAL_STATE: ServersPageState = {
	isLoading: false,
	isSaving: false,
	isLoadingServers: false,
	isLoadingPhysicalServers: false,
	error: undefined,
	servers: {items: [], pagination: PAGE1},
	pager: PAGE1,
	physical_servers: [],
	service_types: [],
	server_types: [],
	locations: [],
	vps_types: [],
	zis_managements: [],
	customer_managements: [],
	backup_types: [],
	server_monitorings: []
}

export default (state = SERVER_INITIAL_STATE, action: ServerActions): ServersPageState => {
	switch (action.type) {
		case ActionType.ADD_IP_TO_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					servers: {
						...state.servers,
						items: state.servers.items.map(
							(s) => s.id === action.data.server_id ? {
								...s,
								addresses: s.addresses?.concat(action.data.address),
							} : s,
						)},
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.REMOVE_IP_FROM_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					servers: {
						...state.servers,
						items: state.servers.items.map(
							(s) => s.id === action.request.payload.server_id ? {
								...s,
								addresses: s.addresses?.filter((a) => a.id !== action.request.payload.address_id),
							} : s,
						)},
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					server: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSaving: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				// add to first pos.
				let new_list = [...state.servers.items]  // new obj
				new_list.unshift(action.data)
				return {
					...state,
					servers: {
						...state.servers,
						items: new_list
					},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVERS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoadingServers: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingServers: false,
					servers: action.data,
					pager: {...action.data.pagination, page: action.data.pagination.page+1},
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingServers: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_PHYSICAL_SERVERS2:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoadingPhysicalServers: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoadingPhysicalServers: false,
					physical_servers: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoadingPhysicalServers: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSaving: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					servers: {
						...state.servers,
						items: state.servers.items.filter(
							(s) => s.id !== action.request.id,
						)},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_SERVER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSaving: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					servers: {
						...state.servers,
						items: state.servers.items.map(
							(s) => s.id === action.data.id ? action.data : s,
						)},
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_SERVER_TYPE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					server_types: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVER_SERVICE_TYPE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					service_types: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVER_BACKUP_TYPE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					backup_types: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVER_VPS_TYPE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					vps_types: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_SERVER_MONITORING_TYPE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					server_monitorings: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_CUSTOMER_MANAGEMENT_TYPE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					customer_managements: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_ZIS_MANAGEMENT_TYPE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					zis_managements: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
	}
	return state
}
