import {ActionType, MailTemplateActions} from './actions'
import {AsyncActionMode} from 'common/models'
import {MailTemplateState} from './models'

const MAIL_INITIAL_STATE: MailTemplateState = {
    isLoading: false,
    isSaving: false,
    templates: [],
    template: undefined,
    error: ''
}

export default (state = MAIL_INITIAL_STATE, action: MailTemplateActions): MailTemplateState => {
    switch (action.type) {
        case ActionType.GET_TEMPLATES:
            if (action.mode === AsyncActionMode.REQUEST) {
                return {...state, isLoading: true, error: ''}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                // success
                return {...state, isLoading: false, templates: action.data}
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {...state, isLoading: false, error: action.error}
            }
            break
        case ActionType.GET_TEMPLATE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoading: true, error: ''}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                // success
                return {
                    ...state,
                    template: action.data,
                    isLoading: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                }
            }
            break
        case ActionType.CREATE_TEMPLATE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true, error: ''}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                // success
                const newTemplates = state.templates
                newTemplates.push(action.data)
                return {
                    ...state,
                    template: action.data,
                    templates: newTemplates,
                    isSaving: false
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    error: action.error,
                    isSaving: false,
                }
            }
            break
        case ActionType.UPDATE_TEMPLATE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true, error: ''}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                // success
                const newTemplates = state.templates?.filter((t) => t.id !== action.data.id)
                newTemplates.push(action.data)
                return {
                    ...state,
                    template: action.data,
                    templates: newTemplates,
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break
        case ActionType.DELETE_TEMPLATE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true, error: ''}
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                // success
                return {
                    ...state,
                    templates: state.templates.filter((t) => t.id !== action.id),
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break
    }
    return state
}
