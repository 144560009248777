import React, {useEffect, useState} from 'react'
import {Button, Form, Input, message, Select, Spin} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {ResGroupModel} from "./models";
import createResGroup from "./actions/createResGroup";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {sort_label} from "../../../common/sorting";
import updateResGroup from "./actions/updateResGroup";



interface Props {
    setModalVisible: (param: boolean) => void
    group: ResGroupModel | undefined
}

const {Item} = Form
const REG_RES_GROUP = new RegExp('^[A-Za-z]{2,12}-[A-Za-z0-9-]{3,24}$')


const NewResGroupForm = ({setModalVisible, group}: Props) => {

    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {isSaving} = useSelector((state: AppState) => state.resourcePage)
    const {customers} = useSelector((state: AppState) => state.auth.tables)

    const [isValid, setValid] = useState<boolean>(false)
    const [groupKey, setGroupKey] = useState<string>('')
    const [custOptions, setCustOptions] = useState<{ label: string, value: number }[]>([])

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // init groupKey for editing
        group && setGroupKey(group.name)
    }, [])

    useEffect(() => {
        if (customers && customers.length) {
            if ( loggedUser.user.is_zcom ) {
                const items = customers.map(c => ({label: c.company!.name ? c.company!.name : '', value: c.id}))
                setCustOptions(items.sort(sort_label))
            }
        }
    }, [customers])

    useEffect(() => {
        // validate groupKey
        if (groupKey) {
            setValid(REG_RES_GROUP.test(groupKey))
        }
    }, [groupKey])

    const onFormSubmit = (values: Store) => {
        // save data
        if (isResGroupValid()) {
            const arr = groupKey.trim().split('-')
            for (let i=0; i<arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
            }
            const name = arr.join('-')
            if (group && group.id) {
                // edit
                dispatch(updateResGroup({...group, name: name}, (isSuccess: boolean) => {
                        if (isSuccess) {
                            setModalVisible(false)
                            message.success(t('general.success'))
                        }
                    }
                ))
            }
            else {
                // insert
                const group: ResGroupModel = {name: name, customer_id: values['customer_id'], resources: []}
                dispatch(createResGroup(group, (isSuccess: boolean) => {
                        if (isSuccess) {
                            setModalVisible(false)
                            message.success(t('general.success'))
                        }
                    }
                ))
            }

        }
    }

    const isResGroupValid = () => {
        // "billing-viewer"
        if (!groupKey) {
            return false
        }

        return REG_RES_GROUP.test(groupKey)
    }

    const handleCasbinKeyPressed = (e) => {
        if (e.target.value && e.target.value.toString().includes('-')) {
            const arr = e.target.value.split('-')
            if (arr.length === 2) {
                if ((e.which >= 97 && e.which <= 122) || (e.which >= 48 && e.which <= 57) || e.which === 45) {
                    return
                }

            }
            else {
                if (e.which >= 97 && e.which <= 122) {
                    return
                }
            }

        }
        else {
            if ((e.which >= 97 && e.which <= 122) || e.which === 45) {
                return
            }
        }
        e.preventDefault();
    }

    return (
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
            initialValues={group ? {...group} : {customer_id: loggedUser.user.customer_id}}
        >
            {
                loggedUser &&
                loggedUser.user.is_zcom &&
                <Item
                    name='customer_id'
                    label={t('createUserPage.customer_id')}
                    rules={[{ required: true, message: t('createUserPage.err_customer') }]}
                >
                    <Select
                        showSearch
                        style={{display: 'block'}}
                        optionFilterProp='label'
                        options={custOptions}
                    />
                </Item>
            }

            <Item name='name' label={t('rights.resources.newResGroup')}
                  rules={[
                      {required: true, message: t('errors.field_required')},
                      {pattern: REG_RES_GROUP, message: 'name has not valid format.'}
                  ]}
                  hasFeedback
            >
                <Input placeholder='model-permission' autoFocus
                       value={groupKey}
                       onKeyPress={handleCasbinKeyPressed}
                       onChange={(e) => setGroupKey(e.target.value)} />
            </Item>

            <Item {...tailLayout}>
                <Button loading={isSaving} type='primary' htmlType='submit' disabled={!isValid}>
                    { group ? t('general.save') : t('general.create')}
                </Button>
            </Item>

        </Form>
    )
}

export default NewResGroupForm
