import {
    DeleteUserAuthLogRequestAction,
    DeleteUserAuthLogResponseAction,
    DeleteUserAuthLogErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | DeleteUserAuthLogRequestAction
                | DeleteUserAuthLogResponseAction
                | DeleteUserAuthLogErrorAction,
        ) => void,
    ) => {
        const request = new DeleteUserAuthLogRequestAction(id)
        dispatch(request)

        protectedApiClient
            .delete(`/user-authentication-ip/${id}`)
            .then((response) => {
                dispatch(new DeleteUserAuthLogResponseAction(request, id))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteUserAuthLogErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
