import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {TableCustomerErrorAction, TableCustomerRequestAction, TableCustomerResponseAction} from "./index";
import {CustomerDetails} from "../../billing/customer/models";


export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | TableCustomerRequestAction
                | TableCustomerResponseAction
                | TableCustomerErrorAction,
        ) => void,
    ) => {
        const request = new TableCustomerRequestAction()
        dispatch(request)

        let url = `/customer?expand=company`
        protectedApiClient
            .get<CustomerDetails[]>(url)
            .then((response) => {
                dispatch(new TableCustomerResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new TableCustomerErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
