import React, {useState} from 'react'
import {Button, Form, Input, InputNumber, Switch, Select, Collapse, Space, Row, Col} from 'antd'
import {useTranslation} from 'react-i18next'
import {useForm} from 'antd/lib/form/Form'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {
	CertificateModel,
	CreateCertificateParams,
	UpdateCertificateParams,
	CHALLENGE_ENUM,
} from 'pages/certificate/models'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {_isValidDomain, ValidateParams} from "../../common/fce";
import clearArchived from "../dns/service/actions/clearArchived";

const {Item} = Form
const {TextArea} = Input
const { Panel } = Collapse;

interface Props {
	dataToUpdate?: CertificateModel
	onFinish: (values: CreateCertificateParams | UpdateCertificateParams) => void
}

export const CertificateForm = ({dataToUpdate, onFinish}: Props) => {
	const [form] = useForm()
	const {t} = useTranslation()

	const [domainField, setDomainField] = useState<ValidateParams>({value: '', validateStatus: 'success', errorMsg: ''})
	const [isFormValid, setFormValid] = useState<boolean>(false)


	const checkDomain = (dom: string) => {
		console.log('checkDomain: '+dom)
		let dom2 = dom.replace('*.', '')
		if (!dom2) {
			setDomainField({
				value: dom,
				validateStatus: 'success',
				errorMsg: ''
			})
			setFormValid(false)
			return true
		}
		const result = _isValidDomain(dom2)
		if (result) {
			setDomainField({
				value: dom,
				validateStatus: 'success',
				errorMsg: ''
			})
			setFormValid(true)
			return true
		}
		else {
			setDomainField({
				value: dom,
				validateStatus: 'error',
				errorMsg: 'Invalid domain'
			})
			setFormValid(false)
			return false
		}
	}

	return (
		<Form {...formItemLayout} form={form}
			  onFinish={onFinish}
			  initialValues={ dataToUpdate ? {...dataToUpdate} : {challenge: CHALLENGE_ENUM.CHALLENGE_DNS}}>
			{dataToUpdate && (
				<Item name='id' style={{display: 'none'}}>
					<InputNumber type='hidden' />
				</Item>
			)}

			<Item
				name='name' label={t('certificatesPage.name')}
				rules={[{required: true, message: t('certificatesPage.error.name')}]}
				validateStatus={domainField.validateStatus}
				help={domainField.errorMsg}
				hasFeedback>
				<Input onBlur={(e) => checkDomain(e.target.value)}
					   onChange={(e) => checkDomain(e.target.value)}
					   readOnly={!!dataToUpdate} disabled={!!dataToUpdate}/>
			</Item>

			<Item
				name='challenge'
				rules={[{required: !dataToUpdate, message: t('certificatesPage.error.challenge')}]}
				label={t('certificatesPage.challenge')}>
				<Select options={Object.values(CHALLENGE_ENUM).map((val) => ({label: val, value: val}))} />
			</Item>

			<Row style={{marginBottom: '15px'}}>
				<Col span={8} style={{textAlign: 'right'}}>Certificate:&nbsp;&nbsp;</Col>
				<Col span={16}>
					<Collapse collapsible="header" defaultActiveKey={['1']}>
						<Panel header="Upload manually" key="1">
							<Item name='key'
								  labelCol={{ span: 4}} wrapperCol={{ span: 20}}
								  label={t('certificatesPage.key')}>
								<TextArea />
							</Item>

							<Item name='crt'
								labelCol={{ span: 4}} wrapperCol={{ span: 20}}
								label={t('certificatesPage.crt')}>
								<TextArea />
							</Item>

							<Item name='ca_crt'
								labelCol={{ span: 4}} wrapperCol={{ span: 20}}
								label={t('certificatesPage.ca_crt')}>
								<TextArea />
							</Item>
						</Panel>
					</Collapse>
				</Col>
			</Row>

			<Item name='auto_prolong' valuePropName='checked' label={t('certificatesPage.auto_prolong')}>
				<Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
			</Item>

			<Item name='comment' label={t('certificatesPage.comment')}>
				<TextArea cols={5} style={{minHeight: '100px'}} />
			</Item>

			<Item {...tailLayout}>
				<Button type='primary' htmlType='submit'>
					{dataToUpdate ? t('general.update') : t('general.create')}
				</Button>
			</Item>
		</Form>
	)
}

export default CertificateForm
