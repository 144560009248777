import React, {useEffect, useRef, useState} from 'react'
import {
	AutoComplete,
	Badge,
	Card,
	Col,
	Input,
	message,
	Modal,
	Row,
	Select,
	Space,
	Spin,
	Switch,
	Table,
	Tooltip
} from 'antd'
import {
	CheckOutlined, CloseOutlined,
	DeleteTwoTone,
	EditTwoTone, FlagFilled,
	PlusCircleOutlined,
	ShoppingCartOutlined
} from '@ant-design/icons/lib/icons'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import getCustomers from './actions/getCustomers'
import {AppState} from 'common/models'
import {CustomerDetails} from './models'
import CustomerForm from 'pages/billing/customer/CustomerForm'
import Button from 'antd-button-color'
import './CustomerPage.scss'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {PAGING} from "../../../common/enums";
import TotalNum from "../../../components/TotalNum/TotalNum";
import {ColumnsType} from "antd/es/table";
import {Link, useLocation} from "react-router-dom";
import getCustomer from "./actions/getCustomer";
import changeFlag from "./actions/changeFlag";
import {CompanyDetails} from "../../company/models";
import {LoadingIndicator} from "../../../components";
import setTerminated from "./actions/setTerminated";
import {
	CommentOutlined, ContactsOutlined,
	ContactsTwoTone, DeleteOutlined, EditOutlined,
	ExclamationCircleOutlined,
	InfoCircleTwoTone,
	RollbackOutlined
} from "@ant-design/icons";
import ContactForm, {IContactForm} from "./ContactForm";
import {_isNotEmptyObject, removeDiac, stopPropagation} from "../../../common/fce";
import queryString, {ParsedQuery} from "query-string";
import {useHistory} from "react-router";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import usePageSize from "../../../common/usePageSize";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {sort_numeric, sort_searchable, sort_str} from "../../../common/sorting";
import CommentForm from "../../../components/Comment/CommentForm";
import updateCustomer from "./actions/updateCustomer";
import HistoryModal from "../../../components/History/HistoryModal";
import {process_customer} from "./datatrans";



const { confirm } = Modal

const lastInvoiceFunc = (rec, appSetting) => {
	if (rec.last_invoice) {
		return (<span style={{whiteSpace: 'nowrap'}}>{appSetting.renderDate(rec.last_invoice.period_from) + ' - ' + appSetting.renderDate(rec.last_invoice.period_to)}</span>)
	} else {
		return ''
	}
}

const getCompanyVAT = (companies: CompanyDetails[] | undefined, id: number): boolean => {
	if (!companies) {
		return true
	}
	const existingCompany = companies.find(c => (c.id === id))
	return !!(existingCompany && existingCompany.vat_payer)
}


const CustomerPage = () => {
	const CONTROL_NAME = 'page_billing_customer'
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const {search} = useLocation()

	const contactFormRef = useRef<IContactForm | null>(null)
	const {customers, customer, isLoading, isSaving, isDeleting} = useSelector((state: AppState) => state.customer)
	const {companies} = useSelector((state: AppState) => state.auth.tables)
	const {billing_customers} = useSelector((state: AppState) => state.sidenav)

	const [isRendering, setRendering] = useState<boolean>(false)
	const [isModalVisible, setModalVisible] = useState<boolean>(false)
	const [isModalContactVisible, setModalContactVisible] = useState<boolean>(false)
	const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
	const [historyTitle, setHistoryTitle] = useState('')
	const [historyModelId, setHistoryModelId] = useState<number | undefined>()
	const [isContactSelected, setContactSelected] = useState<boolean>(false)
	const [isCreate, setIsCreate] = useState(false)
	const [dataToUpdate, setDataToUpdate] = useState<CustomerDetails>()
	const [dataSource, setDataSource] = useState<CustomerDetails[]>([])
	const [currentCustomer, setCurrentCustomer] = useState<CustomerDetails | undefined>()
	const [company, setCompany] = useState<CompanyDetails | undefined>(undefined)

	// filters
	const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
	const [pageSize, setPageSize] = useState<string>()
	const [checkedTerminated, setCheckedTerminated] = useState<boolean>(false)
	const [currentPeriod, setCurrentPeriod] = useState<string>('all')
	const [searchName, setSearchName] = useState('')
	const [filteredPeriod, setFilteredPeriod] = useState('')
	const [filterStamp, setFilterStamp] = useState('')
	const [openDelete, setOpenDelete] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [isModalCommentVisible, setModalCommentVisible] = useState<boolean>(false)
	const [disabled, setDisabled] = useState(true)

	const [isViewer, setViewer] = useState(false)
	const [isCreator, setCreator] = useState(false)
	const [isEditor, setEditor] = useState(false)
	const [isContactEditor, setContactEditor] = useState(false)
	const [isDeleter, setDeleter] = useState(false)

	const options: any[] = [
		{label: t(`RangeFilter.month`), value: 'month'},
		{label: t(`RangeFilter.quarter_year`), value: 'quarter_year'},
		{label: t(`RangeFilter.half_year`), value: 'half_year'},
		{label: t(`RangeFilter.year`), value: 'year'},
		{label: t(`RangeFilter.iregular`), value: 'iregular'},
	];

	const periodOptions: any[] = [
		{label: t(`customerPage.periodOptions.all`), value: 'all'},
		{label: t(`customerPage.periodOptions.invoiced`), value: 'invoiced'},
		{label: t(`customerPage.periodOptions.not_invoiced`), value: 'not_invoiced'},
		{label: t(`customerPage.periodOptions.double_not_invoiced`), value: 'double_not_invoiced'}
	];

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator/>
			</div>
		)
	}

	// required authorization
	if (!loggedUser.hasAccess(CONTROL_NAME)) {
		return <ErrorPage403 />
	}

	// settings
	const appSetting = loggedUser.getAppSettings()
	const SEARCH_MIN = appSetting.min_search_length

	// logger
	const logger = (msg) => { if (appSetting && appSetting.debug) {console.log('CustomerPage: ' + msg)} }
	usePageSize(appSetting, loggedUser.user.id, pageSize)

	// history drag modal
	const draggleRef = useRef<HTMLDivElement>(null)
	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggleRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	}

	let triggerOneTimer = 0

	useEffect(() => {
		const viewer = loggedUser.hasAccess('page_billing_customer')
		if (!viewer) {
			// go to dashboard
			message.error(t('errors.permission_denied'))
			history.replace('/')
			return
		}
		setViewer(viewer)
		setCreator(loggedUser.hasAccess('page_billing_customer_create_button'))
		const editor = loggedUser.hasAccess('page_billing_customer_edit_button')
		setEditor(editor)
		setDeleter(loggedUser.hasAccess('page_billing_customer_delete_button'))

		if (editor) {
			setContactEditor(true)
		}
		else {
			const ce = loggedUser.hasAccess('page_billing_contact_create_button') || loggedUser.hasAccess('page_billing_contact_edit_button') || loggedUser.hasAccess('page_billing_contact_delete_button')
			setContactEditor(ce)
		}


		// apply QS filters
		// and set filters
		handleQueryStringFilters()

		// refresh customers
		triggerOneTimer = Date.now().valueOf()
		!isLoading && dispatch(getCustomers( 'company,last_invoice', suc => setFilterStamp('')))
	}, [])

	useEffect(() => {
		// trigger from Side menu clicked
		if (companies) {
			const when = triggerOneTimer + 1000
			if (when < Date.now().valueOf()) {
				// when triggerOneTimer was not called (after 1sec. of SideMenu click)
				// refresh customers
				!isLoading && dispatch(getCustomers( 'company,last_invoice', suc => setFilterStamp('')))
			}
		}
	}, [billing_customers])

	useEffect(() => {
		// when edit customer
		if (customer) {
			setCompany(companies.find(c => c.id === customer.company_id))
		}
	}, [customer])

	useEffect(() => {
		// update datasource when data was changed
		if (!isLoading) {
			refreshGrid()
		}
	}, [searchName, isModalVisible, filteredPeriod, currentPeriod, checkedTerminated, filterStamp])

	useEffect(() => {
		// update datasource when data was changed
		if (!isLoading) {
			refreshGridForced()
		}
	}, [customers, isLoading])

	useEffect(() => {
		if (isModalVisible) {
			// when modal is open
			// clean customer to edit
			dispatch({ type: 'CLEAR_CUSTOMER', payload: 1 })
		}
	}, [isModalVisible])

	const handleQueryStringFilters = () => {
		// /billing/customer?invoice_period=all&name=soft&billing_period=month&terminated=true
		const qs = parsed
		if (qs && _isNotEmptyObject(qs)) {
			const qs_name: string | string[] | null = qs['name']
			if (qs_name && typeof qs_name === 'string' && qs_name.length > 1) {
				setSearchName(qs_name)
			}
			const qs_terminated: string | string[] | null = qs['terminated']
			if (qs_terminated && typeof qs_terminated === 'string') {
				setCheckedTerminated(qs_terminated.toLowerCase() === 'true')
			}
			const qs_billing: string | string[] | null = qs['billing_period']
			if (qs_billing && typeof qs_billing === 'string') {
				setFilteredPeriod(qs_billing.toLowerCase())
			}
			const qs_invoice: string | string[] | null = qs['invoice_period']
			if (qs_invoice && typeof qs_invoice === 'string') {
				setCurrentPeriod(qs_invoice.toLowerCase())
			}
		}
	}

	const getStamp = (nameStr: string, isTerminated: number) => {
		// cache stamp
		if (nameStr && nameStr.length > SEARCH_MIN) {
			return `${nameStr},${filteredPeriod},${currentPeriod},${isTerminated}`
		}
		else {
			return `${filteredPeriod},${currentPeriod},${isTerminated}`
		}
	}

	const filtered = (nameStr: string | undefined, isCheckedTerminated: boolean) => {
		let data: CustomerDetails[] = []
		if (!customers) {
			return []
		}
		if (!nameStr) {
			nameStr = ''
		}
		setFilterStamp(getStamp(nameStr, checkedTerminated ? 1 : 0))
		logger('customers: '+ customers.length)
		// logger('nameStr: '+ nameStr)
		// logger('billing: '+ filteredPeriod)
		// logger('terminated: '+ isCheckedTerminated)
		// logger('currentPeriod: '+ currentPeriod)

		let qs:string[] = []
		const qZcom = (c) => c.id > 1	// skip ZCOM=1

		data = customers.filter((c) => qZcom(c))
		data = data.map(c => process_customer(c))
		if (!isCheckedTerminated) {
			data = data.filter((c) => !c.terminated)
		}

		// filter currentPeriod
		if (currentPeriod && currentPeriod != 'all') {
			// currentPeriod = all | invoiced | not_invoiced | double_not_invoiced
			// what_period = current | last | last2
			let qPeriod
			if (currentPeriod === 'invoiced') {
				qPeriod = (c) => c.what_period === 'current'
			}
			if (currentPeriod === 'not_invoiced') {
				qPeriod = (c) => (c.what_period === 'last' || c.what_period === 'last2') && c.billing_period != 'iregular'
			}
			if (currentPeriod === 'double_not_invoiced') {
				qPeriod = (c) => c.what_period === 'last2' && c.billing_period != 'iregular'
			}
			data = data.filter((c) => qPeriod(c))
			qs.push('invoice_period='+currentPeriod)
		}

		if (nameStr && nameStr.length > SEARCH_MIN) {
			// name
			const qName = (c) => c.searchableName && c.searchableName.includes(removeDiac(nameStr))
			data = data.filter((c) => qName(c))
			qs.push('name='+removeDiac(nameStr))
		}

		if (filteredPeriod) {
			// billing period
			const qBilling = (c) => c.billing_period && c.billing_period.toLowerCase() === filteredPeriod.toLowerCase()
			data = data.filter((c) => qBilling(c))
			qs.push('billing_period='+filteredPeriod.toLowerCase())
		}
		history.replace('/billing/customer?' + qs.join("&"))
		logger('replace /billing/customer?'+ qs.join("&"))
		setParsed(queryString.parse(search))
		return data.sort(sort_searchable)
	}

	const refreshGrid = () => {
		if (isRendering) {
			return
		}
		setRendering(true)
		const pattern = searchName.toLowerCase()
		const stamp: string = getStamp(pattern, checkedTerminated ? 1 : 0)
		if (stamp === filterStamp && dataSource.length > 0) {
			logger('refreshGrid skipped')
			setRendering(false)
			return
		}

		const data = filtered(pattern, checkedTerminated)
		setDataSource(data)
		logger(`refreshGrid pattern: ${customers?.length}, ${data?.length}`)
		setRendering(false)
	}

	const refreshGridForced = () => {
		// refresh always, even when filter did not change (ex: after insert)
		if (isRendering) {
			return
		}
		setRendering(true)
		const pattern = searchName.toLowerCase()
		const data = filtered(pattern, checkedTerminated)
		setDataSource(data)
		logger(`refreshGridForced pattern: ${customers?.length}, ${data?.length}`)
		setRendering(false)
	}

	const showConfirmDelete = (obj: CustomerDetails, title: string, terminated: number) => {
		if (!obj) {
			return
		}
		confirm({
				icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
				title: title,
				content: <p>{obj.company!.name}</p>,
				okText: t('general.yes'),
				cancelText: t('general.cancel'),
				okButtonProps: { loading: confirmDelete },
				className: 'confirm-alert',
				onOk() {
					setConfirmDelete(true)
					dispatch(setTerminated({id: obj.id, terminated: terminated},suc => {
						setConfirmDelete(false)
						setOpenDelete(false)
						if (suc) {
							message.success(t('general.success'))
							dispatch(getCustomers( 'company,last_invoice'))
						}
						else {
							message.error(t('general.error'))
						}
					}))
				},
				onCancel() {
					setOpenDelete(false)
				},
			}
		)
	}

	const saveComment = (text?: string) => {
		if (dataToUpdate && dataToUpdate.id) {
			text ? dataToUpdate.note = text : dataToUpdate.note = ''
			const params = {id: dataToUpdate.id, note: dataToUpdate.note}
			dispatch(updateCustomer(params, suc => {
				setModalCommentVisible(false)
			}))
		}
	}

	const handleFilterPeriodChange = (id : string) => {
		!isLoading && dispatch(getCustomers( 'company,last_invoice', suc => {
			if (id) {
				const selected = options.find(item => item.value === id)
				setFilteredPeriod(selected.value)
			} else {
				setFilteredPeriod('')
			}
		}))
	}

	const handleCurrentPeriodChange = (id : string) => {
		!isLoading && dispatch(getCustomers( 'company,last_invoice', suc => {
			// refresh customers and change the  filter
			if (id) {
				const selected = periodOptions.find(item=>item.value === id)
				setCurrentPeriod(selected.value)
			}
			else {
				setCurrentPeriod('all')
			}
			setFilterStamp('')
		}))
	}

	const flagFunction = (record: CustomerDetails) => {
		if (!isEditor) {
			return
		}
		if (record.flag === 0) {
			return < FlagFilled onClick={(e) => flagClickFunction(record)}  style={{ fontSize: '16px', color:  'green' }} />
		} else if (record.flag === 1) {
			return  < FlagFilled onClick={(e) => flagClickFunction(record)}  style={{ fontSize: '16px', color:  'red' }} />
		} else {
			return < FlagFilled onClick={(e) => flagClickFunction(record)} style={{ fontSize: '16px', color:  'black' }} />
		}
	}

	const flagClickFunction = (record) => {
		dispatch(changeFlag(record.id))
	}

	const openContacts = (cust: CustomerDetails) => {
		if (cust) {
			setCurrentCustomer(cust)
		}
		setModalContactVisible(true)
	}

	const FilterByPeriod = customers && (
		<Select
			placeholder={t('customerPage.billing_period')}
			options={options}
			value={filteredPeriod}
			style={{ width: '120px', marginRight: "1rem" }}
			allowClear
			onClick={stopPropagation}
			onChange={handleFilterPeriodChange}
			filterOption={(inputValue, option) =>
				option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
			}
		/>
	)

	const FilterByName = customers && (
		<AutoComplete
			placeholder={t('customerPage.company_id')}
			style={{width: '200px'}}
			value={searchName}
			onClick={stopPropagation}
			onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
			allowClear={true}
			onChange={v => {
				setSearchName(v)
			}}
		/>
	)

	const columns: ColumnsType<CustomerDetails> = [
		{
			title: '',
			dataIndex: 'flag',
			key: 'flag',
			render: (text, record,) => (<div style={{textAlign: 'center'}}>{flagFunction(record)}</div>)
		},
		{
			title: (
				<div style={{marginRight: "1rem"}}>
					{FilterByName}
				</div>
			),
			dataIndex: 'ident',
			key: 'ident',
			ellipsis: true,
			width: 300,
			render: (text, record) => (
				<Link to={`/billing/customer-services/${record.id}`} title={t('customerPage.services')}>
					{text}
				</Link>
			),
			sorter: (a, b) => sort_searchable(a, b)
		},
		{
			title: t('companiesPage.note'),
			dataIndex: 'note',
			key: 'note',
			className: 'left',
			ellipsis: true,
			width: '15%'
		},
		{
			title: t('companiesPage.vat_payer'),
			dataIndex: 'company_id',
			key: 'vat_payer',
			className: 'centered',
			render: (record: number) =>
				getCompanyVAT(companies, record) ? <Badge status='success' /> : <Badge status='error' />,
		},
		{
			title: FilterByPeriod,
			dataIndex: 'billing_period',
			className: 'center',
			key: 'billing_period',
			render: (text, record) => (
				<Link to={`/billing/customer-services/${record.id}`} title={t('customerPage.services')} className='text-dark'>
					{t(`RangeFilter.${text}`)}
				</Link>
			),
			sorter: (a, b) => sort_str(a.billing_period, b.billing_period)
		},
		{
			title: t('customerPage.total_price'),
			className: 'totalPrice',
			render: (text, record) => (
				<Link to={`/billing/customer-services/${record.id}`} title={t('customerPage.services')} className='text-dark'>
					{appSetting && appSetting.renderPrice(record.last_invoice?.total_without_vat)}
				</Link>
			),
			sorter: (a, b) =>  {
				return sort_numeric(a.last_invoice?.total_without_vat, b.last_invoice?.total_without_vat)
			}
		},
		{
			title: t('customerPage.currency'),
			dataIndex: 'billing_currency',
			className: 'center',
			key: 'billing_currency',
			render: (text, record) => (
				<Link to={`/billing/customer-services/${record.id}`} title={t('customerPage.services')} className='text-dark'>
					{text}
				</Link>
			),
			sorter: (a, b) => sort_str(a.billing_currency, b.billing_currency)
		},
		{
			title: t('customerPage.last_invoice'),
			className: 'center',
			render: (text, record) => {
				if (record.terminated) {
					return lastInvoiceFunc(record, appSetting)
				}
				return (
					<Link to={`/billing/customer-services/${record.id}`} title={t('customerPage.services')} className='text-dark'>
						{lastInvoiceFunc(record, appSetting)}
					</Link>
				)
			},
			sorter: (a, b) => {
				if (a.last_invoice && b.last_invoice) {
					return sort_str(a.last_invoice.period_from, b.last_invoice.period_from)
				}
				else {
					if (a.last_invoice && !b.last_invoice) {
						return 1
					}
					if (!a.last_invoice && b.last_invoice) {
						return -1
					}
					return 0
				}
			}
		},
		{
			title: 'Action',
			key: 'action',
			dataIndex: 'action',
			align: 'center',
			width: '120px',
			render: (_text, record) => (
				<Space size={1}>
					{
						(isEditor) ? (
							<Button title={record.note}
									icon={<CommentOutlined style={{backgroundColor: record.note ? '#ccffcc' : '', borderRadius: '5px'}} />}
									size='small'
									className='actionButton'
									onClick={() => {
										if (isEditor) {
											setDataToUpdate(record)
											setModalCommentVisible(true)
										}
									}}
							/>
						) : (
							<Button icon={<CommentOutlined style={{color: 'lightgray'}} />} size='small' className='actionButton' />
						)
					}
					{
						isEditor && (
							<Button type='text' title={record.terminated === 0 ? t('billing.customer.edit_customer') : t('billing.customer.terminated_customer')}
									size='small'
									className='actionButton'
									disabled={record.terminated === 1}
									icon={record.terminated === 1 ? <EditTwoTone twoToneColor='#888888' /> : <EditTwoTone twoToneColor='green' />}
									onClick={() => {
										dispatch(getCustomer(record.id, 'last_invoice,company,contacts'))
										setIsCreate(false)
										setModalVisible(true)
									}}
							/>
						)
					}
					{
						!isEditor &&  (
							<Button icon={<EditOutlined style={{color: 'lightgray'}} />} size='small' className='actionButton' />
						)
					}
					{
						isContactEditor && (
							<Button type='text' title={record.terminated === 0 ? t('billing.customer.edit_contacts') : t('billing.customer.terminated_customer')}
									size='small'
									className='actionButton'
									disabled={record.terminated === 1}
									icon={record.terminated === 1 ? <ContactsTwoTone twoToneColor='#888888' /> : <ContactsTwoTone twoToneColor='#1890ff' />}
									onClick={() => {
										dispatch(getCustomer(record.id, 'last_invoice,company,contacts'))
										openContacts(record)}
									}
							/>)
					}
					{
						!isContactEditor && (
							<Button icon={<ContactsOutlined style={{color: 'lightgray'}} />} size='small' className='actionButton' />
						)
					}

					{ // neukonceny
						isDeleter && (
							!record.terminated ? (
							<Button type='text' danger size='small'
									className='actionButton'
									title={t('billing.customer.terminate_customer')}
									onClick={() => showConfirmDelete(record, t('billing.customer.terminate_service_title'), 1)}
									icon={<DeleteTwoTone twoToneColor='red'/>} />
						) : (
							<Button type='text' danger size='small'
									className='actionButton'
									title={t('billing.customer.resurrect_customer')}
									onClick={() => showConfirmDelete(record, t('billing.customer.resurrect_service_title'), 0)}
									icon={<RollbackOutlined twoToneColor='red'/>} />
						)
						)
					}

					{
						!isDeleter && (
							<Button icon={<DeleteOutlined style={{color: 'lightgray'}} />} size='small' className='actionButton' />
						)
					}

					<Button title={t('general.btnHistory')} size='small'
							onClick={() => {
								setHistoryModelId(record.id)
								record.company && setHistoryTitle(record.company!.name)
								setHistoryModalVisible(true)
							}}
							icon={<InfoCircleTwoTone />}
							className='actionButton'
					/>
				</Space>
			),
		},
	]

	if (appSetting && loggedUser) {
		return (
			<>
				<Card
					title={
						<>
							<Row>
								<Col span={4}>
									<ShoppingCartOutlined/> {t('customerPage.title')}
								</Col>

								<Col span={12}>
									<Space style={{marginRight: '4px'}}>
										<div style={{
											float: "left",
											marginRight: "5px"
										}}>{t('customerPage.show_current_period')}</div>
										<Select
											placeholder={t('customerPage.billing_period')}
											options={periodOptions}
											style={{ width: '160px', marginRight: "1rem" }}
											defaultValue='all'
											value={currentPeriod}
											onClick={stopPropagation}
											onChange={handleCurrentPeriodChange}
										/>
									</Space>
								</Col>

								<Col span={8}>
									<Space style={{float: 'right', marginRight: '40px'}}>
										<div style={{
											float: "left",
											marginRight: "1px"
										}}>{t('customerPage.hide_terminated')}</div>
										<Tooltip title={t('customerPage.hide_terminated_customers')}>
											<Switch
												checkedChildren={<CheckOutlined/>}
												unCheckedChildren={<CloseOutlined/>}
												checked={checkedTerminated}
												onChange={() => setCheckedTerminated(!checkedTerminated)}
											/>
										</Tooltip>
									</Space>
								</Col>
							</Row>
						</>
					}
					className='CustomerPage'
					extra={
						<Button
							type='primary'
							disabled={!isCreator}
							onClick={() => {
								setIsCreate(true)
								setModalVisible(true)
							}}>
							<PlusCircleOutlined /> {t('customerPage.create-customer')}{' '}
						</Button>
					}
					loading={false}>

					<Table<CustomerDetails>
						dataSource={dataSource}
						columns={columns}
						bordered={true}
						loading={isLoading || isRendering}
						showSorterTooltip={false}
						rowKey={(record) => `${record.id}`}
						showHeader={true}
						expandRowByClick={true}
						rowClassName={(record) => ((record.terminated) ? 'terminated' : '')}
						className='CustomersTable'
						scroll={{x: 'max-content'}}
						pagination={{
							defaultPageSize: appSetting?.grid_page_size,
							pageSizeOptions: PAGING,
							showSizeChanger: true
						}}
						onChange={(ev) => {setPageSize(`${ev.pageSize}`)}}
						footer={() => TotalNum(Number(dataSource?.length), 'Customer', dataSource)}
					/>

				</Card>

				<Modal title={
					<div style={{width: '100%', cursor: 'move'}}
						 onMouseOver={() => {if (disabled) { setDisabled(false)}}}
						 onMouseOut={() => {setDisabled(true)}}
						 onFocus={() => {}}
						 onBlur={() => {}}
					>
						<><CommentOutlined/> &nbsp; {t('general.comment')}</>
					</div>
				}
					   destroyOnClose
					   className='modalComment'
					   style={{top: 20}}
					   visible={isModalCommentVisible}
					   onOk={() => setModalCommentVisible(false)}
					   onCancel={() => setModalCommentVisible(false)}
					   modalRender={(modal) => (
						   <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
							   <div ref={draggleRef}>{modal}</div>
						   </Draggable>
					   )}
					   getContainer={false}
					   footer={null}
					   confirmLoading={true}
				>
					<CommentForm comment={dataToUpdate?.note} setModalVisible={setModalCommentVisible} saveComment={saveComment} />
				</Modal>

				<Modal
					destroyOnClose
					style={{top: 20}}
					width={600}
					title={
						isCreate ? t('billing.customer.modal-create-title') : t('billing.customer.modal-update-title') + ' ' + company?.name
					}
					visible={isModalVisible}
					onCancel={() => setModalVisible(false)}
					maskClosable={false}
					footer={null}>
					<CustomerForm setModalVisible={() => setModalVisible(false)} isCreate={isCreate} />
				</Modal>

				<Modal
					destroyOnClose={true}
					style={{top: 50}}
					width={700}
					title={t('customerPage.contact_title') + ' ' + company?.name}
					visible={isModalContactVisible}
					onCancel={() => setModalContactVisible(false)}
					maskClosable={false}
					footer={
						<Row>
							<Col span={6} className='center'>
								<Button type='primary'
										disabled={!loggedUser.hasAccess('page_billing_contact_create_button')}
										onClick={() => {
											// this calls child component ContactForm
											contactFormRef.current?.setNewContact()
										}}>
									{t('general.add')}
								</Button>
							</Col>

							<Col span={6} className='center'>
								<Button type='success'
										disabled={!isContactSelected || !loggedUser.hasAccess('page_billing_contact_edit_button')}
										loading={isSaving}
										onClick={() => {
											// this calls child component ContactForm
											contactFormRef.current?.onUpdateContact()
										}}>
									{t('general.save')}
								</Button>
							</Col>
							<Col span={6}>
								<Button type='danger'
										disabled={!isContactSelected || !loggedUser.hasAccess('page_billing_contact_delete_button')}
										loading={isDeleting}
										onClick={() => {
											// this calls child component ContactForm
											contactFormRef.current?.onDeleteContact()
										}}>
									{t('general.delete')}
								</Button>
							</Col>
							<Col span={6}>
								<Button type='default' onClick={() => setModalContactVisible(false)}>
									{t('general.close')}
								</Button>
							</Col>
						</Row>
					}>
					<ContactForm setModalVisible={setModalContactVisible}
								 setContactSelected={setContactSelected}
								 customer={currentCustomer}
								 ref={contactFormRef}
					/>
				</Modal>

				<HistoryModal service='customer' model='Customer'
							  modelId={historyModelId}
							  title={historyTitle}
							  isModalVisible={isHistoryModalVisible}
							  setModalVisible={() => setHistoryModalVisible(false)}
							  modalRender={(modal) => (
								  <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
									  <div ref={draggleRef}>{modal}</div>
								  </Draggable>
							  )}
				/>
			</>
		)
	}
	else {
		return (
			<Spin/>
		)
	}
}

export default CustomerPage
