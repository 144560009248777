import React, { useEffect } from 'react'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { HistoryModel, VpsHelper } from './../../models'
import updateSetting from "../../../login/actions/updateSetting"
import { useLoggedUser } from "../../../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../../../components"
import { Card, Select, Table } from "antd"
import { PAGING } from "../../../../common/enums"
import TotalNum from "../../../../components/TotalNum/TotalNum"
import getContainersHistories from 'pages/vpsadmin/actions/getContainersHistories'
import usePageSize from "../../../../common/usePageSize";


const HistoryTable = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {hypervisorHystories, hypervisor, container, isLoading } = useSelector((state: AppState) => state.vpsadmin)
    const [dataSource, setDataSource] = useState<HistoryModel[]>()
    const [pageSize, setPageSize] = useState<string>()

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()
    usePageSize(appSetting, loggedUser.user.id, pageSize)


    useEffect(() => {
        if (container) {
            dispatch(getContainersHistories(container.id))
        }
    }, [])

    useEffect(() => {
        setDataSource(hypervisorHystories.reverse())
    }, [hypervisorHystories])

    const columnsHistories = [
        {
            title: t('vpsadmin.vps.create'),
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'left ',
            width: '25%',
            render: (text) => text && appSetting.renderFullDateTime(text)
        },
        {
            title: t('vpsadmin.vps.name'),
            dataIndex: 'name',
            key: 'name',
            className: 'left ',
            width: '25%',
        },
        {
            title: t('vpsadmin.vps.host'),
            dataIndex: 'host',
            key: 'host',
            className: 'left ',
            width: '25%',
        },
        {
            title: t('vpsadmin.vps.cpu'),
            dataIndex: 'cpu_count',
            key: 'cpu_count',
            className: 'left align',
            width: '5%'
        },
        {
            title: t('vpsadmin.vps.ram'),
            dataIndex: 'ram_total',
            className: 'left align',
            width: '5%',
            render: (_, record: HistoryModel) => (
                <>
                    {VpsHelper.transformToGB(record.ram_total)}
                    {` ${t('vpsadmin.gb')}`}
                </>
            )
        },
        {
            title: t('vpsadmin.vps.disk'),
            dataIndex: 'disk_total',
            className: 'left ',
            width: '10%',
            style: { minWidth: 100 },
            render: (_, record: HistoryModel) => (
                <>
                    {VpsHelper.transformToGB(record.disk_size)}
                    {` ${t('vpsadmin.gb')}`}
                </>
            )
        },
        {
            title: t('vpsadmin.vps.state'),
            dataIndex: 'state',
            className: 'left ',
            width: '10%',
            render: (_, record: HistoryModel) => (
                <>
                    {record.state}
                </>
            )
        }
    ]

    return (
        <>
            {
                appSetting &&
                    <Table
                        scroll={{ y: 400 }}
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columnsHistories}
                        loading={isLoading}
                        dataSource={dataSource}
                        className='HistoriesTable'
                        rowKey='id'
                        pagination={{
                            defaultPageSize: appSetting?.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => { setPageSize(`${ev.pageSize}`) }}
                        footer={() => TotalNum(Number(dataSource?.length), 'Histories', dataSource)}
                    />
            }
        </>
    )
}

export default HistoryTable
