import {
    SetOrderErrorAction,
    SetOrderRequestAction, SetOrderResponseAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {CustomerServiceDetails, SetOrderParams} from '../models'

export default (params: SetOrderParams,  cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | SetOrderRequestAction
                | SetOrderResponseAction
                | SetOrderErrorAction,
        ) => void,
    ) => {
        const request = new SetOrderRequestAction(params.id)
        dispatch(request)

        protectedApiClient
            .put<CustomerServiceDetails>(`/billing/customer-service/${params.id}/order`, {id: params.id, order: params.ordering})
            .then((response) => {
                dispatch(new SetOrderResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new SetOrderErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
