import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {DeletePricelistParams, PricelistDetails} from '../../pricelist/models'
import {
    ActivatePricelistErrorAction,
    ActivatePricelistRequestAction,
    ActivatePricelistResponseAction
} from "../../pricelist/actions";


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | ActivatePricelistRequestAction
                | ActivatePricelistResponseAction
                | ActivatePricelistErrorAction,
        ) => void,
    ) => {
        const request = new ActivatePricelistRequestAction(id)
        dispatch(request)

        protectedApiClient
            .post<PricelistDetails>(`/billing/pricelist/${id}/activate-default`)
            .then((response) => {
                dispatch(new ActivatePricelistResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new ActivatePricelistErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
