import React, {useEffect, useState} from 'react'
import {Typography } from 'antd';
import {Card, Col, Row, Form, Table} from 'antd'
import {PieChartOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import './DashboardPage.scss'
import { LoadingIndicator } from 'components'
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {useDispatch, useSelector} from "react-redux";
import getLastSeen from "../user/actions/getLastSeen";
import {AppState} from "../../common/models";
import {UserDetails} from "../user/models";
import {Link} from "react-router-dom";
import {renderRole, renderUserGroup} from "../rights/common";
import {sort_str, sort_title} from "../../common/sorting";
const { Text } = Typography;


const DashboardPage = () => {
	const {t} = useTranslation()
	const dispatch = useDispatch()

	const {last_seen, isLoading} = useSelector((state: AppState) => state.user)
	const [dataSource, setDataSource] = useState<UserDetails[]>([])

	useEffect(() => {
		// when user logged => set permissions
		dispatch(getLastSeen())
	}, [])

	useEffect(() => {
		// update datasource when data was changed
		setDataSource(last_seen)
	}, [last_seen])

	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator/>
			</div>
		)
	}
	const appSetting = loggedUser.getAppSettings()

	const columns = [
		{
			title: 'aa',
			dataIndex: 'updated_at',
			key: 'updated_at',
			className: 'last_seen_col',
			render: (dt, record: UserDetails) => (
					// is_unix has special value if token is valid
					record.is_unix === 1 ?
						<Text type="success" style={{whiteSpace: 'nowrap'}}>
							{appSetting.renderDateTime(record.updated_at)}&nbsp;<b>@{record.username}</b>&nbsp;<span style={{fontSize: '0.6em'}}>({record.last_seen_ip})</span>
						</Text>
						:
						<Text delete={true} style={{whiteSpace: 'nowrap'}}>
							{appSetting.renderDateTime(record.updated_at)}&nbsp;@{record.username}&nbsp;<span style={{fontSize: '0.6em'}}>({record.last_seen_ip})</span>
						</Text>
			)
		},
	]

	return (
		<>
			<Card
				title={<><PieChartOutlined /> {t('dashboardPage.title')}</>}
				className='DashboardPage'>
				<Row gutter={[8,8]} style={{overflow: 'none'}}>
					<Col span={7}>
						<Card title={t('dashboardPage.user_info')}
							  type="inner"
							  extra={<Link to='/users/me'>[{t('general.edit')}]</Link>}
							  bordered={true}>
							<Form.Item label={t('dashboardPage.full_name')}>
								<span>{loggedUser.user.title}</span>
							</Form.Item>
							<Form.Item label={t('dashboardPage.username')}>
								<span>@{loggedUser.user.username}</span>
							</Form.Item>
							<Form.Item label={t('dashboardPage.email')}>
								<span><a href={`mailto:${loggedUser.user.email}`}>{loggedUser.user.email}</a></span>
							</Form.Item>
							<Form.Item label={t('dashboardPage.phone')}>
								<span>{appSetting.renderPhone(loggedUser.user.phone)}</span>
							</Form.Item>
							<Form.Item label={t('dashboardPage.roles')} className='role-box'>
								{loggedUser.user.roles.sort(sort_str).map(r => renderRole(r, false, ()=>{}))}
							</Form.Item>
						</Card>

					</Col>

					<Col span={10}>

					</Col>

					<Col span={7}>
						<Card title={t('dashboardPage.last_seen')} type="inner" bordered={true}>
							<Table<UserDetails>
								dataSource={dataSource}
								columns={columns}
								rowKey={(record) => `r${record.username}-${record.updated_at}`}
								className="last-table"
								rowClassName={(record) => {
									if (record.is_unix)
										return 'zcom'
									else
										return ''
								}}
								style={{}}
								loading={isLoading}
								pagination={false}
							/>
						</Card>
					</Col>

				</Row>
			</Card>
		</>
	)
}

export default DashboardPage
