import React, { ReactElement, useEffect, useState } from 'react'
import {Popover, Form, InputNumber, Tooltip, Space, Select, Modal, Spin, Row, Col} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useTranslation } from 'react-i18next'
import Button from 'antd-button-color'
import {formItemLayout, formItemLayout50, tailLayout} from 'helpers/layoutHelpers'
import moment, {Moment} from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import createInvoice from 'pages/billing/invoice/actions/createInvoice'
import { CustomerDetails } from 'pages/billing/customer/models'
import { CheckCircleOutlined } from '@ant-design/icons'
import { useHistory } from "react-router-dom";
import {AppState} from "../../common/models";
import Customer from "../../common/Customer";
import {SQL_DATE_FORMAT} from "../../common/fce";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import DateRange from "../RangeFilter/DateRange";
import DateInput from "../RangeFilter/DateInput";

interface Props {
    children: ReactElement
    customer: CustomerDetails
    companyName: string
    [propName: string]: {},
}

const { Item } = Form

const InvoiceCustomerForm = ({ children, customer, companyName, ...rest }: Props) => {
    const { t } = useTranslation()
    const [form] = useForm()
    const dispatch = useDispatch()
    const {customerservices} = useSelector((state: AppState) => state.customerservice)
    const {isSaving} = useSelector((state: AppState) => state.invoice)

    const [visiblePopUp, setVisiblePopUp] = useState(false)
    const history = useHistory();

    const [dateOfIssue, setDateOfIssue] = useState<Moment>()
    const [dateOfMaturity, setDateOfMaturity] = useState<Moment>()
    const [dateOfTaxing, setDateOfTaxing] = useState<Moment>()
    const [range, setRange] = useState<[string, string] | undefined>()

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // set DateOfIsseue, DateOfTaxing, DateOfMaturity
        const current = moment().startOf("day")
        const dateFrom = moment(current).subtract(1,'months').endOf('month');
        const startDate = moment().startOf("month")
        const diff = moment.duration(current.diff(startDate)).asDays()
        if(diff < 12) {
            setDateOfIssue(dateFrom)
            setDateOfTaxing(dateFrom)
        } else {
            setDateOfIssue(moment())
            setDateOfTaxing(moment())
        }
        setDateOfMaturity(moment(current, "DD-MM-YYYY").add(14, 'days'))
    }, [])

    useEffect(() => {
        // set range as un-invoiced billing period
        const c = new Customer(customer)
        const rr = c.getUnInvoicedBillingPeriod(customerservices)
        setRange(rr)
    },[customerservices, customer])

    const handleDateChange = (dt_from: string, dt_to: string) => {
        // use SQL_DATE_FORMAT
        if (dt_from && dt_to) {
            const dt1: Moment = moment(dt_from)
            const dt2: Moment = moment(dt_to)
            // setSearchRange([dt1, dt2])
            setRange([dt1.format(SQL_DATE_FORMAT), dt2.format(SQL_DATE_FORMAT)])
        }
    }

    const success = (invoice_id: number) => {
        Modal.confirm({
            content: t('billing.invoice.done'),
            okText: t('billing.invoice.okText'),
            cancelText: t('billing.invoice.cancelText'),
            onOk() {
                history.push('/billing/invoice/edit/' + invoice_id)
            },
            icon: <CheckCircleOutlined />,
        });
    }

    const onFinish = (values) => {
        if (!range) {
            console.log('range is empty')
            return
        }
        dispatch(createInvoice({
            customer_id: values.customer_id,
            generate_customer_services: 1,
            period_from: range[0],
            period_to: range[1],
            date_of_maturity: appSetting.renderDateSQL(values.date_of_maturity),
            date_of_taxing: appSetting.renderDateSQL(values.date_of_taxing),
            date_of_issue: appSetting.renderDateSQL(values.date_of_issue),
            currency: customer.billing_currency,
            invoice_detail: values.invoice_detail,
            invoiceItems: []
        }, (isSuccess, invoice_id) => {
            if (isSuccess) {
                isSuccess && setVisiblePopUp(false)
                isSuccess && invoice_id && success(invoice_id)
            }
            else {
                setVisiblePopUp(false)
            }
        }))
    }

    const invoiceForm =
        <div className='genInvoiceBox'>
            <Row gutter={16}>
                <Col span={8} style={{textAlign: 'right', padding: '5px'}}>{t('billing.invoice.customer')}</Col>
                <Col span={16} className='ant-card-head' style={{border: 0, padding: '4px'}}><b>{companyName}</b></Col>
            </Row>
            <Row gutter={16} style={{marginTop: '15px'}}>
                <Col span={8} style={{textAlign: 'right', padding: '5px'}}><span className='req'>{t('invoice-customer-form.period')}</span>:</Col>
                <Col span={16}>
                    <DateRange format={appSetting.date_picker_format} // not Moment formats!! showClear={false}
                               showClear={false}
                               initStart={range ? range[0] : moment().startOf('month').format(SQL_DATE_FORMAT)}
                               initEnd={range ? range[1]: moment().endOf('month').format(SQL_DATE_FORMAT)}
                               onChange={handleDateChange}
                    />
                </Col>
            </Row>
            <Form form={form}
                  {...formItemLayout50}
                onFinish={onFinish}
                style={{ padding: '10px 20px 0' }}
                validateMessages={{ required: '' }}
                initialValues={{
                    customer_id: customer.id,
                    date_of_issue: dateOfIssue,
                    date_of_maturity: dateOfMaturity,
                    date_of_taxing: dateOfTaxing,
                    invoice_detail: customer.invoice_detail,
                    range: range
                }}
            >

                <Item name='date_of_issue' rules={[{ required: true }]} label={t('billing.bill.date_of_issue')} >
                    <DateInput htmlId='__date_of_issue'
                               initDateStr={appSetting.renderDateSQL(dateOfIssue?.toDate())}
                               disabled={false}
                               required={true}
                               format={appSetting.date_picker_format} // not Moment formats!!
                               onChange={(date) => {
                                   if (date) {
                                       form.setFieldsValue({date_of_issue: appSetting.renderDateSQL(date)})
                                       setDateOfIssue(moment(appSetting.renderDateSQL(date)))
                                   }
                                   else {
                                       form.setFieldsValue({date_of_issue: appSetting.renderDateSQL(dateOfIssue?.toDate())})
                                   }
                               }}
                    />
                </Item>

                <Item name='date_of_maturity' rules={[{ required: true }]} label={t('billing.bill.date_of_maturity')} >
                    <DateInput htmlId='__date_of_maturity'
                               initDateStr={appSetting.renderDateSQL(dateOfMaturity?.toDate())}
                               disabled={false}
                               required={true}
                               format={appSetting.date_picker_format} // not Moment formats!!
                               onChange={(date) => {
                                   if (date) {
                                       form.setFieldsValue({date_of_maturity: appSetting.renderDateSQL(date)})
                                       setDateOfMaturity(moment(appSetting.renderDateSQL(date)))
                                   }
                                   else {
                                       form.setFieldsValue({date_of_maturity: appSetting.renderDateSQL(dateOfMaturity?.toDate())})
                                   }
                               }}
                    />
                </Item>

                <Item name='date_of_taxing' rules={[{ required: true }]} label={t('billing.bill.date_of_taxing')} >
                    <DateInput htmlId='__date_of_taxing'
                               initDateStr={appSetting.renderDateSQL(dateOfTaxing?.toDate())}
                               disabled={false}
                               required={true}
                               format={appSetting.date_picker_format} // not Moment formats!!
                               onChange={(date) => {
                                   if (date) {
                                       form.setFieldsValue({date_of_taxing: appSetting.renderDateSQL(date)})
                                       setDateOfTaxing(moment(appSetting.renderDateSQL(date)))
                                   }
                                   else {
                                       form.setFieldsValue({date_of_taxing: appSetting.renderDateSQL(dateOfTaxing?.toDate())})
                                   }
                               }}
                    />
                </Item>

                <Item name='invoice_detail'
                      label={t('customerPage.invoice_detail')}
                      rules={[{ required: true }]}
                >
                    <Select showSearch
                            allowClear
                            optionFilterProp='label'
                            options={[
                                ({label: t('billing.invoice.single_item'), value: 0}),
                                ({label: t('billing.invoice.group_item'), value: 1, disabled: true}),
                                ({label: t('billing.invoice.all_details'), value: 2}),
                            ]}
                            dropdownMatchSelectWidth={200}
                            style={{ width: '150px' }}
                    />
                </Item>

                <Item name='customer_id' style={{ display: 'none' }}>
                    <InputNumber type='hidden' />
                </Item>

                <Item {...tailLayout} style={{marginTop: '25px'}}>
                    <Button htmlType='submit'
                            loading={isSaving}
                            type='warning'>{t('invoice-customer-form.generate')}</Button>
                </Item>
            </Form>
        </div>

    return (
        <>
            <Popover
                trigger='click'
                content={invoiceForm}
                visible={visiblePopUp}
                onVisibleChange={(visible) => setVisiblePopUp(visible)}
                {...rest}
            >
                {children}
            </Popover>
        </>
    )
}

export default InvoiceCustomerForm
