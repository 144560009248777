import { GetContactsRequestAction, GetContactsResponseAction, GetContactsErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {ContactModel} from '../models'


export default (customerId: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetContactsRequestAction
                | GetContactsResponseAction
                | GetContactsErrorAction
        ) => void,
    ) => {
        const request = new GetContactsRequestAction()
        dispatch(request)

        protectedApiClient
            .get<ContactModel[]>(
                `/customer/${customerId}/contact`,
            )
            .then((response) => {
                dispatch(new GetContactsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetContactsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
