import React, { useEffect } from 'react'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { ChangesModel, HistoryModel, HypervisorModel, stateHypervisorSetting, VpsHelper } from './../../models'
import updateSetting from "../../../login/actions/updateSetting"
import { useLoggedUser } from "../../../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../../../components"
import { AutoComplete, Card, Col, message, Row, Space, Select, Table, List } from "antd"
import { PAGING } from "../../../../common/enums"
import TotalNum from "../../../../components/TotalNum/TotalNum"
import getContainersChanges from 'pages/vpsadmin/actions/getContainersChanges'
import usePageSize from "../../../../common/usePageSize";


interface PropsType {
    type: string,
    data?: ChangesModel[],
    isLoading?: boolean
}


const ChangesTable = (props: PropsType) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()


    const { containerChanges, isLoading, hypervisor, vpsServer } = useSelector((state: AppState) => state.vpsadmin)
    const [pageSize, setPageSize] = useState<string>()
    const [dataSourceChanges, setDataSourceChanges] = useState<ChangesModel[]>()

    const { Option } = Select
    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // logger
    const logger = (msg) => {
        if (appSetting && appSetting.debug) {
            console.log('ChangesTable: ' + msg)
        }
    }

    useEffect(() => {
        if (props.type === 'hypervisor' && hypervisor) {
            dispatch(getContainersChanges(hypervisor.id))
        }
        if (props.type === 'vps' && vpsServer) {
            dispatch(getContainersChanges(vpsServer.id))
        }
    }, [])

    useEffect(() => {
        setDataSourceChanges(containerChanges.reverse())
    }, [containerChanges])



    const columnsChanges = [
        {
            title: t('vpsadmin.vps.create'),
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'left ',
            width: '25%',
            render: (text) => text && appSetting.renderFullDateTime(text)
        },
        {
            title: t('vpsadmin.changes.operation'),
            dataIndex: 'operation',
            key: 'operation',
            className: 'left ',
            width: '25%'
        },
        {
            title: t('vpsadmin.vps.host'),
            dataIndex: 'container',
            key: 'container',
            className: 'left ',
            width: '25%',
            render: (item) => {
                if (item) {
                    return item.host
                }
                return item

            }

        },
        {
            title: t('vpsadmin.changes.nameParameters'),
            dataIndex: 'name',
            key: 'name',
            className: 'left ',
            width: '25%'
        },
        {
            title: t('vpsadmin.changes.defaultValue'),
            dataIndex: 'origin_value',
            key: 'origin_value',
            className: 'left ',
            width: '25%'
        },
        {
            title: t('vpsadmin.changes.newValue'),
            dataIndex: 'new_value',
            key: 'new_value',
            className: 'left ',
            width: '25%'
        }


    ]


    return (
        <>
            {
                appSetting &&
                    <Table
                        scroll={{ y: 400 }}
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columnsChanges}
                        loading={isLoading}
                        dataSource={dataSourceChanges}
                        className='ChangesTable '
                        rowKey='id'
                        pagination={{
                            defaultPageSize: appSetting?.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => { setPageSize(`${ev.pageSize}`) }}
                        footer={() => TotalNum(Number(dataSourceChanges?.length), 'Changes', dataSourceChanges)}
                    />
            }


        </>
    )
}

export default ChangesTable

