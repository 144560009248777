import {
    SetOrderProductErrorAction,
    SetOrderProductRequestAction, SetOrderProductResponseAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {ProductDetails, SetOrderProductParams} from "../models";

export default (param: SetOrderProductParams,  cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | SetOrderProductRequestAction
                | SetOrderProductResponseAction
                | SetOrderProductErrorAction,
        ) => void,
    ) => {
        const request = new SetOrderProductRequestAction(param)
        dispatch(request)

        protectedApiClient
            .put<ProductDetails>(`/billing/product/${param.id}/order`, param)
            .then((response) => {
                dispatch(new SetOrderProductResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new SetOrderProductErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
