import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "antd/lib/form/Form";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {Button, Col, Form, Input, message, Row, Select, Spin, Switch, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import {tailLayout} from "../../../helpers/layoutHelpers";
import isCidr from "is-cidr";
import {Store} from "antd/lib/form/interface";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import createIpSubnet from "./actions/createIpSubnet";
import {CreateSubnetIpParams} from "./models";
import {AppState} from "../../../common/models";
import {sort_label} from "../../../common/sorting";
import getDnsServices from "./actions/getDnsServices";
import tableCustomers from "../../login/actions/tableCustomers";



interface Props {
    onClose: () => void
}

const NewSubnetForm = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form1] = useForm()

    const {customers} = useSelector((state: AppState) => state.auth.tables)
    const {dnsServiceNames, isSaving} = useSelector((state: AppState) => state.ipSubnet)

    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [ipmiChecked, setIpmiChecked] = useState<boolean>()


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        if (!dnsServiceNames || !dnsServiceNames.length) {
            dispatch(getDnsServices())
        }
        if(customers && customers.length) {
            const data = customers.map((c) => {return {
                value: c.id,
                key: c.id,
                label: c.company!.name
            }})
            setCustomerOptions(data.sort(sort_label))
        }
        else {
            dispatch(tableCustomers())
        }
    }, [])

    useEffect(() => {
        setSelectedCustomerId(loggedUser.customer.id)
    }, [customers])

    useEffect(() => {
        console.log('dnsServiceNames: '+dnsServiceNames.length)
    }, [dnsServiceNames])

    const submitSubnet = (values: Store): void => {
        const params: CreateSubnetIpParams = {
            note: values.note,
            customer_id: selectedCustomerId ? selectedCustomerId : loggedUser.customer.id,
            cidr: values.cidr,
            reverse_domain: values.reverse_domain,
            ipmi: values.ipmi ? 1 : 0,
            vlan: values.vlan ? Number(values.vlan) : undefined,
            location: values.location ? values.location : undefined,
            nameserver_id: values.nameserver_id ? values.nameserver_id : undefined
        }
        dispatch(createIpSubnet(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                props.onClose()
            }
        }))
    }

    return (
        <>
            <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '0'}}>
                <Col span={6} className='right' >{t('ipSubnetPage.newSubnet.customer')}:&nbsp;</Col>
                <Col span={18} >
                    <Select optionFilterProp='label'
                            style={{width: '100%'}}
                            options={customerOptions}
                            defaultValue={loggedUser.customer.id}
                            onChange={ (value: number) => { setSelectedCustomerId(value) }}
                            showSearch
                    />
                </Col>
            </Row>

            <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '25px'}}>
                <Col span={24} >

                    <Form name='create-subnet'
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          layout="horizontal"
                          form={form1}
                          onFinish={submitSubnet}
                    >
                        <Form.Item name='note'
                                   label={t('ipSubnetPage.newSubnet.name')}
                                   rules={[{required: true, message: t('errors.field_required')}]}>
                            <Input placeholder='cloud1' />
                        </Form.Item>
                        <Form.Item name='cidr'
                                   label={t('ipSubnetPage.newSubnet.cidr')}
                                   rules={[
                                       {required: true, message: t('errors.field_required')},
                                       {validator: (_, value) => {
                                               if (isCidr(value)) {
                                                   return Promise.resolve();
                                               } else {
                                                   return Promise.reject('Validation CIDR failed.');
                                               }
                                           }, message: t('errors.field_error')
                                       }
                                   ]}>
                            <Input placeholder='185.172.35.0/24' />
                        </Form.Item>
                        <Form.Item name='reverse_domain' label={t('ipSubnetPage.reverse_zone')}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='location' label={t('ipSubnetPage.newSubnet.location')}>
                            <Input placeholder='TTC' />
                        </Form.Item>
                        <Form.Item name='nameserver_id' label={t('ipSubnetPage.newSubnet.ns')}>
                            <Select placeholder='ns.zcom.cz' size='middle'
                                    options={dnsServiceNames.map(i => ({
                                        value: i.id,
                                        key: i.id,
                                        label: i.name
                                    }))}
                                    showSearch
                                    optionFilterProp='label'
                            />
                        </Form.Item>
                        <Form.Item name='vlan' label={t('ipSubnetPage.newSubnet.vlan')}>
                            <Input type='number' min={1} />
                        </Form.Item>
                        <Form.Item name='ipmi' label={t('ipSubnetPage.newSubnet.ipmi')}
                                   initialValue={false}
                                   valuePropName='checked'
                        >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={ipmiChecked}
                                onChange={() => setIpmiChecked(!ipmiChecked)}
                            />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type='primary' htmlType='submit'
                                    loading={isSaving}
                                    className='create-button'>
                                {t('ipSubnetPage.newSubnet.create')}
                            </Button>
                        </Form.Item>
                    </Form>

                </Col>
            </Row>
        </>
    )

}

export default NewSubnetForm