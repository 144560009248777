import {
  UpdateCountryRequestAction,
  UpdateCountryResponseAction,
  UpdateCountryErrorAction
} from '.'

import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

import { Country } from '../models'


export default (params: Country, cb?: (isSuccess: boolean) => void) => {
  return (
    dispatch: (
      arg:
        | UpdateCountryRequestAction
        | UpdateCountryResponseAction
        | UpdateCountryErrorAction
    ) => void,
  ) => {

    const request = new UpdateCountryRequestAction(params)
    dispatch(request)
    protectedApiClient
      .put<Country>(`/country/${params.id}`, params)
      .then((response) => {
        dispatch(new UpdateCountryResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new UpdateCountryErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
