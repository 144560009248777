import {UserLoginErrorAction, UserLoginRequestAction, UserLoginResponseAction} from "../../login/actions";
import {AsyncActionMode} from "../../../common/models";
import {CustomerDetails} from "../../billing/customer/models";
import {LogRecord} from "../models";


export enum ActionType {
    GET_LOG_RECORD = 'GET_LOG_RECORD',
    GET_LOG_RECORDS = 'GET_LOG_RECORDS'
}

export type LogActions =
    | GetLogRecordRequestAction
    | GetLogRecordResponseAction
    | GetLogRecordErrorAction
    | GetLogRecordsRequestAction
    | GetLogRecordsResponseAction
    | GetLogRecordsErrorAction

export class GetLogRecordRequestAction {
    readonly type = ActionType.GET_LOG_RECORD
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}

export class GetLogRecordResponseAction {
    readonly type = ActionType.GET_LOG_RECORD
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetLogRecordRequestAction, public data: LogRecord) {
    }
}

export class GetLogRecordErrorAction {
    readonly type = ActionType.GET_LOG_RECORD
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetLogRecordRequestAction, public error: string) {
    }
}

export class GetLogRecordsRequestAction {
    readonly type = ActionType.GET_LOG_RECORDS
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}

export class GetLogRecordsResponseAction {
    readonly type = ActionType.GET_LOG_RECORDS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetLogRecordsRequestAction, public data: LogRecord[]) {
    }
}

export class GetLogRecordsErrorAction {
    readonly type = ActionType.GET_LOG_RECORDS
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetLogRecordsRequestAction, public error: string) {
    }
}
