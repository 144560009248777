import {
  GetDnsZoneRequestAction,
  GetDnsZoneResponseAction,
  GetDnsZoneErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DnsZoneModel, GetZoneParams } from '../models'


export default (domain_id: number, cb?: (isSuccess: boolean) => void) => {
  return (
    dispatch: (
      arg:
        | GetDnsZoneRequestAction
        | GetDnsZoneResponseAction
        | GetDnsZoneErrorAction
    ) => void
  ) => {
    const request = new GetDnsZoneRequestAction(domain_id)
    dispatch(request)
    protectedApiClient
      .get<DnsZoneModel>(`/domain/${domain_id}/dns/zone`)
      .then((response) => {
        dispatch(new GetDnsZoneResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new GetDnsZoneErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
