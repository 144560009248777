import { AsyncActionMode } from 'common/models'
import {
    CreateMailTemplateParams,
    MailTemplateDetails, SendTestMailTemplateParams,
    SendTestMailTemplateResult,
    UpdateMailTemplateParams
} from '../models'

export enum ActionType {
    GET_TEMPLATE = 'GET_TEMPLATE',
    GET_TEMPLATES = 'GET_TEMPLATES',
    CREATE_TEMPLATE = 'CREATE_TEMPLATE',
    UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
    SEND_TEST_MAIL_TEMPLATE = 'SEND_TEST_MAIL_TEMPLATE',
    DELETE_TEMPLATE = 'DELETE_TEMPLATE'
}

export type MailTemplateActions =
    | CreateMailTemplateRequestAction
    | CreateMailTemplateResponseAction
    | CreateMailTemplateErrorAction
    | GetMailTemplatesRequestAction
    | GetMailTemplatesResponseAction
    | GetMailTemplatesErrorAction
    | GetMailTemplateRequestAction
    | GetMailTemplateResponseAction
    | GetMailTemplateErrorAction
    | UpdateMailTemplateRequestAction
    | UpdateMailTemplateResponseAction
    | UpdateMailTemplateErrorAction
    | DeleteMailTemplateRequestAction
    | DeleteMailTemplateResponseAction
    | DeleteMailTemplateErrorAction
    | SendTestMailTemplateRequestAction
    | SendTestMailTemplateResponseAction
    | SendTestMailTemplateErrorAction

// get
export class GetMailTemplateRequestAction {
    readonly type = ActionType.GET_TEMPLATE
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: number) {
        ''
    }
}

export class GetMailTemplateResponseAction {
    readonly type = ActionType.GET_TEMPLATE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetMailTemplateRequestAction, public data: MailTemplateDetails) {
    }
}

export class GetMailTemplateErrorAction {
    readonly type = ActionType.GET_TEMPLATE
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetMailTemplateRequestAction, public error: string) {
    }
}

// get-all

export class GetMailTemplatesRequestAction {
    readonly type = ActionType.GET_TEMPLATES
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetMailTemplatesResponseAction {
    readonly type = ActionType.GET_TEMPLATES
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetMailTemplatesRequestAction,
        public data: MailTemplateDetails[],
    ) {}
}
export class GetMailTemplatesErrorAction {
    readonly type = ActionType.GET_TEMPLATES
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetMailTemplatesRequestAction, public error: string) {}
}

// create
export class CreateMailTemplateRequestAction {
    readonly type = ActionType.CREATE_TEMPLATE
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: CreateMailTemplateParams) {}
}
export class CreateMailTemplateResponseAction {
    readonly type = ActionType.CREATE_TEMPLATE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: CreateMailTemplateRequestAction,
        public data: MailTemplateDetails,
    ) {}
}
export class CreateMailTemplateErrorAction {
    readonly type = ActionType.CREATE_TEMPLATE
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: CreateMailTemplateRequestAction, public error: string) {}
}

// update
export class UpdateMailTemplateRequestAction {
    readonly type = ActionType.UPDATE_TEMPLATE
    readonly mode = AsyncActionMode.REQUEST
    constructor(public id: UpdateMailTemplateParams) {}
}
export class UpdateMailTemplateResponseAction {
    readonly type = ActionType.UPDATE_TEMPLATE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: UpdateMailTemplateRequestAction,
        public data: MailTemplateDetails,
    ) {}
}
export class UpdateMailTemplateErrorAction {
    readonly type = ActionType.UPDATE_TEMPLATE
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: UpdateMailTemplateRequestAction, public error: string) {}
}

// delete
export class DeleteMailTemplateRequestAction {
    readonly type = ActionType.DELETE_TEMPLATE
    readonly mode = AsyncActionMode.REQUEST
    constructor(public id: number) {
        ''
    }
}
export class DeleteMailTemplateResponseAction {
    readonly type = ActionType.DELETE_TEMPLATE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: DeleteMailTemplateRequestAction,
        public id: number,
    ) {}
}
export class DeleteMailTemplateErrorAction {
    readonly type = ActionType.DELETE_TEMPLATE
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: DeleteMailTemplateRequestAction, public error: string) {}
}

// update
export class SendTestMailTemplateRequestAction {
    readonly type = ActionType.SEND_TEST_MAIL_TEMPLATE
    readonly mode = AsyncActionMode.REQUEST
    constructor(public id: SendTestMailTemplateParams) {}
}
export class SendTestMailTemplateResponseAction {
    readonly type = ActionType.SEND_TEST_MAIL_TEMPLATE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: SendTestMailTemplateRequestAction,
        public data: SendTestMailTemplateResult,
    ) {}
}
export class SendTestMailTemplateErrorAction {
    readonly type = ActionType.SEND_TEST_MAIL_TEMPLATE
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: SendTestMailTemplateRequestAction, public error: string) {}
}