import {
    DeleteUserGroupRequestAction,
    DeleteUserGroupResponseAction,
    DeleteUserGroupErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'


export default (
    id: number,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | DeleteUserGroupRequestAction
                | DeleteUserGroupResponseAction
                | DeleteUserGroupErrorAction,
        ) => void,
    ) => {
        const request = new DeleteUserGroupRequestAction(id)
        dispatch(request)
        protectedApiClient
            .delete(`/user-group/${id}`)
            .then((response) => {
                dispatch(new DeleteUserGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteUserGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
