import {
    DeleteInventoryLocationRequestAction,
    DeleteInventoryLocationResponseAction,
    DeleteInventoryLocationErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | DeleteInventoryLocationRequestAction
                | DeleteInventoryLocationResponseAction
                | DeleteInventoryLocationErrorAction,
        ) => void,
    ) => {
        const request = new DeleteInventoryLocationRequestAction(id)
        dispatch(request)

        protectedApiClient
            .delete(`/inventory/location/${id}`)
            .then((response) => {
                dispatch(new DeleteInventoryLocationResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteInventoryLocationErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
