import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {GetGroupsAllErrorAction, GetGroupsAllRequestAction, GetGroupsAllResponseAction} from "./index";
import {UserGroupModel} from "../../usergroups/models";

export default (cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (args: GetGroupsAllRequestAction | GetGroupsAllResponseAction | GetGroupsAllErrorAction) => void) => {
        const request = new GetGroupsAllRequestAction()
        dispatch(request)

        protectedApiClient.get<UserGroupModel[]>(`/user-group`)
            .then((response) => {
                dispatch(new GetGroupsAllResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch(error => {
                dispatch(new GetGroupsAllErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}