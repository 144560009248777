import React, {useEffect, useState} from 'react'
import {Card, Col, Row, Select, Skeleton, Space, Table} from 'antd'
import {DeleteTwoTone} from '@ant-design/icons/lib/icons'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import Button from 'antd-button-color'
import {sum} from 'helpers/arrayHelpers'
import deleteInvoiceCostAllocation from './actions/deleteInvoiceCostAllocation'
import createInvoiceCostAllocation from './actions/createInvoiceCostAllocation'
import {message} from 'antd'
import './InvoiceCostAllocationPage.scss'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import {InvoiceDetails} from "../invoice/models";
import {AppState} from "../../../common/models";
import getInvoiceCostAllocationByInvoice from "./actions/getInvoiceCostAllocationByInvoice";
import {InvoiceCostAllocationDetails, InvoiceCostAllocationParams} from "./models";
import {CheckCircleOutlined} from "@ant-design/icons";
import getCostAllocationCategories from "./actions/getCostAllocationCategories";
import {CostAllocationCategoryDetails} from "../costallocation/models";


interface Props {
    invoice: InvoiceDetails
    title: string
}

const InvoiceCostAllocationPage = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {invoice, title} = props

    const {invoiceAllocations, costAllocationCategories, error} = useSelector((state: AppState) => state.invoiceCostAllocation)

    const [dataSource, setDataSource] = useState<InvoiceCostAllocationDetails[]>([])
    const [isCostAllocationCategoryLoading, setCostAllocationCategoryLoading] = useState<boolean>(false)
    const [isInvoiceCostAllocationLoading, setInvoiceCostAllocationLoading] = useState<boolean>(false)
    const [rest, setRest] = useState<string>('0')
    const [total, setTotal] = useState<number>(0)
    const [categoryId, setCategoryId] = useState<number>(1)
    const [note, setNote] = useState<string>('')

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator/>
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('InvoiceAllocationPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('InvoiceAllocationPage: ' + msg)} }

    const disableRest = () => {
        // disable ADD button
        if (!invoice.total_without_vat) {
            return true
        }
        if (invoice.total_without_vat == total) {
            return true
        }
        if (invoice.total_without_vat > 0) {
            // invoice
            const diff = invoice.total_without_vat - total
            return diff <= 0
        }
        else {
            // credit note
            const diff = invoice.total_without_vat + total
            return diff >= 0
        }
    }

    useEffect(() => {
        if (!costAllocationCategories || costAllocationCategories.length === 0) {
            // load costAllocationCategories
            setCostAllocationCategoryLoading(true)
            dispatch(getCostAllocationCategories(0,(suc) => {
                setCostAllocationCategoryLoading(false)
            }))
        }
    }, [])

    useEffect(() => {
        if (invoice) {
            setInvoiceCostAllocationLoading(true)
            dispatch(getInvoiceCostAllocationByInvoice(invoice.id, (suc) => setInvoiceCostAllocationLoading(false)))
        }
    }, [invoice.id])

    useEffect(() => {
        if (invoice && invoice.total_without_vat && costAllocationCategories && costAllocationCategories.length) {
            setDataSource(invoiceAllocations)
            const t = sum(invoiceAllocations, 'price')
            const r = invoice.total_without_vat - t
            setTotal(t)
            setRest(appSetting.renderPrice(r))
            setCategoryId(Math.min(...costAllocationCategories.map((cat: CostAllocationCategoryDetails) => cat.id!)))
        }
    }, [invoiceAllocations, costAllocationCategories, invoice])

    const columns = [
        {
            title: t('billing.invoice-cost-allocation.title.category'),
            dataIndex: 'cost_allocation_category_id',
            key: 'cost_allocation_category_id',
            width: '20%',
            render: (id) => costAllocationCategories?.find((c) => c.id === id)?.name,
        },
        {
            title: t('billing.invoice-cost-allocation.title.price') + ` [${invoice.currency}]`,
            className: 'right',
            dataIndex: 'price',
            key: 'price',
            width: '20%',
            render: (price: number) => appSetting.renderPrice(price),
        },
        {
            title: t('billing.invoice-cost-allocation.title.note'),
            dataIndex: 'note',
            key: 'note',
            width: '50%',
        },
        {
            title: '',
            dataIndex: 'operation',
            key: 'operation',
            width: '10%',
            render: (id, rec) =>
                dataSource.length >= 1 ? (
                    <Button title={t('billing.invoice-cost-allocation.delete_btn')}
                            type='text' danger
                            size='small'
                            disabled={!loggedUser.hasAccess('page_billing_invoice_edit_button')}
                            onClick={() => handleDelete(rec.id)}
                            icon={<DeleteTwoTone twoToneColor='red'/>}/>
                ) : null,
        },
    ];

    const handleDelete = (id: number) => {
        dispatch(deleteInvoiceCostAllocation(id, suc => {
            if (suc) {
                message.success(t('billing.invoice-cost-allocation.deleted'))
                setNote('')
            }
            else {
                message.error(error.message)
            }
        }))
    };

    const handleAdd = () => {
        if (!invoice.total_without_vat) {
            return
        }

        let p = parseFloat(rest.replace(',', '.'))
        if (p == 0) {
            return
        }
        p = Math.round(p * 100)
        if (invoice.total_without_vat > 0) {
            const oldRest = invoice.total_without_vat - total
            if (oldRest < p) {
                message.error(t('billing.invoice-cost-allocation.not_save'))
                return
            }
        }
        else {
            const oldRest = invoice.total_without_vat - total
            if (oldRest > p) {
                message.error(t('billing.invoice-cost-allocation.not_save'))
                return
            }
        }

        const newData: InvoiceCostAllocationParams = {
            invoice_id: invoice.id,
            cost_allocation_category_id: categoryId,
            price: p,
            note: note
        }
        dispatch(
            createInvoiceCostAllocation(newData, suc => {
                if (suc) {
                    message.success(t('billing.invoice-cost-allocation.created'))
                    setNote('')
                }
                else {
                    message.error(error.message)
                }
            }))
    };



    if (invoice.state === 1) {
        return (<Card title={props.title} className='invoiceAllocationCard'>&nbsp;</Card>)
    }

    if (isInvoiceCostAllocationLoading || isCostAllocationCategoryLoading) {
        return (
            <Card title={props.title} className='invoiceAllocationCard'>
                <Skeleton/>
            </Card>
        )
    }

    return (
        <Card title={props.title} className='invoiceAllocationCard'>
            <Table
                rowKey="id"
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                pagination={false}
                columns={columns}
            />

            {
                disableRest() ?
                    <Space className='done'>
                        {t('billing.invoice-cost-allocation.done')}<CheckCircleOutlined />
                    </Space>

                :
                    <Row style={{marginTop: '24px'}}>
                        <Col style={{width: '20%'}}>
                            <Select
                                disabled={disableRest()}
                                optionFilterProp='label'
                                placeholder={t('billing.invoice-cost-allocation.allocate-to')}
                                className='costAllocationSelect'
                                style={{width: '100%', marginLeft: '4px'}}
                                defaultValue={categoryId}
                                onChange={(v) => {setCategoryId(v)}}
                                options={costAllocationCategories.map(c => ({ value: c.id, key: c.id, label: c.name }))}
                            />
                        </Col>
                        <Col style={{width: '20%'}}>
                            <input type='text'
                                   value={rest}
                                   onChange={(e) => {setRest(e.target.value)}}
                                   disabled={disableRest()}
                                   style={{width: '100%', border: '1px solid #dedede', textAlign: 'right', padding: '4px', marginLeft: '2px'}}/>
                        </Col>
                        <Col style={{width: '40%'}}>
                            <input type='text'
                                   value={note}
                                   onChange={(e) => {setNote(e.target.value)}}
                                   disabled={disableRest()}
                                   style={{width: '100%', border: '1px solid #dedede', padding: '4px'}}/>
                        </Col>
                        <Col style={{width: '10%'}}>
                            <Button type="success"
                                    onClick={handleAdd}
                                    disabled={disableRest() || !loggedUser.hasAccess('page_billing_invoice_edit_button')}
                                    style={{marginBottom: 16}}>
                                {t('billing.invoice-cost-allocation.add_btn')}
                            </Button>
                        </Col>
                    </Row>
            }

        </Card>
    )
}

export default InvoiceCostAllocationPage
