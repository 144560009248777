import {
    AddIpRequestAction,
    AddIpResponseAction,
    AddIpErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {AddIpParams, IpAddressModel, IpSubnetModel} from '../models'

export default (
    params: AddIpParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | AddIpRequestAction
                | AddIpResponseAction
                | AddIpErrorAction,
        ) => void,
    ) => {
        const request = new AddIpRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<IpAddressModel>(`/ip/ip/${params.id}/add-ip`, params)
            .then((response) => {
                dispatch(new AddIpResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new AddIpErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
