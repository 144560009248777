import {AsyncActionMode} from "../../../../common/models";
import {ResEntity, ResGroupModel, ResourceModel, ResourceParams, ResourceRowModel} from "../models";


export enum ActionType {
    GET_ENTITIES = 'GET_ENTITIES',
    GET_RESGROUP = 'GET_RESGROUP',
    GET_RESGROUPS = 'GET_RESGROUPS',
    CREATE_RESGROUP = 'CREATE_RESGROUP',
    UPDATE_RESGROUP = 'UPDATE_RESGROUP',
    DELETE_RESGROUP = 'DELETE_RESGROUP',
    GET_RESOURCES = 'GET_RESOURCES',
    GET_RESOURCE = 'GET_RESOURCE',
    SELECT_RESOURCE = 'SELECT_RESOURCE',
    GET_ALL_RESOURCES = 'GET_ALL_RESOURCES',
    UPDATE_RESOURCE = 'UPDATE_RESOURCE'
}

export type ResourceActions =
    | GetEntitiesRequestAction
    | GetEntitiesResponseAction
    | GetEntitiesErrorAction
    | GetResGroupRequestAction
    | GetResGroupResponseAction
    | GetResGroupErrorAction
    | GetResGroupsRequestAction
    | GetResGroupsResponseAction
    | GetResGroupsErrorAction
    | CreateResGroupRequestAction
    | CreateResGroupResponseAction
    | CreateResGroupErrorAction
    | UpdateResGroupRequestAction
    | UpdateResGroupResponseAction
    | UpdateResGroupErrorAction
    | DeleteResGroupRequestAction
    | DeleteResGroupResponseAction
    | DeleteResGroupErrorAction
    | SelectResourceRequestAction
    | GetResourceRequestAction
    | GetResourceResponseAction
    | GetResourceErrorAction
    | GetResourcesRequestAction
    | GetResourcesResponseAction
    | GetResourcesErrorAction
    | GetAllResourcesRequestAction
    | GetAllResourcesResponseAction
    | GetAllResourcesErrorAction
    | UpdateResourceRequestAction
    | UpdateResourceResponseAction
    | UpdateResourceErrorAction


export class GetEntitiesRequestAction {
    readonly type = ActionType.GET_ENTITIES
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetEntitiesResponseAction {
    readonly type = ActionType.GET_ENTITIES
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetEntitiesRequestAction,
        public data: ResEntity[],
    ) {}
}
export class GetEntitiesErrorAction {
    readonly type = ActionType.GET_ENTITIES
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetEntitiesRequestAction,
        public error: string,
    ) {}
}

export class GetResGroupRequestAction {
    readonly type = ActionType.GET_RESGROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(id: number) {
        ''
    }
}
export class GetResGroupResponseAction {
    readonly type = ActionType.GET_RESGROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetResGroupRequestAction,
        public data: ResGroupModel,
    ) {}
}
export class GetResGroupErrorAction {
    readonly type = ActionType.GET_RESGROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetResGroupRequestAction,
        public error: string,
    ) {}
}

export class GetResGroupsRequestAction {
    readonly type = ActionType.GET_RESGROUPS
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetResGroupsResponseAction {
    readonly type = ActionType.GET_RESGROUPS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetResGroupsRequestAction,
        public data: ResGroupModel[],
    ) {}
}
export class GetResGroupsErrorAction {
    readonly type = ActionType.GET_RESGROUPS
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetResGroupsRequestAction,
        public error: string,
    ) {}
}


export class CreateResGroupRequestAction {
    readonly type = ActionType.CREATE_RESGROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: ResGroupModel) {
    }
}

export class CreateResGroupResponseAction {
    readonly type = ActionType.CREATE_RESGROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: CreateResGroupRequestAction,
        public data: ResGroupModel,
    ) {
    }
}

export class CreateResGroupErrorAction {
    readonly type = ActionType.CREATE_RESGROUP
    readonly mode = AsyncActionMode.ERROR

    constructor(
        public request: CreateResGroupRequestAction,
        public error: string,
    ) {
    }
}

export class UpdateResGroupRequestAction {
    readonly type = ActionType.UPDATE_RESGROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public item: ResGroupModel) {
    }
}

export class UpdateResGroupResponseAction {
    readonly type = ActionType.UPDATE_RESGROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: UpdateResGroupRequestAction,
        public data: ResGroupModel,
    ) {
    }
}

export class UpdateResGroupErrorAction {
    readonly type = ActionType.UPDATE_RESGROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: UpdateResGroupRequestAction,
        public error: string,
    ) {
    }
}

export class DeleteResGroupRequestAction {
    readonly type = ActionType.DELETE_RESGROUP
    readonly mode = AsyncActionMode.REQUEST
    constructor(public id: number) {
        ''
    }
}

export class DeleteResGroupResponseAction {
    readonly type = ActionType.DELETE_RESGROUP
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: DeleteResGroupRequestAction,
        public data: ResGroupModel,
    ) {
    }
}

export class DeleteResGroupErrorAction {
    readonly type = ActionType.DELETE_RESGROUP
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: DeleteResGroupRequestAction,
        public error: string,
    ) {
    }
}


export class SelectResourceRequestAction {
    readonly type = ActionType.SELECT_RESOURCE
    readonly mode = AsyncActionMode.REQUEST
    constructor(public resource: ResourceRowModel) {
        ''
    }
}

export class GetResourceRequestAction {
    readonly type = ActionType.GET_RESOURCE
    readonly mode = AsyncActionMode.REQUEST
    constructor(id: number) {
        ''
    }
}
export class GetResourceResponseAction {
    readonly type = ActionType.GET_RESOURCE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetResourceRequestAction,
        public data: ResourceModel,
    ) {}
}
export class GetResourceErrorAction {
    readonly type = ActionType.GET_RESOURCE
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetResourceRequestAction,
        public error: string,
    ) {}
}

export class GetResourcesRequestAction {
    readonly type = ActionType.GET_RESOURCES
    readonly mode = AsyncActionMode.REQUEST
    constructor(id: number) {
        ''
    }
}
export class GetResourcesResponseAction {
    readonly type = ActionType.GET_RESOURCES
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetResourcesRequestAction,
        public data: ResGroupModel,
    ) {}
}
export class GetResourcesErrorAction {
    readonly type = ActionType.GET_RESOURCES
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetResourcesRequestAction,
        public error: string,
    ) {}
}


export class GetAllResourcesRequestAction {
    readonly type = ActionType.GET_ALL_RESOURCES
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetAllResourcesResponseAction {
    readonly type = ActionType.GET_ALL_RESOURCES
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetAllResourcesRequestAction,
        public data: ResourceModel[],
    ) {}
}
export class GetAllResourcesErrorAction {
    readonly type = ActionType.GET_ALL_RESOURCES
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetAllResourcesRequestAction,
        public error: string,
    ) {}
}

export class UpdateResourceRequestAction {
    readonly type = ActionType.UPDATE_RESOURCE
    readonly mode = AsyncActionMode.REQUEST
    constructor(params: ResourceParams) {}
}

export class UpdateResourceResponseAction {
    readonly type = ActionType.UPDATE_RESOURCE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: UpdateResourceRequestAction,
        public data: ResGroupModel,
    ) {
    }
}

export class UpdateResourceErrorAction {
    readonly type = ActionType.UPDATE_RESOURCE
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: UpdateResourceRequestAction,
        public error: string,
    ) {
    }
}
