import {
    CreateContactRequestAction,
    CreateContactResponseAction,
    CreateContactErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { ContactModel, ContactBase } from '../models'

export default (params: ContactBase, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | CreateContactRequestAction
                | CreateContactResponseAction
                | CreateContactErrorAction,
        ) => void,
    ) => {
        const request = new CreateContactRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<ContactModel>(`/customer/${params.customer_id}/contact`, params)
            .then((response) => {
                dispatch(new CreateContactResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new CreateContactErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
