import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    GetLogRecordsRequestAction,
    GetLogRecordsResponseAction,
    GetLogRecordsErrorAction,
} from '.'
import { LogRecord } from '../models'

export default (qs: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetLogRecordsRequestAction
                | GetLogRecordsResponseAction
                | GetLogRecordsErrorAction,
        ) => void,
    ) => {
        const request = new GetLogRecordsRequestAction()
        dispatch(request)

        protectedApiClient
            .get<LogRecord[]>('/log?'+qs)
            .then((response) => {
                dispatch(
                    new GetLogRecordsResponseAction(request, response.data),
                )
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetLogRecordsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
