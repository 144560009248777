import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {AppState} from "../../../common/models";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import {Card, Col, message, Row, Space, Table, Modal} from "antd";
import Button from "antd-button-color";
import {
    DeleteTwoTone,
    EditTwoTone,
    ExclamationCircleOutlined,
    InfoCircleTwoTone,
    ShopOutlined
} from "@ant-design/icons";
import {PlusCircleOutlined} from "@ant-design/icons/lib/icons";
import TotalNum from "../../../components/TotalNum/TotalNum";
import getLocations from "./actions/getLocations";
import {InventoryLocationModel} from "./models";
import InventoryLocationForm from "./InventoryLocationForm";
import deleteLocation from "./actions/deleteLocation";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {sort_name} from "../../../common/sorting";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import HistoryModal from "../../../components/History/HistoryModal";

const { confirm } = Modal

const InventoryLocationPage = () => {
    const CONTROL_NAME = 'page_inventory_locations'
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {locations, isLoading} = useSelector((state: AppState) => state.inventorylocation)
    const {inventory_locations} = useSelector((state: AppState) => state.sidenav)

    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [dataSource, setDataSource] = useState<InventoryLocationModel[]>()
    const [isModalVisible, setModalVisible] = useState(false)
    const [dataToUpdate, setDataToUpdate] = useState<InventoryLocationModel>()

    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [isEditor, setEditor] = useState(false)

    // get settings and logged user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    let triggerOneTimer = 0

    useEffect(() => {
        // trigger ONCE
        triggerOneTimer = Date.now().valueOf()
        setEditor(loggedUser.hasAccess('page_inventory_locations_editor'))
        dispatch(getLocations())
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }
    }, [locations, isModalVisible])

    useEffect(() => {
        // trigger from Side menu clicked
        if (!isLoading) {
            const when = triggerOneTimer + 1000
            if (when < Date.now().valueOf()) {
                // when triggerOneTimer was not called (after 1sec. of SideMenu click)
                dispatch(getLocations())
            }
        }
    }, [inventory_locations])

    const refreshGrid = () => {
        setDataSource(locations.sort(sort_name))
    }

    const showConfirmDelete = (obj: InventoryLocationModel, title: string) => {
        if (!obj) {
            return
        }
        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{obj.name}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteLocation(obj.id,suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const columns = [
        {
            title: t('inventoryPage.name'),
            dataIndex: 'name',
            key: 'name',
            width: '90%'
        },
        {
            title: 'Action',
            key: 'action',
            width: '100px',
            dataIndex: 'action',
            render: (_, record: InventoryLocationModel) => (
                <Space size='small'>
                    {
                        isEditor && (
                            <Button type='text'
                                    size='small'
                                    onClick={() => {
                                        setDataToUpdate(record)
                                        setModalVisible(true)
                                    }}
                                    className='actionButton'
                                    icon={<EditTwoTone twoToneColor='green' />}
                            />
                        )
                    }

                    {
                        isEditor && (
                            <Button type='text' danger size='small'
                                    className='actionButton'
                                    onClick={() => showConfirmDelete(record, t('inventoryLocationPage.confirm_delete'))}
                                    icon={<DeleteTwoTone twoToneColor='red'/>} />
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(record.id)
                                setHistoryTitle(record.name)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                </Space>
            ),
        },
    ]


    return (
        <>
            <Card className='inventoryPage'
                  title={
                      <Row>
                          <Col span={6}><ShopOutlined />{`  ${t('inventoryLocationPage.title')}`}</Col>
                          <Col span={18}>&nbsp;</Col>
                      </Row>
                  }
                  extra={
                      <Button type='primary'
                              disabled={!isEditor}
                              onClick={() => {
                                  setDataToUpdate(undefined)
                                  setModalVisible(true)
                              }}>
                          <PlusCircleOutlined /> {t('general.create')}
                      </Button>
                  }
            >
                {
                    appSetting &&
                    <Table<InventoryLocationModel>
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columns}
                        loading={isLoading}
                        dataSource={dataSource}
                        className='inventoryPage'
                        rowKey='id'
                        pagination={false}
                        footer={() => TotalNum(Number(dataSource?.length), 'Location', dataSource)}
                    />
                }
            </Card>

            <Modal
                destroyOnClose={true}
                style={{top: 20}}
                title={
                    <>
                        <ShopOutlined /> {dataToUpdate ? t('inventoryLocationPage.update_title') : t('inventoryLocationPage.create_title')}
                    </>
                }
                visible={isModalVisible}
                onCancel={() => setModalVisible(false)}
                maskClosable={false}
                footer={null}>
                <InventoryLocationForm dataToUpdate={dataToUpdate} setModalVisible={setModalVisible} />
            </Modal>

            <HistoryModal service='inventory' model='Location'
                          modelId={historyModelId}
                          title={historyTitle}
                          isModalVisible={isHistoryModalVisible}
                          setModalVisible={() => setHistoryModalVisible(false)}
                          modalRender={(modal) => (
                              <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                  <div ref={draggleRef}>{modal}</div>
                              </Draggable>
                          )}
            />

        </>
    )
}

export default InventoryLocationPage