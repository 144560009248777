import { ActionType, InvoiceCostAllocationActions } from './actions'
import { AsyncActionMode } from 'common/models'
import {InvoiceCostAllocationDetails, InvoiceCostAllocationState} from './models'
import {CostAllocationCategoryDetails} from "../costallocation/models";

const INVOICE_COSTALLOCATION_INITIAL_STATE: InvoiceCostAllocationState = {
  isLoading: false,
  isSaving: false,
  error: undefined,
  invoiceAllocations: [],
  costAllocationCategories: []
}

export default (
  state = INVOICE_COSTALLOCATION_INITIAL_STATE,
  action: InvoiceCostAllocationActions,
): InvoiceCostAllocationState => {
  switch (action.type) {
    case ActionType.GET_INVOICE_COSTALLOCATIONS_BY_INVOICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          invoiceAllocations: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_INVOICE_COSTALLOCATIONS_BY_CATEGORY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          invoiceAllocations: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_INVOICE_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          invoiceAllocation: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_INVOICE_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          invoiceAllocations: state.invoiceAllocations?.concat(action.data),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_INVOICE_COSTALLOCATIONS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          invoiceAllocations: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_INVOICE_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          invoiceAllocations: state.invoiceAllocations!.filter(
            (allocation) => allocation.id !== action.request.id,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.UPDATE_INVOICE_COSTALLOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          invoiceAllocations: state.invoiceAllocations!.map((allocation) =>
            allocation.id === action.data.id ? { ...action.data } : allocation,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_COST_CATEGORIES_INVOICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          costAllocationCategories: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
  }
  return state
}
