import {
    GetCustomerUsersErrorAction,
    GetCustomerUsersRequestAction,
    GetCustomerUsersResponseAction
} from "./index";
import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {UserDetails} from "../../../user/models";


export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetCustomerUsersRequestAction
                | GetCustomerUsersResponseAction
                | GetCustomerUsersErrorAction
        ) => void,
    ) => {
        const request = new GetCustomerUsersRequestAction()
        dispatch(request)

        protectedApiClient
            .get<UserDetails[]>(
                `/customer/${id}/user`,
            )
            .then((response) => {
                dispatch(new GetCustomerUsersResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetCustomerUsersErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
