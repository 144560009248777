import { ActionType, DnsServiceActions } from './actions'
import {AsyncActionMode, PAGE1} from 'common/models'
import {DnsServiceModel, DnsPageState, DnsZone} from './models'

const DNSSERVICE_INITIAL_STATE: DnsPageState = {
  isLoading: false,
  isLoadingService: false,
  isLoadingArchive: false,
  isLoadingDomain: false,
  isSaving: false,
  isSavingZone: false,
  isLoadingZone: false,
  domain: undefined,
  domains: {items: [], pagination: PAGE1},
  pager: PAGE1,
  dnsZones: [],
  dnsServices: [],
  dnsServiceNames: [],
  dnsRecords: [],
  exportedFile: '',
  dnsChecks: {},
  lookup_domain: [],
  isLoadingLookup: false,
  error: ''
}

export default (state = DNSSERVICE_INITIAL_STATE, action: DnsServiceActions): DnsPageState => {
  switch (action.type) {
    case ActionType.GET_DOMAINS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          domains: action.data,
          pager: {...action.data.pagination, page: action.data.pagination.page+1},
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break

    case ActionType.GET_DOMAIN:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, domain: undefined, isLoadingDomain: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          domain: action.data,
          isLoadingDomain: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingDomain: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_DOMAIN:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        // add to first pos.
        let new_list = [...state.domains.items]  // force new obj
        new_list.unshift(action.data)
        return {
          ...state,
          domains: {
            ...state.domains,
            items: new_list
          },
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isSaving: false,
        }
      }
      break
    case ActionType.ADD_DNSSERVICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          domain: undefined,
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.REMOVE_DNSSERVICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          domain: undefined,
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_DOMAIN:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          domain: undefined,
          domains: {
            ...state.domains,
            items: state.domains.items.filter(
                (s) => s.id !== action.request.id,
            )},
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_DNSSERVICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, dnsService: undefined, isLoadingService: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsService: action.data,
          isLoadingService: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingService: false,
          error: action.error,
        }
      }
      break
    case ActionType.CLEAR_DNSSERVICE:
      return { ...state, isLoadingService: false, dnsService: undefined }
      break
    case ActionType.CREATE_DNSSERVICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsServices: state.dnsServices.concat(action.data),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isSaving: false,
        }
      }
      break
    case ActionType.GET_DNSSERVICEES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoadingService: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoadingService: false,
          dnsServiceNames: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingService: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_DNSSERVICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsServices: state.dnsServices!.filter((dnsservice) => dnsservice.id !== action.request.id),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.UPDATE_DNSSERVICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsServices: state.dnsServices!.map((dnsservice) =>
            dnsservice.id === action.data.id ? { ...action.data } : dnsservice,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.SET_DNSSEC_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsZone: action.data,
          isSavingZone: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingZone: false,
          error: action.error
        }
      }
      break
    case ActionType.CLEAR_ARCHIVED:
      return { ...state, isLoadingArchive: false, archived: undefined }
    case ActionType.GET_ARCHIVED_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoadingArchive: true, archived: undefined }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          archived: action.data,
          isLoadingArchive: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingArchive: false,
          error: action.error,
        }
      }
      break
    case ActionType.EXPORT_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoadingArchive: true, exportedFile: '' }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          exportedFile: action.data,
          isLoadingArchive: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingArchive: false,
          error: action.error,
        }
      }
      break
    case ActionType.ADD_RECORD_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSavingZone: false,
          dnsZone: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingZone: false,
          error: action.error,
        }
      }
      break
    case ActionType.REMOVE_RECORD_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSavingZone: false,
          dnsZone: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingZone: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          dnsRecords: [],
          dnsZone: undefined,
          isLoadingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsZone: action.data,
          dnsRecords: action.data.records,
          isLoadingZone: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingZone: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          dnsRecords: [],
          dnsZone: undefined,
          isLoadingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsZone: action.data,
          dnsRecords: action.data.records,
          isLoadingZone: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingZone: false,
          error: action.error,
        }
      }
      break
    case ActionType.CHECK_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        let allChecks = state.dnsChecks
        allChecks[action.data.domain] = action.data
        return {
          ...state,
          dnsChecks: {...allChecks}
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          // dnsZones: state.dnsZones?.concat(action.data),
          isSavingZone: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isSavingZone: false,
        }
      }
      break
    case ActionType.GET_DNS_ZONES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          dnsZones: [],
          dnsChecks: {},
          isLoadingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        let data:DnsZone[] = []
        if (Array.isArray(action.data)) {
          data = action.data
        }
        else {
          Object.entries(action.data).forEach(([key, value]) => {
            // @ts-ignore
            data.push(value)
          });
        }

        return {
          ...state,
          isLoadingZone: false,
          dnsZones: data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingZone: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsZones: state.dnsZones!.filter(
              (dnszone) => dnszone.domain !== action.request.params.zone
          ),
          isSavingZone: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingZone: false,
          error: action.error
        }
      }
      break


    case ActionType.LOOKUP_DOMAIN:
      if (action.mode === AsyncActionMode.REQUEST) {
          return { ...state,
            isLoadingLookup: true,
            lookup_domain: []
          }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        if (action.field === 'domain') {
          return { ...state,
            isLoadingLookup: false,
            lookup_domain: action.data,
          }
        }
        else {
          return { ...state, isLoadingLookup: false}
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isLoadingLookup: false
        }
      }
      break
  }
  return state
}
