import {
    CheckZoneRequestAction,
    CheckZoneResponseAction,
    CheckZoneErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {DnsCheck, GetZoneParams} from '../models'

const x = d => {
    if (d.endsWith('cz.')) {
        return 'NOT_AUTORITATIVE'
    } else {
        if (d.endsWith('com.')) {
            return 'IS_AUTORITATIVE'
        } else {
            return 'NOT_FOUND'
        }
    }
}

export default (params: GetZoneParams, cb?: (isSuccess: boolean, zone: string) => void) => {
    return (
        dispatch: (
            arg:
                | CheckZoneRequestAction
                | CheckZoneResponseAction
                | CheckZoneErrorAction
        ) => void
    ) => {
        const request = new CheckZoneRequestAction(params)
        dispatch(request)
        const url = `/server/service/${params.service_id}/dns-check?domain=${params.domain}`
        protectedApiClient
            .get<{any}>(url)
            .then((response) => {
                for (const key in response.data) {
                    dispatch(new CheckZoneResponseAction(request, response.data[key]))
                    break
                }
                cb && cb(true, params.domain)
            })
            .catch((error) => {
                dispatch(new CheckZoneErrorAction(request, error))
                // handleApiErrorWithNotification(error)
                cb && cb(false, params.domain)
            })
    }
}
