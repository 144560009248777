import {
    GetRoleRequestAction,
    GetRoleResponseAction,
    GetRoleErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { RoleModel } from '../models'

export default (casbin_key: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetRoleRequestAction
                | GetRoleResponseAction
                | GetRoleErrorAction,
        ) => void,
    ) => {
        const request = new GetRoleRequestAction(casbin_key )
        dispatch(request)

        protectedApiClient
            .get<RoleModel>(`/role/${casbin_key}?expand=endpoints`)
            .then((response) => {
                dispatch(new GetRoleResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetRoleErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
