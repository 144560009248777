import {
    AuthConfirmErrorAction,
    AuthConfirmRequestAction,
    AuthConfirmResponseAction
} from "./index";
import {apiClient} from "../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../helpers/errorHandling";
import {Auth2faResponseData, Params2FA} from "../models";


export default (params: Params2FA, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | AuthConfirmRequestAction
                | AuthConfirmResponseAction
                | AuthConfirmErrorAction,
        ) => void,
    ) => {
        const request = new AuthConfirmRequestAction(params)
        dispatch(request)
        apiClient
            .put<Auth2faResponseData>(`/auth/confirm`, params)
            .then((response) => {
                dispatch(new AuthConfirmResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new AuthConfirmErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}

