import {
    RemoveInventoryBaseRequestAction,
    RemoveInventoryBaseResponseAction,
    RemoveInventoryBaseErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {
    PhysicalServerModel,
} from '../models'

export default (
    server_id: number,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | RemoveInventoryBaseRequestAction
                | RemoveInventoryBaseResponseAction
                | RemoveInventoryBaseErrorAction,
        ) => void,
    ) => {
        const request = new RemoveInventoryBaseRequestAction(server_id)
        dispatch(request)

        protectedApiClient
            .put<PhysicalServerModel>(
                `/inventory/physical-server/${server_id}/remove-inventory`,
                {id: server_id},
            )
            .then((response) => {
                dispatch(
                    new RemoveInventoryBaseResponseAction(
                        request,
                        response.data,
                    ),
                )
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new RemoveInventoryBaseErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
