

export interface HypervisorPageState {
    isSaving?: boolean
    isLoading?: boolean
    isLoadingHypervisor?: boolean,
    isLoadingVps?: boolean,
    isScaning?: boolean,
    resultScaning?: string | unknown,
    hypervisor?: HypervisorModel,
    container?: HypervisorModel,
    hypervisorHystories: HistoryModel[],
    containerChanges: ChangesModel[],
    hypervisors: HypervisorModel[],
    vpsServer?: HypervisorModel,
    vpsServers: HypervisorModel[],
    vpsSettings: VpsSettingModel[],
    vpsSetting?: VpsSettingModel,
    logsSacanning?: LogsScanningModel[],
    error?: string
}

export interface LogsScanningModel {
    highlight_ranges?: object,
    message: MessageLogModel,
    index?: string,
    decoration_stats?: string
}
export interface MessageLogModel {
    zis_version?: string,
    gl2_accounted_message_size?: number,
    level?: number,
    service_name?: string,
    gl2_remote_ip?: string,
    gl2_remote_port?: string,
    streams?: string[],
    gl2_message_id?: string,
    source?: string,
    message?: string,
    gl2_source_input?: string,
    environment?: string,
    hostname?: string,
    trace?: string,
    method_name?: string,
    context?: string,
    gl2_source_node?: string,
    _id?: string,
    facility?: string,
    class_name?: string,
    timestamp?: string,
}

export interface ContainerStorages {
    id?: number,
    container_id: number,
    storage_id: number,
    disk_size: number,
    state: number,
}
export interface ChangesModel {
    id?: number,
    device_id: number,
    operation: string,
    type: string,
    name: string,
    origin_value: string,
    new_value: string,
    created_at: number,
    updated_at: number,
    container: HypervisorModel
}

export interface StorageBase {
    name: string
    type: string
    category: string
    auto_type: number
    total_size: number
    free_space: number
    shared: boolean
    enable: boolean
    active: boolean
    last_update?: number
    created_at?: number
}

export interface StorageModel extends StorageBase {
    id: number
}

export interface HypervisorBase {
    server_id: number
    physical_id: number
    vps_id: number
    type: string
    name: string
    host: string
    main_ip: string
    ipmi_ip: string
    cpu_type: string
    cpu_count: number
    cpu_occupied: number
    ram_total: number
    ram_free: number
    disk_total: number
    state: number
    last_update: number
    version: number
    uptime: number
    created_at: number
}

export interface HistoryModel {
    id?: number,
    container_id: number,
    name: string,
    type: number,
    host: string,
    vps_id: number,
    cpu_type: string,
    cpu_count: number,
    ram_total: number,
    ram_free: number,
    disk_size: number,
    state: string // need check in Back end
    created_at: number
}

export interface HypervisorModel extends HypervisorBase {
    id: number
    storages: StorageModel[]
    containerStorages: ContainerStorages[]
}

export interface VpsSettingModel {
    id?: number,
    host: string,
    username: string,
    password: string,
    state: number | string,
    note: string,
    created_at?: number,
    updated_at?: number
}

const VPS_STANDARD = 3;
const VPS_BUSINESS = 4;
const VPS_CORPORATE = 5;


export const vpsTypes = ["STANDARD", "BUSINESS", "CORPORATE"];

export const stateVpsSetting = ["ACTIVE", "PAUSED", "STOPPED", "DELETED"];
export const stateHypervisorSetting = ["UNKNOWN", "OFFLINE", "ONLINE"];
export const stateSuccesScaning = ["OK", "ERROR"];
export const freeColor = '#00A536'
export const disableColor = '#C0C0C0'

export const colorsList = [
    '#98FB98',
    '#6B8E23',
    '#66CDAA',
    '#C0C0C0',
    '#00FFFF',
    '#E0FFFF',
    '#AFEEEE',
    '#7FFFD4',
    '#5F9EA0',
    '#B0C4DE',
    '#1E90FF',
    '#7B68EE',
    '#DDA0DD',
    '#BA55D3',
    '#9400D3',
    '#483D8B',
    '#DEB887',
    '#F4A460',
    '#B8860B',
    '#8B4513',
    '#808080',
    '#FFA07A',
    '#FFC0CB',
    '#8FBC8F',
    '#FAEBD7',
    '#2F4F4F',
    '#FFFF00',
    '#FFDAB9',
    '#BDB76B',
    '#FFD700',
    '#FA8072',
]


export class VpsHelper {
    static sort_numbers = (a, b) => a - b
    static convertedState(state: number | string) {
        switch (state) {
            case 21:
                return stateVpsSetting[0];
            case 23:
                return stateVpsSetting[1];
            case 22:
                return stateVpsSetting[2];
            case 24:
                return stateVpsSetting[3];

                // ["UNKNOWN", "OFFLINE", "ONLINE"];
            case 10:
                return stateHypervisorSetting[0];
            case 11:
                return stateHypervisorSetting[1];
            case 12:
                return stateHypervisorSetting[2];
        }
        return state.toString()
    }

    static setStatusColor = (elem) => {
        if (elem == 21) {
            return 'green'
        }
        if (elem == 23) {
            return 'orange'
        }
        if (elem == 22) {
            return 'grey'
        }
        if (elem == 24) {
            return 'black'
        }
    }

    static reConvertedState(state: string) {
        if (state) {
            if (state == stateVpsSetting[0]) {
                return 21
            }
            if (state == stateVpsSetting[1]) {
                return 23
            }
            if (state == stateVpsSetting[2]) {
                return 22
            }
            if (state == stateVpsSetting[3]) {
                return 24
            }
            if (state == stateHypervisorSetting[0]) {
                return 10
            }
            if (state == stateHypervisorSetting[1]) {
                return 11
            }
            if (state == stateHypervisorSetting[2]) {
                return 12
            }
        }
    }

    static setTypeVps(id: number) {
        switch (id) {
            case VPS_STANDARD:
                return vpsTypes[0]
            case VPS_BUSINESS:
                return vpsTypes[1]
            case VPS_CORPORATE:
                return vpsTypes[2]
        }
    }
    static resetTypeVps(id) {
        switch (id) {
            case vpsTypes[0]:
                return VPS_STANDARD
            case vpsTypes[1]:
                return VPS_BUSINESS
            case vpsTypes[2]:
                return VPS_CORPORATE
        }
    }
    static usedCpus = (data, hypervisor) => {
        let result = 0
        data?.map(elem => {
            if (elem['host'] == hypervisor!['host']) {
                result = result + elem.cpu_occupied
            }
        })
        return result
    }

    static usedRam = (data, hypervisor) => {
        let result = 0
        data?.map(elem => {

            if (elem['host'] == hypervisor!['host']) {
                result = result + elem.ram_total
            }
        })
        return result
    }

    static transformToDays = (time) => {
        let result
        if (time / 24 / 60 / 60 < 1) {
            return Math.round(time / 24 / 60 / 60)
        }
        let transformTime = time / 24 / 60 / 60

        return Math.round(time / 24 / 60 / 60)
    }

    static transformToGB = (b) => {
        return Math.round(b / 1024 / 1024 / 1024)
    }

    static getStatusScaning(result: string) {
        console.log({ 'result': result });
        if (result === undefined) {
            return false
        }

        let data = result.split(':');
        if (data[0] == stateSuccesScaning[0]) {
            return true
        }
        if (data[0] == stateSuccesScaning[1]) {
            return false
        }
    }

}
