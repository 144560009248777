import {CustomerDetails} from "./models";
import {removeDiac} from "../../../common/fce";
import moment from "moment";


export function process_customer(c: CustomerDetails) {
    // set invpoicing filter: what_period
    // set searchableName
    const q1 = [0,1,2]
    const q2 = [3,4,5]
    const q3 = [6,7,8]
    const q4 = [9,10,11]
    const h1 = [0,1,2,3,4,5]
    const h2 = [6,7,8,9,10,11]
    c.what_period = ''
    let name: string = c.company!.name
    c.ident = name
    c.searchableName = name ? removeDiac(name) : ''
    if (c.last_invoice) {
        let prev_month = moment().add(-1, 'month').month()
        let month_from = moment(c.last_invoice.period_from).month()
        if (c.billing_period === 'month') {
            let last_period_month = moment().add(-2, 'month').month()
            let cur_period_month2 = moment().add(-3, 'month').month()
            if (month_from === prev_month) {
                c.what_period = "current"
            }
            else {
                if (month_from === last_period_month) {
                    c.what_period = "last"
                }
                else {
                    c.what_period = "last2"
                }
            }
        }
        if (c.billing_period === 'quarter_year') {
            const prev_q = moment().add(-3, 'month').quarter()
            if (prev_q === 1) {
                // Q1
                if (q1.includes(month_from)) {
                    c.what_period = "current"
                }
                else {
                    if (q4.includes(month_from)) {
                        c.what_period = "last"
                    }
                    else {
                        c.what_period = "last2"
                    }
                }
            }
            if (prev_q === 2) {
                // Q2
                if (q2.includes(month_from)) {
                    c.what_period = "current"
                }
                else {
                    if (q1.includes(month_from)) {
                        c.what_period = "last"
                    }
                    else {
                        c.what_period = "last2"
                    }
                }
            }
            if (prev_q === 3) {
                // Q3
                if (q3.includes(month_from)) {
                    c.what_period = "current"
                }
                else {
                    if (q2.includes(month_from)) {
                        c.what_period = "last"
                    }
                    else {
                        c.what_period = "last2"
                    }
                }
            }
            if (prev_q === 4) {
                // Q4
                if (q4.includes(month_from)) {
                    c.what_period = "current"
                }
                else {
                    if (q3.includes(month_from)) {
                        c.what_period = "last"
                    }
                    else{
                        c.what_period = "last2"
                    }
                }
            }
        }
        if (c.billing_period === 'half_year') {
            if (h1.includes(prev_month)) {
                // H1
                if (h1.includes(month_from)) {
                    c.what_period = "current"
                }
                if (h2.includes(month_from)) {
                    c.what_period = "last"
                }
            }
            if (h2.includes(prev_month)) {
                // H2
                if (h2.includes(month_from)) {
                    c.what_period = "current"
                }
                if (h1.includes(month_from)) {
                    c.what_period = "last"
                }
            }
        }
        if (c.billing_period === 'year') {
            c.what_period = "current"
        }
        if (c.billing_period === 'iregular') {
            c.what_period = "current"
        }
    }
    else {
        c.what_period = "last"
    }
    return c
}
