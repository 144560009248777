import {
    RequestVerificationCodeRequestAction,
    RequestVerificationCodeResponseAction,
    RequestVerificationCodeErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { UserVerificationModel} from '../models'


export default (params: UserVerificationModel, cb?: (suc: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | RequestVerificationCodeRequestAction
                | RequestVerificationCodeResponseAction
                | RequestVerificationCodeErrorAction,
        ) => void,
    ) => {
        const request = new RequestVerificationCodeRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<UserVerificationModel>(`/user/${params.user_id}/verification`, params)
            .then((response) => {
                dispatch(new RequestVerificationCodeResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new RequestVerificationCodeErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
