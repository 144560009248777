import {
    SelectCustomerResponseAction,
    SelectCustomerRequestAction,
    SelectCustomerErrorAction
} from './index'
import { userTokenApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {SelectCustomer} from "../../user/models";

export default (cb?: (isSuccess: boolean) => void, customerId?: number) => {
    return (
        dispatch: (
            arg:
                | SelectCustomerRequestAction
                | SelectCustomerResponseAction
                | SelectCustomerErrorAction,
        ) => void,
    ) => {
        const request = new SelectCustomerRequestAction()
        dispatch(request)
        userTokenApiClient
            .post<SelectCustomer>(`/user/select-customer`, {customer_id: customerId})
            .then((response) => {
                dispatch(new SelectCustomerResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new SelectCustomerErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
