import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {
    AutoComplete,
    Button, Card,
    Col, Divider,
    Form,
    Input, InputRef,
    message,
    Row,
    Select, Space,
    Spin,
    Table,
    Tag,
} from 'antd'
import { Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';
import {AppState} from 'common/models'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import getInventoryPageLocations from "../inventory/actions/getInventoryPageLocations";
import getInventoryPageTypes from "../inventory/actions/getInventoryPageTypes";
import {
    PhysicalServerAddComponentParams,
    PhysicalServerModel,
    PhysicalServerRemoveComponentParams,
    PhysicalServerSwapComponentParams
} from "./models";
import {InventoryModel} from "../inventory/models";
import {InventoryTypeModel} from "../type/models";
import getAllComponentsByType from "./actions/getAllComponentsByType";
import addComponentToPhysicalServer from "./actions/addComponentToPhysicalServer";
import removeComponentFromPhysicalServer from "./actions/removeComponentFromPhysicalServer";
import swapComponentPhysicalServer from "./actions/swapComponentPhysicalServer";
import {stopPropagation} from "../../../common/fce";
import Badge from "antd/lib/badge";
import {Link} from "react-router-dom";
import {ColumnsType} from "antd/lib/table";
import {sort_name} from "../../../common/sorting";
import getPhysicalServer from "./actions/getPhysicalServer";
import getAllComponents from "./actions/getAllComponents";
import {isInventoryUsed, renderInventoryItem} from "../common";
import ClearAllComponentsByType from "./actions/clearAllComponentsByType";
import clearAllComponentsByType from "./actions/clearAllComponentsByType";



interface Props {
    dataToUpdate?: PhysicalServerModel
    setModalVisible: (param: boolean) => void
}

const {Item} = Form


const ComponentsForm = ({dataToUpdate, setModalVisible}: Props) => {

    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [removeForm] = Form.useForm()
    const [swapForm] = Form.useForm()
    const [insertForm] = Form.useForm()
    const selectAddComponentRef = useRef<any>(null);

    const {locations} = useSelector((state: AppState) => state.inventorylocation)
    const {inventory_types} = useSelector((state: AppState) => state.inventorytype)
    const {physicalServer, all_components, isSaving, all_comp_loading} = useSelector((state: AppState) => state.inventoryphysicalserver)

    const [componentSource, setComponentSource] = useState<InventoryModel[]>([])
    const [serverId, setServerId] = useState<number>(0)
    const [inventoryValue, setInventoryValue] = useState<InventoryModel | undefined>()
    const [type, setType] = useState<InventoryTypeModel | undefined>(undefined)
    const [selectType, setSelectType] = useState<number | undefined | null>(null)
    const [serial, setSerial] = useState<string>('')
    const [operation, setOperation] = useState<string>('delete')

    const [selectLocation, setSelectLocation] = useState<number | undefined>(undefined)
    const [selectNewLocation, setSelectNewLocation] = useState<number | undefined>(undefined)
    const [selectAddComponent, setSelectAddComponent] = useState<number | undefined>(undefined)

    const [isAddComponentClosed, setAddComponentClosed] = useState<boolean>(false)
    const [selectRemoveComponents, setSelectRemoveComponents] = useState<number[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    const [addComponentValid, setAddComponentValid] = useState<boolean>(false)
    const [removeComponentValid, setRemoveComponentValid] = useState<boolean>(false)
    const [swapComponentValid, setSwapComponentValid] = useState<boolean>(false)

    const [typeOptions, setTypeOptions] = useState<{ label: string, value: number }[]>([])
    const [selectTypeOptions, setSelectTypeOptions] = useState<{ label: string, value: number }[]>([])
    const [locationOptions, setLocationOptions] = useState<{ label: string, value: number }[]>([])
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');

    // componentOptions === components of the phy.server
    const [componentOptions, setComponentOptions] = useState<{ label: string | ReactNode, value: number, title: string }[]>([])

    // allComponentOptions === all new components available
    const [allComponentOptions, setAllComponentOptions] = useState<{ label: string | ReactNode, value: number, title: string }[]>([])

    const getServerCaseOut = () => {
        return inventory_types.filter(t => t.id === 1 || t.id === 2 || t.id === 4 || t.id === 15)
    }

    const getServerCaseIn = () => {
        return inventory_types.filter(t => t.id > 4 && t.id < 15)
    }

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    const checkMinSearch = (val: string | undefined) => {
        if (!val) {
            return false
        }
        return val.length > SEARCH_MIN
    }

    useEffect(() => {
        if (!locations || locations.length === 0) {
            dispatch(getInventoryPageLocations())
            return
        }

        if (!inventory_types || inventory_types.length === 0) {
            dispatch(getInventoryPageTypes())
            return
        }

        const id = dataToUpdate && dataToUpdate.id
        if (id && id > 0) {
            setServerId(id)
        }
        else {
            message.error('error')
        }
    }, [locations, inventory_types])

    useEffect(() => {
        if (serverId && serverId > 0) {
            setTypeOptions(getServerCaseOut().map(t => ({label: t.name, value: t.id})))
            setSelectTypeOptions(getServerCaseIn().map(t => ({label: t.name, value: t.id})))
            setLocationOptions(locations.map(t => ({label: t.name, value: t.id})))
        }
    }, [serverId])

    useEffect(() => {
        if (dataToUpdate && physicalServer && physicalServer.id === serverId && physicalServer.inventory) {
            setInventoryValue(physicalServer.inventory)
            const tid = physicalServer.inventory.type_id
            setType(inventory_types.find(t => t.id === tid))
            if (physicalServer.server_components) {
                setComponentSource(physicalServer.server_components)
                setComponentOptions(physicalServer.server_components.map(item => ({
                    label: renderInventoryItem(item),
                    value: item.id,
                    title: item.name + '|' + item.serial
                })))
            }
            else {
                setComponentSource([])
                setComponentOptions([])
            }
        }
    }, [physicalServer])

    useEffect(() => {
        // disabled buttons
        if (physicalServer && physicalServer.id > 0) {
            setAddComponentValid( operation === 'insert' && !!selectAddComponent && selectAddComponent>0 )
            setRemoveComponentValid( operation === 'delete' && selectRemoveComponents.length>0 && (!!selectLocation && selectLocation > 0))
            setSwapComponentValid( operation === 'swap' && !!selectAddComponent && selectAddComponent>0 && selectRemoveComponents.length>0 && (!!selectLocation && selectLocation > 0) )
        }
        else {
            setAddComponentValid( false )
            setRemoveComponentValid( false )
            setSwapComponentValid( false )
        }
        setSelectionType(operation === 'swap' ? 'radio' : 'checkbox')

    }, [selectAddComponent, selectRemoveComponents.length, operation, selectLocation, selectNewLocation])

    useEffect(() => {
        console.log('removeComponentValid: '+removeComponentValid)
        console.log('selectRemoveComponents.length: '+selectRemoveComponents.length)
        console.log('selectLocation: '+selectLocation)
    }, [selectRemoveComponents, selectRemoveComponents.length, selectLocation, operation])

    useEffect(() => {
        if (all_components) {
            console.log('useEffect all_components='+all_components.length)
            // options to add a component
            setAllComponentOptions(all_components.sort(sort_name).map(item => ({
                label: renderInventoryItem(item),
                value: item.id,
                title: item.name + '|' + item.serial
            })))
        }
    }, [all_components])

    useEffect(() => {
        if (selectType) {
            setSerial('')
            insertForm.setFieldsValue({serial: undefined})
            setAllComponentOptions([])
            if (selectType > 0) {
                dispatch(getAllComponentsByType(selectType))
                setAddComponentClosed(false)
            }
        }
        else {
            !serial && setAllComponentOptions([])
        }
    }, [selectType])

    useEffect(() => {
        if (serial) {
            setSelectType(undefined)
            insertForm.setFieldsValue({type: null})
            if (checkMinSearch(serial)) {
                setAllComponentOptions([])
                dispatch(getAllComponents('serial=' + serial))
                setAddComponentClosed(false)
            }
        }
        else {
            !selectType && setAllComponentOptions([])
            dispatch(clearAllComponentsByType())
            setSelectAddComponent(undefined)
            insertForm.setFieldsValue({
                add_component: undefined,
            })
        }
    }, [serial])

    const handleAddComponent = () => {
        if (physicalServer && selectAddComponent) {
            const params: PhysicalServerAddComponentParams = {physical_server_id: physicalServer.id, server_component_id: selectAddComponent}
            dispatch(addComponentToPhysicalServer(params, (isSuccess: boolean) => {
                if (isSuccess) {
                    dispatch(clearAllComponentsByType())
                    setSelectAddComponent(undefined)
                    insertForm.setFieldsValue({
                        add_component: undefined,
                    });
                    message.success(t('general.success'))
                }
            }))
        }
    }

    const handleRemoveComponent = () => {
        if (physicalServer && selectRemoveComponents.length > 0 && selectLocation) {
            let params: PhysicalServerRemoveComponentParams
            let counter = 0;
            for (const compId of selectRemoveComponents) {
                params = {
                    physical_server_id: physicalServer.id,
                    server_component_id: compId,
                    new_location_id: selectLocation
                }
                counter++
                dispatch(removeComponentFromPhysicalServer(params, (isSuccess: boolean) => {
                    counter--
                    if (isSuccess) {
                        if (counter === 0) {
                            message.success(t('general.success'))
                            setSelectRemoveComponents([])
                            setSelectedRowKeys([])
                            setSelectLocation(undefined)
                            setComponentOptions([])
                            removeForm.setFieldsValue({
                                locality_dest: undefined,
                            });
                        }
                    }
                    else {
                        if (counter === 0) {
                            message.error(t('general.error'))
                            dispatch(getPhysicalServer(physicalServer.id, 'inventory,server_components,text_ipmi,rack,text_location'))
                        }
                    }
                }))
            }
        }
    }

    const handleSwapComponent = () => {
        if (physicalServer && selectLocation && selectAddComponent && selectRemoveComponents.length) {
            const params: PhysicalServerSwapComponentParams = {
                physical_server_id: physicalServer.id,
                old_component_id: selectRemoveComponents[0],
                new_component_id: selectAddComponent,
                new_location_id: selectLocation
            }
            dispatch(swapComponentPhysicalServer(params, (isSuccess: boolean) => {
                if (isSuccess) {
                    // setModalVisible(false)
                    message.success(t('general.success'))
                }
            }))
        }
    }


    const handleSerialChange = (value: any) => {
        if (value) {
            setSerial(value)
        } else {
            setSerial('')
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selRowKeys: React.Key[], selectedRows: InventoryModel[]) => {
            setSelectedRowKeys(selRowKeys)
            setSelectRemoveComponents(selRowKeys.map(k => {if (typeof k === "string") { return parseInt(k)} else {return k}}))
        },
        getCheckboxProps: (record: InventoryModel) => ({
            disabled: operation === 'insert', // Column configuration not to be checked
            name: record.name,
        }),
    };

    const columns: ColumnsType<InventoryModel> = [
        {
            title: t('physicalServersPage.serial'),
            dataIndex: 'serial',
            width: '80px',
            key: 'serial',
        },
        {
            title: t('physicalServersPage.name'),
            dataIndex: 'name',
            width: '480px',
            key: 'name',
        }
    ]

    if (!physicalServer) {
        return (<Spin />)
    }

    return (
        <>
            <Card title={
                <>Server:&nbsp;
                    <Link to={`/inventory/${physicalServer.inventory?.id}`} title={physicalServer.inventory?.name}>
                        {physicalServer.inventory?.name}
                    </Link>
                </>}
                  className={(operation === 'delete' || operation === 'swap') ? 'info-border' : 'disabled-border'}
                  style={{ width: '55%', maxWidth: 440, marginRight: '25px', float: 'left' }}>
                <Table<InventoryModel>
                    showHeader={true}
                    bordered={true}
                    style={{height: '200px', overflowY: 'auto', overflowX: 'auto', border: '1px lightgray solid'}}
                    columns={columns}
                    dataSource={componentSource}
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                    }}
                    rowKey='id'
                    pagination={false}
                />

                <div style={{padding: '5px', paddingLeft: '10px', marginTop: '10px'}}>{t('physicalServersPage.location_where')}</div>
                <Form name='remove1'
                      form={removeForm}
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 20 }}
                      layout="horizontal"
                      style={{ maxWidth: 600 }}
                >
                    <Row style={{margin: '0px', padding: '5px'}}>
                        <Col span={24}>
                            <Form.Item name='locality_dest' label={t('physicalServersPage.location')} style={{margin: '0', padding: '0'}}>
                                <Select
                                    placeholder={t('physicalServersPage.choose_locality_dest')}
                                    options={locationOptions}
                                    allowClear
                                    style={{width: '100%'}}
                                    onClick={stopPropagation}
                                    disabled={operation === 'insert'}
                                    onChange={(v) => {setSelectLocation(v)}}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>


            <Card title={t('physicalServersPage.new_component')}
                  className={(operation === 'swap' || operation === 'insert') ? 'info-border' : 'disabled-border'}
                  style={{ width: '45%', maxWidth: 400, marginLeft: '25px', float: 'left', height: 343 }}>
                <Form name='insert1'
                      form={insertForm}
                      initialValues={{serial: ''}}
                      layout="vertical">
                    <Row style={{margin: '0px', padding: '5px'}}>
                        <Col span={24}>
                            <Form.Item name='serial'
                                       style={{marginBottom: '0'}}
                                       label={t('physicalServersPage.serial')} >
                                <AutoComplete placeholder={t('physicalServersPage.serial')}
                                       style={{width: '60%'}}
                                       allowClear
                                       disabled={operation === 'delete'}
                                       onClick={stopPropagation}
                                       onKeyDown={(e) => {if (e.key === 'Enter') {handleSerialChange(e.currentTarget.innerText); e.stopPropagation()}}}
                                       value={serial}
                                       onChange={(v) => handleSerialChange(v)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{margin: '0px', padding: '5px'}}>
                        <Col span={24}>
                            <Form.Item name='type'
                                       style={{marginBottom: '0'}}
                                       label={t('physicalServersPage.type')} >
                                <Select placeholder={t('physicalServersPage.choose_type')}
                                        options={selectTypeOptions}
                                        allowClear
                                        showSearch
                                        disabled={operation === 'delete'}
                                        style={{width: '60%'}}
                                        onClick={stopPropagation}
                                        value={selectType}
                                        onChange={(v) => {setSelectType(v); selectAddComponentRef.current.focus()}}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{margin: '0px', padding: '5px'}}>
                        <Col span={24}>
                            <Form.Item name='add_component' label={(<span>{t('physicalServersPage.component')} <span className='text-disabled'>({allComponentOptions.length} items)</span></span>)} >
                                <Select placeholder={t('physicalServersPage.choose_component')}
                                        options={allComponentOptions}
                                        disabled={operation === 'delete'}
                                        allowClear
                                        ref={selectAddComponentRef}
                                        onBlur={() => {setAddComponentClosed(true)}}
                                        onFocus={() => {setAddComponentClosed(false)}}
                                        loading={all_comp_loading}
                                        notFoundContent={all_comp_loading ? <Spin /> : null}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{width: '100%'}}
                                        dropdownMatchSelectWidth={450}
                                        open={ (!!selectType || !!serial) && allComponentOptions.length > 0 && !selectAddComponent && !isAddComponentClosed }
                                        className='selectComponent80'
                                        placement='bottomLeft'
                                        onChange={(v) => {setSelectAddComponent(v)}}
                                        value={selectAddComponent}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>

            <div className='clearfix' />

            <Divider plain style={{marginTop: '45px'}}>{t('physicalServersPage.select_operation')}</Divider>

            <Radio.Group  style={{marginTop: '5px', width: '100%', border: '0px dashed red'}}
                          onChange={ (e: RadioChangeEvent) => {
                              setOperation(e.target.value);
                              if (e.target.value === 'swap') {
                                  setSelectRemoveComponents([])
                                  setSelectedRowKeys([])
                              }
                          }}
                          defaultValue="delete"
                          value={operation}>
            <Row style={{marginTop: '5px', paddingLeft: '10px', width: '100%'}}>
                <Col span={8} className={operation === 'delete' ? 'info-border' : 'disabled-border'} style={{margin: '2px'}}>
                    <Radio value={'delete'} style={{margin: '10px'}}>
                        <Tag style={{border: 0}} color={operation === 'delete' ? 'processing' : 'default'}>{t('physicalServersPage.btn_remove')}</Tag>
                    </Radio>
                    <div className='clearfix' style={{marginTop: '15px'}} />
                    <div style={{textAlign: 'center', width: '100%'}}>
                    <Button type='primary'
                            disabled={!removeComponentValid}
                            loading={isSaving && operation === 'delete'}
                            style={{margin: '5px'}}
                            className={operation === 'delete' ? 'info-border' : 'disabled-border'}
                            onClick={() => handleRemoveComponent()} >{t('physicalServersPage.btn_remove')}</Button>
                    </div>
                </Col>
                <Col span={7} className={operation === 'swap' ? 'info-border' : 'disabled-border'} style={{margin: '2px'}}>
                    <Radio value={'swap'} style={{margin: '10px'}}>
                        <Tag style={{border: 0}} color={operation === 'swap' ? 'processing' : 'default'}>{t('physicalServersPage.btn_swap')}</Tag>
                    </Radio>
                    <div className='clearfix' style={{marginTop: '15px'}} />
                    <Form name='swap1' form={swapForm}
                          labelCol={{ span: 4 }}
                          wrapperCol={{ span: 20 }}
                          layout="horizontal"
                          style={{ maxWidth: 600 }}
                    >
                        <div style={{textAlign: 'center', width: '100%'}}>
                            <Button type='primary'
                                    loading={isSaving && operation === 'swap'}
                                    disabled={!swapComponentValid}
                                    style={{margin: '5px'}}
                                    className={operation === 'swap' ? 'info-border' : 'disabled-border'}
                                    onClick={() => handleSwapComponent()} >{t('physicalServersPage.btn_swap')}</Button>
                        </div>
                    </Form>
                </Col>
                <Col span={8} className={operation === 'insert' ? 'info-border' : 'disabled-border'} style={{margin: '2px'}}>
                    <Radio value={'insert'} style={{margin: '10px', width: '100%'}}>
                        <Tag style={{border: 0}} color={operation === 'insert' ? 'processing' : 'default'}>{t('physicalServersPage.btn_insert')}</Tag>
                    </Radio>
                    <div className='clearfix' style={{marginTop: '15px'}} />
                    <div style={{textAlign: 'center', width: '100%'}}>
                        <Button type='primary'
                                style={{margin: '5px'}}
                                className={operation === 'insert' ? 'info-border' : 'disabled-border'}
                                loading={isSaving && operation === 'insert'}
                                disabled={!addComponentValid}
                                onClick={() => handleAddComponent()} >{t('physicalServersPage.btn_insert')}</Button>
                    </div>
                </Col>
            </Row>
            </Radio.Group>
        </>

    )
}

export default ComponentsForm
