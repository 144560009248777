import {Modal, Spin} from "antd";
import React from "react";

interface Props {
    title: string
    loading: boolean
    visible: boolean
    hide: () => void
    preview: string
}

const PdfPreview = (props: Props) => {

    return (
        <Modal title={props.title}
               visible={props.visible}
               footer={null}
               onCancel={() => props.hide()}
               width={1200}
               maskClosable={false}
               style={{height: '100vh', top: 50}}
        >
            {
                props.loading &&
                <Spin/>
            }
            {
                !props.loading &&
                <embed type='application/pdf' src={`data:application/pdf;headers=filename%3Daaafilename.pdf;base64, ${props.preview}`}
                       style={{width: '100%', height: '100vh'}}/>
            }
        </Modal>
    )
}

export default PdfPreview