import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {AppState} from "../../../common/models";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import {Card, Col, Modal, message, Row, Space, Table, Tag} from "antd";
import Button from "antd-button-color";
import {
    BorderOutlined, CheckSquareFilled,
    DeleteTwoTone,
    EditTwoTone, ExclamationCircleOutlined,
    InfoCircleTwoTone,
    ShopOutlined
} from "@ant-design/icons";
import {PlusCircleOutlined, SplitCellsOutlined} from "@ant-design/icons/lib/icons";
import TotalNum from "../../../components/TotalNum/TotalNum";
import {InventoryTypeModel} from "./models";
import getTypes from "./actions/getTypes";
import deleteType from "./actions/deleteType";
import InventoryTypeForm from "./InventoryTypeForm";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {sort_name} from "../../../common/sorting";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {ColumnsType} from "antd/lib/table";
import '../inventory/inventory.scss'
import {InventoryLocationModel} from "../location/models";
import HistoryModal from "../../../components/History/HistoryModal";

const { confirm } = Modal

const renderKey = (name: string) => {
    if (!name) {
        return
    }
    return (<Tag color="#aaaaaa"
                 title={name}
                 key={name}
                 style={{fontSize: '0.9em'}}>{name}</Tag>)
}

export const renderUsable = (typeId: number, types: InventoryTypeModel[]) => {
    if (!typeId) {
        return
    }
    const tag = types.find(t => t.id === typeId)
    if (!tag) {
        return
    }
    return (<Tag icon={<SplitCellsOutlined twoToneColor='green' />}
                 color='green'
                 title={tag.name}
                 key={tag.name}
                 style={{fontSize: '1em'}}>{tag.name}</Tag>)
}

const InventoryTypePage = () => {
    const CONTROL_NAME = 'page_inventory_types'
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {inventory_types, isLoading} = useSelector((state: AppState) => state.inventorytype)
    const {inventory_dim_types} = useSelector((state: AppState) => state.sidenav)

    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [dataSource, setDataSource] = useState<InventoryTypeModel[]>()
    const [isModalVisible, setModalVisible] = useState(false)
    const [dataToUpdate, setDataToUpdate] = useState<InventoryTypeModel>()
    const [isEditor, setEditor] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }
    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    let triggerOneTimer = 0

    useEffect(() => {
        // trigger ONCE
        triggerOneTimer = Date.now().valueOf()
        setEditor(loggedUser.hasAccess('page_inventory_types_editor'))
        dispatch(getTypes())
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }
    }, [inventory_types, isModalVisible])

    useEffect(() => {
        // trigger from Side menu clicked
        if (!isLoading) {
            const when = triggerOneTimer + 1000
            if (when < Date.now().valueOf()) {
                // when triggerOneTimer was not called (after 1sec. of SideMenu click)
                dispatch(getTypes())
            }
        }
    }, [inventory_dim_types])

    const refreshGrid = () => {
        setDataSource(inventory_types.sort(sort_name))
    }

    const showConfirmDelete = (obj: InventoryLocationModel, title: string) => {
        if (!obj) {
            return
        }
        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{obj.name}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteType(obj.id, (suc) => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        suc && message.success(t('general.success'))
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const expandedRowRender = (rec: InventoryTypeModel) => {
        return (
            <>
                <Row style={{padding: '5px'}}>
                    <Col span='24' style={{padding: '5px', fontSize: '1.2em'}}>
                        <u><b>{rec.name}</b></u>
                    </Col>
                </Row>
                <Row style={{padding: '5px'}}>
                    <Col span='24' style={{padding: '5px'}}><>{t('inventoryTypePage.usables')}</>:&nbsp;
                        {
                            rec.usableIds && rec.usableIds.filter(k => !!k).length > 0 ? rec.usableIds.filter(k => !!k).map(typeId => renderUsable(typeId, inventory_types)) : 'nic'
                        }
                    </Col>
                </Row>
                <Row style={{padding: '5px'}}>
                    <Col span='24' style={{padding: '5px'}}><>{t('inventoryTypePage.parameters')}</>:&nbsp;
                        {
                            rec.keys && rec.keys.filter(k => !!k).length > 0 ? rec.keys.filter(k => !!k).map(key => renderKey(key)) : 'nic'
                        }
                    </Col>
                </Row>
            </>
        )
    }


    const columns: ColumnsType<InventoryTypeModel> = [
        {
            title: t('inventoryTypePage.name'),
            dataIndex: 'name',
            key: 'name',
            width: '25%'
        },
        {
            title: t('inventoryTypePage.is_server_case'),
            dataIndex: 'is_server_case',
            key: 'is_server_case',
            width: 80,
            align: 'center',
            render: (_, rec: InventoryTypeModel) => rec.is_server_case ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: t('inventoryTypePage.is_server_component'),
            dataIndex: 'is_server_component',
            key: 'is_server_component',
            width: 80,
            align: 'center',
            render: (_, rec: InventoryTypeModel) => rec.is_server_component ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: t('inventoryTypePage.is_rack'),
            dataIndex: 'is_rack',
            key: 'is_rack',
            width: 80,
            align: 'center',
            render: (_, rec: InventoryTypeModel) => rec.is_rack ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: t('inventoryTypePage.is_rack_equipment'),
            dataIndex: 'is_rack_equipment',
            key: 'is_rack_equipment',
            width: 80,
            align: 'center',
            render: (_, rec: InventoryTypeModel) => rec.is_rack_equipment ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: t('inventoryTypePage.is_blade_module'),
            dataIndex: 'is_blade_module',
            key: 'is_blade_module',
            width: 80,
            align: 'center',
            render: (_, rec: InventoryTypeModel) => rec.is_blade_module ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: t('inventoryTypePage.is_blade_container'),
            dataIndex: 'is_blade_container',
            key: 'is_blade_container',
            width: 80,
            align: 'center',
            render: (_, rec: InventoryTypeModel) => rec.is_blade_container ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: t('inventoryTypePage.has_management_ip'),
            dataIndex: 'has_management_ip',
            key: 'has_management_ip',
            width: 80,
            align: 'center',
            render: (_, rec: InventoryTypeModel) => rec.has_management_ip ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: 'Action',
            key: 'action',
            width: '100px',
            dataIndex: 'action',
            align: 'center',
            render: (_, record: InventoryTypeModel) => (
                <Space size={1}>
                    {
                        isEditor && (
                            <Button type='text'
                                    size='small'
                                    onClick={() => {
                                        setDataToUpdate(record)
                                        setModalVisible(true)
                                    }}
                                    className='actionButton'
                                    icon={record.is_used ? <EditTwoTone twoToneColor='#222222' /> : <EditTwoTone twoToneColor='green' />}
                            />
                        )
                    }

                    {
                        isEditor && (
                            <Button type='text' danger size='small'
                                    className='actionButton'
                                    disabled={record.is_deletable === 0}
                                    onClick={() => showConfirmDelete(record, t('inventoryTypePage.confirm_delete'))}
                                    icon={record.is_deletable === 1 ? <DeleteTwoTone twoToneColor='red' /> : <DeleteTwoTone twoToneColor='#bbbbbb' />} />
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(record.id)
                                setHistoryTitle(record.name)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                </Space>
            ),
        },
    ]
    return (
        <>
            <Card className='inventoryTypePage'
                  title={
                      <Row>
                          <Col span={6}>
                              <SplitCellsOutlined />{`  ${t('inventoryTypePage.title')}`}
                          </Col>
                          <Col span={18}>&nbsp;</Col>
                      </Row>
                  }
                  extra={
                      <Button type='primary'
                              disabled={!isEditor}
                              onClick={() => {
                                  setDataToUpdate(undefined)
                                  setModalVisible(true)
                              }}>
                          <PlusCircleOutlined /> {t('general.create')}
                      </Button>
                  }
            >
                {
                    appSetting &&
                    <Table<InventoryTypeModel>
                        bordered={true}
                        columns={columns}
                        expandable={{expandedRowRender}}
                        // style={{whiteSpace: 'pre'}}
                        rowClassName={(record) => (record.is_used === 0 ? 'unused' : '')}
                        loading={isLoading}
                        dataSource={dataSource}
                        className='inventoryTypeTable'
                        rowKey='id'
                        pagination={false}
                        footer={() => TotalNum(Number(dataSource?.length), 'InventoryType', dataSource)}
                    />
                }
            </Card>

            <Modal
                destroyOnClose={true}
                style={{top: 20}}
                title={
                    <>
                        <ShopOutlined /> {dataToUpdate ? t('inventoryTypePage.update_title') : t('inventoryTypePage.create_title')}
                    </>
                }
                visible={isModalVisible}
                onCancel={() => setModalVisible(false)}
                maskClosable={false}
                footer={null}>
                <InventoryTypeForm dataToUpdate={dataToUpdate} setModalVisible={setModalVisible} />
            </Modal>

            <HistoryModal service='inventory' model='InventoryType'
                          modelId={historyModelId}
                          title={historyTitle}
                          isModalVisible={isHistoryModalVisible}
                          setModalVisible={() => setHistoryModalVisible(false)}
                          modalRender={(modal) => (
                              <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                  <div ref={draggleRef}>{modal}</div>
                              </Draggable>
                          )}
            />
        </>
    )
}

export default InventoryTypePage