import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {IdPayload, InvoiceDetails} from '../models'
import {RepairInvoiceErrorAction, RepairInvoiceRequestAction, RepairInvoiceResponseAction} from "./index";

export default (params: IdPayload, cb?: (isSuccess: boolean, document: InvoiceDetails) => void) => {
    return (
        dispatch: (args: RepairInvoiceRequestAction | RepairInvoiceResponseAction | RepairInvoiceErrorAction) => void
    ) => {
        const request = new RepairInvoiceRequestAction(params)
        dispatch(request)

        protectedApiClient.post<InvoiceDetails>(`/billing/invoice/${params.id}/repair`, params)
            .then(response => {
                dispatch(new RepairInvoiceResponseAction(request, response.data))
                cb && cb(true, response.data)
            })
            .catch(error => {
                dispatch(new RepairInvoiceErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false, error)
            })
    }
}
