import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import AppSetting from "./AppSettings";
import updateSelfSetting from "../pages/login/actions/updateSelfSetting";
import {SettingsDetails, SettingsParams} from "../pages/settings/models";
import {AppState} from "./models";


const usePageSize = (appSetting: AppSetting, userId: number, newPageSize: string | number | undefined) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {self_settings} = useSelector((state: AppState) => state.auth)
    const [counter, setCounter] = useState(0)   // not needed - just for sure to prevent infinite update loop

    const getSettingItem = (name: string): SettingsDetails | undefined => {
        return self_settings.find((item) => item.name === name)
    }

    useEffect(() => {
        // update pageSize when changed
        // console.log('usePageSize newPageSize: '+newPageSize)
        if (appSetting && appSetting.remember_page_size && newPageSize) {
            let pageSize = ''
            let n = 0
            if ((typeof newPageSize === 'number') || (typeof newPageSize === 'string')) {
                n = parseInt(`${newPageSize}`)
                n = Math.abs(n)
                if (n > 0) {
                    if (n > 10000) {
                        n = 10000
                    }
                }
            }

            // check
            if (n === 0) {
                // not valid
                return
            }

            pageSize = `${n}`
            const gridPageSize = getSettingItem('grid_page_size')
            if (gridPageSize && gridPageSize.value && gridPageSize.value != pageSize) {
                const params: SettingsParams = {id: userId, name: 'grid_page_size', value: pageSize}
                setCounter(counter+1)
                counter < 50 && dispatch(updateSelfSetting(params, suc => {
                    if (!suc) console.error(t('Update settings: grid_page_size failed.'))
                }))
            }
        }
    }, [newPageSize])

};

export default usePageSize;