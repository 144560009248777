import React, { useState} from 'react'
import {Button, Form, Input, message} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {InventoryLocationModel} from "./models";
import updateLocation from "./actions/updateLocation";
import createLocation from "./actions/createLocation";


interface Props {
    dataToUpdate?: InventoryLocationModel
    setModalVisible: (param: boolean) => void
}

const {Item} = Form

const InventoryLocationForm = ({dataToUpdate, setModalVisible}: Props) => {

    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {isLoading} = useSelector((state: AppState) => state.inventorylocation)
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)

    const onFormSubmit = (values: Store) => {
        // save data
        if (dataToUpdate && dataToUpdate.id > 0) {
            dispatch(updateLocation({
                id: dataToUpdate.id,
                name: values.name,
            }, (isSuccess: boolean) => {
                if (isSuccess) {
                    setModalVisible(false)
                    message.success(t('general.success'))
                }
            }))
        } else {
            dispatch(createLocation({
                    name: values.name
                }, (isSuccess: boolean) => {
                    if (isSuccess) {
                        setModalVisible(false)
                        message.success(t('general.success'))
                    }
                }
            ))
        }
    }

    return (
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={{
                ...dataToUpdate,
            }}
        >
            <Item name='name' label={t('inventoryLocationPage.name')}
                rules={[{required: true, message: t('errors.field_required')}]}
                hasFeedback
            >
                <Input />
            </Item>

            <Item {...tailLayout}>
                <Button loading={isLoading} type='primary' htmlType='submit' disabled={!updateStatus}>
                    {
                        dataToUpdate ? t('general.update') : t('general.create')
                    }
                </Button>
            </Item>

        </Form>
    )
}

export default InventoryLocationForm
