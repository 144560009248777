import {ActionType, CustomerServiceActions} from './actions'
import {AsyncActionMode} from 'common/models'
import {CustomerServiceDetails, CustomerServiceState} from './models'
import moment from 'moment'
import {ContactModel} from "../customer/models";
import {removeDiac} from "../../../common/fce";

const CUSTOMERSERVICE_INITIAL_STATE: CustomerServiceState = {
	isLoading: false,
	isSaving: false,
	error: undefined,
	customerId: '',
	// customer: undefined,
	customerservices: [],
	customerservices_all: []
}

export default (state = CUSTOMERSERVICE_INITIAL_STATE, action: CustomerServiceActions): CustomerServiceState => {

	function processServices(data: CustomerServiceDetails[]): CustomerServiceDetails[] {
		// prepare data for search
		if (data && data.length > 0) {
			return data.map((c) => {
				c.searchableName = removeDiac(c.name)
				return c
			})
		}
		return []
	}

	switch (action.type) {
		case ActionType.GET_CUSTOMERSERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					customerservice: action.data,
					customerservices: state.customerservices.map((cs) =>
						cs.id === action.data.id ? action.data : cs,
					),
					customerservices_all: state.customerservices.map((cs) =>
						cs.id === action.data.id ? action.data : cs,
					)
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.CLEAR_CUSTOMERSERVICES:
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					customerservices: [],
				}
			}
			break
		case ActionType.GET_CUSTOMERSERVICES:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state,
					isLoading: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					customerservices: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_CUSTOMER_PRICELIST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					pricelist: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break

		case ActionType.GET_CUSTOMERSERVICES_ALL:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					customerservices_all: processServices(action.data),
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_ONE_TIME_PRODUCT:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSaving: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					customerservices: state.customerservices?.concat(action.data),
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.CREATE_CUSTOMERSERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSaving: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				const prevId = action.request.payload.previous_id
				return {
					...state,
					customerservices: prevId
						? state.customerservices
								?.map((cs) => (cs.id === prevId ? {...cs, date_to: moment(action.data.date_from).subtract(1,'d').format('YYYY-MM-DD')} : {...cs}))
								.concat(action.data)
						: state.customerservices?.concat(action.data),
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_CUSTOMERSERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSaving: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					customerservices: state.customerservices!.filter(
						(customerservice) => customerservice.id !== action.request.id,
					),
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_CUSTOMERSERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, isSaving: true}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				const dateTo = action.request.payload.date_to
				return {
					...state,
					customerservice: action.data,
					customerservices: state.customerservices!.map((cs) =>
						cs.id === action.data.id
							? dateTo
								? {...action.data, date_to: dateTo}
								: {...action.data}
							: cs,
					),
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.SEND_CUSTOMER_SERVICE:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, customerId: ''}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {...state, customerId: action.id}
			}
			break;

		case ActionType.SET_ORDER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {...state, }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {...state,
					customerservices: state.customerservices.map((cs) =>
						cs.id === action.data.id ?  {...action.data} : cs,
					),}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {...state }
			}
			break;
	}
	return state
}
