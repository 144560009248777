import React, {useEffect, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from "../../../common/models";
import { useTranslation } from "react-i18next";
import {AutoComplete, Card, Col, Input, message, Row, Select, Space, Spin, Table, Modal} from "antd";
import Button from "antd-button-color";
import { PAGING } from "../../../common/enums";
import TotalNum from "../../../components/TotalNum/TotalNum";
import {EditTwoTone, ExclamationCircleOutlined, InfoCircleOutlined, InfoCircleTwoTone} from "@ant-design/icons";
import { AppstoreAddOutlined, DeleteTwoTone, PlusCircleOutlined } from "@ant-design/icons/lib/icons";
import { useLoggedUser } from "../../../helpers/loginUserHelper";
import { LoadingIndicator } from "../../,,/../../components";
import { CostAllocationCategoryDetails, typeCategory } from './models';
import getCostAllocationCategories from './actions/getCostAllocationCategories';
import CostAllocationCategoryForm from './components/CostAllocationCategoryForm';
import deleteCostAllocation from './actions/deleteCostAllocation';
import setDefaultCostAllocationCategory from './actions/setDefaultCostAllocationCategory';
import {stopPropagation} from "../../../common/fce";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import HistoryLog from "../../../components/History/HistoryLog";
import getHistoryLogs from "../../../components/History/actions/getHistoryLogs";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {sort_name} from "../../../common/sorting";
import HistoryModal from "../../../components/History/HistoryModal";

const { Option } = Select
const { confirm } = Modal

const CostAllocationCategoryPage = props => {
    const CONTROL_NAME = 'page_billing_cost_allocation'
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const { self_settings } = useSelector((state: AppState) => state.auth)
    const { costAllocationCategories, isLoading } = useSelector((state: AppState) => state.costAllocationCategory)

    const [dataSource, setDataSource] = useState<CostAllocationCategoryDetails[]>()
    const [pageSize, setPageSize] = useState<string>()
    const [searchTypeCategory, setSearchTypeCategory] = useState<string | number>(-1)
    const [searchName, setSearchName] = useState('')
    const [isModalVisible, setModalVisible] = useState(false)
    const [updateId, setUpdateId] = useState(0)
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    // permissions
    const [isEditor, setEditor] = useState(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator />
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // logger
    const logger = (msg) => { if (appSetting && appSetting.debug) {console.log('CostAllocationCategoryPage: ' + msg)} }

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        if (loggedUser) {
            setEditor(loggedUser.hasAccess('page_billing_cost_allocation_editor'))
        }
        dispatch(getCostAllocationCategories(isSuccess => {
            if (!isSuccess) console.error(t('Update settings: grid_page_size failed.'))
        }))

    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }
    }, [isModalVisible, searchName, costAllocationCategories, searchTypeCategory])

    const filtered = (name: string, type: string | number) => {
        if (!costAllocationCategories) {
            return []
        }

        const qType = (h) => h.typ == type
        let result = costAllocationCategories;
        if (name && name.length > SEARCH_MIN) {
            const qName = (h) => h.name.toLowerCase().includes(name.toLowerCase())
            result = result.filter((t) => qName(t))
        }

        if (type !== -1) {
            result = result.filter((t) => qType(t))
        }
        return result
    }

    const refreshGrid = () => {
        const newData = filtered(searchName, searchTypeCategory).sort(sort_name)
        setDataSource(newData)
    }

    const onDelete = (id) => {
        dispatch(deleteCostAllocation(id))
    }

    const showConfirmDelete = (b: CostAllocationCategoryDetails, title: string) => {
        if (!b) {
            return
        }
        const bid = b.id
        if (bid === undefined) {
            return
        }

        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{b.name}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteCostAllocation(bid,suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const fetchHistory = (service: string, model: string, mid: number) => {
        setHistoryTitle(`${service} / ${model} / ${mid}`)
        dispatch(getHistoryLogs(service, model, mid))
    }

    const FilterByNameInput = self_settings && (
        <AutoComplete
            placeholder={t('costAllocationPage.name')}
            style={{width: '280px'}}
            value={searchName}
            allowClear={true}
            onClick={stopPropagation}
            onChange={v => { setSearchName(v) }}
        />
    )

    const FilterCurrencyInput = (
        <>
            <Select
                style={{ width: 200 }}
                placeholder={t('costAllocationPage.typ')}
                allowClear={true}
                onChange={(currValue) => {
                    setSearchTypeCategory(typeCategory.indexOf(currValue))
                }} >
                {
                    <>
                        {typeCategory!.map((value, index) => {
                            if (value) {
                                return <Option value={value} key={index}>{value}</Option>
                            }

                        })}
                    </>
                }
            </Select>
        </>
    )

    const columns = [

        {
            title: FilterByNameInput,
            dataIndex: 'name',
            key: 'name',
            width: '100%',
        },
        {
            title: FilterCurrencyInput,
            dataIndex: 'typ',
            key: 'typ',
            className: 'left',
            width: '10%',
            render: (_, record: CostAllocationCategoryDetails) => (
                <Space size='small'>
                    {typeCategory[record.typ!]}
                </Space>
            ),
        },

        {
            title: 'Action',
            key: 'action',
            width: '60px',
            dataIndex: 'action',
            className: 'center',
            render: (_, record: CostAllocationCategoryDetails) => (
                <Space size='small'>
                    {
                        isEditor && (
                            <Button type='text'
                                    size='small'
                                    onClick={() => {
                                        record.id && setUpdateId(record.id)
                                        setModalVisible(true)
                                    }}
                                    className='actionButton'
                                    icon={<EditTwoTone twoToneColor='green' />}
                            />
                        )
                    }

                    {
                        isEditor && (
                                <Button type='text' danger size='small'
                                        className='actionButton'
                                        onClick={() => showConfirmDelete(record, t('costAllocationPage.confirm_delete'))}
                                        icon={<DeleteTwoTone twoToneColor='red'/>} />
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryTitle(record.name)
                                setHistoryModelId(record.id)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                </Space>
            ),
        },
    ]

    if (appSetting && loggedUser) {
        return (
            <>
                <Card className='CostAllocationCategoryPage'
                    title={
                        <Row>
                            <Col span={6}>
                                <AppstoreAddOutlined />&nbsp;{t('costAllocationPage.title')}
                            </Col>

                        </Row>
                    }
                    extra={
                        <Button type='primary' disabled={!isEditor} onClick={() => {
                            setUpdateId(0)
                            setModalVisible(true)
                        }}>
                            <PlusCircleOutlined /> {t('costAllocationPage.create_title')}
                        </Button>
                    }
                >
                    <Table<CostAllocationCategoryDetails>
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columns}
                        loading={isLoading}
                        dataSource={dataSource}
                        className='costAllocationCategoryTable'
                        rowKey='id'
                        pagination={{
                            defaultPageSize: appSetting.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => {
                            setPageSize(`${ev.pageSize}`)
                        }}
                        footer={() => TotalNum(Number(dataSource?.length), 'records', dataSource)}
                    />

                </Card>

                <Modal
                    destroyOnClose={true}
                    style={{ top: 20 }}
                    title={
                        <>
                            <AppstoreAddOutlined />{' '}
                            {updateId ? t('costAllocationPage.update_title') : t('costAllocationPage.create_title')}
                        </>
                    }
                    visible={isModalVisible}
                    onCancel={() => {
                        dispatch(setDefaultCostAllocationCategory())
                        setModalVisible(false)
                    }
                    }
                    footer={null}>
                    {<CostAllocationCategoryForm
                        updateId={updateId}
                        setModalVisible={setModalVisible}
                    />
                    }
                </Modal>

                <HistoryModal service='billing' model='CostAllocationCategory'
                              modelId={historyModelId}
                              title={historyTitle}
                              isModalVisible={isHistoryModalVisible}
                              setModalVisible={() => setHistoryModalVisible(false)}
                              modalRender={(modal) => (
                                  <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                      <div ref={draggleRef}>{modal}</div>
                                  </Draggable>
                              )}
                />
            </>
        )
    }
    else {
        return (
            <Spin />
        )
    }
}

export default CostAllocationCategoryPage
