import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {RoleModel} from "../models";
import {CreateRoleErrorAction, CreateRoleRequestAction, CreateRoleResponseAction} from "./index";


export default (params: RoleModel, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | CreateRoleRequestAction
                | CreateRoleResponseAction
                | CreateRoleErrorAction,
        ) => void,
    ) => {
        const request = new CreateRoleRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<RoleModel>('/role', params)
            .then((response) => {
                dispatch(new CreateRoleResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new CreateRoleErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
