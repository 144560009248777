import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import userLogout from 'pages/login/actions/logout'
import {AppState} from 'common/models'
import {LoadingIndicator} from 'components'
import {useTranslation} from 'react-i18next'
import {message} from 'antd'
import {removeCurrentUser} from 'helpers/authHelpers'

const LogoutPage = () => {
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const {isLoading} = useSelector((state: AppState) => state.auth)

	useEffect(() => {
		dispatch(
			userLogout((isSuccess) => {
				removeCurrentUser()
				if (isSuccess) {
					message.success(t('logoutPage.logouted'))
				}
			}),
		)
	}, [])

	return isLoading ? <LoadingIndicator /> : <>Logout</>
}

export default LogoutPage
