import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import './SearchBox.css'
import {AutoComplete, Select, Input, Empty, Tag} from "antd";
import {ContactsOutlined, UserOutlined} from "@ant-design/icons";
import {removeDiac} from "../../common/fce";
import {ContactModel, CustomerDetails} from "../../pages/billing/customer/models";
import {useTranslation} from "react-i18next";
import getCustomerServicesAll from "../../pages/billing/customerservice/actions/getCustomerServicesAll";


// Searching in loaded state
// so far no API calls
const SearchBox = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const {users} = useSelector((state: AppState) => state.user)
    const {companies, customers} = useSelector((state: AppState) => state.auth.tables)
    const {contacts_all} = useSelector((state: AppState) => state.customer)
    const {customerservices_all} = useSelector((state: AppState) => state.customerservice)

    const [searchValue, setSearchValue] = useState<string>('')
    const [resultOptions, setResultOptions] = useState<{label: string, options: {value: string, label: JSX.Element}[]}[]>([])

    let counter = 0 // ordering of results

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);


    const doSearch = (value) => {
        // Search for users, companies, Customers, Invoices, Bills
        if (!companies || companies.length === 0) {
            console.log('companies: 0')
            return
        }
        if (!customers || customers.length === 0) {
            console.log('customers: 0')
            return
        }
        if (!customerservices_all || customerservices_all.length === 0) {
            console.log('customerservices_all: 0')
            //b dispatch(getCustomerServicesAll())
            return
        }
        if (!users || users.length === 0) {
            console.log('users: 0')
            return
        }



        counter = 0
        const results: {label: string, options: {value: string, key: string, label: JSX.Element}[]}[] = []

        const qUser = (c) => c.searchableName.includes(removeDiac(value))
        const userList = users.filter((c) => qUser(c))
        if (userList.length > 0) {
            // company found
            results.push({
                label: t('usersPage.title') + ` (${userList.length})`,
                options: userList.map(u => renderItem(`${u.title}`, `${u.email}, @${u.username}`, `user:${u.id}`, u.id))
            })
        }

        if (companies && companies.length > 0) {
            const qName = (c) => removeDiac(c.searchableName).includes(removeDiac(value))
            const compList = companies.filter((c) => qName(c))
            const compIds = compList.map(c => c.id)
            if (compList.length > 0) {
                // company found
                results.push({
                    label: t('companiesPage.title') + ` (${compList.length})`,
                    options: compList.map(c => renderItem(c.name, '',`company:${c.id}`, c.id))
                })
                if (customers && customers.length > 0) {
                    const custList = customers.filter((c) => compIds.includes(c.company_id))
                    if (custList.length > 0) {
                        // customer found
                        results.push({
                            label: t('customerPage.title') + ` (${custList.length})`,
                            options: custList.map(cu => renderItem(cu.company!.name, '', `customer:${cu.id}`, cu.id))
                        })
                    }
                }
            }
        }

        const qContact = (c) => c.searchableName.includes(removeDiac(value))
        const qContactPhone = (c) => c.phone.includes(removeDiac(value))
        const qContactEmail = (c) => c.email.includes(removeDiac(value))
        const contactList = contacts_all.filter((c) => qContact(c)) || qContactPhone || qContactEmail
        if (contactList.length > 0) {
            // contact found
            results.push({
                label: t('customerPage.contact_title') + ` (${contactList.length})`,
                options: contactList.map(c => renderItem(`${customers.find(cu => cu.id === c.customer_id)?.company!.name}`, presentContact(c), `contact:${c.id}`, c.id))
            })
        }

        if (customerservices_all && customerservices_all.length > 0) {
            const qService = (c) => c.searchableName.includes(removeDiac(value))
            const serviceList = customerservices_all.filter((c) => qService(c))
            if (serviceList.length > 0) {
                // service found
                results.push({
                    label: t('billing.customer-services.title') + ` (${serviceList.length})`,
                    options: serviceList.map(cs => renderItem(`${customers.find(cu => cu.id === cs.customer_id)?.company!.name}`, cs.name, `customer_service:${cs.customer_id}`, cs.customer_id))
                })
            }
        }

        setResultOptions(results)
    }

    const presentContact = (c: ContactModel) => {
        if (c.email && c.phone) {
            return `${c.email} (${c.phone})`.substr(0, 25) + '..'
        }
        if (c.email && !c.phone) {
            return c.email
        }
        if (!c.email && c.phone) {
            return c.phone
        }
        return c.name
    }

    const handleSearch = (value: string) => {
        setSearchValue(value)

        const item = {
            value: 'Search zatím nefunguje.',
            key: 'not-work',
            label: (
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span>Search zatím nefunguje.</span>
            </div>
        )}
        setResultOptions([{label: 'Search zatím nefunguje.', options:[item]}])
        return
        if (value && value.length > 2) {
            // min 3 char
            doSearch(value)
        }
        else {
            setResultOptions([])
        }
    }


    const stopPropagation = (e) => {
        e.stopPropagation()
        clearSearch()
    }

    function renderItem(title: string, subtitle: string, key: string, id: number):{value: string, key: string, label: JSX.Element} {
        // set ordering of result as title/key must be uniq
        counter++
        key = `${key}:${counter}`
        title = `${counter}.${title}`
        if (key.includes('customer:')) {
            return {
                value: title,
                key: key,
                label: (
                    <div key={'div_'+key} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span className='link'>{title.substr(0, 60)}</span>
                        <span><Link to={'/billing/invoice?name='+title} onClick={stopPropagation}>{t('billing.invoice.title')}</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/billing/customer-services/'+id} onClick={stopPropagation}>{t('billing.customer-services.title')}</Link></span>
                    </div>
                )
            }
        }

        if (key.includes('contact:')) {
            return {
                value: title,
                key: key,
                label: (
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span><span className='link'>{title.substr(0, 60)}</span> <i>{subtitle.substr(0, 60)}</i></span> <ContactsOutlined />
                    </div>
                )
            }
        }

        if (key.includes('customer_service:')) {
            return {
                value: title,
                key: key,
                label: (
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span><span className='link'>{title.substr(0, 60)}</span> <i>{subtitle.substr(0, 60)}</i></span>
                    </div>
                )
            }
        }

        if (key.includes('user:')) {
            return {
                value: title,
                key: key,
                label: (
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span><span className='link'>{title.substr(0, 60)}</span> <i>{subtitle.substr(0, 60)}</i></span> <UserOutlined/>
                    </div>
                )
            }
        }

        if (key.includes('company:')) {
            return {
                value: title,
                key: key,
                label: (
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span className='link'>{title.substr(0, 60)}</span> <Link to={'/billing/bill?name=' + title}
                                                                                  onClick={stopPropagation}>{t('billing.bill.title')}</Link>
                    </div>
                )
            }
        }
        return {
            value: title,
            key: key,
            label: (
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span className='link'>{title.substr(0, 60)}</span>
                </div>
            )
        }
    }


    const handleSelect = (value, option) => {
        const model = option.key.split(':')[0]
        const mid = option.key.split(':')[1]

        const pos = value.indexOf(".")
        if (pos > 0) {
            // remove ordering
            value = value.substr(pos+1)
        }

        if (model === 'user') {
            const title = value.split(',')[0]
            history.replace('/users?title='+title)
        }
        if (model === 'company') {
            history.replace('/billing/companies?name='+value)
        }
        if (model === 'customer') {
            history.replace('/billing/customer?name='+value)
        }
        if (model === 'contact') {
            const parts = value.split(' => ')
            history.replace('/billing/customer?name='+parts[0])
        }
        if (model === 'customer_service') {
            history.replace('/billing/customer-services/'+mid)
        }

        clearSearch()
    }

    const clearSearch = () => {
        setSearchValue('')
        setResultOptions([])
    }


    return (
        <>
            <AutoComplete
                className="searchBox" listHeight={500}
                dropdownMatchSelectWidth={600}
                notFoundContent={<span>Search zatím nefunguje.</span>}
                onSearch={handleSearch}
                onSelect={handleSelect}
                onBlur={() => clearSearch()}
                onKeyDown={(e => {
                    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab') {
                        e.stopPropagation()
                    }
                })}
                options={resultOptions}
                showSearch={true}
                value={searchValue}
            >
                <Input.Search size="large" placeholder={t('search.title')} />
            </AutoComplete>

        </>
    )
}

export default SearchBox