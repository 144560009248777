import React, {useState, useEffect, useCallback} from 'react'
import {Button, Form, Input, message, Select, Spin, Switch, Tooltip} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import createProduct from 'pages/billing/product/actions/createProduct'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {ProductDetails} from 'pages/billing/product/models'
import updateProduct from 'pages/billing/product/actions/updateProduct'
import {TaxDetails} from 'pages/billing/tax/models'
import {AppState} from 'common/models'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {CheckOutlined, CloseOutlined, QuestionCircleTwoTone} from "@ant-design/icons";


interface Props {
	productId: number | undefined
	isCreate?: boolean
	setModalVisible: (param: boolean) => void
}

const {Option} = Select
const {Item} = Form

const ProductForm = (props: Props) => {
	const [form] = useForm()
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const {products} = useSelector((state: AppState) => state.product)
	const {taxes} = useSelector((state: AppState) => state.tax)
	const {servicetypes} = useSelector((state: AppState) => state.servicetype)
	const {isLoading, isSaving, product} = useSelector((state: AppState) => state.product)

	const [dataToUpdate, setDataToUpdate] = useState<ProductDetails | undefined>(undefined)
	const [updateStatus, setUpdateStatus] = useState<boolean>(false)

	const [isPriceProrated, setPriceProrated] = useState(true)
	const [isExpired, setExpired] = useState(false)
	const [editing, setEditing] = useState<boolean>(false)
	const cze_taxes = taxes?.filter((item: TaxDetails) => item.country === 'CZ')

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (<Spin />)
	}
	const appSetting = loggedUser.getAppSettings()

	useEffect(() => {
		// trigger ONCE

	}, []);

	useEffect(() => {

		if (props.isCreate) {
			setDataToUpdate({
				id: 0,
				name: '',
				unit: '',
				decimal_places: 0,
				is_price_prorated: 1,
				tax_id: 84,
				expired: 0
			})
		}
		else {
			// edit
			if (product && props.productId === product.id) {
				// product was fetched from DB
				setDataToUpdate(product)
				setPriceProrated(product.is_price_prorated === 1)
				setExpired(product.expired === 1)
			}
		}

	}, [product]);

	useEffect( () => {
		// update values to form
		form.setFieldsValue(dataToUpdate)
	}, [dataToUpdate])

	const onFormSubmit = (values: Store) => {

		if (!props.isCreate && product && product.id && dataToUpdate) {
			// update
			dispatch(
				updateProduct(
					{
						id: product.id,
						name: values.name,
						service_type_id: values.service_type_id,
						unit: values.unit,
						tax_id: values.tax_id,
						expired: isExpired ? 1 : 0,
						is_price_prorated: isPriceProrated ? 1 : 0
					},
					(suc: boolean) => {
						if (suc) {
							message.success(t('general.success'))
							props.setModalVisible(false)
						}
					},
				),
			)
		}
		else {
			// create
			dispatch(
				createProduct(
					{
						name: values.name,
						service_type_id: values.service_type_id,
						unit: values.unit,
						tax_id: values.tax_id,
						expired: isExpired ? 1 : 0,
						is_price_prorated: isPriceProrated ? 1 : 0
					},
					(suc: boolean) => {
						if (suc) {
							message.success(t('general.success')).then()
							props.setModalVisible(false)
						}
					},
				),
			)
		}
	}

	if (!props.isCreate && (!product || props.productId != product.id)) {
		// wait for product loaded
		return (<Spin/>)
	}

	if (!dataToUpdate) {
		return (<Spin/>)
	}

	return (
		<Form form={form}
			{...formItemLayout}
			onFinish={onFormSubmit}
			onChange={() => setUpdateStatus(true)}
			initialValues={dataToUpdate}>

			<Item name='name'
				rules={[{required: true, message: t('billing.product.error.name')}]}
				label={t('billing.product.name')}>
				<Input />
			</Item>

			<Item name='service_type_id'
				label={t('billing.product.service_type')}
				rules={[{required: true, message: t('billing.product.error.service_type_id')}]}>
				<Select showSearch allowClear
						optionFilterProp='children'
						loading={isSaving}
						virtual={false}
						onChange={() => setUpdateStatus(true)}>
					{servicetypes &&
					servicetypes.map((i) => (
						<Option value={i.id} key={i.id}>
							{i.name}
						</Option>
					))}
				</Select>
			</Item>

			<Item name='unit' label={t('billing.product.unit')}>
				<Input />
			</Item>

			<Item name='tax_id'
				label={t('billing.product.tax_id')}
				rules={[{required: true, message: t('billing.product.error.tax_id')}]}>
				<Select onChange={() => setUpdateStatus(true)}>
					{cze_taxes &&
					cze_taxes.map((i) => (
						<Option key={i.id} value={i.id}>
							{appSetting.renderPercent(i.rate)}
						</Option>
					))}
				</Select>
			</Item>

			<Item name='is_price_prorated'
				  label={t('billing.product.is_price_prorated')}
				  valuePropName='checked'
			>
				<Switch
					checkedChildren={<CheckOutlined />}
					unCheckedChildren={<CloseOutlined />}
					checked={isPriceProrated}
					onChange={() => {setPriceProrated(!isPriceProrated); setUpdateStatus(true)}}
				/>
				<Tooltip title={t('billing.product.proratedPriceHelper')}>
					<Button type='text'
							size='small'
							className='actionButton'
							icon={<QuestionCircleTwoTone  />}
					/>
				</Tooltip>
			</Item>

			<Item name='expired' hasFeedback label={t('billing.product.expired')}>
				<Switch
					checkedChildren={<CheckOutlined />}
					unCheckedChildren={<CloseOutlined />}
					checked={isExpired}
					onChange={() => {setExpired(!isExpired);setUpdateStatus(true)}}
				/>
			</Item>

			<Item {...tailLayout}>
				<Button type='primary' htmlType='submit'
						loading={isSaving}
						disabled={!updateStatus}>
					{dataToUpdate ? t('general.update') : t('general.create')}
				</Button>
			</Item>
		</Form>
	)
}

export default ProductForm
