import React, {useEffect, useRef, useState} from 'react'
import {Card, Button, Modal, message, Space, Table} from 'antd'
import {DatabaseOutlined, PlusCircleOutlined} from '@ant-design/icons/lib/icons'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {DataCenterModel} from './actions/models'
import DatacenterForm from 'pages/datacenter/datacenter/DatacenterForm'
import getDatacenters from './actions/getDatacenters'
import getLocations from 'pages/inventory/location/actions/getLocations'
import createDatacenter from './actions/createDatacenter'
import deleteDatacenter from './actions/deleteDatacenter'
import updateDatacenter from './actions/updateDatacenter'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import getHistoryLogs from "../../../components/History/actions/getHistoryLogs";
import {sort_name} from "../../../common/sorting";
import {AppState} from "../../../common/models";
import TotalNum from "../../../components/TotalNum/TotalNum";
import {
    DeleteTwoTone,
    EditTwoTone,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    InfoCircleTwoTone
} from "@ant-design/icons";
import HistoryLog from "../../../components/History/HistoryLog";
import HistoryModal from "../../../components/History/HistoryModal";


const { confirm } = Modal

const DatacenterPage = () => {
    const CONTROL_NAME = 'page_datacenter_datacenter'
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {locations} = useSelector((state: AppState) => state.inventorylocation)
    const {datacenters, isLoading} = useSelector((state: AppState) => state.datacenter)
    const {datacenter_datacenters} = useSelector((state: AppState) => state.sidenav)

    const [dataToUpdate, setDataToUpdate] = useState<DataCenterModel>()
    const [isModalVisible, setModalVisible] = useState<boolean>(false)
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [dataSource, setDataSource] = useState<DataCenterModel[]>([])

    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    const hideModal = () => setModalVisible(false)
    const showModal = () => setModalVisible(true)

    const [isEditor, setEditor] = useState(false)

    // get settings and logged user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403/>
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({left: 0, top: 0, bottom: 0, right: 0})
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const {clientWidth, clientHeight} = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    let triggerOneTimer = 0

    useEffect(() => {
        // trigger ONCE
        triggerOneTimer = Date.now().valueOf()
        setEditor(loggedUser.hasAccess('page_datacenter_datacenter_edit_button'))
        dispatch(getDatacenters())
        dispatch(getLocations())
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }
    }, [datacenters, isModalVisible])

    useEffect(() => {
        // trigger from Side menu clicked
        if (!isLoading) {
            const when = triggerOneTimer + 1000
            if (when < Date.now().valueOf()) {
                // when triggerOneTimer was not called (after 1sec. of SideMenu click)
                dispatch(getDatacenters())
            }
        }
    }, [datacenter_datacenters])

    const refreshGrid = () => {
        setDataSource(datacenters.sort(sort_name))
    }

    const onFinish = (values: DataCenterModel) => {
        if (dataToUpdate) {
            dispatch(updateDatacenter(
                {...values, id: dataToUpdate.id},
                isOk => {
                    isOk && message.success(t('DatacenterPage.updated'))
                    hideModal()
                }
            ))
        } else {
            dispatch(createDatacenter(
                {...values},
                isOk => {
                    isOk && message.success(t('DatacenterPage.created'))
                    hideModal()
                }
            ))
        }
    }

    const showConfirmDelete = (obj: DataCenterModel, title: string) => {
        if (!obj) {
            return
        }
        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{obj.name}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteDatacenter(obj.id,suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const columns = [
        {
            title: t('DatacenterPage.name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Action',
            key: 'action',
            width: 120,
            dataIndex: 'action',
            render: (_, record: DataCenterModel) => (
                <Space size='small'>
                    {
                        isEditor && (
                            <Button type='text'
                                    size='small'
                                    onClick={() => {
                                        setDataToUpdate(record)
                                        setModalVisible(true)
                                    }}
                                    className='actionButton'
                                    icon={<EditTwoTone twoToneColor='green' />}
                            />
                        )
                    }

                    {
                        isEditor && (
                            <Button type='text' danger size='small'
                                    className='actionButton'
                                    onClick={() => showConfirmDelete(record, t('DatacenterPage.confirm_delete'))}
                                    icon={<DeleteTwoTone twoToneColor='red'/>} />
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(record.id)
                                setHistoryTitle(record.name)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                </Space>
            ),
        },
    ]

    return (
        <>
            <Card className='DatacenterPage'
                  title={<><DatabaseOutlined/> &nbsp; {t('DatacenterPage.title')}</>}
                  extra={
                      <Button type='primary'
                              onClick={() => {
                                  setDataToUpdate(undefined)
                                  setModalVisible(true)
                              }}
                      >
                          <PlusCircleOutlined/> {t('DatacenterPage.create')}
                      </Button>}
            >

                {
                    appSetting &&
                    <Table<DataCenterModel>
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columns}
                        loading={isLoading}
                        dataSource={dataSource}
                        className='DatacenterPage'
                        rowKey='id'
                        pagination={false}
                        footer={() => TotalNum(Number(dataSource.length), 'Datacentra', dataSource)}
                    />
                }
            </Card>

            <Modal
                destroyOnClose
                style={{top: 20}}
                width={600}
                title={<>
                    <DatabaseOutlined/> &nbsp;
                    {dataToUpdate ? t('DatacenterPage.update-title') : t('DatacenterPage.create-title')}
                </>}
                visible={isModalVisible}
                onCancel={hideModal}
                footer={null}
                confirmLoading={true}
            >
                <DatacenterForm dataToUpdate={dataToUpdate} onFinish={onFinish}/>
            </Modal>

            <HistoryModal service='datacenter' model='Datacenter'
                          modelId={historyModelId}
                          title={historyTitle}
                          isModalVisible={isHistoryModalVisible}
                          setModalVisible={() => setHistoryModalVisible(false)}
                          modalRender={(modal) => (
                              <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                  <div ref={draggleRef}>{modal}</div>
                              </Draggable>
                          )}
            />
        </>
    )
}

export default DatacenterPage