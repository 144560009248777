import { AsyncActionMode } from 'common/models'
import {
  CompanyParams,
  UpdateCompanyParams,
  CompanyDetails, CompanyGrid,
} from '../models'


export enum ActionType {
  GET_COMPANY = 'GET_COMPANY',
  CREATE_COMPANY = 'CREATE_COMPANY',
  GET_COMPANIES = 'GET_COMPANIES',
  UPDATE_COMPANY = 'UPDATE_COMPANY',
  DELETE_COMPANY = 'DELETE_COMPANY',
  CLEAR_COMPANY = 'CLEAR_COMPANY',
  LOOKUP_COMPANY = 'LOOKUP_COMPANY'
}

export type CompanyActions =
  | GetCompanyRequestAction
  | GetCompanyResponseAction
  | GetCompanyErrorAction
  | CreateCompanyRequestAction
  | CreateCompanyResponseAction
  | CreateCompanyErrorAction
  | UpdateCompanyRequestAction
  | UpdateCompanyResponseAction
  | UpdateCompanyErrorAction
  | GetCompaniesRequestAction
  | GetCompaniesResponseAction
  | GetCompaniesErrorAction
  | DeleteCompanyRequestAction
  | DeleteCompanyResponseAction
  | DeleteCompanyErrorAction
  | ClearCompanyRequestAction
  | LookupCompanyRequestAction
  | LookupCompanyResponseAction
  | LookupCompanyErrorAction


export class ClearCompanyRequestAction {
  readonly type = ActionType.CLEAR_COMPANY
  readonly mode = AsyncActionMode.REQUEST
  constructor() { '' }
}

export class GetCompanyRequestAction {
  readonly type = ActionType.GET_COMPANY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: number) {
    ''
  }
}
export class GetCompanyResponseAction {
  readonly type = ActionType.GET_COMPANY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: GetCompanyRequestAction,
    public data: CompanyDetails,
  ) {}
}
export class GetCompanyErrorAction {
  readonly type = ActionType.GET_COMPANY
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: GetCompanyRequestAction,
    public error: string,
  ) {}
}

export class CreateCompanyRequestAction {
  readonly type = ActionType.CREATE_COMPANY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: CompanyParams) {}
}
export class CreateCompanyResponseAction {
  readonly type = ActionType.CREATE_COMPANY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: CreateCompanyRequestAction,
    public data: CompanyDetails,
  ) {}
}
export class CreateCompanyErrorAction {
  readonly type = ActionType.CREATE_COMPANY
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: CreateCompanyRequestAction,
    public error: string,
  ) {}
}

export class GetCompaniesRequestAction {
  readonly type = ActionType.GET_COMPANIES
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetCompaniesResponseAction {
  readonly type = ActionType.GET_COMPANIES
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: GetCompaniesRequestAction,
    public data: CompanyGrid,
  ) {}
}
export class GetCompaniesErrorAction {
  readonly type = ActionType.GET_COMPANIES
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: GetCompaniesRequestAction,
    public error: string,
  ) {}
}

export class UpdateCompanyRequestAction {
  readonly type = ActionType.UPDATE_COMPANY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: UpdateCompanyParams) {}
}
export class UpdateCompanyResponseAction {
  readonly type = ActionType.UPDATE_COMPANY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: UpdateCompanyRequestAction,
    public data: CompanyDetails,
  ) {}
}
export class UpdateCompanyErrorAction {
  readonly type = ActionType.UPDATE_COMPANY
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: UpdateCompanyRequestAction,
    public error: string,
  ) {}
}

export class DeleteCompanyRequestAction {
  readonly type = ActionType.DELETE_COMPANY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}
export class DeleteCompanyResponseAction {
  readonly type = ActionType.DELETE_COMPANY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: DeleteCompanyRequestAction,
    public data: CompanyDetails,
  ) {}
}
export class DeleteCompanyErrorAction {
  readonly type = ActionType.DELETE_COMPANY
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: DeleteCompanyRequestAction,
    public error: string,
  ) {}
}


export class LookupCompanyRequestAction {
  readonly type = ActionType.LOOKUP_COMPANY
  readonly mode = AsyncActionMode.REQUEST
  constructor(public field: string) {
    ''
  }
}
export class LookupCompanyResponseAction {
  readonly type = ActionType.LOOKUP_COMPANY
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: LookupCompanyRequestAction,
      public field: string,
      public data: string[],
  ) {}
}
export class LookupCompanyErrorAction {
  readonly type = ActionType.LOOKUP_COMPANY
  readonly mode = AsyncActionMode.ERROR
  constructor(public request: LookupCompanyRequestAction, public error: string) {}
}