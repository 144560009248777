import { AsyncActionMode } from 'common/models'
import { ChangesModel, HistoryModel, HypervisorModel, VpsSettingModel } from "../models";


export enum ActionType {
    GET_HYPERVISORS = 'GET_HYPERVISORS',
    GET_HYPERVISOR = 'GET_HYPERVISOR',
    GET_VPS_SERVER = 'GET_VPS_SERVER',
    GET_VPS_SERVERS = 'GET_VPS_SERVERS',
    GET_HYPERVISOR_BY_HOST = 'GET_HYPERVISOR_BY_HOST',
    GET_VPSADMIN_SETTINGS = "GET_VPSADMIN_SETTINGS",
    GET_VPSADMIN_SETTING = "GET_VPSADMIN_SETTING",
    CREATE_VPSADMIN_SETTING = "CREATE_VPSADMIN_SETTING",
    UPDATE_VPSADMIN_SETTING = "UPDATE_VPSADMIN_SETTING",
    DELETE_VPSADMIN_SETTING = "DELETE_VPSADMIN_SETTING",
    SCAN_VPSADMIN_SETTING = "SCAN_VPSADMIN_SETTING",
    SCAN_VPSADMIN_SETTINGS = "SCAN_VPSADMIN_SETTINGS",
    WIPE_DATABASE = "WIPE_DATABASE",
    SET_DEFAULT_VPSADMIN_SETTING = "SET_DEFAULT_VPSADMIN_SETTING",
    SET_DEFAULT_HYPERVISOR = "SET_DEFAULT_HYPERVISOR",
    GET_HISTORIES_BY_HOST_NAME = "GET_HISTORIES_BY_HOST_NAME",
    GET_CHANGES_BY_ID = "GET_CHANGES_BY_ID",
    SET_DEFAULT_VPS_SERVERS = 'SET_DEFAULT_VPS_SERVERS',
    SET_DEFAULT_SCANNING = 'SET_DEFAULT_SCANNING',
}

export type VpsSettingActions =
    | GetVpsSettingRequestAction
    | GetVpsSettingResponseAction
    | GetVpsSettingErrorAction

    | GetVpsSettingsRequestAction
    | GetVpsSettingsResponseAction
    | GetVpsSettingsErrorAction

    | CreateVpsSettingRequestAction
    | CreateVpsSettingResponseAction
    | CreateVpsSettingErrorAction

    | DeleteVpsSettingRequestAction
    | DeleteVpsSettingResponseAction
    | DeleteVpsSettingErrorAction

    | UpdateVpsSettingRequestAction
    | UpdateVpsSettingResponseAction
    | UpdateVpsSettingErrorAction

    | ScanVpsSettingRequestAction
    | ScanVpsSettingResponseAction
    | ScanVpsSettingErrorAction

    | ScanVpsSettingsRequestAction
    | ScanVpsSettingsResponseAction
    | ScanVpsSettingsErrorAction

    | SetDefaultScanningAction

    | WipeDatabaseRequestAction
    | WipeDatabaseResponseAction
    | WipeDatabaseErrorAction

    | SetDefaultVpsSettingAction
    | SetDefaultHypervisorAction
    | SetDefaultVPSServersAction

export type HistoryActions =
    | GetContainersHistoriesResponseAction
    | GetContainersHistoriesErrorAction
    | GetContainersHistoriesRequestAction


export type ChangesActions =
    | GetContainersChangesResponseAction
    | GetContainersChangesErrorAction
    | GetContainersChangesRequestAction

export type HypervisorActions =

    | GetHypervisorsRequestAction
    | GetHypervisorsResponseAction
    | GetHypervisorsErrorAction

    | GetHypervisorRequestAction
    | GetHypervisorResponseAction
    | GetHypervisorErrorAction

    | GetHypervisorByHostRequestAction
    | GetHypervisorByHostResponseAction
    | GetHypervisorByHostErrorAction

    | GetVpsServerRequestAction
    | GetVpsServerResponseAction
    | GetVpsServerErrorAction

    | GetVpsServersRequestAction
    | GetVpsServersResponseAction
    | GetVpsServersErrorAction



// get Changes records by host name => 
export class GetContainersChangesRequestAction {
    readonly type = ActionType.GET_CHANGES_BY_ID
    readonly mode = AsyncActionMode.REQUEST
    constructor(id) {
        ''
    }
}
export class GetContainersChangesResponseAction {
    readonly type = ActionType.GET_CHANGES_BY_ID
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetContainersChangesRequestAction, public data: ChangesModel[]) { }
}
export class GetContainersChangesErrorAction {
    readonly type = ActionType.GET_CHANGES_BY_ID
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetContainersChangesRequestAction, public error: string) { }
}


// get History records by host name => 
export class GetContainersHistoriesRequestAction {
    readonly type = ActionType.GET_HISTORIES_BY_HOST_NAME
    readonly mode = AsyncActionMode.REQUEST
    constructor(id) {
        ''
    }
}
export class GetContainersHistoriesResponseAction {
    readonly type = ActionType.GET_HISTORIES_BY_HOST_NAME
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetContainersHistoriesRequestAction, public data: HistoryModel[]) { }
}
export class GetContainersHistoriesErrorAction {
    readonly type = ActionType.GET_HISTORIES_BY_HOST_NAME
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetContainersHistoriesRequestAction, public error: string) { }
}

// get vps cotainers => 
export class GetVpsServersRequestAction {
    readonly type = ActionType.GET_VPS_SERVERS
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetVpsServersResponseAction {
    readonly type = ActionType.GET_VPS_SERVERS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetVpsServersRequestAction, public data: HypervisorModel[]) { }
}
export class GetVpsServersErrorAction {
    readonly type = ActionType.GET_VPS_SERVERS
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetVpsServersRequestAction, public error: string) { }
}

// get vps cotainer => 
export class GetVpsServerRequestAction {
    readonly type = ActionType.GET_VPS_SERVER
    readonly mode = AsyncActionMode.REQUEST
    constructor(id: string) {
        ''
    }
}
export class GetVpsServerResponseAction {
    readonly type = ActionType.GET_VPS_SERVER
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetVpsServerRequestAction, public data: HypervisorModel) { }
}
export class GetVpsServerErrorAction {
    readonly type = ActionType.GET_VPS_SERVER
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetVpsServerRequestAction, public error: string) { }
}



// get hypervisor => 
export class GetHypervisorRequestAction {
    readonly type = ActionType.GET_HYPERVISOR
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: string) {
        ''
    }
}
export class GetHypervisorResponseAction {
    readonly type = ActionType.GET_HYPERVISOR
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetHypervisorRequestAction, public data: HypervisorModel) { }
}
export class GetHypervisorErrorAction {
    readonly type = ActionType.GET_HYPERVISOR
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetHypervisorRequestAction, public error: string) { }
}
// get hypervisor by host => 
export class GetHypervisorByHostRequestAction {
    readonly type = ActionType.GET_HYPERVISOR_BY_HOST
    readonly mode = AsyncActionMode.REQUEST
    constructor(host: string) {
        ''
    }
}
export class GetHypervisorByHostResponseAction {
    readonly type = ActionType.GET_HYPERVISOR_BY_HOST
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetHypervisorByHostRequestAction, public data: HypervisorModel[]) { }
}
export class GetHypervisorByHostErrorAction {
    readonly type = ActionType.GET_HYPERVISOR_BY_HOST
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetHypervisorByHostRequestAction, public error: string) { }
}
export class GetHypervisorsRequestAction {
    readonly type = ActionType.GET_HYPERVISORS
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetHypervisorsResponseAction {
    readonly type = ActionType.GET_HYPERVISORS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetHypervisorsRequestAction, public data: HypervisorModel[]) { }
}
export class GetHypervisorsErrorAction {
    readonly type = ActionType.GET_HYPERVISORS
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetHypervisorsRequestAction, public error: string) { }
}


// get one =>
export class GetVpsSettingRequestAction {
    readonly type = ActionType.GET_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: number) {
        ''
    }
}
export class GetVpsSettingResponseAction {
    readonly type = ActionType.GET_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetVpsSettingRequestAction, public data: VpsSettingModel) { }
}
export class GetVpsSettingErrorAction {
    readonly type = ActionType.GET_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetVpsSettingRequestAction, public error: string) { }
}
// get all =>
export class GetVpsSettingsRequestAction {
    readonly type = ActionType.GET_VPSADMIN_SETTINGS
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetVpsSettingsResponseAction {
    readonly type = ActionType.GET_VPSADMIN_SETTINGS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetVpsSettingsRequestAction, public data: VpsSettingModel[]) { }
}
export class GetVpsSettingsErrorAction {
    readonly type = ActionType.GET_VPSADMIN_SETTINGS
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetVpsSettingsRequestAction, public error: string) { }
}

// create =>
export class CreateVpsSettingRequestAction {
    readonly type = ActionType.CREATE_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: VpsSettingModel) { }

}
export class CreateVpsSettingResponseAction {
    readonly type = ActionType.CREATE_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: CreateVpsSettingRequestAction, public data: VpsSettingModel) { }
}
export class CreateVpsSettingErrorAction {
    readonly type = ActionType.CREATE_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: CreateVpsSettingRequestAction, public error: string) { }
}

// update =>
export class UpdateVpsSettingRequestAction {
    readonly type = ActionType.UPDATE_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: VpsSettingModel) { }

}
export class UpdateVpsSettingResponseAction {
    readonly type = ActionType.UPDATE_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: UpdateVpsSettingRequestAction, public data: VpsSettingModel) { }
}
export class UpdateVpsSettingErrorAction {
    readonly type = ActionType.UPDATE_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: UpdateVpsSettingRequestAction, public error: string) { }
}

// delete =>
export class DeleteVpsSettingRequestAction {
    readonly type = ActionType.DELETE_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.REQUEST
    constructor(public id: number) { }

}
export class DeleteVpsSettingResponseAction {
    readonly type = ActionType.DELETE_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: DeleteVpsSettingRequestAction, public data: number) { }
}
export class DeleteVpsSettingErrorAction {
    readonly type = ActionType.DELETE_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: DeleteVpsSettingRequestAction, public error: string) { }
}

//scan one server =>
export class ScanVpsSettingRequestAction {
    readonly type = ActionType.SCAN_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: number) { }

}
export class ScanVpsSettingResponseAction {
    readonly type = ActionType.SCAN_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: ScanVpsSettingRequestAction, public data: unknown) { }
}

export class ScanVpsSettingErrorAction {
    readonly type = ActionType.SCAN_VPSADMIN_SETTING
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: ScanVpsSettingRequestAction, public error: string) { }
}

//scan all servers
export class ScanVpsSettingsRequestAction {
    readonly type = ActionType.SCAN_VPSADMIN_SETTINGS
    readonly mode = AsyncActionMode.REQUEST
    constructor() { }

}
export class ScanVpsSettingsResponseAction {
    readonly type = ActionType.SCAN_VPSADMIN_SETTINGS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: ScanVpsSettingsRequestAction, public data: unknown) { }
}
export class ScanVpsSettingsErrorAction {
    readonly type = ActionType.SCAN_VPSADMIN_SETTINGS
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: ScanVpsSettingsRequestAction, public error: string) { }
}

//wipe db
export class WipeDatabaseRequestAction {
    readonly type = ActionType.WIPE_DATABASE
    readonly mode = AsyncActionMode.REQUEST
    constructor() { }

}
export class WipeDatabaseResponseAction {
    readonly type = ActionType.WIPE_DATABASE
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: WipeDatabaseRequestAction, public data: unknown) { }
}
export class WipeDatabaseErrorAction {
    readonly type = ActionType.WIPE_DATABASE
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: WipeDatabaseRequestAction, public error: string) { }
}


export class SetDefaultScanningAction {
    readonly type = ActionType.SET_DEFAULT_SCANNING
    constructor() { '' }
}


// set default undefined
export class SetDefaultVpsSettingAction {
    readonly type = ActionType.SET_DEFAULT_VPSADMIN_SETTING
    constructor() { '' }
}
// set default undefined
export class SetDefaultHypervisorAction {
    readonly type = ActionType.SET_DEFAULT_HYPERVISOR
    constructor() { '' }
}
export class SetDefaultVPSServersAction {
    readonly type = ActionType.SET_DEFAULT_VPS_SERVERS
    constructor() { '' }
}