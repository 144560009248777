import {
    SetDefaultCostAllocationCategoryAction

} from '.'

export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: SetDefaultCostAllocationCategoryAction
        ) => void,
    ) => {

        dispatch(new SetDefaultCostAllocationCategoryAction())

    }
}

