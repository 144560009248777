import React, {useEffect, useState} from 'react'
import {Button, Form, Input, message, Select} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import createTax from 'pages/billing/tax/actions/createTax'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {UpdateTaxParams} from 'pages/billing/tax/models'
import moment from 'moment'
import updateTax from 'pages/billing/tax/actions/updateTax'
import {sort_label} from "../../../common/sorting";
import DateInput from "../../../components/RangeFilter/DateInput";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";

interface Props {
  dataToUpdate?: UpdateTaxParams
  setModalVisible: (param: boolean) => void
}

const {Item} = Form

const TaxForm = ({dataToUpdate, setModalVisible}: Props) => {

  const [form] = useForm()
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const {isLoading: isCountriesLoading, countries} = useSelector((state: AppState) => state.countries)
  const {isSaving: isTaxSaving} = useSelector((state: AppState) => state.tax)

  const [updateStatus, setUpdateStatus] = useState<boolean>(false)
  const [countryOptions, setCountryOptions] = useState<{ label: string, value: string }[]>([])

  // get settings and logged user from store
  const loggedUser = useLoggedUser()
  if (!loggedUser || !loggedUser.isLoaded()) {
    return (
        <div className="fullwidth-loading">
          <LoadingIndicator/>
        </div>
    )
  }
  const appSetting = loggedUser.getAppSettings()

  useEffect(() => {
    // Customer Options
    if (countries && countries.length) {
      const items = countries.map(c => ({label: c.name_cz ? c.name_cz : '', value: c.iso}))
      setCountryOptions(items.sort(sort_label))
    }
  }, [countries])

  const onFormSubmit = (values: Store) => {
    if (appSetting) {
      if (dataToUpdate) {
        dispatch(updateTax({
          id: dataToUpdate.id,
          name: values.name,
          rate: parseInt(values.rate) * 100,
          valid_from: appSetting.renderDateSQL(values.valid_from),
          valid_to: appSetting.renderDateSQL(values.valid_to),
          country: values.country
        }, (isSuccess: boolean) => {
          if (isSuccess) {
            setModalVisible(false)
            message.info(t('billing.tax.updated'))
          }
        }))
      }
      else {
        dispatch(createTax({
              name: values.name,
              rate: parseInt(values.rate) * 100,
              valid_from: appSetting.renderDateSQL(values.valid_from),
              valid_to: appSetting.renderDateSQL(values.valid_to),
              country: values.country
            }, (isSuccess: boolean) => {
              if (isSuccess) {
                setModalVisible(false)
                message.info(t('billing.tax.created'))
              }
            }
        ))
      }
    }
  }

  const renderData = () => {
    // render data in correct format
    if (appSetting && dataToUpdate) {
      const obj = {
        ...dataToUpdate,
        valid_from: dataToUpdate.valid_from ? moment(dataToUpdate.valid_from) : '',
        valid_to: dataToUpdate.valid_to ? moment(dataToUpdate.valid_to) : '',
        rate: dataToUpdate.rate / 100
      }
      return obj
    }
    return {country: 'CZ'}
  }

  return (
    <Form
      {...formItemLayout}
      onFinish={onFormSubmit}
      form={form}
      onChange={() => setUpdateStatus(true)}
      initialValues={renderData()}
    >
      <Item
        name='name'
        rules={[{required: true, message: t('billing.tax.error.name')}]}
        label={t('billing.tax.name')}
        hasFeedback
      >
        <Input />
      </Item>

      <Item
        name='rate'
        rules={[{required: true, message: t('billing.tax.error.rate')}]}
        label={t('billing.tax.rate')}
        hasFeedback
      >
        <Input type='number' />
      </Item>

      <Item name='valid_from'
        rules={[{required: true, message: t('billing.tax.error.valid_from')}]}
        label={t('billing.tax.valid_from')}
      >
        <DateInput htmlId='__valid_from'
                   initDateStr={dataToUpdate && dataToUpdate.valid_from ? appSetting.renderDateSQL(moment(dataToUpdate.valid_from).toDate()) : appSetting.renderDateSQL(moment().toDate())}
                   disabled={false}
                   required={true}
                   format={appSetting.date_picker_format} // not Moment formats!!
                   onChange={(date) => {
                     date && form.setFieldsValue({valid_from: appSetting.renderDateSQL(date)})
                     setUpdateStatus(true)
                   }}
        />

      </Item>

      <Item name='valid_to' label={t('billing.tax.valid_to')} >
        <DateInput htmlId='__valid_to'
                   initDateStr={dataToUpdate && dataToUpdate.valid_to ? appSetting.renderDateSQL(moment(dataToUpdate.valid_to).toDate()) : ''}
                   disabled={false}
                   format={appSetting.date_picker_format} // not Moment formats!!
                   onChange={(date) => {
                     date && form.setFieldsValue({valid_to: appSetting.renderDateSQL(date)})
                     setUpdateStatus(true)
                   }}
        />
      </Item>

      <Item
        name='country'
        rules={[{required: true, message: t('billing.tax.error.country')}]}
        label={t('billing.tax.country')}
        hasFeedback
      >
        <Select
          loading={isCountriesLoading}
          showSearch
          optionFilterProp='children'
          onChange={() => setUpdateStatus(true)}
          allowClear
          value='CZ'
          options={countryOptions}
        />

      </Item>

      <Item {...tailLayout}>
        <Button loading={isTaxSaving} type='primary' htmlType='submit' className='login-form-button' disabled={!updateStatus}>
          {
            dataToUpdate ? t('billing.tax.update') : t('billing.tax.create')
          }
        </Button>
      </Item>

    </Form>
  )
}

export default TaxForm
