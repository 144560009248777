import {
  UpdateVpsSettingRequestAction,
  UpdateVpsSettingResponseAction,
  UpdateVpsSettingErrorAction
} from '.'

import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

import { VpsSettingModel } from '../models'


export default (params: VpsSettingModel, cb?: (isSuccess: boolean) => void) => {
  return (
    dispatch: (
      arg:
        | UpdateVpsSettingRequestAction
        | UpdateVpsSettingResponseAction
        | UpdateVpsSettingErrorAction
    ) => void,
  ) => {

    const request = new UpdateVpsSettingRequestAction(params)
    dispatch(request)
    console.log(params)
    protectedApiClient
      .put<VpsSettingModel>(`/vpsadmin/setting/${params.id}`, params)
      .then((response) => {
        dispatch(new UpdateVpsSettingResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new UpdateVpsSettingErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
