import {Pagination} from "../../../common/models";
import {CustomerDetails} from "../../billing/customer/models";


export interface DomainGrid {
  items: DomainModel[]
  pagination: Pagination
}

export interface DnsPageState {
  isSaving: boolean
  isLoading: boolean
  isLoadingDomain: boolean
  isLoadingService: boolean
  isLoadingArchive: boolean
  isSavingZone: boolean
  isLoadingZone: boolean
  domain?: DomainModel
  domains: DomainGrid
  pager: Pagination
  dnsZone?: DnsZoneModel
  archived?: DnsZoneModel
  dnsZones: DnsZone[]
  dnsService?: DnsServiceModel
  dnsServices: DnsServiceModel[]
  dnsServiceNames: DnsServiceName[]
  dnsRecords: DnsRecordModel[]
  exportedFile: string
  dnsChecks: MapCheck
  lookup_domain: string[]
  isLoadingLookup: boolean
  error?: string
}

export type MapCheck = {
  [key: string]: DnsCheck;
};

export interface DnsServiceName {
  id: number
  name: string
}

export interface DnsServiceModel extends DnsServiceParams {
  id: number
}

export interface DriverParams {
  token: string
}

export interface DnsServiceParams {
  id?: number
  host: string
  owner_id: number
  driver_id: number
  driver?: string
  protocol: string
  port: number
  params: string // JSON DriverParams
  instance?: string //localhost
  username?: string
  password?: string
  base_url?: string //API path
  token_value?: string //API token value
  is_active?: number
  is_shared?: number

}

export interface DeleteDnsServiceParams {
  id: number
}

export interface DnsZone {
  domain: string
  dnssec: boolean
  state: string
  type: string
}

export interface DnsZoneName {
  name: string
  id: string
}

export interface DnsCheck {
  domain: string
  type: string
  state: string
  ts_called?: number
}

export interface DomainService {
  type_id: number
  name: string
  service_id?: number
}

export interface DomainModel {
  id: number
  name: string
  customer_id: number
  customer?: CustomerDetails
  services?: DomainService[]
  has_dns?: boolean
}

export interface DomainServiceParams {
  id: number  // domain
  service_id: number
}

export interface CreateDomainParam {
  id: number
  name: string
  nameserver_id?: number
}

export interface DnsZoneModel {
  zone: string
  dnssec: boolean
  records: DnsRecordModel[]
  archived?: DnsZoneModel
}

export interface CreateDnsZoneParams {
  service_id: number
  zone: string //Domain
  ns1?: string
  ns2?: string
}

export interface DeleteDnsZoneParams {
  id: number
  zone: string
}

export interface GetZoneParams {
  service_id: number
  domain: string
}

export interface ExportDnsZoneParams {
  service_id: number
  domain: string
}

export interface SetSoaTtlDnsZoneParams {
  id: number
  domain: string
  ttl: number //TTL
}

export interface SetDnsSecDnsZoneParams {
  service_id: number
  zone: string
  dnssec: 1 | 0
}

export interface DnsRecordModel extends AddDnsRecordParams {}

export interface AddDnsRecordParams {
  id: number      // domain or service
  digest?: string
  zone: string // Domain
  name: string // @
  type: string
  is_new?: Boolean
  content: string
  ttl: number //TTL
  sort_searchable?: string
}

export interface RemoveDnsRecordParams {
  id: number    // domain or service
  digest: string
  domain: string // Domain
  is_new?: Boolean
}


export enum DNSType {
  SOA = 'SOA',
  A = 'A',
  AAAA = 'AAAA',
  CNAME = 'CNAME',
  MX = 'MX',
  NS = 'NS',
  PTR = 'PTR',
  SRV = 'SRV',
  TXT = 'TXT',
}
