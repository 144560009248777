import {
    CreateUserGroupRequestAction,
    CreateUserGroupResponseAction,
    CreateUserGroupErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { UserGroupModel, CreateGroupParams } from '../models'

export default (
    params: CreateGroupParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | CreateUserGroupRequestAction
                | CreateUserGroupResponseAction
                | CreateUserGroupErrorAction,
        ) => void,
    ) => {
        const request = new CreateUserGroupRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<UserGroupModel>(`/customer/${params.customer_id}/user-group`, params)
            .then((response) => {
                dispatch(new CreateUserGroupResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new CreateUserGroupErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
