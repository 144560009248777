import {EndPointModel, RoleModel, RolesPageState} from "./models";
import {AsyncActionMode} from "../../../common/models";
import {ActionType, RoleActions} from "./actions";


const ROLE_PAGE_INITIAL_STATE: RolesPageState = {
    isLoadingRole: false,
    isLoadingEP: false,
    isLoadingEPAll: false,
    isSaving: false,
    roles: [],
    endpoints: [],
    allEndPoints: [],
    error: undefined
}


export default (
    state = ROLE_PAGE_INITIAL_STATE,
    action: RoleActions,
): RolesPageState => {
    switch (action.type) {
        case ActionType.GET_ROLE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state,
                    role: undefined,
                    endpoints: [],
                    isLoadingRole: true
                }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoadingRole: false,
                    role: {...action.data},
                    endpoints: action.data.endpoints ? action.data.endpoints : [],
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingRole: false,
                    error: action.error,
                }
            }
            break
        case ActionType.GET_ROLES:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, roles:[], isLoadingRole: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoadingRole: false,
                    roles: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingRole: false,
                    error: action.error,
                }
            }
            break
        case ActionType.CREATE_ROLE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state,
                    isSaving: true,
                    role: undefined,
                    endpoints: []
                }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    role: action.data,
                    roles: state.roles?.concat(action.data),
                    endpoints: action.data.endpoints ? action.data.endpoints : [],
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break
        case ActionType.UPDATE_ROLE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true, endpoints: [] }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    role: action.data,
                    roles: state.roles!.map((item) =>
                        item.casbin_key === action.data.casbin_key ? { ...action.data } : item,
                    ),
                    endpoints: action.data.endpoints ? action.data.endpoints : [],
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break
        case ActionType.DELETE_ROLE:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true, endpoints: [] }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    role: undefined,
                    roles: state.roles!.filter(
                        (item) => item.casbin_key !== action.request.casbin_key,
                    ),
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_ALL_ENDPOINTS:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isLoadingEPAll: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoadingEPAll: false,
                    allEndPoints: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingEPAll: false,
                    error: action.error,
                }
            }
            break

        case ActionType.GET_ENDPOINTS:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, endpoints: [], isLoadingEP: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoadingEP: false,
                    endpoints: action.data,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingEP: false,
                    error: action.error,
                }
            }
            break
        case ActionType.GET_ENDPOINT:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, endpoint: undefined, isLoadingEP: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    isLoadingEP: false,
                    endpoint: {...action.data, methods: ['GET', 'POST', 'PUT']},
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isLoadingEP: false,
                    error: action.error,
                }
            }
            break
        case ActionType.UPDATE_ENDPOINT:
            if (action.mode === AsyncActionMode.REQUEST) {
                return { ...state, isSaving: true }
            }
            if (action.mode === AsyncActionMode.RESPONSE) {
                return {
                    ...state,
                    role: action.data,
                    roles: state.roles!.map((item) =>
                        item.id === action.data.id ? { ...action.data } : item,
                    ),
                    endpoints: action.data.endpoints ? action.data.endpoints : [],
                    isSaving: false,
                }
            }
            if (action.mode === AsyncActionMode.ERROR) {
                return {
                    ...state,
                    isSaving: false,
                    error: action.error,
                }
            }
            break
    }
    return state
}
