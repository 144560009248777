import { ActionType, PricelistActions } from './actions'
import { AsyncActionMode } from 'common/models'
import { PricelistState } from './models'

const PRICELIST_INITIAL_STATE: PricelistState = {
	isLoading: false,
	isSaving: false,
	error: undefined,
	pricelist: undefined,
	pricelists: [],
	historyPricelists: [],
	isHistoryLoading: false
}

export default (state = PRICELIST_INITIAL_STATE, action: PricelistActions): PricelistState => {
	switch (action.type) {
		case ActionType.GET_PRICELIST_BY_CUSTOMER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					pricelists: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_HISTORY_PRICELIST_BY_CUSTOMER:
			if (action.mode === AsyncActionMode.REQUEST) {
				return {
					...state,
					historyPricelists: [],
					isHistoryLoading: true
				}
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isHistoryLoading: false,
					historyPricelists: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isHistoryLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_PRICELIST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					pricelist: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.GET_CURRENT_PRICELIST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					pricelist: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break

		case ActionType.CREATE_PRICELIST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					pricelists: state.pricelists?.concat(action.data),
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.GET_PRICELISTS:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isLoading: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isLoading: false,
					pricelists: action.data,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isLoading: false,
					error: action.error,
				}
			}
			break
		case ActionType.DELETE_PRICELIST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					pricelists: state.pricelists!.filter((pricelist) => pricelist.id !== action.request.id),
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break

		case ActionType.UPDATE_PRICELIST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				const newData = state.pricelists.map((pl) => {
					return pl.id === action.data.id ? action.data : pl
				})
				return {
					...state,
					pricelists: newData,
					isSaving: false,
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false,
					error: action.error,
				}
			}
			break
		case ActionType.ACTIVATE_PRICELIST:
			if (action.mode === AsyncActionMode.REQUEST) {
				return { ...state, isSaving: true }
			}
			if (action.mode === AsyncActionMode.RESPONSE) {
				return {
					...state,
					isSaving: false,
					pricelist: action.data,
					pricelists: state.pricelists!.map((pricelist) =>
						pricelist.id === action.data.id ? { ...action.data } : pricelist,
					),
				}
			}
			if (action.mode === AsyncActionMode.ERROR) {
				return {
					...state,
					isSaving: false
				}
			}
			break
		
	}
	return state
}
