import {GetServiceTypeRequestAction, GetServiceTypeResponseAction, GetServiceTypeErrorAction} from '.'
import {protectedApiClient} from 'helpers/api'
import {handleApiErrorWithNotification} from 'helpers/errorHandling'
import {NameItem} from "../../../common/models";


export default ( cb?: (isSuccess: boolean) => void) => {
	return (
		dispatch: (
			arg: GetServiceTypeRequestAction | GetServiceTypeResponseAction | GetServiceTypeErrorAction,
		) => void,
	) => {
		const request = new GetServiceTypeRequestAction()
		dispatch(request)

		protectedApiClient
			.get<NameItem[]>(`/server/service-type`)
			.then((response) => {
				dispatch(new GetServiceTypeResponseAction(request, response.data))
				cb && cb(true)
			})
			.catch((error) => {
				dispatch(new GetServiceTypeErrorAction(request, error))
				handleApiErrorWithNotification(error)
				cb && cb(false)
			})
	}
}
