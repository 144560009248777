import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Card, Modal, Table} from "antd";
import {CloudServerOutlined} from "@ant-design/icons/lib/icons";
import {ServerModel} from "./models";
import {PAGING} from "../../common/enums";
import TotalNum from "../../components/TotalNum/TotalNum";
import React from "react";


const EditServerPage = () => {
    const CONTROL_NAME = 'page_servers'
    const {t} = useTranslation()
    const dispatch = useDispatch()

    return (
            <Card title={<><CloudServerOutlined/> &nbsp; {t('serversPage.title')}</>}
                className='ServersPage'
            >Edit Server</Card>

    )
}

export default EditServerPage