import {SelectResourceRequestAction} from '.'
import {ResourceRowModel} from '../models'


export default (resource: ResourceRowModel, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (arg: SelectResourceRequestAction) => void,
    ) => {
        const request = new SelectResourceRequestAction(resource)
        dispatch(request)
    }
}
