import {
    DeleteDomainRequestAction,
    DeleteDomainResponseAction,
    DeleteDomainErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'


export default (
    domain_id: number,
    cb?: (isSuccess: boolean) => void
) => {
    return (
        dispatch: (
            arg:
                | DeleteDomainRequestAction
                | DeleteDomainResponseAction
                | DeleteDomainErrorAction
        ) => void
    ) => {
        const request = new DeleteDomainRequestAction(domain_id)
        dispatch(request)
        protectedApiClient
            .delete(`/domain/${domain_id}`)
            .then((response) => {
                dispatch(new DeleteDomainResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteDomainErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
