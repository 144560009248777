import {
    GetVpsServersResponseAction,
    GetVpsServersErrorAction,
    GetVpsServersRequestAction
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { HypervisorModel } from "../models";


export default (cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetVpsServersRequestAction | GetVpsServersResponseAction | GetVpsServersErrorAction,
        ) => void,
    ) => {
        const request = new GetVpsServersRequestAction()
        dispatch(request)

        protectedApiClient
            .get<HypervisorModel[]>(`/vpsadmin/container/vps`)
            .then((response) => {
                dispatch(new GetVpsServersResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetVpsServersErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
