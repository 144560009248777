import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../common/models";
import React, {useEffect, useRef, useState} from "react";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import useLogger from "../../../common/useLogger";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {AutoComplete, Modal, Card, Col, Row, Space, Spin, Table, Tag, message, Tooltip} from "antd";
import {GlobalOutlined, PlusCircleOutlined} from "@ant-design/icons/lib/icons";
import Button from "antd-button-color";
import {DomainModel} from "./models";
import {
    BankOutlined,
    DeleteTwoTone, EditTwoTone,
    ExclamationCircleOutlined,
    InfoCircleTwoTone, PlusCircleTwoTone
} from "@ant-design/icons";
import {ColumnsType} from "antd/lib/table/interface";
import getDomains from "./actions/getDomains";
import usePageSize from "../../../common/usePageSize";
import Pager from "../../../components/pagination/pager";
import { Link } from "react-router-dom";
import {stopPropagation} from "../../../common/fce";
import lookupDomain from "./actions/lookupDomain";
import NewDomainForm from "./NewDomainForm";
import deleteDomain from "./actions/deleteDomain";
import HistoryModal from "../../../components/History/HistoryModal";
import {hasDnsService} from "./common";
import tableCustomers from "../../login/actions/tableCustomers";
import {sort_label} from "../../../common/sorting";
import getDomain from "./actions/getDomain";
import DomainEditForm from "./DomainEditForm";


const { confirm } = Modal


const DomainsPage = () => {
    const CONTROL_NAME = 'page_domains'
    const {t} = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const {customers, customerNames} = useSelector((state: AppState) => state.auth.tables)
    const {isLoading, domains, pager, lookup_domain, isLoadingLookup} = useSelector((state: AppState) => state.dnsservice)
    const {dns_domains} = useSelector((state: AppState) => state.sidenav)

    const [dataSource, setDataSource] = useState<DomainModel[]>([])
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [qsFilter, setQsFilter] = useState<string>('')
    const [selectedDomain, setSelectedDomain] = useState<DomainModel>()

    const [searchDomainLookup, setSearchDomainLookup] = useState<string>('')
    const [searchDomain, setSearchDomain] = useState<string>('')
    const [searchCustomerId, setSearchCustomerId] = useState<number | undefined>(undefined)
    const [searchCustomer, setSearchCustomer] = useState<string>('')
    const [forceUpdate, setForceUpdate] = useState<number>(0)

    // options
    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: string }[]>([])
    const [domainOptions, setDomainOptions] = useState<{ label: string, value: string }[]>([])

    const [modalNewDomainVisible, setModalNewDomainVisible] = useState(false)
    const [modalEditDomainVisible, setModalEditDomainVisible] = useState(false)
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    const [isDomainViewer, setDomainViewer] = useState(false)
    const [isDomainCreator, setDomainCreator] = useState(false)
    const [isDomainEditor, setDomainEditor] = useState(false)
    const [isDomainDeleter, setDomainDeleter] = useState(false)



// get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    const logger = useLogger(appSetting, 'DomainPage')
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        // trigger ONCE
        const access = loggedUser.hasAccess('page_domains')
        setDomainViewer(access)
        setDomainCreator(loggedUser.hasAccess('page_domains_create_button'))
        setDomainEditor(loggedUser.hasAccess('page_domains_edit_button'))
        setDomainDeleter(loggedUser.hasAccess('page_domains_delete_button'))
        if (!access) {
            // failover 403
            history.replace('/')
        }

        loadOptions()
        if (!isLoading && domains.items.length === 0) {
            // fetch data
            fetchRecords(1, pageSize)
        }
    }, [])

    useEffect(() => {
        // trigger from Side menu clicked
        if (!isLoading && isDomainViewer) {
            // reload
            setPageNumber(1)
            fetchRecords(1, pageSize)
        }
    }, [dns_domains])

    useEffect(() => {
        // populate CustomerOptions
        if (customers && customers.length) {
            const data = customers.map(c => ({value: `${c.id}`, label: getCustomerName(c.id)}))
            setCustomerOptions(data.sort(sort_label))
        }
    }, [customers])

    useEffect(() => {
        // populate domainOptions
        setDomainOptions(lookup_domain.map(s => ({label: s, value: s})))
    }, [lookup_domain])
    
    useEffect(() => {
        // when filter is changed
        // show the first page
        logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
        fetchRecords(pageNumber, pageSize)
    }, [qsFilter])

    useEffect(() => {
        // update datasource when data was changed
        if (!customerNames) {
            logger(`customerNames:false`)
            return
        }
        if (domains.items && filterIsValid()) {
            const data = domains.items.map(item => {
                return {
                    ...item,
                    has_dns: hasDnsService(item),
                    customer_name: customerNames!.get(item.customer_id),
                    searchableName: item.name
                }
            })
            setDataSource(data)
        }
    }, [domains.items, customerNames])

    useEffect(() => {
        replaceHistory()
    }, [pageSize, pageNumber, qsFilter])

    useEffect(() => {
        fetchRecords(pageNumber, pageSize)
    }, [forceUpdate])

    useEffect(() => {
        // update QsFilter
        const qs: string = prepareQsFilter()
        logger(`qsFilter: ${qs}`)
        if (qs != qsFilter) {
            setQsFilter(qs)
        }
    }, [searchCustomerId, searchDomain])

    const loadOptions = () => {
        // load Options for refresh F5
        if (!customers || customers.length === 0) {
            dispatch(tableCustomers())
        }
    }

    const getCustomerName = (cid) => {
        if (customerNames) {
            let name = customerNames!.get(cid)
            return name ? name : `cust.${cid}`
        }
        return `err.${cid}`
    }

    const fetchRecords = (pn: number, ps: number) => {
        setPageNumber(pn)
        setPageSize(ps)
        if (!isLoading && filterIsValid()) {
            // purchase_from is required
            logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
            dispatch(getDomains(ps, pn - 1, qsFilter, suc => {}))
        }
    }

    const fetchDomainLookup = (searchText: string) => {
        // call lookup for domain
        searchText = searchText.trim()
        if (checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (checkMinSearch(searchText)) {
                qs.push('field=name')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupDomain: ' + qs.join("&"))
                dispatch(lookupDomain('domain', qs.join("&")))
            }
        }
    }

    const replaceHistory = () => {
        if (filterIsValid()) {
            logger('replaceHistory '+qsFilter)
            const url = `/dns/domains?pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}&` + qsFilter
            history.replace(url)
        }
    }

    const filterIsValid = (): boolean => {
        // no rules
        return true
    }

    const getQSFilter = (): string[] => {
        let qs: string[] = []
        if (searchCustomerId && searchCustomerId > 0) {
            qs.push('customer_id=' + searchCustomerId)
        }
        return qs
    }

    const prepareQsFilter = (): string => {
        // load filtered data from server
        let qs: string[]
        qs = getQSFilter()
        if (checkMinSearch(searchDomain)) {
            qs.push('name=' + encodeURIComponent(searchDomain))
        }
        logger('prepareQsFilter: '+qs.join("&"))
        return qs.join("&")
    }

    const checkMinSearch = (val: string | undefined) => {
        if (!val) {
            return false
        }
        return val.length > appSetting.min_search_length
    }

    const onClearCustomer = () => {
        setSearchCustomer('')
        setSearchCustomerId(undefined)
    }

    const onSelectCustomer = (v: string) => {
        const cid = parseInt(v)
        if (cid > 0) {
            logger('onSelectCustomer.. '+v)
            setSearchCustomerId(cid)
            const name = customerNames!.get(cid)
            name && setSearchCustomer(name)
        }
        else {
            setSearchCustomerId(undefined)
            v && setSearchCustomer(v)
        }
    }

    const onChangeCustomerLookup = (data: string) => {
        if (!data) {
            if (searchCustomer.length === 1) {
                setSearchCustomer('')
            }
            return
        }
        if (data != searchCustomer){
            setSearchCustomer(data)
        }
    }

    const onClearDomain = () => {
        setSearchDomainLookup('')
        setSearchDomain('')
        setDomainOptions([])
    }

    const onSelectDomain = (data: string) => {
        setSearchDomain(data)
        setPageNumber(1)
    }

    const onChangeDomainLookup = (data: string) => {
        if (!data) {
            if (searchDomainLookup.length === 1) {
                setSearchDomainLookup('')
                fetchDomainLookup('')
            }
            return
        }
        if (data != searchDomainLookup){
            setSearchDomainLookup(data)
            fetchDomainLookup(data)
        }
    }


    const showConfirmDelete = (d: DomainModel, title: string) => {
        if (!d) {
            return
        }

        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{d.name}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteDomain(d.id,suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const FilterByDomain = (
        <AutoComplete
            showSearch
            placeholder={t('domainPage.domain')}
            style={{ width: '200px' }}
            value={searchDomainLookup}
            options={domainOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectDomain(e.currentTarget.value)
                }
            }}
            onSelect={onSelectDomain}
            //onSearch={onChangeDomainLookup}
            onChange={onChangeDomainLookup}
            onClear={onClearDomain}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )


    const FilterByCustomer = (
        <AutoComplete
            showSearch
            placeholder={t('ipSubnetPage.customer')}
            style={{ width: '150px' }}
            value={searchCustomer}
            options={customerOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectCustomer(e.currentTarget.value)
                }
            }}
            onSelect={onSelectCustomer}
            //onSearch={onChangeCustomerLookup}
            onChange={onChangeCustomerLookup}
            onClear={onClearCustomer}
            onClick={stopPropagation}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )

    const columns: ColumnsType<DomainModel> = [
        {
            title: FilterByDomain,
            key: 'name',
            dataIndex: 'name',
            width: 260,
            fixed: 'left',
            ellipsis: {showTitle: false},
            render: (text, rec: DomainModel) => {
                if (!rec.has_dns) {
                    return text
                }
                else {
                    return <Link to={`/dns/domains/${text}/records`}>{text}</Link>
                }
            }
        },
        {
            title: t('domainPage.dns'),
            key: 'has_dns',
            dataIndex: 'has_dns',
            align: 'right',
            width: 160,
            render: (text, rec: DomainModel) => {
                if (!rec.has_dns) {
                    return <span className='text-disabled'>{t('domainPage.no_dns')}</span>
                } else {
                    return rec.services && rec.services.filter(ss => ss.type_id === 1).map(s => s.name.replace('.zcom.cz', '..'))
                }
            }
        },
        {
            title: FilterByCustomer,
            dataIndex: 'customer_name',
            key: 'customer_name',
            width: 180,
            ellipsis: {showTitle: false},
            render: name => (
                <Tooltip placement="topLeft" title={name}>
                    {name}
                </Tooltip>
            ),
        },
        {
            title: t('domainPage.services'),
            key: 'services',
            dataIndex: 'services',
            render: (text, rec: DomainModel) => {
                if (!rec.services) {
                    return ''
                } else {
                    return rec.services.filter(ss => ss.type_id > 1).map(s => <Tag key={s.service_id}>{s.name}</Tag>)
                }
            }
        },
        {
            title: t('billing.servicetype.action'),
            key: 'action',
            width: 110,
            align: 'center',
            dataIndex: 'action',
            render: (text: string, record: DomainModel) => (
                <Space size='small'>

                    <Button type='text' size='small'
                            disabled={!isDomainCreator}
                            onClick={() => {
                                setSelectedDomain(record)
                                dispatch(getDomain(record.name))
                                setModalEditDomainVisible(true)
                            }}
                            className='actionButton'
                            icon={<EditTwoTone twoToneColor={isDomainCreator ? "green" : "#ccc"} />}
                    />

                    <Button type='text' danger size='small'
                            disabled={!isDomainDeleter || record.has_dns}
                            className='actionButton'
                            title={record.has_dns ? 'Domain has DNS Zone.' : ''}
                            onClick={() => showConfirmDelete(record, t('domainPage.confirm_delete'))}
                            icon={<DeleteTwoTone twoToneColor={(isDomainDeleter && !record.has_dns) ? "red" : "#ccc"} />}
                    />

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(record.id)
                                setHistoryTitle(record.name)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                </Space>
            ),
        },
    ]

    return (
        <>
            <Card
                loading={isLoading}
                className='DomainPage'
                title={
                <>
                    <Row>
                        <Col span={12}><BankOutlined /> &nbsp;{t('domainPage.title')}</Col>
                        <Col span={12}></Col>
                    </Row>
                </>}
                extra={
                    <Button type='primary'
                            disabled={!isDomainCreator}
                            onClick={() => setModalNewDomainVisible(true)}>
                        <PlusCircleOutlined/> {t('domainPage.create_button')}
                    </Button>
                }
            >

                <Table<DomainModel>
                    rowClassName={() => 'highlight'}
                    bordered={true}
                    columns={columns}
                    loading={isLoading}
                    dataSource={dataSource}
                    rowKey={(record) => `${record.id}`}
                    showHeader={true}
                    pagination={false}
                    footer={() => Pager({
                        filename: 'fn',
                        total: pager.totalCount,
                        current: pager.page,
                        pageSize: pager.pageSize,
                        data: dataSource,
                        fetchRecords: fetchRecords
                    })}
                    onChange={(ev) => {
                        ev.pageSize && setPageSize(ev.pageSize)
                    }}
                />

            </Card>

            <Modal title={
                       <div style={{width: '100%', cursor: 'move'}}
                            onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                            onMouseOut={() => {setDisabled(true)}}
                            onFocus={() => {}}
                            onBlur={() => {}}
                       >
                           <GlobalOutlined/> &nbsp; <>{t('domainPage.create_title')}</>
                       </div>
                   }
                destroyOnClose
                style={{top: 20}}
                width={600}
                visible={modalNewDomainVisible}
                onCancel={() => setModalNewDomainVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                footer={null}
                confirmLoading={true}
            >
                <NewDomainForm closeModal={() => setModalNewDomainVisible(false)}/>
            </Modal>

            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                     onMouseOut={() => {setDisabled(true)}}
                     onFocus={() => {}}
                     onBlur={() => {}}
                >
                    <GlobalOutlined/> &nbsp; <>{t('domainPage.domain')}: {selectedDomain?.name}</>
                </div>
            }
                   destroyOnClose
                   style={{top: 20}}
                   width={600}
                   visible={modalEditDomainVisible}
                   onCancel={() => setModalEditDomainVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   footer={null}
                   confirmLoading={true}
            >
                <DomainEditForm closeModal={(update) => {
                    setModalEditDomainVisible(false)
                    update && setForceUpdate(forceUpdate+1)
                }}/>
            </Modal>

            <HistoryModal service='core' model='Domain'
                          modelId={historyModelId}
                          title={historyTitle}
                          isModalVisible={isHistoryModalVisible}
                          setModalVisible={() => setHistoryModalVisible(false)}
                          modalRender={(modal) => (
                              <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                  <div ref={draggleRef}>{modal}</div>
                              </Draggable>
                          )}
            />
        </>
    )
}

export default DomainsPage