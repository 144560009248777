import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {CreateInvoiceAttachmentParams, InvoiceAttachmentDetails} from "../models";
import {
  CreateInvoiceAttachmentErrorAction,
  CreateInvoiceAttachmentRequestAction,
  CreateInvoiceAttachmentResponseAction
} from "./index";


export default (
  params: CreateInvoiceAttachmentParams,
  cb?: (id: number | undefined, isSuccess: boolean) => void,
  onProgress?: (ProgressEvent: any) => void,
) => {
  return (
    dispatch: (
      arg:
        | CreateInvoiceAttachmentRequestAction
        | CreateInvoiceAttachmentResponseAction
        | CreateInvoiceAttachmentErrorAction,
    ) => void,
  ) => {
    const request = new CreateInvoiceAttachmentRequestAction(params)
    dispatch(request)
    protectedApiClient
      .post<InvoiceAttachmentDetails>(
        `/billing/invoice/${params.invoice_id}/invoice-attachment`,
        params,
        { onUploadProgress: onProgress },
      )
      .then((response) => {
        dispatch(
          new CreateInvoiceAttachmentResponseAction(request, response.data),
        )
        cb && cb(response.data.id, true)
      })
      .catch((error) => {
        dispatch(new CreateInvoiceAttachmentErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(0, false)
      })
  }
}
