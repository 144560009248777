

export const recountPriceVAT = (form, appSetting, taxSource) => {
    let price_per_unit = form.getFieldValue('price_per_unit')
    if (price_per_unit) {
        price_per_unit = appSetting.readNumberFromForm(form.getFieldValue('price_per_unit'))
        let unit_count = appSetting.readNumberFromForm(form.getFieldValue('unit_count'))
        let tax_id = form.getFieldValue('tax_id')
        let tax = taxSource.find(t => t.id === tax_id)
        if (unit_count && tax) {
            let total = price_per_unit * unit_count
            let total_with_vat = total + (total * tax.rate / 10000)
            form.setFieldsValue({'price_per_unit': (Math.round(price_per_unit * 100) / 100).toFixed(2)})
            form.setFieldsValue({'total_with_vat': (Math.round(total_with_vat * 100) / 100).toFixed(2)})
        }
    }
}

export const recountPriceWithoutVAT = (form, appSetting, taxSource) => {
    let total_with_vat = form.getFieldValue('total_with_vat')
    if (total_with_vat) {
        total_with_vat = appSetting.readNumberFromForm(form.getFieldValue('total_with_vat'))
        let unit_count = appSetting.readNumberFromForm(form.getFieldValue('unit_count'))
        let tax_id = form.getFieldValue('tax_id')
        let tax = taxSource.find(t => t.id === tax_id)
        if (unit_count && tax) {
            let single_price = total_with_vat / unit_count
            let price_per_unit = single_price / (1 + tax.rate/10000)
            form.setFieldsValue({'price_per_unit': (Math.round(price_per_unit*100)/100).toFixed(2)})
            form.setFieldsValue({'total_with_vat': (Math.round(total_with_vat*100)/100).toFixed(2)})
        }
    }
}