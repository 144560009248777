import './App.scss'

import {
    PlainRoute,
    // PlainRoute,
    PrivateRoute,
    PublicRoute,
} from './components/routeComponents'
import LoginPage from './pages/login/LoginPage'
import React, { useEffect, useState } from 'react'
import { Switch, useHistory } from 'react-router'
import PasswordResetPage from './pages/password/passwordReset/PasswordResetPage'
import PasswordRequestPage from './pages/password/passwordRequest/PasswordRequestPage'
import ChangePasswordPage from './pages/user/PasswordChangeForm'
import Logout from 'pages/login/LogoutPage'
import CreateUserPage from 'pages/user/CreateUserPage'
import UsersPage from 'pages/user/UsersPage'
import CustomerPage from 'pages/billing/customer/CustomerPage'
import SubnetsPage from 'pages/ip/subnet/SubnetsPage'
import TaxesPage from 'pages/billing/tax/TaxesPage'
import ProductsPage from 'pages/billing/product/ProductsPage'
import BillingPricelistPage from 'pages/billing/pricelist/PricelistPage'
import CustomerServicePage from 'pages/billing/customerservice/CustomerServicePage'
import ProductPricePage from 'pages/billing/productprice/ProductPricePage'
import InvoicePage from 'pages/billing/invoice/InvoicePage'
import CreateInvoicePage from 'pages/billing/invoice/CreateInvoicePage'
import { useTranslation } from 'react-i18next'
import enUS from 'antd/lib/locale/en_US'
import csCZ from 'antd/lib/locale/cs_CZ'
import { ConfigProvider } from 'antd'
import EditInvoicePage from 'pages/billing/invoice/EditInvoicePage'
import CreateBillPage from 'pages/billing/bill/CreateBillPage'
import UpdateBillPage from 'pages/billing/bill/UpdateBillPage'
import ServersPage from 'pages/server/ServersPage'
import InventoryPage from 'pages/inventory/inventory/InventoryPage'
import PhysicalServersPage from 'pages/inventory/physicalserver/PhysicalServersPage'
import DashboardPage from 'pages/dashboard/DashboardPage'
import DatacenterPage from 'pages/datacenter/datacenter/DatacenterPage'
import RackPage from 'pages/datacenter/rack/RackPage'
import BlockPage from 'pages/datacenter/block/BlockPage'
import AsyncJobGroupPage from 'pages/asyncjob/group/AsyncJobGroupPage'
import AsyncJobPage from 'pages/asyncjob/job/AsyncJobPage'
import CertificatesPage from 'pages/certificate/CertificatesPage'
import VhostsPage from 'pages/webservice/vhost/VhostsPage'
import TemplatesPage from 'pages/webservice/template/TemplatesPage'
import StatusPage from './pages/status/StatusPage'
import ServiceTypePage from './pages/billing/servicetype/ServiceTypePage'
import SettingsPage from './pages/settings/SettingsPage'
import MailPage from './pages/billing/mail/MailPage'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from './common/models'
import MailTemplatePage from "./pages/billing/mailtemplate/MailTemplatePage"
import moment from "moment"
import {_getRoles} from 'common/fce'
import logUrl from "./pages/login/actions/logUrl"
import UserDetailPage from "./pages/user/UserDetailPage"
import HypervisorsPage from "./pages/vpsadmin/HypervisorsPage"
import VpsSettingsPage from "./pages/vpsadmin/VpsSettingsPage"
import VpsContainersPage from "./pages/vpsadmin/VpsContainersPage"
import CountriesPage from 'pages/billing/countries/CountriesPage'
import CostAllocationCategoryPage from 'pages/billing/costallocation/CostAllocationCategoryPage'
import HypervisorDetailPage from 'pages/vpsadmin/HypervisorDetailPage'
import InventoryLocationPage from "./pages/inventory/location/InventoryLocationPage";
import InventoryTypePage from "./pages/inventory/type/InventoryTypePage";
import VpsDetailPage from 'pages/vpsadmin/VpsDetailPage';
import LogPage from "./pages/logviewer/LogPage";
import RolesPage from "./pages/rights/roles/RolesPage";
import ResourcePage from "./pages/rights/resources/ResourcePage";
import GroupsPage from "./pages/rights/usergroups/GroupPage";
import UserRightsPage from "./pages/rights/users/UserRightsPage";
import {MINIMAL_ROLE} from "./common/LoggedUser";
import EditServerPage from "./pages/server/EditServerPage";
import DatabasesPage from "./pages/database/Databases";
import {getLanguage} from "./helpers/langHelpers";
import LoadingPage from "./pages/login/LoadingPage";
import BillsPage from "pages/billing/bill/BillsPage";
import CompaniesPage from "./pages/company/CompaniesPage";
import IpAddressesPage from "./pages/ip/subnet/IpAddressesPage";
import DomainsPage from "./pages/dns/service/DomainsPage";
import DnsServicePage from "./pages/dns/service/DnsServicePage";
import ZonesPage from "./pages/dns/service/ZonesPage";
import DnsRecordsPage from "./pages/dns/service/DnsRecordsPage";
import ZoneRecordsPage from "./pages/dns/service/ZoneRecordsPage";



const App = () => {
    const { i18n } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const { menu_roles } = useSelector((state: AppState) => state.auth)
    const [domainName, setDomainName] = useState<string>('localhost')
    moment.locale(i18n.language, {
        week: {
            dow: 1 // Date offset = week starts on Monday
        }
    })

    useEffect(() => {
        // this is fix for hot reload error: https://github.com/facebook/create-react-app/issues/11771
        // window.process = { ...window.process, }
        if (window.location.hostname === 'localhost') {
            setDomainName('localhost')
        } else if (window.location.hostname === 'zistest.zcom.cz') {
            setDomainName('stage')
        } else if (window.location.hostname === 'zisdev.zcom.cz') {
            setDomainName('devel')
        } else {
            setDomainName('')
        }
    }, [])

    useEffect(() => {

        return history.listen((location) => {
            // log history of urls
            const url = location.pathname.toLowerCase()
            const skippedList = ['/login', '/logout', '/status', '/2fa', '/password-request', '/password-reset']
            if (!skippedList.includes(url)) {
                dispatch(logUrl(url))
                //const history = useHistory()
            }
        })

    }, [history])

    const getRoles = (ctrlName: string): string[] => {
        // returns roles needed for displaying a control element
        if (menu_roles) {
            const roles = _getRoles(ctrlName, menu_roles)
            if (roles && roles.length) {
                return roles
            }
        }
        return [MINIMAL_ROLE]
    }

    return (
        <div className='App'>
            <div className={`App_inner ${domainName}`}>
                <ConfigProvider locale={getLanguage() === 'en' ? enUS : csCZ}>
                    <Switch>
                        <PublicRoute path='/login' component={LoginPage} />
                        <PublicRoute path='/password-request' component={PasswordRequestPage} />
                        <PublicRoute path='/user/password-reset' component={PasswordResetPage} />
                        <PrivateRoute
                            exact
                            path='/'
                            neededRoles={getRoles('left_menu_dashboard')}
                            component={DashboardPage}
                        />
                        <PrivateRoute
                            path='/loading'
                            neededRoles={getRoles('left_menu_dashboard')}
                            component={LoadingPage}
                        />
                        <PrivateRoute
                            path='/dashboard'
                            neededRoles={getRoles('left_menu_dashboard')}
                            component={DashboardPage}
                        />

                        <PrivateRoute path='/logout' component={Logout} />
                        <PrivateRoute path='/change-password' component={ChangePasswordPage} />
                        <PrivateRoute path='/users' exact component={UsersPage} neededRoles={getRoles('left_menu_users')} />
                        <PrivateRoute path='/users/create-user' component={CreateUserPage} neededRoles={getRoles('left_menu_users')} />
                        <PrivateRoute path='/users/:id' component={UserDetailPage} neededRoles={getRoles('page_user_detail')} />
                        <PrivateRoute path='/rights/user' exact component={UserRightsPage} neededRoles={getRoles('left_menu_rights_users')} />
                        <PrivateRoute path='/rights/role' exact component={RolesPage} neededRoles={getRoles('left_menu_rights_roles')} />
                        <PrivateRoute path='/rights/role/:role_key?' component={RolesPage} neededRoles={getRoles('left_menu_rights_roles')} />
                        <PrivateRoute path='/rights/resource-group' exact component={ResourcePage} neededRoles={getRoles('left_menu_rights_resources')} />
                        <PrivateRoute path='/rights/resource-group/:group_id?' component={ResourcePage} neededRoles={getRoles('left_menu_rights_resources')} />
                        <PrivateRoute path='/rights/user-group' exact component={GroupsPage} neededRoles={getRoles('left_menu_rights_usergroups')} />
                        <PrivateRoute path='/rights/user-group/:group_id?' component={GroupsPage} neededRoles={getRoles('left_menu_rights_usergroups')} />
                        <PrivateRoute path='/ip/subnets' component={SubnetsPage} neededRoles={getRoles('left_menu_ip_subnet')} />
                        <PrivateRoute path='/ip/subnet/:id' component={IpAddressesPage} neededRoles={getRoles('left_menu_ip_subnet')} />

                        {/*<PrivateRoute path='/billing' exact neededRoles={[]} component={TaxesPage} />*/}
                        <PrivateRoute
                            path='/billing/customer'
                            exact
                            neededRoles={getRoles('left_menu_billing_customer')}
                            component={CustomerPage}
                        />
                        <PrivateRoute
                            path='/billing/companies'
                            neededRoles={getRoles('left_menu_billing_company')}
                            component={CompaniesPage}
                        />
                        <PrivateRoute path='/billing/dim/taxes'
                            neededRoles={getRoles('left_menu_billing_tax')}
                            component={TaxesPage} />
                        <PrivateRoute path='/billing/dim/mail-templates'
                            neededRoles={getRoles('left_menu_billing_mail_template')}
                            component={MailTemplatePage} />
                        <PrivateRoute path='/billing/dim/servicetypes'
                            neededRoles={getRoles('left_menu_billing_service_type')}
                            component={ServiceTypePage} />
                        <PrivateRoute path='/billing/dim/products'
                            neededRoles={getRoles('left_menu_billing_product')}
                            component={ProductsPage} />
                        <PrivateRoute path='/billing/dim/countries'
                            neededRoles={getRoles('left_menu_billing_country')}
                            component={CountriesPage} />
                        <PrivateRoute path='/billing/dim/cost-allocation-category'
                            neededRoles={getRoles('left_menu_billing-cost-allocation-category')}
                            component={CostAllocationCategoryPage} />

                        <PrivateRoute path='/billing/pricelist'
                            neededRoles={getRoles('left_menu_billing_pricelist')}
                            component={BillingPricelistPage} />
                        <PrivateRoute path='/billing/pricelist-products/:id'
                            neededRoles={getRoles('left_menu_billing')}
                            component={ProductPricePage} />
                        <PrivateRoute path='/billing/customer-services' exact
                            neededRoles={getRoles('left_menu_billing')}
                            component={CustomerServicePage} />
                        <PrivateRoute
                            path='/billing/customer-services/:customer_id'
                            neededRoles={getRoles('left_menu_billing')}
                            component={CustomerServicePage}
                        />
                        <PrivateRoute path='/billing/invoice' exact
                            neededRoles={getRoles('left_menu_billing')}
                            component={InvoicePage} />
                        <PrivateRoute path='/billing/invoice/create'
                            neededRoles={getRoles('left_menu_billing')}
                            component={CreateInvoicePage} />
                        <PrivateRoute path='/billing/invoice/edit/:id'
                            neededRoles={getRoles('left_menu_billing')}
                            component={EditInvoicePage} />
                        <PrivateRoute path='/billing/bill' exact
                                      neededRoles={getRoles('page_billing_bill')}
                                      component={BillsPage} />
                        <PrivateRoute path='/billing/bill/create'
                            neededRoles={getRoles('left_menu_billing')}
                            component={CreateBillPage} />
                        <PrivateRoute path='/billing/bill/edit/:id'
                            neededRoles={getRoles('left_menu_billing')}
                            component={UpdateBillPage} />
                        <PrivateRoute path='/billing/mail' exact
                            neededRoles={getRoles('page_billing_mails')}
                            component={MailPage} />
                        <PrivateRoute path='/servers' exact
                            neededRoles={getRoles('page_servers')}
                            component={ServersPage} />
                        <PrivateRoute path='/servers/edit/:id'
                                      neededRoles={getRoles('page_servers')}
                                      component={EditServerPage} />

                        <PrivateRoute path='/inventory' exact
                            neededRoles={getRoles('left_menu_inventory')}
                            component={InventoryPage} />

                        <PrivateRoute path='/inventory/physical-servers'
                            neededRoles={getRoles('left_menu_inventory_physical')}
                            component={PhysicalServersPage} />
                        <PrivateRoute path='/inventory/dim/locations'
                            neededRoles={getRoles('left_menu_inventory_locations')}
                            component={InventoryLocationPage} />
                        <PrivateRoute path='/inventory/dim/types'
                            neededRoles={getRoles('left_menu_inventory_types')}
                            component={InventoryTypePage} />
                        <PrivateRoute path='/inventory/:inventory_id' exact
                                      neededRoles={getRoles('left_menu_inventory')}
                                      component={InventoryPage} />

                        <PrivateRoute path='/datacenter' exact
                            neededRoles={getRoles('left_menu_datacenter')}
                            component={DatacenterPage} />
                        <PrivateRoute path='/datacenter/rack'
                            neededRoles={getRoles('left_menu_datacenter_rack')}
                            component={RackPage} />
                        <PrivateRoute path='/datacenter/block'
                            neededRoles={getRoles('left_menu_datacenter_block')}
                            component={BlockPage} />
                        <PrivateRoute path='/vpsadmin/hypervisors'
                            neededRoles={getRoles('left_menu_vpsadmin_hypervisor')}
                            component={HypervisorsPage} />
                        <PrivateRoute path='/vpsadmin/setting'
                            neededRoles={getRoles('left_menu_vpsadmin_setting')}
                            component={VpsSettingsPage} />
                        <PrivateRoute
                            path='/vpsadmin/vps/:vps_id'
                            neededRoles={getRoles('vpsadmin_vps_detail')}
                            component={VpsDetailPage}
                        />

                        <PrivateRoute path='/vpsadmin/vps'
                            neededRoles={getRoles('left_menu_vpsadmin_vps')}
                            component={VpsContainersPage} />
                        <PrivateRoute
                            path='/vpsadmin/hypervisor/:hypervisor_id'
                            neededRoles={getRoles('vpsadmin_hypervisor_detail')}
                            component={HypervisorDetailPage}
                        />

                        <PrivateRoute path='/billing/countries'
                            neededRoles={getRoles('left_menu_billing_country')}
                            component={CountriesPage} />

                        <PrivateRoute path='/logviewer' exact neededRoles={getRoles('page_logviewer')} component={LogPage} />
                        <PrivateRoute path='/dns/domains' exact neededRoles={getRoles('page_domains')} component={DomainsPage} />
                        <PrivateRoute path='/dns/domains/:domain_url/records' exact neededRoles={getRoles('page_domain_dns_records')} component={DnsRecordsPage} />
                        <PrivateRoute path='/dns/services' exact neededRoles={getRoles('page_dns_servers')} component={DnsServicePage} />
                        <PrivateRoute path='/dns/services/:service_id' exact neededRoles={getRoles('page_dns_zones')} component={ZonesPage} />
                        <PrivateRoute path='/dns/:service_url/zones/:domain_url/records' exact neededRoles={getRoles('page_zone_dns_records')} component={ZoneRecordsPage} />
                        <PrivateRoute path='/asyncjob/group' exact neededRoles={[]} component={AsyncJobGroupPage} />
                        <PrivateRoute path='/asyncjob/job' exact neededRoles={[]} component={AsyncJobPage} />
                        <PrivateRoute path='/certificates' exact neededRoles={[]} component={CertificatesPage} />
                        <PrivateRoute path='/databases' exact neededRoles={[]} component={DatabasesPage} />
                        <PrivateRoute path='/webservices/vhosts' exact neededRoles={[]} component={VhostsPage} />
                        <PrivateRoute path='/webservices/templates' exact neededRoles={[]} component={TemplatesPage} />
                        <PublicRoute exact path='/status' neededRoles={[]} component={StatusPage} />
                        <PrivateRoute exact path='/settings' neededRoles={getRoles('page_app_settings')} component={SettingsPage} />

                        <PublicRoute path='*' component={LoginPage} />

                    </Switch>
                </ConfigProvider>
            </div>
        </div>
    )
}

export default App

export const breadcrumbNameMap = {
    '/': 'Dashboard',
    '/servers': 'Servers',
    '/domains': 'Domains',
    '/login': 'Login',
    '/logout': 'Logout',
    '/reset-password': 'Reset password',
    '/change-password': 'Change password',
    '/password-request': 'Password request',
    '/companies': 'Companies',
    '/profile': 'Profile',
    '/users': 'Users',
    '/users/create-user': 'Create user',
    '/users/update': 'Update user',
    '/user-group': 'User Group',
    '/user-group/create': 'Create User Group',
    '/user-group/edit': 'Update User Group',
    '/user-group/remove': 'Remove User Group',
    '/rights/user': 'UserRights',
    '/rights/group': 'Groups',
    '/rights/role': 'Roles',
    '/rights/resource': 'Resources',
    '/ip/subnets': 'IP subnets',
    '/customer/edit': 'Edit customer',
    '/role-group': 'Role groups',
    '/billing': 'Billing',
    '/billing/companies': 'Companies',
    '/billing/customer': 'Customers',
    '/billing/customer/create': 'Create customer',
    '/billing/taxes': 'Taxes',
    '/billing/servicetypes': 'Service Types',
    '/billing/products': 'Products',
    '/billing/pricelist': 'Pricelists',
    '/billing/pricelist-products': 'Pricelist Products',
    '/billing/customer-services': 'Customer Services',
    '/billing/invoice': 'Invoices',
    '/billing/invoice/create': 'Create invoice',
    '/billing/invoice/edit': 'Edit invoice',
    '/billing/bill': 'Incoming document',
    '/billing/bill/create': 'Enter incoming document',
    '/billing/bill/edit': 'Edit incoming document',
    '/billing/mail': 'Mails',
    '/billing/mail-templates': 'Mail Templates',
    '/billing/cost-allocation-category': 'Cost Allocation Category',
    '/inventory': 'Inventory',
    '/inventory/physicalservers': 'Physical servers',
    '/datacenter': 'Datacenter',
    '/datacenter/rack': 'Rack',
    '/datacenter/block': 'Rack Block',
    '/dns': 'DNS ',
    '/dns/service': 'Services',
    '/dns/zone': 'Zones',
    '/asyncjob': 'Async Job',
    '/asyncjob/group': 'Job Groups',
    '/asyncjob/job': 'Jobs',
    '/certificates': 'Certificates',
    '/vpsadmin': 'VPS Admin',
    '/webservices': 'Web services',
    '/webservices/vhosts': 'Vhosts',
    '/webservices/templates': 'Templates',
    '/status': 'Status',
    '/logviewer': 'Log Viewer',
    '/settings': 'Settings'
}
