import {
    FlagUpRequestAction,
    FlagUpResponseAction,
    FlagUpErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { CustomerDetails } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | FlagUpRequestAction
                | FlagUpResponseAction
                | FlagUpErrorAction,
        ) => void,
    ) => {
        const request = new FlagUpRequestAction(id)
        dispatch(request)

        protectedApiClient
            .put<CustomerDetails>(`/customer/${id}/flag-up`)
            .then((response) => {
                dispatch(new FlagUpResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new FlagUpErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
