import React, {useEffect, useState} from 'react'
import {Card, Col, Input, InputNumber, Row, Select, Skeleton, Space, Table} from 'antd'
import {SaveOutlined, DeleteTwoTone} from '@ant-design/icons/lib/icons'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import Button from 'antd-button-color'
import {sum} from 'helpers/arrayHelpers'
import {message} from 'antd'
import './BillCostAllocationCtrl.scss'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {AppState} from "../../../common/models";
import {CheckCircleOutlined, ExceptionOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import getBillCostAllocationByBill from "./actions/getBillCostAllocationByBill";
import createBillCostAllocation from "./actions/createBillCostAllocation";
import deleteBillCostAllocation from "./actions/deleteBillCostAllocation";
import getCostAllocationCategories from "./actions/getCostAllocationCategories";
import {CostAllocationCategoryDetails} from "../costallocation/models";
import {BillCostAllocationModel} from "./models";
import {_parsePrice, decEn} from "../../../common/fce";


interface Props {
    billId: number
    title: string
}

const disableRest = (bill, total): number => {
    // disable ADD button
    if (!bill.total_without_vat) {
        return 1
    }
    const diff = bill.total_without_vat - total
    if (diff > 0) {
        return 1
    }
    if (diff === 0) {
        return 0
    }
    if (diff < 0) {
        return -1
    }
    return 1
}

const BillCostAllocationCtrl = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {billId, title} = props

    const {bill, costAllocations, costAllocationCategories, vatSummaries, isVatSummaryLoading, error} = useSelector((state: AppState) => state.bill)

    const [dataSource, setDataSource] = useState<BillCostAllocationModel[]>([])
    const [isCostAllocationCategoryLoading, setCostAllocationCategoryLoading] = useState<boolean>(false)
    const [isBillCostAllocationLoading, setBillCostAllocationLoading] = useState<boolean>(false)
    const [rest, setRest] = useState<string>('0')
    const [restNum, setRestNum] = useState<number>()
    const [total, setTotal] = useState<number>(0)
    const [categoryId, setCategoryId] = useState<number>(1)
    const [note, setNote] = useState<string>('')
    const [categoriesOptions, setCategoriesOptions] = useState<{ label: string, value: number, key: number }[]>([])

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <Card title={props.title} className='billAllocationCard'>
                <Skeleton/>
            </Card>
        )
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('BillAllocationPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('BillAllocationPage: ' + msg)} }

    useEffect(() => {
        if (bill && bill.id === billId) {
            dispatch(getCostAllocationCategories(1))
            !costAllocations && dispatch(getBillCostAllocationByBill(bill.id))
        }
    }, [])

    useEffect(() => {
        if (bill && bill.id === billId) {
            setDataSource(costAllocations)
            const t = sum(costAllocations, 'price')
            const v = sum(vatSummaries, 'total_without_vat')
            const r = v - t
            setTotal(t)
            setRest(appSetting.renderPrice(r))
        }
    }, [costAllocations.length, vatSummaries, isVatSummaryLoading])

    useEffect(() => {
        setRestNum(appSetting.parsePrice(rest)/100)
    }, [rest])

    useEffect(() => {
        if (costAllocationCategories && costAllocationCategories.length) {
            setCategoryId(Math.min(...costAllocationCategories.map((c: CostAllocationCategoryDetails) => c.id!)))
            setCategoriesOptions(costAllocationCategories.map(c => {return {value: c.id!, key: c.id!, label: c.name}}))
        }
    }, [costAllocationCategories.length])

    const handleDelete = (id: number) => {
        dispatch(deleteBillCostAllocation(id, suc => {
            if (suc) {
                message.success(t('billing.bill-cost-allocation.deleted'))
                setNote('')
            }
            else {
                message.error(error)
            }
        }))
    };

    const handleAdd = () => {
        if (!restNum || !bill || !bill.total_without_vat) {
            return
        }
        const p: number = restNum * 100
        const oldRest = bill.total_without_vat - total
        if (oldRest < (p)) {
            message.error(t('billing.bill-cost-allocation.not_save'))
            return
        }
        const newData: BillCostAllocationModel = {
            bill_id: bill.id,
            cost_allocation_category_id: categoryId,
            price: p,
            note: note
        };

        dispatch(
            createBillCostAllocation(newData, suc => {
                if (suc) {
                    message.success(t('billing.bill-cost-allocation.created'))
                    setNote('')
                }
                else {
                    message.error(error)
                }
            }))
    };

    const columns = [
        {
            title: t('billing.bill-cost-allocation.title.category'),
            dataIndex: 'cost_allocation_category_id',
            key: 'cost_allocation_category_id',
            width: '20%',
            render: (id) => costAllocationCategories?.find((c) => c.id === id)?.name,
        },
        {
            title: t('billing.bill-cost-allocation.title.price') + ` [${bill?.currency}]`,
            className: 'right',
            dataIndex: 'price',
            key: 'price',
            width: '20%',
            render: (price: number) => appSetting.renderPrice(price),
        },
        {
            title: t('billing.bill-cost-allocation.title.note'),
            dataIndex: 'note',
            key: 'note',
            width: '50%',
        },
        {
            title: '',
            dataIndex: 'operation',
            key: 'operation',
            width: '10%',
            render: (id, rec) =>
                dataSource.length >= 1 ? (
                    <Button title={t('billing.bill-cost-allocation.delete_btn')}
                            type='text' danger
                            size='small'
                            onClick={() => handleDelete(rec.id)}
                            icon={<DeleteTwoTone twoToneColor='red'/>}
                    />
                ) : null,
        },
    ];

    const handleFormatter = v => {
        let value = appSetting.renderPrice(_parsePrice(v))
        setRest(decEn(value))
    }

    if (isBillCostAllocationLoading || isCostAllocationCategoryLoading) {
        return (
            <Card title={props.title} className='billAllocationCard'>
                <Skeleton/>
            </Card>
        )
    }

    return (
        <Card title={props.title} className='billAllocationCard'>
            <Table
                rowKey="id"
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                pagination={false}
                columns={columns}
            />

            {
                bill && bill.total_without_vat > 0 && (
                    <>
                        {
                            (disableRest(bill, total) === 0) &&
                            <Space className='done'>
                                {t('billing.bill-cost-allocation.done')}<CheckCircleOutlined/>
                            </Space>
                        }
                        {
                            (disableRest(bill, total) < 0) &&
                            <Space className='error'>
                                {t('errors.error')}<ExclamationCircleOutlined />
                            </Space>
                        }
                        {
                            (disableRest(bill, total) > 0) &&
                                <Row style={{marginTop: '24px'}}>
                                    <Col style={{width: '20%'}}>
                                        <Select
                                            placeholder={t('billing.bill-cost-allocation.allocate-to')}
                                            className='costAllocationSelect'
                                            style={{width: '100%', marginLeft: '4px'}}
                                            defaultValue={categoryId}
                                            onChange={(v) => {setCategoryId(v)}}
                                            options={categoriesOptions}
                                            value={categoryId}
                                        />
                                    </Col>
                                    <Col style={{width: '28%'}}>
                                        <InputNumber
                                            precision={2}
                                            className='inputPriceField'
                                            decimalSeparator=','
                                            addonAfter={bill?.currency}
                                            value={restNum}
                                            onChange={(v) => {setRestNum(appSetting.parsePrice(v!)/100)}}
                                            onBlur={(e) => {(e) => handleFormatter(e.target.value)}} />
                                    </Col>
                                    <Col style={{width: '40%'}}>
                                        <Input type='text'
                                               value={note}
                                               placeholder={t('billing.bill-cost-allocation.title.note')}
                                               onChange={(e) => {setNote(e.target.value)}}
                                        />
                                    </Col>
                                    <Col style={{width: '10%'}}>
                                        <Button type="success"
                                                onClick={handleAdd}
                                                disabled={!loggedUser.hasAccess('page_billing_bill_edit_button')}
                                                style={{marginBottom: 16}}>
                                            {t('billing.bill-cost-allocation.add_btn')}
                                        </Button>
                                    </Col>
                                </Row>
                        }
                    </>
                )
            }


    </Card>
)
}

export default BillCostAllocationCtrl
