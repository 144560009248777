import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import {UserDetails} from "./models";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../components";
import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Radio, Row, Spin, Tag} from "antd";
import {CustomerDetails} from "../billing/customer/models";
import {isIdentVerified} from "./UserDetailPage";
import {_renderPhone} from "../../common/fce";
import {ROLE_USER_MANAGER} from "../../common/LoggedUser";

interface Props {
    editProfile: (userId: number) => void
    openModalVerifySms: (ident: string, isOpen:boolean) => void
    openModalVerifyEmail: (ident: string, isOpen:boolean) => void
}

const PanelProfile = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {self} = useSelector((state: AppState) => state.auth)
    const {companies, customers} = useSelector((state : AppState) => state.auth.tables)
    const {user, verifications} = useSelector((state: AppState) => state.user)

    const [customer, setCustomer] = useState<CustomerDetails>()

    const [verifyPhone, setVerifyPhone] = useState<string | undefined>()
    const [verifyEmail, setVerifyEmail] = useState<string | undefined>()
    const [isViewer, setViewer] = useState(false)
    const [isEditor, setEditor] = useState(false)


    // get settings and logged user from store
    let loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator/>
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()
    // logger
    const logger = (msg, obj: any = null) => {
        if (appSetting && appSetting.debug) {
            obj ? console.log('PanelProfile: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('PanelProfile: ' + msg)
        }
    }

    useEffect(() => {
        // trigger ONCE
        setViewer(isMe() || loggedUser!.hasAccess('page_users'))
        setEditor(loggedUser!.hasAccess('page_users_edit_button'))

    }, [])

    useEffect(() => {
        if (user) {
            setCustomer(customers.find(c => c.id === user.customer_id))
        }
    }, [user])

    const isMe = () => {
        if (user && self) {
            return user.id === self.id
        }
        return false
    }

    const isPhoneVerified = (phone: string | undefined): boolean => {
        if (!phone) {
            return false
        }
        return isIdentVerified('phone', phone, verifications)
    }

    const isEmailVerified = (email: string | undefined): boolean => {
        if (!email) {
            return false
        }
        return isIdentVerified('email', email, verifications)
    }

    const renderEmailVerification = (email: string | undefined) => {
        if (isEmailVerified(email)) {
            return (<Tag color='success'>{t('usersPage.email_verified')}</Tag>)
        }
        return (<Tag color='error'>{t('usersPage.email_not_verified')}</Tag>)
    }

    const renderPhoneVerification = (phone?: string, isDisabled: boolean = false) => {
        if (isPhoneVerified(phone)) {
            if (isDisabled) {
                return (<Tag color='default' style={{color: '#bcbcbc'}}>{t('usersPage.phone_verified')}</Tag>)
            }
            return (<Tag color='success'>{t('usersPage.phone_verified')}</Tag>)
        }
        if (isDisabled) {
            return (<Tag color='default' style={{color: '#bcbcbc'}}>{t('usersPage.phone_not_verified')}</Tag>)
        }
        return (<Tag color='error'>{t('usersPage.phone_not_verified')}</Tag>)
    }

    const renderButton = (text: string, self?: UserDetails, user?: UserDetails) => {
        if (self && user) {
            if (isMe() || isEditor) {
                // user is loaded
                // open ProfileForm
                return (
                    <Button type='link'
                            size='small'
                            onClick={() => {
                                props.editProfile(user.id)
                            }}>{text}
                    </Button>
                )
            }
        }
        return (<span className='text-disabled'>{text}</span>)
    }

    const getCompanyName = (customer_id: number) => {
        const cust = customers.find(c => c.id === customer_id)
        if (cust) {
            if (cust.company) {
                return cust.company.name
            }
            const comp = companies.find(c => c.id === cust.company_id)
            if (comp) {
                return comp.name
            }
        }
        if (self!.id === user!.id) {
            // customers and companies are empty (only role user_base)
            return loggedUser?.company!.name
        }
        return 'error'
    }

    if (!user) {
        return (
            <Card title='Profile' className='fblock'
                  extra={renderButton(t('general.set'), self, user)}
                  style={{height: '420px'}}>
                <Row gutter={[0,0]}>
                    <Col span={24}>
                        <div className="loading">
                            <span style={{margin: '20px'}}>loading..</span> <Spin />
                        </div>
                    </Col>
                </Row>
            </Card>
        )
    }

    return (
        <Card title={t('usersPage.menu_profile')} className='fblock'
              extra={renderButton(t('general.edit'), self, user)}
              style={{height: '460px', marginTop: 0}}>

            <Row gutter={[0,0]}>
                <Col span={8} className='labelCol'><span className='label'>{t('usersPage.id')}:</span></Col>
                <Col span={16} className='valueCol'><span className="value">{user.id}</span></Col>
                <Col span={8} className='labelCol' style={{paddingTop: '16px'}}><span className='label'>{t('usersPage.name')}:</span></Col>
                <Col span={16} className='valueCol'><span className="value" style={{fontSize: '1.6em'}}>{user.title}</span></Col>
                <Col span={8} className='labelCol'><span className='label'>{t('usersPage.username')}:</span></Col>
                <Col span={16} className='valueCol'><span className="value">{user.username}</span></Col>
                <Col span={8} className='labelCol'><span className='label'>{t('usersPage.ident')}:</span></Col>
                <Col span={16} className='valueCol'><span className="value">{getCompanyName(user.customer_id)}</span></Col>
                <Col span={8} className='labelCol'><span className='label'>{t('usersPage.created')}:</span></Col>
                <Col span={16} className='valueCol'><span className="value">{appSetting.renderDate(user.created_at)}</span></Col>
                <Col span={8} className='labelCol'><span className='label'>{t('dashboardPage.last_seen')}:</span></Col>
                <Col span={16} className='valueCol'><span className="value">{appSetting.renderDate(user.last_seen_at)}</span></Col>

                <Col span={8} className='labelCol'><span className='label'>{t('usersPage.email')}:</span></Col>
                <Col span={16} className='valueCol'>
                    <span className="value">{user.email}</span><br/>
                    { user.email && renderEmailVerification(user.email)}
                    {
                        user.email && !isEmailVerified(user.email) && (
                            <Button type='primary'
                                    className='login-form-button'
                                    disabled={!isMe()}
                                    onClick={() => {
                                        setVerifyEmail(user.email)
                                        props.openModalVerifyEmail(user.email, true)
                                    }}
                                    size='small'>
                                {t('Force2FA.verify')}
                            </Button>
                        )
                    }
                </Col>

                <Col span={8} className='labelCol'><span className='label'>{t('usersPage.phone')}:</span></Col>
                <Col span={16} className='valueCol'>
                    <span className="value">{_renderPhone(user.phone)}</span><br/>
                    {user.phone && renderPhoneVerification(user.phone)}
                    {
                        user.phone && !isPhoneVerified(user.phone) && (
                            <Button type='primary'
                                    className='login-form-button'
                                    disabled={!isMe()}
                                    onClick={() => {
                                        // phone is valid
                                        setVerifyPhone(user.phone)
                                        if (user.phone && user.phone.length>3) {
                                            props.openModalVerifySms(user.phone, true)
                                        }
                                    }}
                                    size='small'>
                                {t('Force2FA.verify')}
                            </Button>
                        )
                    }
                </Col>

                <Col span={8} className='labelCol'><span className='label'>{t('usersPage.note')}:</span></Col>
                <Col span={16} className='valueCol'><i>{user.note}</i></Col>
            </Row>


        </Card>
    )
}

export default PanelProfile