import {LogUrlErrorAction, LogUrlRequestAction, LogUrlResponseAction} from "./index";

export default (url: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | LogUrlRequestAction
                | LogUrlResponseAction
                | LogUrlErrorAction
        ) => void,
    ) => {
        const request1 = new LogUrlRequestAction()
        dispatch(new LogUrlResponseAction(request1, url))
    }
}