import {IpSubnetModel} from "./models";


export const isPrivateIP_old = (item: IpSubnetModel) => {
    // on the server
    if (item.cidr.startsWith('10.0.0.')) {
        return true
    }
    if (item.cidr.startsWith('172.16.')) {
        return true
    }
    if (item.cidr.startsWith('172.32.')) {
        return true
    }
    if (item.cidr.startsWith('192.168.')) {
        return true
    }
    return false
}

export const isSubnetExternal = (subnetId: number): boolean => {
    return subnetId === 1 || subnetId === 2
}

export const compIPV6 = (input)  =>{
    return input.replace(/\b(?:0+:){2,}/, ':');
}