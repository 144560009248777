import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {CustomerDetails, CustomerTerminatedParams} from '../models'
import {SetTerminatedErrorAction, SetTerminatedRequestAction, SetTerminatedResponseAction} from "./index";

export default (params: CustomerTerminatedParams, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | SetTerminatedRequestAction
                | SetTerminatedResponseAction
                | SetTerminatedErrorAction,
        ) => void,
    ) => {
        const request = new SetTerminatedRequestAction(params)
        dispatch(request)

        protectedApiClient
            .put<CustomerDetails>(`/customer/${params.id}/terminate`, params)
            .then((response) => {
                dispatch(new SetTerminatedResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new SetTerminatedErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
