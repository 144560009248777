import {useTranslation} from "react-i18next";
import {useForm} from "antd/lib/form/Form";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import moment, {Moment} from "moment";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {Button, Col, Form, Input, InputNumber, message, Row, Select, Spin, Tag} from "antd";
import {AppState} from "../../../common/models";
import {TaxDetails} from "../tax/models";
import {_parsePrice, _parseCount} from "../../../common/fce";
import getTaxes from "../tax/actions/getTaxes";
import {CompanyDetails} from "../../company/models";
import {CustomerDetails} from "../customer/models";
import getInvoiceItem from "./actions/getInvoiceItem";
import updateInvoiceItem from "./actions/updateInvoiceItem";
import createInvoiceItem from "./actions/createInvoiceItem";
import getInvoice from "./actions/getInvoice";
import {sort_num, sort_rate} from "../../../common/sorting";
import {recountPriceVAT, recountPriceWithoutVAT} from "./common";


interface Props {
    invoiceItemId: number
    hideInvoiceItemModal: () => void
    selectedCompany: CompanyDetails
    selectedCustomer: CustomerDetails
    updateHistory: () => void
}

interface InvoiceItemForm {
    id: number
    name: string
    invoice_id: number
    price_per_unit: string
    total_with_vat: string
    tax_id: number
    calculation_type: string
    unit: string
    unit_count: string
}

const initItem = {
    id: 0,
    name: '',
    invoice_id: 0,
    price_per_unit: '0',
    total_with_vat: '0',
    tax_id: 0,
    unit: '',
    unit_count: '1',
    calculation_type: 'unit'
}

const InvoiceItemForm = (props: Props) => {
    const {t} = useTranslation()
    let [form] = useForm()
    const dispatch = useDispatch()

    const {invoiceItemId, hideInvoiceItemModal, selectedCompany, selectedCustomer} = props
    const {taxes} = useSelector((state: AppState) => state.tax)
    const {invoice, invoiceItem} = useSelector((state: AppState) => state.invoice)
    const [dataToUpdate, setDataToUpdate] = useState<InvoiceItemForm>()
    const [taxSource, setTaxSource] = useState<TaxDetails[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('InvoiceItemForm: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('InvoiceItemForm: ' + msg)} }


    useEffect(() => {
        if(invoiceItemId) {
            setLoading(true)
            dispatch(getInvoiceItem(invoiceItemId, suc => setLoading(false)))
        }
        else {
            // insert
            if(invoice && invoice.id && taxes && taxes.length) {
                const data = getCurrentTaxes()
                setTaxSource(data)
                const t = data.pop()
                if (t && invoiceItemId === 0) {
                    setDataToUpdate({...initItem, tax_id: t.id, invoice_id: invoice.id})
                }
            }
        }
    }, [])

    useEffect(() => {
        if (invoiceItem && invoiceItem.id === invoiceItemId) {
            const data = {
                id: invoiceItem.id,
                name: invoiceItem.name,
                invoice_id: invoiceItemId,
                price_per_unit: appSetting.renderPrice(invoiceItem.price_per_unit),
                total_with_vat: appSetting.renderPrice(invoiceItem.total_with_vat),
                tax_id: invoiceItem.tax_id,
                unit: invoiceItem.unit ? invoiceItem.unit : '',
                unit_count: appSetting.renderCount(invoiceItem.unit_count),
                calculation_type: invoiceItem.calculation_type
            }
            setDataToUpdate(data)
        }
    }, [invoiceItem])


    useEffect(() => {
        if(taxes && taxes.length) {
            setTaxSource(getCurrentTaxes())
        }
        else {
            dispatch(getTaxes())
        }
    }, [dispatch, taxes])

    const getCurrentTaxes = () => {
        return taxes
            .filter( t => t.country.toLowerCase().includes('cz') )
            .filter(t2 => moment().isBetween(t2.valid_from, t2.valid_to ? t2.valid_to : moment(), undefined, '[]'))
            .sort((a, b) => sort_num(a.rate, b.rate))
    }

    const onFinish = (values: any) => {
        logger('invoiceItemId: '+invoiceItemId)
        logger('onFinish: '+JSON.stringify(values))
        if (invoiceItemId > 0) {
            // EDIT
            if (!invoiceItem || !invoiceItem.id) {
                console.error('missing invoiceItem.id')
                return
            }
            invoiceItem.name = values['name']
            if (invoiceItem.calculation_type === 'range') {
                invoiceItem.price_per_unit = 0
            }
            else {
                invoiceItem.price_per_unit = _parsePrice(values['price_per_unit'])
            }
            invoiceItem.total_with_vat = _parsePrice(values['total_with_vat'])
            invoiceItem.total_without_vat = 0   // zero will be counted on the server
            invoiceItem.total_vat = 0
            invoiceItem.unit = values['unit']
            if (invoiceItem.calculation_type === 'fix') {
                invoiceItem.unit_count = 1000
            }
            else {
                invoiceItem.unit_count = _parseCount(values['unit_count'])
            }
            invoiceItem.tax_id = values['tax_id']
            setIsSaving(true)
            if (invoiceItem.id) {
                const invoiceId = invoiceItem.invoice_id
                dispatch(updateInvoiceItem(invoiceItem, suc => {
                    setIsSaving(false)
                    if (suc) {
                        props.updateHistory()
                        message.success(t('billing.invoice.invoice-item-updated'))
                        dispatch(getInvoice(invoiceId, suc => {hideInvoiceItemModal()}))
                    }
                }))
            }
        } else {
            // INSERT
            if (invoice && dataToUpdate) {
                dataToUpdate.name = values['name']
                dataToUpdate.invoice_id = invoice.id
                dataToUpdate.unit = values['unit']
                dataToUpdate.tax_id = values['tax_id']
                setIsSaving(true)
                dispatch(createInvoiceItem({
                        name: values['name'],
                        calculation_type: 'unit',
                        invoice_id: invoice.id,
                        unit: values['unit'],
                        tax_id: values['tax_id'],
                        price_per_unit: _parsePrice(values['price_per_unit']),
                        total_with_vat: _parsePrice(values['total_with_vat']),
                        unit_count: _parseCount(values['unit_count'])
                    },
                        suc => {
                            setIsSaving(false)
                            if (suc) {
                                props.updateHistory()
                                message.success(t('billing.invoice.invoice-item-created'))
                                dispatch(getInvoice(invoice.id, suc => {hideInvoiceItemModal()}))
                            }
                })
                )
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        logger('Failed:', errorInfo);
    };


    if (!dataToUpdate || loading || !invoice || (!invoiceItem && invoiceItemId>0)) {
        return <Spin/>
    }

    return (
        <>
        <Row style={{marginBottom: '24px'}}>
            <Col span={8} className='right'>{t('billing.productprice.calculation_type')}:&nbsp;</Col>
            <Col span={16}>
                &nbsp;
                <Tag color={dataToUpdate.calculation_type === "fix" ? "red" : dataToUpdate.calculation_type === "unit" ? "blue" : dataToUpdate.calculation_type === "range" ? "green" : "blue"} >
                    {dataToUpdate.calculation_type.toUpperCase()}
                </Tag>
            </Col>
        </Row>

        <Form name="editInvoiceItem"
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={dataToUpdate}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
        >
            <Form.Item name="name" label={t('billing.invoice.item.name')}
                rules={[{required: true, message: t('errors.field_required')}]}
            >
                <Input />
            </Form.Item>

            {
                dataToUpdate.calculation_type === 'fix' && (
                    <Input name="unit_count" type='hidden' />
                )
            }

            {
                dataToUpdate.calculation_type != 'fix' && (
                    <Form.Item name="unit_count" label={t('billing.invoice.item.unit_count')}
                               rules={[
                                   {required: true, message: t('errors.field_required')},
                                   {
                                       message: 'invalid number',
                                       validator: (_, value) => {
                                           if (_parseCount(value) > 0) {
                                               return Promise.resolve();
                                           } else {
                                               return Promise.reject('not a number');
                                           }
                                       }
                                   }
                               ]}
                    >
                        <Input onChange={() => recountPriceVAT(form, appSetting, taxSource)}
                               style={{width: '150px', textAlign: 'right'}} />
                    </Form.Item>
                )
            }


            <Form.Item name="unit" label={t('billing.invoice.item.unit')} >
                <Input style={{width: '150px', textAlign: 'right'}}  />
            </Form.Item>

            {
                dataToUpdate.calculation_type != "range" && (
                    <Form.Item name="price_per_unit" label={t('billing.invoice.item.price_per_unit')}
                        rules={[{required: true, message: t('errors.field_required')}]}
                    >
                        <InputNumber precision={2}
                                     className='inputPerUnit'
                                     style={{width: '150px', textAlign: 'right'}}
                                     decimalSeparator=','
                                     onFocus={(event) => event.target.select()}
                                     onKeyPress={(e) => {if (e.key === 'Enter') {recountPriceVAT(form, appSetting, taxSource)}}}
                                     onBlur={() => recountPriceVAT(form, appSetting, taxSource)}
                                     addonAfter={invoice.currency}
                        />
                    </Form.Item>
                )
            }

            <Form.Item name='tax_id' label={t('billing.invoice.tax')}
                rules={[{required: true, message: t('errors.field_required')}]} >
                <Select size='middle' style={{width: '150px'}}
                    optionFilterProp='label'
                    onChange={() => recountPriceVAT(form, appSetting, taxSource)}
                    options={
                        taxSource.map(t => ({
                            label: `${t.country} ${t.rate / 100} %`,
                            value: t.id,
                            id: `${t.rate / 100} %`
                        }))}
                />
            </Form.Item>

            <Form.Item name='total_with_vat'
                  rules={[{required: true}]}
                  label={t('billing.invoice.price_vat')}>
                <InputNumber precision={2}
                             className='inputPerUnit'
                             style={{width: '150px', textAlign: 'right'}}
                             decimalSeparator=','
                             onFocus={(event) => event.target.select()}
                             onKeyPress={(e) => {if (e.key === 'Enter') {recountPriceWithoutVAT(form, appSetting, taxSource)}}}
                             onBlur={() => recountPriceWithoutVAT(form, appSetting, taxSource)}
                             addonAfter={invoice.currency}
                />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary"
                        onClick={() => {
                            form && form.submit()
                        }}
                        loading={isSaving}
                        >
                    {t('billing.invoice.item.save')}
                </Button>
            </Form.Item>
        </Form>

        </>
    )
}

export default InvoiceItemForm