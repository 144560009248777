import React, {useEffect, useRef, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {
	Card,
	Table,
	Space,
	message,
	Modal,
	Col,
	Row,
	Switch,
	Spin,
	AutoComplete
} from 'antd'
import {
	PercentageOutlined,
	PlusCircleOutlined,
	CheckOutlined, CloseOutlined
} from '@ant-design/icons/lib/icons'
import {AppState} from 'common/models'
import {useTranslation} from 'react-i18next'
import {PricelistDetails} from './models'
import getPricelists from './actions/getPricelists'
import PricelistForm from './PricelistForm'
import deletePricelist from './actions/deletePricelist'
import Button from 'antd-button-color'
import './PricelistPage.scss'
import {
	DeleteTwoTone,
	EditTwoTone,
	EuroOutlined, ExclamationCircleOutlined,
	InfoCircleTwoTone,
} from '@ant-design/icons'
import {ColumnsType} from 'antd/lib/table'
import TotalNum from "../../../components/TotalNum/TotalNum";
import {PAGING} from "../../../common/enums";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import {_isNotEmptyObject, removeDiac, stopPropagation, ZCOM_CUSTOMER_ID} from "../../../common/fce";
import queryString, {ParsedQuery} from "query-string";
import {useHistory} from "react-router";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import usePageSize from "../../../common/usePageSize";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {sort_date, sort_desc, sort_str} from "../../../common/sorting";
import HistoryModal from "../../../components/History/HistoryModal";

const { confirm } = Modal

// sorting
const sort_customer_name = (a, b) => {
	const ZCOM_ID = 1;
	if( a.customer_id === ZCOM_ID && b.customer_id > ZCOM_ID) {
		return -1
	}
	if( a.customer_id > ZCOM_ID && b.customer_id === ZCOM_ID) {
		return 1
	}
	return sort_str(a.customer_name, b.customer_name)
}


const PricelistsPage = () => {
	const CONTROL_NAME = 'page_billing_pricelist'
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const {search} = useLocation()

	const {pricelists, isLoading, isSaving} = useSelector((state: AppState) => state.pricelist)
	const {customers} = useSelector((state: AppState) => state.auth.tables)
	const {billing_pricelists} = useSelector((state: AppState) => state.sidenav)

	// filters
	const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
	const [pageSize, setPageSize] = useState<string>()
	const [searchCustomerName, setSearchCustomerName] = useState('')
	const [dataSource, setDataSource] = useState<PricelistDetails[]>()
	const [searchablePricelists, setSearchablePricelists] = useState<PricelistDetails[]>()
	const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
	const [historyTitle, setHistoryTitle] = useState('')
	const [historyModelId, setHistoryModelId] = useState<number | undefined>()
	const [isModalVisible, setModalVisible] = useState(false)
	const [dataToUpdate, setDataToUpdate] = useState<PricelistDetails>()
	const [checked, setChecked] = useState<boolean>(true)
	const [activeChecked, setActiveChecked] = useState<boolean>(true)
	const [priceListCSV, setPriceListCSV] = useState<PricelistDetails[]>()
	const [openDelete, setOpenDelete] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)

	const [isViewer, setViewer] = useState(false)
	const [isCreator, setCreator] = useState(false)
	const [isEditor, setEditor] = useState(false)
	const [isDeleter, setDeleter] = useState(false)

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator/>
			</div>
		)
	}

	// required authorization
	if (!loggedUser.hasAccess(CONTROL_NAME)) {
		return <ErrorPage403 />
	}

	// settings
	const appSetting = loggedUser.getAppSettings()
	const SEARCH_MIN = appSetting.min_search_length

	// logger
	const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('BillingPricelistPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('BillingPricelistPage: ' + msg)} }
	usePageSize(appSetting, loggedUser.user.id, pageSize)

	// history drag modal
	const draggleRef = useRef<HTMLDivElement>(null)
	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggleRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	}

	let triggerOneTimer = 0

	useEffect(() => {
		triggerOneTimer = Date.now().valueOf()
		const viewer = loggedUser.hasAccess('page_billing_pricelist')
		setViewer(viewer)
		setCreator(loggedUser.hasAccess('page_billing_pricelist_create_button'))
		setEditor(loggedUser.hasAccess('page_billing_pricelist_edit_button'))
		setDeleter(loggedUser.hasAccess('page_billing_pricelist_delete_button'))
		if (!viewer) {
			// go to dashboard
			history.replace('/')
		}
		dispatch(getPricelists())
	}, [])

	useEffect(() => {
		// load products from API
		// trigger ONCE
		logger('getPricelists: '+pricelists.length)
		if (pricelists && pricelists.length > 0 && customers && customers.length) {
			populateSearchablePricelists()
			// apply QS filters
			handleQueryStringFilters()
		}

	}, [pricelists, customers])

	useEffect(() => {
		// trigger from Side menu clicked
		const when = triggerOneTimer + 1000
		if (when < Date.now().valueOf()) {
			// when triggerOneTimer was not called (after 1sec. of SideMenu click)
			if (!isLoading) {
				dispatch(getPricelists())
			}
		}
	}, [billing_pricelists])

	useEffect(() => {
		// update datasource when data was changed
		refreshGrid()
	}, [searchablePricelists, isModalVisible, checked, activeChecked, searchCustomerName])

	const populateSearchablePricelists = () => {
		logger('populateSearchablePricelists: '+pricelists.length)
		setSearchablePricelists(pricelists.map(p => {
			return {...p, customer_name: getCompanyName(p.customer_id)}
		}))
	}

	const getCompanyName = (cid: number): string => {
		if (cid === ZCOM_CUSTOMER_ID) {
			//return 'ZCOM'
		}
		const c = customers.find(c => c.id === cid)
		return c && c.company!.name ? c.company!.name : '?'+cid
	}

	const handleQueryStringFilters = () => {
		// /billing/pricelist?hidden=false&name=com&active=true
		if (parsed && _isNotEmptyObject(parsed)) {
			const qs_cname: string | string[] | null = parsed['cname']
			if (qs_cname && typeof qs_cname === 'string' && qs_cname.length > 1) {
				setSearchCustomerName(qs_cname)
			}
			const qs_hidden: string | string[] | null = parsed['hidden']
			if (qs_hidden && typeof qs_hidden === 'string') {
				setChecked(qs_hidden.toLowerCase() === 'true')
			}
			else {
				setChecked(false)
			}
			const qs_active: string | string[] | null = parsed['active']
			if (qs_active && typeof qs_active === 'string') {
				setActiveChecked(qs_active.toLowerCase() === 'true')
			}
			else {
				setActiveChecked(false)
			}
		}
	}

	const filtered = () => {
		// search for pattern
		logger('filtered: '+searchablePricelists?.length)
		if (!searchablePricelists || searchablePricelists.length === 0) {
			return []
		}

		let qs:string[] = []
		let data = searchablePricelists
		if (checked) {
			// use only Pricelists with products
			data = data.filter((pl) => pl.counter > 0)
			qs.push('hidden=true')
		}
		if (activeChecked) {
			// filter validity (only active pricelists)
			const dt = new Date().toISOString()
			const d = dt.split('T')[0]
			const now: number = Date.parse(d)
			const q1 = (t) => (!t.valid_to && (new Date(t.valid_from).getTime() <= now))
			const q2 = (t) => ( t.valid_to && (new Date(t.valid_from).getTime() <= now) && (new Date(t.valid_to).getTime() >= now))
			data = data.filter((t) => q1(t) || q2(t))
			qs.push('active=true')
		}

		if (searchCustomerName && searchCustomerName.length > SEARCH_MIN) {
			data = data.filter((pl) => removeDiac(pl.customer_name).includes(removeDiac(searchCustomerName)))
			qs.push('cname='+removeDiac(searchCustomerName))
		}
		history.replace('/billing/pricelist?' + qs.join("&"))
		return data.sort(sort_customer_name)
	}

	const refreshGrid = () => {
		logger('refreshGrid')
		setDataSource(filtered())
	}

	const showConfirmDelete = (b: PricelistDetails, title: string) => {
		if (!b) {
			return
		}
		// if (!isDeleter) {
		//     message.error(t('general.error403'))
		//     return
		// }
		confirm({
				icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
				title: title,
				content: <p>{b.customer_name}</p>,
				okText: t('general.yes'),
				cancelText: t('general.cancel'),
				okButtonProps: { loading: confirmDelete },
				className: 'confirm-alert',
				onOk() {
					setConfirmDelete(true)
					dispatch(deletePricelist(b.id,suc => {
						setConfirmDelete(false)
						setOpenDelete(false)
						if (suc) {
							message.success(t('general.success'))
						}
					}))
				},
				onCancel() {
					setOpenDelete(false)
				},
			}
		)
	}

	const FilterByCustomer = pricelists && (
		<AutoComplete
			placeholder={t('billing.pricelist.customer')}
			style={{width: 160}}
			allowClear={true}
			value={searchCustomerName}
			onClick={stopPropagation}
			onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
			onChange={(v) => {
				setSearchCustomerName(v)
			}}
		/>
	)

	const columnsPricelist: ColumnsType<PricelistDetails> = [
		{
			title: FilterByCustomer,
			dataIndex: 'customer_name',
			key: 'customer_name',
			width: 240,
			ellipsis: true,
			render: (text: number, rec) => (
				<Link to={`/billing/pricelist-products/${rec.id}`}>
					{text}
				</Link>
			),
			sorter: sort_customer_name
		},
		{
			title: t('billing.pricelist.currency'),
			dataIndex: 'currency',
			key: 'currency',
			sorter: (a:any, b:any) => sort_str(a.currency, b.currency)
		},
		{
			title: t('billing.pricelist.valid_from'),
			key: 'valid_from',
			dataIndex: 'valid_from',
			render: (text, record) => (
				<span>{appSetting?.renderDate(text)}</span>
			),
			sorter: (a:any, b:any) => sort_date(a.valid_from, b.valid_from)
		},
		{
			title: t('billing.pricelist.valid_to'),
			key: 'valid_to',
			dataIndex: 'valid_to',
			render: (text, record) => (
				<span>{appSetting?.renderDate(text)}</span>
			),
			sorter: (a:any, b:any) => sort_date(a.valid_to, b.valid_to)
		},
		{
			title: t('billing.pricelist.description'),
			dataIndex: 'description',
			key: 'description',
			ellipsis: true,
			sorter: sort_desc
		},
		{
			title: t('billing.pricelist.products'),
			dataIndex: 'counter',
			key: 'counter',
			align: 'center',
			sorter: (a:any, b:any) => a.counter - b.counter
		},
		{
			title: 'Action',
			key: 'action',
			width: '100px',
			dataIndex: 'action',
			render: (_, record: PricelistDetails) => (
				<Space size='small'>
					{
						isEditor && (
							<Button type='text'
									size='small'
									onClick={() => {
										setDataToUpdate(record)
										setModalVisible(true)
									}}
									className='actionButton'
									icon={<EditTwoTone twoToneColor='green' />}
							/>
						)
					}
					{
						isDeleter && (
								<Button type='text' danger size='small'
										className='actionButton'
						disabled={!!record.deleted_at || !!record.is_used}
										onClick={() => showConfirmDelete(record, t('billing.pricelist.confirm_delete'))}
										icon={<DeleteTwoTone twoToneColor='red'/>} />
						)
					}

					<Button title={t('general.btnHistory')} size='small'
							onClick={() => {
								setHistoryModelId(record.id)
								record.customer_name && setHistoryTitle(record.customer_name)
								setHistoryModalVisible(true)
							}}
							icon={<InfoCircleTwoTone />}
							className='actionButton'
					/>
				</Space>
			),
		},
	]

	if (appSetting && loggedUser) {
		return (
			<>
				<Card
					title={
						<>
							<Row>
								<Col span={6}>
									<EuroOutlined /> {t('billing.pricelist.title')}
								</Col>
								<Col span={18}>
									<Space style={{float: 'right', marginRight: '20px'}}>
										<div style={{
											float: "left",
											marginRight: "10px"
										}}>{t('billing.pricelist.hide_title')}</div>
										<Switch
											checkedChildren={<CheckOutlined/>}
											unCheckedChildren={<CloseOutlined/>}
											checked={checked}
											onChange={() => setChecked(!checked)}
										/>
										<div style={{width: '60px'}}>&nbsp;</div>

										{t('billing.pricelist.show_active')}
										<Switch
											checkedChildren={<CheckOutlined/>}
											unCheckedChildren={<CloseOutlined/>}
											checked={activeChecked}
											onChange={() => setActiveChecked(!activeChecked)}
										/>
									</Space>
								</Col>
							</Row>
						</>
					}
					extra={
						<Button type='primary'
								disabled={!isCreator}
								onClick={() => {
									setDataToUpdate(undefined)
									setModalVisible(true)
								}}>
							<PlusCircleOutlined/> {t('billing.pricelist.create')}
						</Button>
					}
					className='BillingPricelistPage'
					loading={false}>

						<Table<PricelistDetails>
							expandedRowKeys={[1, 2]}
							className='BillingPricelistTable'
							rowClassName={(record) => {
								if (record?.customer_id === 1)
									return 'highlight'
								else
									return ''
							}}
							bordered={true}
							columns={columnsPricelist}
							loading={isLoading}
							expandRowByClick={true}
							dataSource={dataSource}
							scroll={{x: 'max-content'}}
							rowKey={(record) => record.id}
							pagination={{
								defaultPageSize: appSetting.grid_page_size,
								pageSizeOptions: PAGING,
								showSizeChanger: true
							}}
							onChange={(ev) => {
								setPageSize(`${ev.pageSize}`)
							}}
							footer={() => TotalNum(Number(dataSource?.length), 'Price list', priceListCSV)}
						/>
				</Card>

				<Modal
					destroyOnClose
					style={{top: 20}}
					title={
						<>
							<PercentageOutlined/>{' '}
							{dataToUpdate ? t('billing.pricelist.update_title') : t('billing.pricelist.create_title')}
						</>
					}
					visible={isModalVisible}
					onCancel={() => setModalVisible(false)}
					maskClosable={false}
					footer={null}>
					<PricelistForm
						dataToUpdate={dataToUpdate}
						onClose={() => {setModalVisible(false)}}
					/>
				</Modal>

				<HistoryModal service='billing' model='Pricelist'
							  modelId={historyModelId}
							  title={historyTitle}
							  isModalVisible={isHistoryModalVisible}
							  setModalVisible={() => setHistoryModalVisible(false)}
							  modalRender={(modal) => (
								  <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
									  <div ref={draggleRef}>{modal}</div>
								  </Draggable>
							  )}
				/>
			</>
		)
	}
	else {
		return (
			<Spin/>
		)
	}
}

export default PricelistsPage
