import {
    GetArchivedDnsZoneRequestAction,
    GetArchivedDnsZoneResponseAction,
    GetArchivedDnsZoneErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiError } from 'helpers/errorHandling'
import { DnsZoneModel, GetZoneParams } from '../models'

export default (params: GetZoneParams, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetArchivedDnsZoneRequestAction
                | GetArchivedDnsZoneResponseAction
                | GetArchivedDnsZoneErrorAction
        ) => void
    ) => {
        const request = new GetArchivedDnsZoneRequestAction(params)
        dispatch(request)
        protectedApiClient
            .put<DnsZoneModel>(`/dns/zone/recover`, params)
            .then((response) => {
                dispatch(new GetArchivedDnsZoneResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                if (error.message.includes('status code 404')) {
                    // error resolved
                    dispatch(new GetArchivedDnsZoneResponseAction(request, undefined))
                    cb && cb(false)
                }
                else {
                    dispatch(new GetArchivedDnsZoneErrorAction(request, error))
                    handleApiError(error)
                    cb && cb(false)
                }
            })
    }
}
