import {
    DeleteDomainDnsZoneRequestAction,
    DeleteDomainDnsZoneResponseAction,
    DeleteDomainDnsZoneErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DeleteDnsZoneParams } from '../models'


export default (
    params: DeleteDnsZoneParams,
    cb?: (isSuccess: boolean) => void
) => {
    return (
        dispatch: (
            arg:
                | DeleteDomainDnsZoneRequestAction
                | DeleteDomainDnsZoneResponseAction
                | DeleteDomainDnsZoneErrorAction
        ) => void
    ) => {
        const request = new DeleteDomainDnsZoneRequestAction(params)
        dispatch(request)
        protectedApiClient
            .delete(`/domain/${params.id}/dns/zone?zone=${params.zone}`)
            .then((response) => {
                dispatch(new DeleteDomainDnsZoneResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new DeleteDomainDnsZoneErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
