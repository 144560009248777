import React, {useEffect, useRef, useState} from 'react'
import {AppState} from 'common/models'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import getMailTemplates from './actions/getMailTemplates'
import {AutoComplete, Card, Input, message, Space, Table, Modal} from "antd";
import {
    DeleteTwoTone,
    EditTwoTone,
    ExclamationCircleOutlined,
    InfoCircleTwoTone,
    MailOutlined
} from "@ant-design/icons";
import TotalNum from "../../../components/TotalNum/TotalNum";
import {MailTemplateDetails} from "./models";
import moment from "moment";
import {ColumnsType} from "antd/lib/table";
import Button from "antd-button-color";
import {PlusCircleOutlined} from "@ant-design/icons/lib/icons";
import deleteMailTemplate from "./actions/deleteMailTemplate";
import MailTemplateForm from "./MailTemplateForm";
import {PAGING} from "../../../common/enums";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import usePageSize from "../../../common/usePageSize";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {sort_sid} from "../../../common/sorting";
import {removeDiac, stopPropagation} from "../../../common/fce";
import HistoryModal from "../../../components/History/HistoryModal";

const { confirm } = Modal

const cleanup = (text: string) : string => {
    // cleanup content for grid
    let newText = text.replace(/<\/?[^>]+(>|$)/g, ""); // strip html
    newText = newText.substr(0, 50)
    newText = newText.replaceAll(/<br>/gi, ' ')
    newText = newText.replaceAll(/<div>/gi, ' ')
    newText = newText.replace(/\s/g, ' ')
    newText = newText.replace(/\\n/g, ' ')
    newText = newText.replace(/\\r/g, ' ')
    newText = newText + ' ...'
    return newText
}


const MailTemplatePage = () => {
    const CONTROL_NAME = 'page_billing_mail_template'
    const {t} = useTranslation()
    const dispatch = useDispatch()

    // data from store
    const {templates} = useSelector((state : AppState) => state.mailtemplate)
    const {isLoading} = useSelector((state: AppState) => state.mailtemplate)

    // init
    const [dataSource, setDataSource] = useState<MailTemplateDetails[]>([])
    const [isModalVisible, setModalVisible] = useState(false)
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [dataToUpdate, setDataToUpdate] = useState<MailTemplateDetails>()
    const [searchName, setSearchName] = useState('')
    const [pageSize, setPageSize] = useState<string>()
    const [openDelete, setOpenDelete] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    // permissions
    const [isEditor, setEditor] = useState(false)

// get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // logger
    const logger = (msg) => { if (appSetting && appSetting.debug) {console.log('MailTemplatePage: ' + msg)} }
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        // load templates from API
        // trigger ONCE
        if (loggedUser) {
            setEditor(loggedUser.hasAccess('page_billing_mail_template_editor'))
        }
        dispatch(getMailTemplates())
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }

    }, [templates, searchName, isModalVisible])     // trigger when templates changed

    const filtered = (name: string) => {
        let data = templates.filter((t) => t.deleted_at === 0)
        if (name && checkMinSearch(name)) {
            data = data.filter((t) => removeDiac(t.sid).includes(removeDiac(name)))
        }

        // search for pattern
        return data.sort(sort_sid)
    }

    const refreshGrid = () => {
        logger('refreshGrid')
        const pattern = searchName.replace(/\s/g, '')
        setDataSource(filtered(pattern))
    }

    const formCallback = (modalVisible: boolean, isSuccess: boolean) => {
        setModalVisible(modalVisible)  // force refreshGrid()
    }

    const showConfirmDelete = (b: MailTemplateDetails, title: string) => {
        if (!b) {
            return
        }
        // if (!isDeleter) {
        //     message.error(t('general.error403'))
        //     return
        // }
        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{b.subject}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteMailTemplate(b.id,suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const checkMinSearch = (val: string | undefined) => {
        if (!val) {
            return false
        }
        return val.length > SEARCH_MIN
    }

    const FilterByName = templates && (
        <AutoComplete
            placeholder={t('billing.mail_template.table.sid')}
            style={{width: 200}}
            value={searchName}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {setSearchName(e.currentTarget.innerText); e.stopPropagation()}}}
            allowClear={true}
            onChange={(v) => {
                setSearchName(v)
            }}
        />
    )

    const columns: ColumnsType<MailTemplateDetails> = [
        { title: FilterByName, dataIndex: 'sid', key: 'sid'},
        { title: t('billing.mail_template.table.subject'), dataIndex: 'subject', key: 'subject', render: text => cleanup(text) },
        { title: t('billing.mail_template.table.created_at'), dataIndex: 'created_at', key: 'created_at', render: text => moment.unix(text).format(appSetting?.date_format) },
        {
            title: t('billing.mail_template.table.action'),
            width: '100px',
            key: 'action',
            render: (_, record: MailTemplateDetails) => (
                <Space size='small'>
                    {
                        isEditor && (
                            <Button type='text'
                                    size='small'
                                    onClick={() => {
                                        setDataToUpdate(record)
                                        setModalVisible(true)
                                    }}
                                    className='actionButton'
                                    icon={<EditTwoTone twoToneColor='green' />}
                            />
                        )
                    }

                    {
                        isEditor && (
                                <Button type='text' danger size='small'
                                        className='actionButton'
                                        onClick={() => showConfirmDelete(record, t('billing.mail_template.confirm_delete'))}
                                        icon={<DeleteTwoTone twoToneColor='red'/>} />
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(record.id)
                                setHistoryTitle(record.sid)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                </Space>
            ),
        },
    ]

    return (
        <>
            <Card
                title={
                    <><MailOutlined /> &nbsp; {t('billing.mail_template.title')}</>
                }
                extra={
                    <Button
                        type='primary'
                        disabled={!isEditor}
                        onClick={() => {
                            setDataToUpdate(undefined)
                            setModalVisible(true)
                        }}>
                        <PlusCircleOutlined /> {t('billing.mail_template.create')}
                    </Button>
                }
            >
                {
                    appSetting &&
                    <Table
                        dataSource={dataSource}
                        rowKey='id'
                        loading={isLoading}
                        columns={columns}
                        bordered
                        pagination={{
                            defaultPageSize: appSetting?.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => {setPageSize(`${ev.pageSize}`)}}
                        footer={() => TotalNum(Number(dataSource?.length), t('billing.mail_template.title'), dataSource)}
                    />
                }
            </Card>

            <Modal
                destroyOnClose={true}
                width='50%'
                style={{top: 20}}
                title={
                    <>
                        <MailOutlined /> {dataToUpdate ? t('billing.mail_template.form.update_title') : t('billing.mail_template.form.create_title')}
                    </>
                }
                visible={isModalVisible}
                onCancel={() => setModalVisible(false)}
                maskClosable={false}
                footer={null}>
                <MailTemplateForm dataToUpdate={dataToUpdate} pageCallback={formCallback} />
            </Modal>

            <HistoryModal service='user' model='MailTemplate'
                          modelId={historyModelId}
                          title={historyTitle}
                          isModalVisible={isHistoryModalVisible}
                          setModalVisible={() => setHistoryModalVisible(false)}
                          modalRender={(modal) => (
                              <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                  <div ref={draggleRef}>{modal}</div>
                              </Draggable>
                          )}
            />
        </>
    )
}

export default MailTemplatePage
