import { ActionType, CompanyActions } from './actions'
import {AsyncActionMode, PAGE1, Pagination} from 'common/models'
import {CompanyState} from './models'


const COMPANY_INITIAL_STATE: CompanyState = {
  isLoading: false,
  isSaving: false,
  error: undefined,
  companies: {items: [], pagination: PAGE1},
  pager: PAGE1,
  lookup_number: [],
  lookup_company: [],
  lookup_country: [],
  isLoadingLookup: false
}

export default (
  state = COMPANY_INITIAL_STATE,
  action: CompanyActions,
): CompanyState => {

  switch (action.type) {
    case ActionType.GET_COMPANY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: false }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          company: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.CLEAR_COMPANY:
      return { ...state, isLoading: false, company: undefined }
    case ActionType.CREATE_COMPANY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          companies: {
            ...state.companies,
            items: state.companies.items.concat(action.data)
          },
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_COMPANIES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          companies: action.data,
          pager: {...action.data.pagination, page: action.data.pagination.page+1},
          isLoadingLookup: false
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_COMPANY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          companies: {
            ...state.companies,
            items: state.companies.items!.filter((c) => c.id !== action.request.id)
          },
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_COMPANY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          companies: {
            ...state.companies,
            items: state.companies.items!.map((c) =>
                c.id === action.data.id ? { ...action.data } : c,
            )
          },
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.LOOKUP_COMPANY:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          isLoadingLookup: true,
          lookup_number:[]
        }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        if (action.field === 'company_number') {
          return {
            ...state,
            lookup_number: action.data,
            lookup_company: [],
            isLoadingLookup: false
          }
        }
        if (action.field === 'name') {
          return {
            ...state,
            lookup_company: action.data,
            lookup_number: [],
            isLoadingLookup: false
          }
        }
        if (action.field === 'country') {
          return {
            ...state,
            lookup_country: action.data,
            isLoadingLookup: false
          }
        }
        console.error('Wrong action field.')
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isLoadingLookup: false
        }
      }
      break

  }
  return state
}
