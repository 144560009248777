import {protectedApiClient} from "../../../../helpers/api";
import {handleApiErrorWithNotification} from "../../../../helpers/errorHandling";
import {ResourceParams, ResGroupModel} from "../models";
import {UpdateResourceErrorAction, UpdateResourceRequestAction, UpdateResourceResponseAction} from "./index";


export default (
    params: ResourceParams,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | UpdateResourceRequestAction
                | UpdateResourceResponseAction
                | UpdateResourceErrorAction,
        ) => void,
    ) => {
        const request = new UpdateResourceRequestAction(params)
        dispatch(request)
        protectedApiClient
            .put<ResGroupModel>(
                `/resource-group/${params.res_group_id}/resource`,
                params,
            )
            .then((response) => {
                dispatch(
                    new UpdateResourceResponseAction(
                        request,
                        response.data,
                    ),
                )
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new UpdateResourceErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
