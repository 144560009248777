import {
    GetHypervisorByHostResponseAction,
    GetHypervisorByHostErrorAction,
    GetHypervisorByHostRequestAction
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { HypervisorModel } from "../models";


export default (host: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: GetHypervisorByHostRequestAction | GetHypervisorByHostResponseAction | GetHypervisorByHostErrorAction,
        ) => void,
    ) => {
        const request = new GetHypervisorByHostRequestAction(host)
        dispatch(request)

        protectedApiClient
            .get<HypervisorModel[]>(`/vpsadmin/container?type=2&host=${host}`)
            .then((response) => {
                dispatch(new GetHypervisorByHostResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetHypervisorByHostErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
