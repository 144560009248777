import {
  GetVpsSettingRequestAction,
  GetVpsSettingResponseAction,
  GetVpsSettingErrorAction
} from '.'

import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'

import { VpsSettingModel } from '../models'

export default (id: number, cb?: (isSuccess: boolean) => void) => {
  return (
    dispatch: (
      arg: GetVpsSettingRequestAction | GetVpsSettingResponseAction | GetVpsSettingErrorAction,
    ) => void,
  ) => {
    const request = new GetVpsSettingRequestAction(id)
    dispatch(request)

    protectedApiClient
      .get<VpsSettingModel>(`/vpsadmin/setting/${id}`)
      .then((response) => {
        console.log(response)
        dispatch(new GetVpsSettingResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new GetVpsSettingErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}

