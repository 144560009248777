import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, Row, Tag} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import requestVerificationCode from "./actions/requestVerificationCode";
import confirmVerificationCode from "./actions/confirmVerificationCode";
import {_parsePhone} from "../../common/fce";


interface Props {
    ident?: string
    setModalVisible: (param: boolean) => void
}


const VerifySMS = ({ident, setModalVisible}: Props) => {

    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const codeRef = React.useRef<any>(null)

    const {user, verification} = useSelector((state: AppState) => state.user)

    const [page1, setPage1] = useState<boolean>(true)
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [code, setCode] = useState<string>('')
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isSentSms, setIsSentSms] = useState<boolean>(false)
    const [isSentCode, setIsSentCode] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!ident) {
            setModalVisible(false)
        }
        console.log('VerifySMS: ident='+JSON.stringify(ident))
        setLoading(false)
        if (codeRef.current) {
            codeRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (codeRef.current) {
            codeRef.current.focus();
        }
    }, [page1]);

    useEffect(() => {
        if (user && verification && verification.user_id === user.id) {
            setIsSuccess(verification.is_verified === 1)
            setUpdateStatus(true)
        }
        else {
            setIsSuccess(false)
        }
    }, [verification])


    if (page1) {
        // page1
        return (
            <>
                <Row>
                    <Col span={24} style={{textAlign: 'center'}}>
                        {t('verifySms.phone_number')} <span style={{fontSize: '1.1em', fontWeight: 'bold', backgroundColor: '#ffff55'}}>{ident}</span> <br/>
                        {t('verifySms.we_send_code')} <br/>
                        {t('verifySms.you_enter_code')}
                    </Col>
                </Row>

                <Row style={{marginTop: '35px'}}>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <Button  type='primary' onClick={() => {
                            // send SMS
                            setPage1(false)
                            if ( user && ident) {
                                const params = {user_id: user.id, field: 'phone', value: _parsePhone(ident)}
                                dispatch(requestVerificationCode(params, suc => {
                                    setIsSentSms(true)
                                }))
                            }
                        }}>{t('verifySms.send_code')}</Button>
                    </Col>
                </Row>
            </>
        )
    }
    else {
        // page2
        return (
            <>
                <Row>
                    <Col span={24} style={{textAlign: 'center'}}>
                        {t('verifySms.phone_number')} <span style={{fontSize: '1.1em', fontWeight: 'bold'}}>{ident}</span> <br/> {t('verifySms.code_sent')}<br/>
                    </Col>
                </Row>
                <Row style={{marginTop: '35px'}}>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <span style={{fontSize: '1.1em'}}>{t('verifySms.enter_code')}</span><br/>
                        <Input type='text' ref={codeRef}
                               value={code}
                               onChange={e => {
                                   e.target.value ? setCode(e.target.value) : setCode('')
                               }}
                               style={{width: '100px', fontSize: '1.1em', fontWeight: 'bold', marginTop: '10px'}} />
                    </Col>
                </Row>
                <Row style={{marginTop: '35px'}}>
                    <Col span={24} style={{textAlign: 'center'}}>
                        {
                            isSentCode && isSuccess && (
                                <Tag color='success'>{t('verifySms.verified')}</Tag>
                            )
                        }
                        {
                            isSentCode && !isSuccess && (
                                <Tag color='error'>{t('verifySms.invalid_code')}</Tag>
                            )
                        }
                    </Col>
                </Row>
                <Row style={{marginTop: '35px'}}>
                    <Col span={8} style={{paddingTop: '2px'}}>
                        <Button type='link' size='small' onClick={() => setPage1(true)}>&lt; {t('general.back')}</Button>
                    </Col>
                    <Col span={8} style={{textAlign: 'center'}}>
                        <Button type='primary'
                                disabled={!verification}
                                onClick={() => {
                                    if (user && ident && verification) {
                                        const params = {
                                            id: verification.id,
                                            user_id: user.id,
                                            field: 'phone',
                                            value: _parsePhone(ident),
                                            confirm: code
                                        }
                                        dispatch(confirmVerificationCode(params, suc => {
                                            setIsSentCode(true)
                                            suc && setModalVisible(false)
                                        }))
                                    }
                                }}>{t('verifySms.confirm')}</Button>
                    </Col>
                    <Col span={8} style={{textAlign: 'center'}}></Col>
                </Row>
            </>
        )
    }
}

export default VerifySMS
