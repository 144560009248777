
import {LookupCompanyErrorAction, LookupCompanyRequestAction, LookupCompanyResponseAction} from "./index";
import {handleApiErrorWithNotification} from "../../../helpers/errorHandling";
import protectedApiClient from "../../../helpers/api/protectedApiClient";


export default (field: string, qs: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg: LookupCompanyRequestAction | LookupCompanyResponseAction | LookupCompanyErrorAction,
        ) => void,
    ) => {
        const request = new LookupCompanyRequestAction(field)
        dispatch(request)
        let url = `/company/company/lookup?`+qs
        protectedApiClient
            .get<string[]>(url)
            .then((response) => {
                dispatch(new LookupCompanyResponseAction(request, field, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new LookupCompanyErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
