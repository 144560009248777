import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../common/models";
import React, {useEffect, useState} from "react";
import {UserDetails} from "../user/models";
import getLastSeen from "../user/actions/getLastSeen";
import {Card, Col, Row} from "antd";



const LoadingPage = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        // when user logged => set permissions

    }, [])



    return (
        <>
            <Card className='DashboardPage'>
                <Row gutter={[8,8]} style={{overflow: 'none'}}>
                    <Col span={8}>
                    </Col>
                    <Col span={8}>
                        Loading..
                    </Col>
                    <Col span={8}>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default LoadingPage