import { ActionType, InventoryLocationActions } from './actions'
import { AsyncActionMode } from 'common/models'
import {InventoryLocationModel, InventoryLocationState} from './models'

const INVENTORY_LOCATIONSLOCATION_INITIAL_STATE: InventoryLocationState = {
  isLoading: false,
  isSaving: false,
  locations: [],
  error: undefined,
}

export default (
  state = INVENTORY_LOCATIONSLOCATION_INITIAL_STATE,
  action: InventoryLocationActions,
): InventoryLocationState => {
  switch (action.type) {
    case ActionType.GET_INVENTORY_LOCATIONS:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          locations: action.data,
          isLoading: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isLoading: false,
        }
      }
      break
    case ActionType.CREATE_INVENTORY_LOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          locations: state.locations?.concat(action.data),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.UPDATE_INVENTORY_LOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          locations: state.locations!.map((item) =>
              item.id === action.data.id ? { ...action.data } : item,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_INVENTORY_LOCATION:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          locations: state.locations!.filter(
              (item) => item.id !== action.request.id,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break
  }
  return state
}
