import { AsyncActionMode } from 'common/models'
import { Country } from "../models";


export enum ActionType {
    GET_COUNTRIES = 'GET_COUNTRIES',
    GET_COUNTRY = 'GET_COUNTRY',
    SET_DEFAULT_COUNTRY = 'SET_DEFAULT_COUNTRY',
    CREATE_COUNTRY = 'CREATE_COUNTRY',
    UPDATE_COUNTRY = 'UPDATE_COUNTRY',
    DELETE_COUNTRY = 'DELETE_COUNTRY'
}

export type CountriesActions =
    | GetCountriesRequestAction
    | GetCountriesResponseAction
    | GetCountriesErrorAction

    | GetCountryRequestAction
    | GetCountryResponseAction
    | GetCountryErrorAction

    | UpdateCountryRequestAction
    | UpdateCountryResponseAction
    | UpdateCountryErrorAction

    | SetDefaultCountryAction




// get-all =>
export class GetCountriesRequestAction {
    readonly type = ActionType.GET_COUNTRIES
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetCountriesResponseAction {
    readonly type = ActionType.GET_COUNTRIES
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetCountriesRequestAction, public data: Country[]) { }
}
export class GetCountriesErrorAction {
    readonly type = ActionType.GET_COUNTRIES
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetCountriesRequestAction, public error: string) { }
}


// get one =>
export class GetCountryRequestAction {
    readonly type = ActionType.GET_COUNTRY
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: number) {
        ''
    }
}
export class GetCountryResponseAction {
    readonly type = ActionType.GET_COUNTRY
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: GetCountryRequestAction, public data: Country) { }
}
export class GetCountryErrorAction {
    readonly type = ActionType.GET_COUNTRY
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: GetCountryRequestAction, public error: string) { }
}




// update =>
export class UpdateCountryRequestAction {
    readonly type = ActionType.UPDATE_COUNTRY
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: Country) { }

}
export class UpdateCountryResponseAction {
    readonly type = ActionType.UPDATE_COUNTRY
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public request: UpdateCountryRequestAction, public data: Country) { }
}
export class UpdateCountryErrorAction {
    readonly type = ActionType.UPDATE_COUNTRY
    readonly mode = AsyncActionMode.ERROR
    constructor(public request: UpdateCountryRequestAction, public error: string) { }
}


// default
export class SetDefaultCountryAction {
    readonly type = ActionType.SET_DEFAULT_COUNTRY
    constructor() { '' }
}