import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, Col, Form, Input, message, Row, Space, Spin} from "antd";
import {formItemLayout, invoiceItemLabelLayout, tailLayout} from "../../../helpers/layoutHelpers";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Button from "antd-button-color";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {AppState} from "../../../common/models";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {CustomerDetails} from "../customer/models";
import {validateInvoicingEmail, ValidateParams, ValidateStatus} from "../../../common/fce";
import sendInvoiceMail from "./actions/sendInvoiceMail";

interface Props {
    selectedCustomer: CustomerDetails
    setSendMailVisible: (bool) => void
    updateHistory: () => void
}

const SendMailForm = (props: Props) => {
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const {selectedCustomer, setSendMailVisible} = props
    const {invoice, attachments, mail, isSaving} = useSelector((state: AppState) => state.invoice)

    const [mailLoading, setMailLoading] = useState<boolean>(false)
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
    const [attachList, setAttachList] = useState<{label: string, value: string}[]>([]);

    const [invoicing, setInvoicing] = useState<ValidateParams>({value: '', validateStatus: '', errorMsg: ''})

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('SendMailForm: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('SendMailForm: ' + msg)} }

    useEffect(() => {
        // fetch mail
        if (mail) {
            setMailLoading(false)
            form && form.setFieldsValue({subject: mail.subject})
            form && form.setFieldsValue({body: mail.body})
        }
    }, [mail])

    useEffect(() => {
        // fetch invoice
        if (invoice && attachments && attachments.length) {
            const options = attachments.map(attach => {return {label: attach.name, value: attach.id.toString()}})
            setAttachList(options)
            const values = attachments.map(attach => attach.id.toString())
            setCheckedList(values)
            logger('CheckedList='+values)
        }
        else {
            setAttachList([])
            setCheckedList([])
            logger('CheckedList=[]')
        }
    }, [attachments])

    const handleInvoicingChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setInvoicing({...validateInvoicingEmail(value), value})
    }

    const handleFinish = (v) => {
        if (mail && (invoicing.validateStatus === 'success' || !invoicing.validateStatus)) {
            const data = {
                mail_id: mail.id,
                subject: v.subject,
                body: v.body,
                emails: v.to,
                attachment_ids: checkedList
            }
            logger('sendInvoiceMail: ' + JSON.stringify(data))
            dispatch(sendInvoiceMail(data, (suc) => {
                props.updateHistory()
                if (suc) {
                    message.success(t('billing.invoice.send_mail_success') + ' to ' + data.emails)
                } else {
                    message.error(t('billing.invoice.send_mail_error'))
                }
                setSendMailVisible(false)
            }))
        }
    }


    if (mailLoading) {
        return <Spin />
    }

    return (
        <>
            {
                mail && selectedCustomer &&
                <Form form={form}
                      {...formItemLayout}
                      initialValues={{...mail, to: selectedCustomer.invoicing}}
                      onFinish={handleFinish}
                >
                    <Form.Item name='to'
                        label={t('billing.invoice.modal.to')}
                        validateStatus={invoicing.validateStatus}
                        help={invoicing.errorMsg}>
                        <Input onChange={handleInvoicingChange} />
                    </Form.Item>
                    <Form.Item name='subject'
                        label={t('billing.invoice.modal.subject')}>
                        <Input />
                    </Form.Item>

                    <Form.Item label={t('billing.invoice.modal.body')} name='body'>
                        <ReactQuill theme="snow"/>
                    </Form.Item>

                    <Form.Item name='attachment'
                        label={t('billing.invoice.modal.attachment')}>
                        <span style={{fontWeight: 'bold'}}>{mail.attachment}</span>
                    </Form.Item>

                    { attachList && attachList.length > 0 &&
                        (
                            <Form.Item name='attachment_ids' initialValue={checkedList}
                                label={t('billing.invoice.modal.attachment')}>
                                <Checkbox.Group options={attachList} onChange={setCheckedList} />
                            </Form.Item>
                        )
                    }

                    <Form.Item {...invoiceItemLabelLayout}>
                        <Row className={'text-center'}>
                            <Col span={8} className='left'>
                                <Space>
                                    <Button size='middle' type='default' onClick={() => setSendMailVisible(false)}>
                                        {t('billing.invoice.modal.btn_close')}
                                    </Button>
                                </Space>
                            </Col>
                            <Col span={16} className='left'>
                                <Space>
                                    <Button htmlType='submit'
                                            disabled={isSaving}
                                            size='middle' type='primary'>
                                        {t('billing.invoice.modal.btn_send')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form.Item>

                    <Row className={'text-center'}>
                        <Col span={'24'}>
                            {
                                mail.sent === undefined || mail.sent.length === 0 ? <span className={'text-danger'} /> :
                                    <span className={'text-danger'}>Already sent to {mail.sent[0].mail_to} at {appSetting.renderDateTime(mail.sent[0].created_at)}</span>
                            }
                        </Col>
                    </Row>
                </Form>
            }
        </>
    )
}

export default SendMailForm