import React, { useEffect, useRef, useState } from 'react'
import {Progress, Upload, Skeleton, message, Spin, Modal} from 'antd'
import { PlusOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/es/upload/interface'
import { useDispatch, useSelector } from 'react-redux'
import {useParams} from 'react-router'
import createBillAttachment from './actions/createBillAttachment'
import { AppState } from 'common/models'
import deleteBillAttachment from './actions/deleteBillAttachment'
import './BillAttachment.scss'
import Button from 'antd-button-color'
import {protectedApiClient} from 'helpers/api'
import {useTranslation} from "react-i18next";


interface ParamTypes {
    id: string
}


const BillAttachment = () => {
    const { id: bill_id } = useParams<ParamTypes>()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const { bill, attachments, isUploading } = useSelector((state: AppState) => state.bill)

    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [progress, setProgress] = useState(0)
    const [downloading, setDownloading] = useState<boolean>(false)

    const [pdfDlg, setPdfDlg] = useState<boolean>(false)
    const [previewLoading, setPreviewLoading] = useState<boolean>(false)
    const [preview, setPreview] = useState<string>('')
    const [mimeType, setMimeType] = useState<string>('application/pdf')


    useEffect(() => {
        // Attachments are loaded on UpdateBillPage.tsx
        if (bill && bill.id === parseInt(bill_id)) {
            renderAttachments()
        }
    }, [attachments])

    const renderAttachments = () => {
        const files:UploadFile[] = attachments.map(attach => {
            return {
                uid: attach && attach.id ? attach.id.toString() : '',
                name: attach.name,
                bill_id: Number(bill_id),
                status: 'done',
                url: '',
                mime_type: attach.mime_type
            }
        })
        setFileList(files)
    }

    const handlePreview = (file) => {
        console.log('handlePreview: '+JSON.stringify(file))
        setMimeType(file.mime_type)
        pdfPreview(file.uid)
    }

    const handleDownload = (file) => {
        setDownloading(true)
        protectedApiClient.get<string>(`/billing/bill-attachment/${file.uid}/content`,{onDownloadProgress: () => {
            }})
            .then(response => {
                const downloadLink = document.createElement("a")
                downloadLink.href = `data:${file.mime_type};base64,${response.data}`
                downloadLink.download = file.name
                downloadLink.click()
                setDownloading(false)
            })
            .catch(error => {
                console.log(error)
                setDownloading(false)
            })
    }

    const handleUpload = (info) => {
        if (fileList && info.fileList.length > fileList.length) {
            const reader = new FileReader()
            reader.onload = (e) => {
                info.file.base64 = e.target?.result
                dispatch(
                    createBillAttachment({
                            bill_id: Number(bill_id),
                            name: info.file.name,
                            content: (e.target?.result as string).split(',')[1]
                        },
                        (attachment_id, isSuccess) => {
                            if (attachment_id) {
                                info.file.uid = attachment_id
                            }
                        },
                        progressEvent => {
                            const percent = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                            setProgress(percent)
                        })
                )
            }
            reader.readAsDataURL(info.file)
        }
        setFileList(info.fileList)
    }

    const onRemove = (file: UploadFile) => {
        dispatch(
            deleteBillAttachment(
                Number(file.uid),
                suc => suc && message.success(t('general.success'))
            )
        )
    }

    function downloadBase64File(base64Data, fileName) {
        const linkSource = `${base64Data}`
        const downloadLink = document.createElement("a")
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
    }

    const pdfPreview = (attachId) => {
        // download and show preview PDF
        setPdfDlg(true)
        setPreviewLoading(true)
        protectedApiClient.get<string>(`/billing/bill-attachment/${attachId}/content`)
            .then(response => {
                setPreviewLoading(false)
                setPreview(response.data)
            })
            .catch(error => {
                message.error(t('billing.invoice.preview_fail'))
                setPreviewLoading(false)
            })
    }

    return (
        <>
            {isUploading ? <Progress percent={progress} /> : null}

            {
                downloading ?
                    <Skeleton active />
                    :
                    <Upload
                        className='InvoiceAttachment'
                        accept='.png, .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .ppt, .pptx'
                        listType='text'
                        fileList={fileList}
                        onPreview={handlePreview}
                        onDownload={handleDownload}
                        onChange={handleUpload}
                        onRemove={onRemove}
                        beforeUpload={() => false}
                        maxCount={10}
                        showUploadList={{showDownloadIcon: false, downloadIcon: <ArrowDownOutlined />}}
                    >

                        <Button icon={<PlusOutlined />}>Upload</Button>

                    </Upload>
            }

            <Modal
                visible={pdfDlg}
                footer={null}
                onCancel={() => setPdfDlg(false)}
                width={1200}
                style={{height: '100vh', top: 50}}
                title={t('billing.invoice.pdf_preview')}
            >
                {
                    previewLoading &&
                    <Spin />
                }
                {
                    !previewLoading &&
                    <embed type={mimeType} src={`data:${mimeType};base64, ${preview}`} style={{width: '100%', height: '100vh'}}/>
                }
            </Modal>
        </>
    )

}

export default BillAttachment
