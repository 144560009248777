import {Alert, Col, Row} from "antd";
import React from "react";

interface Props {
    condition: boolean
    message: string
    type?: "warning" | "error" | "info" | "success"
}


const Zalert = (props: Props) => {

    if (!props.condition) {
        return null
    }

    return (
        <Row>
            <Col span={24}>
                {
                    props.condition &&
                    <Alert className='zis-alert'
                           message={props.message}
                           type={!props.type ? 'warning' : props.type} />
                }
            </Col>
        </Row>
    )
}

export default Zalert