import {
    GetCreditNoteRequestAction, GetCreditNoteResponseAction, GetCreditNoteErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { InvoiceDetails } from '../models'

export default (
    params: {id: number},
    onProgress?: (ProgressEvent: any) => void,
    cb?: (isSuccess: boolean, id: number) => void,
) => {
    return (
        dispatch: (
            arg:
                | GetCreditNoteRequestAction
                | GetCreditNoteResponseAction
                | GetCreditNoteErrorAction,
        ) => void,
    ) => {
        const request = new GetCreditNoteRequestAction(params)
        dispatch(request)

        protectedApiClient
            .post<InvoiceDetails>(`/billing/invoice/${params.id}/credit-note`, params)
            .then((response) => {
                dispatch(new GetCreditNoteResponseAction(request, response.data))
                cb && cb(true, response.data.id)
            })
            .catch((error) => {
                dispatch(new GetCreditNoteErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false, 0)
            })
    }
}
