import {
    RemoveDnsServiceRequestAction,
    RemoveDnsServiceResponseAction,
    RemoveDnsServiceErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {DomainModel, DomainServiceParams} from '../models'


export default (
    id: number,
    cb?: (suc: boolean) => void
) => {
    return (
        dispatch: (
            arg:
                | RemoveDnsServiceRequestAction
                | RemoveDnsServiceResponseAction
                | RemoveDnsServiceErrorAction
        ) => void
    ) => {
        const request = new RemoveDnsServiceRequestAction(id)
        dispatch(request)
        protectedApiClient
            .delete(`/domain/${id}/dns`)
            .then((response) => {
                dispatch(new RemoveDnsServiceResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new RemoveDnsServiceErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
