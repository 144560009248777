import React, {useEffect, useRef} from 'react'
import {Card, Table, Space, message, Modal} from 'antd'
import {PlusCircleOutlined, ShopOutlined} from '@ant-design/icons/lib/icons'
import {AppState} from 'common/models'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {useState} from 'react'
import deleteServiceType from './actions/deleteServiceType'
import {ServiceTypeDetails} from './model'
import Button from 'antd-button-color'
import {
	DeleteTwoTone,
	EditTwoTone,
	ExclamationCircleOutlined,
	InfoCircleTwoTone
} from '@ant-design/icons'
import getServiceTypes from './actions/getServiceTypes'
import ServiceTypeForm from 'components/ServiceTypeForm/ServiceTypeForm'
import createServiceType from './actions/createServiceType'
import updateServiceType from './actions/updateServiceType'
import TotalNum from "../../../components/TotalNum/TotalNum";
import {PAGING} from "../../../common/enums";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import usePageSize from "../../../common/usePageSize";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import HistoryModal from "../../../components/History/HistoryModal";
import {sort_name} from "../../../common/sorting";

const { confirm } = Modal

const ServiceTypePage = () => {
	const CONTROL_NAME = 'page_billing_service_type'
	const {t} = useTranslation()
	const dispatch = useDispatch()

	const {servicetypes, isLoading, isSaving} = useSelector((state: AppState) => state.servicetype)

	const [dataSource, setDataSource] = useState<ServiceTypeDetails[]>()
	const [pageSize, setPageSize] = useState<string>()
	const [isModalVisible, setModalVisible] = useState(false)
	const [dataToUpdate, setDataToUpdate] = useState<ServiceTypeDetails>()
	const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
	const [historyTitle, setHistoryTitle] = useState('')
	const [historyModelId, setHistoryModelId] = useState<number | undefined>()
	const [openDelete, setOpenDelete] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)

	// permissions
	const [isEditor, setEditor] = useState(false)

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator/>
			</div>
		)
	}

	// required authorization
	if (!loggedUser.hasAccess(CONTROL_NAME)) {
		return <ErrorPage403 />
	}

	// settings
	const appSetting = loggedUser.getAppSettings()

	// logger
	const logger = (msg) => { if (appSetting && appSetting.debug) {console.log('ServiceTypePage: ' + msg)} }
	usePageSize(appSetting, loggedUser.user.id, pageSize)

	// history drag modal
	const draggleRef = useRef<HTMLDivElement>(null)
	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggleRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	}

	useEffect(() => {
		// load servicetypes from API
		// trigger ONCE
		if (loggedUser) {
			setEditor(loggedUser.hasAccess('page_billing_service_type_editor'))
		}
		dispatch(getServiceTypes())
	}, [])


	useEffect(() => {
		// update datasource when data was changed
		if (!isModalVisible) {
			// refreshGrid after model closed
			setDataSource(servicetypes.sort(sort_name))
		}

	}, [servicetypes, isModalVisible])

	const onFinish = (values: any) => {
		dataToUpdate
			? dispatch(
			updateServiceType({...values, id: dataToUpdate.id}, (isSuccess: boolean) => {
				if (isSuccess) {
					message.success(t('billing.servicetype.edit_done'))
					setModalVisible(false)
				} else {
					message.error(t('billing.servicetype.error.update'))
					setModalVisible(false)
				}
			}),
			)
			: dispatch(
			createServiceType(values, (isSuccess: boolean) => {
				if (isSuccess) {
					message.success(t('billing.servicetype.create_done'))
					setModalVisible(false)
				} else {
					message.error(t('billing.servicetype.error.create'))
					setModalVisible(false)
				}
			}),
			)
	}

	const onDelete = (id: number) => {
		dispatch(
			deleteServiceType(id, (isSuccess: boolean) => {
				isSuccess && message.success(t('billing.servicetype.delete_done'))
			}),
		)
	}

	const showConfirmDelete = (b: ServiceTypeDetails, title: string) => {
		if (!b) {
			return
		}
		// if (!isDeleter) {
		//     message.error(t('general.error403'))
		//     return
		// }
		confirm({
				icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
				title: title,
				content: <p>{b.name}</p>,
				okText: t('general.yes'),
				cancelText: t('general.cancel'),
				okButtonProps: { loading: confirmDelete },
				className: 'confirm-alert',
				onOk() {
					setConfirmDelete(true)
					dispatch(deleteServiceType(b.id,suc => {
						setConfirmDelete(false)
						setOpenDelete(false)
						if (suc) {
							message.success(t('general.success'))
						}
					}))
				},
				onCancel() {
					setOpenDelete(false)
				},
			}
		)
	}

	const columns = [
		{
			title: t('billing.servicetype.name'),
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: t('billing.servicetype.is_dynamic'),
			key: 'is_dynamic',
			dataIndex: 'is_dynamic',
		},
		{
			title: t('billing.servicetype.service_link'),
			key: 'service_link',
			dataIndex: 'service_link',
		},
		{
			title: t('billing.servicetype.action'),
			key: 'action',
			width: '100px',
			dataIndex: 'action',
			render: (text: string, record: ServiceTypeDetails) => (
				<Space size='small'>
					{
						isEditor && (
							<Button type='text' size='small'
									onClick={() => {
										setModalVisible(true)
										setDataToUpdate(record)
									}}
									className='actionButton'
									icon={<EditTwoTone twoToneColor='green' />}
							/>
						)
					}

					{
						isEditor && (
							<Button type='text' danger size='small'
									className='actionButton'
									onClick={() => showConfirmDelete(record, t('billing.servicetype.confirm_delete'))}
									icon={<DeleteTwoTone twoToneColor='red'/>} />
						)
					}
					<Button title={t('general.btnHistory')} size='small'
							onClick={() => {
								setHistoryModelId(record.id)
								setHistoryTitle(record.name)
								setHistoryModalVisible(true)
							}}
							icon={<InfoCircleTwoTone />}
							className='actionButton'
					/>
				</Space>
			),
		},
	]

	return (
		<>
			<Card
				title={t('billing.servicetypes.title')}
				extra={
					<Button
						type='primary'
						disabled={!isEditor}
						onClick={() => {
							setDataToUpdate(undefined)
							setModalVisible(true)
						}}>
						<PlusCircleOutlined /> {t('billing.servicetype.create')}
					</Button>
				}
				className='BillingTaxesPage'
				>
				{
					appSetting &&
					<Table<ServiceTypeDetails>
						rowClassName={() => 'highlight'}
						bordered={true}
						columns={columns}
						loading={isLoading}
						dataSource={dataSource}
						rowKey={(record) => `${record.id}`}
						showHeader={true}
						pagination={{
							defaultPageSize: appSetting?.grid_page_size,
							pageSizeOptions: PAGING,
							showSizeChanger: true
						}}
						onChange={(ev) => {setPageSize(`${ev.pageSize}`)}}
						footer={() => TotalNum(Number(dataSource?.length), 'Service Type', dataSource)}
					/>
				}

			</Card>
			<Modal
				destroyOnClose={true}
				style={{top: 20}}
				title={
					<>
						<ShopOutlined />{' '}
						{dataToUpdate ? t('billing.servicetype.update_title') : t('billing.servicetype.create_title')}
					</>
				}
				visible={isModalVisible}
				onCancel={() => setModalVisible(false)}
				maskClosable={false}
				footer={null}>
				<ServiceTypeForm
					dataToUpdate={dataToUpdate}
					setModalVisible={setModalVisible}
					isSaving={isSaving}
					onFinish={onFinish}
				/>
			</Modal>

			<HistoryModal service='billing' model='ServiceType'
						  modelId={historyModelId}
						  title={historyTitle}
						  isModalVisible={isHistoryModalVisible}
						  setModalVisible={() => setHistoryModalVisible(false)}
						  modalRender={(modal) => (
							  <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
								  <div ref={draggleRef}>{modal}</div>
							  </Draggable>
						  )}
			/>
		</>
	)
}

export default ServiceTypePage
