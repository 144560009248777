import {InventoryModel} from "./inventory/models";
import {Space} from "antd";
import Badge from "antd/lib/badge";
import React from "react";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import moment from "moment";
import i18next from "i18next";


export const isInventoryUsed = (parentId) => {
    // 1 - 13 => locations
    return parentId > 13
}

export const _validateInventoryForm = (_form:FormInstance<any>, _isOwnerZcom: boolean, t: typeof i18next.t) => {
    const name = _form.getFieldValue('name')
    if (!name || name.length < 3) {
        _form.setFields([
            {name: 'name', errors: [t('errors.field_required')]},
        ])
        return false
    }
    const type_id = _form.getFieldValue('type_id')
    if (!type_id || type_id < 1) {
        _form.setFields([
            {name: 'type_id', errors: [t('errors.field_required')]},
        ])
        return false
    }
    const location_id = _form.getFieldValue('location_id')
    if (!location_id || location_id < 1) {
        _form.setFields([
            {name: 'location_id', errors: [t('errors.field_required')]},
        ])
        return false
    }
    // if (_isOwnerZcom) {
    //     const vendor_id = _form.getFieldValue('vendor_id')
    //     if (!vendor_id || vendor_id < 1) {
    //         _form.setFields([
    //             {name: 'vendor_id', errors: [t('errors.field_required')]},
    //         ])
    //         return false
    //     }
    // }

    const owner_id = _form.getFieldValue('owner_id')
    if (!owner_id || owner_id < 1) {
        _form.setFields([
            {name: 'owner_id', errors: [t('errors.field_required')]},
        ])
        return false
    }

    const w = _form.getFieldValue('warranty')
    if (!w || parseInt(w) < 1) {
        _form.setFields([
            {name: 'warranty', errors: [t('errors.field_required')]},
        ])
        return false
    }
    const purchase_date = _form.getFieldValue('purchase_date')
    if (purchase_date) {
        const dt = new Date(purchase_date);
        if (!dt || dt < new Date('2010-01-01')) {
            _form.setFields([
                {name: 'purchase_date', errors: [t('errors.field_error')]},
            ])
            return false
        }
    }

    return true
}


export const renderInventoryItem = (item: InventoryModel) => {
    return (
        <div className='componentOption'>
            <div className='name'>{item.name}</div>
            <div>
                <Space size='large'>
                    <span className='serial'>serial: {item.serial}</span>
                    {
                        isInventoryUsed(item.parent_id) && (
                            <Badge color="red" text="Used" />
                        )
                    }
                    {
                        !isInventoryUsed(item.parent_id) && (
                            <Badge color="green" text="Free" />
                        )
                    }
                </Space>
            </div>
        </div>
    )
}