import { createHash } from 'crypto'
import { UserDetails } from 'pages/user/models'
import {SUPER_ROLE} from "../common/LoggedUser";

export const hash256 = (txt: string) => {
  return createHash('sha256').update(txt).digest('hex')
}
export const hash512 = (txt: string) => {
  return createHash('sha512').update(txt).digest('hex')
}

export const storeCurrentUser = (token: string) =>
  localStorage.setItem('currentUser', token)

export const removeCurrentUser = () => localStorage.removeItem('currentUser')

export const currentUser = (): string | null => {
  return localStorage.getItem('currentUser')
}

export const isAllowedHelper = (neededRoles: string[], selfUser?: UserDetails) => {
  if (selfUser?.roles?.includes(SUPER_ROLE)) {
    // zcom-root is God
    return true
  }
  if (!neededRoles || (neededRoles && neededRoles.length === 0)) {
    return true
  }
  return selfUser?.roles?.some((item:string) => neededRoles.includes(item))
}

export const hasRoleHelper = (self: UserDetails, role) =>
  self?.roles?.includes(role)

export const isZcomNavHelper = (selfUser?: UserDetails) => {
  const roles = selfUser?.roles
  return (roles?.includes(SUPER_ROLE) || roles?.includes('zcom-admin'));
}

export const isNavAllowHelper = (selfUser?: UserDetails, menu_roles?: string[]) => {
  const roles = selfUser?.roles
  return menu_roles?.some((item:string) => roles?.includes(item))
}
