import React, { useEffect } from 'react'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import updateSetting from "../../../login/actions/updateSetting"
import { useLoggedUser } from "../../../../helpers/loginUserHelper"
import { LoadingIndicator } from "../../../../components"
import {Card, Col, Row, Spin} from "antd"
import { VpsHelper } from 'pages/vpsadmin/models'
import { Link } from 'react-router-dom'


const RenderBox = (props) => {
    return (
        <div className={'center'} style={{ width: 180, float: 'left', margin: '10px', padding: '10px', backgroundColor: '#ededed', border: '1px solid #dcdcdc', borderRadius: '5px' }}>
            <div style={{padding: '20px', color: '#666666'}}>
                <span className='title2'>{props.title}</span>
            </div>
            <div>
                <span className='title3' style={{padding: '1px'}}>{props.line}</span>
            </div>
        </div>
    )
}

const HypervisorDetailComponent = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { vpsServers, isLoadingHypervisor } = useSelector((state: AppState) => state.vpsadmin)
    const [pageSize, setPageSize] = useState<string>()

    const hypervisor = props.hypervisor

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator />
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()


    if (!hypervisor) {
        return (<Spin />)
    }

    return (
            <Card
                key={hypervisor.id}
                className={'mb8'}
                style={{border: 0}}
                loading={isLoadingHypervisor}
                title={
                <>
                    <Row style={{marginBottom: '10px'}}>
                        <Col span={2} className='right' style={{marginTop: '4px'}}>
                            <span className='textGray'>{t('vpsadmin.hypervisor')}:</span>
                        </Col>
                        <Col span={8} className='left'>&nbsp;
                            <Link className='title3' to={`/vpsadmin/hypervisor/${hypervisor.id}`}>{hypervisor.host}</Link>
                        </Col>

                        <Col span={10} className='center'>
                            <span className='textGray'>{t('vpsadmin.hypervisors.updated')}:</span> {appSetting.renderFullDateTime(hypervisor?.last_update)}
                        </Col>
                        <Col span={4} className='right'>
                            <span className='textGray'>{t('vpsadmin.proxmox')}:</span> {hypervisor.version}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={2} className='right'>
                            <span className='textGray'>{t('vpsadmin.cpu')}:</span>
                        </Col>
                        <Col span={22} className='left'>&nbsp;
                            {hypervisor.cpu_type}
                        </Col>
                    </Row>
                </>
                }>

                <Row style={{marginTop: '25px', marginBottom: '25px'}}>
                    <Col span={24} className='right'>
                        <RenderBox title={t('vpsadmin.cores')} line={hypervisor.vps_id ? `${hypervisor.cpu_occupied}` : `${VpsHelper.usedCpus(vpsServers, hypervisor)} / ${hypervisor.cpu_count}`} />
                        <RenderBox title={t('vpsadmin.vps.ram')} line={hypervisor.vps_id ? `${VpsHelper.transformToGB(hypervisor.ram_total)} GB` : `${VpsHelper.transformToGB(VpsHelper.usedRam(vpsServers, hypervisor))} / ${VpsHelper.transformToGB(hypervisor.ram_total)} GB`} />
                        {
                            hypervisor.storages.map(el => {
                                let storage = hypervisor.containerStorages.find(s => s.storage_id === el.id)
                                if (storage) {
                                    let size = hypervisor.vps_id ? `${VpsHelper.transformToGB(storage.disk_size)}` : `${VpsHelper.transformToGB(el.total_size - el.free_space)} / ${VpsHelper.transformToGB(el.total_size)}`
                                    return (
                                        <RenderBox title={el.type} line={`${size} GB`} />
                                    )
                                }
                            })
                        }
                    </Col>
                </Row>
            </Card>
    )
}

export default HypervisorDetailComponent

