import { UpdateSettingRequestAction, UpdateSettingResponseAction, UpdateSettingErrorAction } from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {SettingsDetails} from "../../settings/models";


export default (params: SettingsDetails, cb?: (isSuccess: boolean) => void) => {
    return (dispatch: (args: UpdateSettingRequestAction | UpdateSettingResponseAction | UpdateSettingErrorAction) => void) => {
        const request = new UpdateSettingRequestAction(params)
        dispatch(request)

        protectedApiClient.put<SettingsDetails>(`/setting/${params.id}`, params)
            .then(response => {
                dispatch(new UpdateSettingResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch(error => {
                dispatch(new UpdateSettingErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
