import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from "react-redux";
import moment from 'moment'
import {AppState} from "../../../common/models";
import {downloadTxtFile} from 'helpers/fileHelpers'
import {Spin} from "antd";

interface Props {
    zone: string
    serviceId: number

}

const DnsZoneExport = (props: Props) => {
    const {t} = useTranslation()

    const {exportedFile} = useSelector((state: AppState) => state.dnsservice)
    const [dataToExport, setDataToExport] = useState<string>()
    const [nameToExport, setNameToExport] = useState<string>()

    useEffect(() => {
        setDataToExport(exportedFile)
        dataToExport && downloadTxtFile(dataToExport, `zone_${nameToExport}_${moment().unix()}.txt`)
        //eslint-disable-next-line
        console.log('exportedFile: '+ exportedFile)
    }, [exportedFile])

    if (!exportedFile) {
        return (<Spin/>)
    }

    return (
        <>
            <div className='zoneFile'>
                <pre>{exportedFile}</pre>
            </div>
        </>
    )
}

export default DnsZoneExport