import React, {useState, useEffect} from 'react'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import Form, {useForm} from 'antd/lib/form/Form'
import Item from 'antd/lib/form/FormItem'
import {Input, Button, Row, Col, Divider, Spin, message, Radio, RadioChangeEvent} from 'antd'
import {AiOutlineMail} from 'react-icons/ai'
import { UserOutlined} from '@ant-design/icons'
import {BsFillPersonFill} from 'react-icons/bs'
import {FiPhone} from 'react-icons/fi'
import {RiStickyNoteLine} from 'react-icons/ri'
import {useTranslation} from 'react-i18next'
import {Store} from 'antd/lib/form/interface'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from 'common/models'
import {_parsePhone, _renderPhone, REG_IPV4} from "../../common/fce";
import getUser from "./actions/getUser";
import updateUser from "./actions/updateUser";
import {euPhoneNumberRegex} from "../../helpers/stringHelpers";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../components";
import {UpdateUserParams} from "./models";
import {ROLE_APP_ADMIN, ROLE_USER_MANAGER} from "../../common/LoggedUser";

interface Props {
	userId?: number
	setModalVisible: (param: boolean) => void
}

const FormStates = {
	LOADING: 0,
	HUMAN: 1,
	DAEMON: 2
}

const UserUpdateForm = ({userId, setModalVisible}: Props) => {
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const [form] = useForm()

	const {user, isSaving, isLoading} = useSelector((state: AppState) => state.user)
	const {companies, customers} = useSelector((state: AppState) => state.auth.tables)
	const {fontSize} = useSelector((state: AppState) => state.font)

	const [customerName, setCustomerName] = useState<string>('')
	const [accountType, setAccountType] = useState<string>('')

	const [updateStatus, setUpdateStatus] = useState<boolean>(false)
	const [pageState, setPageState] = useState<number>(0)

	const [isViewer, setViewer] = useState(false)
	const [isEditor, setEditor] = useState(false)
	const [isAdmin, setAdmin] = useState(false)	// admin can edit daemons
	const [canEdit, setCanEdit] = useState<boolean>(false)

	// get settings and logged user from store
	let loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator/>
			</div>
		)
	}
	const appSetting = loggedUser.getAppSettings()

	useEffect(() => {
		if (!userId) {
			setModalVisible(false)
			return
		}
		setViewer(true)
		setEditor(loggedUser!.hasAccess('page_users_edit_button'))
		setAdmin(loggedUser!.hasRole(ROLE_USER_MANAGER))
		dispatch(getUser(userId))
		transitionTo(FormStates.LOADING)
	}, [])

	useEffect(() => {
		if (user && user.id === userId) {
			setCanEdit(checkPermissions())
			setAccountType(user.is_system ? 'daemonType' : 'userType')
			setCustomerName(getCustomerName(user.customer_id))
			if (user.is_system) {
				transitionTo(FormStates.DAEMON)
			}
			else {
				transitionTo(FormStates.HUMAN)
			}
		}
		form.resetFields()
	}, [user && user.id])

	const getCustomerName = (cid: number) => {
		const cust = customers.find(c => c.id === cid)!
		if (!cust) {
			return `cust_${cid}`
		}
		if (cust.company) {
			return cust.company.name
		}
		else {
			return companies.find(c => c.id === cust.company_id)!.name
		}
	}

	const checkPermissions = () => {
		if (user && user.id === userId) {
			if (isAdmin) {
				return true
			}
			if (isEditor && user.is_system === 0) {
				return true
			}
		}
		return false
	}

	const handleSave = (values: Store): void => {
		if (user && user.id === userId) {
			let params: UpdateUserParams = {id: userId}
			if (user.is_system === 1) {
				// bot
				params = {
					id: userId,
					surname: values.surname,
					note: values.note,
					whitelist_ip: values.whitelist_ip
				}
			}
			else {
				// human
				params = {
					id: userId,
					surname: values.surname,
					name: values.name,
					email: values.email,
					phone: _parsePhone(values.phone),
					note: values.note
				}
			}

			dispatch(updateUser(params, suc => {
				if (suc) {
					message.success(t('general.success'))
					setModalVisible(false)
				}
			}))
		}
	}

	const transitionTo = (page: number) => {
		setPageState(page)
	}

	const renderHuman = () => {
		if (!user) {
			return renderLoading()
		}
		return (
			<Form
				{...formItemLayout}
				form={form}
				name='create-user'
				className='create-user-form'
				initialValues={{...user, phone: _renderPhone(user.phone)}}
				onFinish={handleSave}
				onChange={() => setUpdateStatus(true)}
			>
				<Item name='id' style={{display: 'none'}}>
					<Input type='hidden' />
				</Item>

				<Item name='surname' label={t('createUserPage.surname')}
					  rules={[{required: true, message: t('errors.field_required')}]}
					  hasFeedback>
					<Input size={fontSize} />
				</Item>

				<Item name='name' label={t('createUserPage.name')}>
					<Input size={fontSize} />
				</Item>

				<Item name='customerName' initialValue={customerName} label={t('createUserPage.customer')}>
					<Input size={fontSize} disabled />
				</Item>

				<Item name='accountType' initialValue={t(`createUserPage.${accountType}`)} label={t('createUserPage.accountType')}>
					<Input size={fontSize} disabled />
				</Item>

				<Item name='username' label={t('createUserPage.username')} >
					<Input size={fontSize} disabled />
				</Item>

				<Item name='email' label={t('createUserPage.email')}
					  rules={[{
						  required: false,
						  type: "email",
						  message: t('errors.error_email')
					  }]}
					  hasFeedback>
					<Input size={fontSize} type='email' />
				</Item>

				<Item name='phone' label={t('createUserPage.phone')}
					  rules={[{
						  pattern: new RegExp(euPhoneNumberRegex),
						  message: t('errors.error_phone')
					  }]}
					  hasFeedback>
					<Input size={fontSize} />
				</Item>

				<Item name='note' label={t('createUserPage.note')}>
					<Input size={fontSize} />
				</Item>

				<Item {...tailLayout}>
					<Button type='primary'
							htmlType='submit'
							className='login-form-button'
							size={fontSize}
							loading={isSaving}
							disabled={!updateStatus}>
						{t('updateUserPage.update')}
					</Button>
				</Item>
			</Form>
		)
	}

	const renderDaemon = () => {
		return (
			<Form
				{...formItemLayout}
				form={form}
				name='create-user'
				className='create-user-form'
				initialValues={user}
				onFinish={handleSave}
				onChange={() => setUpdateStatus(true)}
			>
				<Item name='id' style={{display: 'none'}}>
					<Input type='hidden' />
				</Item>

				<Item name='surname' label={t('createUserPage.daemonName')}
					  rules={[{required: true, message: t('errors.field_required')}]} >
					<Input size={fontSize} />
				</Item>

				<Item name='customerName' initialValue={customerName} label={t('createUserPage.customer')}>
					<Input size={fontSize} disabled />
				</Item>

				<Item name='accountType' initialValue={t(`createUserPage.${accountType}`)} label={t('createUserPage.accountType')}>
					<Input size={fontSize} disabled />
				</Item>

				<Item name='username' label={t('createUserPage.username')} >
					<Input size={fontSize} disabled />
				</Item>

				<Item name='whitelist_ip' label={t('createUserPage.whitelistIp')}
					  rules={[{required: true, message: t('errors.field_required')},
						  {pattern: REG_IPV4, message: 'Invalid format for IPv4.'}]}
					  hasFeedback>
					<Input size={fontSize} />
				</Item>

				<Item name='note' label={t('createUserPage.note')}>
					<Input size={fontSize} />
				</Item>

				<Item {...tailLayout}>
					<Button type='primary'
							htmlType='submit'
							className='login-form-button'
							size={fontSize}
							loading={isSaving}
							disabled={!updateStatus}>
						{t('updateUserPage.update')}
					</Button>
				</Item>
			</Form>
		)
	}

	const renderLoading = () => {
		return (
			<Row gutter={[0,0]}>
				<Col span={24} style={{textAlign: 'center'}}>
					<Spin/>
				</Col>
			</Row>
		)
	}

	if (isLoading) {
		return renderLoading()
	}

	if (!user) {
		return renderLoading()
	}

	// render
	switch(pageState) {
		case FormStates.LOADING:
			return renderLoading()

		case FormStates.HUMAN:
			return renderHuman()

		case FormStates.DAEMON:
			return renderDaemon()

		default:
			return renderLoading()
	}

}

export default UserUpdateForm
