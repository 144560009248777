import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../common/models";
import {Button, Col, Form, Input, InputNumber, message, Row, Select, Spin, Switch} from "antd";
import {halfLayout} from "../../../helpers/layoutHelpers";
import {useForm} from "antd/lib/form/Form";
import {Store} from "antd/lib/form/interface";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import { sort_label} from "../../../common/sorting";
import tableCustomers from "../../login/actions/tableCustomers";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import getDnsServices from "./actions/getDnsServices";
import {getDnsServiceId, hasDnsService} from "./common";
import {DomainModel} from "./models";
import addDnsService from "./actions/addDnsService";
import removeDnsService from "./actions/removeDnsService";


interface Props {
    closeModal: (update: boolean) => void
}

const DomainEditForm = (props: Props) => {
    const {t} = useTranslation()
    const [form] = useForm()
    const dispatch = useDispatch()

    const {customers} = useSelector((state: AppState) => state.auth.tables)
    const {isSaving, domain, dnsServiceNames} = useSelector((state: AppState) => state.dnsservice)

    const [customerOptions, setCustomerOptions] = useState<{ label: string, value: number, key: number }[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [serviceOptions, setServiceOptions] = useState<{ label: string, value: number }[]>([])
    const [selectedServiceId, setSelectedServiceId] = useState<number | undefined>()
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [createDns, setCreateDns] = useState<boolean>(false)
    const [removeDns, setRemoveDns] = useState<boolean>(false)


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading">
                <LoadingIndicator/>
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        if(customers && customers.length) {
            const data = customers.map((c) => {return {
                value: c.id,
                key: c.id,
                label: c.company!.name
            }})
            setCustomerOptions(data.sort(sort_label))
        }
        else {
            dispatch(tableCustomers())
        }

        if (!dnsServiceNames || !dnsServiceNames.length) {
            dispatch(getDnsServices())
        }
    }, [])

    useEffect(() => {
        setSelectedCustomerId(loggedUser.customer.id)
    }, [dispatch, customers])

    useEffect(() => {
        if (dnsServiceNames.length > 0) {
            setServiceOptions(dnsServiceNames.map(dns => ({label: dns.name, value: dns.id})))
        }
    }, [dnsServiceNames])

    useEffect(() => {
        if (domain && hasDnsService(domain)) {
            setSelectedServiceId(getDnsServiceId(domain))
        }
    }, [domain])

    useEffect(() => {
        if (selectedServiceId) {
            form.setFieldsValue({service_id: selectedServiceId})
        }
        else {
            form.setFieldsValue({service_id: undefined})
        }
    }, [selectedServiceId])



    const onFormSubmit = (values: Store) => {
        // save data
        if (!domain) {
            message.error('Missing domain')
            return
        }

        if (createDns && selectedServiceId) {
            let params = {id: domain.id, service_id: selectedServiceId}
            dispatch(addDnsService(params, (suc: boolean) => {
                if (suc) {
                    props.closeModal(true)
                    message.success(t('general.success'))
                }
            }))
            return
        }

        if (removeDns && domain) {
            dispatch(removeDnsService(domain.id, (suc: boolean) => {
                if (suc) {
                    props.closeModal(true)
                    message.success(t('general.success'))
                }
            }))
            return
        }
    }

    if (!customers || customers.length === 0 || !domain) {
        return (<Spin/>)
    }

    return (
        <Form
            {...halfLayout}
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={{...domain}}
        >

            <Form.Item name='name' label={t('domainPage.domain')}>
                <Input type='hidden' />
                <b>{domain.name}</b>
            </Form.Item>

            <Form.Item name='customer_id' label={t('domainPage.customer')}>
                <Select optionFilterProp='label'
                        style={{width: '100%'}}
                        options={customerOptions}
                        disabled={true}
                />
            </Form.Item>

            {   // there is DNS server
                domain && hasDnsService(domain) && (
                    <>
                        <Form.Item name='service_id' label={t('domainPage.nameserver')}>
                            {!removeDns && domain.services?.map(s => s.name)}<Input type='hidden' />
                        </Form.Item>
                        <Form.Item name='create_dns' label={t('domainPage.remove_dns')} valuePropName='checked' >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={removeDns}
                                onChange={() => {
                                    setRemoveDns(!removeDns)
                                    setSelectedServiceId(undefined)
                                    setServiceOptions([])
                                    setUpdateStatus(true)
                                }}
                            />
                        </Form.Item>
                    </>
                )
            }

            {   // DNS server not set
                domain && !hasDnsService(domain) && (
                    <>
                        <Form.Item name='create_dns' label={t('domainPage.create_dns')} valuePropName='checked' >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={createDns}
                                onChange={() => {
                                    setCreateDns(!createDns)
                                }}
                            />
                        </Form.Item>
                        <Form.Item name='service_id' label={t('domainPage.nameserver')}>
                            <Select optionFilterProp='label'
                                    style={{width: '100%'}}
                                    options={serviceOptions}
                                    disabled={!createDns}
                                    onChange={ (value: number) => {
                                        setSelectedServiceId(value)
                                    }}
                            />
                        </Form.Item>
                    </>
                )
            }

            <Row>
                <Col span={12}></Col>
                <Col span={12}>
                    <Button loading={isSaving} type='primary' htmlType='submit'
                            disabled={!(domain && !hasDnsService(domain) && createDns && selectedServiceId) && !(domain && hasDnsService(domain) && removeDns)}>
                        {t('general.confirm_change')}
                    </Button>
                </Col>
            </Row>

        </Form>
    )
}

export default DomainEditForm