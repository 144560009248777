import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import './GraphLine.scss'

import { Badge, Popover, Row, Tooltip } from 'antd'


const GraphLine = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [maxValue, setMaxValue] = useState<number>()
    useEffect(() => {
        setMaxValue(calculateTotalValue())
    }, [])



    const calculateTotalValue = () => {
        let total = 0

        props.data.data.map((item) => {
            total = total + item.value
        })
        return total;
    }


    const calculateWidthElement = (elem) => {
        let result = 0
        if (maxValue) {
            result = elem * 100 / maxValue
        }

        return result

    }


    const progresBar = () => {
        let counter = 0

        let result = props.data.data.map((item) => {

            let result = <Popover key={item.type} content={
                <div >
                    {item.value + ' ' + item.type}
                </div>
            }>
                <div key={counter} style={{
                    backgroundColor: props.data.color[counter],
                    width: calculateWidthElement(item.value) + '%'
                }} className={'borader-bar'} >
                    {item.value}
                </ div >
            </Popover >
            counter++
            return result
        })

        return result
    }




    return (
        <>
            <div className='progress'>
                <div className='type'>{props.data.title}</div>
                <div className='progress-line'> {progresBar()}</div>
            </div>

        </>

    )
}

export default GraphLine

