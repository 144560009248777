import {
    GetDomainRequestAction,
    GetDomainResponseAction,
    GetDomainErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {DomainModel} from '../models'

export default (domain: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetDomainRequestAction
                | GetDomainResponseAction
                | GetDomainErrorAction
        ) => void
    ) => {
        const request = new GetDomainRequestAction(domain)
        dispatch(request)

        protectedApiClient
            .get<DomainModel>(`/domain?name=${domain}&expand=services`)
            .then((response) => {
                let data = null
                if (Array.isArray(response.data) && response.data.length) {
                    dispatch(new GetDomainResponseAction(request, response.data[0]))
                    cb && cb(true)
                }
                else {
                    dispatch(new GetDomainErrorAction(request, 'Not found'))
                    cb && cb(false)
                }

            })
            .catch((error) => {
                dispatch(new GetDomainErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
