import React, {useEffect, useRef} from 'react'
import {Card, Table, Space, message, AutoComplete, Row, Col, Modal} from 'antd'
import {
	PercentageOutlined,
	PlusCircleOutlined,
} from '@ant-design/icons/lib/icons'
import './TaxesPage.scss'
import {AppState} from 'common/models'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import getTaxes from './actions/getTaxes'
import moment from 'moment'
import getCountries from 'components/SelectCountries/actions/getCountries'
import {useState} from 'react'
import TaxForm from 'pages/billing/tax/TaxForm'
import deleteTax from './actions/deleteTax'
import {TaxDetails} from './models'
import Button from 'antd-button-color'
import {
	DeleteTwoTone,
	EditTwoTone,
	ExclamationCircleOutlined,
	InfoCircleTwoTone
} from '@ant-design/icons'
import './TaxGrid.scss';
import TotalNum from "../../../components/TotalNum/TotalNum";
import {PAGING} from "../../../common/enums";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import usePageSize from "../../../common/usePageSize";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {sort_country_rate} from "../../../common/sorting";
import {CountryModel} from "../../company/models";
import DateInput from "../../../components/RangeFilter/DateInput";
import HistoryModal from "../../../components/History/HistoryModal";

const { confirm } = Modal

const TaxesPage = () => {
	const CONTROL_NAME = 'page_billing_tax'
	const {t} = useTranslation()
	const dispatch = useDispatch()

	const {taxes, isLoading} = useSelector((state: AppState) => state.tax)
	const {countries} = useSelector((state: AppState) => state.countries)

	const [dataSource, setDataSource] = useState<TaxDetails[]>()
	const [searchCountry, setSearchCountry] = useState('CZ')
	const [searchDate, setSearchDate] = useState<string | undefined>()

	const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
	const [historyTitle, setHistoryTitle] = useState('')
	const [historyModelId, setHistoryModelId] = useState<number | undefined>()
	const [isModalVisible, setModalVisible] = useState(false)
	const [dataToUpdate, setDataToUpdate] = useState<TaxDetails>()
	const [pageSize, setPageSize] = useState<string>()
	const [openDelete, setOpenDelete] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)

	// permissions
	const [isEditor, setEditor] = useState(false)

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (
			<div className="fullwidth-loading" >
				<LoadingIndicator/>
			</div>
		)
	}

	// required authorization
	if (!loggedUser.hasAccess(CONTROL_NAME)) {
		return <ErrorPage403 />
	}

	// settings
	const appSetting = loggedUser.getAppSettings()
	const SEARCH_MIN = appSetting.min_search_length

	// logger
	const logger = (msg) => { if (appSetting && appSetting.debug) {console.log('BillingTaxPage: ' + msg)} }
	usePageSize(appSetting, loggedUser.user.id, pageSize)

	// history drag modal
	const draggleRef = useRef<HTMLDivElement>(null)
	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggleRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	}

	useEffect(() => {
		// load servicetypes from API
		// trigger ONCE
		if (loggedUser) {
			setEditor(loggedUser.hasAccess('page_billing_tax_editor'))
		}
		setSearchDate(appSetting.renderDateSQL(moment().toDate()))
		dispatch(getTaxes())
		if(!countries) {
			// dispatch(getMenuRoles) && isAllowedHelper(menu_roles?.['page_billing_tax'], self) &&
			dispatch(getCountries())
		}
	}, [])

	useEffect(() => {
		// update datasource when data was changed
		if (!isModalVisible) {
			refreshGrid()
		}
	}, [taxes, searchCountry, searchDate, isModalVisible])

	const filtered = (country: string, dateStr: string | undefined) => {
		if (!taxes) {
			return []
		}

		if (country.length === 0 && dateStr === undefined) {
			// all records
			return taxes
		}

		let data = taxes
		if (country && country.length > 1) {
			const qCountry = (t) => t.country.toLowerCase().includes(country.toLowerCase())
			data = data.filter((t) => qCountry(t))
		}

		if (dateStr && dateStr.length > 1) {
			// date
			const selDate: number = new Date(dateStr).getTime()
			const q1 = (t) => (!t.valid_to && (new Date(t.valid_from).getTime() <= selDate))
			const q2 = (t) => ( t.valid_to && (new Date(t.valid_from).getTime() <= selDate) && (new Date(t.valid_to).getTime() >= selDate))
			data = data.filter((t) => q1(t) || q2(t))
		}
		return data
	}

	const refreshGrid = () => {
		let pattern = ''
		if (searchCountry) {
			pattern = searchCountry.replace(/\s/g, '')
		}

		const newData = filtered(pattern, searchDate).sort(sort_country_rate)
		logger(`refreshGrid country: ${pattern}, date: ${searchDate}, taxes: ${taxes?.length}, dataSource: ${newData.length}`)
		setDataSource(newData)
	}

	const showConfirmDelete = (b: TaxDetails, title: string) => {
		if (!b) {
			return
		}
		// if (!isDeleter) {
		//     message.error(t('general.error403'))
		//     return
		// }
		confirm({
				icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
				title: title,
				content: <p>{b.name}</p>,
				okText: t('general.yes'),
				cancelText: t('general.cancel'),
				okButtonProps: { loading: confirmDelete },
				className: 'confirm-alert',
				onOk() {
					setConfirmDelete(true)
					dispatch(deleteTax(b.id,suc => {
						setConfirmDelete(false)
						setOpenDelete(false)
						if (suc) {
							message.success(t('general.success'))
						}
					}))
				},
				onCancel() {
					setOpenDelete(false)
				},
			}
		)
	}

	const FilterByNameInput = countries && (
		<AutoComplete
			placeholder={t('billing.tax.country')}
			options={countries.map((c: CountryModel) => ({value: c.iso, label: `${c.iso} - ${c.name_cz}`, key: c.iso}))}
			style={{width: 100}}
			dropdownMatchSelectWidth={200}
			value={searchCountry}
			showSearch
			allowClear
			optionFilterProp='id'
			onChange={(value) => {
				setSearchCountry(value)
			}}
			filterOption={(inputValue, option) => option!.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1}
		/>
	)

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			className: 'right',
		},
		{
			title: t('billing.tax.name'),
			dataIndex: 'name',
			key: 'name',
			width: '50%'
		},
		{
			title: FilterByNameInput,
			dataIndex: 'country',
			key: 'country',
			className: 'center',
			render: (text, rec: TaxDetails) => (<span>{`${rec.country}`}</span>)
		},
		{
			title: t('billing.tax.rate'),
			dataIndex: 'rate',
			key: 'rate',
			width: '100px',
			className: 'right',
			render: (n: number) => appSetting?.renderPercent(n),
		},
		{
			title: t('billing.tax.valid_from'),
			key: 'valid_from',
			dataIndex: 'valid_from',
			width: '100px',
			render: (dt: Date) => dt && appSetting?.renderDate(dt),
			className: "filter__date"
		},
		{
			title: t('billing.tax.valid_to'),
			key: 'valid_to',
			dataIndex: 'valid_to',
			width: '100px',
			render: (dt: Date) => dt && appSetting?.renderDate(dt),
		},
		{
			title: 'Action',
			key: 'action',
			width: '100px',
			dataIndex: 'action',
			render: (_, record: TaxDetails) => (
				<Space size='small'>
					{
						isEditor && (
							<Button type='text' size='small'
									onClick={() => {
										setDataToUpdate(record)
										setModalVisible(true)
									}}
									icon={<EditTwoTone twoToneColor='green' />}
							/>
						)
					}

					{
						isEditor && (
								<Button type='text' danger size='small'
										className='actionButton'
										disabled={!!record.deleted_at}
										onClick={() => showConfirmDelete(record, t('billing.tax.confirm_delete'))}
										icon={<DeleteTwoTone twoToneColor='red'/>} />
						)
					}

					<Button title={t('general.btnHistory')} size='small'
							onClick={() => {
								setHistoryTitle(record.name)
								setHistoryModelId(record.id)
								setHistoryModalVisible(true)
							}}
							icon={<InfoCircleTwoTone />}
							className='actionButton'
					/>
				</Space>
			),
		},
	]
	return (
		<>
			<Card className='BillingTaxesPage'
				title={
					<Row>
						<Col span={6}>
							{`%  ${t('billing.tax.title')}`}
						</Col>
						<Col span={8} style={{textAlign: 'right', padding: '5px'}}>
							<span style={{marginRight: "10px"}}>{t('billing.tax.filter_data')}</span>
						</Col>
						<Col span={10}>
							<DateInput htmlId='__date_start'
									   initDateStr={appSetting.renderDateSQL(moment().toDate())}
									   disabled={false}
									   format={appSetting.date_picker_format} // not Moment formats!!
									   onChange={(date) => {
										   date ? setSearchDate(appSetting.renderDateSQL(date)) : setSearchDate(undefined)
									   }}
							/>
						</Col>
					</Row>
				}
				extra={
					<Button type='primary' disabled={!isEditor}
						onClick={() => {
							setDataToUpdate(undefined)
							setModalVisible(true)
						}}>
						<PlusCircleOutlined /> {t('billing.tax.create')}
					</Button>
				}
				>
				{
					appSetting &&
					<Table<TaxDetails>
						rowClassName={() => 'highlight'}
						bordered={true}
						columns={columns}
						loading={isLoading}
						dataSource={dataSource}
						className='BillingTaxesTable'
						rowKey='id'
						pagination={{
							defaultPageSize: appSetting?.grid_page_size,
							pageSizeOptions: PAGING,
							showSizeChanger: true
						}}
						onChange={(ev) => {setPageSize(`${ev.pageSize}`)}}
						footer={() => TotalNum(Number(dataSource?.length), 'Tax', dataSource)}
					/>
				}
			</Card>

			<Modal
				destroyOnClose={true}
				style={{top: 20}}
				title={
					<>
						<PercentageOutlined /> {dataToUpdate ? t('billing.tax.update_title') : t('billing.tax.create_title')}
					</>
				}
				visible={isModalVisible}
				onCancel={() => setModalVisible(false)}
				maskClosable={false}
				footer={null}>
				<TaxForm dataToUpdate={dataToUpdate} setModalVisible={setModalVisible} />
			</Modal>

			<HistoryModal service='billing' model='Tax'
						  modelId={historyModelId}
						  title={historyTitle}
						  isModalVisible={isHistoryModalVisible}
						  setModalVisible={() => setHistoryModalVisible(false)}
						  modalRender={(modal) => (
							  <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
								  <div ref={draggleRef}>{modal}</div>
							  </Draggable>
						  )}
			/>
		</>
	)
}

export default TaxesPage
