import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Col, Divider, Form, Input, message, Row, Spin, Tag} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {EndPointModel, EndpointParams, RoleModel} from "./models";
import {CheckboxValueType} from "antd/lib/checkbox/Group";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import getAllEndpoints from "./actions/getAllEndpoints";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import updateEndpoint from "./actions/updateEndpoint";

const CheckboxGroup = Checkbox.Group;

interface Props {
    setModalVisible: (param: boolean) => void
}

const {Item} = Form

const EditEndpointForm = ({setModalVisible}: Props) => {

    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {isSaving, endpoint, allEndPoints, role} = useSelector((state: AppState) => state.rolesPage)
    const [status, setStatus] = useState<boolean>(false)
    const [casbinKey, setCasbinKey] = useState<string>('')
    const [path, setPath] = useState<string>('')
    const [allMethods, setAllMethods] = useState<string[]>([])
    const [checkedMethods, setCheckedMethods] = useState<string[]>([])
    const [selectedEndPoint, setSelectedEndPoint] = useState<EndPointModel | undefined>()

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin />)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        if (!allEndPoints || allEndPoints.length === 0) {
            dispatch(getAllEndpoints())
        }
        if (role) {
            setCasbinKey(role.casbin_key)

        }
    }, [])

    useEffect(() => {
        // validate casbin_key
        if (endpoint) {
            setPath(endpoint.path)
            setCheckedMethods(endpoint.methods)
            //setCheckedList(endpoint.methods);
            if (role) {
                const ep = role.endpoints?.find(p => p.id === endpoint.id)
                setSelectedEndPoint(ep)
            }
        }
    }, [endpoint])

    useEffect(() => {
        if (selectedEndPoint) {
            setCheckedList(selectedEndPoint.methods);
        }
    }, [selectedEndPoint])

    useEffect(() => {
        if (path) {
            const epa = allEndPoints.find(ep => ep.path === path)
            epa && setAllMethods(epa.methods)
        }
    }, [path])

    useEffect(() => {
        if (allMethods && allMethods.length) {
            const epa = allEndPoints.find(ep => ep.path === path)
            epa && setAllMethods(epa.methods)
        }
    }, [allMethods])

    const onChangeMethods = (list: CheckboxValueType[]) => {
        setCheckedList(list)
        setIndeterminate(!!list.length && list.length < allMethods.length)
        setCheckAll(list.length === allMethods.length)
        setStatus(true)
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? allMethods : [])
        setIndeterminate(false)
        setCheckAll(e.target.checked)
        setStatus(true)
    };

    const onFormSubmit = (values: Store) => {
        // save data
        if (casbinKey && endpoint && endpoint.id) {
            const params: EndpointParams = {
                casbin_key: casbinKey,
                endpoint_id: endpoint.id,
                methods: checkedList.map(m => m.valueOf().toString())}
            dispatch(updateEndpoint(params, (isSuccess: boolean) => {
                    if (isSuccess) {
                        setModalVisible(false)
                        message.success(t('general.success'))
                    }
                }
            ))
        }
    }

    if (!role || !endpoint) {
        // role is required
        return (<Spin />)
    }

    return (
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
            initialValues={{}}
        >
            <Row>
                <Col span={8} className='right' style={{padding: '5px'}}>{ t('rights.roles.name')}:</Col>
                <Col span={16} style={{padding: '5px'}}><Tag color='blue' style={{fontSize: '1em'}}>{casbinKey}</Tag></Col>
            </Row>
            <Row>
                <Col span={8} className='right' style={{padding: '5px'}}>{ t('rights.roles.endpoint')}:</Col>
                <Col span={16} style={{padding: '5px'}}><Tag color='gold' style={{fontSize: '1em'}}>{path}</Tag></Col>
            </Row>

            {
                allMethods && allMethods.length > 1 &&
                <>
                    <Divider />
                    <Row style={{marginTop: '25px'}}>
                        <Col span={24} className='center'>
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                { t('rights.roles.check_all')}
                            </Checkbox>
                        </Col>
                    </Row>
                </>
            }

            <Row style={{marginTop: '25px'}}>
                <Col span={8} className='right' style={{padding: '5px'}}>{ t('rights.roles.methods')}:</Col>
                <Col span={16} className='left' style={{padding: '5px'}}>
                    <CheckboxGroup options={allMethods} value={checkedList} onChange={onChangeMethods} />
                </Col>
            </Row>

            <Divider />
            <Item {...tailLayout}>
                <Button loading={isSaving} type='primary' htmlType='submit' disabled={!status}>
                    { t('general.save')}
                </Button>
            </Item>

        </Form>
    )
}

export default EditEndpointForm
