import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {LoadingIndicator} from "../../../components";
import React, {useEffect, useRef, useState} from "react";
import {Card, Col, Input, message, Row, Space, Table, Tag, Tooltip} from "antd";
import {PlusCircleOutlined, SplitCellsOutlined} from "@ant-design/icons/lib/icons";
import Button from "antd-button-color";
import {
    DeleteTwoTone,
    EditTwoTone, InfoCircleOutlined,
    InfoCircleTwoTone,
    PlusCircleTwoTone,
    TrophyOutlined
} from "@ant-design/icons";
import {stopPropagation} from "../../../common/fce";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import PopConfirmZis from "../../../components/popconfirm/PopConfirmZis";
import {EndPointModel, RoleModel} from "./models";
import {AppState} from "../../../common/models";
import "./RolesPage.scss"
import Modal from "antd/lib/modal/Modal";
import NewRoleForm from "./NewRoleForm";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import getAllEndpoints from "./actions/getAllEndpoints";
import getRoles from "./actions/getRoles";
import {sort_name, sort_path} from "../../../common/sorting";
import {ColumnsType} from "antd/lib/table";
import deleteRole from "./actions/deleteRole";
import EditEndpointForm from "./EditEndpointForm";
import getRole from "./actions/getRole";
import getEndpoint from "./actions/getEndpoint";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import {getMethodColor, renderRole, renderRoleModel, renderSelected} from "../common";
import HistoryLog from "../../../components/History/HistoryLog";
import getHistoryLogs from "../../../components/History/actions/getHistoryLogs";
import EditRoleForm from "./EditRoleForm";
import {
    ROLE_ACCESS_MANAGER,
    ROLE_APP_ADMIN,
    ROLE_CUSTOMER_MANAGER,
    ROLE_USER_MANAGER
} from "../../../common/LoggedUser";


interface ParamTypes {
    role_key: string
}


const renderMethods = (rec: EndPointModel) => {
    const children = rec.methods.map( m => {return (<Tag color={getMethodColor(m)} key={m.toUpperCase()}>{m.toUpperCase()}</Tag>)})
    return (<>{children}</>)
}

const RolesPage = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const {role_key} = useParams<ParamTypes>()

    const {role, roles, endpoints, allEndPoints, isLoadingRole, isLoadingEP} = useSelector((state: AppState) => state.rolesPage)
    const {rights_roles} = useSelector((state: AppState) => state.sidenav)

    const [searchRole, setSearchRole] = useState<string>('');
    const [searchAllEP, setSearchAllEP] = useState<string>('');
    const [selectedRoleKey, setSelectedRoleKey] = useState<string>('');
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [forceUpdateHistoryId, setForceUpdateHistoryId] = useState<number>(1)

    const [dsRoles, setDsRoles] = useState<RoleModel[]>([]);
    const [dsEndPoints, setDsEndPoints] = useState<EndPointModel[]>([]);
    const [dsAllEndPoints, setDsAllEndPoints] = useState<EndPointModel[]>([]);
    const [isRoleModalVisible, setRoleModalVisible] = useState<boolean>(false);
    const [isRoleEditModalVisible, setRoleEditModalVisible] = useState<boolean>(false);
    const [isEndpointModalVisible, setEndpointModalVisible] = useState<boolean>(false);


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('RolesPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('RolesPage: ' + msg)} }

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    // permissions
    // required: ROLE_ACCESS_MANAGER | ROLE_APP_ADMIN
    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)

    useEffect(() => {
        // trigger ONCE
        const access_users = loggedUser.hasRole(ROLE_ACCESS_MANAGER) || loggedUser.hasRole(ROLE_APP_ADMIN)
        setViewer(access_users)
        setCreator(access_users)
        setEditor(access_users)
        setDeleter(access_users)
        if (!access_users) {
            // go to dashboard
            message.error(t('general.error403'))
            history.replace('/')
            return
        }

        dispatch(getAllEndpoints())
        dispatch(getRoles())
    }, [])

    useEffect(() => {
        // handle breadcrumb click
        if (role_key && role_key.length > 3) {
            // check QueryString
            dispatch(getRole(role_key))
            logger('role_key: '+ role_key)
        }
    }, [role_key])

    useEffect(() => {
        // trigger from Side menu clicked
        if (!isLoadingRole) {
            setSelectedRoleKey('')
            dispatch(getRoles())
        }
    }, [rights_roles])

    useEffect(() => {
        // update roles
        if (!isRoleModalVisible) {
            refreshRoles()
        }
    }, [roles, searchRole, isRoleModalVisible])

    useEffect(() => {
        // update all-endpoints
        if (!isRoleModalVisible) {
            refreshAllEndPoints()
        }
    }, [allEndPoints, searchAllEP, isEndpointModalVisible])

    useEffect(() => {
        // select role if changed
        if (role) {
            if (!selectedRoleKey) {
                if (role_key) {
                    setSelectedRoleKey(role_key)
                }
            }
            else {
                if (role.casbin_key != selectedRoleKey) {
                    setSelectedRoleKey(role.casbin_key)
                }
            }
        }
        else {
            setSelectedRoleKey('')
        }
    }, [role])

    useEffect(() => {
        // select role if changed
        refreshEndpoints()
    }, [endpoints])


    useEffect(() => {
        // handle selectedRoleKey
        if (selectedRoleKey) {
            history.replace('/rights/role/'+selectedRoleKey)
        }
    }, [selectedRoleKey])

    const filteredRole = () => {
        // search for pattern
        if (!roles) {
            return []
        }
        let data = roles.map(r => {return {...r, key: r.casbin_key}})
        if (searchRole && searchRole.length>SEARCH_MIN) {
            data = data.filter((r) => r.casbin_key?.includes(searchRole.toLowerCase()))
        }
        logger(`filtered roles: ${data.length}`)
        return data.sort(sort_name)
    }

    const getDesc = (key: string): string => {
        const role = roles.find(r => r.casbin_key === key)
        if (role && role.description) {
            return role.description
        }
        return ''
    }

    const filteredAllEP = () => {
        // search for pattern
        if (!allEndPoints) {
            return []
        }

        let data = allEndPoints
        const iid = endpoints.map(r => r.id)
        if (iid) {
            // filter out already selected
            data = data.filter((r) => !iid.includes(r.id))
        }

        if (searchAllEP && searchAllEP.length>SEARCH_MIN) {
            // search
            data = data.filter((r) => r.path?.includes(searchAllEP))
        }
        return data.sort(sort_path)
    }

    const refreshRoles = () => {
        setDsRoles(filteredRole())
    }

    const refreshEndpoints = () => {
        setDsEndPoints(endpoints.sort(sort_path))
    }

    const refreshAllEndPoints = () => {
        setDsAllEndPoints(filteredAllEP())
    }

    const onDeleteRole = () => {
        if (selectedRoleKey) {
            dispatch(deleteRole(selectedRoleKey, (suc) => suc && message.success(t('general.success'))))
            setSelectedRoleKey('')
        }
    }

    const FilterByRole = (
        <Input
            placeholder={t('rights.roles.name')}
            style={{ width: '100%' }}
            value={searchRole}
            allowClear={true}
            onClick={stopPropagation}
            onChange={(current) => {
                setSearchRole(current.target.value)
            }}
        />
    )


    const HeaderEP = (
        <div style={{ width: '100%', padding: '5px' }}>{t('rights.roles.url')}</div>
    )

    const FilterByEP = (
        <Input
            placeholder={t('rights.roles.all_name')}
            style={{ width: '100%' }}
            value={searchAllEP}
            allowClear={true}
            onClick={stopPropagation}
            onChange={(current) => {
                setSearchAllEP(current.target.value)
            }}
        />
    )

    const columnsRole = [
        {
            title: FilterByRole,
            dataIndex: 'casbin_key',
            key: 'casbin_key',
            width: '99%',
            className: 'td-blue tdOver',
            render: (_, rec: RoleModel) => renderRoleModel(rec, rec.casbin_key === selectedRoleKey, undefined)
        }
    ]

    const columnsEP: ColumnsType<EndPointModel> = [
        {
            title: HeaderEP,
            dataIndex: 'path',
            key: 'path',
            width: '60%',
            fixed: 'left',
            render: (_, rec) => (
                <Tag style={{fontSize: '1em'}} key={rec.id}>{rec.path}</Tag>
            )
        },
        {
            title: t('rights.roles.actions'),
            dataIndex: 'methods',
            key: 'methods',
            render: (_, rec: EndPointModel) => renderMethods(rec)
        },
        {
            title: '',
            key: 'action',
            width: '34px',
            dataIndex: 'action',
            align: 'right',
            fixed: 'right',
            render: (_, rec: EndPointModel) => (
                <Space>
                    <Button type='text'
                            size='small'
                            disabled={!selectedRoleKey}
                            onClick={() => {
                                if (selectedRoleKey && rec.id) {
                                    setEndpointModalVisible(true)
                                    dispatch(getEndpoint(rec.id))
                                }
                            }}
                            className='actionButton'
                            icon={<EditTwoTone twoToneColor='green' />}
                    />
                </Space>
            ),
        },
    ]

    const columnsALL: ColumnsType<EndPointModel> = [
        {
            title: FilterByEP,
            dataIndex: 'path',
            key: 'path',
            fixed: 'left',
            className: 'tdOver'
        },
        {
            title: '',
            key: 'action',
            width: '50px',
            dataIndex: 'action',
            fixed: 'right',
            render: (_, rec: EndPointModel) => (
                <Space size='small'>
                    <Button type='text'
                            size='small'
                            disabled={!selectedRoleKey}
                            onClick={() => {
                                if (selectedRoleKey && rec.id) {
                                    setEndpointModalVisible(true)
                                    dispatch(getEndpoint(rec.id))
                                }
                            }}
                            className='actionButton'
                            icon={selectedRoleKey ? <PlusCircleTwoTone /> : <PlusCircleTwoTone twoToneColor="#cccccc" />}
                    />
                </Space>
            ),
        },
    ]

    return (
        <>
            <Card className='RolesPage'
                  title={
                      <Row>
                          <Col span={8}>
                              <TrophyOutlined />{`  ${t('rights.roles.title')}`} &nbsp;
                              {selectedRoleKey && (
                                  <Tooltip title={getDesc(selectedRoleKey)}>{renderSelected(selectedRoleKey)}</Tooltip>
                              )} &nbsp;

                              {
                                  selectedRoleKey && (
                                      <Button title={t('general.rename')} size='small'
                                              onClick={() => {
                                                  if (selectedRoleKey) {
                                                      dispatch(getRole(selectedRoleKey))
                                                      setRoleEditModalVisible(true)
                                                  }
                                              }}
                                              icon={<EditTwoTone twoToneColor='green' />}
                                              className='actionButton'
                                      />
                                  )
                              }

                              {
                                  selectedRoleKey && role && (
                                      <Button title={t('general.btnHistory')} size='small'
                                              onClick={() => {
                                                  setHistoryTitle(`Role / ${role?.casbin_key}`)
                                                  role.id && setHistoryModelId(role.id)
                                                  role.id && setHistoryModalVisible(true)
                                                  setForceUpdateHistoryId(forceUpdateHistoryId)
                                              }}
                                              icon={<InfoCircleTwoTone />}
                                              className='actionButton'
                                      />
                                  )
                              }
                              {
                                  selectedRoleKey &&
                                  <PopConfirmZis title={t('rights.roles.delete_role_confirm')} onConfirm={() => {
                                      if (selectedRoleKey) {
                                          if (endpoints && endpoints.length > 0) {
                                              message.error('Role must be empty.')
                                              return
                                          }
                                          onDeleteRole()
                                      }
                                  }}>
                                      <Button type='text' danger size='small'
                                              disabled={!selectedRoleKey}
                                              title={t('rights.roles.delete_role')}
                                              className='actionButton'
                                              icon={<DeleteTwoTone twoToneColor='red' />}
                                      />
                                  </PopConfirmZis>
                              }
                          </Col>
                          <Col span={16}>&nbsp;
                          </Col>
                      </Row>
                  }
                  extra={
                      <Button type='primary'
                          onClick={() => {
                            setRoleModalVisible(true)
                          }}>
                          <PlusCircleOutlined/> {t('rights.roles.new_role')}
                      </Button>
                  }
            >

                <Row gutter={[8, 8]} style={{borderTop: '1px solid #dcdcdc'}}>
                    <Col span={4} className='panel' style={{marginRight: '0px'}}>
                        <Table<RoleModel>
                            rowClassName={(record) => selectedRoleKey === record.casbin_key ? 'tr-selected' : 'tr'}
                            bordered={true}
                            columns={columnsRole}
                            onRow={(rec, rowIndex) => {
                                return {
                                    onClick: (event) => {setSelectedRoleKey(rec.casbin_key)}, // click row
                                };
                            }}
                            loading={isLoadingRole}
                            dataSource={dsRoles}
                            className='roles-table'
                            rowKey='casbin_key'
                            scroll={{ x: 'none', y: 600 }}
                            pagination={false}
                            footer={() => (
                                <Row>
                                    <Col span={12}><span>{t('rights.roles.total')}: {dsRoles.length}</span></Col>
                                    <Col span={12}><div style={{float: 'right'}}>&nbsp;</div></Col>
                                </Row>
                            )}
                        />
                    </Col>
                    <Col span={14} className='panel' style={{marginRight: '0px'}}>
                            <Table<EndPointModel>
                                rowClassName={() => 'highlight'}
                                bordered={true}
                                columns={columnsEP}
                                loading={isLoadingEP}
                                dataSource={dsEndPoints}
                                className='endpoint-table'
                                rowKey='id'
                                scroll={{ y: 600 }}
                                style={{height: '600px'}}
                                pagination={false}
                                footer={() => (
                                    <Row>
                                        <Col span={12}><span>{t('rights.roles.total')}: {dsEndPoints.length}</span></Col>
                                        <Col span={12}><div style={{float: 'right'}}>&nbsp;</div></Col>
                                    </Row>
                                )}
                            />
                    </Col>
                    <Col span={6} className='panel' style={{marginRight: '0px'}}>
                        <Table<EndPointModel>
                            rowClassName={() => 'highlight'}
                            bordered={true}
                            columns={columnsALL}
                            loading={isLoadingEP}
                            dataSource={dsAllEndPoints}
                            className='all-endpoint-table'
                            rowKey='id'
                            scroll={{ y: 600 }}
                            pagination={false}
                            footer={() => (
                                <Row>
                                    <Col span={12}><span>{t('rights.roles.total')}: {dsAllEndPoints.length}</span></Col>
                                    <Col span={12}><div style={{float: 'right'}}>&nbsp;</div></Col>
                                </Row>
                            )}
                        />
                    </Col>
                </Row>

                <Row gutter={[8, 8]} style={{borderTop: '1px solid #dcdcdc'}}>
                    <Col span={6} className='panel' style={{marginRight: '0px'}}>

                    </Col>
                    <Col span={12} className='panel' style={{marginRight: '0px'}}>

                    </Col>
                    <Col span={6} className='panel' style={{marginRight: '0px'}}>

                    </Col>
                </Row>
            </Card>

            <Modal
                destroyOnClose={true}
                style={{top: 20}}
                title={
                    <>
                        <TrophyOutlined /> {t('rights.roles.new_role')}
                    </>
                }
                visible={isRoleModalVisible}
                onCancel={() => setRoleModalVisible(false)}
                maskClosable={false}
                footer={null}>
                <NewRoleForm  setModalVisible={setRoleModalVisible} />
            </Modal>

            <Modal
                destroyOnClose={true}
                style={{top: 20}}
                title={
                    <>
                        <TrophyOutlined /> {t('rights.roles.edit_role')}
                    </>
                }
                visible={isRoleEditModalVisible}
                onCancel={() => setRoleEditModalVisible(false)}
                maskClosable={false}
                footer={null}>
                <EditRoleForm  setModalVisible={setRoleEditModalVisible} />
            </Modal>

            <Modal
                destroyOnClose={true}
                style={{top: 20}}
                title={
                    <>
                        <TrophyOutlined /> {t('rights.roles.edit_endpoint')}
                    </>
                }
                visible={isEndpointModalVisible}
                onCancel={() => setEndpointModalVisible(false)}
                maskClosable={false}
                footer={null}>
                <EditEndpointForm  setModalVisible={setEndpointModalVisible} />
            </Modal>

            <Modal
                    destroyOnClose
                    style={{top: 50}}
                    bodyStyle={{height: '60%', minHeight: 450, padding: 2}}
                    width='60%'
                    className='historyLogModal'
                    title={ (<><InfoCircleOutlined />&nbsp;{t('general.history')}: {historyTitle}</>)}
                    visible={isHistoryModalVisible}
                    onCancel={() => setHistoryModalVisible(false)}
                    maskClosable={false}
                    modalRender={(modal) => (
                        <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                            <div ref={draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    footer={null}>

                    <HistoryLog isModal={true}
                                service='core'
                                model='Role'
                                modelId={historyModelId}
                                forceUpdateId={forceUpdateHistoryId}
                                showTitle={false} />
                </Modal>
        </>
    )
}

export default RolesPage