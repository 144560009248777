import {
    CreateInventoryLocationRequestAction,
    CreateInventoryLocationResponseAction,
    CreateInventoryLocationErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { BaseInventoryLocationModel, InventoryLocationModel } from '../models'

export default (
    params: BaseInventoryLocationModel,
    cb?: (isSuccess: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | CreateInventoryLocationRequestAction
                | CreateInventoryLocationResponseAction
                | CreateInventoryLocationErrorAction,
        ) => void,
    ) => {
        const request = new CreateInventoryLocationRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<InventoryLocationModel>('/inventory/location', params)
            .then((response) => {
                dispatch(new CreateInventoryLocationResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new CreateInventoryLocationErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
