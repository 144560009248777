import {
    GetDomainsRequestAction,
    GetDomainsResponseAction,
    GetDomainsErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DomainGrid} from '../models'

export default (pageSize: number, pageNumber: number, filter: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | GetDomainsRequestAction
                | GetDomainsResponseAction
                | GetDomainsErrorAction
        ) => void
    ) => {
        const request = new GetDomainsRequestAction()
        dispatch(request)

        protectedApiClient
            .get<DomainGrid>(`/domain?expand=services&${filter}&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`)
            .then((response) => {
                dispatch(new GetDomainsResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new GetDomainsErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
