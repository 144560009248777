import { AsyncActionMode } from 'common/models'
import {BaseInventoryLocationModel, InventoryLocationModel} from '../models'
import {BaseInventoryModel, InventoryModel} from "../../inventory/models";

export enum ActionType {
  GET_INVENTORY_LOCATIONS = 'GET_INVENTORY_LOCATIONS',
  CREATE_INVENTORY_LOCATION = 'CREATE_INVENTORY_LOCATION',
  UPDATE_INVENTORY_LOCATION = 'UPDATE_INVENTORY_LOCATION',
  DELETE_INVENTORY_LOCATION = 'DELETE_INVENTORY_LOCATION',
}

export type InventoryLocationActions =
    | GetInventoryLocationsRequestAction
    | GetInventoryLocationsResponseAction
    | GetInventoryLocationsErrorAction
    | CreateInventoryLocationRequestAction
    | CreateInventoryLocationResponseAction
    | CreateInventoryLocationErrorAction
    | UpdateInventoryLocationRequestAction
    | UpdateInventoryLocationResponseAction
    | UpdateInventoryLocationErrorAction
    | DeleteInventoryLocationRequestAction
    | DeleteInventoryLocationResponseAction
    | DeleteInventoryLocationErrorAction

export class GetInventoryLocationsRequestAction {
  readonly type = ActionType.GET_INVENTORY_LOCATIONS
  readonly mode = AsyncActionMode.REQUEST
  constructor() {
    ''
  }
}
export class GetInventoryLocationsResponseAction {
  readonly type = ActionType.GET_INVENTORY_LOCATIONS
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
    public request: GetInventoryLocationsRequestAction,
    public data: InventoryLocationModel[],
  ) {}
}
export class GetInventoryLocationsErrorAction {
  readonly type = ActionType.GET_INVENTORY_LOCATIONS
  readonly mode = AsyncActionMode.ERROR
  constructor(
    public request: GetInventoryLocationsRequestAction,
    public error: string,
  ) {}
}

export class CreateInventoryLocationRequestAction {
  readonly type = ActionType.CREATE_INVENTORY_LOCATION
  readonly mode = AsyncActionMode.REQUEST
  constructor(public payload: BaseInventoryLocationModel) {
  }
}

export class CreateInventoryLocationResponseAction {
  readonly type = ActionType.CREATE_INVENTORY_LOCATION
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: CreateInventoryLocationRequestAction,
      public data: InventoryLocationModel,
  ) {
  }
}

export class CreateInventoryLocationErrorAction {
  readonly type = ActionType.CREATE_INVENTORY_LOCATION
  readonly mode = AsyncActionMode.ERROR

  constructor(
      public request: CreateInventoryLocationRequestAction,
      public error: string,
  ) {
  }
}

export class UpdateInventoryLocationRequestAction {
  readonly type = ActionType.UPDATE_INVENTORY_LOCATION
  readonly mode = AsyncActionMode.REQUEST
  constructor(public model: InventoryLocationModel) {
  }
}

export class UpdateInventoryLocationResponseAction {
  readonly type = ActionType.UPDATE_INVENTORY_LOCATION
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: UpdateInventoryLocationRequestAction,
      public data: InventoryLocationModel,
  ) {
  }
}

export class UpdateInventoryLocationErrorAction {
  readonly type = ActionType.UPDATE_INVENTORY_LOCATION
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: UpdateInventoryLocationRequestAction,
      public error: string,
  ) {
  }
}

export class DeleteInventoryLocationRequestAction {
  readonly type = ActionType.DELETE_INVENTORY_LOCATION
  readonly mode = AsyncActionMode.REQUEST
  constructor(public id: number) {
    ''
  }
}

export class DeleteInventoryLocationResponseAction {
  readonly type = ActionType.DELETE_INVENTORY_LOCATION
  readonly mode = AsyncActionMode.RESPONSE
  constructor(
      public request: DeleteInventoryLocationRequestAction,
      public data: InventoryLocationModel,
  ) {
  }
}

export class DeleteInventoryLocationErrorAction {
  readonly type = ActionType.DELETE_INVENTORY_LOCATION
  readonly mode = AsyncActionMode.ERROR
  constructor(
      public request: DeleteInventoryLocationRequestAction,
      public error: string,
  ) {
  }
}
