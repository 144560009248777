import {
  ListSubnetIpRequestAction,
  ListSubnetIpResponseAction,
  ListSubnetIpErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {IpSubnetModel, SubnetGrid} from '../models'

export default (pageSize: number, pageNumber: number, filter: string, cb?: (isSuccess: boolean) => void) => {
  return (
    dispatch: (
      arg:
        | ListSubnetIpRequestAction
        | ListSubnetIpResponseAction
        | ListSubnetIpErrorAction,
    ) => void,
  ) => {
    const request = new ListSubnetIpRequestAction()
    dispatch(request)

    const url = `/ip/subnet?pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}&${filter}`
    protectedApiClient
      .get<SubnetGrid>(url)
      .then((response) => {
        dispatch(new ListSubnetIpResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new ListSubnetIpErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
