import React, {ReactNode, useEffect, useState} from 'react'
import {Card, Form, Input, Button, message, Row, Col, Radio, Tag, InputRef, Result} from 'antd'
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {Store} from 'antd/lib/form/interface'
import {useDispatch, useSelector} from 'react-redux'
import changePasswordAction from './actions/changePassword'
import {useHistory} from 'react-router'
import {AppState} from 'common/models'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import './UsersPage.scss'
import {useForm} from "antd/lib/form/Form";
import {AUTH_GA_NAME, AUTH_SMS_NAME} from "../login/LoginPage";
import {LoadingIndicator} from "../../components";
import SendConfirm from "../login/actions/sendConfirm";
import {Method2FA} from "../login/models";
import changePassword2faConfirm from "./actions/changePassword2faConfirm";


interface Props {
    setModalVisible: (visible: boolean) => void
    alwaysHook: number
}

const PasswordStates = {
    LOADING_APP: -1,
    CHANGE_PASS: 0,
    CHOOSE_METHOD: 1,
    SEND_SMS: 2,
    CONFIRM_CODE: 3,
    SUCCESS: 4
}

const PasswordChangeForm = ({setModalVisible, alwaysHook}: Props) => {
    const {t} = useTranslation()
    const [form] = useForm()
    const dispatch = useDispatch()
    const history = useHistory()
    const phoneRef: React.Ref<InputRef> = React.createRef()
    const codeRef: React.Ref<InputRef> = React.createRef()

    const {fontSize} = useSelector((state: AppState) => state.font)
    const {isLoading, user, response2fa} = useSelector((state: AppState) => state.user)

    const [pageNum, setPageNum] = useState<number>(0)
    const [optionsAllAuthentications, setOptionsAllAuthentications] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [selectedAuthKey, setSelectedAuthKey] = useState<string | undefined>('')
    const [selectedMethod, setSelectedMethod] = useState<string | undefined>('')
    const [isIdentVerified, setIdentVerified] = useState<boolean>(true)
    const [image2FA, setImage2FA] = useState<string | undefined>('')

    const [code, setCode] = useState<string | undefined>('')
    const [sentCode, setSentCode] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [serverMessage, setServerMessage] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [requestId, setRequestId] = useState<string>('')

    useEffect(() => {
        // this is called always after opening modal (open / close / open)
        if (form) {
            form.setFieldsValue({password_old: '', password: '', password_repeat: ''})
        }
        transitionTo(PasswordStates.CHANGE_PASS)
    }, [alwaysHook])

    useEffect(() => {

    }, [])

    useEffect(() => {
        // check auth_2fa
        if (response2fa && response2fa.reason) {
            console.log('useEffect[]: reason = '+ response2fa.reason)
            if (response2fa.reason === 'auth_2fa') {
                if (response2fa.methods && response2fa.methods.length) {
                    setSelectedAuthKey(undefined)
                    if (!handle2faMethodsOrExit(response2fa.methods)) {
                        // exit
                        return
                    }
                }
            }
            if (response2fa.reason === 'change-password') {
                if (response2fa.methods && response2fa.methods.length) {
                    setSelectedAuthKey(undefined)
                    if (!handle2faMethodsOrExit(response2fa.methods)) {
                        // exit
                        return
                    }
                }
            }
            if (response2fa.reason === 'change-password-confirmed') {
                transitionTo(PasswordStates.SUCCESS)
            }
        }

    }, [response2fa, response2fa?.reason, response2fa?.methods])

    useEffect(() => {
        console.log('useEffect[]: pageNum = '+pageNum)
        if (phoneRef.current) {
            phoneRef.current.focus();
        }
        if (codeRef.current) {
            codeRef.current.focus();
        }
    }, [pageNum])

    useEffect(() => {
        if (selectedAuthKey) {
            const method = get2faMethod(selectedAuthKey)
            method && setSelectedMethod(method.method)
            method && setIdentVerified(true)
            setCode('')
        }
    }, [selectedAuthKey])


    const handle2faMethodsOrExit = (methods: Method2FA[]): boolean => {
        console.log('handle2faMethodsOrExit..')
        setRequestId('')
        setOptionsAllAuthentications(methods.map(a2 => ({label: t('Force2FA.method_'+a2.method), value: a2.request})))
        setServerMessage('')
        if (methods && methods.length === 0) {
            // error - missing methods
            console.error('2FA - missing methods')
            //setModalVisible(false)
            return false
        }
        if (methods && methods.length === 1) {
            const method: Method2FA = methods[0]
            if (!method) {
                // error - missing methods
                console.error('2FA - missing methods')
                setModalVisible(false)
                return false
            }

            setSelectedAuthKey(method.request)
            // ident verified => 2FA auth => confirm
            if (method.method === AUTH_SMS_NAME) {
                // ident verified => 2FA auth => confirm
                transitionTo(PasswordStates.SEND_SMS)
            }
            else {
                transitionTo(PasswordStates.CONFIRM_CODE)
            }

            if (selectedMethod != method.method) {
                setSelectedMethod(method.method)
            }
            return false
        }
        if (methods && methods.length > 1) {
            let isVerified = false
            methods.forEach(m => {
                if (m.is_ident_verified === 1) {
                    isVerified = true
                }
            })
            setIdentVerified(isVerified)
            if (!selectedAuthKey) {
                transitionTo(PasswordStates.CHOOSE_METHOD)
                return false
            }
            console.log('selectedAuthKey..'+selectedAuthKey)
            const method = get2faMethod(selectedAuthKey)
            if (!method) {
                console.error('2FA - selectedAuthKey method not found')
                setModalVisible(false)
                return false
            }
            setSelectedMethod(method.method)
            // ident verified => 2FA auth => confirm
            if (method.method === AUTH_SMS_NAME) {
                // ident verified => 2FA auth => confirm
                transitionTo(PasswordStates.SEND_SMS)
            }
            else {
                transitionTo(PasswordStates.CONFIRM_CODE)
            }
        }
        return true
    }
    
    const onFinish = (values: Store): void => {
        dispatch(
            changePasswordAction(
                {
                    password_old: values.password_old,
                    password: values.password,
                    password_repeat: values.password_repeat
                },
                (suc) => {
                    if (form) {
                        form.setFieldsValue({password_old: '', password: '', password_repeat: ''})
                    }
                }))
    }

    const handleSelectedMethod = () => {
        setCode('')
        if (selectedAuthKey) {
            const method = get2faMethod(selectedAuthKey)
            if (method && method.method === AUTH_SMS_NAME) {
                // ident verified => 2FA auth => confirm
                transitionTo(PasswordStates.SEND_SMS)
            }
            else {
                transitionTo(PasswordStates.CONFIRM_CODE)
            }
        }
    }

    const handleSendCode = () => {
        if (selectedAuthKey) {
            const params = {request: selectedAuthKey}
            dispatch(SendConfirm(params, suc => {
                if (suc) {
                    setCode('')
                    setSentCode(true)
                    transitionTo(PasswordStates.CONFIRM_CODE)
                }
            }))
        }
    }

    const handleConfirmCode = () => {
        if (code && selectedAuthKey) {
            const params = {request: selectedAuthKey, confirm: code}
            dispatch(changePassword2faConfirm(params, suc => {
                suc && clear()
                setIsSuccess(suc)
            }))
        }
    }

    const clear = () => {
        setServerMessage('')
        setCode('')
        setSentCode(false)
    }

    const onCodeChange = (v: string) => {
        setCode(v)
    }

    const get2faMethod = (req: string): Method2FA | undefined => {
        return response2fa!.methods!.find(m => m.request === req)
    }

    const showMsg = () => {
        let s = serverMessage.replaceAll('<strong>', '').replaceAll('</strong>', '')
        s = s.replace('DEBUG', '')
        const arr = s.split('DEBUG')
        return (<span>{arr[0]}<br/>{arr[1]}</span>)
    }

    const transitionTo = (page: number) => {
        if (page === PasswordStates.CHOOSE_METHOD) {
            setSelectedAuthKey('')
            setCode('')
            setIsSuccess(false)
            let isVerified = false
            if (response2fa && response2fa.methods) {
                response2fa.methods.forEach(m => {
                    if (m.is_ident_verified === 1) {
                        isVerified = true
                    }
                })
            }
            setIdentVerified(isVerified)
        }
        setPageNum(page)
    }
    
    const renderChangePassword = () => {
        return (
            <div className='changePassword'>

                <Form name='change_password'
                      form={form}
                      className='change-password-form'
                      initialValues={{password_old: '', password: '', password_repeat: ''}}
                      onFinish={onFinish}
                      {...formItemLayout}
                >
                    <Form.Item name='password_old'
                               rules={[{required: true, message: t('changePasswordPage.err_old_password')}]}
                               label={t('changePasswordPage.old_password')}
                    >
                        <Input.Password
                            autoComplete="off"
                            data-gramm="false"
                            data-gramm_editor="false"
                            data-enable-grammarly="false"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            size={fontSize}/>
                    </Form.Item>

                    <Form.Item name='password'
                               rules={[
                                   {required: true, message: t('changePasswordPage.err_new_password')},
                                   {min: 5, message: t('changePasswordPage.err_password_length', {count: 5})},
                                   // { pattern: mediumRegex, message: t('changePasswordPage.err_password_mediumRegex') }
                               ]}
                               label={t('changePasswordPage.new_password')}
                    >
                        <Input.Password
                            autoComplete="off"
                            data-gramm="false"
                            data-gramm_editor="false"
                            data-enable-grammarly="false"
                            size={fontSize}/>
                    </Form.Item>

                    <Form.Item name='password_repeat'
                               label={t('changePasswordPage.repeat_new_password')}
                               rules={[
                                   {
                                       required: true,
                                       message: t('changePasswordPage.err_repeat_password'),
                                   },
                                   ({getFieldValue}) => ({
                                       validator(rule, value) {
                                           if (!value || getFieldValue('password') === value) {
                                               return Promise.resolve()
                                           }
                                           return Promise.reject(t('changePasswordPage.err_repeat_not_match'))
                                       },
                                   }),
                               ]}
                    >
                        <Input.Password
                            autoComplete="off"
                            data-gramm="false"
                            data-gramm_editor="false"
                            data-enable-grammarly="false"
                            size={fontSize}/>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type='primary' htmlType='submit' className='login-form-button' size={fontSize}>
                            {t('changePasswordPage.change')}
                        </Button>
                    </Form.Item>
                </Form>

            </div>
        )
    }

    const renderChooseMethod = () => {
        return (
            <Card className='LoginPage' style={{border: 0}}>
                <Row>
                    <Col span={24} style={{textAlign: 'center', padding: '15px'}}>
                        <h1>{t('Force2FA.title')}</h1><br/>
                        <h2>{t('Force2FA.select_method')}</h2><br/>
                        {
                            response2fa!.methods && response2fa!.methods.length > 1 && (
                                <div style={{width: '320px', margin: '0 40%', textAlign: 'left'}}>
                                    <Radio.Group
                                        options={optionsAllAuthentications}
                                        onChange={(v) => {
                                            setSelectedAuthKey(v.target.value)
                                        }}
                                        className='select2fa'
                                        value={selectedAuthKey}
                                        style={{display: 'block'}}
                                    />
                                </div>
                            )
                        }
                        {
                            response2fa!.methods && response2fa!.methods.length === 1 && (<span>{response2fa!.methods[0].method}</span>)
                        }
                        {
                            !response2fa!.methods || response2fa!.methods.length === 0 && (<Tag color='error'>{t('Force2FA.method_not_found')}</Tag>)
                        }
                    </Col>
                    <Col span={24} style={{textAlign: 'center', padding: '15px'}}>
                        <Button
                            type='primary'
                            loading={isLoading}
                            onClick={() => {
                                selectedAuthKey && handleSelectedMethod()
                            }}
                            className='login-form-button'
                            style={{margin: '15px'}}
                            size={fontSize}>
                            {t('general.continue')}
                        </Button>
                    </Col>
                </Row>

                <Row style={{marginTop: '35px'}}>
                    <Col span={24}  style={{textAlign: 'center'}}>{errorMessage}&nbsp;</Col>
                </Row>
            </Card>
        )}

    const renderSendSms = () => {
        return (
            <Card style={{border: 0}} className='LoginPage'>
                <Row>
                    <Col span={24}  style={{textAlign: 'center', color: '#cccccc', marginTop: '20px'}}>
                        {
                            selectedMethod === AUTH_SMS_NAME && (
                                <h2>{t('Force2FA.method_'+AUTH_SMS_NAME)}</h2>
                            )
                        }
                        {
                            selectedMethod === AUTH_GA_NAME && (
                                <h2>{t('Force2FA.method_'+AUTH_GA_NAME)}</h2>
                            )
                        }
                    </Col>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <span style={{fontSize: '1.2em'}}>{t('Force2FA.we_send_sms')}</span>
                    </Col>
                </Row>

                <Row style={{marginTop: '35px'}}>
                    <Col span={24}  style={{textAlign: 'center'}}>
                        <Button
                            type='primary'
                            loading={isLoading}
                            onClick={() => handleSendCode()}
                            className='login-form-button'
                            style={{margin: '15px'}}
                            size={fontSize}>
                            {t('Force2FA.send')}
                        </Button>
                    </Col>
                </Row>


                <div style={{margin: '25px 40%'}}>
                    <Button type='link' size='small'
                            style={{fontSize: '0.8em', display: (response2fa!.methods && response2fa!.methods.length>1) ? 'block' : 'none'}}
                            onClick={() => {transitionTo(PasswordStates.CHOOSE_METHOD)}}> {t('Force2FA.select_method_btn')} </Button>

                </div>

            </Card>
        )
    }

    const renderConfirmCode = () => {
        return (
            <Card style={{border: 0}} className='LoginPage'>

                <Row>
                    <Col span={24}  style={{textAlign: 'center', color: '#cccccc'}}>
                        {
                            selectedMethod === AUTH_SMS_NAME && (
                                <h2>{t('Force2FA.method_'+AUTH_SMS_NAME)}</h2>
                            )
                        }
                        {
                            selectedMethod === AUTH_GA_NAME && (
                                <h2>{t('Force2FA.method_'+AUTH_GA_NAME)}</h2>
                            )
                        }
                    </Col>
                </Row>

                <Row>
                    <Col span={24} style={{textAlign: 'center', marginTop: '25px'}}>
                        <h2>{t('Force2FA.sub_title')}</h2>
                    </Col>
                </Row>

                <Row style={{marginTop: '25px'}}>
                    <Col span={24}  style={{textAlign: 'center'}}>
                        <Input type='text' size='large' name='code'
                               ref={codeRef}
                               value={code}
                               defaultValue={code}
                               onChange={(el) => onCodeChange(el.target.value)}
                               onPressEnter={(e) => handleConfirmCode()}
                               autoComplete='off'
                               data-gramm="false"
                               style={{width: '120px'}} />
                    </Col>
                </Row>

                <Row style={{marginTop: '25px'}}>
                    <Col span={24}  style={{textAlign: 'center'}}>
                        <Button
                            type='primary'
                            loading={isLoading}
                            onClick={() => handleConfirmCode()}
                            disabled={!code || code.length < 5}
                            className='login-form-button'
                            style={{margin: '25px'}}
                            size={fontSize}>
                            {t('Force2FA.confirm')}
                        </Button>
                        <div>
                            {
                                response2fa && response2fa.methods && response2fa.methods.length > 1 && (
                                    <Button type='link' size='small'
                                            style={{fontSize: '0.8em'}}
                                            onClick={() => {
                                                if (isIdentVerified) {
                                                    if (selectedMethod === AUTH_SMS_NAME) {
                                                        transitionTo(PasswordStates.SEND_SMS)
                                                    }
                                                    if (selectedMethod === AUTH_GA_NAME) {
                                                        transitionTo(PasswordStates.CHOOSE_METHOD)
                                                    }
                                                }
                                            }}> {t('Force2FA.select_method_btn')} </Button>
                                )
                            }

                        </div>
                    </Col>
                </Row>
            </Card>
        )
    }

    const renderLoadingPage = () => {
        return (
            <Card className='LoginPage' style={{height: '500px', border: 0}}>
                <Row>
                    <Col span={24} style={{textAlign: 'center', padding: '50px'}}></Col>
                </Row>
                <Row>
                    <Col span={24} style={{textAlign: 'center', padding: '50px'}}>
                        <LoadingIndicator background />
                    </Col>
                </Row>
            </Card>
        )
    }

    const renderSuccessPage = () => {
        return (
            <Card className='LoginPage' style={{height: '500px', border: 0}}>
                <Row>
                    <Col span={24} style={{textAlign: 'center', padding: '50px'}}>
                        <Result
                            status="success"
                            title={t('changePasswordPage.changed')}
                            subTitle=""
                            extra={<Button onClick={() => setModalVisible(false)}>{t('general.close')}</Button>}
                        />
                    </Col>
                </Row>
            </Card>
        )
    }

    if (isLoading) {
        return renderLoadingPage()
    }


    // render
    switch(pageNum) {
        case PasswordStates.CHANGE_PASS:
            return renderChangePassword()

        case PasswordStates.CHOOSE_METHOD:
            return renderChooseMethod()

        case PasswordStates.SEND_SMS:
            return renderSendSms()

        case PasswordStates.CONFIRM_CODE:
            return renderConfirmCode()

        case PasswordStates.SUCCESS:
            return renderSuccessPage()

        default:
            return renderLoadingPage()
    }

}

export default PasswordChangeForm
