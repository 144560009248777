import {AsyncActionMode} from 'common/models'

export enum ActionType {
    TOGGLE_MENU = 'TOGGLE_MENU'
}

export type ToggleMenuActions = ToggleMenuResponseAction

export class ToggleMenuResponseAction {
    readonly type = ActionType.TOGGLE_MENU
    readonly mode = AsyncActionMode.RESPONSE
    constructor(public path: string) {}
}
