import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {Button, Col, Divider, Form, Input, InputRef, message, Row, Select, Space, Spin, TreeSelect} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {AppState} from 'common/models'
import {formItemLayout} from 'helpers/layoutHelpers'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {CreatePhysicalServerParams, PhysicalServerModel} from "./models";
import TextArea from "antd/lib/input/TextArea";
import createPhysicalServer from "./actions/createPhysicalServer";
import getNewServers from "./actions/getNewServers";
import getTypes from "../type/actions/getTypes";
import {InventoryModel} from "../inventory/models";
import getInventoryPageLocations from "../inventory/actions/getInventoryPageLocations";
import {removeDiac, stopPropagation} from "../../../common/fce";
import {sort_id, sort_name} from "../../../common/sorting";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {renderInventoryItem} from "../common";


interface Props {
    dataToUpdate?: PhysicalServerModel
    setModalVisible: (param: boolean) => void
}

const {Item} = Form

const CreateServerForm = ({setModalVisible}: Props) => {
    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const inputRef = useRef<InputRef>(null)

    const {locations} = useSelector((state: AppState) => state.inventorylocation)
    const {newServers, isSaving} = useSelector((state: AppState) => state.inventoryphysicalserver)
    const {inventory_types} = useSelector((state: AppState) => state.inventorytype)

    const [selectedInventory, setSelectedInventory] = useState<number | undefined>(undefined)
    const [identValue, setIdentValue] = useState<string>('')
    const [confValue, setConfValue] = useState<string>('')
    const [dataSource, setDataSource] = useState<{value: number, title: string | ReactNode, name: string, children: any[]}[]>([]);
    const [items, setItems] = useState<{value: number, title: string | ReactNode, name: string, children: any[]}[]>([]);
    const [serial, setSerial] = useState('')

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    useEffect(() => {
        if (!inventory_types || inventory_types.length === 0) {
            dispatch(getTypes())
        }
        if (!locations || locations.length === 0) {
            dispatch(getInventoryPageLocations())
            return
        }
        dispatch(getNewServers())
    }, [inventory_types, locations])

    useEffect(() => {
        if (newServers) {
            // render select dropdown
            const arr: {value: number, title: string | ReactNode, name: string, children: any[]}[] = []
            for (const item of newServers.sort(sort_id)) {
                arr.push({value: item.id, title: renderInventoryItem(item), name: `${item.name}|${item.serial}`, children: []})
            }
            setItems(arr)
        }
    }, [newServers])

    useEffect(() => {
        refreshGridItems()
    }, [serial, items])

    const onSerialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSerial(event.target.value);
    }

    const checkMinSearch = (val: string | undefined) => {
        if (!val) {
            return false
        }
        return val.length > SEARCH_MIN
    }

    const refreshGridItems = () => {
        setDataSource(filtered().sort((a, b) => sort_name(b, a)))
    }

    const filtered = () => {
        if (!items || items.length === 0) {
            return []
        }

        let data = items
        if (checkMinSearch(serial)) {
            // inventory
            const qName = (s: {value: number, title: string | ReactNode, name: string, children: any[]}) => removeDiac(s.name).includes(removeDiac(serial))
            data = data.filter((c) => qName(c))
        }
        return data
    }

    const onFormSubmit = (values: Store) => {
        if (selectedInventory && selectedInventory > 0) {
            const item: CreatePhysicalServerParams = {
                ident: values.ident,
                inventory_id: selectedInventory,
                conf: values.conf
            }
            dispatch(createPhysicalServer(item, (suc: boolean) => {
                if (suc) {
                    setModalVisible(false)
                    message.info(t('general.success'))
                }
            }))
        }
    }

    const onChange = (newValue: number | undefined) => {
        if (newValue && newValue < 1000000) {
            setSelectedInventory(newValue);
        }
        else {
            setSelectedInventory(undefined);
        }
    }

    const isFormValid = () => {
        if (!selectedInventory) {
            return false
        }
        if (selectedInventory > 1000000) {
            return false
        }
        return true
    }

    return (
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
        >
            <Row>
                <Col span={24}>
                    <Item name='ident' labelCol={{span: 4}} wrapperCol={{span: 20}} label={t('physicalServersPage.ident')} >
                        <Input style={{width: '120px', fontWeight: 'bold'}} value={identValue} onChange={(e) => setIdentValue(e.target.value)} />
                    </Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Item name='conf' labelCol={{span: 4}} wrapperCol={{span: 20}} label={t('physicalServersPage.conf')} >
                        <TextArea value={confValue}
                                  data-gramm="false"
                                  data-gramm_editor="false"
                                  data-enable-grammarly="false"
                                  onChange={(e) => setConfValue(e.target.value)} />
                    </Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Item name='inventory_id' labelCol={{span: 4}} wrapperCol={{span: 20}} label={t('physicalServersPage.inventory')} >
                        <Select
                            style={{ width: '100%' }}
                            className='selectComponent80'
                            placement='topLeft'
                            dropdownStyle={{ maxHeight: 600, overflow: 'auto' }}
                            placeholder={t('physicalServersPage.inventory_select')}
                            allowClear
                            showSearch
                            dropdownRender={menu => (
                                <>
                                    <Space style={{ padding: '0 8px 4px' }}>
                                        <span className='text-disabled'>Filter: </span>
                                        <Input
                                            placeholder="Serial"
                                            ref={inputRef}
                                            value={serial}
                                            onChange={onSerialChange}
                                        />
                                        <span className='text-disabled'>({dataSource.length} items)</span>
                                    </Space>
                                    <Divider style={{ margin: '8px 0' }} />
                                    {menu}
                                </>
                            )}
                            onClick={stopPropagation}
                            onChange={onChange}
                            options={dataSource.map(item => ({ label: item.title, value: item.value }))}
                        />

                    </Item>
                </Col>
            </Row>

            <Row>
                <Col span={4}>&nbsp;</Col>
                <Col span={20}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Button type='primary' htmlType='submit' disabled={!isFormValid()} loading={isSaving}>
                            {t('general.save')}
                        </Button>
                    </Space>
                </Col>
            </Row>

        </Form>
    )
}

export default CreateServerForm
