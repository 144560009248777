import {
    LogoutAsUserResponseAction,
    LogoutAsUserErrorAction,
    LogoutAsUserRequestAction,
} from '.'
import {AuthState, LoginAsParams, LogoutAsParams} from '../models'
import {protectedApiClient } from 'helpers/api'
import {handleApiErrorWithNotification } from 'helpers/errorHandling'

export default (params: LogoutAsParams, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | LogoutAsUserRequestAction
                | LogoutAsUserResponseAction
                | LogoutAsUserErrorAction,
        ) => void,
    ) => {
        const request = new LogoutAsUserRequestAction(params)
        dispatch(request)
        protectedApiClient
            .post<AuthState>(`/user/logout-as`, params)
            .then((response) => {
                dispatch(new LogoutAsUserResponseAction(request))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new LogoutAsUserErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
