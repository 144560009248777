import { AsyncActionMode } from 'common/models'
import {
    CreateBillAttachmentParams,
    BillAttachmentDetails,
    BillAttachmentError,
} from '../models'

export enum ActionType {
    CREATE_BILL_ATTACHMENT = 'CREATE_BILL_ATTACHMENT',
    GET_BILL_ATTACHMENTS = 'GET_BILL_ATTACHMENTS',
    GET_BILL_ATTACHMENT = 'GET_BILL_ATTACHMENT',
    DELETE_BILL_ATTACHMENT = 'DELETE_BILL_ATTACHMENT',
}

export type BillAttachmentActions =
    | CreateBillAttachmentRequestAction
    | CreateBillAttachmentResponseAction
    | CreateBillAttachmentErrorAction
    | GetBillAttachmentsRequestAction
    | GetBillAttachmentsResponseAction
    | GetBillAttachmentsErrorAction
    | GetBillAttachmentRequestAction
    | GetBillAttachmentResponseAction
    | GetBillAttachmentErrorAction
    | DeleteBillAttachmentRequestAction
    | DeleteBillAttachmentResponseAction
    | DeleteBillAttachmentErrorAction

export class CreateBillAttachmentRequestAction {
    readonly type = ActionType.CREATE_BILL_ATTACHMENT
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: CreateBillAttachmentParams) {}
}
export class CreateBillAttachmentResponseAction {
    readonly type = ActionType.CREATE_BILL_ATTACHMENT
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: CreateBillAttachmentRequestAction,
        public data: BillAttachmentDetails,
    ) {}
}
export class CreateBillAttachmentErrorAction {
    readonly type = ActionType.CREATE_BILL_ATTACHMENT
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: CreateBillAttachmentRequestAction,
        public error: BillAttachmentError,
    ) {}
}

export class GetBillAttachmentRequestAction {
    readonly type = ActionType.GET_BILL_ATTACHMENT
    readonly mode = AsyncActionMode.REQUEST
    constructor(public payload: number) {
        ''
    }
}
export class GetBillAttachmentResponseAction {
    readonly type = ActionType.GET_BILL_ATTACHMENT
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetBillAttachmentRequestAction,
        public data: BillAttachmentDetails,
    ) {}
}
export class GetBillAttachmentErrorAction {
    readonly type = ActionType.GET_BILL_ATTACHMENT
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetBillAttachmentRequestAction,
        public error: BillAttachmentError,
    ) {}
}

export class GetBillAttachmentsRequestAction {
    readonly type = ActionType.GET_BILL_ATTACHMENTS
    readonly mode = AsyncActionMode.REQUEST
    constructor() {
        ''
    }
}
export class GetBillAttachmentsResponseAction {
    readonly type = ActionType.GET_BILL_ATTACHMENTS
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: GetBillAttachmentsRequestAction,
        public data: BillAttachmentDetails[],
    ) {}
}
export class GetBillAttachmentsErrorAction {
    readonly type = ActionType.GET_BILL_ATTACHMENTS
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: GetBillAttachmentsRequestAction,
        public error: BillAttachmentError,
    ) {}
}

export class DeleteBillAttachmentRequestAction {
    readonly type = ActionType.DELETE_BILL_ATTACHMENT
    readonly mode = AsyncActionMode.REQUEST
    constructor(public id: number) {
        ''
    }
}
export class DeleteBillAttachmentResponseAction {
    readonly type = ActionType.DELETE_BILL_ATTACHMENT
    readonly mode = AsyncActionMode.RESPONSE
    constructor(
        public request: DeleteBillAttachmentRequestAction,
        public data: BillAttachmentDetails,
    ) {}
}
export class DeleteBillAttachmentErrorAction {
    readonly type = ActionType.DELETE_BILL_ATTACHMENT
    readonly mode = AsyncActionMode.ERROR
    constructor(
        public request: DeleteBillAttachmentRequestAction,
        public error: BillAttachmentError,
    ) {}
}
