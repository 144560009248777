import {
    ConfirmVerificationCodeRequestAction,
    ConfirmVerificationCodeResponseAction,
    ConfirmVerificationCodeErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { UserVerificationModel} from '../models'


export default (params: UserVerificationModel, cb?: (suc: boolean) => void,
) => {
    return (
        dispatch: (
            arg:
                | ConfirmVerificationCodeRequestAction
                | ConfirmVerificationCodeResponseAction
                | ConfirmVerificationCodeErrorAction,
        ) => void,
    ) => {
        const request = new ConfirmVerificationCodeRequestAction(params)
        dispatch(request)

        protectedApiClient
            .put<UserVerificationModel>(`/user-verification/${params.id}/confirm`, params)
            .then((response) => {
                dispatch(new ConfirmVerificationCodeResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new ConfirmVerificationCodeErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
