import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from "../../common/models";
import {SettingsDetails} from "./models";
import './SettingsPage.scss';
import {useTranslation} from "react-i18next";
import {Card, Col, Input, Row, Space, Spin, Table, Tag} from "antd";
import Button from "antd-button-color";
import {PAGING} from "../../common/enums";
import TotalNum from "../../components/TotalNum/TotalNum";
import {
    BorderOutlined,
    CheckSquareFilled,
    EditTwoTone,
    LockTwoTone,
    SettingOutlined,
    UnlockTwoTone
} from "@ant-design/icons";
import {ShopOutlined} from "@ant-design/icons/lib/icons";
import Modal from "antd/lib/modal/Modal";
import loggedUser from "../../common/LoggedUser";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import SettingForm from "./components/SettingForm";
import {LoadingIndicator} from "../../components";
import updateSetting from "../login/actions/updateSetting";
import getSettingsSelf from "../login/actions/getSettingsSelf";
import {stopPropagation} from "../../common/fce";
import {renderScope} from "../user/SettingItem";
import usePageSize from "../../common/usePageSize";


enum Scope {
    APP = 'app',
    USER = 'user',
    CUSTOMER = 'customer'
}

// sorting
const sort_name = (a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1


const SettingsPage = props => {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const {self_settings, isLoading} = useSelector((state: AppState) => state.auth)

    const [dataSource, setDataSource] = useState<SettingsDetails[]>()
    const [pageSize, setPageSize] = useState<string>()
    const [searchName, setSearchName] = useState('')
    const [isModalVisible, setModalVisible] = useState(false)
    const [dataToUpdate, setDataToUpdate] = useState<SettingsDetails>()

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }
    const appSetting = loggedUser.getAppSettings()
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('SettingsPage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('SettingsPage: ' + msg)} }

    // permissions
    const [editApp, setEditApp] = useState(false)
    const [editCustomer, setEditCustomer] = useState(false)
    useEffect(() => {
        logger('')
        dispatch(getSettingsSelf())
        // when user logged => set permissions
        setEditApp(loggedUser.hasRole('zcom-admin'))
        setEditCustomer(loggedUser.hasRole('billing-customer'))
    }, [])

    useEffect(() => {
        // update datasource when data was changed
        if (!isModalVisible) {
            refreshGrid()
        }
    }, [self_settings, isModalVisible, searchName])

    const filtered = (name: string, scope: string) => {
        if (!self_settings) {
            return []
        }

        let data = self_settings.filter((t) => t.name.toLowerCase().includes(name.toLowerCase()))
        if (!editCustomer && !editApp) {
            // hide for level: user
            const userNames2 = data.filter(s => s.scope === 'user').map(s => s.name.toLowerCase())
            data = data.filter(s => !(s.scope === 'customer' && userNames2.includes(s.name.toLowerCase())))
            const custNames2 = data.filter(s => (s.scope === 'user' || s.scope === 'customer')).map(s => s.name.toLowerCase())
            data = data.filter(s => !(s.scope === 'app' && custNames2.includes(s.name.toLowerCase())))
            data = data.filter(s => s.hidden === 0)
        }
        else if (editCustomer && !editApp) {
            // level: customer
            const userNames2 = data.filter(s => s.scope === 'user').map(s => s.name.toLowerCase())
            data = data.filter(s => !(s.scope === 'customer' && userNames2.includes(s.name.toLowerCase())))
            const custNames2 = data.filter(s => (s.scope === 'user' || s.scope === 'customer')).map(s => s.name.toLowerCase())
            data = data.filter(s => !(s.scope === 'app' && custNames2.includes(s.name.toLowerCase())))
            data = data.filter(s => !(s.scope === 'customer' && s.hidden === 1))
        }
        else if (editApp) {
            // App
            // not hiding
            const userNames2 = data.filter(s => s.scope === 'user').map(s => s.name.toLowerCase())
            data = data.filter(s => !(s.scope === 'customer' && userNames2.includes(s.name.toLowerCase())))
            const custNames2 = data.filter(s => (s.scope === 'user' || s.scope === 'customer')).map(s => s.name.toLowerCase())
            data = data.filter(s => !(s.scope === 'app' && custNames2.includes(s.name.toLowerCase())))
            // unfreeze
            data = data.map( (s) => ({...s, frozen: 0}))
        }

        if (editCustomer || editApp) {
            // unfreeze for customer and app
            data = data.map( (s) => ({...s, frozen: 0}))
        }

        logger(`filtered settings: ${self_settings?.length}, dataSource: ${data?.length}`)
        return data
    }

    const refreshGrid = () => {
        const newData = filtered(searchName, 'app').sort(sort_name)
        setDataSource(newData)
    }

    const onDelete = (sid) => {

    }



    const FilterByNameInput = self_settings && (
        <Input placeholder={t('settingsPage.name')} value={searchName} onClick={stopPropagation}
               onChange={e => {setSearchName(e.target.value)}}
        />
    )

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            className: 'center',
            width: '30px',
        },
        {
            title: t('settingsPage.group'),
            dataIndex: 'group',
            key: 'group',
            className: 'left',
            width: 100,
        },
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            sorter: (a, b) => sort_name(a, b),
            render: (text) => (<b>{text}</b>)
        },
        {
            title: t('settingsPage.value'),
            dataIndex: 'value',
            key: 'value',
            className: 'left',
            width: '30%'
        },
        {
            title: 'F',
            dataIndex: 'frozen',
            key: 'frozen',
            className: 'center',
            width: 25,
            render: (n) => n == 1 ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: 'H',
            dataIndex: 'hidden',
            key: 'hidden',
            className: 'center',
            width: 25,
            render: (n) => n == 1 ? <CheckSquareFilled /> : <BorderOutlined />
        },
        {
            title: t('settingsPage.help'),
            dataIndex: 'name',
            key: 'name',
            className: 'left',
            width: '40%',
            render: (text) => (t(`settingHelp.${text}`))
        },
        {
            title: t('settingsPage.scope'),
            dataIndex: 'scope',
            key: 'scope',
            className: 'center',
            width: '10%',
            render: (text: string, record: SettingsDetails) => renderScope(record.scope),
        },
        {
            title: 'Action',
            key: 'action',
            width: '60px',
            dataIndex: 'action',
            className: 'center',
            render: (_, record: SettingsDetails) => (
                <Space size='small'>
                    {
                        record.frozen ?
                            <LockTwoTone twoToneColor='red' title={t('settingsPage.frozen')} />
                        :
                        < Button
                            type='text'
                            size='small'
                            onClick={() => {setDataToUpdate(record); setModalVisible(true)}}
                            icon={<EditTwoTone twoToneColor='green' />}
                        />
                    }
                </Space>
            ),
        },
    ]

    if (appSetting && loggedUser) {
        return (
            <>
                <Card className='SettingsPage'
                      title={
                          <Row>
                              <Col span={6}>
                                  <SettingOutlined />&nbsp;{t('settingsPage.title')}
                              </Col>
                              <Col span={18}>
                                  <Space style={{float: 'right', marginRight: '20px'}}>
                                      <div style={{float: "left", marginRight: "10px"}}>
                                          {
                                              (editApp || editCustomer) &&
                                              <span>{t('settingsPage.scope_title')}:&nbsp;</span>
                                          }
                                          {
                                              editApp && renderScope('app')
                                          }
                                          &nbsp;
                                          {
                                              editCustomer && renderScope('customer')
                                          }

                                      </div>
                                  </Space>
                              </Col>
                          </Row>
                      }
                >
                    <Table<SettingsDetails>
                        rowClassName={() => 'highlight'}
                        bordered={true}
                        columns={columns}
                        loading={isLoading}
                        dataSource={dataSource}
                        className='settingsTable'
                        rowKey='id'
                        pagination={{
                            defaultPageSize: appSetting.grid_page_size,
                            pageSizeOptions: PAGING,
                            showSizeChanger: true
                        }}
                        onChange={(ev) => {
                            setPageSize(`${ev.pageSize}`)
                        }}
                        footer={() => TotalNum(Number(dataSource?.length), 'Tax', dataSource)}
                    />

                </Card>

                <Modal
                    destroyOnClose={true}
                    style={{top: 20}}
                    title={
                        <>
                            <ShopOutlined/>{' '}
                            {dataToUpdate ? t('settingsPage.update_title') : t('settingsPage.create_title')}
                        </>
                    }
                    visible={isModalVisible}
                    onCancel={() => setModalVisible(false)}
                    footer={null}>
                    <SettingForm
                        settingToUpdate={dataToUpdate}
                        setModalVisible={setModalVisible}
                    />
                </Modal>
            </>
        )
    }
    else {
        return (
            <Spin/>
        )
    }
}

export default SettingsPage
